/* eslint-disable space-before-function-paren */
import { Accept, useDropzone } from "react-dropzone";
import {
    useCallback,
    SetStateAction,
    useState,
    useEffect,
    FC,

} from "react";
import addFolderIcon from "../../Assets/Images/icon/folder-add.svg";


interface IImgUpldPropP {
    handleFileUpload: any
}

const ImageUploadComponent: FC<IImgUpldPropP> = ({ handleFileUpload }) => {

    const [files, setFiles] = useState<string[] | any>([]);

    const { getRootProps: getRootFilesProps, getInputProps: getInputFilesProps } = useDropzone({
        onDrop: useCallback<any>(
            (acceptedFiles: {
                map: (arg0: (file: any) => any) => SetStateAction<never[]>;
            }) => {
                // console.log("ac", acceptedFiles);
                setFiles(
                    acceptedFiles.map((file: Blob | MediaSource) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                );
            },
            []
        ),
        accept: "image/jpeg, image/png" as unknown as Accept,
        maxFiles: 1,
        onDropAccepted(files, event) {
            handleFileUpload(event, files)
        },
    });

    const deleteFiles = () => {
        setFiles([])
    }

    const thumbs = files.map((file: any) => (
        <div key={file.name} className="input-div">
            <span onClick={deleteFiles} style={{ fontSize: "20px", color: 'black' }}>x</span>
            <img
                src={file.preview}
                alt={file.name}
                className="input-icon"
            />
            <aside>{file.name}</aside>

        </div>
    ));

    useEffect(() => {
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }, [files,]);

    const formData = new FormData()
    formData.append('files_a', files)

    return (
        <div className="upload-comp"> 
            <div {...getRootFilesProps()} className="form-group ">
                <label style={{ width: '458px', height: "160px", backgroundColor: "#dfe4ea", marginTop: "9px" }}>
                    {files.length === 0 ? (
                        <div className="input-div " style={{
                            marginTop: "26px",
                            border: '1px solid #dfe4ea',
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",

                        }}>
                            <img
                                className="input-icon"
                                style={{
                                    height: "64px",
                                    width: '80px',

                                }}
                                src={addFolderIcon}
                                alt="folder icon"
                            />{" "}
                            <div><p> Drag and drop or browse your file</p></div>
                        </div>
                    ) : (
                        <aside>{thumbs}</aside>
                    )}

                    <input {...getInputFilesProps()} />
                </label>
            </div>
        </div>

    )
}
export default ImageUploadComponent
import React, { useState } from 'react'
import "./EnterpriseOtp.scss" 
import Logo from "../../../../../Assets/Images/cinderBuildLogo.png" 
import { Link, useNavigate } from 'react-router-dom'
import { decodeToken, isExpired } from "react-jwt"; 
import Cookie from 'cookie-universal'
import OtpInput from 'react-otp-input';
import { useForm } from 'react-hook-form';
import { ErrorHandler } from '../../../../../Helpers/ErrorHandler' 
import toast, { Toaster } from 'react-hot-toast';
import authService from '../../../../../Services/authService'
import { useAuth } from '../../../../../Components/AuthProvider/AuthProvider';
import { Logger } from '../../../../../Helpers/Logger';
 
const EnterpriseOtp = () => {
  const [submit, setSubmit] = useState(false)
  const [otp, setOtp] = useState("")
  const [error, setError] = useState<any>(null)

  const { login } = useAuth()
 
  const cookies = Cookie()
  const referrer = cookies.get("referer")
  const history = useNavigate()

  const { handleSubmit } = useForm({ mode: 'onBlur' });

  const onSubmit = handleSubmit((data:any) => processOtp(data));

  const processOtp = async (data:any) => {
    setSubmit(true)
   
    const a:any = localStorage.getItem("temp__reg");
    const userPrev = JSON.parse(a);
  
    const Fdata = {
      verificationCode: String(otp),
      phoneNumber: String(userPrev.phoneNumber)
    }

      
    await authService.otpReg(Fdata)
      .then(
        (res:any) => {
          setSubmit(false)

          if (Boolean(res.data.status) === false) {
            Logger(res.data)

            setError(res.data.message)
          } else {
            // Logger("wewrewrwrwe", res.data.data.token)
            const myDecodedToken:any = decodeToken(res.data.data.token);
            // eslint-disable-next-line no-unused-vars
            const isMyTokenExpired = isExpired(res.data.token);  

            login({ ...res.data.data, ...myDecodedToken, ...res.data, switched: 0 })

            // unset temp data
            localStorage.removeItem("temp__reg");
            if (myDecodedToken.isSeller) { 

              return history("/phone-verify-success", { replace: true })
             
            } else {
              if (referrer) {

                cookies.remove("referer")
                return history(referrer, { replace: true })

              } else {
                history("/enterprise/dashboard", { replace: true }) 
                return true
              }
            }
          }
        },
        (error:any) => {
          setSubmit(false)
          console.log("error", error.response)
          ErrorHandler(error,
            (e) => {
              setError(e)
              toast.error(e, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
         });
  }

  const handleChangeOtp = (otp:any) => {
    setOtp(otp);
  }

    const sendAgain = async () => {
    const a:any = localStorage.getItem("temp__reg");
    const userPrev = JSON.parse(a); 
    
    const Fdata = {
      phoneNumber: String(userPrev.phoneNumber),
      countryIso2: String(userPrev.countryIso2)
    }

    const resending = toast.loading("Resending token, please wait...", { duration: 20000, className: 'bg-white text-dark' });
    await authService.resetPassword(Fdata).then((user:any) => {
      toast.success("Token resent", { duration: 20000, className: 'bg-white text-dark' });
      toast.dismiss(resending);
    }, (error:any) => {
      // Logger(error.response)
      toast.dismiss(resending);
      toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
      setSubmit(false)
    })
  }

  return (
    <div className="enterpriseOtp">
        <div className="row h-100 justify-content-center align-items-center"> 
            <div className="col-md-6 my-auto">
                <div className="login-header d-md-none">
                    <div className="row h-100">
                        <div className="col-2">
                            <div className="login-back">
                            <a href="" onClick={() => setError(null)}><span className="d-icon d-arrowhead-left"></span></a>
                            </div>
                        </div>
                        <div className="col-8 text-start ps-0 my-auto">
                            <div className="login-logo">
                                <img src={Logo} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="login-form" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <h1 className='text-center'>Verify OTP</h1>
                        <small className='text-center'>A one time password has been sent to your registered phone number.</small>
                        <form onSubmit={onSubmit}>
                            <div className="form-group mt-4 mb-3 container-token text-center">
                            <OtpInput
                                value={otp}
                                onChange={handleChangeOtp}
                                numInputs={4}
                                separator={<span>   </span>}
                                inputStyle={{
                                  width: "3.1rem",
                                  height: "2.5rem",
                                  minHeight: "2.5rem",
                                  margin: "0 .5rem",
                                  fontSize: "1rem",
                                  borderRadius: 4,
                                  backgroundColor: "#ECF1F4",
                                  boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
                                  border: "1px solid rgba(0,0,0,0.1)"
                                }}
                              />

                            </div>

                            {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                            <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                                <div className="text-danger">
                                   {error}
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}

                            <div className="mt-4 text-center">
                            <Link to="#" onClick={() => sendAgain()} className="resendOtp">Didn’t recieve code? Send again..</Link>
                          </div>

                          <div className="form-group mt-4">
                                {!submit && (<button type="submit" className="btn btn-login">Verify</button>)}
                                {submit && (<button type="submit" className="btn btn-login" disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>)}
                            </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <Toaster></Toaster>
    </div>
  )
}

export default EnterpriseOtp

import React, { Fragment, useEffect, useState } from 'react'
import { EnterprisePageTitle, HorizontalLoader } from '../../../../../../Components'
import "./EnterpriseUserManagement.scss"
import ImageSrc from '../../../../../../Components/ImageSrc/ImageSrc';
import { Link } from 'react-router-dom';
import corperateUserService from '../../../../../../Services/corperateUserService';
import toast, { Toaster } from 'react-hot-toast';
import { corperateUser } from '../../../../../../Models/enterprise.types';
 
const EnterpriseUserManagement = () => {
    
    const [corperateUser, setCorperateUser] = useState([])
    const [corperateUserRequest, setCorperateUserRequest] = useState(false)
    const [page,] = useState(1)
    const [sortOrder,] = useState("DESC")
    
    const getCorperateUser = async () => {  
        setCorperateUserRequest(true)
        await corperateUserService.index(page, sortOrder)
          .then((res: { data: { data: {dataset : React.SetStateAction<any>; }}; }) => { 
            console.log("rr",res.data.data)
            setCorperateUserRequest(false)
            setCorperateUser(res.data.data.dataset)
          }, (error:any) => { 
            setCorperateUserRequest(false)
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    
      useEffect(() => {
        getCorperateUser();
      }, []) 


 const deactivate = async (uuid?:string) => {
   const loading = toast.loading("Deactivating user", { className: 'custom-alert bg-info text-dark', position: "top-right" });
   
   await corperateUserService.deactivate(uuid)
   .then((res) => { 
      
        toast.dismiss(loading)
        toast.success("User Deactivated", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        
        setTimeout(()=>{
            getCorperateUser();
        }, 4000)

   }, (error:any) => { 
        toast.dismiss(loading) 
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
   })
 
 }

    return (
        <div className='enterpriseUserManagement'>
            <div className="container">
                <EnterprisePageTitle
                    title="User Management"
                    parent=""
                    parentUrl=""
                    showTitle={false}
                />
 
                <div className="row mt-4">
                    <div className="col-12 col-lg-6">
                      <div className="seach-field">
                        <input type="text" className='search-input form-control' placeholder='Search user by name, email or role'/>
                        <svg className="search-icon" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6752 11.5657C9.3418 13.8991 5.55859 13.8991 3.22518 11.5657C0.891767 9.23226 0.891767 5.44905 3.22518 3.11564C5.55859 0.782229 9.3418 0.782229 11.6752 3.11564C14.0086 5.44905 14.0086 9.23226 11.6752 11.5657ZM11.6752 11.5657L16.4128 16.3033" stroke="#868484" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>  
                      </div>
                    </div>
                    <div className="col-4 col-lg-2">

                        <button className='btn btn-filter dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg className="btn-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9404 22.6516C10.4604 22.6516 9.99039 22.5316 9.55039 22.2916C8.67039 21.8016 8.14039 20.9116 8.14039 19.9116V14.6116C8.14039 14.1116 7.81039 13.3616 7.50039 12.9816L3.76039 9.02156C3.13039 8.39156 2.65039 7.31156 2.65039 6.50156V4.20156C2.65039 2.60156 3.86039 1.35156 5.40039 1.35156H18.6004C20.1204 1.35156 21.3504 2.58156 21.3504 4.10156V6.30156C21.3504 7.35156 20.7204 8.54156 20.1304 9.13156L15.8004 12.9616C15.3804 13.3116 15.0504 14.0816 15.0504 14.7016V19.0016C15.0504 19.8916 14.4904 20.9216 13.7904 21.3416L12.4104 22.2316C11.9604 22.5116 11.4504 22.6516 10.9404 22.6516ZM5.40039 2.85156C4.70039 2.85156 4.15039 3.44156 4.15039 4.20156V6.50156C4.15039 6.87156 4.45039 7.59156 4.83039 7.97156L8.64039 11.9816C9.15039 12.6116 9.65039 13.6616 9.65039 14.6016V19.9016C9.65039 20.5516 10.1004 20.8716 10.2904 20.9716C10.7104 21.2016 11.2204 21.2016 11.6104 20.9616L13.0004 20.0716C13.2804 19.9016 13.5604 19.3616 13.5604 19.0016V14.7016C13.5604 13.6316 14.0804 12.4516 14.8304 11.8216L19.1104 8.03156C19.4504 7.69156 19.8604 6.88156 19.8604 6.29156V4.10156C19.8604 3.41156 19.3004 2.85156 18.6104 2.85156H5.40039Z" fill="#868484"/>
                                <path d="M5.99968 10.7473C5.85968 10.7473 5.72968 10.7073 5.59968 10.6373C5.24968 10.4173 5.13968 9.94733 5.35968 9.59733L10.2897 1.69733C10.5097 1.34733 10.9697 1.23733 11.3197 1.45733C11.6697 1.67733 11.7797 2.13733 11.5597 2.48733L6.62968 10.3873C6.48968 10.6173 6.24968 10.7473 5.99968 10.7473Z" fill="#868484"/>
                            </svg>
                            Filter 
                        </button> 
                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                            <li><a className="dropdown-item" href="#">
                                    Account Managers
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#"> 
                                Warehouse Managers
                                </a>
                            </li> 
                        </ul> 
                    </div>
                    <div className="col-4 col-lg-2">
 
                        <button className='btn btn-filter dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg className="btn-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 11V17L11 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 17L7 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> 
                            Export 
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                            <li><a className="dropdown-item" href="#">
                            Export as .pdf
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#"> 
                                Export as .doc
                                </a>
                            </li> 
                            <li>
                                <a className="dropdown-item" href="#"> 
                                    Export as .xlsx
                                </a>
                            </li> 
                        </ul>

                    </div>
                    <div className="col-4 col-lg-2">
                      <Link to="/enterprise/user-management-addnew">
                        <button className='btn btn-new-user'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 18V6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> 
                            New User 
                        </button>
                        </Link>
                    </div>
                </div>


                <div className="enterprise-table">
                    <div className="table-responsive">
                        <table className="table rounded"> 
                            <thead className='rounded'>
                                <tr className='d-none d-md-table-row'>
                                <th scope="col">Full Name</th>
                                <th scope="col">Photo</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone number</th>
                                <th scope="col">Role</th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {corperateUser.length > 0 ? corperateUser.map((res:corperateUser, index)=>{
                                return <Fragment key={index}>
                                <tr className='d-none d-md-table-row'>
                                    <td>{res.firstName} {res.lastName}</td>
                                    <td><ImageSrc src={res.photoUrl? res.photoUrl: ""} alt={''} title={undefined} width={'table-profile'}></ImageSrc></td>
                                    <td>{res.emailAddress}</td>
                                    <td>{res.msisdn}</td>
                                    <td> {res.role === "WARE_HOUSE_LEVEL"? "Warehouse Manager": "Account Manager" }</td>
                                    <td>
                                      <div className="btn-group">
                                        <button type="button" className="btn border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className='d-icon d-more-vertical'></i>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                                           <li><Link className="dropdown-item" to={`/enterprise/user-management-edit/${res.uuid}`}>
                                                <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M16.0399 3.02123L8.15988 10.9012C7.85988 11.2012 7.55988 11.7912 7.49988 12.2212L7.06988 15.2312C6.90988 16.3212 7.67988 17.0812 8.76988 16.9312L11.7799 16.5012C12.1999 16.4412 12.7899 16.1412 13.0999 15.8412L20.9799 7.96123C22.3399 6.60123 22.9799 5.02123 20.9799 3.02123C18.9799 1.02123 17.3999 1.66123 16.0399 3.02123Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.9102 4.14844C15.5802 6.53844 17.4502 8.40844 19.8502 9.08844" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                    Edit User
                                                </Link>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#" onClick={()=>{ deactivate(res.uuid) }}>
                                                <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.1205 13.05C12.1005 13.05 12.0705 13.05 12.0505 13.05C12.0205 13.05 11.9805 13.05 11.9505 13.05C9.68047 12.98 7.98047 11.21 7.98047 9.03C7.98047 6.81 9.79047 5 12.0105 5C14.2305 5 16.0405 6.81 16.0405 9.03C16.0305 11.22 14.3205 12.98 12.1505 13.05C12.1305 13.05 12.1305 13.05 12.1205 13.05ZM12.0005 6.49C10.6005 6.49 9.47047 7.63 9.47047 9.02C9.47047 10.39 10.5405 11.5 11.9005 11.55C11.9305 11.54 12.0305 11.54 12.1305 11.55C13.4705 11.48 14.5205 10.38 14.5305 9.02C14.5305 7.63 13.4005 6.49 12.0005 6.49Z" fill="#292D32"/>
                                                    <path d="M11.9998 22.7503C9.30984 22.7503 6.73984 21.7503 4.74984 19.9303C4.56984 19.7703 4.48984 19.5303 4.50984 19.3003C4.63984 18.1103 5.37984 17.0003 6.60984 16.1803C9.58984 14.2003 14.4198 14.2003 17.3898 16.1803C18.6198 17.0103 19.3598 18.1103 19.4898 19.3003C19.5198 19.5403 19.4298 19.7703 19.2498 19.9303C17.2598 21.7503 14.6898 22.7503 11.9998 22.7503ZM6.07984 19.1003C7.73984 20.4903 9.82984 21.2503 11.9998 21.2503C14.1698 21.2503 16.2598 20.4903 17.9198 19.1003C17.7398 18.4903 17.2598 17.9003 16.5498 17.4203C14.0898 15.7803 9.91984 15.7803 7.43984 17.4203C6.72984 17.9003 6.25984 18.4903 6.07984 19.1003Z" fill="#292D32"/>
                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#292D32"/>
                                                    <path d="M2.5007 14.4999L21.5 10.4928" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg> 
                                                Deactivate User
                                                </a>
                                            </li> 
                                        </ul>
                                      </div>
                                    </td>
                                </tr>  
                                <tr className='d-md-none'>
                                    <td>
                                        <ImageSrc src={res.photoUrl? res.photoUrl: ""} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                                    </td>
                                    <td colSpan={4}>
                                        <div className="row"> 
                                            <div className='col-12 profile-title'>{res.firstName} {res.lastName}</div>
                                            <div className='col-12 profile-detail'>{res.emailAddress}</div> 
                                            <div className='col-12 profile-detail'>{res.role === "WARE_HOUSE_LEVEL"? "Warehouse Manager": "Account Manager" }</div>
                                        </div>
                                    </td>
                                    <td className='profile-btn'>
 
                                        <div className="btn-group">
                                            <button type="button" className="btn border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className='d-icon d-more-vertical'></i>
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                                            <li><Link className="dropdown-item" to={`/enterprise/user-management-edit/${res.uuid}`}>
                                                    <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M16.0399 3.02123L8.15988 10.9012C7.85988 11.2012 7.55988 11.7912 7.49988 12.2212L7.06988 15.2312C6.90988 16.3212 7.67988 17.0812 8.76988 16.9312L11.7799 16.5012C12.1999 16.4412 12.7899 16.1412 13.0999 15.8412L20.9799 7.96123C22.3399 6.60123 22.9799 5.02123 20.9799 3.02123C18.9799 1.02123 17.3999 1.66123 16.0399 3.02123Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M14.9102 4.14844C15.5802 6.53844 17.4502 8.40844 19.8502 9.08844" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                        Edit User
                                                    </Link>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#" onClick={()=>{ deactivate(res.uuid) }}>
                                                    <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.1205 13.05C12.1005 13.05 12.0705 13.05 12.0505 13.05C12.0205 13.05 11.9805 13.05 11.9505 13.05C9.68047 12.98 7.98047 11.21 7.98047 9.03C7.98047 6.81 9.79047 5 12.0105 5C14.2305 5 16.0405 6.81 16.0405 9.03C16.0305 11.22 14.3205 12.98 12.1505 13.05C12.1305 13.05 12.1305 13.05 12.1205 13.05ZM12.0005 6.49C10.6005 6.49 9.47047 7.63 9.47047 9.02C9.47047 10.39 10.5405 11.5 11.9005 11.55C11.9305 11.54 12.0305 11.54 12.1305 11.55C13.4705 11.48 14.5205 10.38 14.5305 9.02C14.5305 7.63 13.4005 6.49 12.0005 6.49Z" fill="#292D32"/>
                                                        <path d="M11.9998 22.7503C9.30984 22.7503 6.73984 21.7503 4.74984 19.9303C4.56984 19.7703 4.48984 19.5303 4.50984 19.3003C4.63984 18.1103 5.37984 17.0003 6.60984 16.1803C9.58984 14.2003 14.4198 14.2003 17.3898 16.1803C18.6198 17.0103 19.3598 18.1103 19.4898 19.3003C19.5198 19.5403 19.4298 19.7703 19.2498 19.9303C17.2598 21.7503 14.6898 22.7503 11.9998 22.7503ZM6.07984 19.1003C7.73984 20.4903 9.82984 21.2503 11.9998 21.2503C14.1698 21.2503 16.2598 20.4903 17.9198 19.1003C17.7398 18.4903 17.2598 17.9003 16.5498 17.4203C14.0898 15.7803 9.91984 15.7803 7.43984 17.4203C6.72984 17.9003 6.25984 18.4903 6.07984 19.1003Z" fill="#292D32"/>
                                                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#292D32"/>
                                                        <path d="M2.5007 14.4999L21.5 10.4928" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg> 
                                                    Deactivate User
                                                    </a>
                                                </li> 
                                            </ul>
                                      </div>
                                    </td>   
                                </tr> 
                                </Fragment> 
                                }): <tr>
                                    <td colSpan={7} className="text-center">
                                       {corperateUser.length === 0 && corperateUserRequest ===true && <HorizontalLoader></HorizontalLoader>}
                                       {corperateUserRequest ===false && corperateUser.length === 0 && "No user found"}
                                    </td>
                                </tr>
                                } 
                            </tbody> 
                        </table>
                    </div>
                </div>
            </div>
            <Toaster></Toaster>
        </div>
    )
}

export default EnterpriseUserManagement

import "./BusinessInformation.scss";
import { BsCheckCircle } from "react-icons/bs";
import RightArrow from "../../../../../../../Assets/Images/icon/right-arrow.png";
import { Link } from "react-router-dom";

const BusinessInformation = () => {
    return (
        <div className="biz-info">
            <div className="title mb-3">
                Upload business documents to start selling on Cinderbuild
            </div>
            <div className="item">
                <div className="details mb-2">
                    <div className="icon-title" >
                        <BsCheckCircle className="icon icon-checked" />
                        <p className="details-title">CAC Document</p>
                    </div>
                    <Link to='cac-document'>  <img src={RightArrow} alt="img" /></Link>

                </div>
                <div className="details">
                    <div className="icon-title">
                        <BsCheckCircle className="icon icon-checked" />
                        <p className="details-title">ID Card</p>
                    </div>
                    <Link to='id-card'><img src={RightArrow} alt="img" /></Link>
                </div>
                <div className="details mt-1">
                    <div className="icon-title">
                        <BsCheckCircle className="icon icon-checked" />
                        <p className="details-title">Bank Account</p>
                    </div>
                    <Link to='/seller/bank-account'><img src={RightArrow} alt="img" /></Link>
                </div>
            </div>
        </div>
    );
};

export default BusinessInformation;

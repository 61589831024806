import {FC} from "react";
import "./SellerNavCard.scss";
import  {Link} from "react-router-dom";

interface propsType {
    title:string;
    icon:string;
    description:string;
    link:string;
}

const SellerNavCard: FC<propsType> = ({title, icon, description,link}) => {
    return (
        <Link className="nav-link" to={link}>
            <div className="card seller-nav-card">
                <div className="card-body"> 
                    <div className="row">
                        <div className="col-3 col-md-2">
                            <div className="icon-container">
                                <img src={icon} alt=""/>
                            </div>
                        </div>
                        <div className="col-9 col-md-10">
                            <div className="link-details">
                                <div className="title">{title}</div>
                                <div className="description">{description}</div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
        </Link>
    )
}

export default SellerNavCard;
import React, { useEffect, useState } from 'react'
import { EnterprisePageTitle } from '../../../../../../Components'
import { useAllOrderDelivery } from '../../../../../../Hooks/Queries/Enterprise/useAllOrderDelivery'
import { Deliveries } from '../../Components'
import "./EnterpriseWareHouseOrder.scss"

const EnterpriseWareHouseOrder = () => {
 
    const [pageDelivery, setPageDelivery] = useState(1)
    const [sortOrder,] = useState("DESC")
    
    const {data:myOrdersDelivery, isLoading:loadingRequestDelivery, isFetching:fetchingDelivery, isSuccess:isSuccessDelivery, refetch: getDeliveries } = useAllOrderDelivery(pageDelivery, sortOrder)
    
    console.log("myOrdersDelivery", {myOrdersDelivery, loadingRequestDelivery, isSuccessDelivery})

    useEffect(() => { 
        getDeliveries()
      }, [pageDelivery])

    return (
        <div className='delivery-order-manager'>
            <div className="container">
                <EnterprisePageTitle
                    title="Delivery Request"
                    parent=""
                    parentUrl=""
                    showTitle={true}
                />
                <div className="mt-5">
                    <Deliveries isSuccess={isSuccessDelivery} loadingRequest={loadingRequestDelivery} myOrders={myOrdersDelivery} setPage={setPageDelivery} fetchingDelivery={fetchingDelivery}></Deliveries>
                </div>
            </div>
        </div>
    )
}

export default EnterpriseWareHouseOrder

/* eslint-disable react/no-unknown-property */

import React from 'react'
import "./SubNavMenu.scss"
import { Link } from 'react-router-dom';
import CategoryMegaMenu from '../../NavMenu/Components/CategoryMegaMenu/CategoryMegaMenu';

const SubNavMenu = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-level2">
        <div className="container ps-1">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ps-0">
                    <CategoryMegaMenu></CategoryMegaMenu>
                    <li className="nav-item dropdown ps-0 d-none">
                        <Link className="nav-link dropdown-toggle" to="/categories" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Categories
                        </Link>
                        <ul className="dropdown-menu  border-0 shadow" aria-labelledby="navbarDropdown">
                        <li><Link className="dropdown-item" to="/categories">All</Link></li>
                        </ul>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/brands">Brands</Link>
                    </li>
                    <li className="nav-item d-none">
                        <Link className="nav-link" to="/bestdeals-product">Best deals</Link>
                    </li>
                    <li className="nav-item d-none">
                        <Link className="nav-link" to="/bestselling-product" tabIndex={-1} aria-disabled="true">Best seller</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/products" tabIndex={-1} aria-disabled="true">All Products</Link>
                    </li>
                    <li className="nav-item d-none">
                        <a className="nav-link" href="#" tabIndex={-1} aria-disabled="true"> <i className="iconsax" icon-name="milk"></i> Resources</a>
                    </li>
                </ul>
             </div>
        </div>
    </nav>
  )
}

export default SubNavMenu

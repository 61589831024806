import { FC, useState } from "react";
import "./EnterpriseSettingsNotification.scss"
import { useForm } from "react-hook-form"; 
import { MiniLoader } from "../../../../../../../Components";  
import EnterpriseSubPageTitle from "../../../../../../../Components/Enterprise/EnterpriseSubPageTitle/EnterpriseSubPageTitle";
 
type formProp = {
  newPassword: string;
  currentPassword: string;
  reNewPassword: string;
}

interface propsType {
    module?: string
}
const EnterpriseSettingsNotification:FC<propsType> = ({module})=>{  
  const [loadingRequest,] = useState<boolean>(false)
  // eslint-disable-next-line no-unused-vars
  const [loadingPassword, setLoadingPassword] = useState<boolean>(false)
 

  const {  handleSubmit: handleSubmitPass } = useForm<formProp>()
  
  const changePassword = handleSubmitPass((data:any) => processPassword(data))
  const processPassword = async (data: { newPassword: any; }) => { 
    
  }
  
    return  (
       <div className="enterpriseSettingsNotification"> 
            <EnterpriseSubPageTitle
                title="Email Notification"
                parents={[
                    {
                        title:"Settings",
                        url: "settings"
                    }
                ]} 
            />
            <div className="row justify-content-center">
                <div className="col-12 col-md-9 col-lg-6 profile-item-container mb-4">
                     <div className="description">Select users that gets copied in email notifications sent to your corporate email address</div>
                  <MiniLoader show={loadingRequest}/>
                  <div className="card border-0"> 
                      <div className="card-body"> 
                          <form onSubmit={changePassword}>


                                 <div className='form-group my-3'>
                                  <label htmlFor="">No user found</label>
                                    {/* <input type="password" className="form-control" placeholder='Current password' {...registerPass("currentPassword", { required: "this field cannot be empty" })}/>
                                    <div className="text-danger"> {errorsPass.currentPassword && errorsPass.currentPassword.message} </div> */}
                                </div>   

                                <div className='form-group my-3 text-center mt-5'> 
                                    {!loadingPassword && (<button type="submit" className={module && module==='estate'? "btn security-btn-primary-estate btn-lg btn-block w-100":"btn security-btn-primary btn-lg btn-block w-100"} disabled>Save Changes</button>)}
                                  {loadingPassword && (<button type="submit" className={module && module==='estate'? "btn security-btn-primary-estate btn-lg btn-block w-100":"btn security-btn-primary btn-lg btn-block w-100"} disabled>
                                      Please wait
                                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  </button>)}
                                </div>
                            </form>
                          </div>
                      </div>  
                </div>
            </div>
       </div>
    )

}

export default EnterpriseSettingsNotification;

import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Profiler, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./Components/AuthProvider/AuthProvider";
import "./index.scss";
import {
 ForgotPassword, 
 ForgotPasswordSuccess, 
 Home, 
 Products, 
 Login, 
 NewPassword, 
 Otp, 
 Registration, 
 Categories,
 Brands, 
 ProductsByBrand, 
 ProductByCategory, 
 ProductSinglePage, 
 Cart, 
 Checkout, 
 OrderCompleted, 
 BestSellingProducts, 
 BestDealsProducts, 
 SearchProducts, 
 OrderCompletedPrint, 
 EnterpriceHome, 
 EnterpriseSetup, 
 BankInfomation, 
 BusinessInfoUpload, 
 BusinessInfomation, 
 StoreRegistration, 
 PhoneVerifySuccess, 
 EnterpriseOtp, 
 PaymentSuccessful, 
 Builder360, 
 BuyerUnauthPaymentCompleted, 
 CBcast, 
 CBcastDetail, 
 Resources, 
 ResourcesDetails, 
 ProductVariants,
   
} from './Pages/Frontend/Interface';  
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { HelmetProvider } from 'react-helmet-async';
import Wrapper from './Pages/Skeleton/Wrapper/Wrapper'; 
import ReactGA from "react-ga4";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";
import Store from "./Storage/Store";
import { FullscreenLoader, ProtectedEstateManagers } from "./Components";
import {
  WalletWithdrawal,
  BankingAndFinance,
  BankingAndFinanceWalletFunding,
  BuyerDashboard,
  BuyerOrders,
  PaidOrders,
  PLPOrders,
  PayOnDelivery,
  BuyerOrdersDetails,
  Profile,
  MyAccountBankAndFinance,
  Security,
  AddressBook,
  BuyerPaymentCompleted,
  BuyerNotification,
} from "./Pages/Frontend/Interface/Buyer/Interface";
import AddBankAccount from "./Pages/Frontend/Interface/Buyer/Interface/BankingAndFinance/AddBankAccount/AddBankAccount";
import BuyerOrderTrackingPage from "./Pages/Frontend/Interface/Buyer/Interface/BuyerOrderTrackingPage/BuyerOrderTrackingPage";
import BuyerQuoteRequest from "./Pages/Frontend/Interface/Buyer/Interface/BuyerQuoteRequest/BuyerQuoteRequest";
import ActiveRequest from "./Pages/Frontend/Interface/Buyer/Interface/BuyerQuoteRequest/ActiveRequest/ActiveRequest";
import PendingResponse from "./Pages/Frontend/Interface/Buyer/Interface/BuyerQuoteRequest/PendingResponse/PendingResponse";
import BuyerQuoteDetails from "./Pages/Frontend/Interface/Buyer/Interface/BuyerQuoteDetails/BuyerQuoteDetails";
import SavedList from "./Pages/Frontend/Interface/Buyer/Interface/SavedListPage/SavedList";
import ContactUsPage from "./Pages/Frontend/Interface/Buyer/Interface/ContactUsPage/ContactUsPage";
import PLPApplicationForm from "./Pages/Frontend/Interface/Buyer/Interface/PLP/PLPApplication/PLPApplicationForm";
import PLP from "./Pages/Frontend/Interface/Buyer/Interface/PLP/PLP";
import PLPMainPage from "./Pages/Frontend/Interface/Buyer/Interface/PLP/PLPMainPage/PLPMainPage";
import MyAccount from "./Pages/Frontend/Interface/Buyer/Interface/MyAccount/MyAccount";
import BuyerWraperWithoutSideBar from "./Pages/Skeleton/WrapperAuth/BuyerWraperWithoutSideBar/BuyerWraperWithoutSideBar";
import Main from "./Pages/Frontend/Interface/Buyer/Interface/MyAccount/Main/Main";
import MyAccountWalletFunding from "./Pages/Frontend/Interface/Buyer/Interface/MyAccount/BankAndFinance/BankingAndFinance/BankingAndFinanceWalletFunding/BankingAndFinanceWalletFunding";
import MyAccountWalletWidthraw from "./Pages/Frontend/Interface/Buyer/Interface/MyAccount/BankAndFinance/BankingAndFinance/WalletWithdrawal/WalletWithdrawal";
  
 
import WrapperStoreReg from './Pages/Skeleton/WrapperStoreReg/WrapperStoreReg'; 
import { 
  EnterpriseOrderManagement, 
  EnterpriseBankingAndFinance, 
  EnterpriseProduct, 
  EnterpriseWareHouseManagement, 
  EnterpriseCart,
  EnterprisePLPMainPage, 
  EnterprisePLPApplicationForm, 
  EnterpriseCheckout, 
  EnterpriseDashboard, 
  EnterpriseOrderCompleted, 
  EnterprisePLP, 
  EnterpriseProductSingle, 
  EnterpriseReorder, 
  EnterpriseSettings, 
  EnterpriseUserManagement, 
  EnterpriseWalletHistory,
  EnterpriseSettingsMain,
  EnterpriseSettingsProfile,
  EnterpriseSettingsSecurity,
  EnterpriseSettingsAddressBook,
  EnterpriseSettingsBankAndFinance,
  EnterpriseAddNewUser,
  EnterpriseBulkOrderManagement,
  EnterpriseOrderDetails,
  EnterpriseInvoice,
  EnterpriseInvoiceDetails,
  EnterpriseMakeOffer,
  EnterpriseWareHouseShipToSite,
  EnterpriseWareHouseShipToSiteCheckout,
  EnterpriseSettingsBusinessProfile,
  EnterpriseSettingsNotification,
  EnterpriseEditUser,
  EnterpriseNotification,
  EnterpriseWareHouseShipToSiteRequestSent,
  EnterpriseWareHouseShipToSiteDetail,
  EnterpriseContactUsPage,
  EnterpriseSavedListPage,
  EnterprisePaymentCompleted,
  EnterPriseMobileMenuTutorial,
  EnterpriseQuote, 
 } from './Pages/Frontend/Interface/Enterprise';
 

import EnterpriseWarehouseDetails from './Pages/Frontend/Interface/Enterprise/Interface/EnterpriseWareHouseManagement/EnterpriseWarehouseDetails/EnterpriseWarehouseDetails';
import ProtectedSeller from './Components/ProtectedSeller/ProtectedSeller';
   
import EnterpriseWareHouseOrder from './Pages/Frontend/Interface/Enterprise/Interface/EnterpriseWareHouseOrder/EnterpriseWareHouseOrder';
import { ToolTipsHelper } from "./Helpers/ToolTipsHelper";
import TermsAndCondition from "./Pages/Frontend/Interface/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "./Pages/Frontend/Interface/PrivacyPolicy/PrivacyPolicy";
import AboutUs from "./Pages/Frontend/Interface/AboutUs/AboutUs";
import { BankingFinance, CreateProduct, EditProduct, EditProductVariant, PLPSeller, PLPSellerApplicationForm, PLPSellerMainPage, SellerNotification, SellerOrders, SellerProducts, SellerQuoteRequest, SellerStore } from "./Pages/Frontend/Interface/Seller/Interface";
import SellerHome from './Pages/Frontend/Interface/Seller/Interface/SellerHome/SellerHome';
import SellerOrdersDetails from "./Pages/Frontend/Interface/Seller/Interface/SellerOrderDetails/SellerOrdersDetails";
import SellerOrderTrackingPage from "./Pages/Frontend/Interface/Seller/Interface/SellerOrderTrackingPage/SellerOrderTrackingPage";
import SellerQuoteDetails from "./Pages/Frontend/Interface/Seller/Interface/SellerQuoteDetails/SellerQuoteDetails";
import SupportPage from "./Pages/Frontend/Interface/Seller/Interface/Support/SupportPage";
import SellerSettings from "./Pages/Frontend/Interface/Seller/Interface/SellerSettings/SellerSettings";
import SellerSecurity from "./Pages/Frontend/Interface/Seller/Interface/SellerSettings/SellerSecurity/SellerSecurity";
import SellerPickupLocations from "./Pages/Frontend/Interface/Seller/Interface/SellerSettings/SellerPickupLocations/SellerPickupLocations";
import CACDocuments from "./Pages/Frontend/Interface/Seller/Interface/SellerSettings/BusinessProfile/BusinessProfileUpdate/CACDocuments/CACDocuments";
import BusinessProfile from "./Pages/Frontend/Interface/Seller/Interface/SellerSettings/BusinessProfile/BusinessProfile";
import BusinessBasicInformation from "./Pages/Frontend/Interface/Seller/Interface/SellerSettings/BusinessProfile/BusinessProfileUpdate/BusinessBasicInformation/BusinessBasicInformation";
import SettingsMainPage from "./Pages/Frontend/Interface/Seller/Interface/SellerSettings/SettingsMainPage/SettingsMainPage";
import SellerProfile from "./Pages/Frontend/Interface/Seller/Interface/SellerSettings/SellerProfile/SellerProfile";
import AddNewProduct from "./Pages/Frontend/Interface/Seller/Interface/Products/AddProduct/AddNewProduct";
import ProductListSetup from "./Pages/Frontend/Interface/Seller/Interface/Products/ProductListSetup";
import EnterpriseQuoteDetails from "./Pages/Frontend/Interface/Enterprise/Interface/EnterpriseQuoteDetails/EnterpriseQuoteDetails";
import EnterpriseActiveRequest from "./Pages/Frontend/Interface/Enterprise/Interface/EnterpriseQuote/EnterpriseQuoteRequest/EnterpriseActiveRequest/EnterpriseActiveRequest";
import EnterprisePendingResponse from "./Pages/Frontend/Interface/Enterprise/Interface/EnterpriseQuote/EnterpriseQuoteRequest/EnterprisePendingResponse/EnterprisePendingResponse";
import SellerSingleProducts from './Pages/Frontend/Interface/Seller/Interface/Products/SellerSingleProducts/SellerSingleProducts';
import { EstateBankingAndFinance, EstateHome, EstateInvestorManagement, EstateInvestorManagementDetails, EstateManagersDashboard, EstateManagersMortgageCard, EstateManagersSetup, EstateManagersSetupOtp, EstateManagersSubscriberSetup, EstateManagersSubscriberSetupCompleted, EstateManagersSubscriberSetupOtp, EstateOrderManagement, EstateProjectDetails, EstateProjectManagement, EstateSubscriberPortfolio, EstateSubscriberPortfolioDetails, EstateSubscriberProjects, EstateSubscriberProjectsDetails, EstateSubscriberRecurrentPayment, EstateSubscriberRecurrentPaymentDetails, EstateSubscriberTransaction, EstateSubscriberTransactionDetails, EstateSubscriptionDetails } from "./Pages/Frontend/Interface/EstateManagers/Interface/Index";

const ProtectedEnterprise = React.lazy(() => import('./Components/ProtectedEnterprise/ProtectedEnterprise'));
const ProtectedBuyer = React.lazy(() => import('./Components/ProtectedBuyer/ProtectedBuyer'));
 
const TRACKING_ID = "UA-240902707-1";
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
});
// get component render profile
const onRenderCallback = (
  id: any, // the "id" prop of the Profiler tree that has just committed
  phase: any, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration: any, // time spent rendering the committed update
  baseDuration: any, // estimated time to render the entire subtree without memoization
  startTime: any, // when React began rendering this update
  commitTime: any, // when React committed this update
  interactions: any // the Set of interactions belonging to this update
) => { 
};
const tutorial = ToolTipsHelper.get()
const MOBILE_BREAKING_POINT = 991

root.render(
  <React.StrictMode>
  <QueryClientProvider client={queryClient}>
  <Profiler id="Navigation" onRender={onRenderCallback}>
  <Provider store={Store}>
      <BrowserRouter>
        <HelmetProvider>
          <AuthProvider> 
              <Routes>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Registration />} />
                <Route path="otp" element={<Otp />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="forgot-password-success" element={<ForgotPasswordSuccess />} />
                <Route path="new-password" element={<NewPassword />} />
                

                <Route path="/terms-and-conditions" element={<TermsAndCondition/>}></Route> 
                <Route path="/privacy-policy"  element={<PrivacyPolicy/>}></Route>
                <Route path="/about" element={<AboutUs />}></Route>

                <Route path="phone-verify-success" element={<PhoneVerifySuccess />} />

                <Route  path='/store-registration' element={ <WrapperStoreReg> <StoreRegistration /> </WrapperStoreReg> } >
                  <Route index element={<BusinessInfomation businessName={""} cacNumber={""} businessAddress={""} handleChange={undefined} handleBlur={undefined} formikErrors={undefined} formikTouchedValues={undefined} />} />
                  <Route path='business-info-upload' element={<BusinessInfoUpload handleFileUpload={undefined} />} />
                  <Route path='bank-information' element={<BankInfomation bankName={""} accountNumber={""} handleChange={undefined} handleBlur={undefined} formikErrors={undefined} formikTouchedValues={undefined} />} />
                </Route>

                <Route path="/" element={<Wrapper><Home /></Wrapper>} />
                <Route path="products" element={<Wrapper><Products /></Wrapper>} />
                <Route path="products/variant/:productUuid" element={<Wrapper><ProductVariants /></Wrapper>} />
                
                <Route path="categories" element={<Wrapper><Categories /></Wrapper>} />
                <Route path="brands" element={<Wrapper><Brands /></Wrapper>} />
                <Route path="products-by-brand/:brandUuid" element={<Wrapper><ProductsByBrand /></Wrapper>} />
                <Route path="products-by-category/:catUuid" element={<Wrapper><ProductByCategory /></Wrapper>} />
                <Route path="product/:productUuid" element={<Wrapper><ProductSinglePage /></Wrapper>} />
                <Route path="bestselling-product" element={<Wrapper><BestSellingProducts /></Wrapper>} />
                <Route path="bestdeals-product" element={<Wrapper><BestDealsProducts /></Wrapper>} />

                <Route path="/search-product">
                  <Route path=":searchText" element={<Wrapper><SearchProducts /></Wrapper>} />
                  <Route path="" element={<Wrapper><SearchProducts /></Wrapper>} />
                </Route>

                <Route path="cart" element={<Wrapper><Cart /></Wrapper>} />
                <Route path="checkout" element={<Wrapper><Checkout /></Wrapper>} />
                <Route path="order-completed" element={<Wrapper><OrderCompleted /></Wrapper>} />
                
                <Route path="enterprise-home" element={<Wrapper><EnterpriceHome /></Wrapper>} />
                <Route path="enterprise-setup" element={<EnterpriseSetup />} />
                <Route path="enterprise-otp" element={<EnterpriseOtp />} />
                
  
                <Route path="order-completed-print" element={<OrderCompletedPrint />} />
                
                <Route path="payment-successful" element={<PaymentSuccessful />} />
                <Route path="builder360" element={<Wrapper><Builder360 /></Wrapper>} />
                
                {/* <Route path="*" element={<Wrapper><NotFound /></Wrapper>}> </Route> */}
                
                <Route path="cb-cast"  element={<Wrapper><CBcast /></Wrapper>} />
                <Route path="cb-cast/:uuid"  element={<Wrapper><CBcastDetail /></Wrapper>} />
                
                <Route path="resources"  element={<Wrapper><Resources /></Wrapper>} />
                <Route path="resources/:uuid"  element={<Wrapper><ResourcesDetails /></Wrapper>} />
                
                <Route path="payment-completed"  element={<Wrapper><BuyerUnauthPaymentCompleted /></Wrapper>} />
            </Routes>
            
              <Suspense fallback={<FullscreenLoader></FullscreenLoader>}>
                  <Routes>
                    <Route path="/buyer" element={<ProtectedBuyer></ProtectedBuyer>}>
                      <Route path="" element={<BankingAndFinance />} />
                      <Route path="dashboard" element={<BuyerDashboard />} />
                      <Route path="banking-and-finance" element={<BankingAndFinance />} />
                      <Route path="banking-and-finance-wallet-funding" element={<BankingAndFinanceWalletFunding />} />
                      <Route path="banking-and-finance-wallet-withdrawal" element={<WalletWithdrawal />} />
                      <Route path="banking-and-finance-add-bank-detail" element={<AddBankAccount />} />


                      <Route path="payment-completed"  element={<BuyerPaymentCompleted />} />

                      <Route path="my-orders" element={<BuyerOrders />}>
                          <Route path="" element={<PaidOrders />} />
                          <Route path="delivery" element={<PayOnDelivery />} />
                          <Route path="paid" element={<PaidOrders />} />
                          <Route path="plp" element={<PLPOrders />} />
                        </Route>

                        <Route  path="my-orders/details/:orderId" element={<BuyerOrdersDetails />}/>

                        <Route path="my-quotes" element={<BuyerQuoteRequest />}>
                          <Route index element={<ActiveRequest />} />

                          <Route path="active-request" element={<ActiveRequest />} />

                          <Route path="pending-response" element={<PendingResponse />}/>
                        </Route>

                        <Route path="my-quotes/details/:quoteId" element={<BuyerQuoteDetails />}/>

                        <Route path="saved" element={<SavedList />} />
                        <Route path="product-leasing-program" element={<PLP />}>
                          <Route path="apply" element={<PLPApplicationForm />} />
                          <Route index element={<PLPMainPage />} />
                        </Route>
                        <Route path="contact-us" element={<ContactUsPage />} />

                        <Route
                          path="my-orders/order-track/:orderId"
                          element={<BuyerOrderTrackingPage />}
                        />
                        <Route
                          path="notification"
                          element={<BuyerNotification />}
                        />
                        
                      </Route>
                    </Routes>
                 </Suspense>

                 <Suspense fallback={<FullscreenLoader></FullscreenLoader>}>
                 <Routes>
                  <Route path="buyer/my-account" element={
                      <BuyerWraperWithoutSideBar>
                        <MyAccount />
                      </BuyerWraperWithoutSideBar>
                    }>
                    <Route index element={<Main />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="security" element={<Security />} />
                    <Route path="address" element={<AddressBook />} />
                    <Route
                      path="bank-and-finance"
                      element={<MyAccountBankAndFinance />}
                    />

                    <Route
                      path="bank-and-finance/banking-and-finance-wallet-funding"
                      element={<MyAccountWalletFunding />}
                    />

                    <Route
                      path="bank-and-finance/banking-and-finance-wallet-withdrawal"
                      element={<MyAccountWalletWidthraw />}
                    /> 
                  </Route>
                </Routes>
               </Suspense>
 

                  {/* seller module */}

                  <Suspense fallback={<FullscreenLoader></FullscreenLoader>}>
                      <Routes>
                        <Route
                          path="/seller/"
                          element={<ProtectedSeller></ProtectedSeller>}>

                          <Route index element={<SellerHome />} />
                          <Route path="dashboard" element={<SellerHome />} />
                          
                          
                          <Route path="product" element={<ProductListSetup />}>
                            <Route index element={<SellerProducts />} />
                            <Route path='add-product' element = {<AddNewProduct/>} />
                            <Route path="create" element={<CreateProduct />} />
                            <Route path="details/:productUuid" element={<SellerSingleProducts />} />
                            <Route path="edit/:productUuid" element={<EditProduct />} />
                            <Route path="editvar/:productUuid" element={<EditProductVariant />} /> 
                            
                          </Route>

                          
                          <Route path="notification" element={<SellerNotification />} />
                          <Route path="my-orders" element={<SellerOrders />} />
                          <Route path="my-orders/details/:orderId" element={<SellerOrdersDetails />} />
                          <Route
                            path="my-orders/order-track/:orderId"
                            element={<SellerOrderTrackingPage />}
                          />

                          <Route path="my-quotes" element={<SellerQuoteRequest />} />
                          <Route
                            path="my-quotes/details/:quoteId"
                            element={<SellerQuoteDetails />}
                          />

                          <Route path="my-store" element={<SellerStore />} />
                          <Route
                            path="banking-finance"
                            element={<BankingFinance />}
                          />
                          <Route
                            path="product-leasing-program"
                            element={<PLPSeller />}>
                            <Route index element={<PLPSellerMainPage />} />
                            <Route
                              path="apply"
                              element={<PLPSellerApplicationForm />}
                            />
                          </Route>


                          <Route path="settings" element={<SellerSettings />}> 
                            <Route index element={<SettingsMainPage />} />
                            <Route path="profile" element={<SellerProfile />} />
                            <Route path="business-profile" element={<SellerSettings />} >
                              <Route index element={<BusinessProfile />} />
                              <Route path="cac-document" element={<CACDocuments />} />
                              <Route path="business-basic-information" element={<BusinessBasicInformation />} />
                            </Route>
                            <Route path="security" element={<SellerSecurity />} />
                            <Route path="pickup-locations" element={<SellerPickupLocations />} /> 
                          </Route>  
                          <Route path="support" element={<SupportPage />} />
                        </Route>
                    </Routes>
                  </Suspense>
                    {/* seller module */}
 

                    {/* enterprise module */}
                    <Suspense fallback={<FullscreenLoader></FullscreenLoader>}>
                      <Routes>
                        <Route path='/enterprise' element={<ProtectedEnterprise></ProtectedEnterprise>}>

                          <Route index element={
                            tutorial===false && document.body.clientWidth >= MOBILE_BREAKING_POINT? 
                            <EnterpriseDashboard />: 
                            (document.body.clientWidth <= MOBILE_BREAKING_POINT? <EnterPriseMobileMenuTutorial />: <EnterpriseDashboard/>)
                            } />

                          <Route path='dashboard' element={
                            tutorial?
                            <EnterpriseDashboard/>:
                            (document.body.clientWidth <= MOBILE_BREAKING_POINT? <EnterPriseMobileMenuTutorial />: <EnterpriseDashboard/>)
                            } />

                          <Route path="mobile-menu-tutorial" element={<EnterPriseMobileMenuTutorial />} />
                      
                          <Route path='invoice' element={<EnterpriseInvoice />} />
                          <Route path='invoice/:invoiceId' element={<EnterpriseInvoiceDetails />} />
                          <Route path='make-offer' element={<EnterpriseMakeOffer />} />
                          <Route path='support-center' element={<EnterpriseContactUsPage />} />
                          <Route path='saved-list' element={<EnterpriseSavedListPage />} />
                          
                          <Route path='products' element={<EnterpriseProduct />} />
                          <Route path='product/:productUuid' element={<EnterpriseProductSingle />} /> 
                          <Route path='product/:productUuid' element={<EnterpriseProductSingle />} /> 
                          <Route path='cart' element={<EnterpriseCart />} /> 
                          <Route path='checkout' element={<EnterpriseCheckout />} />  
                          <Route path='order-completed' element={<EnterpriseOrderCompleted />} />  
                          <Route path='banking-and-finance' element={<EnterpriseBankingAndFinance />} />  
                          <Route path='order-management' element={<EnterpriseOrderManagement />} />  
                          <Route path='order-management-bulk' element={<EnterpriseBulkOrderManagement />} />  
                          <Route path='order-details/:orderId' element={<EnterpriseOrderDetails />} />  
                          <Route path='order-management/ship-to-site/detail/:deliveryRequestUuid' element={<EnterpriseWareHouseShipToSiteDetail />} />   
                          <Route path='delivery-orders' element={<EnterpriseWareHouseOrder />} />   
                          
                          
                          <Route path='user-management' element={<EnterpriseUserManagement />} />  
                          <Route path='user-management-addnew' element={<EnterpriseAddNewUser />} />  
                          <Route path='user-management-edit/:uuid' element={<EnterpriseEditUser />} />  
                          
                          <Route path='warehouse-management' element={<EnterpriseWareHouseManagement />} />   
                          <Route path='warehouse-management/:warehouseUuid' element={<EnterpriseWarehouseDetails module="estate"/>} />   
                          <Route path='warehouse-management/ship-to-site/:warehouseId' element={<EnterpriseWareHouseShipToSite />} />   
                          <Route path='warehouse-management/ship-to-site/checkout/:deliveryRequestUuid' element={<EnterpriseWareHouseShipToSiteCheckout />} />   
                          <Route path='warehouse-management/ship-to-site/request-success/:warehouseId/:siteId' element={<EnterpriseWareHouseShipToSiteRequestSent />} />   
                        
                          <Route path='wallet-history' element={<EnterpriseWalletHistory />} />   
                          
                          <Route path="plp" element={<EnterprisePLP />}>
                            <Route path="apply" element={<EnterprisePLPApplicationForm/>} />
                            <Route index element={<EnterprisePLPMainPage />} />
                          </Route>
    
                          <Route path='reorder' element={<EnterpriseReorder />} />   
                          <Route path='quote-request/:quoteId' element={<EnterpriseQuoteDetails />} />  
                          

                        <Route path="quote-request" element={<EnterpriseQuote />}>
                          <Route index element={<EnterpriseActiveRequest />} />

                          <Route path="active-request" element={<EnterpriseActiveRequest />} />

                          <Route path="pending-response" element={<EnterprisePendingResponse />}/>
                        </Route>

                          <Route
                            path="settings"
                            element={ 
                                <EnterpriseSettings /> 
                            }>

                            <Route index element={<EnterpriseSettingsMain />} />
                            <Route path="profile" element={<EnterpriseSettingsProfile />} />
                            <Route path="profile-business" element={<EnterpriseSettingsBusinessProfile />} />
                            
                            <Route path="notification" element={<EnterpriseSettingsNotification />} />
                            <Route path="security" element={<EnterpriseSettingsSecurity />} />
                            <Route path="address" element={<EnterpriseSettingsAddressBook />} />
                            <Route
                              path="bank-and-finance"
                              element={<EnterpriseSettingsBankAndFinance />}
                            />
                            
                            <Route
                              path="bank-and-finance/banking-and-finance-wallet-funding"
                              element={<MyAccountWalletFunding />}
                            />

                            <Route
                              path="bank-and-finance/banking-and-finance-wallet-withdrawal"
                              element={<MyAccountWalletWidthraw />}
                            />

                          </Route>

                          <Route path="notification" element={<EnterpriseNotification />} />
                          <Route path="payment-completed" element={<EnterprisePaymentCompleted />} /> 
                        </Route> 
                      </Routes>
                    </Suspense>
                    


                    {/* estate managers module */} 
                    <Routes> 
                        <Route path="estate-managers-setup" element={<EstateManagersSetup />} />
                        <Route path="estate-otp" element={<EstateManagersSetupOtp />} />
                        <Route path="estate-subscribers-setup" element={<EstateManagersSubscriberSetup />} />
                        <Route path="estate-subscribers-otp" element={<EstateManagersSubscriberSetupOtp />} />
                        <Route path="estate-subscribers-completed" element={<EstateManagersSubscriberSetupCompleted />} />
                        
                        <Route path="estate-home"  element={<EstateHome />} />
                    </Routes>
                    
                    <Suspense fallback={<FullscreenLoader></FullscreenLoader>}>
                      <Routes>
                          <Route path='/estate-managers' element={<ProtectedEstateManagers></ProtectedEstateManagers>}>

                            <Route index element={ <EstateManagersDashboard />  } /> 

                            <Route path="dashboard"  element={ <EstateManagersDashboard /> } /> 
                              
                            <Route path="project-management" element={<EstateProjectManagement />} /> 
                            <Route path="project-management/:projectUuid" element={<EstateProjectDetails />} /> 
                            <Route path="order-management" element={<EstateOrderManagement module="estate"/>} />  
                            <Route path='order-details/:orderId' element={<EnterpriseOrderDetails />} />  
                            <Route path="warehouse-management" element={<EnterpriseWareHouseManagement module="estate"/>} /> 
                            <Route path='warehouse-management/:warehouseUuid' element={<EnterpriseWarehouseDetails module="estate"/>} />   
                            <Route path='warehouse-management/ship-to-site/:warehouseId' element={<EnterpriseWareHouseShipToSite module="estate"/>} />   
                            <Route path='warehouse-management/ship-to-site/checkout/:deliveryRequestUuid' element={<EnterpriseWareHouseShipToSiteCheckout module="estate"/>} />   
                            <Route path='warehouse-management/ship-to-site/request-success/:warehouseId/:siteId' element={<EnterpriseWareHouseShipToSiteRequestSent module="estate"/>} />   
                          
                            <Route path="investor-management" element={<EstateInvestorManagement />} /> 
                            <Route path="investor-management/:investorUuid" element={<EstateInvestorManagementDetails />} /> 
                            
                            <Route path="mortgage-card" element={<EstateManagersMortgageCard />} /> 
                            
                            <Route path="my-account" element={<EnterpriseSettings />}> 
                                <Route index element={<EnterpriseSettingsMain module="estate"/>} />
                                <Route path="profile" element={<EnterpriseSettingsProfile module="estate"/>} />
                                <Route path="profile-business" element={<EnterpriseSettingsBusinessProfile module="estate"/>} />
                                
                                <Route path="notification" element={<EnterpriseSettingsNotification  module="estate"/>} />
                                <Route path="security" element={<EnterpriseSettingsSecurity module="estate"/>} />
                                <Route path="address" element={<EnterpriseSettingsAddressBook />} />
                                <Route
                                  path="bank-and-finance"
                                  element={<EnterpriseSettingsBankAndFinance module="estate"/>}
                                />
                                
                                <Route
                                  path="bank-and-finance/banking-and-finance-wallet-funding"
                                  element={<MyAccountWalletFunding />}
                                />

                                <Route
                                  path="bank-and-finance/banking-and-finance-wallet-withdrawal"
                                  element={<MyAccountWalletWidthraw/>}
                                />
                            </Route>

                            <Route path="settings" element={ <EnterpriseSettings />  }>

                                <Route index element={<EnterpriseSettingsMain module="estate"/>} />
                                <Route path="profile" element={<EnterpriseSettingsProfile module="estate"/>} />
                                <Route path="profile-business" element={<EnterpriseSettingsBusinessProfile module="estate"/>} />
                                
                                <Route path="notification" element={<EnterpriseSettingsNotification  module="estate"/>} />
                                <Route path="security" element={<EnterpriseSettingsSecurity module="estate"/>} />
                                <Route path="address" element={<EnterpriseSettingsAddressBook />} />
                                <Route
                                  path="bank-and-finance"
                                  element={<EnterpriseSettingsBankAndFinance module="estate"/>}
                                />
                                
                                <Route
                                  path="bank-and-finance/banking-and-finance-wallet-funding"
                                  element={<MyAccountWalletFunding />}
                                />

                                <Route
                                  path="bank-and-finance/banking-and-finance-wallet-withdrawal"
                                  element={<MyAccountWalletWidthraw/>}
                                /> 
                             </Route>

                             <Route path="banking-and-finance" element={<EstateBankingAndFinance />} /> 
                             <Route path="subscriber-projects" element={<EstateSubscriberProjects />} /> 
                             <Route path="subscriber-projects/:projectUuid" element={<EstateSubscriberProjectsDetails />} /> 
                             <Route path="subscription-details/:projectUuid/:numberOfSlot" element={<EstateSubscriptionDetails />} /> 
                             
                             <Route path="subscriber-portfolio" element={<EstateSubscriberPortfolio />} /> 
                             <Route path="subscriber-portfolio/:projectSubscriptionUuid" element={<EstateSubscriberPortfolioDetails />} /> 
                             
                             <Route path="subscriber-transactions" element={<EstateSubscriberTransaction />} /> 
                             <Route path="subscriber-transactions/:uuid" element={<EstateSubscriberTransactionDetails />} /> 


                             <Route path="subscriber-recurrent-payment" element={<EstateSubscriberRecurrentPayment />} /> 
                             <Route path="subscriber-recurrent-payment/:uuid" element={<EstateSubscriberRecurrentPaymentDetails />} /> 
                             
                             <Route path='support-center' element={<EnterpriseContactUsPage />} />
                             

                             <Route path="quote-request" element={<EnterpriseQuote />}>
                              <Route index element={<EnterpriseActiveRequest module="estate"/>} />

                              <Route path="active-request" element={<EnterpriseActiveRequest module="estate"/>} />

                              <Route path="pending-response" element={<EnterprisePendingResponse module="estate"/>}/>
                            </Route>
                            <Route path='quote-request/:quoteId' element={<EnterpriseQuoteDetails module="estate"/>} />  
                          
                             
                          </Route>
                      </Routes>
                    </Suspense>
                    
                    <Routes>
                      <Route path="settings" element={<EnterpriseSettings />} />
                      <Route path="reorder" element={<EnterpriseReorder />} /> 
                    </Routes>   
              </AuthProvider>
            </HelmetProvider>
          </BrowserRouter>
        </Provider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </Profiler>
    </QueryClientProvider>
  </React.StrictMode>
);
AOS.init();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { FC } from 'react'
import "./ProductSaveForLaterButton.scss"
import heartLight from "../../../../../Assets/Images/icon/heart-light.png"

interface propsType{
  color?:string
}

const ProductSaveForLaterButton:FC<propsType> = ({color}) => { 
  return (
    <button className="btn btn-save-later">
      {!color? <img src={heartLight}/> :
      <svg className="me-2 pe-2" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.62 20.8116C12.28 20.9316 11.72 20.9316 11.38 20.8116C8.48 19.8216 2 15.6916 2 8.69156C2 5.60156 4.49 3.10156 7.56 3.10156C9.38 3.10156 10.99 3.98156 12 5.34156C13.01 3.98156 14.63 3.10156 16.44 3.10156C19.51 3.10156 22 5.60156 22 8.69156C22 15.6916 15.52 19.8216 12.62 20.8116Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg> } Save for later</button>
  )
}

export default ProductSaveForLaterButton

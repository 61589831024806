import {FC} from "react";
import Avatar from "../../../../../../../Assets/Images/enterprise-avarter.svg";
import profilAavarter from "../../../../../../../Assets/Images/estate/profile-circle.png";

import "./ProfileAvatar.scss";
import ImageSrc from '../../../../../../../Components/ImageSrc/ImageSrc';

interface propType  {
    picture:string;
    module?:string;
}
const ProfileAvatar:FC<propType> = ({picture, module})=> {
    return (
        <div className={module && module==="estate"? "avatar-estate": "avatar"}>
            <ImageSrc src={module && module==="estate"? (picture || profilAavarter):  (Avatar || profilAavarter)} alt='' title={undefined} width={""}/>
        </div>
    )
}

export default ProfileAvatar;

import "./DocumentUploadCard.scss";
import {FC} from "react";


interface propTypes {
    title:string;
    description:string;
    value?:any;
    changeHandler?: any
    name:string;
    doc:string; 
    checkIfExist:any
}

const DocumentUploadCard:FC<propTypes> = ({title, description, value, changeHandler, name, doc, checkIfExist}) => {

    

    return (
        <div className={checkIfExist(doc) ? "document-upload w-100  border-danger": "document-upload w-100"}>
            <div className="document-title mt-3">{title}</div>
            <div className="document-desc  mt-3">{description}</div>
            <label className="mb-3 file-upload  mt-3" htmlFor={name}>
                <div  className="form-label file-upload-label mb-0">Add file</div>
                <input 
                    id={name}
                    type="file" 
                    accept="image/*" 
                    className="form-control file-upload-input" 
                    onChange={(event)=>changeHandler(event, doc)}
                    value={value}
                    name={name}
                />
            </label>
        </div>
    )
}

export default DocumentUploadCard;
import React from 'react'
import { NavLink, Outlet } from 'react-router-dom' 
import "./EnterpriseQuoteRequest.scss"
import { EnterprisePageTitle } from '../../../../../../../Components'

const EnterpriseQuoteRequest = () => {
  return (
        <div className='enterprise-quote-request'>
            <div className="container mt-4">
               <EnterprisePageTitle title={'My Quote requests'} parent={null} parentUrl={null}></EnterprisePageTitle>

                <ul className="nav sub-nav">
                    <li className="nav-item">
                      <NavLink className={({ isActive }) => isActive ? "nav-link active" : "nav-link"} to="active-request">Active request</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className={({ isActive }) => isActive ? "nav-link active" : "nav-link"} to="pending-response">Pending response</NavLink>
                    </li>
                </ul>
               <Outlet />
            </div>
        </div>
  )
}

export default EnterpriseQuoteRequest;


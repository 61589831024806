/* eslint-disable no-unused-vars */
import "./AddNewProduct.scss"
import SubPageTitle from "../../../Component/Settings/SubPageTitle/SubPageTitle"
import { useForm } from "react-hook-form"
import { Key, ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";
import productService from "../../../../../../../Services/productService";
import toast, { Toaster } from "react-hot-toast";
import brandService from "../../../../../../../Services/brandService";
import { CurrencyFormatter, FilterSearch } from '../../../../../../../Helpers';
import countryList from "../../../../../../../Data/country.json"
import state from "../../../../../../../Data/stateNG.json"
import lgas from "../../../../../../../Data/lgaNG.json" 
import ImgUpldIcon from "../../../../../../../Assets/Images/imgUpld.png"
import TrashIcon from "../../../../../../../Assets/Images/trash.svg"
import MultipleVariant from "../../../Component/Products/MultipleVariant/MultipleVariant";
import { useNavigate } from "react-router-dom";
import CreateBrandModal from "../../../../../Components/Seller/CreateBrandModal/CreateBrandModal";

interface IaddNewProduct {
  productName: string;
  description: string;
  category: string;
  brandUuid: string;
  minQty: string
  maxQty: string;
  country: any;
  state: any;
  localGovt: string;
  price: string
  categories: any | []

}

const AddNewProduct = () => {
  const navigate = useNavigate()

  const [show, setShow] = useState(false)
  const [categories, setCategories] = useState<any>([])
  const [brandSearch, setBrandSearch] = useState(true)
  const [loader, setLoader] = useState<boolean>(false)
  const [brandFilter, setBrandFilter] = useState<any>([])
  const [brands, setBrands] = useState<any>({
    data: [{}]
  })
  const [activeBrand, setActiveBrand] = useState({
    imageUrl: "",
    name: "",
    productsCount: "",
    uuid: ""
  })
  const [toggleBrandDropDown, setToggleBrandDropDown] = useState(false)
  const [activeCategory, setActiveCategory] = useState("");
  const [activeBrandUuid, setActiveBrandUuid] = useState("");
  const [country, setCountry] = useState<any>('')
  const [selectStates, setSelectedStates] = useState<any>([])
  const [selectLgas, setSelectedLgas] = useState<any>([])
  const [loadingBr, setLoadingBr] = useState(false)
  const [images, setImages] = useState<any>([])
  const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false)
  const [lgaPriceRow, setLgaPriceRow] = useState([
    { localGovernmentArea: "", price: "0" }
  ])
  const [showLgaPriceRow, setShowLgaPriceRow] = useState<boolean>(false)
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [uploadedProduct, setUploadedProduct] = useState(false)

  const [isVariantChecked, setIsVariantchecked] = useState(false)





  const { handleSubmit, register, formState: { errors } } = useForm<IaddNewProduct>()


  const handleOnChecked = () => {
    setIsVariantchecked(prev => !prev)

  }


  const togleBrDrop = () => {
    console.log('toggle down click')
    setToggleBrandDropDown(!toggleBrandDropDown)
    // const search = FilterSearch(brands, value);
    console.log("search STATEsk",)
    setBrandFilter(brands.data)
  }
  console.log('brandFilter: ', brandFilter)
  // eslint-disable-next-line no-unused-vars

  const handleActiveBrand = (e: any) => {
    console.log(e)
  }

  const getAllCategory = async () => {
    setLoader(true)
    productService.getAllCategory(1, 'ASC', 3).then((res: any) => {
      setLoader(false) 
      setCategories(res.data.data.dataset)
    }, (error: any) => {
      toast.error(error.message, { duration: 20000, className: 'bg-danger text-white' });
    })
  }

  const getBrandByCategory = async (catUuid: string) => { 
    setActiveCategory(catUuid)
    setLoadingBr(true)
    const isAddProduct = true;
    await brandService.getCategoryByBrand(catUuid, isAddProduct).then((data: any) => { 
      if (data.status && data.data !== null) {
        setBrands(data.data)
      } else {
        setLoadingBr(false)
        setBrands([]) 
      }
    }, (error: any) => {
      setBrands([])
      setLoadingBr(false)
      console.log("getCategoryByBrand error", error.response)
      //   dispatch({ type: BRAND_FAILURE, data: error.response.data.error })
    })
  }

  console.log('braaaaadss', brands)


  const handleOnChangeBrand = async (e: { target: { value: any; }; }) => {
    console.log("search categories", categories)
    const value = e.target.value;
    setActiveBrand({
      imageUrl: "",
      name: value,
      productsCount: "",
      uuid: ""
    })

    if (value.length > 2) {
      const search = FilterSearch(brands, value);
      console.log("search STATEsk", search)
      setBrandFilter(search)
      if (search.length >= 1 && value.length > 1) setBrandSearch(true)
      if (search.length === 0) setBrandSearch(false)
    } else {
      console.log("search STATEsd", brands)
      console.log("search STATEs vale", value.length)
      setBrandFilter(brands)
      if (value.length === 0) setBrandSearch(true)
      if (value.length > 2) setBrandSearch(false)
    }
  }

  const brandAction = (data: any) => {
    setActiveBrandUuid(data.uuid)
    handleActiveBrand(data);
    setActiveBrand(data);
    togleBrDrop()
  }


  const handleFileUpload = async (event: { target: any }) => {
    setLoadingPhoto(true)
    console.log(event.target.files[0]);

    setImages((prev: any) => [...prev, ...event.target.files])
    console.log(event.target.files[0].name);
    setLoadingPhoto(false)
  };

  // const startUpload = async (productUuid: any) => {
  //   console.log('upload', productUuid, images)
  //   const newImages = Array.of(images);
  //   const arraySize = images.length
  //   for (let i = 0; i < newImages.length; i++) {
  //     const formData = new FormData();
  //     // Update the formData object FilterSearch
  //     formData.append(
  //       "file",
  //       newImages[i]
  //     );
  //     await productService.file(productUuid, formData).then((res: any) => {
  //       console.log("FILE UPLOAD", newIimages[i])
  //       toast.success("Photo saved successfully", { duration: 20000, className: 'bg-white shadow-md text-secondary' });
  //       setLoadingPhoto(false)
  //       if (i === arraySize - 1) {
  //         toast.success("Upload completed", { duration: 20000, className: 'bg-success shadow-md text-white' });
  //         // handleShow(
  //         setTimeout(() => {
  //           window.location.reload()
  //         }, 3000)
  //       }
  //     }, (error: any) => {
  //       setLoadingPhoto(false)
  //       toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
  //       console.log(error.response)
  //       console.log(error)
  //     })
  //   }

  //   // const files = Array.from(images);
  //   // const formData = new FormData()

  //   // images.map( (file: any) => {
  //   //   formData.append('file', file)

  //   //     return productService.file(productUuid, file).then((res: any) => {
  //   //     toast.success("Photo saved successfully", { duration: 20000, className: 'bg-white shadow-md text-secondary' });
  //   //     setLoadingPhoto(false)
  //   //    navigate(-1)
  //   //   }, (error: any) => {
  //   //     setLoadingPhoto(false)
  //   //     toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
  //   //     console.log(error.response)
  //   //     console.log(error)

  //   //   })

  //   // });


  // }

  const startUpload = async (productUuid: any) => {
    const arraySize = images.length
    for (const [index, currentProductImage] of images.entries()) {
      const formData = new FormData();

      // Update the formData object FilterSearch
      formData.append(
        "file",
        currentProductImage
      );
      await productService.file(productUuid, formData).then((res: any) => {
        console.log("FILE UPLOAD", currentProductImage)
        toast.success("Photo saved successfully", { duration: 20000, className: 'bg-white shadow-md text-secondary' });
        setLoadingPhoto(false)
        if (index === arraySize - 1) {
          toast.success("Upload completed", { duration: 20000, className: 'bg-success shadow-md text-white' });
          // handleShow()
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        }
      }, (error: any) => {
        setLoadingPhoto(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
        console.log(error.response)
        console.log(error)
      })
    }
  }




  const removeImage = (i: number) => {
    const filteredItems = images.slice(0, i).concat(images.slice(i + 1, images.length))
    setImages(filteredItems)
  }

  const handleCountryChange = (e: any) => {
    const allState = state[2].data
    setSelectedStates(allState)
    const cont = JSON.parse(e.target.value)
    setCountry(cont)
  }

  const handleState = (e: any) => {
    const state_id = JSON.parse(e.target.value).id;
    const localGovt: any = lgas[2].data;
    const getLga: any = localGovt.filter((res: any) => Number(res.state_id) === Number(state_id))
    setSelectedLgas(getLga)
  }


  const handleAddFormRow = (e: any) => {
    e.preventDefault()
    const values = [...lgaPriceRow];
    values.push({ 
      localGovernmentArea: "",
      price: ""
    });
    setLgaPriceRow(values);
  };

  const handleRemoveFormRow = (index: number) => {
    const values = [...lgaPriceRow];
    values.splice(index, 1);
    setLgaPriceRow(values);
  };

  const handleFormRowInputChange = (index: number, event: { target: { name: string; value: string } }) => {
    const values: any[] = [...lgaPriceRow];
    const updatedValue: any = event.target.name;
    values[index][updatedValue] = event.target.value;
    setLgaPriceRow(values)
  };

  
  const onsubmit = handleSubmit((data: any) => {
    console.log('dataaaaaa ', data);
    processForm(data)
  })

  const processForm = async (data: any) => {
    if (data.brandUuid === "") {
      toast.error('Brand field cannot be empty', { duration: 20000, className: 'bg-danger text-white' });
      return 0
    }

    // serialize data
    const datas = {
      name: data.productName,
      description: data.description,
      categoryUuid: data.category,
      brandUuid: data.brandUuid,
      price: 0,
      locationState: JSON.parse(data.state).name,
      minQty: data.minQty,
      maxQty: data.maxQty,
      newPickupAddress: {
        contactPhoneNumber: '',
        contactFullName: '',
        state: JSON.parse(data.state).name,
        country: JSON.parse(data.country).name,
        address: '',
        name: ''
      },
      pickupAddressUuid: ""

    }
    console.log("data00", datas)

    setLoadingProduct(true)
    await productService.createProduct(datas)
      .then(async (res: any) => {
        setLoadingProduct(false)
        setUploadedProduct(res.data.data)
        console.log("Add product success", res)
        toast.success("Proccessing images", { duration: 20000, className: 'bg-white shadow-md text-secondary' });
        await startUpload(res.data.data.productUuid)
      }, (error: any) => {
        setLoadingProduct(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
        console.log("Add product error", error.response)
      })
  }



  console.log('catarr', categories)
  useEffect(() => {
    const abortController = new AbortController()
    // getBrand()
    getAllCategory()

    console.log('catarr', categories)
    return () => abortController.abort()
  }, [])

  return (

    <div className="add-new-product w-100">
      <SubPageTitle
        title="Add New Product"
        parents={[
          {
            title: "",
            url: "product"
          }
        ]}
      />
      <div className='container d-flex justify-content-center align-items-center '>
        <form className="form" onSubmit={onsubmit}>
          <section className="row d-flex justify-content-center align-items-center section-a">
            <div className="form-group col-7 col-lg-12  ">
              <label htmlFor="productName">Product Name</label>
              <input
                className=" input"
                id='productName'
                type="text"
                placeholder="e.g"
                {...register("productName", { required: "This field is required" })}
                {...errors.productName && <div className='text-danger'>{errors.productName.message}</div>}

              />
            </div>
            <div className="form-group col-7 col-lg-12">
              <label htmlFor="description"> Description</label>
              <input
                className=" input"
                id='description'
                type="text"
                placeholder="e.g"
                {...register("description", { required: "This field is required" })}
                {...errors.description && <div className='text-danger'>{errors.description.message}</div>}
              />
            </div>

            <div className="form-group col-7 col-lg-12">
              <label htmlFor="category"> Category</label>
              <select
                className=" input select"
                id='categoryUuid'
                placeholder="Select category"
                {...register("category", { required: "This field is required", onChange: (e) => getBrandByCategory(e.target.value) })}
                {...errors.category && <div className='text-danger'>{errors.category.message}</div>}
              >
                <option>Select category</option>
                {categories.length > 0 && categories?.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((res: any, index: Key) => {
                  return <option key={index} value={res.uuid}><input type='checkbox' />{res.name}</option>
                })}
              </select>
            </div>
            <div className="form-group dropdown-form-element col-7 col-lg-12 ">
              <div className="label-div d-flex justify-content-between w-100"><label htmlFor="brand"> Brand</label>  <span style={{ color: 'green', paddingRight: '9px', cursor: 'pointer' }} onClick={() => setShow(!show)}>
                <i className="fa fa-plus me-2"></i>
                Create Brand</span>
              </div>

              <select
                onClick={togleBrDrop}
                // value={activeBrand.name}
                className="dropdown-select input"
                id='brand'
                placeholder="Type or select brand from list"
                {...register("brandUuid", { required: "This field is required", onChange: (e) => handleOnChangeBrand(e) })}

                {...errors.brandUuid && <div className='text-danger'>{errors.brandUuid.message}</div>}
              >

                <option>Type or select brand from list</option>

                {brandFilter.length > 0 && brandFilter.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((res: any, index: Key) => {
                  return <option className="input-select" key={index} value={res.uuid} >{res.name}</option>
                })}

              </select> 
            </div> 
          </section>
          
          <section className=" section-b pe-3">
            <div className="row sub-section">
              <div className="col-7 col-lg-6 form-group">
                <label htmlFor="minQty"> Min Order Quantity</label>
                <input
                  className=" input"
                  id='minOrderQty'
                  type="number"
                  placeholder="e.g"
                  {...register("minQty", { required: "This field is required" })}
                  {...errors.minQty && <div className='text-danger'>{errors.minQty.message}</div>}
                />
              </div>
              <div className="col-7 col-lg-6 form-group">
                <label htmlFor="maxOrderQty"> Max Order Quantity</label>
                <input
                  className=" input"
                  id='maxQty'
                  type="number"
                  placeholder="e.g"
                  {...register("maxQty", { required: "This field is required" })}
                  {...errors.maxQty && <div className='text-danger'>{errors.maxQty.message}</div>}
                />
              </div>
            </div>
            <div className="row sub-section">
              <div className="col-7 col-lg-6 form-group">
                <label htmlFor="country"> Country</label>
                <select
                  className=" input"
                  id='country'
                  placeholder="e.g"
                  {...register("country", { required: "This field is required", onChange: (e: any) => handleCountryChange(e) })}
                // {...errors.country && <div className='text-danger'>{errors.country.message}</div>}
                >
                  <option>Country</option>
                  {countryList.countries.length > 0 && countryList.countries.map((res: any, index: Key) => {
                    return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                  })}
                </select>
              </div>
              <div className="col-7 col-lg-6 form-group">
                <label htmlFor="state"> State</label>
                <select
                  className=" input"
                  id='state'
                  placeholder="e.g"
                  {...register("state", { required: "This field is required", onChange: (e) => handleState(e) })}
                // {...errors.state && <div className='text-danger'>{errors.state.message}</div>}
                >
                  <option>State</option>
                  {selectStates.map((result: { id: string | number | readonly string[] | undefined; name: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }, index: Key | null | undefined) => {
                    return <option key={index} value={JSON.stringify(result)}>{result.name}</option>
                  }
                  )
                  }
                </select>
              </div>

              <div className="row sub-section">
                <div className="col-7 col-lg-6 form-group">
                  <label htmlFor="localGovt"> Local Govt Area</label>
                  <select
                    className=" input"
                    id='localGovt'
                    placeholder="e.g"
                    {...register("localGovt", { required: "This field is required" })}
                  // {...errors.localGovtArea && <div className='text-danger'>{errors.country.message}</div>}
                  >
                    <option>Local Govt Area</option>

                    {selectLgas.length > 0 && selectLgas.map((res: { name: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }, index: Key | null | undefined) => <option key={index}>{res.name}</option>)}
                  </select>
                </div>
                <div className="col-7 col-lg-6 form-group">
                  <label htmlFor="price"> Price </label>
                  <input
                    className=" input"
                    id='price'
                    placeholder="e.g"
                    {...register("price", { required: "This field is required" })}
                    {...errors.price && <div className='text-danger'>{errors.price.message}</div>}
                  >

                  </input>
                </div>
              </div>

              <div className={lgaPriceRow.length > 1 ? `container-add-location` : 'container-no-location'}>
                {lgaPriceRow.length > 0 &&
                  (

                    lgaPriceRow.map((field, index: number, array) => (
                      <> {Number(index) >= 1 &&
                        <div key={index} className={showLgaPriceRow && Number(index) >= 1 ? "row w-100 sub-section d-none d-flex justify-content-center align-items-center " : "row sub-section justify-content-center  d-flex align-items-center w-100 "}>

                          <div className={Number(index) >= 1 ? 'form-group col-7 col-lg-5 ' : 'form-group col-7 col-lg-7'}>
                            <label htmlFor="Local Govt Area"> Local Govt Area</label>
                            <select className='input' onChange={(event) => handleFormRowInputChange(index, event)} name="localGovernmentArea" >
                              {field.localGovernmentArea ? <option>{field.localGovernmentArea}</option> : <option>--choose Lga--</option>}
                              {selectLgas.length > 0 && selectLgas.map((res: { name: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }, index: Key | null | undefined) => <option key={index}>{res.name}</option>)}
                            </select>

                          </div>
                          <div className={Number(index) >= 1 ? 'form-group  col-7 col-lg-5  ' : 'form-group  col-7 col-lg-6'}>
                            <label htmlFor="price"> Price</label>
                            <input type="number" name="price" placeholder='Price for local government' className='input' onChange={(event) => handleFormRowInputChange(index, event)} value={field.price} />

                          </div>
                          <div className={Number(index) >= 1 ? 'trash-b pt-4 col-lg-1' : 'trash-a pt-4'}>
                            {index >= 1 && array.length > 1 && <span className="btn btn-sm border-0  btn-remove" onClick={() => handleRemoveFormRow(index)}> <img src={TrashIcon} /> </span>}
                          </div>
                        </div>}
                        {index === 0 && lgaPriceRow.length >= 2 && <div className="toggle-form-row w-100 d-flex justify-content-center align-items-center"><span className='btn btn-sm mt-2' onClick={() => setShowLgaPriceRow(!showLgaPriceRow)}>{!showLgaPriceRow ? "Hide" : "Show"}</span></div>}
                        {array.length - 1 === index &&
                          <div className='col-7 col-md-12 d-flex '>
                            <a href="" className=" add-new-price text-success" onClick={(e) => handleAddFormRow(e)}>
                              <i className="fa fa-plus text-success me-2"></i> Add Location
                            </a>
                          </div>}
                      </>

                    ))


                  )}
              </div>

            </div>

          </section>
          <div className="d-flex align-items-center mt-4">
            <input type="checkbox" checked={isVariantChecked} onChange={handleOnChecked} />
            <span className="p-1 ms-2">This product has multiple variants (i.e product options)</span>
          </div>
          {isVariantChecked && <MultipleVariant handleOnChecked={handleOnChecked} />}


          <div className="container-fluid px-0 uploadImg my-5 mb-2 pb-5">
            <h4 className='title'>{isVariantChecked ? `Upload Product Cover Photo` : `Upload Image`}</h4>
            <div className='desc my-2'>{isVariantChecked && `Photo displayed when no particular variantis selected. `}At least 1 image, must not exceed 5Mb each. </div>
            <div className="row d-flex w-100 align-content-center justify-content-between">
              <div className="col-2 img-cont ">
                <div className='add-img-btn '>
                  <label htmlFor="contained-button-file" ><img src={ImgUpldIcon} ></img></label>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  id="contained-button-file"
                  className='d-none'
                  onChange={handleFileUpload}
                />
                {loadingPhoto && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
              </div>
              <div className="col-10">

                <div className="row d-flex ">

                  {images.map((res: any, index: Key) => {
                    return <div className="col-3 ms-4 " key={index}>
                      <div className='uploadedImgCont bg-white'>
                        <img src={URL.createObjectURL(res)} className="prev-img" />
                        <span className='btn rounded-circle border-dark circle d-flex align-items-center justify-content-center' style={{ width: "20px", height: "25px" }} onClick={() => removeImage(Number(index))}> <i className='fa fa-times'></i></span>

                      </div>
                    </div>
                  })}
                </div>

              </div>
            </div>

          </div>
          <button className="submit-btn" onClick={onsubmit}>Create Product</button>
        </form>
      </div>


      <CreateBrandModal show={show} setShow={setShow} activeCategory={activeCategory} />
      <div className="w-100 d-flex align-items-center justify-content-center">
        <p className="footer" >Powered by Cinderbuild </p>
      </div>
      <Toaster></Toaster>
    </div>
  )
}
export default AddNewProduct
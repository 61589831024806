import React, { PropsWithChildren, useState } from 'react'
import "./CategoryDesktop.scss"
// import categoryImage from "../../../../../../Assets/Images/category/category-image.png"
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper";
import { useCategory } from '../../../../../../Hooks/Queries/Categories/useCategory'
import { ImageSrc } from '../../../../../../Components'

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from 'react-router-dom';

const CategoryDesktop = () => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)

  const pagination = {
    clickable: true,
    renderBullet: function (index:any, className:any) {
      // eslint-disable-next-line no-useless-escape
      return '<span class=\"' + className + '\">' + (index + 1) + '</span>';
    }
  }
  const [pageNumber,] = useState(1)
  const [sortOrder,] = useState("DESC")
  const [pageSize,] = useState(20)
  const { isSuccess, data: categoryList, isLoading } = useCategory(pageNumber, sortOrder, pageSize);
 
  function Box ({ children }: PropsWithChildren<unknown>) {
    return (
        <div
            style={{
              border: '1px solid #fff',
              display: 'block',
              lineHeight: 2,
              padding: '0rem',
              marginBottom: '0.1rem',
              width: "100%",
            //   height: 137,
            }}
        >
            {children}
        </div>
    )
  }

  return (
    <div className="category-desktop mb-md-5 mt-md-5">
    <div className="container my-4 mt-5">
      <div className="row section-header">
        <div className="col-8 col-md-10 ps-3">
        <h2>Categories</h2>
        </div>
        <div className="col-3 col-md-1 text-end d-none d-md-block"> <Link to="/categories" className="text-decoration-none d-flex justify-content-end mt-2 mb-md-4">See all</Link></div>
         <div className="col-4 col-md-1 d-flex justify-content-end mt-2 mb-md-4">
            <div className="carousel-control-container text-end position-relative">
              <button ref={(node) => setPrevEl(node)} className="carousel-control-prev" type="button">
                <span className="d-icon d-arrowhead-left islarge text-secondary" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button ref={(node) => setNextEl(node)} className="carousel-control-next bg-danger" type="button">
                <span className="d-icon d-arrowhead-right islarge text-secondary" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
      </div>
    </div>
     <div className="container">
     {isSuccess === true && categoryList && <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              pagination={pagination}
              // modules={[Pagination]}
              className="mySwiper"
              /// ////
              modules={[Pagination, Navigation]}
              // slidesPerView={3}
              // slidesPerGroup={3}
              loop={true}
              loopFillGroupWithBlank={true}

              navigation={{ prevEl, nextEl }}
            >
           {isSuccess && categoryList && categoryList?.map((res:{name:string, imageUrl:string, uuid:string}, index:React.Key) => <SwiperSlide key={index}>
            <div className="card product-card border-0 shadow-sm">
                 <div className="card-body p-0">
                    <ImageSrc src={res?.imageUrl} alt={res.name} title={res.name} width="product-image w-100"></ImageSrc>
                    <Link to={`/products-by-category/${res.uuid}`} className="btn category-btn shadow">{res.name}</Link>
                 </div>
               </div>
            </SwiperSlide>)}
      </Swiper>}

      {isLoading === true && <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              pagination={pagination}
              className="mySwiper"
              modules={[Pagination, Navigation]}
              loop={true}
              loopFillGroupWithBlank={true}

              navigation={{ prevEl, nextEl }}
            >
            { [...Array(5)].map((res:any, index:React.Key) => {
              return <SwiperSlide key={index}>
                <div className="card product-card border-0 shadow-sm">
                  <Skeleton wrapper={Box} count={1} height={200} width={"100%"} className="product-image w-100"/>
                  <Skeleton wrapper={Box} count={1} height={30} width={"100%"} className="btn category-name shadow"/>
                </div>
            </SwiperSlide>
            })}
            </Swiper>}

     </div>
 </div>
  )
}

export default CategoryDesktop

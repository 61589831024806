import { Outlet } from "react-router-dom";
import "./SellerSettings.scss";

const SellerSettings = () => {
    return (
        <div className="seller-settings">
            <div className="container pe-0">
                <div className="outlet">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default SellerSettings;

import React from 'react'
// eslint-disable-next-line no-unused-vars
import { Navigate, Outlet } from 'react-router-dom'
import BuyerWraper from '../../Pages/Skeleton/WrapperAuth/BuyerWraper/BuyerWraper' 

import { useAuth } from '../AuthProvider/AuthProvider'

const ProtectedBuyer = () => {

  const { user } = useAuth()  
  
  if (!user) { 
    return <Navigate to="/login" />
  } 
  // const patchAuth = () => {
 
  //   switch (user.isCooperate) {
  //     case false:
  //       return <BuyerWraper><Outlet/></BuyerWraper>
  //     case true:  
  //       return <Navigate to="/enterprise/dashboard" />
  //     default:
  //       return <Navigate to="/" />
  //   }
    
  // }
  
  // // user is not authenticated
  // switch (user.isSeller) {
  //   case false:
  //     return patchAuth()
  //   case true: 
  //     return <Navigate to="/seller/dashboard" />
  //   default:
  //     return <Navigate to="/" />
  // }


  const patchAuth = () => {
    switch (user.isSeller) {
      case true:
        return <Navigate to="/seller/dashboard" />
      case false:  
        return <BuyerWraper><Outlet/></BuyerWraper>
      default:
        return <Navigate to="/" />
    } 
  }
 
  // user is authenticated 

  console.log("user", user)
  switch (true) {
    case user.isInvestor:
      return <Navigate to="/estate-managers/dashboard" />
     case user.isDeveloper:
      return <Navigate to="/estate-managers/dashboard" />
     case user.isCooperate:
      return <Navigate to="/enterprise/dashboard" />
     default:
      return patchAuth()
  }
  
}

export default ProtectedBuyer;

import React, {FC} from 'react'

interface propsType {
    setToggleShow: (value:boolean)=>void;
    toggleShow: boolean;
}
const EyeIcon:FC<propsType>= ({setToggleShow, toggleShow}) => {
    return (
        <span className="ms-2 pe-auto" style={{ cursor: "pointer" }}>
            {toggleShow?  <i onClick={()=>setToggleShow(!toggleShow)} className="d-icon pe-auto d-eye-close text-white" style={{fontSize: "12px"}}></i> : 
            <i onClick={()=>setToggleShow(!toggleShow)} className="d-icon d-eye-open pe-auto text-white" style={{fontSize: "12px"}}></i>
            }
        </span>
    )
}

export default EyeIcon

import CallIcon from "../../../../../../../Assets/Images/call-calling.png";
import "./GiveUsACall.scss";
import NextIcon from "../../../../../../../Assets/Images/icon/next.svg"

const GiveUsACall = () => {
    return (
        <div className="give-us-a-call bg-white">
            <a className="w-full d-flex flex-column align-items-center"
                href="tel:+234898877666" target={"_blank"} rel="noreferrer"
            >
                <div className="call-icon-container">
                    <img src={CallIcon} alt='Call us' />
                </div>
                <p style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#333333",
                }}>Give us a call</p>
                <p style={{ fontSize: "16px", fontWeight: 400, color: "#333333" }}>+234898877666</p>
            </a>
            <span className="next-icon">
                <img src={NextIcon} alt='' />
            </span>
        </div>
    )
}

export default GiveUsACall;
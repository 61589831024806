import React, { useEffect, useState } from 'react' 
import "./EnterpriseOrderManagement.scss"  
import { EnterprisePageTitle, HorizontalLoader } from '../../../../../../Components';
import { useAllOrder } from '../../../../../../Hooks/Queries/Enterprise/useAllOrder';
import { CurrencyFormatter } from '../../../../../../Helpers/CurrencyFormatter'; 
import { Link } from 'react-router-dom';
import { TimeConverter } from '../../../../../../Helpers/TimeConverter';
 
const EnterpriseBulkOrderManagement = () => {

    const [isActive, setIsActive] = useState("all")

    useEffect(()=>{
        setIsActive("all")
    }, [isActive])

    const [sortOrder,] = useState("DESC")
    const [status,] = useState("ALL") 
    const [page,] = useState(1) 
    const [paymentVariantActive,] = useState<string|any>(null)
   
    const [persona,] = useState("BUYER")
     
    const {data:myOrders, isLoading:loadingRequest, isSuccess, refetch: getOrder } = useAllOrder(page, sortOrder, persona, status, paymentVariantActive)
    
    useEffect(() => {
        const abortController = new AbortController()
        getOrder()
        return () => { abortController.abort() }
      }, [page])

    return (
        <div className='enterpriseOrderManagement'>
             <div className="container">
                <EnterprisePageTitle
                    title="Bulk Order"
                    parent="Order Management"
                    parentUrl="order-management"
                    showTitle={false}
                />

                <div className="row mt-4">
                    <div className="col-md-10">
                        <div className="bulk-order-title">
                           <h5>Bulk Order </h5>
                            {/* <<h5>Bulk Order #10515</h5> */}
                            {/* <div>24/09/2022, 16:06:35</div> */}
                        </div>
                    </div>
                    <div className="col-md-2">
                        {/* <span className="processing-status">Processing</span> */}
                    </div>
                </div>
 
  


                <div className="enterprise-table">
                    <div className="card mt-4">
                        <div className="card-body p-0"> 
                            <div className="table-responsive">
                                <table className="table rounded border-0  mt-0"> 
                                    <thead className='rounded'>
                                        <tr className='d-none d-md-table-row'>
                                            <th scope="col"></th>
                                            <th scope="col">Product name</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Amount</th>  
                                            <th scope="col">Date</th>  
                                            <th scope="col"></th>  
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isSuccess && myOrders.length > 0 && myOrders.map((res:{
                                            referenceNumber:number,
                                            calculatedTotalCostMajor:number,
                                            currencySymbol:string,
                                            orderItems:any,
                                            status:any,
                                            orderUuid:string,
                                            createdAt:any,
                                            procurementInvoiceUuid:string
                                            }, index: React.Key | null | undefined) => {
                                            return (
                                                res.procurementInvoiceUuid !== null && <React.Fragment key={index}>
                                            <tr className='d-none d-md-table-row'>
                                                <td>
                                                    <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle opacity="0.15" cx="20" cy="20" r="20" fill="#F5A623"/>
                                                        <path d="M11.1699 15.4375L19.9999 20.5475L28.7699 15.4675" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M20 29.6091V20.5391" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M17.9296 10.48L12.5896 13.45C11.3796 14.12 10.3896 15.8 10.3896 17.18V22.83C10.3896 24.21 11.3796 25.89 12.5896 26.56L17.9296 29.53C19.0696 30.16 20.9396 30.16 22.0796 29.53L27.4196 26.56C28.6296 25.89 29.6196 24.21 29.6196 22.83V17.18C29.6196 15.8 28.6296 14.12 27.4196 13.45L22.0796 10.48C20.9296 9.84 19.0696 9.84 17.9296 10.48Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M24.9998 21.2416V17.5816L15.5098 12.1016" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </td> 
                                                <td>
                                                    {res.orderItems.length > 0 && res.orderItems.map((resInner:any, index:any) => {
                                                    return <React.Fragment key={index}>{ resInner.productName}{res.orderItems.length !== (index + 1) ? ", " : ""}</React.Fragment>
                                                    })}
                                                </td>
                                                <td>
                                                   {res.orderItems.length > 0 && res.orderItems.map((resInner: { quantity:number }) => {
                                                    return  resInner.quantity++
                                                    })}
                                                    </td> 
                                                <td>{res.currencySymbol} {CurrencyFormatter(res.calculatedTotalCostMajor)}</td> 

                                               <td> { TimeConverter(res.createdAt)}</td> 
                                               <td> 
                                                    <Link className="dropdown-item" to={`/enterprise/order-details/${res.orderUuid}`}>
                                                        <button type="button" className="btn btn-sm btn-pill border">
                                                            View
                                                        </button> 
                                                    </Link>
                                                </td>  
                                            </tr>  
                                            <tr className='d-md-none'>
                                                <td>
                                                <svg className='table-profile' width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle opacity="0.15" cx="20" cy="20" r="20" fill="#F5A623"/>
                                                        <path d="M11.1699 15.4375L19.9999 20.5475L28.7699 15.4675" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M20 29.6091V20.5391" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M17.9296 10.48L12.5896 13.45C11.3796 14.12 10.3896 15.8 10.3896 17.18V22.83C10.3896 24.21 11.3796 25.89 12.5896 26.56L17.9296 29.53C19.0696 30.16 20.9396 30.16 22.0796 29.53L27.4196 26.56C28.6296 25.89 29.6196 24.21 29.6196 22.83V17.18C29.6196 15.8 28.6296 14.12 27.4196 13.45L22.0796 10.48C20.9296 9.84 19.0696 9.84 17.9296 10.48Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M24.9998 21.2416V17.5816L15.5098 12.1016" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>  
                                                </td>
                                                <td colSpan={4}>
                                                    <div className="row"> 
                                                        <div className='col-12 profile-title'>{res.orderItems.length > 0 && res.orderItems.map((resInner:any, index:any) => {
                                                    return <React.Fragment key={index}>{ resInner.productName}{res.orderItems.length !== (index + 1) ? ", " : ""}</React.Fragment>
                                                    })}</div>
                                                        <div className='col-12 profile-detail'>{res.currencySymbol} {CurrencyFormatter(res.calculatedTotalCostMajor)}</div> 
                                                        <div className='col-12 profile-detail'>{TimeConverter(res.createdAt)}</div>
                                                    </div>
                                                </td>
                                                <td className='profile-btn'>

                                                    <Link className="dropdown-item" to={`/enterprise/order-details/${res.orderUuid}`}>
                                                        <button type="button" className="btn btn-sm p-1 border d-md-none">
                                                            View
                                                        </button> 
                                                    </Link>
                                                </td>   
                                            </tr>   
                                            </React.Fragment>) 
                                        })} 
                                            
                                          {loadingRequest === false && myOrders?.length === 0 &&  <React.Fragment>
                                            <tr className='d-none d-md-table-row'>
                                                <td colSpan={4} className="text-center"> No order found</td> 
                                            </tr> 
                                        </React.Fragment> }

                                        <React.Fragment>
                                           {loadingRequest === true && <tr>
                                            <td colSpan={5}>
                                                    <div className='text-secondary text-center fw-bold'><HorizontalLoader></HorizontalLoader></div>
                                                </td>
                                            </tr>} 
                                        </React.Fragment>
                                    </tbody> 
                                    <tfoot>  
                                    </tfoot>
                                </table>
                                </div>
                            </div>
                    </div>

                    {/* <div className="bulk-action">
                        <button className='btn order-details'>Order Details</button>
                        <button className='btn pay-now'>Pay Now</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default EnterpriseBulkOrderManagement

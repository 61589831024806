 

import "./BusinessProfileHeader.scss";
import { FC } from "react";
// import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
import SecuritySafeOutlineIcon from "../../../../../../../Assets/Images/icon/security-safe-outline.svg";
import SellerProfileAvatar from "../SellerProfileAvatar/SellerProfileAvatar";
import { Link } from "react-router-dom";

interface propType {
    businessName: string,
    bizLogo: string
}

const BusinessProfileHeader: FC<propType> = ({ businessName, bizLogo }) => {
    return (
        <div className="biz-profile-header card">
            <div className="biz-logo-name w-100 card-body"> 
                <div className="row">
                    <div className="col-4 col-md-3 my-auto"> 
                        <div className="avatar-container w-100">
                            <SellerProfileAvatar picture={bizLogo} />
                        </div>
                    </div>
                    <div className="col-7 col-md-5  my-auto">  
                        <div className="biz-info-container">
                            <div className="biz-name">{businessName}</div>
                            <div className="cacNo">CAC NO </div>
                            <div className="address">Address</div>
                        </div> 
                        
                        <Link to="business-basic-information" className="d-md-none">
                            <button className="update-btn justify-content-center"> 
                                Update Profile
                            </button>
                        </Link>
                    </div> 

                    <div className="col-7 col-md-4 d-none d-md-block my-auto">  
                        <Link to="business-basic-information">
                            <button className="update-btn justify-content-center">
                                <img src={SecuritySafeOutlineIcon} alt=''className="d-none" />
                                Update Profile
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default BusinessProfileHeader;
import { db } from '../Config/DBConfig';
 
type PropsType = "BUYER_BUY" | "CORPORATE_BUY" |  "CORPORATE_WALLET_FUNDING" | "CORPORATE_SITE_DELIVERY_FEE" | "CORPORATE_WALLET_FUNDING" | "BUYER_WALLET_FUNDING" | "UNAUTH_BUYER_BUY" | "BUYER_QUOTE_PAYMENT" | "CORPORATE_QUOTE_PAYMENT" | "ESTATE_QUOTE_PAYMENT"
 
export const savePaymentType = async (props: PropsType) =>{ 
    
    return await db.lastPayment.count().then(async (res)=>{

        if (Number(res) === 0) {    
          return  await db.lastPayment.add({type: props})  

        } else {  
          return await db.lastPayment.where("id").above(0).delete()
          .then( 
            async  () => await db.lastPayment.add({type: props}) 
          )

        } 

      })
     
}
 

 

import React from 'react'
import logo from "../../../Assets/Images/cinderBuild-logo.png"
import cbd_loader from "../../../Assets/Images/cbd_loader.png"

const FullscreenLoader = () => {
    return ( 
      <div className="mainLoader" style={{ height: "100vh", backgroundColor: "white" }}>
        <div className="row h-100 justify-content-center">
            <div className="col-4 col-md-2 my-auto text-center">
            <img src={logo} className="w-75"/>
            <img src={cbd_loader} className="w-75"/> 
            </div>
        </div> 
     </div>
    )
}

export default FullscreenLoader

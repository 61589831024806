import React, { FC, useEffect, useRef, useState } from 'react'
import "./EditProduct.scss" 
import { Modal } from 'react-bootstrap'
import { Logger } from '../../../../../../Helpers/Logger' 
import { CurrencyFormatter, FilterSearch, CalculateCommission } from '../../../../../../Helpers'; 
import brandService from '../../../../../../Services/brandService'
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form' 
import productService from '../../../../../../Services/productService'

import country from "../../../../../../Data/country.json"
import state from "../../../../../../Data/stateNG.json"
import { CreateVariation } from '../../Component'
import { useCategory } from '../../../../../../Hooks/Queries/Categories/useCategory';
import { useParams, useNavigate } from 'react-router-dom';
import { ErrorHandler } from '../../../../../../Helpers/ErrorHandler'
import ProductVariantList from '../../Component/CreateProduct/ProductVariantList/ProductVariantList'
import SellerSubPageTitle from '../../Component/SellerSubPageTitle/SellerSubPageTitle';
 
type productType = { 
    name:string,
    productName:string,
    minimumQuantity: number,
    maximumQuantity: number,
    productDescription: string,
    locationState: string,
    country: string,
    categoryUuid: string,
    brandUud: string,
    price: string,
    minQty: number,
    maxQty: number,
    description:string,
    images: any,
    productUuid: any,
    category:any;
    brand:any;
}

const EditProduct = () => { 
    
const { productUuid } = useParams<{ productUuid:string }>()

 
const { handleSubmit, register, setValue, formState: { errors } } = useForm<productType>()
 
const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false)  
const [loadingProduct, setLoadingProduct] = useState<boolean>(false)
 
const [checkMainPrice, setCheckMainPrice] = useState(0)
 
const [brands, setBrands] = useState([])
const [images, setImages] = useState<any>([])
 
const [selectStates, setSelectedStates] = useState<any>([])
const [hasVariant, setHasVariant] = useState(false)
 
const navigate = useNavigate()

// eslint-disable-next-line no-unused-vars
const [selectedCountry, setCountry] = useState<any>({
  id: "",
  sortname: "",
  name: "",
  phoneCode: ""
}) 
 
const [data, setData] = useState<productType>()

// eslint-disable-next-line no-unused-vars
const [loading, setLoading] = useState(false)

// eslint-disable-next-line no-unused-vars
const [loadingVariant, setLoadingVariant] = useState(false)
 
const [toggleBrandDropDown, setToggleBrandDropDown] = useState(false)

const [activeCategory, setActiveCategory] = useState("");
const [activeBrandUuid, setActiveBrandUuid] = useState("");

 
 
// holds all variation
const [variations, setVariations] = useState<any>([])
 

const [showBrandUpload, setShowBrandUpload] = useState(false);

const [brandImages, setBrandImages] = useState("null")

const [creatingBrand, setCreatingBrand] = useState(false)

 
const [pageNumber,] = useState(1)
const [sortOrder,] = useState("DESC")
const [pageSize,] = useState(25)

const { isSuccess, data: categories } = useCategory(pageNumber, sortOrder, pageSize)


const onsubmit = handleSubmit((data:any) => {
  processForm(data)
})

const processForm = async (data: any) => { 

  if (activeBrandUuid === "") {
    toast.error('Brand field cannot be empty', { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
    return 0
  }

  const varientElement = variations.length > 0 ? { tags: variations } : ""

  const newContact = data.contactPhoneNumber
    ? {
        newPickupAddress: {
          contactPhoneNumber: data.contactPhoneNumber,
          contactFullName: data.contactFullName,
          state: JSON.parse(data.locationState).name,
          country: JSON.parse(data.country).name,
          address: data.address
        }
      }
    : { pickupAddressUuid: data.pickupAddressUuid }

  // serialize data
  const datas = {
    name: data.name,
    description: data.description,
    categoryUuid: data.categoryUuid,
    brandUuid: activeBrandUuid,
    price: data.price ? data.price : 0,
    locationState: JSON.parse(data.locationState).name,
    minQty: data.minQty,
    maxQty: data.maxQty,
    ...newContact,
    ...varientElement, 
  } 


  setLoadingProduct(true)
  await productService.updateProduct(productUuid, datas)
    .then(async (res:any) => { 
        
      if (images.length === 0 && variations.length === 0) { 
          toast.success("Product updated successfully", { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
          setLoadingProduct(false) 

          setTimeout(() => {
            navigate("/seller/product", {replace: true})
         }, 3000);
      } 

      if (images.length > 0) { 
        await startUpload(productUuid)
      }  
      
      variations.length > 0 && await startCreatingVariant(productUuid, datas)

    }, (error:any) => {
      setLoadingProduct(false) 
      toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
     })
}

const handleFileUpload = async (event: { target: any }) => {
  setLoadingPhoto(true)  

  setImages([...images, ...event.target.files]) 

  setLoadingPhoto(false)
};

const startUpload = async (productUuid:any) => {  
  const arraySize = images.length
  for (const [index, currentProductImage] of images.entries()) {
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      currentProductImage
    );
    await productService.file(productUuid, formData).then((res:any) => {
      
      setLoadingPhoto(false)
      if (index === arraySize - 1) { 
        if (variations.length === 0) {

        setLoadingProduct(false) 
        toast.success("Product updated successfully", { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
         setTimeout(() => {
            navigate("/seller/product", {replace: true})
         }, 3000);
        }
      }
    }, (error:any) => { 

      setLoadingPhoto(false)
      toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
      Logger(error.response)
      Logger(error)
    })
  }
}

const startCreatingVariant = async (productUuid:any, data:any) => {
 
  const arraySize = variations.length
  for (const [index, currentVariant] of variations?.entries()) {
    const formData = {
      ...data,
      name: currentVariant.variable_title,
      tags: currentVariant, 
    }  
    await productService.variant(productUuid, formData).then(async (res:any) => {
       setLoadingPhoto(false)
      await startUploadVariantImage(res.data.data.productUuid, currentVariant.images, currentVariant.name)
      
    }, (error:any) => {
      setLoadingPhoto(false)
 
      if (index === arraySize - 1) { 
        setLoadingProduct(false) 
      }

      toast.error(`Variant upload error:, ${error.response.data.error}`, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
     })
  }
}

const startUploadVariantImage = async (productUuid:any, imageStack:any, variantName:string) => {
   
  // eslint-disable-next-line no-unused-vars
  for (const [index, currentVariantImage] of imageStack.entries()) {
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      currentVariantImage
    );

    await productService.file(productUuid, formData).then((res:any) => {
      
      // toast.success("Variant image saved successfully", { duration: 20000, className: 'bg-white shadow-md text-secondary', position: "top-right" });
      setLoadingPhoto(false)
      
    }, (error:any) => {
      setLoadingPhoto(false) 
      toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
      
    })
  }
}

const handleBrandFileUpload = async (event: { target: any }) => {
 
  setBrandImages(event.target.files[0])
};

const startBrandImageUpload = async (brandUuid:any) => {
  const formData = new FormData();

  // Update the formData object
  formData.append(
    "file",
    brandImages
  );

  console.log("brandImages", brandImages)

  await brandService.file(brandUuid, formData).then((res:any) => {
     setCreatingBrand(false)
    setShowBrandUpload(false)
  }, (error:any) => {
    setCreatingBrand(false)
    toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
    Logger(error.response)
  })
}

const handleCountryChange = (e:any) => {
  const allState = state[2].data

  setSelectedStates(allState)

  const cont = JSON.parse(e.target.value)
  setCountry(cont)
}

function checkPrice (value:any) {
  setCheckMainPrice(value)
}

const getBrandByCategory = async (catUuid:string) => {
  setActiveCategory(catUuid) 
  const isAddProduct = true;
  await brandService.getCategoryByBrand(catUuid, isAddProduct).then((data:any) => {
    if (data.data.status && data.data.data !== null) {
      setBrands(data.data.data)
    } else { 
      setBrands([]) 
    }
  }, (error:any) => {
    setBrands([]) 
    Logger("getCategoryByBrand error", error.response) 
  })
}

    const removeImage = (i:number) => {
        const filteredItems = images.slice(0, i).concat(images.slice(i + 1, images.length))
        setImages(filteredItems)
    }
 
 
    const togleBrDrop = () => {
        setToggleBrandDropDown(!toggleBrandDropDown)
    }
 

    const [brandFilter, setBrandFilter] = useState<any>([])

    const [activeBrand, setActiveBrand] = useState({
        imageUrl: "",
        name: "",
        productsCount: "",
        uuid: ""
    }) 
  
    const [productVariant, setProductVariant] = useState<any[]>([])

    
    const handleOnChangeBrand = async (e: { target: { value: any; }; }) => { 
        const value = e.target.value;
        setActiveBrand({
            imageUrl: "",
            name: value,
            productsCount: "",
            uuid: ""
        })
        if (value.length > 2) {
            const search = await FilterSearch(brands, value); 
            setBrandFilter(search) 
        } else { 
            setBrandFilter(brands) 
        }
    }

   
    const getProductVariant = async () => {
      setLoadingVariant(true)
      await productService.getProductVariant(productUuid).then((res:any) => {
        setProductVariant(res.data.data)
        setLoadingVariant(false) 
      }, (error:any) => {
        setLoadingVariant(false)
        ErrorHandler(error,
          () => {
            toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
          })
      })
    }
  
    useEffect(() => {
      getProductVariant()
    }, [])
 
  const getProduct = async (productUuid:string) => {
    setLoading(true)
    await productService.getProduct(productUuid).then((res:any) => {
      setLoading(false)
      setData(res.data.data) 
    }, (error:any) => {
      toast.error(error.message, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
      setLoading(false)
    })
  }

  useEffect(() => {
    if (productUuid) {
      getProduct(productUuid)
    }
    window.scrollTo(0,0)
  }, [productUuid])



    const startCreatingBrand = async () => {
        setCreatingBrand(true)
        const toastId = toast.loading("Adding new brand, please wait", { className: 'bg-white shadow-md text-dark', position: "top-right" })
        await brandService.createBrand(activeCategory, activeBrand.name).then(async (data:any) => {
            Logger("createBrand", data.data.data)
            setActiveBrandUuid(data.data.data.uuid)

            await startBrandImageUpload(data.data.data.uuid)

            toast.dismiss(toastId);
            toast.success("Brand added", { duration: 20000, className: 'bg-white shadow-md text-dark', position: "top-right" });
        }, (error:any) => {
            setCreatingBrand(false)
            toast.dismiss(toastId);
            Logger("createBrand error", error.response)
            toast.error(error.response.data.error, { duration: 20000, className: 'bg-white shadow-sm text-dark', position: "top-right" });
        })
    }

    const brandAction = (data:any) => {
        setActiveBrandUuid(data.uuid)  
        togleBrDrop()
    }
  
     const fileElement = useRef<any>(null)

     const selectUpload = () => { 
      console.log(fileElement)
      fileElement.current.click()
     }
 
     useEffect(() => {
      if (data) { 
        setValue('name', data.productName)
        setValue('price', data.price)
        setValue('minQty', data.minimumQuantity)
        setValue('maxQty', data.maximumQuantity)
        setValue('description', data.productDescription)
        setValue('locationState', data.locationState) 
      }
    }, [data])


    useEffect(() => { 
       if (data) {
          setDefaultImages(data?.images || []) 
       }
    }, [data])
  
    const [defaultImages, setDefaultImages] = useState([])

    const deleteImage = async (keyFromCloudProvider:string, productUuid:string, i:number) => {
      setLoadingPhoto(true)
      await productService.deleteProductImage(productUuid, keyFromCloudProvider).then(() => {
        const filteredItems = defaultImages.slice(0, i).concat(defaultImages.slice(i + 1, defaultImages.length))
        setDefaultImages(filteredItems)
        setLoadingPhoto(false)
        toast.success("Image deleted successfully", { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
      }, (error:any) => {
        setLoadingPhoto(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
        Logger(error.response)
      })
    }


    return (
        <div className='editProduct'>
            <div className='container'>
             <SellerSubPageTitle
                title="Edit Product"
                parents={[
                {
                    title: "Products",
                    url: "product"
                }
                ]}
            />
            </div>


            <div className="container mb-5">
                <div className="form-container">
                    <form onSubmit={onsubmit}>
                        <div className="form-input">
                            <label htmlFor="name">Product Name</label>
                            <input type="text" id="name" className="form-control" {...register("name", { required: "This field is required" })}/>
                            {errors.name && <div className='text-danger'>{errors.name.message}</div>}
                        </div>
                        <div className="form-input mt-3">
                            <label htmlFor="description">Description</label>
                            <input type="text" id="description" className="form-control" {...register("description", { required: "This field is required" })}/>
                            {errors.description && <div className='text-danger'>{errors.description.message}</div>}
                        </div> 

                        <div className='form-group'>
                          <label>Category</label> 
                          { data && <select className='form-control' id="category" placeholder='Select category'  {...register("categoryUuid", { required: "This field is required", onChange: (e) => getBrandByCategory(e.target.value) })}>
                              { data && <option value={data?.category.uuid}>{data?.category.name}</option> }
                              {isSuccess === true && categories?.sort((a:any, b:any) => a.name.localeCompare(b.name)).map((res:any, index:React.Key) => {
                                  return <option key={index} value={res.uuid}>{res.name}</option>
                                })} 
                          </select>}
                          {errors.categoryUuid && <div className='text-danger'>{errors.categoryUuid.message}</div>}
                      </div>


                        <div className="form-input mt-3">
                            <div className="row">
                                <div className="col-md-6 text-start"> 
                                  <label htmlFor="brand">Brand</label> 
                                </div>
                                <div className="col-md-6 text-end my-auto">
                                  <div className="create-brand">
                                    <button type="button" className="btn p-0" onClick={()=> setShowBrandUpload(true)}> 
                                       <span><i className="d-icon d-add mt-1"></i> </span>
                                       <span>Create Brand</span>
                                    </button>
                                  </div>
                                </div>
                            </div>  
                            <div className="dropdown-form-element">
                                <div className="dropdown-select">
                                    <input type="text"  id="brand"  onClick={togleBrDrop} className="form-control" value={activeBrand.name !== '' ? activeBrand.name : data?.brand?.name} placeholder="Type or select brand from list" {...register("brandUud", { required: "This field is required", onChange: (e) => handleOnChangeBrand(e) })}></input> 
                                </div>
                                {toggleBrandDropDown && brandFilter.length > 0 && <ul className="dropdown-list shadow border rounded">
                                    {brandFilter.length > 0 && brandFilter.sort((a:any, b:any) => a.name.localeCompare(b.name)).map((res:any, index:React.Key) => {
                                        return <li key={index} className="dropdown-item" onClick={() => brandAction(res)}>{res.name}</li>
                                    })}
                                </ul>}
                               {errors.brandUud && <div className='text-danger'>{errors.brandUud.message}</div>}
                          </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6"> 
                                <div className="form-input mt-3">
                                    <label htmlFor="min">Min Order Quantity</label>
                                    <input type="number" id="min" {...register("minQty", { required: "This field is required" })} className="form-control" />
                                    {errors.minQty && <div className='text-danger'>{errors.minQty.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-input mt-3">
                                    <label htmlFor="max">Max Order Quantity</label>
                                    <input type="number" id="max" className="form-control"  {...register("maxQty", { required: "This field is required" })}/>
                                    {errors.maxQty && <div className='text-danger'>{errors.maxQty.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-input mt-3">
                                    <label htmlFor="country">Country</label> 
                                    <select className='form-control' {...register("country", { required: "This field is required", onChange: (e:any) => handleCountryChange(e) })}>
                                          <option>--Country--</option>
                                          {country.countries.length > 0 && country.countries.map((res:any, index:React.Key) => {
                                            return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                                          })}
                                      </select>
                                      {errors.country && <div className='text-danger'>{errors.country.message}</div>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-input mt-3">
                                    <label htmlFor="state">State</label> 
                                    <select className='form-control' id="state" {...register("locationState", { required: "This field is required"})}>
                                        <option>--State--</option>
                                        {selectStates.map((result: { id: string | number | readonly string[] | undefined; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => {
                                          return <option key={index} value={JSON.stringify(result)}>{result.name}</option>
                                        })}
                                    </select>
                                    {errors.locationState && <div className='text-danger'>{errors.locationState.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6 d-none">
                                <div className="form-input mt-3">
                                    <label htmlFor="lga">Local Govt. Area</label>
                                    <input type="text" id="lga" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-input mt-3">
                                    <label htmlFor="price">Price</label> 
                                    <input type="number" id="price" className='form-control' defaultValue={0} placeholder='Price (N)' {...register("price", { onChange: (e:any) => checkPrice(e.target.value) })}></input>
                                    {checkMainPrice !== 0 && <div className='commissionText'>Price shown to buyer: {CurrencyFormatter(Number(checkMainPrice) + Number(CalculateCommission(checkMainPrice)))}</div>}
                                    {errors.price && <div className='text-danger'>{errors.price.message}</div>}
                                </div>
                            </div> 
                            <div className="col-md-6"></div>
                            <div className="col-md-6"> 
                               <div className="form-input mt-3 multi-variant">
                                    <label htmlFor="variantOption">
                                       <input type="checkbox" id="variantOption"  onChange={()=>setHasVariant(!hasVariant)}/>
                                       <span>Add new variant (i.e product options)</span>
                                    </label>
                                </div>
                            </div>

                            {hasVariant ===true && <CreateVariation setVariationParient={(value:any[])=>setVariations(value)} setImages={(value:any)=>setImages([value])}></CreateVariation>}


                            <div className="col-12">
                                <hr />
                            </div>

                            <div className="col-12">
                              {productVariant.length > 0 && <div className='mt-5'>
                                  <ProductVariantList ProductVariantList={productVariant} loadingVariant={loadingVariant}/>
                              </div>}
                            </div>

                            <div className="col-12">
                                <hr />
                            </div>

 
                            <div className="col-md-8">
                                <div className="upload-container mt-4">
                                    <div className='title'>Upload Product Cover Photo</div>
                                    <div className='instruction'>Photo displayed when no particular variant is selected. At least 1 Image, must not exceed 5 Mb each</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                             </div>
                           
                             <div className="col-md-3">
                                <div className="upload-container mt-3">
                                    <div className="card upload-card">
                                        <div className="card-body" onClick={selectUpload}>
                                            <label htmlFor="containedButtonFile">
                                              <div>
                                                  <UploadSvg></UploadSvg>
                                                  <div className="text">
                                                      Drag and drop, or browse your files
                                                  </div>
                                              </div>

                                              <input
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                id="containedButtonFile"
                                                className='d-none'
                                                onChange={handleFileUpload}
                                                ref={fileElement}
                                              /> 
                                            </label>
                                            {loadingPhoto && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            {images.map((res:any, index:React.Key) => {
                                return <div className="col-6 col-md-3" key={index}>
                                    <div className='uploadedImgCont card mb-1 p-0 mt-3'>
                                        <div className="card-body p-0 text-center">
                                          <button type="button" className='btn rounded-circle p-1' onClick={() => removeImage(Number(index))}> <i className='fa fa-times text-secondary'></i></button>
                                          <div className="image-wrap">
                                            <img src={URL.createObjectURL(res)} className="w-75 px-3"></img>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            })} 
                             {defaultImages.length > 0 && defaultImages?.map((res:any, index:React.Key) => {
                                return <div className="col-6 col-md-3" key={index}>
                                <div className='uploadedImgCont card mb-1 p-0 mt-3'>
                                    <div className="card-body p-0 text-center">
                                      <button className='btn rounded-circle p-1' onClick={() => { deleteImage(res.keyFromCloudProvider, data?.productUuid, Number(index)); }}>
                                         <i className='fa fa-times text-dark'></i></button>
                                         <div className="image-wrap">
                                            <img src={res.url} className="w-100"></img>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              })}

                            <div className="col-md-12">
                                <div className="upload-button">
                                  {!loadingProduct && (<button type="submit" className="btn btn-create" disabled={(images.length === 0 && defaultImages.length === 0)}>Save Product</button>)}
                                  {loadingProduct && (<button type="submit" className="btn btn-create" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  </button>)} 
                                </div>
                            </div>
                            
                            <div className="col-md-8"></div>
                        </div>
                    </form>
                </div>
            </div>


            <Modal show={showBrandUpload} onHide={() => setShowBrandUpload(false)} contentClassName="createBrandModal">
                <Modal.Header closeButton>
                <Modal.Title>Create Brand</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form  >  
                    <div className="form-input"> 
                        <label className=' mb-2'>Brand name</label> 
                        <input type="text" onClick={togleBrDrop} className="form-control" value={activeBrand.name} placeholder="Type or select brand from list" {...register("brandUud", { required: "This field is required", onChange: (e) => handleOnChangeBrand(e) })}></input> 
                    </div> 
                    
                    <label className=' mt-4'>Brand Logo</label>
                    <div className={brandImages !=='null'? 'border border-success card brand-upload mt-2' :'card brand-upload mt-2'}> 
                        <div className='card-body text-center'>
                           <label htmlFor='uploadBrandImg'>
                                <div className="icon">
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.25">
                                            <path d="M57.2401 23.3535C57.5384 23.9782 57.0578 24.6654 56.3655 24.6654H6.33398C5.7817 24.6654 5.33398 24.2176 5.33398 23.6654V17.1187C5.33398 10.612 10.614 5.33203 17.1207 5.33203H23.3073C27.654 5.33203 29.014 6.74536 30.7473 9.06536L34.4807 14.0254C35.3073 15.1187 35.414 15.252 36.9607 15.252H44.4007C50.0581 15.252 54.9507 18.5585 57.2401 23.3535Z" fill="#292D32"/>
                                            <path d="M58.6174 29.6607C58.6155 29.1097 58.1683 28.6641 57.6174 28.6641L6.33397 28.665C5.78169 28.665 5.33398 29.1127 5.33398 29.665V44.3983C5.33398 52.265 11.734 58.665 19.6007 58.665H44.4007C52.2673 58.665 58.6673 52.265 58.6673 44.3983L58.6174 29.6607ZM38.6673 44.665H34.1607V49.3317C34.1607 50.425 33.254 51.3317 32.1607 51.3317C31.0407 51.3317 30.1607 50.425 30.1607 49.3317V44.665H25.334C24.2407 44.665 23.334 43.7583 23.334 42.665C23.334 41.5717 24.2407 40.665 25.334 40.665H30.1607V35.9983C30.1607 34.905 31.0407 33.9983 32.1607 33.9983C33.254 33.9983 34.1607 34.905 34.1607 35.9983V40.665H38.6673C39.7607 40.665 40.6673 41.5717 40.6673 42.665C40.6673 43.7583 39.7607 44.665 38.6673 44.665Z" fill="#292D32"/>
                                        </g>
                                    </svg> 
                                </div>

                                <div className="detail">
                                    Drag and drop, or browse your files
                                </div>  
                            </label>

                           <input type="file" id="uploadBrandImg" className="d-none" onChange = {(e) => handleBrandFileUpload(e) }></input>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                    {!creatingBrand && <button className="btn apply-outline-success" disabled={brandImages === "null"} onClick={startCreatingBrand}>
                       Create
                    </button>}

                    {creatingBrand && <button className="btn apply-outline-success">
                    <span className='spinner-border spinner-border-sm'></span> Creating brand...
                    </button>} 
                    </div> 
                </form>
                </Modal.Body>

                </Modal>
                <Toaster></Toaster>
          {/* {progressStart === true && <CreatingLoading progress={progress} progressCompleted={progressCompleted} progressError={progressError} setProgressStart={setProgressStart} setProgressError={setProgressError}/>} */}
        </div>
    )
}

export default EditProduct

export const UploadSvg:FC = () =><svg className="upload-img" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.25">
    <path d="M44.0393 33.6405L37.7793 19.0005C36.6393 16.3205 34.9393 14.8005 32.9993 14.7005C31.0793 14.6005 29.2193 15.9405 27.7993 18.5005L23.9993 25.3205C23.1993 26.7605 22.0593 27.6205 20.8193 27.7205C19.5593 27.8405 18.2993 27.1805 17.2793 25.8805L16.8393 25.3205C15.4193 23.5405 13.6593 22.6805 11.8593 22.8605C10.0593 23.0405 8.5193 24.2805 7.4993 26.3005L4.0393 33.2005C2.7993 35.7005 2.9193 38.6005 4.3793 40.9605C5.8393 43.3205 8.3793 44.7405 11.1593 44.7405H36.6793C39.3593 44.7405 41.8593 43.4005 43.3393 41.1605C44.8593 38.9205 45.0993 36.1005 44.0393 33.6405Z" fill="#292D32"/>
    <path d="M13.9377 16.7622C17.6712 16.7622 20.6977 13.7356 20.6977 10.0022C20.6977 6.26874 17.6712 3.24219 13.9377 3.24219C10.2043 3.24219 7.17773 6.26874 7.17773 10.0022C7.17773 13.7356 10.2043 16.7622 13.9377 16.7622Z" fill="#292D32"/>
    </g>
</svg>




 
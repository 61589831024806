import React, { useEffect } from 'react'

import "./PrivacyPolicy.scss"
import { FooterLarge, Header } from '../../../../Layout' 

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <Header></Header>
        <div className='privacyPolicy my-5'>
             <div className='container'>
            <div className="row justify-content-center">
                <div className="col-md-9 bg-white p-4">

                <h1 className='fw-bold mb-4'>PRIVACY POLICY</h1>
                <p>Last Updated July 2022&nbsp;</p>
                <p><br/></p>
                <p>CinderBuild Inc (&ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo; &ldquo;CinderBuild&rdquo;, &ldquo;Company&rdquo;), we recognize your privacy is important. This Policy explains our privacy practices on our website [<a href="https://www.cinderbuild.com">https://www.cinderbuild.com</a>] (the &ldquo;Site&rdquo;) and our mobile applications (collectively &ldquo;the Platforms&rdquo;). This Policy also explains what information we collect about our customers and how we use this information.&nbsp;</p>
                <p><br/></p>
                <p>This Privacy Policy is designed to be read in connection with the Terms &amp; Conditions. By accessing or using our Platforms, you hereby agree with the method of collection, use, disclosure and processing of your Personal Information as described in this Privacy Policy and the Terms &amp; Conditions.</p>
                <p><br/></p>
                <p>For the purposes of clarity, the &ldquo;Services&rdquo; refer to the use of any of the digital services or products on any of the Platforms. &ldquo;User,&rdquo; &ldquo;customer,&rdquo; or &ldquo;subscriber,&rdquo; refers to product marketers, buyers, sellers or any consumers of the Services. &ldquo;Personal Information,&rdquo; or &ldquo;Personal Data,&rdquo; means any information that identifies or can be used to identify a User, directly or indirectly, including, but not limited to name, email address, mobile number, photograph, bank details or IP address.</p>
                <p><br/></p>
                <p>Information Content of the Register</p>
                <p>Your use of our Services, especially registration-only Services on our Platforms may store User information in the register.&nbsp;</p>
                <p><br/></p>
                <p>The following information may be stored in the register:</p>
                <ul>
                    <li>
                        <p>Personal details: Name, email address, phone number, street address</p>
                    </li>
                    <li>
                        <p>Account details: username, password (stored in encrypted format)</p>
                    </li>
                    <li>
                        <p>The description text that the user may write about him/herself</p>
                    </li>
                    <li>
                        <p>The offers and requests the user has posted to the service</p>
                    </li>
                    <li>
                        <p>The given and received feedback and badges</p>
                    </li>
                    <li>
                        <p>Statistical data about service usage, e.g number times the user has logged in</p>
                    </li>
                </ul>
                <p><br/></p>
                <p>Collection of Personal Information</p>
                <p>All information supplied by Users of the Services as defined under the Terms &amp; Conditions is covered by the extant laws and regulations regulating the use and management of personal data.</p>
                <ul>
                    <li>
                        <p>Voluntarily Submitted Data: When you sign up for the Company&rsquo;s Services, make payment for the services, consult with our customer service team, send us an email or communicate with us in any way, you are voluntarily giving us information that we process, including, but not limited to; name, username, email address, mobile number, IP address, credit card information, bank information, and purchase history. By submitting this information, you consent to its collection, usage, disclosure, and storage by us, as described in our Terms &amp; Conditions and in this Privacy Policy.</p>
                    </li>
                </ul>
                <p><br/></p>
                <ul>
                    <li>
                        <p>Automatically Collected Data: When you use the Services or browse any of our Platforms, we may collect information about your visit, your usage of the Services, and/or your web browsing; which may include your IP address, operating system, device type, operating system, browser ID, browsing activity, and other information about how you interacted with our Websites or Mobile Applications. We may collect this information as a part of log files or through the use of cookies or other tracking technologies.</p>
                    </li>
                </ul>
                <p><br/></p>
                <ul>
                    <li>
                        <p>Service Usage Data: We may receive information about how and when you use the Services, store it in log files or other types of files associated with your account, and link it to other information we collect about you. This information may include, for example, your IP address, time, date, browser used, and actions you have taken within the Website or Mobile Applications. This type of information helps us to improve our Services for both you and for all of our users.</p>
                    </li>
                </ul>
                <p><br/></p>
                <ul>
                    <li>
                        <p>Cookie Data: The Site uses cookies. Cookies are text files containing small amounts of information which are downloaded to your device when you visit a website. Cookies are useful because they allow a website to recognize a User&rsquo;s device. And, to do a lot of other tasks, you may see examples below. You can find more information about cookies at: allaboutcookies.org and youronlinechoices.eu&nbsp;</p>
                    </li>
                </ul>
                <p><br/></p>
                <p>We use strictly necessary cookies, analytical/performance, functionality and targeting/advertising cookies on the Platforms. These cookies may allow:</p>
                <ol>
                    <li>
                        <p>Strictly necessary &ndash; for us to remember of any of the features and services you use;</p>
                    </li>
                    <li>
                        <p>Functionality - us to remember choices you make to improve your experience;</p>
                    </li>
                    <li>
                        <p>Advertising or targeting - third party advertising companies to display advertisements more relevant to you and your interests. As part of their services, they will place a separate cookie on your computer to help them precisely target advertising to you. These third-party advertising companies do not collect personally identifiable information and;</p>
                    </li>
                    <li>
                        <p>Analytical or Performance &ndash; for us or/and third-party partners collect anonymous information including but not limited to performance and website improvement. This may include web analytics, error management and testing designs.</p>
                    </li>
                </ol>
                <p>You can find out more about cookies at this site: allaboutcookies.org/manage-cookies.</p>
                <p><br/></p>
                <p>Mobile Application Data</p>
                <p>When you use our Mobile Applications, we may collect certain information in addition to information described elsewhere in this Policy. For example, the type of device and operating system you use. We may ask you if you want to receive push notifications. If you have opted in to these notifications and no longer wish to receive them, you may turn them off through your device&rsquo;s operating system or send an email to&nbsp;<a href="mailto:cb_support@cinderbuild.com">cb_support@cinderbuild.com</a>. We may use mobile analytics software to better understand how people use our application. We may collect information about how often you use the application and other performance data.</p>
                <p><br/></p>
                <p>Accuracy of Personal Information</p>
                <p>We will use commercially reasonable efforts to keep your Personal Information as provided by you accurate for the identified purposes.&nbsp;</p>
                <p><br/></p>
                <p>Users are responsible for informing us about any changes to their Personal Information from time to time. Users will be informed that in order to implement the measurements of verification of information provided by the user, we may request you to provide copies of documents related to your Personal Information including but not limited to an official ID Card, bank verification number, etc.</p>
                <p><br/></p>
                <p>Users represent that all Personal Information provided to us is accurate for the identified purpose.</p>
                <p><br/></p>
                <p>Limiting of Personal Information</p>
                <p>The Company may limit its collection of Personal Information only to the extent that the information is unnecessary for the identified purposes. The Company does not direct the Platforms to, nor does it knowingly collect any Personal Information from persons under the age of eighteen (18 years). Consequently, the Company shall not be liable for any use or processing of Personal Information of persons under the age of eighteen.&nbsp;</p>
                <p><br/></p>
                <p>Purpose for Collecting Personal Information</p>
                <p>We collect Personal Information for the following reasons:</p>
                <p><br/></p>
                <p>For Promotional Purposes: This includes sending you emails relating to our services or brand. You can stop receiving our promotional emails by following the unsubscribe instructions included in every email we send, or by adjusting your Marketing Preferences in your profile.</p>
                <p><br/></p>
                <p>For Billing Purposes: This includes sending you emails, invoices, receipts, notices of delinquency, and alerting you if we need a different credit card number. We use third parties for secure credit card transaction processing, and we send billing information to those third parties to process your orders and credit card payments. This data is processed in accordance with consent and third-party data processing contract requirements under extant data protection laws.</p>
                <p><br/></p>
                <p>To Provide and Improve Our Services. This includes, for example, aggregating information from your use of the Services or visit to our Platforms and sharing this information with third parties to improve our Services. This might also include sharing your information with third parties in order to provide and support our Services. When we do have to share Personal Information with third parties, we take steps to protect your information by requiring these third parties to enter into a contract with us that requires them to use the Personal Information we transfer to them in a manner that is consistent with this policy. This data is processed in accordance with consent and third-party data processing contract requirements under extant data protection laws.</p>
                <p>For Account and Support Communication: For example, we may inform you of subscription payment successes or failures, password reset attempts, and other support-related functions.</p>
                <p><br/></p>
                <p>For Platform Alerts: For example, we may inform you of temporary or permanent changes to our Services, such as pricing changes, planned outages, new features, version updates, releases, abuse warnings, and changes to our Privacy Policy.</p>
                <p><br/></p>
                <p>For Legal Purposes: For example, complying with court orders, valid discovery requests, valid subpoenas, to prosecute and defend a court, arbitration, or similar legal proceeding. To respond to lawful requests by public authorities, including to meet national security or law enforcement requirements. To provide information to representatives and advisors, including attorneys and accountants, to help us comply with legal, accounting, or security requirements.</p>
                <p><br/></p>
                <p>For Transfer Purposes: In the case of a sale, merger, consolidation, liquidation, reorganization, or acquisition. In that event, any acquirer will be subject to our obligations under this Privacy Policy, including your rights to access and choice. We will notify you of the change either by sending you an email or posting a notice on our website.</p>
                <p><br/></p>
                <p>Consent for Collection, Use and Disclosure</p>
                <p>Your use of the Platforms and/or registration for the Services constitutes your consent to the terms of this Privacy Policy. If you do not agree, you can withdraw your consent at any time but please note that the Company will not be able to provide you with its services. The Company reserves the right to amend this Privacy Policy at any time. When it does, the Company will also revise the &quot;last updated&quot; date at the top of this Privacy Policy. The Company will notify you by email regarding material changes to this Privacy Policy that will affect information collected from you in the future. However, the Company will not notify you in certain circumstances, such as in connection with investigation of a breach of an agreement, contravention of laws, an emergency where the life, health or security of an individual is threatened, the collection of a debt or in compliance with the request of a law enforcement agency or a court order. However, the Company may notify Users of such circumstances upon request by the User in permissible circumstances. You may withdraw your consent for collection, use and disclosure at any time by sending an email using the Company&rsquo;s contact details on the &lsquo;Contact Us&rsquo; page on the Site. Please note that, if you withdraw your consent for collection, the Company may suspend its provision of Services to you. By continuing to access and use the Services, you are deemed to have accepted the changes to the provisions of this Privacy Policy. If you provide Personal Information of any third party to the Company, we assume that you have obtained the required consent from the relevant third party to share and transfer his/her Personal Information to us.</p>
                <p><br/></p>
                <p>Disclosure of Personal Information</p>
                <p>The Company will not disclose any of your Personal Information to anyone else, except:</p>
                <p><br/></p>
                <ol>
                    <li>
                        <p>to its employees, independent contractors, subsidiaries, affiliates, consultants, business associates, service providers, suppliers and agents, acting on its behalf for any of the identified purposes;</p>
                    </li>
                    <li>
                        <p>if it has reason to believe that disclosure is necessary to identify, contact or bring legal action against someone who may be causing injury to or interference (either intentionally or unintentionally) with the Company&rsquo;s rights or property, other users of the Platforms, the Services, or anyone else that could be harmed by such activities;</p>
                    </li>
                    <li>
                        <p>in the event of business transfers which may occur when we sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, personal data may be part of the transferred assets; and</p>
                    </li>
                    <li>
                        <p>to respond to judicial process and provide information to law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law, or otherwise as required by law.</p>
                    </li>
                </ol>
                <p><br/></p>
                <p>Data Retention</p>
                <p>We may retain Personal Information about the User, as long as it is necessary for business and/or legal purposes. Also, we may retain aggregated anonymous information indefinitely. In addition, we may retain your information for an additional period as is permitted or required to, among other things, comply with our legal obligations, resolve disputes, and enforce agreements.</p>
                <p><br/></p>
                <p>If your account becomes inactive (that is, if you request to be removed from the Company&rsquo;s database), the Company will keep your Personal Information in its archives for the duration required by law. Your information will be used only as necessary for tax reasons or to prove the Company&apos;s compliance with any applicable law.</p>
                <p><br/></p>
                <p>Data Transfer</p>
                <p>For the purpose of providing the Services, the Company (through the Platforms) processes information about our Users on servers located in a number of countries. The Company may also subcontract processing or sharing of information to third parties located in other countries, other than your home country. Also, such information may be transferred to another company if the need arises. Therefore, information may be transferred across international borders outside the country where you use our services. We will only transfer Personal Information outside the country upon compliance with the provisions of any applicable law in relation to transfer of Personal Information outside the State of Delaware.</p>
                <p>By this Privacy Policy, you hereby explicitly and contractually consent to the transfer of your Personal Information by the Company as contemplated herein.</p>
                <p><br/></p>
                <p>Data Security</p>
                <p>We transmit and store the information we collect using standard security techniques. However, given the nature of the internet and the fact that network security measures are not foolproof, we cannot guarantee the security of such information. We protect your Personal Information by:</p>
                <ul>
                    <li>
                        <p>restricting access to the Personal Information;</p>
                    </li>
                    <li>
                        <p>maintaining technology products to prevent unauthorized computer access; and</p>
                    </li>
                    <li>
                        <p>securely destroying your Personal Information when it is no longer needed for any legal or business purpose.</p>
                    </li>
                    <li>
                        <p>using state of the art Secure Socket Layer (SSL) encryption technology when processing your financial details.</p>
                    </li>
                </ul>
                <p><br/></p>
                <p>Your password is the key to your account. Please use unique numbers, letters and special characters, and do not share your password to anyone. If you do, you will be responsible for all actions taken in the name of your account and the consequences. If you lose control of your password, you may lose substantial control over your Personal Information and other information submitted to us. You could also be subject to legally binding actions taken on your behalf. Therefore, if your password has been compromised for any reason or if you have grounds to believe that your password has been compromised, you should immediately contact us and change your password. As a safety measure, you are required to log off from your account and close the browser after using a shared computer.&nbsp;</p>
                <p><br/></p>
                <p>Should a security breach occur, the Company will notify all affected customers as soon as is reasonably possible, and later may file a report with the appropriate authorities on the actions we took if the need arises.</p>
                <p><br/></p>
                <p>Other Websites &amp; Links</p>
                <p>Our Platforms may contain links to third party websites (&ldquo;Linked Websites&rdquo;). This Privacy Policy does not cover collection or use of information by Linked Websites. We are not responsible for the privacy practices of Linked Websites. If you have questions about the privacy policies or practices of a Linked Website; you should contact the web administrator of the site directly.</p>
                <p><br/></p>
                <p>Your Data Privacy Rights</p>
                <ol>
                    <li>
                        <p>Right to Rectification: Users can modify or change their name, email password, and mobile login PIN via their profile. For all other requests, such as updating email address or mobile number, please contact us at&nbsp;<a href="mailto:cb_support@cinderbuild.com">cb_support@cinderbuild.com</a></p>
                    </li>
                </ol>
                <p><br/></p>
                <ol>
                    <li>
                        <p>Right of Access, Right to Erasure, Right to Restrict Processing: Users can request access or erasure of their Personal Information, as well as request restriction on further processing of their Personal Information by contacting us at&nbsp;<a href="mailto:cb_support@cinderbuild.com">cb_support@cinderbuild.com</a>. Please allow up to 30 days for requests to be processed. The Company reserves the right to charge a reasonable fee to process excessive or repeat requests.</p>
                    </li>
                </ol>
                <p><br/></p>
                <ol>
                    <li>
                        <p>Right to Withdraw Consent: Users can stop receiving our promotional emails by following the unsubscribe instructions included in every email we send, or by adjusting your Marketing Preferences in your profile.</p>
                    </li>
                </ol>
                <p><br/></p>
                <ol>
                    <li>
                        <p>Right to lodge a complaint with a supervisory authority: Should you feel your data privacy rights are not being adequately protected by the Company, you have the right to lodge a formal complaint with the appropriate supervisory authority.</p>
                    </li>
                </ol>
                <p><br/></p>
                <p>Available Remedies in the Case of Breach</p>
                <p>In the case of a breach of any of the obligations with respect to your Personal Information being breached or compromised, please exercise your right to contact us through any of the channels highlighted below immediately. A data breach procedure is established and maintained in order to deal with incidents concerning personal data or privacy practices leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal data transmitted, stored or otherwise processed. On notification of such breach, we will investigate to determine if an actual breach has occurred, the actions required to manage such breach, communicate with the subject of the breach and take appropriate action under its dispute resolution framework to remedy such breach.</p>
                <p><br/></p>
                <p>Privacy Policy Changes</p>
                <p>We may make changes to this Privacy Policy from time to time, and for any reason. You are advised to consult this Privacy Policy regularly for any changes, as we deem your continued use, following posting of any amendment, modification or change, approval of all changes.</p>
                <p><br/></p>
                <p>Contact Us</p>
                <p>If you have questions regarding your data privacy rights or would like to submit a related data privacy right request, please email us at&nbsp;<a href="mailto:cb_support@cinderbuild.com">cb_support@cinderbuild.com</a>.&nbsp;</p>
                <p><br/></p>
                <p>Please allow up to 30 days for requests to be processed. The Company reserves the right to charge a reasonable fee to process excessive or repeat requests.</p>
                <p><br/></p>
                <p>If you have general questions concerning this Privacy Policy, please contact us at:</p>
                <p><a href="mailto:cb_support@cinderbuild.com">cb_support@cinderbuild.com</a></p>
                <p>CinderBuild Inc.&nbsp;</p>
                <p>Address: 16192 Coastal Highway, Lewes, Delaware, USA.&nbsp;</p>
                <p><br/></p>
                <p>Last updated - 22nd July 2022&nbsp;</p>
                <p><br/></p>
                <p><br/></p>
                <p><br/></p>
                <p><br/></p>
                </div>
            </div>
        </div>
        </div>
    <FooterLarge></FooterLarge>
    </React.Fragment>
  )
}

export default PrivacyPolicy

import { FC } from "react"
import Footer from "../../../Layout/Footer/Footer"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Navbar/Navbar";

interface propTypes {
  children: any
}
const WrapperStoreReg: FC<propTypes> = ({ children }) => {


  return (
    <>
      <Navbar/>
      {children}
      <Footer></Footer>
      <ToastContainer autoClose={3000} hideProgressBar />
    </>)
}

export default WrapperStoreReg

import React, { useEffect, useState } from 'react';
import './EstateManagersDashboard.scss';
import { AccountStats, OrderHistory, PageAds, PageNotice, PageSuccessNotice, ProductStatistics, Transaction, WalletInformation } from '../../Components/Index';
import { useAuth } from '../../../../../../Components/AuthProvider/AuthProvider';
import { useDispatch } from 'react-redux';
import { TOGGLE_VERIFY_DRAWER } from '../../../../../../Constants/VerifyConstants';
import authService from '../../../../../../Services/authService';
import toast, { Toaster } from 'react-hot-toast';
import { EstateSubscriberProjects } from '../Index';

const EstateManagersDashboard: React.FC = () => {
  const { user } = useAuth(); 
  const [closeNotice, setCloseNotice] = useState(0);

  const closeActiveNotice = () => {
    localStorage.setItem(`close_active_notice_${user.token}`, '1');
    setCloseNotice(1);
  };

  useEffect(() => {
    const closeItemStatus = localStorage.getItem(`close_active_notice_${user.token}`);
    if (closeItemStatus) {
      setCloseNotice(Number(localStorage.getItem(`close_active_notice_${user.token}`)));
    } else if (!closeItemStatus && user.isDeveloperAccountApprovedAndConfirm === true) {
      setCloseNotice(0);
    }
  }, []);

  const dispatch = useDispatch();

  const activateAccount = () => {
    dispatch({ type: TOGGLE_VERIFY_DRAWER, payload: { active: true, placement: 'end' } });
  };

  const [status, setStatus] = useState<string>();

  const getStatus = async () => {
    await authService.verifyActivation().then((response)=>{ 
    setStatus(response.data.data)

      if (response.data.data ==="active") {
        setTimeout(()=>{ 
          closeActiveNotice()
        }, 6000)
      }
    }, error => { 
    toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" }); 
    })
}

  useEffect(() => {
    getStatus();
  }, []);

  
  return (
    <div className="EstateManagersDashboard">
      {!user.isInvestor && <div className="container">
        <div className="header-title">
          <h2>Account Overview</h2>
          <div className="subtext">Here is the summary of the overall data</div>
        </div>
        <PageAds></PageAds>

        {status === 'inactive' && user.isDeveloperAccountApprovedAndConfirm === 'inactive' && (
          <PageNotice
            noticeType="warning"
            message="Your account has limited functionality at the moment, allowing purchases only. To unlock the platform's full potential, kindly complete the verification process."
            closable={false}
            buttonLabel="Verify Account"
            action={activateAccount}
          />
        )}

        {status === 'pending' && (
          <PageNotice
            noticeType="warning"
            message="Your account activation request has been received and we are currently verifying your documents. Please check back later"
            closable={false}
            buttonLabel="Verify Account"
            action={activateAccount}
          />
        )}

        {status === 'active' && closeNotice === 0 && (
          <PageSuccessNotice
            noticeType="success"
            message="Account verification succesful. <br/>Your account is active"
            closable={false}
            buttonLabel="Verify Account"
            action={closeActiveNotice}
          />
        )}

        <div className="row">
          {user.isDeveloper && <div className="col-md-7">
            <AccountStats></AccountStats>
          </div>}
          <div className="col-md-5">
            <WalletInformation></WalletInformation>
          </div>
          <div className="col-md-7">
            <Transaction></Transaction>
          </div>
          {user.isDeveloper && <div className="col-md-5">
            <ProductStatistics></ProductStatistics>
          </div>}
          <div className="col-md-12">
            <OrderHistory></OrderHistory>
          </div>
        </div>
      </div>}

      {user.isInvestor && <div className="container">
        <EstateSubscriberProjects></EstateSubscriberProjects>
        </div>}
      <Toaster></Toaster>
    </div>
  );
};

export default EstateManagersDashboard;

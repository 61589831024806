import { NumberWithComma } from "../../../../../../../Helpers/NumberWithCommer";
import Rating from "../../../../../../../Components/Rating/Rating";
import LocationIcon from "../../../../../../../Assets/Images/icon/location-minus.png";
import {FC, useState} from "react";
import "./BuyerSavedListCard.scss";
import productService from "../../../../../../../Services/productService";
import { toast } from 'react-hot-toast';
import ImageSrc from '../../../../../../../Components/ImageSrc/ImageSrc';

interface propTypes {
    productName:string,
    productBrandLogo:string,
    amount:number,
    location:string,
    productBrand:string,
    ratingInfo: any,
    productImage:string,
    productUuid:string,
    setRefreshList:any
}

const BuyerSavedListCard:FC<propTypes> =  (prop:propTypes)=> {
    const [deleting, setDeleting] = useState(false);
    const {
        productBrand,productName,
        // productBrandLogo,
         setRefreshList,
        ratingInfo,amount,location, productImage, productUuid        
    } = prop
    
    const deleteThisProductFromSaved = async () => {
        setDeleting(true)
        await productService.deleteSavedProduct(productUuid).then((res)=>{
            toast.success(res.data.data, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" })
            setRefreshList(true)
            setDeleting(false)
        },(error)=>{
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    }

    return (
        <div className="card mb-3 buyer-saved-list-card m-0">
        <div className="card-body">
            <div className="card-title custom-card-title">
                {
                    !deleting &&
                    <>
                        {/* <img  className="brand-logo" src={productBrandLogo}  alt=''/> */}
                        <button type="button" className="btn-close close-btn " aria-label="Close"
                            onClick={deleteThisProductFromSaved}
                        ></button>
                    </>
                }

                {
                    deleting &&
                    <div>
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <span>Removing item...</span>
                    </div>
                }
            </div>
            <div className="w-100 d-flex justify-content-center">
                <ImageSrc src={productImage} alt={productName} title={productName} width={"product-image"}></ImageSrc> 
            </div>
            <div className="product-name mb-2">
                <div>{productName}</div>
            </div>
            <div className="w-100 mb-2 d-flex justify-content-between align-items-center">
                <div className="amount">₦{NumberWithComma(amount)}</div>
                <Rating 
                    ratingValue={ratingInfo?.totalNumberOfRatings}
                    maxRating=  {ratingInfo?.totalRatingsValue}
                />
            </div>
            <div className="row px-2">
                <div className="col-md-8 p-0">
                   <div className="product-brand">{productBrand.toLowerCase()}</div>
                </div>
                <div className="col-md-4 p-0 text-end">
                   <div className="location"><img  className= "me-2" src={LocationIcon} alt=''/>{location}</div> 
                </div>
            </div> 
        </div>
        </div>
    )
}

export default BuyerSavedListCard;
import React, {FC} from "react";  
import { useCategory } from "../../../../../../../../Hooks"; 
import { ErrorMessage } from "../../../../Component";
import {FormValuesType} from "../PLPApplicationFormConfig";

interface propTypes {
    formikValues:FormValuesType;
    handleChange :any;
    handelBlur:any;
    formikErrors:any;
    formikTouchedValues:any;
}

const DefferedPaymentDetailsInput:FC<propTypes> = (prop) => {
    
    const {
        formikValues, handleChange,
        handelBlur, formikTouchedValues,
        formikErrors
    } =prop;

  

    const { isSuccess, data: productCat } = useCategory(1, "DESC", 25)

   
    return (
         <div>
         <div className="form-section-header">
          <p className="form-section-title">Deferred Payment Details</p>
          <p className="form-section-description">Enter the deferred payment details.</p>
         </div>
         <div className="row">
          <div className="mb-4 col-12">
              <label  className="form-label">How are you buying your product?</label>
                <div className="form-check  d-flex align-items-center">
                <input className="form-check-input me-2" type="radio" name="modeOfDelivery" id="modeOfDelivery1" checked
                    value={formikValues.modeOfDelivery? formikValues.modeOfDelivery : "Pickup from Seller"} onBlur={handelBlur} onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="modeOfDelivery1">
                    Pickup from Seller
                </label>
                </div>
                <div className="form-check d-flex align-items-center">
                <input className="form-check-input me-2" type="radio" name="modeOfDelivery" id="modeOfDelivery2"
                    value={formikValues.modeOfDelivery? formikValues.modeOfDelivery : " Delivery from seller"} onBlur={handelBlur} onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="modeOfDelivery2">
                    Delivery from seller
                </label>
                </div>
                {
                    formikErrors.modeOfDelivery && formikTouchedValues.modeOfDelivery &&
                    <ErrorMessage error={formikErrors.modeOfDelivery}/>
                }
          </div>
          <div className="mb-3 col-12 col-md-6">
              <label htmlFor="productCategoryFormControlInput" className="form-label">Product category</label>
              <select className={`form-select form-control ${formikErrors.productCategoryUuid && formikTouchedValues.productCategoryUuid ? 'custom-form-select-error' : 'custom-form-select'}`} aria-label="Default select example"
                name="productCategoryUuid" value={formikValues.productCategoryUuid} onBlur={handelBlur} onChange={handleChange}
              >
                 <option>--product category--</option>
                {isSuccess === true && productCat?.length > 0 && productCat?.map((res:{uuid:string, name:string}, index:React.Key) => {
                return (<option key={index} value={res.uuid}>{res.name}</option>)
                })} 
              </select>
              {
                    formikErrors.productCategoryUuid && formikTouchedValues.productCategoryUuid &&
                    <ErrorMessage error={formikErrors.productCategoryUuid}/>
              }
          </div>
          <div className="mb-3 col-12 col-md-6">
              <label htmlFor="productQuantityFormControlInput" className="form-label">Product quantity</label>
              <input type="number" className={`form-control ${formikErrors.productQuantity && formikTouchedValues.productQuantity ? 'form-input-error' :'form-input'}`} id="productQuantityFormControlInput"
                name="productQuantity" value={formikValues.productQuantity} onBlur={handelBlur} onChange={handleChange}
              />
              {
                    formikErrors.productQuantity && formikTouchedValues.productQuantity &&
                    <ErrorMessage error={formikErrors.productQuantity}/>
              }
          </div>
          
          <div className="mb-3 col-12 col-md-6">
              <label htmlFor="purchaseAmountFormControlInput" className="form-label">Exact purchase amount</label>
              <input type="number" className="form-control form-input" id="purchaseAmountFormControlInput"
                name="principalAmountMajor" value= {formikValues.principalAmountMajor}
                onBlur={handelBlur} onChange={handleChange}
              />
              {
                    formikErrors.principalAmountMajor && formikTouchedValues.principalAmountMajor &&
                    <ErrorMessage error={formikErrors.principalAmountMajor}/>
              }
          </div>
         </div>
 
        </div>
    )
}

export default DefferedPaymentDetailsInput;
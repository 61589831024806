
import React, { FC, useEffect } from 'react'
import './BuyerWraper.scss'
import EventBus from '../../../../Helpers/EventBus'
import { Logout } from '../../../../Helpers/Logout'
import { BuyerHeader, BuyerMobileHeader, BuyerSidebar, FooterLarge } from '../../../../Layout' 
import { useSelector } from 'react-redux'
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas'
import { CartDrawer } from '../../../Frontend/Components'

interface propTypes {
  children: any
}

const BuyerWraper:FC<propTypes> = ({ children }) => {
  const cartDrawer = useSelector((state: {cartDrawer:{active:boolean, placement: OffcanvasPlacement | undefined}}) => state.cartDrawer)
 
  useEffect(() => {
    EventBus.on("logout", () => {
      Logout()
    });

    return () => {
      EventBus.remove("logout", () => {
        Logout()
      });
    };
  }, []);

  return (
    <React.Fragment>
      <div className='buyerwraper'> 
        <BuyerMobileHeader/>
        <div className="row">
          <div className="col-25 pe-0">
            <BuyerSidebar></BuyerSidebar>
          </div>
          <div className="col-85">
            <BuyerHeader></BuyerHeader>
            { cartDrawer.active && <CartDrawer></CartDrawer> }
            { children }
          </div>
        </div>
        <FooterLarge></FooterLarge>
      </div>
    </React.Fragment>
  )
}

export default BuyerWraper

import React, { useState } from 'react'
import "./NavMenu.scss"
import profileCircle from "../../../Assets/Images/icon/profile-circle.png"
import heart from "../../../Assets/Images/icon/heart.png" 
import login from "../../../Assets/Images/login.png"
import logoutIco from "../../../Assets/Images/logout.png"
import userCircle from "../../../Assets/Images/user-cirlce-add.png"

import { HeaderSearchBox } from '../../../Components'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../Components/AuthProvider/AuthProvider'
import CartMenu from '../../NavMenu/Cart/CartMenu'
import BuyerNotification from './Notification/BuyerNotification'
import ModalPrompt from '../../../Components/ModalPrompt/ModalPrompt';


const NavMenu = () => {
  const [scroll, setScroll] = useState(false)

  const [logoutShow, setLogoutShow] = useState<boolean>(false)

  const navigate = useNavigate()

  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;

    if (currentScrollPos > 200) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }
  const { user, logout } = useAuth()

  function logoutNow ():void { 
    setLogoutShow(!logoutShow)
  };
 

  return (
             <nav className={scroll ? "navbar navbar-expand-lg shadow-sm" : "navbar navbar-expand-lg"}>
                <div className="container">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul className="navbar-nav w-50 mb-2 mb-lg-0">
                            <li className="nav-item">
                            <HeaderSearchBox></HeaderSearchBox>
                            </li>
                        </ul>

                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            
                            <BuyerNotification></BuyerNotification>

                            <li className="nav-item">
                               <Link className="nav-link nav-responsive" to="/buyer/saved"> <img src={heart} alt="" className="nav-icon"/> Saved List</Link>
                            </li>
                            <li className="nav-item">
                              <CartMenu></CartMenu>
                            </li>
                            {!user && <li className="nav-item dropdown">
                                <a className="nav-link prof-d dropdown-toggle nav-responsive" href="#" role="button" id='navbarDropdown' data-bs-toggle="dropdown" aria-haspopup='true' aria-expanded="false">
                                    <img src={profileCircle} className="avarter me-2"/>   {user ? `Hi ${user?.firstName}` : "Account"}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end border-0 shadow" aria-labelledby='navbarDropdown'>
                                      <li><Link className="dropdown-item" to="/login"> <img src={login}></img> Login</Link></li>
                                      <li><Link className="dropdown-item" to="/register"> <img src={userCircle}></img> Signup</Link></li>
                                  </ul>
                            </li>}
                            {user && <li className="nav-item dropdown">
                                <a className="nav-link prof-d dropdown-toggle nav-responsive" href="#" id='navbarDropdown2' role="button" data-bs-toggle="dropdown" aria-haspopup='true' aria-expanded="false">
                                    <img src={profileCircle} className="avarter me-2"/>   {user ? `Hi ${user?.firstName}` : "Account"}
                                </a>
                                 <ul className="dropdown-menu dropdown-menu-end border-0 shadow" aria-labelledby='navbarDropdown2'>
                                      <li><Link className="dropdown-item" to={user? "/buyer/my-account": "/login"}> <img src={login}></img> My Account</Link></li>
                                      <li><Link className="dropdown-item" to={user? "/buyer/my-orders/paid": "/login"}> <img src={login}></img> My Orders</Link></li>
                                      <li><Link className="dropdown-item" to={user? "/buyer/my-quotes/active-request": "/login"}> <img src={login}></img> Quote Requests</Link></li>
                                      <li><Link className="dropdown-item d-none" to={user? "": "/login"}> <img src={login}></img> Switch to Seller</Link></li>
                                      <li><Link className="dropdown-item text-danger" to="#" onClick={logoutNow}> <img src={logoutIco}></img> Log out</Link></li>
                                  </ul>
                            </li>}
                        </ul>
                    </div>
                    </div>
                          <ModalPrompt 
                            showModal={logoutShow} 
                            action={()=>{
                              logout()
                              navigate('/login', { replace: true })
                            }} 
                            closeModal={()=> setLogoutShow(false)}
                            message={'Are you sure you want to logout?'}/>  
                </nav>
  )
}

export default NavMenu

import React, { FC, useEffect, useState } from 'react'
import "./PaymentOption.scss"
import mastercard from "../../../../../Assets/Images/mastercard.png"
import Visa from "../../../../../Assets/Images/Visa.png"
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../../../../../Config/DBConfig'
import toast, { Toaster } from 'react-hot-toast'
import { useForm } from 'react-hook-form' 
import cartService from '../../../../../Services/cartService';
import { Link, useNavigate } from 'react-router-dom'
import { ErrorHandler } from '../../../../../Helpers/ErrorHandler'
import { useAuth } from '../../../../../Components/AuthProvider/AuthProvider'
import { Modal } from 'react-bootstrap'
import ChekoutSigup from '../ChekoutSigup/ChekoutSigup'
import bankingFinanceService from '../../../../../Services/bankingFinanceService'; 
import { Logger } from '../../../../../Helpers/Logger'
import { PickUpOrderRegisteredUser } from './PickUpOrderRegisteredUser'
import { DeliveryOrderRegisteredUser } from './DeliveryOrderRegisteredUser'
import { PickUpOrderUnRegisteredUser } from './PickUpOrderUnRegisteredUser'
import { DeliveryOrderUnRegisteredUser } from './DeliveryOrderUnRegisteredUser'

interface propsTypes {
    totalCost: number;
    module?: string;
}
type Inputs = {
    terms: string;
  };

const PaymentOption:FC<propsTypes> = ({ totalCost, module }) => {
  const orderDetails:any[] = useLiveQuery<any>(async () => await db.orderDetails.toArray())

  const cartData = useLiveQuery(async () => await db.cart.toArray());

  const [paymentVar, setPaymentVar] = useState<string|undefined>()
  const [errorMsg, setErrorMsg] = useState<any[]>([])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { user } = useAuth()

  const navigate = useNavigate()
 

  const [loadingOrder, setLoadingOrder] = useState<boolean>()
  const [walletBalance , setWalletBalance] = useState ({
    currency: "",
    currencySymbol: "",
    amountMajor: ""
  })

  useEffect(()=>{
    if (user) { 
      getMyBalance()
    }
  },[])

  const getMyBalance = async () => { 
    await bankingFinanceService.getMyBalance()
      .then((res:any) => { 
        setWalletBalance(res.data.data) 
      }, (error:any) => { 
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  const updatePaymentVariant = async (e:any) => {
    setPaymentVar(e.target.value)
    await db.orderDetails.update(orderDetails[0]?.id, { orderPaymentVariant: e.target.value }).then(function (updated) {
      if (updated) {
        toast.success(`Payment Variant Updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      } else {
        toast.error(`Nothing was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      }
    });
  }

  const { register, handleSubmit, formState: { errors }, watch } = useForm<Inputs>()

  const processOrder = async () => {  

    if (orderDetails[0].deliveryType === 'PICKUP' && orderDetails[0].pickupLocations.sellers.length === 0) {
      setErrorMsg([...errorMsg, "Pickup location(s) not set."])
      return 0
    }

    setLoadingOrder(true)

    /**
     * USING DIFFERENT ENDPINT TO PROCESS THE TWO TYPES OF ORDER
     */
    //

    // ########for authenticated users#######
    if (user) {


      if (orderDetails[0].deliveryType === 'PICKUP') {
      
        return await PickUpOrderRegisteredUser(
          orderDetails, 
          (res: boolean)=> setLoadingOrder(res),  
          (res:any)=>savePaymentDetail(res),
          navigate
          )
      } else { 
      // door delivery
        return await DeliveryOrderRegisteredUser(
          orderDetails, 
          (res: boolean)=> setLoadingOrder(res),  
          (res:any)=>savePaymentDetail(res),
           navigate
        )
      
      }
    } else {

      // #####UNauth user checkout 
      if (user === null && orderDetails[0]?.newDeliveryAddress?.signup && orderDetails[0]?.newDeliveryAddress?.signup === "on") {
        return handleShow()
      }

      if (orderDetails[0].deliveryType === 'PICKUP') {

        await PickUpOrderUnRegisteredUser(
          orderDetails, 
          (res: boolean)=> setLoadingOrder(res),  
          (res:any)=>savePaymentDetail(res),
          navigate
        )
     
      } else {
        // DELIVERY ORDER
        // remove address of excess field
        
        await DeliveryOrderUnRegisteredUser(
          orderDetails, 
          (res: boolean)=> setLoadingOrder(res),  
          (res:any)=>savePaymentDetail(res),
          cartData
        )
        
      }
    }
  }

  const cartItems:any = useLiveQuery<any>(async () => await db.cart.toArray())

  const onSubmit = async (data:any) => { 

    if (user === null && orderDetails[0]?.newDeliveryAddress?.signup && orderDetails[0]?.newDeliveryAddress?.signup === "true") {
      handleShow()
    }

    setLoadingOrder(true);
    if (user) {
      const arraySize = cartItems.length
      for (const [index, currentProductImage] of cartItems.entries()) { 

        await cartService.add({ productUuid: currentProductImage.productUuid, quantity: currentProductImage.quantity })
          .then((res:any) => {
            setLoadingOrder(false); 
           
            toast.success("Added to remote cart", { duration: 9000, className: 'custom-alert bg-white text-dark', position: 'top-right' });

            if (index === arraySize - 1) {
              processOrder()
            }
          }, (error: any) => {
            setLoadingOrder(false);
            Logger("Add product error", error.response)
            ErrorHandler(error,
              () => {
                toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
              })
          })
      }
    } else {
      const newPickupLoc = cartItems.map((item:any) => {
        delete item.productDetails;
        delete item.id;
        return item;
      });
      await cartService.addUnauth({ temporaryCartItems: newPickupLoc })
        .then((res:any) => {
          setLoadingOrder(false);

          toast.success("Added to remote cart", { duration: 9000, className: 'custom-alert bg-white text-dark', position: 'top-right' });

          processOrder()
        }, (error: any) => {
          setLoadingOrder(false);
          Logger("Add product error", error.response)
          ErrorHandler(error,
            () => {
              toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        })
    }
  }

  const savePaymentDetail = async (data: any) => {
    await db.orderDetails.update(orderDetails[0]?.id, { paymentDetails: data.data.data }).then(function (updated) {
      if (updated) {
        toast.success(`Order detail saved`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        return true
      } else {
        toast.error(`Order detail was not updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        return false
      }
    });
  }

  const isWalletFundSufficient = ()=> {
      if ( Number(totalCost) <= Number(walletBalance.amountMajor)) {
        return true 
      } else { 
        return false
      }
  }

  return (
        <div className='paymentOption'>
          {user === null
            ? <div className='payment-unauth'>
                Wallet and payment on delivery options are not available because you are not logged in.<br></br>
                To get more payment options, <Link to="/login">log in</Link> or <Link to="/register">sign up</Link>.
              </div>
            : ""}
           <ul className="list-group mt-2 list-group-flush ps-1">
                 <li className="list-group-item ps-0 border-0">
                    <input name="paymentVariant" className="form-check-input me-1" type="radio" value="WALLET" id="firstCheckboxStretched1" onChange={updatePaymentVariant} disabled={!user}/>
                    <label className="form-check-label stretched-link ps-2" htmlFor="firstCheckboxStretched1">Wallet</label>
                </li>
                <li className="list-group-item ps-0 border-0">
                    <input name="paymentVariant" className="form-check-input me-1" type="radio" value="CARD" id="secondCheckboxStretched2" onClick={updatePaymentVariant}/>
                    <label className="form-check-label stretched-link ps-2" htmlFor="secondCheckboxStretched2">Card</label>
                </li>
                <li className="list-group-item ps-0 border-0">
                    <input name="paymentVariant" className="form-check-input me-1" type="radio" value="PAY_ON_DELIVERY" id="thirdCheckboxStretched3" onClick={updatePaymentVariant} disabled={!user}/>
                    <label className="form-check-label stretched-link ps-2" htmlFor="thirdCheckboxStretched3">Payment on delivery</label>
                </li>
            </ul>

           {paymentVar && paymentVar === "WALLET" && <div className="wallet border-bottom pb-4 ">
                <div className="card">
                    <div className="card-body">
                        <div className="title">
                        Your balance
                        </div>

                        <div className="price">
                         {`${walletBalance.currencySymbol} ${walletBalance.amountMajor}`}
                        </div>

                        {
                          !isWalletFundSufficient() &&
                          <>
                            <div className="alert alert-danger mt-4">
                            Your balance is low. fund wallet to continue shopping.
                            </div>
                            <button className="btn">Fund wallet</button>
                          </>
                        }
                    </div>
                </div>
            </div>}

            <div className="checkout-total ">
                <div className="row">
                    <div className="col-6">Total order</div>
                    <div className="col-6">₦{totalCost}</div>
                </div>

                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                    <label htmlFor="terms" className="d-flex align-items-center">
                        <input type="checkbox" id="terms" {...register("terms", { required: "this field is required" })} className="me-2"/> <span> I accept the <Link to="/terms-and-conditions">Terms & Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link></span>
                    </label>
                    {errors.terms && <div className="text-danger">{errors?.terms?.message}</div>}
                    {errorMsg.length > 0 && <div className='alert alert-danger'>{errorMsg.map(res => res)}</div>}
                    {!loadingOrder && <button className={watch("terms") ? "btn active" : "btn"}>Pay ₦{totalCost}</button>}
                    {loadingOrder && <button className={watch("terms") ? "btn active" : "btn"} disabled>Pay ₦{totalCost} <span className='spinner-border spinner-border-sm'></span></button>}
                </form>
            </div>

            <div className="payment-option mb-5">
                Acceptable payment methods
                <div className="">
                    <img src={mastercard} className=""/>
                    <img src={Visa} className=""/>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} contentClassName="regModal" centered>
                <Modal.Header closeButton>
                  <Modal.Title>Complete your sign up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 {orderDetails && <ChekoutSigup orderDetails={orderDetails[0]}></ChekoutSigup>}
                </Modal.Body>
              </Modal>
            <Toaster/>
        </div>
  )
}

export default PaymentOption 


import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import BuyerPageTitle from '../../../../../../Components/Buyer/BuyerPageTitle/BuyerPageTitle'
import "./BuyerOrders.scss"

const BuyerOrders = () => {
  return (
        <div className='buyerOrders'>
            <div className="container mt-4">
               <BuyerPageTitle title={'My orders'} parent={null} parentUrl={null}></BuyerPageTitle>
 
                <ul className="nav sub-nav">
                    <li className="nav-item">
                      <NavLink className={({ isActive }) => isActive ? "nav-link active" : "nav-link"} to="paid">Paid orders</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className={({ isActive }) => isActive ? "nav-link active" : "nav-link"} to="plp">PLP orders</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className={({ isActive }) => isActive ? "nav-link active" : "nav-link"} to="delivery">Pay on delivery</NavLink>
                    </li>
                </ul>
               <Outlet />
            </div>
        </div>
  )
}

export default BuyerOrders

import CallIcon from "../../../../../../../Assets/Images/icon/call-calling.png";
import "./GiveUsACall.scss";
import NextIcon from "../../../../../../../Assets/Images/icon/next.svg"
import { useState } from "react";
import userService from "../../../../../../../Services/userService";
import toast, { Toaster } from "react-hot-toast";

const GiveUsACall = () => {
    const [sendLoading, setSendLoading] = useState(false)

    const sendRequest = async () => {
        setSendLoading(true)
        await userService.requestcall().then((res)=>{
            setSendLoading(false)
            toast.success("Call request sent succesfully", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        },
        error =>{
            setSendLoading(false)
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    }
    
    return (
        <div className="give-us-a-call bg-white">
            <a className="w-full d-flex flex-column align-items-center" 
                href="#"  
                onClick={sendRequest}
            >
                <div className="call-icon-container">
                    {sendLoading === false && <img src={CallIcon} alt='Call us' />}
                    {sendLoading === true && <span className="spinner-border spinner-border-md"></span>}

                </div> 
                <p>Request a call</p>
                <p>+2347001236202</p>
            </a>
            <span className="next-icon">
                <img src={NextIcon} alt=''/>
            </span>
            <Toaster></Toaster>
        </div>
    )
}

export default GiveUsACall;
import React, { FC } from 'react';
import "./PageNotice.scss";  
type NoticeType = "success" | "danger" | "warning" | "info";

interface Props {
    noticeType: NoticeType;
    message: string;
    closable: boolean;
    buttonLabel?: string;
    action: ()=>void
}

const PageSuccessNotice: FC<Props> = ({ noticeType, closable, message, buttonLabel, action }) => {
    
    const isWarning = noticeType === 'warning';
    const alertClassName = `alert ${isWarning ? 'alert-warning' : 'alert-success'} alert-dismissible fade show`;

    return (
        <div className='PageNotice'>
            <div className={alertClassName} role="alert">
                <div className="row h-100">
                    <div className="col-11 my-auto">
                        <div dangerouslySetInnerHTML={{__html: message}} />
                    </div>
                    <div className="col-1 text-end my-auto pe-0">
                        {!closable && buttonLabel && (
                            <button className='btn btn-close-notice' onClick={()=> action()}> 
                                <i className='d-icon d-close-circle'></i>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageSuccessNotice;

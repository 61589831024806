import { TOGGLE_VERIFY_DRAWER } from "../../Constants/VerifyConstants";

const AccountVerificationReducer = (state = false, action: { type: string; payload: number; }) => {
  switch (action.type) {
    case TOGGLE_VERIFY_DRAWER:
      return action.payload
    default:
      return state
  }
}

export default AccountVerificationReducer;
 

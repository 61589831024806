import { Outlet } from "react-router-dom";
import "./ProductListSetup.scss";

const ProductListSetup = () => {
    return (
        <div className="product-list-setup">
            <div className="container">
                <div className="outlet">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default ProductListSetup;
import "./SellerPageDisputeModal.scss"; 
import { Modal } from 'react-bootstrap';
import { FC } from "react";
import CloseIcon from "../../../../../Assets/Images/icon/close_icon.svg";
import SellerPageOrderSummaryItem from "../SellerPageOrderSummaryItem/SellerPageOrderSummaryItem";
import SellerPageDisputeForm from "../../../Interface/Seller/Component/SellerPageDisputeForm/SellerPageDisputeForm";
         
interface propTypes {
    showDisputeModal: boolean | undefined;
    handleClose: any;
    orderDetails: any
}

const SellerPageDisputeModal: FC<propTypes> = ({ showDisputeModal, handleClose, orderDetails }: propTypes) => {
  
    return (
        <Modal show={showDisputeModal} onHide={handleClose} placement='end' className="custom-modal">
            <Modal.Header>
                <div className="d-flex flex-row justify-content-between w-100">
                    <div className="custom-offcanvas-close-btn order-2"
                        onClick={handleClose}
                    >
                        <img src={CloseIcon} alt='' />
                    </div>
                    <p className="custom-offcanvas-title order-1">Raise dispute</p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <ul className="list-group list-group-flush">
                    {
                        orderDetails?.orderItems?.map((orderItem: any) => {
                            return (
                                <SellerPageOrderSummaryItem
                                    imageUrl={orderItem.images[0].url}
                                    name={orderItem.productName}
                                    quantity={orderItem.quantity}
                                    amount={orderItem.unitPriceForBuyer}
                                    key={orderItem.orderUuid}
                                    color={orderItem.color || 'N/A'}
                                    currency={orderDetails.currencySymbol}
                                />
                            )
                        })
                    }
                </ul>
                <SellerPageDisputeForm orderDetails={orderDetails} />
            </Modal.Body>
        </Modal>
    )
}

export default SellerPageDisputeModal;
import BankingAndFinance from "./BankingAndFinance/BankingAndFinance";
import "./EnterpriseSettingsBankAndFinance.scss" 
import EnterpriseSubPageTitle from "../../../../../../../Components/Enterprise/EnterpriseSubPageTitle/EnterpriseSubPageTitle";
import { FC } from "react";

interface propsType{
    module?: string
}

const EnterpriseSettingsBankAndFinance:FC<propsType> = ({ module })=>{
    return  (
       <div className="EnterpriseSettings-bankAndFinance">
           <EnterpriseSubPageTitle
                title="Banking and Finance Settings"
                parents={[
                    {
                        title:"Settings",
                        url: "settings"
                    }
                ]} 
            />
             <div className="row justify-content-center">
                <div className="col-12 profile-item-container mb-4">
                    <BankingAndFinance module={module}/>
                </div>
            </div>
       </div>
    )

}

export default EnterpriseSettingsBankAndFinance;
import React from 'react'
import "./OrderHistory.scss"

const OrderHistory = () => {
    return (
        <div className='OrderHistory'>
            <div className="card">
                <div className="card-header border-0 bg-white">
                    <div className="row">
                        <div className="col-md-10"> 
                        Order History
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="no-product">
                        <div className="">
                            <div className="d-block text-center">
                                <svg width="89" height="88" viewBox="0 0 89 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.83325 7.3335H14.2133C18.1733 7.3335 21.2899 10.7435 20.9599 14.6668L17.9166 51.1868C17.4033 57.1635 22.1332 62.2968 28.1466 62.2968H67.1966C72.4766 62.2968 77.0966 57.9702 77.4999 52.7268L79.4799 25.2268C79.9199 19.1402 75.2999 14.1901 69.1766 14.1901H21.8399" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M60.0833 80.6667C62.6146 80.6667 64.6667 78.6146 64.6667 76.0833C64.6667 73.552 62.6146 71.5 60.0833 71.5C57.552 71.5 55.5 73.552 55.5 76.0833C55.5 78.6146 57.552 80.6667 60.0833 80.6667Z" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M30.7501 80.6667C33.2814 80.6667 35.3334 78.6146 35.3334 76.0833C35.3334 73.552 33.2814 71.5 30.7501 71.5C28.2188 71.5 26.1667 73.552 26.1667 76.0833C26.1667 78.6146 28.2188 80.6667 30.7501 80.6667Z" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M33.5 29.3335H77.5" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> 
                            </div>
                            <div className="d-block text-center"> 
                           No order recorded
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderHistory

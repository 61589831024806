import React, { FC, useState } from 'react'
import "./ActivateCard.scss"
import { useForm } from 'react-hook-form';
import mortgageService from '../../../../../../../Services/mortgageService';
import Swal from 'sweetalert2';

interface propsType {
    handleHide: ()=>void
}
type formType = {
   pan:string
}
const ActivateCard:FC<propsType> = ({handleHide}) => {
    const [request, setRequest] = useState(false) 
    const { register, handleSubmit, formState:{errors} } = useForm<formType>()
    const onSubmit = handleSubmit((data: formType)=>processForm(data))

    const processForm = async (data: formType) => {
        setRequest(true)
        await mortgageService.activate(data).then((response)=>{ 
            setRequest(false)
            handleHide()

            Swal.fire({
                title: "Congratulations!",
                text: "You have successfully activated your mortage card",
                icon: "success",
                confirmButtonText: "close",
              });
 
        }, error =>{
            setRequest(false)
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: { 
                cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });
            swalWithBootstrapButtons.fire({
                title: "Oops!",
                text: error.response.data.error,
                icon: "warning",
                showCancelButton: true, 
                showConfirmButton: false, 
                cancelButtonText: "close",
                reverseButtons: true
            });
        })
       console.log("processForm", data)
    }
    return (
        <div className='activateCard'>
            <div className="container">
                <div className="title">Activate PAN Number</div>
                <div className="description">Kindly input your PAN number to proceed</div>

                <form onSubmit={onSubmit}>
                    <div className="form-container mt-4">
                            <div className="form-input">
                                <label htmlFor="devpan">Developer’s PAN Number</label>
                                <input type="text" id='devpan' {...register("pan", {required: "field is required"})} placeholder="PAN Number" className='form-control'/>
                                {errors.pan && <div className='errors'>{errors.pan.message}</div>}
                            </div>
                    </div>

                    <div className="row mt-5 justify-content-center">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btn btn-cancel w-100" type='button' onClick={()=>handleHide()}>Cancel</button>
                                </div>
                                <div className="col-md-6">
                                    <button className="btn btn-proceed w-100" type='submit' disabled={request}>{request && <span className='spinner-border spinner-border-sm'></span>} Proceed</button>
                                </div>
                            </div>
                        </div>
                    </div> 
                </form>
            </div>
        </div>
    )
}

export default ActivateCard

import React from 'react'
import "./Categories.scss"
import CategoryDesktop from './CategoryDesktop/CategoryDesktop'

const Categories = () => {
  return (
     <React.Fragment>
       <CategoryDesktop></CategoryDesktop>
     </React.Fragment>
  )
}

export default Categories

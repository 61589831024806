import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import EnterpriseSubPageTitle from "../../../../../../../Components/Enterprise/EnterpriseSubPageTitle/EnterpriseSubPageTitle";
import { Logger } from "../../../../../../../Helpers/Logger";
import { userProfile } from "../../../../../../../Models";
import userService from "../../../../../../../Services/userService";  
import ProfileAvatar from "../../../Components/EnterpriseSettings/ProfileAvatar/ProfileAvatar";
import "./EnterpriseSettingsBusinessProfile.scss"
 


type formProp = {
  email:string;
  phoneNumber:string;
}

interface propsType {
  module?:string
}

const EnterpriseSettingsBusinessProfile:FC<propsType> = ({module})=>{

    const initialState:userProfile = {
        userUuid: "",
        isOnProductLease: false,
        firstName: "",
        lastName: "",
        emailAddress: "",
        msisdn: "",
        sellerUniqueCode: "",
        photoUrl: "",
        role: "",
        accountRating: {
          totalRatingsValue: 0,
          totalNumberOfRatings: 0
        },
    
        businessProfile: {
          businessName: "",
          businessAddress: "",
          businessCACNumber: ""
        }
      }
      const [userProfileInfo, setUserProfile] = useState<userProfile>(initialState)
       // eslint-disable-next-line no-unused-vars
       const [loadingRequest, setLoadingRequest] = useState<boolean>(false) 
      // eslint-disable-next-line no-unused-vars
      const [photo, setPhoto] = useState<any>(null)

    const getProfile = async () => {
        setLoadingRequest(true)
        await userService.getProfile().then((res): void => {
          setLoadingRequest(false)
          setUserProfile(res.data.data)
          Logger("User profile", res.data.data)
          setPhoto(res.data.data.photoUrl)
        }, (error:any) => {
          setLoadingRequest(false)
          Logger(error.response)
        })
      }
      
      useEffect(() => {
        const abortController = new AbortController();
        getProfile()
        return () => abortController.abort();
      }, [])

 
        const [submit, setSubmit] = useState(false)
        const {register, handleSubmit, formState: {errors}} = useForm<formProp>()
    
        const onSubmit = handleSubmit((data) => processProfile(data))
    
        const processProfile = (data: { [x: string]: any; }) => {
          Logger(data)
          setSubmit(true)
        }
    
    return  (
       <div className="enterpriseSettingsBusinessProfile"> 
           <EnterpriseSubPageTitle
                title="Business Profile"
                parents={[
                    {
                        title:"Settings",
                        url: "settings"
                    }
                ]} 
            />

            <div className="row justify-content-center">
                <div className="col-12 col-md-9 col-lg-6 profile-item-container mb-4">
                    <div className="mb-4">
                      <div className="profileHeader">
                          <div className="pictureAndName">
                              <ProfileAvatar picture={''} module={module}/>
                              <div className="profileName text-capitalize">{userProfileInfo.firstName + ' ' +userProfileInfo.lastName }</div>
                          </div> 
                      </div> 
                    </div>

                    <div className="mb-2"> 

                      <form onSubmit={onSubmit}>
                          <div className="form-input">
                              <label htmlFor="email">Corporate Email Address</label>
                              <input type="text" id="email" defaultValue={userProfileInfo.emailAddress} {...register("email")} className="form-control" />
                              {errors.email && <div className="text-danger">{errors.email.message}</div>}
                          </div> 
                          <div className="form-input"> 
                              {!submit && <button className={module && module === "estate"? "btn-estate":"btn"}> 
                                  <svg className="me-2" width="8" height="15" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3 5.66406C4.10457 5.66406 5 4.76863 5 3.66406C5 2.55949 4.10457 1.66406 3 1.66406C1.89543 1.66406 1 2.55949 1 3.66406C1 4.76863 1.89543 5.66406 3 5.66406Z" stroke={ module && module==="estate"? "#ffffff": "#F5A623"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M3 5.66406V8.66406" stroke={ module && module==="estate"? "#ffffff": "#F5A623"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                  Request Update
                              </button>}
                              {submit && <button className={module && module === "estate"? "btn-estate":"btn"}> 
                                <span className="spinner-border spinner-border-sm me-2"></span>
                                Please wait
                              </button>}
                          </div>
                      </form> 
                    </div>
                    
                </div>
            </div>
       </div>
    )

}

export default EnterpriseSettingsBusinessProfile;
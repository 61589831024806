import ChatWithUsOnWhatsApp from "./ChatWithUsOnWhatsApp/ChatWithUsOnWhatsApp";
import FAQ from "./FAQ/FAQ";
import GiveUsACall from "./GiveUsACall/GiveUsACall";
import "./SupportPage.scss";
import SellerPageTitleWithoutBreadcumbs from "../../../../../../Components/Seller/SellerPageTitleWithougtBreadcumbs/SellerPageTitleWithoutBreadcumbs";

const SupportPage = () => {
    return (
        <div className="support-page">
            <div className="container mt-4">
                <div className="row">
                    <SellerPageTitleWithoutBreadcumbs title="Support" />
                    <div className="col-12 col-md-6 mt-4">
                        <GiveUsACall />
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <ChatWithUsOnWhatsApp />
                    </div>
                    <div className="col">
                        <FAQ />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SupportPage;
import "./Main.scss";
import NavCard from "../../../Component/MyAccount/NavCard/NavCard";
import SecurityIcon from "../../../../../../../Assets/Images/icon/security-safe.svg";
import BankIcon from "../../../../../../../Assets/Images/icon/bank.svg";
import ProfileIcon from "../../../../../../../Assets/Images/icon/profile.svg";
import AddressBookIcon from "../../../../../../../Assets/Images/icon/address-book.svg";
import BuyerPageTitle from "../../../../../../../Components/Buyer/BuyerPageTitle/BuyerPageTitle";

const Main = ()=>{
    return (
        <div className="main mb-4">
             <BuyerPageTitle
                title="My account"
                parent=""
                parentUrl=""
             />
            <div className="row justify-content-center">
                <div className="col-12 col-md-9 col-lg-6 nav-card-container">
                    <div className="mb-2" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="9000">
                    <NavCard
                        title="Profile"
                        description="Change profile picture and update profile."
                        icon={ProfileIcon}
                        link="profile"
                    />
                    </div>
                    <div className="mb-2" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="7000">
                        <NavCard
                            title="Banking and Finance"
                            description="Fund wallet, withdraw and view transactions history."
                            icon={BankIcon}
                            link="bank-and-finance"
                        />
                    </div>
                    <div className="mb-2" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="5000">
                        <NavCard
                            title="Security"
                            description="Change Password"
                            icon={SecurityIcon}
                            link="security"
                        />
                    </div>
                    <div className="" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="3000">
                        <NavCard
                        title="Address Book"
                        description="Add, edit or delete delivery addresses"
                        icon={AddressBookIcon}
                        link="address"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;
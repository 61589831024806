import Dexie, { Table } from 'dexie';

export interface Cart {
  id?: number;
  productUuid: string|undefined;
  quantity: number|undefined;
  productDetails: any
}

export interface SiteCart {
  id?: number; 
  quantity: number|undefined;
  site: any;
  details: any
}

export interface OrderDetails {
  id?: number;
  deliveryType?: string|undefined;
  locationUuid?: string|undefined;
  orderPaymentVariant?: string;
  deliveryAddressUuid?: string;
  pickupLocations?: string;
  newDeliveryAddress?:any;
  paymentDetails?:any;
  pickupContactInformation?:any;
  wareHouseUuid?:string
}

export interface OrderCompletedDetails {
  id?: number;
  orderUuid: string|undefined;
}

export interface LastPayment {
  id?: number;
  type:string;
}
 
export class CDBDatabase extends Dexie { 
  // We just tell the typing system this is the case
  cart!: Table<Cart>;
  orderDetails!: Table<OrderDetails>;
  orderCompletedDetails!: Table<OrderCompletedDetails>;
  siteCart!: Table<SiteCart>;
  lastPayment!: Table<LastPayment>;

  constructor () {
    super('CDBDatabase');

    this.version(1).stores({
      cart: '++id, productUuid, quantity', // Primary key and indexed props
      orderDetails: '++id, deliveryType, locationUuid, orderPaymentVariant, deliveryAddressUuid, pickupLocations, newDeliveryAddress, paymentDetails, pickupContactInformation,  wareHouseUuid', // Primary key and indexed props   ++id,
      orderCompletedDetails: '++id, orderUuid',
      siteCart: '++id, quantity, site, details', 
      lastPayment: '++id, type', 
    });
  }
}

export const db = new CDBDatabase();
 

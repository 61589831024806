 
import toast, { Toaster } from "react-hot-toast";
import userService from "../../../../../../../Services/userService";
import "./GiveUsACall.scss"; 
import { useState } from "react";

const GiveUsACall = () => {

    const [sendLoading, setSendLoading] = useState(false)

    const sendRequest = async () => {
        setSendLoading(true)
        await userService.requestcall().then((res)=>{
            setSendLoading(false)
            toast.success("Call request sent succesfully", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        },
        error =>{
            setSendLoading(false)
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    }
    return (
        <div className="give-us-a-call bg-white">
            <a className="w-full d-flex flex-column align-items-center" 
                href="#"
                onClick={sendRequest}
            >
                <div className="call-icon-container"> 
                    {sendLoading === false && <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M41.8475 25.532C40.8263 25.532 40.0188 24.7008 40.0188 23.7033C40.0188 22.8245 39.14 20.9958 37.6675 19.4045C36.2187 17.8608 34.6275 16.9583 33.2975 16.9583C32.2763 16.9583 31.4688 16.127 31.4688 15.1295C31.4688 14.132 32.3 13.3008 33.2975 13.3008C35.6725 13.3008 38.1662 14.5833 40.3512 16.887C42.3937 19.0483 43.7 21.732 43.7 23.6795C43.7 24.7008 42.8688 25.532 41.8475 25.532Z" fill="#5a5a5a"/>
                        <path d="M50.4209 25.5312C49.3997 25.5312 48.5922 24.7 48.5922 23.7025C48.5922 15.2712 41.7284 8.43125 33.3209 8.43125C32.2997 8.43125 31.4922 7.6 31.4922 6.6025C31.4922 5.605 32.2997 4.75 33.2972 4.75C43.7472 4.75 52.2497 13.2525 52.2497 23.7025C52.2497 24.7 51.4184 25.5312 50.4209 25.5312Z" fill="#5a5a5a"/>
                        <path d="M26.2437 35.5063L21.85 39.9C20.9237 40.8262 19.4513 40.8262 18.5013 39.9237C18.24 39.6625 17.9788 39.425 17.7175 39.1637C15.2712 36.6938 13.0625 34.105 11.0913 31.3975C9.14375 28.69 7.57625 25.9825 6.43625 23.2988C5.32 20.5913 4.75 18.0025 4.75 15.5325C4.75 13.9175 5.035 12.3738 5.605 10.9488C6.175 9.5 7.0775 8.17 8.33625 6.9825C9.85625 5.48625 11.5188 4.75 13.2763 4.75C13.9413 4.75 14.6062 4.8925 15.2 5.1775C15.8175 5.4625 16.3638 5.89 16.7913 6.5075L22.3013 14.2738C22.7288 14.8675 23.0375 15.4137 23.2512 15.9362C23.465 16.435 23.5838 16.9338 23.5838 17.385C23.5838 17.955 23.4175 18.525 23.085 19.0712C22.7763 19.6175 22.325 20.1875 21.755 20.7575L19.95 22.6337C19.6887 22.895 19.57 23.2038 19.57 23.5838C19.57 23.7738 19.5938 23.94 19.6413 24.13C19.7125 24.32 19.7838 24.4625 19.8313 24.605C20.2588 25.3888 20.995 26.41 22.04 27.645C23.1087 28.88 24.2487 30.1388 25.4837 31.3975C25.7212 31.635 25.9825 31.8725 26.22 32.11C27.17 33.0363 27.1938 34.5562 26.2437 35.5063Z" fill="#5a5a5a"/>
                        <path d="M52.1795 43.5345C52.1795 44.1995 52.0608 44.8882 51.8233 45.5532C51.752 45.7432 51.6808 45.9332 51.5858 46.1232C51.182 46.9782 50.6595 47.7857 49.9708 48.5457C48.807 49.8282 47.5245 50.7545 46.0758 51.3482C46.052 51.3482 46.0283 51.372 46.0045 51.372C44.6033 51.942 43.0833 52.2507 41.4445 52.2507C39.022 52.2507 36.4333 51.6807 33.702 50.517C30.9708 49.3532 28.2395 47.7857 25.532 45.8145C24.6058 45.1257 23.6795 44.437 22.8008 43.7007L30.567 35.9345C31.232 36.4332 31.8258 36.8132 32.3245 37.0745C32.4433 37.122 32.5858 37.1932 32.752 37.2645C32.942 37.3357 33.132 37.3595 33.3458 37.3595C33.7495 37.3595 34.0583 37.217 34.3195 36.9557L36.1245 35.1745C36.7183 34.5807 37.2883 34.1295 37.8345 33.8445C38.3808 33.512 38.927 33.3457 39.5208 33.3457C39.972 33.3457 40.447 33.4407 40.9695 33.6545C41.492 33.8682 42.0383 34.177 42.632 34.5807L50.4933 40.162C51.1108 40.5895 51.5383 41.0882 51.7995 41.682C52.037 42.2757 52.1795 42.8695 52.1795 43.5345Z" fill="#5a5a5a"/>
                    </svg>}
 
                    {sendLoading === true && <span className="spinner-border spinner-border-md"></span>}

                </div> 
                <div className="fw-bolder fs-5">Request a call</div>
                <div>+234 7001 236 202</div>
            </a> 
            <Toaster></Toaster>
        </div>
    )
}

export default GiveUsACall; 
   
import React, { FC, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { HorizontalLoader, ImageSrc } from '../../../../../../../Components'; 
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter';
import { projectDetailsType } from '../../../../../../../Models/projectType';

interface propType { 
    loadingRequest: boolean;
    projects: Array<projectDetailsType>
}
const RejectedProjects:FC<propType> = ({projects, loadingRequest}) => {
    return (
        <table className="table rounded border-0 mt-0"> 
            <thead className='rounded'>
                <tr className='d-none d-md-table-row'>
                    <th scope="col">S/N</th>
                    <th scope="col">Date</th> 
                    <th scope="col">Project name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Type</th>
                    <th scope="col">Slot</th> 
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
            {projects.length > 0 && projects.map((res:projectDetailsType, index: React.Key | null | undefined) => {
                return (
                    <Fragment key={index}>
                        <tr className='d-none d-md-table-row'>
                            <td>{Number(index) +1}</td>
                            <td> { TimeConverter(res.createdAt)}</td>  
                            <td> {res.name}</td>
                            <td><div className="details">{res.details}</div></td>
                            <td><span className='type'>{res.type}</span></td>
                            <td><span className='slots'>{ res.numberOfSlots} units</span> </td> 
                            <td> 
                                <div className="btn-group">
                                    <button type="button" className="btn border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className='d-icon d-more-vertical'></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm"> 
                                        <li>
                                            <Link className="dropdown-item" to={`/estate-managers/project-management/${res.projectUuid}`}>
                                            <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.16992 7.4375L11.9999 12.5475L20.7699 7.46747" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 21.6091V12.5391" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M9.92963 2.48L4.58963 5.45003C3.37963 6.12003 2.38965 7.80001 2.38965 9.18001V14.83C2.38965 16.21 3.37963 17.89 4.58963 18.56L9.92963 21.53C11.0696 22.16 12.9396 22.16 14.0796 21.53L19.4196 18.56C20.6296 17.89 21.6196 16.21 21.6196 14.83V9.18001C21.6196 7.80001 20.6296 6.12003 19.4196 5.45003L14.0796 2.48C12.9296 1.84 11.0696 1.84 9.92963 2.48Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M16.9998 13.2416V9.5816L7.50977 4.10156" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                            Project Details
                                            </Link>
                                        </li> 
                                    </ul>
                                </div>
                            </td> 
                        </tr>  
                        <tr className='d-md-none'>
                            <td>
                                <ImageSrc src={''} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                            </td>
                            <td colSpan={4}>
                                <div className="row"> 
                                    <div className='col-12 profile-title'>
                                    {res.name}
                                    </div>
                                    <div className='col-12 profile-detail'>{res.details}</div> 
                                    <div className='col-12 profile-detail'>{ TimeConverter(res.createdAt)}</div>
                                </div>
                            </td>
                            <td className='profile-btn'>
                                <Link className="dropdown-item" to={`/estate-managers/project-management/${res.projectUuid}`}>
                                    <button type="button" className="btn btn-sm p-1 border d-md-none">
                                        View
                                    </button> 
                                </Link>
                            </td>   
                        </tr>  
                        </Fragment>) 
                    })}
                {loadingRequest === false && projects?.length === 0 &&  <tr>
                    <td colSpan={6}>
                        <div className='text-secondary text-center fw-bold'>No item found</div>
                    </td>
                </tr>}
                {loadingRequest === true && <tr>
                    <td colSpan={6}>
                        <div className='text-secondary text-center fw-bold'><HorizontalLoader></HorizontalLoader></div>
                    </td>
                </tr>}
            </tbody> 
        </table> 
    )
}

export default RejectedProjects

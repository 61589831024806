import React from 'react'
import EnterpriseQuoteRequest from './EnterpriseQuoteRequest/EnterpriseQuoteRequest'
 
const EnterpriseQuote = () => { 
    return (
        <div className='enterpriseQuote'> 
           <EnterpriseQuoteRequest></EnterpriseQuoteRequest>
        </div> 
    )
}

export default EnterpriseQuote

import React, { FC, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/esm/Modal'
import toast, { Toaster } from 'react-hot-toast'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { HorizontalLoader, ImageSrc } from '../../../../../../../Components'
import { db } from '../../../../../../../Config/DBConfig'
import { useAllInventory } from '../../../../../../../Hooks/Queries/Enterprise/useAllInventory'
import { wareHouse } from '../../../../../../../Models/enterprise.types'
import wareHouseService from '../../../../../../../Services/wareHouseService'
import "./Inventory.scss"
import { useDispatch } from 'react-redux';
import { TOGGLE_CART_DRAWER } from '../../../../../../../Constants/CartConstants'
import productService from '../../../../../../../Services/productService';
 

interface CheckboxPropsType  {
        name: string, 
        value: any, 
        tick: any, 
        onCheck: (value:any)=>void
    }
   
export function CheckBox ({name, value, tick, onCheck}: CheckboxPropsType) {
    return ( 
            <input
                name={name}
                type="checkbox"
                value={value}
                checked={tick || false}
                onChange={onCheck}
            /> 
    );
}

interface propsType {
    warehouseData?: wareHouse; 
    module? : string
  }
   
const Inventory:FC<propsType> = ({warehouseData, module}) => {
    const { warehouseUuid } = useParams<{ warehouseUuid: string }>()
   
    const [show, setShow] = useState(false); 

    const [reorderLevelProduct, setReorderLevelProduct] = useState(""); 
    const [reorderLevel, setReorderLevel] = useState(0); 
    const [reorderLevelRequest, setReorderLevelRequest] = useState(false); 
   
    const handleClose = () => setShow(false); 

    const handleShow = (uuid:string) => {
        setReorderLevelProduct(uuid)
        setShow(true);
    }

    const [sortOrder,] = useState("DESC") 
    
    const [page,] = useState(1)

    const {data, isLoading, isSuccess, isError, error, refetch } = useAllInventory(warehouseUuid, page, sortOrder)
    console.log("useAllInventory", data)
    const navigate = useNavigate()

    const [dataIn, setDataIn] = useState({
        checkList: [],
        isAllSelected: false
    })
 
    const [selectedDataIn, setSelectedDataIn] = useState<any>([])


   useEffect(() => {
       warehouseData && refetch()
     }, [warehouseData])

     const addtoSite = async (data:any, count:string) => { 
        const addingToSite =  toast.loading('Adding to site');
      
        try {
            if (count === "single") {
          // Add the new cart item!
            await db.siteCart.add({
            quantity: 0,
            site: "null",
            details: data,
          });

          toast.dismiss(addingToSite) 
    
          toast.success(`Added to site`, { duration: 9000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          
          setTimeout(()=>{
           return  navigate(`/enterprise/warehouse-management/ship-to-site/${warehouseUuid}`)
          }, 3000)

        } else {
            
            const arraySize = selectedDataIn.length
            for (const [index, currentProduct] of selectedDataIn.entries()) {
                 
                await db.siteCart.add({
                    quantity: 0,
                    site: "null",
                    details: currentProduct,
                  }).then(()=>{
                    if (index === arraySize - 1) {

                        toast.dismiss(addingToSite) 
                        toast.success(`Please select site and quantity to deliver to site`, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          
                        setTimeout(()=>{
                         return  navigate(`/enterprise/warehouse-management/ship-to-site/${warehouseUuid}`)
                        }, 3000)
                      }
                  }) 

            }
        }
          
        } catch (error) { 
           toast.dismiss(addingToSite) 
          toast.error(`Failed to add ${name}: ${error}`, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }
      }

    
    
     useEffect(()=>{
        setDataIn({ 
            isAllSelected: false, 
            checkList: isSuccess === true && data.length >0 ? data.map((res:any)=>({...res, checked: false})): []
           })
     }, [data]) 

    const onCheckBoxChange = (checkName:any, isChecked:any) => {
 
        const isAllChecked = (checkName === 'all' && isChecked);
        const isAllUnChecked = (checkName === 'all' && !isChecked);
        const checked = isChecked;

        const checkList:any = dataIn.checkList.map((invent:any, index:any) => {
            if (isAllChecked || invent.uuid === checkName.uuid) {
                return Object.assign({}, invent, {
                    checked,
                });
            } else if (isAllUnChecked) {
                return Object.assign({}, invent, {
                    checked: false,
                });
            }

            return invent;
        });

        const isAllSelected = (checkList.findIndex((item: { checked: boolean }) => item.checked === false) === -1) || isAllChecked;
        
        const allSelected = checkList.filter((resCk: { checked:boolean })=>resCk.checked === true)

        setSelectedDataIn(allSelected) 

        setDataIn({
            checkList,
            isAllSelected,
        });

    }


    const submitReorderLevel = async () => {
        setReorderLevelRequest(true)

        await wareHouseService.reorderLevel({ wareHouseUuid: warehouseUuid, productUuid: reorderLevelProduct, level: reorderLevel })
        .then(()=>{
                setReorderLevelRequest(false)
                handleClose()
                toast.success("Reorder level set", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
   
          }, (error:any) => { 
            setReorderLevelRequest(false) 
            
            toast.success(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
   
          }) 
    }




    const dispatch = useDispatch()

    const addtocart = async (inFlowQuantity:number, dataPayload:any) => {
         
      try { 
         

        await  productService.getSingleProduct(dataPayload.uuid).then(async (res:any)=>{
           
               // Add the new cart item!
            await db.cart.add({
               productUuid: res.data.data.productUuid,
               quantity: inFlowQuantity,
               productDetails: res.data.data
           });  
 
           dispatch({ type: TOGGLE_CART_DRAWER, payload: { active: true, placement: "end" } })
         
           toast.success(`Added to cart`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        
          })
            
      } catch (error) {   
          toast.error(`Failed to add ${name}: ${error}`, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      }
    }
 
    return (
        <div className='inventory'>
               <div className="row mt-4">
                    <div className={selectedDataIn.length <= 1 ? "col-12 col-lg-8":"col-12 col-lg-6"}>
                      <div className="seach-field">
                        <input type="text" className='search-input form-control' placeholder='Search product or product ID'/>
                        <svg className="search-icon" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6752 11.5657C9.3418 13.8991 5.55859 13.8991 3.22518 11.5657C0.891767 9.23226 0.891767 5.44905 3.22518 3.11564C5.55859 0.782229 9.3418 0.782229 11.6752 3.11564C14.0086 5.44905 14.0086 9.23226 11.6752 11.5657ZM11.6752 11.5657L16.4128 16.3033" stroke="#868484" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>  
                      </div>
                    </div>
                    <div className="col-4 col-lg-2">

                        <button className='btn btn-filter dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg className="btn-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9404 22.6516C10.4604 22.6516 9.99039 22.5316 9.55039 22.2916C8.67039 21.8016 8.14039 20.9116 8.14039 19.9116V14.6116C8.14039 14.1116 7.81039 13.3616 7.50039 12.9816L3.76039 9.02156C3.13039 8.39156 2.65039 7.31156 2.65039 6.50156V4.20156C2.65039 2.60156 3.86039 1.35156 5.40039 1.35156H18.6004C20.1204 1.35156 21.3504 2.58156 21.3504 4.10156V6.30156C21.3504 7.35156 20.7204 8.54156 20.1304 9.13156L15.8004 12.9616C15.3804 13.3116 15.0504 14.0816 15.0504 14.7016V19.0016C15.0504 19.8916 14.4904 20.9216 13.7904 21.3416L12.4104 22.2316C11.9604 22.5116 11.4504 22.6516 10.9404 22.6516ZM5.40039 2.85156C4.70039 2.85156 4.15039 3.44156 4.15039 4.20156V6.50156C4.15039 6.87156 4.45039 7.59156 4.83039 7.97156L8.64039 11.9816C9.15039 12.6116 9.65039 13.6616 9.65039 14.6016V19.9016C9.65039 20.5516 10.1004 20.8716 10.2904 20.9716C10.7104 21.2016 11.2204 21.2016 11.6104 20.9616L13.0004 20.0716C13.2804 19.9016 13.5604 19.3616 13.5604 19.0016V14.7016C13.5604 13.6316 14.0804 12.4516 14.8304 11.8216L19.1104 8.03156C19.4504 7.69156 19.8604 6.88156 19.8604 6.29156V4.10156C19.8604 3.41156 19.3004 2.85156 18.6104 2.85156H5.40039Z" fill="#868484"/>
                                <path d="M5.99968 10.7473C5.85968 10.7473 5.72968 10.7073 5.59968 10.6373C5.24968 10.4173 5.13968 9.94733 5.35968 9.59733L10.2897 1.69733C10.5097 1.34733 10.9697 1.23733 11.3197 1.45733C11.6697 1.67733 11.7797 2.13733 11.5597 2.48733L6.62968 10.3873C6.48968 10.6173 6.24968 10.7473 5.99968 10.7473Z" fill="#868484"/>
                            </svg>
                            Filter 
                        </button> 
                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                            <li>
                                <a className="dropdown-item" href="#">
                                Available
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#"> 
                                Out of Stock
                                </a>
                            </li> 
                            <li>
                                <a className="dropdown-item" href="#"> 
                                Reorder
                                </a>
                            </li> 
                        </ul> 
                    </div>
                    <div className="col-4 col-lg-2">
 
                        <button className='btn btn-filter dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg className="btn-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 11V17L11 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 17L7 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> 
                            Export 
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                            <li><a className="dropdown-item" href="#">
                            Export as .pdf
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#"> 
                                Export as .doc
                                </a>
                            </li> 
                            <li>
                                <a className="dropdown-item" href="#"> 
                                    Export as .xlsx
                                </a>
                            </li> 
                        </ul>

                    </div> 
                    {selectedDataIn.length >= 2 && <div className="col-4 col-lg-2">
                    <button className='btn move-bulk-btn'  onClick={()=>addtoSite({}, "multiple")}> Move to site <span>{selectedDataIn.length}</span></button>
                    </div>}
                </div>
 
                <div className="enterprise-table">
                    <div className="table-responsive">
                        <table className="table rounded"> 
                            <thead className='rounded'>
                                <tr className='d-none d-md-table-row'>
                                <th scope="col">
                                    {/* <input type="checkbox" className="select-all"  ref={selector}/> */}
                                    <CheckBox
                                        name="select-all"
                                        value="ALL"
                                        tick={dataIn.isAllSelected}
                                        onCheck={(e) => onCheckBoxChange('all', e.target.checked)}
                                        />
                                </th>
                                <th scope="col">ID</th>
                                <th scope="col">Product</th>
                                <th scope="col">Inflow</th>
                                <th scope="col">Outflow</th>
                                <th scope="col">Available</th>  
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody> 
                                 
                                {isError ===true &&  <tr>
                                    <td colSpan={6}>
                                      <div className='text-center'>{error.response.data.error}</div>
                                    </td>
                                </tr>}
                                {isLoading === true &&  <tr>
                                    <td colSpan={6}>
                                      <div className='text-center'> 
                                        <HorizontalLoader></HorizontalLoader>
                                      </div>
                                    </td>
                                </tr>}
                                {isSuccess === true &&  data.length === 0 && <tr>
                                    <td colSpan={6}>
                                      <div className='text-center'> 
                                          No item found
                                      </div>
                                    </td>
                                </tr>}
 
                                {isSuccess === true &&  data.length >0 && dataIn.checkList.map((res: { checked:boolean, product: { uuid:string; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined }; inFlowQuantity:  number; outFlowQuantity: number; availableQuantity: number; uuid: any }, index:number) => {
                                 return <React.Fragment key={index}>
                                    <tr  className='d-none d-md-table-row'>
                                    <td> 
                                       {/* <input type="checkbox" ref={selectItems}/> */}
                                       <CheckBox
                                            key={index}
                                            name={"inventory"}
                                            value={res}
                                            tick={res.checked}
                                            onCheck={(e) => onCheckBoxChange(res, e.target.checked)}/>
                                    </td>
                                    <td>00{index + 1}</td>
                                    <td> <ImageSrc src={''} alt={''} title={undefined} width={'w-product-image'}></ImageSrc> {res.product.name}</td>
                                    <td>{res.inFlowQuantity}</td>
                                    <td>{res.outFlowQuantity}</td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <span className='qty_available'>{res.availableQuantity}</span>
                                            <button className={module && module==="estate"?'btn btn-reorder-estate ms-1':"btn btn-reorder ms-1"} onClick={()=> addtocart(res.inFlowQuantity, res.product)}>
                                            <svg className="me-2" width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.0655 3.30562H4.04305L5.45305 1.89562C5.67055 1.67812 5.67055 1.31813 5.45305 1.10063C5.23555 0.883125 4.87555 0.883125 4.65805 1.10063L2.28805 3.47062C2.23555 3.52312 2.19805 3.58312 2.16805 3.65062C2.13805 3.71812 2.12305 3.79312 2.12305 3.86812C2.12305 3.94312 2.13805 4.01812 2.16805 4.08562C2.19805 4.15312 2.23555 4.21312 2.28805 4.26562L4.65805 6.63563C4.77055 6.74813 4.91305 6.80062 5.05555 6.80062C5.19805 6.80062 5.34055 6.74813 5.45305 6.63563C5.67055 6.41813 5.67055 6.05813 5.45305 5.84062L4.04305 4.43062H13.0655C13.9955 4.43062 14.753 5.18812 14.753 6.11812V8.60812C14.753 8.91563 15.008 9.17062 15.3155 9.17062C15.623 9.17062 15.878 8.91563 15.878 8.60812V6.11812C15.878 4.56562 14.618 3.30562 13.0655 3.30562Z" fill="#F5A623"/>
                                                <path d="M15.878 14.1306C15.878 14.0556 15.863 13.9806 15.833 13.9131C15.803 13.8456 15.7655 13.7856 15.713 13.7331L13.343 11.3631C13.1255 11.1456 12.7655 11.1456 12.548 11.3631C12.3305 11.5806 12.3305 11.9406 12.548 12.1581L13.958 13.5681H4.93555C4.00555 13.5681 3.24805 12.8106 3.24805 11.8806V9.39062C3.24805 9.08312 2.99305 8.82812 2.68555 8.82812C2.37805 8.82812 2.12305 9.08312 2.12305 9.39062V11.8806C2.12305 13.4331 3.38305 14.6931 4.93555 14.6931H13.958L12.548 16.1031C12.3305 16.3206 12.3305 16.6806 12.548 16.8981C12.6605 17.0106 12.803 17.0631 12.9455 17.0631C13.088 17.0631 13.2305 17.0106 13.343 16.8981L15.713 14.5281C15.7655 14.4756 15.803 14.4156 15.833 14.3481C15.863 14.2806 15.878 14.2056 15.878 14.1306Z" fill="#F5A623"/>
                                            </svg>
                                            Reorder Now
                                        </button>
                                        </div>
                                        </td> 
                                    <td>
                                      <div className="btn-group">
                                        <button type="button" className="btn border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className='d-icon d-more-vertical'></i>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                                        {selectedDataIn.length <= 1 && <li>
                                              <Link to="#" onClick={()=>addtoSite(res, "single")} className="dropdown-item">
                                                <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.9998 14.75H11.9998C11.5898 14.75 11.2498 14.41 11.2498 14C11.2498 13.59 11.5898 13.25 11.9998 13.25H12.9998C13.6898 13.25 14.2498 12.69 14.2498 12V2.75H5.99978C4.81978 2.75 3.73975 3.38998 3.15975 4.41998C2.95975 4.77998 2.49979 4.91002 2.13979 4.71002C1.77979 4.51002 1.64975 4.05 1.84975 3.69C2.68975 2.19 4.27978 1.25 5.99978 1.25H14.9998C15.4098 1.25 15.7498 1.59 15.7498 2V12C15.7498 13.52 14.5198 14.75 12.9998 14.75Z" fill="#333333"/>
                                                    <path d="M19 20.75H18C17.59 20.75 17.25 20.41 17.25 20C17.25 19.31 16.69 18.75 16 18.75C15.31 18.75 14.75 19.31 14.75 20C14.75 20.41 14.41 20.75 14 20.75H10C9.59 20.75 9.25 20.41 9.25 20C9.25 19.31 8.69 18.75 8 18.75C7.31 18.75 6.75 19.31 6.75 20C6.75 20.41 6.41 20.75 6 20.75H5C2.93 20.75 1.25 19.07 1.25 17C1.25 16.59 1.59 16.25 2 16.25C2.41 16.25 2.75 16.59 2.75 17C2.75 18.24 3.76 19.25 5 19.25H5.34998C5.67998 18.1 6.74 17.25 8 17.25C9.26 17.25 10.32 18.1 10.65 19.25H13.36C13.69 18.1 14.75 17.25 16.01 17.25C17.27 17.25 18.33 18.1 18.66 19.25H19C20.24 19.25 21.25 18.24 21.25 17V14.75H19C18.04 14.75 17.25 13.96 17.25 13V10C17.25 9.04 18.03 8.25 19 8.25L17.93 6.38C17.71 5.99 17.29 5.75 16.84 5.75H15.75V12C15.75 13.52 14.52 14.75 13 14.75H12C11.59 14.75 11.25 14.41 11.25 14C11.25 13.59 11.59 13.25 12 13.25H13C13.69 13.25 14.25 12.69 14.25 12V5C14.25 4.59 14.59 4.25 15 4.25H16.84C17.83 4.25 18.74 4.78001 19.23 5.64001L20.94 8.63C21.07 8.86 21.07 9.15 20.94 9.38C20.81 9.61 20.56 9.75 20.29 9.75H19C18.86 9.75 18.75 9.86 18.75 10V13C18.75 13.14 18.86 13.25 19 13.25H22C22.41 13.25 22.75 13.59 22.75 14V17C22.75 19.07 21.07 20.75 19 20.75Z" fill="#333333"/>
                                                    <path d="M8 22.75C6.48 22.75 5.25 21.52 5.25 20C5.25 18.48 6.48 17.25 8 17.25C9.52 17.25 10.75 18.48 10.75 20C10.75 21.52 9.52 22.75 8 22.75ZM8 18.75C7.31 18.75 6.75 19.31 6.75 20C6.75 20.69 7.31 21.25 8 21.25C8.69 21.25 9.25 20.69 9.25 20C9.25 19.31 8.69 18.75 8 18.75Z" fill="#333333"/>
                                                    <path d="M16 22.75C14.48 22.75 13.25 21.52 13.25 20C13.25 18.48 14.48 17.25 16 17.25C17.52 17.25 18.75 18.48 18.75 20C18.75 21.52 17.52 22.75 16 22.75ZM16 18.75C15.31 18.75 14.75 19.31 14.75 20C14.75 20.69 15.31 21.25 16 21.25C16.69 21.25 17.25 20.69 17.25 20C17.25 19.31 16.69 18.75 16 18.75Z" fill="#333333"/>
                                                    <path d="M22 14.75H19C18.04 14.75 17.25 13.96 17.25 13V10C17.25 9.04 18.04 8.25 19 8.25H20.29C20.56 8.25 20.81 8.39 20.94 8.63L22.65 11.63C22.71 11.74 22.75 11.87 22.75 12V14C22.75 14.41 22.41 14.75 22 14.75ZM19 9.75C18.86 9.75 18.75 9.86 18.75 10V13C18.75 13.14 18.86 13.25 19 13.25H21.25V12.2L19.85 9.75H19Z" fill="#333333"/>
                                                    <path d="M8 8.75H2C1.59 8.75 1.25 8.41 1.25 8C1.25 7.59 1.59 7.25 2 7.25H8C8.41 7.25 8.75 7.59 8.75 8C8.75 8.41 8.41 8.75 8 8.75Z" fill="#333333"/>
                                                    <path d="M6 11.75H2C1.59 11.75 1.25 11.41 1.25 11C1.25 10.59 1.59 10.25 2 10.25H6C6.41 10.25 6.75 10.59 6.75 11C6.75 11.41 6.41 11.75 6 11.75Z" fill="#333333"/>
                                                    <path d="M4 14.75H2C1.59 14.75 1.25 14.41 1.25 14C1.25 13.59 1.59 13.25 2 13.25H4C4.41 13.25 4.75 13.59 4.75 14C4.75 14.41 4.41 14.75 4 14.75Z" fill="#333333"/>
                                                </svg>

                                                Ship to site
                                              </Link>
                                            </li>}
                                            <li>
                                                <Link className="dropdown-item" to="#" onClick={()=>handleShow(res.product.uuid)}>
                                                <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.4201 4.4075H5.39008L7.27008 2.5275C7.56008 2.2375 7.56008 1.7575 7.27008 1.4675C6.98008 1.1775 6.50008 1.1775 6.21008 1.4675L3.05008 4.6275C2.98008 4.6975 2.93008 4.7775 2.89008 4.8675C2.85008 4.9575 2.83008 5.0575 2.83008 5.1575C2.83008 5.2575 2.85008 5.3575 2.89008 5.4475C2.93008 5.5375 2.98008 5.6175 3.05008 5.6875L6.21008 8.8475C6.36008 8.9975 6.55008 9.0675 6.74008 9.0675C6.93008 9.0675 7.12008 8.9975 7.27008 8.8475C7.56008 8.5575 7.56008 8.0775 7.27008 7.7875L5.39008 5.9075H17.4201C18.6601 5.9075 19.6701 6.9175 19.6701 8.1575V11.4775C19.6701 11.8875 20.0101 12.2275 20.4201 12.2275C20.8301 12.2275 21.1701 11.8875 21.1701 11.4775V8.1575C21.1701 6.0875 19.4901 4.4075 17.4201 4.4075Z" fill="#333333"/>
                                                    <path d="M21.1701 18.8434C21.1701 18.7434 21.1501 18.6434 21.1101 18.5534C21.0701 18.4634 21.0201 18.3834 20.9501 18.3134L17.7901 15.1534C17.5001 14.8634 17.0201 14.8634 16.7301 15.1534C16.4401 15.4434 16.4401 15.9234 16.7301 16.2134L18.6101 18.0934H6.58008C5.34008 18.0934 4.33008 17.0834 4.33008 15.8434V12.5234C4.33008 12.1134 3.99008 11.7734 3.58008 11.7734C3.17008 11.7734 2.83008 12.1134 2.83008 12.5234V15.8434C2.83008 17.9134 4.51008 19.5934 6.58008 19.5934H18.6101L16.7301 21.4734C16.4401 21.7634 16.4401 22.2434 16.7301 22.5334C16.8801 22.6834 17.0701 22.7534 17.2601 22.7534C17.4501 22.7534 17.6401 22.6834 17.7901 22.5334L20.9501 19.3734C21.0201 19.3034 21.0701 19.2234 21.1101 19.1334C21.1501 19.0434 21.1701 18.9434 21.1701 18.8434Z" fill="#333333"/>
                                                </svg>

                                                Set Reorder
                                                </Link>
                                            </li> 
                                            <li>
                                                <Link className="dropdown-item" to="/enterprise/order-details/jkjkjkjkjdjkjk">
                                                    <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M15.7099 15.1778L12.6099 13.3278C12.0699 13.0078 11.6299 12.2378 11.6299 11.6078V7.50781" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg> 
                                                    Purchase History
                                                </Link>
                                            </li> 
                                            <li>
                                                <Link className="dropdown-item" to="/enterprise/order-details/jkjkjkjkjdjkjk">
                                                    <svg className="me-2" width="17" height="17" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11 21.5C16.5 21.5 21 17 21 11.5C21 6 16.5 1.5 11 1.5C5.5 1.5 1 6 1 11.5C1 17 5.5 21.5 11 21.5Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M8.16992 14.3319L13.8299 8.67188" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M13.8299 14.3319L8.16992 8.67188" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg> 
                                                    Remove Item
                                                </Link>
                                            </li> 
                                        </ul>
                                      </div>
                                    </td>
                                </tr>  
                                <tr className='d-md-none'>
                                    <td>
                                        <ImageSrc src={''} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                                    </td>
                                    <td colSpan={4}>
                                        <div className="row"> 
                                            <div className='col-12 profile-title'>{res.product.name}</div>
                                            <div className='col-12 profile-detail'>Inflow: {res.inFlowQuantity}</div> 
                                            <div className='col-12 profile-detail'>Outflow: {res.outFlowQuantity}</div>
                                        </div>
                                    </td>
                                    <td className='profile-btn'>
  
                                        <div className="btn-group">
                                        <button type="button" className="btn border-0 dropdown-toggle p-0" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className='d-icon d-more-vertical'></i>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                                        {selectedDataIn.length <= 1 && <li>
                                              <Link to="#" onClick={()=>addtoSite(res, "single")} className="dropdown-item">
                                                <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.9998 14.75H11.9998C11.5898 14.75 11.2498 14.41 11.2498 14C11.2498 13.59 11.5898 13.25 11.9998 13.25H12.9998C13.6898 13.25 14.2498 12.69 14.2498 12V2.75H5.99978C4.81978 2.75 3.73975 3.38998 3.15975 4.41998C2.95975 4.77998 2.49979 4.91002 2.13979 4.71002C1.77979 4.51002 1.64975 4.05 1.84975 3.69C2.68975 2.19 4.27978 1.25 5.99978 1.25H14.9998C15.4098 1.25 15.7498 1.59 15.7498 2V12C15.7498 13.52 14.5198 14.75 12.9998 14.75Z" fill="#333333"/>
                                                    <path d="M19 20.75H18C17.59 20.75 17.25 20.41 17.25 20C17.25 19.31 16.69 18.75 16 18.75C15.31 18.75 14.75 19.31 14.75 20C14.75 20.41 14.41 20.75 14 20.75H10C9.59 20.75 9.25 20.41 9.25 20C9.25 19.31 8.69 18.75 8 18.75C7.31 18.75 6.75 19.31 6.75 20C6.75 20.41 6.41 20.75 6 20.75H5C2.93 20.75 1.25 19.07 1.25 17C1.25 16.59 1.59 16.25 2 16.25C2.41 16.25 2.75 16.59 2.75 17C2.75 18.24 3.76 19.25 5 19.25H5.34998C5.67998 18.1 6.74 17.25 8 17.25C9.26 17.25 10.32 18.1 10.65 19.25H13.36C13.69 18.1 14.75 17.25 16.01 17.25C17.27 17.25 18.33 18.1 18.66 19.25H19C20.24 19.25 21.25 18.24 21.25 17V14.75H19C18.04 14.75 17.25 13.96 17.25 13V10C17.25 9.04 18.03 8.25 19 8.25L17.93 6.38C17.71 5.99 17.29 5.75 16.84 5.75H15.75V12C15.75 13.52 14.52 14.75 13 14.75H12C11.59 14.75 11.25 14.41 11.25 14C11.25 13.59 11.59 13.25 12 13.25H13C13.69 13.25 14.25 12.69 14.25 12V5C14.25 4.59 14.59 4.25 15 4.25H16.84C17.83 4.25 18.74 4.78001 19.23 5.64001L20.94 8.63C21.07 8.86 21.07 9.15 20.94 9.38C20.81 9.61 20.56 9.75 20.29 9.75H19C18.86 9.75 18.75 9.86 18.75 10V13C18.75 13.14 18.86 13.25 19 13.25H22C22.41 13.25 22.75 13.59 22.75 14V17C22.75 19.07 21.07 20.75 19 20.75Z" fill="#333333"/>
                                                    <path d="M8 22.75C6.48 22.75 5.25 21.52 5.25 20C5.25 18.48 6.48 17.25 8 17.25C9.52 17.25 10.75 18.48 10.75 20C10.75 21.52 9.52 22.75 8 22.75ZM8 18.75C7.31 18.75 6.75 19.31 6.75 20C6.75 20.69 7.31 21.25 8 21.25C8.69 21.25 9.25 20.69 9.25 20C9.25 19.31 8.69 18.75 8 18.75Z" fill="#333333"/>
                                                    <path d="M16 22.75C14.48 22.75 13.25 21.52 13.25 20C13.25 18.48 14.48 17.25 16 17.25C17.52 17.25 18.75 18.48 18.75 20C18.75 21.52 17.52 22.75 16 22.75ZM16 18.75C15.31 18.75 14.75 19.31 14.75 20C14.75 20.69 15.31 21.25 16 21.25C16.69 21.25 17.25 20.69 17.25 20C17.25 19.31 16.69 18.75 16 18.75Z" fill="#333333"/>
                                                    <path d="M22 14.75H19C18.04 14.75 17.25 13.96 17.25 13V10C17.25 9.04 18.04 8.25 19 8.25H20.29C20.56 8.25 20.81 8.39 20.94 8.63L22.65 11.63C22.71 11.74 22.75 11.87 22.75 12V14C22.75 14.41 22.41 14.75 22 14.75ZM19 9.75C18.86 9.75 18.75 9.86 18.75 10V13C18.75 13.14 18.86 13.25 19 13.25H21.25V12.2L19.85 9.75H19Z" fill="#333333"/>
                                                    <path d="M8 8.75H2C1.59 8.75 1.25 8.41 1.25 8C1.25 7.59 1.59 7.25 2 7.25H8C8.41 7.25 8.75 7.59 8.75 8C8.75 8.41 8.41 8.75 8 8.75Z" fill="#333333"/>
                                                    <path d="M6 11.75H2C1.59 11.75 1.25 11.41 1.25 11C1.25 10.59 1.59 10.25 2 10.25H6C6.41 10.25 6.75 10.59 6.75 11C6.75 11.41 6.41 11.75 6 11.75Z" fill="#333333"/>
                                                    <path d="M4 14.75H2C1.59 14.75 1.25 14.41 1.25 14C1.25 13.59 1.59 13.25 2 13.25H4C4.41 13.25 4.75 13.59 4.75 14C4.75 14.41 4.41 14.75 4 14.75Z" fill="#333333"/>
                                                </svg>

                                                Ship to site
                                              </Link>
                                            </li>}
                                            <li>
                                                <Link className="dropdown-item" to="#" onClick={()=>handleShow(res.product.uuid)}>
                                                <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.4201 4.4075H5.39008L7.27008 2.5275C7.56008 2.2375 7.56008 1.7575 7.27008 1.4675C6.98008 1.1775 6.50008 1.1775 6.21008 1.4675L3.05008 4.6275C2.98008 4.6975 2.93008 4.7775 2.89008 4.8675C2.85008 4.9575 2.83008 5.0575 2.83008 5.1575C2.83008 5.2575 2.85008 5.3575 2.89008 5.4475C2.93008 5.5375 2.98008 5.6175 3.05008 5.6875L6.21008 8.8475C6.36008 8.9975 6.55008 9.0675 6.74008 9.0675C6.93008 9.0675 7.12008 8.9975 7.27008 8.8475C7.56008 8.5575 7.56008 8.0775 7.27008 7.7875L5.39008 5.9075H17.4201C18.6601 5.9075 19.6701 6.9175 19.6701 8.1575V11.4775C19.6701 11.8875 20.0101 12.2275 20.4201 12.2275C20.8301 12.2275 21.1701 11.8875 21.1701 11.4775V8.1575C21.1701 6.0875 19.4901 4.4075 17.4201 4.4075Z" fill="#333333"/>
                                                    <path d="M21.1701 18.8434C21.1701 18.7434 21.1501 18.6434 21.1101 18.5534C21.0701 18.4634 21.0201 18.3834 20.9501 18.3134L17.7901 15.1534C17.5001 14.8634 17.0201 14.8634 16.7301 15.1534C16.4401 15.4434 16.4401 15.9234 16.7301 16.2134L18.6101 18.0934H6.58008C5.34008 18.0934 4.33008 17.0834 4.33008 15.8434V12.5234C4.33008 12.1134 3.99008 11.7734 3.58008 11.7734C3.17008 11.7734 2.83008 12.1134 2.83008 12.5234V15.8434C2.83008 17.9134 4.51008 19.5934 6.58008 19.5934H18.6101L16.7301 21.4734C16.4401 21.7634 16.4401 22.2434 16.7301 22.5334C16.8801 22.6834 17.0701 22.7534 17.2601 22.7534C17.4501 22.7534 17.6401 22.6834 17.7901 22.5334L20.9501 19.3734C21.0201 19.3034 21.0701 19.2234 21.1101 19.1334C21.1501 19.0434 21.1701 18.9434 21.1701 18.8434Z" fill="#333333"/>
                                                </svg>

                                                Set Reorder
                                                </Link>
                                            </li> 
                                            <li>
                                                <Link className="dropdown-item" to="#" onClick={()=> addtocart(res.inFlowQuantity, res.product)}>
                                                <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.4201 4.4075H5.39008L7.27008 2.5275C7.56008 2.2375 7.56008 1.7575 7.27008 1.4675C6.98008 1.1775 6.50008 1.1775 6.21008 1.4675L3.05008 4.6275C2.98008 4.6975 2.93008 4.7775 2.89008 4.8675C2.85008 4.9575 2.83008 5.0575 2.83008 5.1575C2.83008 5.2575 2.85008 5.3575 2.89008 5.4475C2.93008 5.5375 2.98008 5.6175 3.05008 5.6875L6.21008 8.8475C6.36008 8.9975 6.55008 9.0675 6.74008 9.0675C6.93008 9.0675 7.12008 8.9975 7.27008 8.8475C7.56008 8.5575 7.56008 8.0775 7.27008 7.7875L5.39008 5.9075H17.4201C18.6601 5.9075 19.6701 6.9175 19.6701 8.1575V11.4775C19.6701 11.8875 20.0101 12.2275 20.4201 12.2275C20.8301 12.2275 21.1701 11.8875 21.1701 11.4775V8.1575C21.1701 6.0875 19.4901 4.4075 17.4201 4.4075Z" fill="#333333"/>
                                                    <path d="M21.1701 18.8434C21.1701 18.7434 21.1501 18.6434 21.1101 18.5534C21.0701 18.4634 21.0201 18.3834 20.9501 18.3134L17.7901 15.1534C17.5001 14.8634 17.0201 14.8634 16.7301 15.1534C16.4401 15.4434 16.4401 15.9234 16.7301 16.2134L18.6101 18.0934H6.58008C5.34008 18.0934 4.33008 17.0834 4.33008 15.8434V12.5234C4.33008 12.1134 3.99008 11.7734 3.58008 11.7734C3.17008 11.7734 2.83008 12.1134 2.83008 12.5234V15.8434C2.83008 17.9134 4.51008 19.5934 6.58008 19.5934H18.6101L16.7301 21.4734C16.4401 21.7634 16.4401 22.2434 16.7301 22.5334C16.8801 22.6834 17.0701 22.7534 17.2601 22.7534C17.4501 22.7534 17.6401 22.6834 17.7901 22.5334L20.9501 19.3734C21.0201 19.3034 21.0701 19.2234 21.1101 19.1334C21.1501 19.0434 21.1701 18.9434 21.1701 18.8434Z" fill="#333333"/>
                                                </svg> 
                                                 Reorder
                                                </Link>
                                            </li> 
                                            <li>
                                                <Link className="dropdown-item" to="/enterprise/order-details/jkjkjkjkjdjkjk">
                                                    <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M15.7099 15.1778L12.6099 13.3278C12.0699 13.0078 11.6299 12.2378 11.6299 11.6078V7.50781" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg> 
                                                    Purchase History
                                                </Link>
                                            </li> 
                                            <li>
                                                <Link className="dropdown-item" to="/enterprise/order-details/jkjkjkjkjdjkjk">
                                                    <svg className="me-2" width="17" height="17" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11 21.5C16.5 21.5 21 17 21 11.5C21 6 16.5 1.5 11 1.5C5.5 1.5 1 6 1 11.5C1 17 5.5 21.5 11 21.5Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M8.16992 14.3319L13.8299 8.67188" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M13.8299 14.3319L8.16992 8.67188" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg> 
                                                    Remove Item
                                                </Link>
                                            </li> 
                                        </ul>
                                      </div>
                                    </td>   
                                </tr>  
                                </React.Fragment> 
                                        })}
                            </tbody> 
                        </table>
                    </div>
                </div>


                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    contentClassName="reorderModal"
                >
                    <form>
                    <Modal.Header closeButton>
                        <Modal.Title>Set Reorder</Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                    className="rounded">
                    
                    <div className='form-group mt-3'>
                        <label htmlFor='address'>Reorder level 
                            <svg className='me-2' width="12" height="12" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7 0.703125C10.86 0.703125 14 3.75341 14 7.50313C14 11.2528 10.86 14.3031 7 14.3031C3.14 14.3031 0 11.2528 0 7.50313C0 3.75341 3.14 0.703125 7 0.703125ZM8.12377 5.77071H6.37012L5.54351 9.56453C5.51186 9.71472 5.48812 9.85427 5.47229 9.98317C5.45647 10.1121 5.44855 10.2303 5.44855 10.3385C5.44855 10.5887 5.48203 10.8021 5.5496 10.9795C5.61716 11.1569 5.70908 11.303 5.82595 11.4183C5.94282 11.5336 6.08099 11.6187 6.23925 11.6725C6.39751 11.7257 6.56795 11.753 6.75056 11.753C6.90882 11.753 7.06525 11.7287 7.21986 11.6808C7.37446 11.6329 7.51325 11.5584 7.63621 11.4585C7.75856 11.3586 7.86081 11.232 7.94238 11.0806C8.02333 10.9287 8.07204 10.7465 8.08787 10.5349C8.04404 10.5538 7.99656 10.5709 7.94543 10.5869C7.89369 10.6023 7.8103 10.61 7.69526 10.61C7.52543 10.61 7.40064 10.578 7.3209 10.5148C7.24177 10.4509 7.20221 10.3368 7.20221 10.1712C7.20221 10.1091 7.20525 10.047 7.21134 9.98317C7.21682 9.9199 7.2296 9.85486 7.24969 9.78982L8.12377 5.77071ZM7.57656 3.25288C7.44204 3.25288 7.31603 3.27772 7.19916 3.32798C7.08229 3.37824 6.98004 3.44683 6.89299 3.53316C6.80595 3.61949 6.73777 3.71944 6.68786 3.83356C6.63855 3.94709 6.6136 4.06888 6.6136 4.20015C6.6136 4.33142 6.63855 4.45323 6.68786 4.56676C6.73777 4.68029 6.80595 4.77845 6.89299 4.86123C6.98004 4.94401 7.08229 5.00965 7.19916 5.05755C7.31603 5.10544 7.44204 5.12969 7.57656 5.12969C7.71108 5.12969 7.83707 5.10544 7.95394 5.05755C8.07081 5.00965 8.17429 4.94401 8.26316 4.86123C8.35203 4.77845 8.42265 4.68029 8.47439 4.56676C8.52552 4.45323 8.55169 4.33142 8.55169 4.20015C8.55169 4.06888 8.52552 3.94709 8.47439 3.83356C8.42265 3.71944 8.35203 3.61949 8.26316 3.53316C8.17429 3.44683 8.07081 3.37824 7.95394 3.32798C7.83707 3.27772 7.71108 3.25288 7.57656 3.25288Z" fill="#787878"/>
                            </svg>
                        </label>
                        <input className='form-control fc textarea-pk' id="level" onChange={(e:any)=> setReorderLevel(e.target.value) } placeholder=''/> 
                    </div>  

                    </Modal.Body>
                    <Modal.Footer className='border-0 mt-5'> 
                        {reorderLevelRequest===false && <button type="submit" className="btn w-100 border-0" onClick={submitReorderLevel}>Save</button> }
                        {reorderLevelRequest===true && <button type="submit" className="btn w-100 border-0 text-center" disabled={reorderLevelRequest}><span className='spinner-border spinner-border-sm'></span></button> }
                    </Modal.Footer>
                    </form>
                </Modal>

                <Toaster/>
        </div>
    )
}

export default Inventory

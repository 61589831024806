import React  from 'react' 
   
 const EstateManagersCompanyInformation = (prop:any) => {
    const {
        formikValues,
        handleChange, 
        handleBlur, 
        formikErrors,
        formikTouchedValues, 
    } = prop; 

    return (
        <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
        <div className="form-group mb-3">
            <label htmlFor="companyName">Company Name</label>
            <input type="text" id="companyName" 
            className={`form-control ${formikErrors.companyName && formikTouchedValues.companyName ? 'form-input-error' :'form-input'}`}  
            placeholder='Company name'
            value={formikValues.companyName}  onChange={handleChange}  onBlur ={handleBlur}/>
         </div> 
            <div className="form-group mb-3">
                <label htmlFor="cacNumber">CAC Number</label>
                <input type="text" id="cacNumber" 
                className={`form-control ${formikErrors.cacNumber && formikTouchedValues.cacNumber ? 'form-input-error' :'form-input'}`}  
                placeholder='CAC Number'
                value={formikValues.cacNumber}  onChange={handleChange}  onBlur ={handleBlur}/>
             </div> 
            <div className="form-group mb-3">
                <label htmlFor="emailAddress">Company Email Address</label>
                <input type="text" id="emailAddress" 
                className={`form-control ${formikErrors.emailAddress && formikTouchedValues.emailAddress ? 'form-input-error' :'form-input'}`}
                placeholder='Email Address'
                value={formikValues.emailAddress}  onChange={handleChange}  onBlur ={handleBlur}/> 
            </div>  
            <div className="form-group mb-3">
                <label htmlFor="businessEmail">Company Address</label>
                <input 
                    type="text" 
                    id="address" 
                    className={`form-control ${formikErrors.address && formikTouchedValues.address ? 'form-input-error' :'form-input'}`}
                    placeholder='Company Address'
                    value={formikValues.address}  
                    onChange={handleChange}  
                    onBlur ={handleBlur}/> 
            </div>  
        </div>
    )
}

export default EstateManagersCompanyInformation

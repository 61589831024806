import React, { FC } from 'react'
import { Helmet } from "react-helmet-async";

interface propsType {
    title: string;
}

const HeaderMeta:FC<propsType> = ({ title }) => {
  return (
    <Helmet>
       <title>{title} -  Africa’s #1 Building Materials Marketplace</title>
    </Helmet>
  )
}

export default HeaderMeta

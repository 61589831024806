import React, { PropsWithChildren } from 'react'
import "./ProductSinglePageSkeleton.scss"
import Skeleton from 'react-loading-skeleton'

const ProductSinglePageSkeleton = () => {
  function Box ({ children }: PropsWithChildren<unknown>) {
    return (
            <div
                style={{
                  display: 'block',
                  padding: '0rem',
                  marginBottom: '0.0rem',
                  width: "100%",
                //   height: 137,
                }}
            >
                {children}
            </div>
    )
  }

  return (
        <div className='productSinglePageskeleton'>
            <div className="container my-2">
                <div className="ps-2">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                         <Skeleton wrapper={Box} count={1} height={10} width={100}/>
                         <Skeleton wrapper={Box} count={1} height={10} width={100}/>
                         <Skeleton wrapper={Box} count={1} height={10} width={100}/>
                         <Skeleton wrapper={Box} count={1} height={10} width={100}/>
                        </ol>
                    </nav>
                </div>
            </div>

            <section>
                <div className="product-element pb-5">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-md-7">
                                <div className="mb-5 mt-3">
                                    <h1><Skeleton wrapper={Box} count={1} height={20} width={250}/></h1>
                                    <div className="desc">
                                    <Skeleton wrapper={Box} count={1} height={20} width={350}/>
                                    </div>
                                </div>
                                <div className="product-image">
                                    <Skeleton wrapper={Box} count={1} height={420} width={'100%'}/>
                                    <div id="carouselProductImage" className="row" data-bs-ride="true">
                                        <div className="col-md-4">
                                            <Skeleton wrapper={Box} count={1} height={100} width={'100%'}/>
                                        </div>
                                        <div className="col-md-4">
                                            <Skeleton wrapper={Box} count={1} height={100} width={'100%'} />
                                        </div>
                                        <div className="col-md-4">
                                            <Skeleton wrapper={Box} count={1} height={100} width={'100%'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    <div className="col-md-5 my-auto">
                        <div className="product-details px-5">
                            <div className="price">
                            <Skeleton wrapper={Box} count={1} height={20} width={150}/>
                            </div>

                            <div className="soldby">
                            <Skeleton wrapper={Box} count={1} height={20} width={160}/>
                            </div>
                            <hr/>
                            <div className="qty pb-3">
                                <label htmlFor="Qty"><Skeleton wrapper={Box} count={1} height={20} width={80}/></label>
                                <Skeleton wrapper={Box} count={1} height={50} width={'100%'}/>
                            </div>
                            <hr/>
                            <div className="pt-3">
                            <Skeleton wrapper={Box} count={1} height={50} width={'100%'}/>
                            </div>
                            <div className="mt-3">
                            <Skeleton wrapper={Box} count={1} height={50} width={'100%'}/>
                            </div>
                            <div className="product-option mt-4">
                              <Skeleton wrapper={Box} count={1} height={20} width={80}/>
                            </div>
                            <div className="product-option">
                            <Skeleton wrapper={Box} count={1} height={20} width={80}/>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </section>

            <section>
                <div className="product-description">
                    <div className="container mt-5 pt-5 ">
                        <hr/>
                            <div className="row">
                                <div className="col-md-6">
                                    <nav>
                                        <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                                            aria-selected="true"> <Skeleton wrapper={Box} count={1} height={10} width={"100%"}/></button>
                                            <button className="nav-link reviewtab" id="nav-profile-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                                            <Skeleton wrapper={Box} count={1} height={10} width={"100%"}/>
                                            </button>
                                        </div>
                                    </nav>
                                    <div className="tab-content border-0" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex={0}>
                                            <Skeleton wrapper={Box} count={1} height={10} width={"100%"}/>
                                            <Skeleton wrapper={Box} count={1} height={10} width={"90%"}/>
                                            <Skeleton wrapper={Box} count={1} height={10} width={"80%"}/>
                                            <Skeleton wrapper={Box} count={1} height={10} width={"70%"}/>
                                            <Skeleton wrapper={Box} count={1} height={10} width={"100%"}/>
                                            <Skeleton wrapper={Box} count={1} height={10} width={"100%"}/>
                                         </div>
                                    </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="ps-5">
                                            <div className=" ">
                                            <Skeleton wrapper={Box} count={1} height={150} width={"100%"}/>
                                            </div>

                                            <div className=" ">
                                            <Skeleton wrapper={Box} count={1} height={150} width={"100%"}/>
                                            </div>
                                        </div>
                                    </div>
                               </div>
                            </div>
                        </div>
                    <hr/>
            </section>
        </div>
  )
}

export default ProductSinglePageSkeleton

import {FC} from "react";
import ProfileAvatar from "../../../../../../../Assets/Images/profileAvatar.svg";
import "./SellerProfileAvatar.scss";
import ImageSrc from '../../../../../../../Components/ImageSrc/ImageSrc';

interface propType  {
    picture:string;
}
const SellerProfileAvatar:FC<propType> = ({picture})=> {
    return (
        <div className="avatar">
            <ImageSrc src={picture || ProfileAvatar} alt='' title={undefined} width={""}/>
        </div>
    )
}

export default SellerProfileAvatar;

import "./SettingsMainPage.scss";
import SecurityIcon from "../../../../../../../Assets/Images/sellerSec.svg";
import BankIcon from "../../../../../../../Assets/Images/bankFina.svg";
import PickupIcon from "../../../../../../../Assets/Images/pickup.svg";
import SellerProfileIcon from "../../../../../../../Assets/Images/sellerProfile.svg";
import BusinessProfileIcon from "../../../../../../../Assets/Images/businessProfile.svg";
import SellerPageTitle from "../../../../../../../Components/Seller/SellerPageTitle/SellerPageTitle";
import SellerNavCard from "../../../Component/Settings/SellerNavCard/SellerNavCard";

const SettingsMainPage = () => {
    return (
        <div className="settings-main-page mb-4">
        <div className="container">
                <SellerPageTitle
                    title="Settings"
                    parent=""
                    parentUrl=""
                    variant="false"
                />
                <div className="row justify-content-center">
                    <div className="col-12 col-md-9 col-lg-6 nav-card-container">
                        <div className="mb-3 w-100" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="9000">
                            <SellerNavCard
                                title="Profile"
                                description="Change profile picture and update profile."
                                icon={SellerProfileIcon}
                                link="profile"
                            />
                        </div>
                        <div className="mb-3 w-100" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="9000">
                            <SellerNavCard
                                title="Business Profile"
                                description="Upload CAC, ID CArd, Bank Account, Company Logo, and update profile."
                                icon={BusinessProfileIcon}
                                link="business-profile"
                            />
                        </div>
                        
                        <div className="mb-3 w-100" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="7000">
                            <SellerNavCard
                                title="Banking and Finance"
                                description="Fund wallet, withdraw and view transactions history."
                                icon={BankIcon}
                                link="/seller/banking-finance"
                            />
                        </div>

                        <div className="mb-3 w-100" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="5000">
                            <SellerNavCard
                                title="Security"
                                description="Change Password"
                                icon={SecurityIcon}
                                link="security"
                            />

                        </div>
                        <div className="mb-3 w-100" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="3000">
                            <SellerNavCard
                                title="Pickup locations"
                                description="Add, edit or delete pickup locations"
                                icon={PickupIcon}
                                link="pickup-locations"
                            />
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsMainPage;
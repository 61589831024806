import { PICKUP_CONTACT_INFORMATION } from "../../Constants/CheckoutConstants";

const initialstate = {
  msisdn: null,
  emailAddress: null,
  fullName: null
}
const PickupContactInformationReducer = (state = initialstate, action: { type: string; payload: number; }) => {
  switch (action.type) {
    case PICKUP_CONTACT_INFORMATION:
      return action.payload
    default:
      return state
  }
}

export default PickupContactInformationReducer;

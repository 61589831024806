import React, { Key, useEffect, useState } from 'react'
import "./EstateProjectDetails.scss"
import { CbdModal, EstatePageTitle, HorizontalLoader, OffcanvasDrawer } from '../../../../../../../Components'  
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas' 
import projectService from '../../../../../../../Services/projectService'
import { projectDetailsType, projectSubscribersType } from '../../../../../../../Models/projectType'
import { useParams } from 'react-router';
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter';
import { CurrencyFormatter } from '../../../../../../../Helpers'
import { EditProject, ProjectStatusUpdate } from '../../../Components/Index' 
import { Toaster, toast } from 'react-hot-toast'
import ImageCollage from '../../../../../../../Components/ImageCollage/ImageCollage' 

const EstateProjectDetails = () => { 
    const { projectUuid } = useParams<{ projectUuid: string|undefined }>()
    // eslint-disable-next-line no-unused-vars
    const [placement, setPlacement] = useState<OffcanvasPlacement>('end') 
    

    const handleClose = (placement:OffcanvasPlacement|undefined) => {
        setShow(false)
    } 
 
    
    const [showStatusModal, setShowStatusModal] = useState(false) 

    const [show, setShow] = useState(false)  
    const [loadingRequest, setLoadingRequest] = useState(false)  
    const [project, setProject] = useState<projectDetailsType>()

    const getProject = async () => { 
        setLoadingRequest(true)
        await projectService.getSingle(projectUuid).then((response)=>{
          console.log("projectService", response) 
          setLoadingRequest(false)
          setProject(response.data.data)
        }, error => { 
            setLoadingRequest(false) 
            toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
     
            console.log("projectService error", error)
        })
    }

    useEffect(()=>{
        getProject()
    }, [projectUuid])

    
    return (
        <div className='EstateProjectDetails pt-4'>
            <div className="container">
                <div className="top-header">

                    {project && <EstatePageTitle
                        title={project.name}
                        parent="project management"
                        parentUrl="project-management"
                        showTitle={false}
                    /> }
                   {loadingRequest && <HorizontalLoader></HorizontalLoader>}
                    {project && <div className="row mt-4">
                        <div className="col-md-8">  
                            <div className="page-title"> 
                                <h2>{project.name}</h2> 
                                <span className='ids'>ID: #0011</span>
                                <span className="open">{project.status}</span>   
                            </div>
                        </div>
                        <div className="col-md-4 mt-auto text-md-end">
                           {project.status !=="CLOSED" && <div className="button-container">
                                <button className='btn btn-edit'  onClick={()=>setShow(true)}> 
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.2603 3.60022L5.05034 12.2902C4.74034 12.6202 4.44034 13.2702 4.38034 13.7202L4.01034 16.9602C3.88034 18.1302 4.72034 18.9302 5.88034 18.7302L9.10034 18.1802C9.55034 18.1002 10.1803 17.7702 10.4903 17.4302L18.7003 8.74022C20.1203 7.24022 20.7603 5.53022 18.5503 3.44022C16.3503 1.37022 14.6803 2.10022 13.2603 3.60022Z" stroke="#3A0CA3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M11.8896 5.0498C12.3196 7.8098 14.5596 9.9198 17.3396 10.1998" stroke="#3A0CA3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3 22H21" stroke="#3A0CA3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Edit Project 
                                </button>
                                <button className='btn btn-closen' onClick={()=>setShowStatusModal(true)}>
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9.16992 14.8299L14.8299 9.16992" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M14.8299 14.8299L9.16992 9.16992" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg> 
                                  Close Project 
                                </button>
                            </div>}
                        </div>
                    </div>}
                </div> 
            </div>
 
            { project && <div className='container mt-5'>
                <div className="row">
                    <div className="col-md-7">
                         <ImageCollage images={project.images}></ImageCollage> 
                    </div>
                    <div className="col-md-5">
                        <div className="price">
                           ₦{CurrencyFormatter(project.costPerSlot)} <span className='unit'>perunit</span>
                        </div>

                        <div className="project-content mt-3">
                            <div className="row">
                                <div className="col-4 mt-3">
                                    <div className="label">Project Type:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{project.type}</div>
                                </div> 
                                <div className="col-4 mt-3">
                                    <div className="label">Project Details:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{project.details}</div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="label">Current Stage:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">-</div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="label">Number of Units:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{project.numberOfSlots}</div>
                                </div>

                                <div className="col-4 mt-3">
                                    <div className="label">Number of Units Sold:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{project.numberOfSlotSold} sold</div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="label">Start Date:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{ project.startDate}</div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="label">Initial Percentage:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{ project.initialInvestmentPercentage}%</div>
                                </div>

                                <div className="col-4 mt-3">
                                    <div className="label">Duration:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{ project.duration} months</div>
                                </div>

                                <div className="col-4 mt-3">
                                    <div className="label">Address</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{ project.address}</div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="label">State</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{ project.state}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

 
            {project && <div className="container">
                <div className="card subscribers-card">
                    <div className="card-header bg-white">
                        <div className="row">
                            <div className="col-6">
                                <div className="title">Subscribers</div>
                            </div>
                            <div className="col-6 text-end">
                                <div className="see-all">See all</div></div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="table-responsive">
                            <table className='table'>
                                <thead className=''>
                                    <tr>
                                        <td>Date</td>
                                        <td>Investor</td>
                                        <td>Total Amount</td>
                                        <td>Amount Paid</td>
                                        <td>Amount Due</td>
                                        <td>Duration</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {project && project.projectSubscriptions && project.projectSubscriptions.length > 0 && project.projectSubscriptions.map((data:projectSubscribersType, index:Key)=><tr key={index}>
                                        <td>
                                        { TimeConverter(data.createdAt)}
                                        </td>
                                        <td>
                                            <span className='profile-avarter'>{data.investorPublicProfile.firstName.charAt(0).toUpperCase()}</span>{data.investorPublicProfile.firstName} {data.investorPublicProfile.lastName}
                                        </td>
                                        <td>
                                           ₦{CurrencyFormatter(data.totalAmountMinor)}
                                        </td>
                                        <td>
                                           ₦{CurrencyFormatter(data.amountPaidMinor)}
                                        </td>
                                        <td>
                                           ₦{CurrencyFormatter(data.amountDueMinor)}
                                        </td>
                                        <td>
                                           {data.duration} month(s)
                                        </td>
                                    </tr>)} 

                                    {project && !project.projectSubscriptions && <tr>
                                        <td colSpan={6} className='text-center'>
                                            No subscriber found
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>}

            <OffcanvasDrawer show={show} placement={placement} handleClose={handleClose} title={'Edit Project'} description="Kindly fill all fields to create a new project">
                <EditProject handleClose={()=>setShow(true)} projectData={project}></EditProject>
            </OffcanvasDrawer>
            <CbdModal showModal={showStatusModal} closeModal={ () => setShowStatusModal(false)} title={''}>
                <ProjectStatusUpdate handleHide={ ()=> setShowStatusModal(false)  }></ProjectStatusUpdate>
            </CbdModal>
            <Toaster></Toaster>
        </div>
    )
}

export default EstateProjectDetails

import React, { useState } from 'react'
import "./Categories.scss"
import { Link } from 'react-router-dom';
import { useCategory } from '../../../../Hooks' 
import { ImageSrc } from '../../../../Components';

const Categories = () => {
  const [pageNumber,] = useState(1)
  const [sortOrder,] = useState("DESC")
  const [pageSize,] = useState(25)

  const { isSuccess, data: productCat } = useCategory(pageNumber, sortOrder, pageSize)

  
  return (
        <div className='categories-main mb-5'>
            <div className="container my-4">
                <div className="ps-2">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item"><a href="/">Home</a></li>
                          <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">All Categories</a></li>
                      </ol>
                    </nav>
                </div>

                <div className="row section-header mt-5">
                    <div className="col-10 ps-3">
                    <h2>Categories</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                   {/* <!--Start Product row--> */}
                <div className="row">
                {isSuccess && productCat?.length > 0 && productCat?.map((res: { imageUrl: string; name: string, uuid: any; }, index:React.Key) => {
                  return <div className="catItems col-6 col-sm-4  col-md-6 col-lg-2 p-1" key={index}>
                      <div className="card product-card border-0 shadow-sm">
                        <div className="card-body p-0">
                          <ImageSrc src={res?.imageUrl} alt={res.name} title={res.name} width="product-image w-100"></ImageSrc>
                          <Link to={`/products-by-category/${res.uuid}`} className="btn category-btn shadow">{res.name}</Link>
                         </div>
                      </div>
                    </div>
                })}

                   </div>

                  {/* <!--Start Product row--> */}

          </div>
        </div>
  )
}

export default Categories

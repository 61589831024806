import axios from 'axios'

 
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()


const mortgageService = {
  activate: async (data: { "pan": string }) => {
    return await axios.post(`mortgagecard/activate`, data)
  }
}

export default mortgageService

import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import Modal from 'react-bootstrap/esm/Modal';
import { Link } from 'react-router-dom'
import { productType } from '../../../../../Models/productType';
import "./PriceCalculate.scss"
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';
import size from "../../../../../Data/size.json"
import { FieldValues, useForm } from 'react-hook-form';

interface formProp {
  size: string;
  length: string;
  width: string;
}
const PriceCalculate = () => {
  const [show, setShow] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { data: productDetail } = useQuery<productType>(['productsSingleData'], {
    enabled: false,
    select: (data: any) => data.data.data,
  })

  const { register, handleSubmit, formState: { errors } } = useForm<formProp>()

  const submit = (data: FieldValues) => {
    setSubmitStatus(true)
  }
  return (
        <div className='priceCalculate mb-4'>
            <Link to="" onClick={handleShow}>Help me calculate the price i need</Link>

            <Modal show={show} onHide={handleClose} contentClassName="priceCalculator">
                <Modal.Header closeButton>
                  <Modal.Title>Calculate quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-3">
                      {productDetail && productDetail?.images?.length > 0 && <ImageSrc src={productDetail?.images[0].url} alt={''} title={''} width={'w-100'}></ImageSrc> }
                    </div>
                    <div className="col-md-9">
                      <div className='desc'>
                      We calculate the quantity you need based on the measurement of the space you want to tile.
                      </div>

                      <b>Masurement</b>

                      <form onSubmit={handleSubmit((sata) => submit(sata))}>
                        <div className='form-group'>
                             <label htmlFor='size'>Size</label>
                             <select className='form-control' id="size" {...register('size')}>
                               <option value="0">--select size--</option>
                               {productDetail?.category.uuid === "216b8687-de0d-4cf6-8cbf-f2cff870abf8" && size.tiles.map((res:{value:string}, index:React.Key) => <option key={index} value={res.value}>{res.value}</option>)}
                               {productDetail?.category.uuid === "66846c92-152a-4088-af87-5e5e7a3a7255" && size.paint.map((res:{value:string}, index:React.Key) => <option key={index} value={res.value}>{res.value}</option>)}
                             </select>
                             {errors.size && <div className='text-danger fs-6'>{errors.size.message}</div>}
                        </div>
                        <div className='form-group'>
                             <label htmlFor='length'>Length</label>
                             <input type="number" className='form-control' id="length" {...register('length')}></input>
                             {errors.length && <div className='text-danger fs-6'>{errors.length.message}</div>}
                        </div>
                        <div className='form-group'>
                             <label htmlFor='width'>Width</label>
                            <input type="number" className='form-control' id="width" {...register('width')}></input>
                             {errors.width && <div className='text-danger fs-6'>{errors.width.message}</div>}
                        </div>
                        <div className='form-group'>
                           {submitStatus && <button className='btn btn-primary' disabled={true}>Calculate quantity
                             <span className='spinner-border spinner-border-sm ms-3'></span>
                           </button>}
                           {!submitStatus && <button className='btn btn-primary'>Calculate quantity</button>}
                        </div>
                      </form>

                    </div>
                  </div>
                </Modal.Body>
              </Modal>
        </div>
  )
}

// e.g size = 12x12
// area = length X breath
// length and breadth of the room
// measurement params = cm or inches

export default PriceCalculate

import "./BankAccount.scss";
import { FC } from "react";
import BankAccountItemCard from "./BankAccountItemCard/BankAccountItemCard";
import { CustomTimeFormat } from "../../../../../../../Helpers/TimeConverter";

interface propType {
    principalAmount:number;
    amountDue:number;
    dateOpened:string;
    nextPaymentDueDate:string;
    currency:string;
}


const BankAccount:FC<propType> = (prop) => {
        const {
            principalAmount, amountDue, dateOpened, 
            nextPaymentDueDate, currency
        } = prop;

        const constructDate =  (date:string):string =>{
            const convertedDate =CustomTimeFormat(date);
            const constructedDate:string = `${convertedDate.date}th ${convertedDate.month}, ${convertedDate.year}`
            if (constructedDate.includes("NaN")) {
                return "N/A"
            }
            return constructedDate; 
        }
        return (
            <div className="row bank-account">
                <div className="header">Bank account</div>
                <div className="col-12 col-sm-6 col-md-6 mb-2">
                    <BankAccountItemCard
                        item={"Principal"}
                        value={ principalAmount ? `${currency} ${principalAmount}` : '₦ 0.00' }
                    />
                </div> 
                 
                <div className="col-12 col-sm-6 col-md-6 mb-2 mb-md-0">
                    <BankAccountItemCard
                        item={"Amount due"}
                        value={ amountDue ? `${currency} ${amountDue}` :'₦ 0.00' }
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-6 mb-2 mb-md-0">
                    <BankAccountItemCard
                        item={"Date opened"}
                        value={constructDate(dateOpened)}
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-6 mb-2 mb-md-0">
                    <BankAccountItemCard
                        item={"Next payment due date"}
                        value={constructDate(nextPaymentDueDate)}
                    />
                </div>  
            </div>
        )
}

export default BankAccount;
import React from 'react'
import "./SellerSidebar.scss"
import SellerSidebarContent from './SellerSidebarContent/SellerSideBarContent'

const SellerSidebar = () => {
    return (
        <div className='seller-sidebar d-none d-lg-block' >
            <SellerSidebarContent></SellerSidebarContent>
        </div>
    )
}

export default SellerSidebar

import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import { HeaderMeta, Loader } from '../../../../Components'
import { db } from '../../../../Config/DBConfig';

const PaymentSuccessful = () => {
    const [searchParams] = useSearchParams();
    const reference = searchParams.get('trxref')

    const navigate = useNavigate() 

    const initProcess = async () => {
        const lastPaymentType =  await db.lastPayment.toArray() 
        switch (lastPaymentType[0].type) {
            case 'CORPORATE_BUY':
                navigate(`/enterprise/order-completed?reference=${reference}`, { replace: true })
                break;
            case 'BUYER_BUY':
                navigate(`/order-completed?reference=${reference}`, { replace: true })
                break; 
            case 'CORPORATE_SITE_DELIVERY_FEE':
                navigate(`/enterprise/payment-completed?action=/enterprise/order-management&reference=${reference}`, { replace: true })
                break;  
            case 'CORPORATE_WALLET_FUNDING':
                navigate(`/enterprise/payment-completed?action=/enterprise/banking-and-finance&reference=${reference}`, { replace: true })
                break;    
            case 'BUYER_WALLET_FUNDING':
                navigate(`/buyer/payment-completed?action=/buyer/banking-and-finance&reference=${reference}`, { replace: true })
                break;   
            case 'UNAUTH_BUYER_BUY':
                navigate(`/payment-completed?action=/`, { replace: true })
                break;    
            case 'BUYER_QUOTE_PAYMENT':
                navigate(`/buyer/my-orders/paid`, { replace: true })
                break;  
            case 'CORPORATE_QUOTE_PAYMENT':
                navigate(`/buyer/my-orders/paid`, { replace: true })
                break; 
                
        }  
        
       
    }
    
    useEffect(() => {
        initProcess()
    }, [])
   
    
    return (
        <div className='container'>
            <HeaderMeta title={'Payment successful'}></HeaderMeta>
            <div className="row vh-100 justify-content-center">
                <div className="col-md-4 my-auto">
                   <div className='text-center'> Validating payment, please wait..</div>
                   
                    <Loader show={true}></Loader>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccessful

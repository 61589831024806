import React, { useState } from 'react'
import "./BuyerQuoteRequestPayNow.scss"

import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'; 
// import { Logger } from '../../../../../../Helpers/Logger';
import cartService from '../../../../../../Services/cartService';
import { savePaymentType } from '../../../../../../Helpers/savePaymentType';

const BuyerQuoteRequestPayNow = (props:any) => {
 
  const navigate = useNavigate()
  const [fields, updateFields] = useState({ password: "", paymentVariant: "", locationUuid: "" })
  const [loadingOrder, setLoadingOrder] = useState<boolean>()

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    // Logger({ name, value })
    updateFields({
      ...fields,
      [name]: value,
    })
  }

  const onSubmit = async () => {
    const deliveryAddressUuid = props.orderDetails.orderReceiveType === "DELIVERY" ? { deliveryAddressUuid: props.orderDetails.deliverAddressUuid } : {}
    const locationUuid = props.orderDetails.orderReceiveType === "PICKUP" ? { locationUuid: props.orderDetails.sellerPickupLocation?.uuid } : {}

    setLoadingOrder(true)

    await cartService.deliveryDetailsQuoteReq(
      {
        // password: fields.password,
        orderReceiveType: props.orderDetails.orderReceiveType,
        ...locationUuid,
        ...deliveryAddressUuid
      },
      props.orderid,
      fields.paymentVariant
    ).then(async (res:any) => {
      setLoadingOrder(false) 

      if (res.data.data.paymentTransactionStatus === "paid") {
       return navigate("/buyer/my-orders", {replace:true})
      } else if (res.data.data.paymentTransactionStatus === "unpaid" && fields.paymentVariant === "PAY_ON_DELIVERY") {
       return navigate("/buyer/my-orders", {replace:true})
      } else {

        await savePaymentType("BUYER_QUOTE_PAYMENT").then(async ()=>{
           window.location.href = res.data.data.paymentProviderDetails.paymentProviderRedirectUrl;
        })
      }
      return props.onHide
    }, (error:any) => {
      setLoadingOrder(false)
      // console.warn("ORDER ERROR: ", error.response)
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }

  return (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          contentClassName="qrPayModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Payment Option
            </Modal.Title>
          </Modal.Header>
          
          <Modal.Body> 

            <div>Payment Option <span className='text-danger fw-bolder'>*</span></div>
            <div className="form-group my-3">
            <select className="form-control" onChange={handleChange}
                    name="paymentVariant">
                <option>--choose payment method--</option>
                <option value="WALLET">Wallet</option>
                <option value="CARD">Card</option>
                <option value="PAY_ON_DELIVERY">Pay On Delivery</option>
            </select>
            </div> 
 
 
          </Modal.Body>
          <Modal.Footer>

            {!loadingOrder && <Button className={fields.paymentVariant ? "btn btn-primary w-100 rounded" : "btn btn-primary w-100 rounded disabled"} onClick={() => onSubmit()}>Pay Now</Button>}
            {loadingOrder && <Button className="btn btn-primary w-100 rounded disabled" type="button" disabled={true}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            please wait...
            </Button>}
          </Modal.Footer>
        </Modal>
  );
}

export default BuyerQuoteRequestPayNow

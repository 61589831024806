import React, { FC, useState } from 'react' 
import "./EnterpriseBankDetails.scss" 
// import userService from "../../../../../../../Services/userService";
// import { Logger } from '../../../../../../../Helpers/Logger';
import { Toaster } from 'react-hot-toast';
import EnterpriseAddBankAccountModal from '../EnterpriseAddBankAccount/EnterpriseAddBankAccountModal'; 
import EnterpriseAccountUpdate from '../EnterpriseAccountUpdate/EnterpriseAccountUpdate';

interface propsType {
    myBankAccount:{
        bankCode: null,
        bankName: null,
        bankAccountNumber: null,
        bankAccountName: null,
    },
    module?: string
}
  
const EnterpriseBankDetails:FC<propsType> = ({ myBankAccount, module }) => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [showAccountUpgradeForm, setShowAccountUpgradeForm] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)
  

  const handleModalClose = ()=>{
      setShowAccountUpgradeForm(false)
  }

  const handleModalOpen = ()=>{
    setShowAccountUpgradeForm(true)
  }

  
  const handleRequestAccountUpdate = async ()=>{ 
    handleModalOpen() 
    // setLoading(true);
    // await userService.upgradeToSeller()
    // .then((res:any) => {
    //   setLoading(false)
    //   if (res.data.status) {
    //     handleModalOpen()
    //   }
    //    Logger("Upgrade to seller", res.data.status)
    // }, (error:any) => {
    //   setLoading(false)
    //   toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    // })
  }
  return (
        <div className='enterpriseBankDetails'>
            <div className="title">
            Bank Account
            </div>

            {!myBankAccount && <div className="card border-0 pt-5 pb-5">
              <div className="card-body">
                  <div className='text-center'>
                    <div className='nobank'>
                       No bank available yet
                    </div>
                    <button className="btn add-bank" onClick={()=> setShowAccountModal(true)}>Add Bank</button>
                  </div>  
              </div>
            </div>}

             {myBankAccount && <div className="card border-0">
            <div className="card-body">

            </div>
            <div className="card-footer border-0 pb-4">
              <div className="row">
                <div className="col-md-12">
                    <div className="name">
                    {myBankAccount?.bankAccountName}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="bank">
                    {myBankAccount?.bankAccountNumber} -  {myBankAccount?.bankName}
                    </div>
                </div>
                <div className="col-md-6">
                    <button className={module && module === "estate"? "btn btn2 d-flex align-items-center justify-content-center" : 'btn d-flex align-items-center justify-content-center'} 
                      onClick={handleRequestAccountUpdate} disabled={loading}
                    >
                      Request Account Update
                      {
                        loading && 
                        <div className="spinner-border text-secondary ms-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      }
                    </button>
                </div>
              </div>
            </div>
           </div>}
           <Toaster></Toaster>
           <EnterpriseAccountUpdate showModal={showAccountUpgradeForm} handleClose={handleModalClose} module={module}/>
           <EnterpriseAddBankAccountModal showModal={showAccountModal} hide={(val:boolean) => setShowAccountModal(val) } module={module}></EnterpriseAddBankAccountModal>
        </div>
  )
}

export default EnterpriseBankDetails

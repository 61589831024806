  
import { db } from '../../../../../Config/DBConfig';
import cartService from '../../../../../Services/cartService';
import { savePaymentType } from '../../../../../Helpers/savePaymentType';
import { Logger } from '../../../../../Helpers/Logger';
import toast from 'react-hot-toast'; 
import { ErrorHandler } from '../../../../../Helpers/ErrorHandler';
 
export const DeliveryOrderRegisteredUser = async (orderDetails:any, setLoadingOrder:any, savePaymentDetail:any, navigate:any) => {

 

    const deliveryAddressUuid = orderDetails[0].deliveryAddressUuid
    ? {
        deliveryAddressUuid: orderDetails[0].deliveryAddressUuid.uuid
      }
    : "";

  const deliveryOption = orderDetails[0].newDeliveryAddress
    ? {
        newDeliveryAddress: {
          address: orderDetails[0].newDeliveryAddress.address,
          country: JSON.parse(orderDetails[0].newDeliveryAddress.country).name,
          state: JSON.parse(orderDetails[0].newDeliveryAddress.state).name,
          contactFullName: orderDetails[0].newDeliveryAddress.contactFullName,
          contactPhoneNumber: orderDetails[0].newDeliveryAddress.contactPhoneNumber,
        },
      }
    : "";

  Logger("DELIVERY", {
    data: {
      orderReceiveType: orderDetails[0].deliveryType,
      ...deliveryOption,
      ...deliveryAddressUuid
    },
    orderPaymentVariant: orderDetails[0].orderPaymentVariant
  })

  return await cartService.deliveryDetailsDoor(
    {
      orderReceiveType: orderDetails[0].deliveryType,
      ...deliveryOption,
      ...deliveryAddressUuid
    },
    orderDetails[0].orderPaymentVariant
  ).then(async (res:any) => {

 if (res) {

    setLoadingOrder(false)

    Logger("ORDER created deliver", res)
    
    await savePaymentType("BUYER_BUY").then( async ()=>{
      
      await db.cart.clear()
      await db.orderDetails.clear()


        toast.success("Order succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
  
         
        if (orderDetails[0].orderPaymentVariant === "CARD") {
          savePaymentDetail(res) 
  
          window.location.href = res.data.data.paymentProviderDetails.paymentProviderRedirectUrl;
  
        } else if (orderDetails[0].orderPaymentVariant === "WALLET" && res?.data.data.paymentTransactionStatus === "paid") {
          
          toast.success("Payment succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
         
           setTimeout(()=>{ 
              navigate(`/buyer/payment-completed?action=/buyer/my-orders`, { replace: true })
           }, 2000)
  
        } else if (orderDetails[0].orderPaymentVariant === "PAY_ON_DELIVERY" && res.data.status === true && res.data.data.paymentTransactionStatus === "unpaid") {
          toast.success("Pay on delivery Order succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          
          setTimeout(()=>{ 
            navigate(`/buyer/my-orders`, { replace: true })
         }, 3000)
  
        }
    })
  }

   
  }, (error:any) => {
    setLoadingOrder(false)

    ErrorHandler(error,
      () => {
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  })
}
 
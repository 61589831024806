import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const cartService = {
  async getAll () {
    return await axios.get(`/orders/prepare/fromcart`);
  },

  async delete (productUuid) {
    return await axios.delete(`/cart/product/${productUuid}`)
  },

  async add (data) {
    console.warn(" CART SERVICE", data)
    return await axios.post(`/cart/item`, data)
  },
  async addUnauth (data) {
    console.warn(" TEMPO CART SERVICE", data)
    return await axios.post(`/temporaryorders/prepare`, data)
  },
  async deliveryDetailsUnauth (data) {
    return await axios.post(`/temporaryorders/temporary/create/frompreparedcart`, data)
  },

  async deliveryDetails (data, orderPaymentVariant) {
    return await axios.post(`/orders/create/frompreparedcart/${orderPaymentVariant}`, data)
  },

  async deliveryDetailsDoor (data, orderPaymentVariant) {
    return await axios.post(`/orders/create/fromcart/${orderPaymentVariant}`, data)
  },

  async searchBuyer (phone) {
    return await axios.get(`/profile/public/${phone}`)
  },

  async deliveryDetailsQuoteReq (data, orderUuid, orderPaymentVariant) {
    return await axios.post(`/orders/create/fromquoterequest/${orderUuid}/${orderPaymentVariant}`, data)
  },
  async deliveryDetailsOrderReq (data, orderUuid, orderPaymentVariant) {
    return await axios.post(`/payments/pay/order/${orderUuid}/paymentVariant/${orderPaymentVariant}`, data)
  },

  async requestQuote (data) {
    return await axios.post(`/quoterequests`, data)
  }
}
export default cartService

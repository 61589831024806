import React, { FC, useEffect, useState } from 'react' 
import "./EnterpriseWarehouseDetails.scss"  
import EnterprisePageTitle from '../../../../../../../Components/Enterprise/EnterprisePageTitle/EnterprisePageTitle';
 
import { Inventory, Sites } from '../../../Components';
import { useParams } from 'react-router-dom';
import wareHouseService from '../../../../../../../Services/wareHouseService';
import { Logger } from '../../../../../../../Helpers/Logger';
import { wareHouse } from '../../../../../../../Models/enterprise.types';
 
interface propsType {
  module? : string
}
const EnterpriseWarehouseDetails:FC<propsType> = ({module}) => { 
    const [isActive, setIsActive] = useState("inventory")
    const [warehouseData, setWarehouseData] = useState<wareHouse>()
 
    const {warehouseUuid} = useParams<{warehouseUuid:string}>()

    const getWareHouseDetails = async (warehouseUuid:string) => {
      await wareHouseService.getDetails(warehouseUuid)
      .then((result)=>{
        Logger("WH result", result.data.data)
        setWarehouseData(result.data.data)
      })
    }
    useEffect(()=>{
        warehouseUuid && getWareHouseDetails(warehouseUuid)
    }, [warehouseUuid])

    return (
        <div className='enterpriseWarehouseDetails'>
             <div className="container">
                <EnterprisePageTitle
                    title={warehouseData?.name? warehouseData?.name:"loading..."}
                    parent="Warehouse Management "
                    parentUrl="warehouse-management"
                    showTitle={false}
                />


                <div className="order-management-menu">
                <ul className="nav sub-nav">
                    <li className="nav-item">
                      <span className={isActive==="inventory" ? (module && module==="estate"? "nav-link active-estate": "nav-link active") : "btn nav-link"} onClick={()=>setIsActive("inventory")}>Inventory</span>
                    </li>
                    <li className="nav-item">
                      <span className={isActive==="sites" ?  (module && module==="estate"? "nav-link active-estate": "nav-link active") : "btn nav-link"} onClick={()=>setIsActive("sites")}>Sites </span>
                    </li> 
                </ul>
                </div>

                {isActive==="inventory" && <Inventory warehouseData={warehouseData} module={module}></Inventory>}
                {isActive==="sites" && <Sites warehouseData={warehouseData} module="estate"></Sites>}
 
             
            </div>
 


        </div>
    )
}

export default EnterpriseWarehouseDetails

import * as yup from "yup";

export const ValidationSchema =  yup.object({
    
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        phoneNumber: yup.string().required("Your phone number is required"),
        emailAddress: yup.string().email("Invalid email format").required("Your email address is required"),
        stateResidence: yup.string().required("Your state of residence is required"),
        bvn: yup.string().required("Your bvn is required"),
        businessType: yup.string().required("Please select your business type"),
        cacNumber: yup.string().required("CAC number is required"),
        companyName: yup.string().required("Please provide company  name"),
        companyAddress: yup.string().required("company address is required"),
        jobTitle: yup.string().required("Job title is required"),
        modeOfDelivery: yup.string().required("Please choose your mode of delivery"),
        productCategoryUuid: yup.string().required("Product category is required"),
        productQuantity: yup.number().required("Product quantity is required"),
        principalAmountMajor: yup.number().required("Amount is required"),
        idCardNumber: yup.number().required("ID card number is required"),
      
})

export  const InitialFormValues = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: "",
        stateResidence: "",
        bvn:"",
        businessType: "",
        cacNumber: "",
        companyName: "",
        companyAddress: "",
        jobTitle: "",
        modeOfDelivery: "",
        productCategoryUuid: "",
        productQuantity: 0,
        principalAmountMajor: 0,
        currency: 'NGN',
        idCardNumber:'',
}

export  interface FormValuesType  {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        emailAddress: string;
        stateResidence: string;
        bvn:string;
        businessType: string;
        cacNumber: string;
        companyName: string;
        companyAddress: string;
        jobTitle: string;
        modeOfDelivery: string;
        productCategoryUuid: string;
        productQuantity: number;
        principalAmountMajor: number;
        idCardNumber:string;
}

export  interface FormErrorsType  {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        emailAddress: string;
        stateResidence: string;
        bvn:string;
        businessType: string;
        cacNumber: string;
        companyName: string;
        companyAddress: string;
        jobTitle: string;
        modeOfDelivery: string;
        productCategoryUuid: string;
        productQuantity: string;
        principalAmountMajor: string;
        idCardNumber:string;
}
import "./Step.scss";
import {FC, useEffect, useState, memo} from "react";

interface propTypes {
    currentStep:number;
    visitedSteps:number[];
    step:number
}



export const Step:FC<propTypes> = ({currentStep, visitedSteps, step}) => {
    const [isVisited, setIsVisited] = useState(false);
 
    useEffect(()=>{
        isStepVisted() 
    },[currentStep])
    const isStepVisted  = ()=>{
            if (step===currentStep) {
                setIsVisited(true)
                return
            }
        
            for (let i=0;  i<=visitedSteps.length; i++) {
                if (step===visitedSteps[i]) {
                    setIsVisited(true)
                     break;
                     // return;  
                }
                setIsVisited(false)
            }
        
    }
    return (
       <>
        {
            isVisited &&  <li className="step step-visited"></li>
        }

        {
            !isVisited && <li className="step step-unvisited"></li>
        }
       </>
    )
}

export  const MemoizedStep=memo(Step);
import React, { FC } from 'react'
import { AccountStats, Procurement, WalletInformation } from '../../Components'
import Expenses from '../../Components/EnterpriseDashboard/Expenses/Expenses'
import ProductCount from '../../Components/EnterpriseDashboard/ProductCount/ProductCount'
import ReorderProduct from '../../Components/EnterpriseDashboard/ReorderProduct/ReorderProduct'
import "./EnterpriseDashboard.scss"
import Builder360 from '../../Components/EnterpriseDashboard/Builder360/Builder360';
import { BasicRBAC } from '../../../../../../Helpers/BasicRBAC'; 

 

const EnterpriseDashboard:FC = () => {
     
    const access = BasicRBAC 
    return (
        <div className='enterpriseDashboard'>
             <div className="container">
                <div className="row">
                    {access('WalletInformation') === true && <div className="col-md-4">
                        <WalletInformation></WalletInformation>
                    </div>}
                    {access('AccountStats') === true && <div className="col-md-8">
                        <AccountStats></AccountStats>
                    </div>}
                    {access('Procurement') === true &&  <div className="col-md-4">
                        <Procurement></Procurement>
                    </div>}
                    {access('Builder360') === true && <div className="col-md-8">
                        <Builder360></Builder360>
                    </div>}
                    {access('Product-Count') === true && <div className="col-md-4">
                        <ProductCount></ProductCount>
                    </div>}
                    {access('Expenses') === true && <div className="col-md-8">
                        <Expenses></Expenses>
                    </div>}
                    {access('Reorder-Product') === true && <div className="col-12">
                        <ReorderProduct></ReorderProduct>
                    </div>}
                </div>
             </div>
        </div>
    )
}

export default EnterpriseDashboard

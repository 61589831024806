import React, { FC } from 'react'
import { Link } from 'react-router-dom';
import "./CreatingLoading.scss"

interface propsType {
    progress:string;
    progressCompleted:boolean;
    progressError:any;
    setProgressStart:(value:boolean)=>void
    setProgressError:(value:boolean)=>void

}

export const CreatingLoading:FC<propsType> = ({ progress, progressCompleted, progressError, setProgressStart, setProgressError }) => {
  return (
    <div className='creatingLoading'>
        <div className="row h-100 justify-content-center">
            {!progressCompleted && progressError === false && <div className="col-12 col-md-4 my-auto">
                <div className="card m-1" data-aos="fade-up">
                    <div className="card-body card-body-loading">
                         <div className="spiner-container">
                             <span className="spinner-border"></span>
                         </div>
                         <div className="text text-start">
                            <div className="title">Creating product, please wait...</div>
                            <div className='progress-bar text-start'>{progress}</div>
                         </div>
                    </div>
                </div>
            </div>}
            {progressCompleted && progressError === false &&
             <div className="col-12 col-md-4 my-auto">
                <div className="card m-1" data-aos="fade-up">
                    <div className="card-body card-body-loading">
                         <div className="spiner-container">
                            <div className="sp-container bg-success rounded-circle d-flex justify-content-center">
                                <i className="d-icon d-check"></i>
                            </div>
                         </div>
                         <div className="text text-start">
                            <div className="title">Product created succesfully</div>
                            <div className='progress-bar text-start'>Product was created successfully,<br></br> click on close button below to continue.</div>

                            <div className="text-left border-0 bg-white mt-2">
                                <Link to="/seller/product"><button className='btn btn-outline-danger btn-sm'>Close</button></Link>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
             }

             {!progressCompleted && progressError !== false && <div className="col-12 col-md-4 my-auto">
                <div className="card m-1" data-aos="fade-up">
                    <div className="card-body card-body-loading">
                         <div className="spiner-container">
                         <i className="d-icon d-close"></i>
                         </div>
                         <div className="text text-start">
                            <div className="title">Oops an error occurred</div>
                            <div className='progress-bar text-start'>{progressError}</div>
                         </div>
                    </div>
                    <div className="card-footer text-center border-0 bg-white">
                            <button className='btn btn-outline-danger' onClick={() => {
                              setProgressStart(false);
                              setProgressError(false);
                            }}>Close</button>
                    </div>
                </div>
            </div>}

         
        </div>
    </div>
  )
}

import WhatsAppLogo from "../../../../../../../Assets/Images/icon/whatsapp.png";
import "./ChatWithUsOnWhatsApp.scss"; 

const ChatWithUsOnWhatsApp = () => {
    return (
        <div className="give-us-a-call bg-white">
            <a className="w-full d-flex flex-column align-items-center" 
                href="https://wa.me/+2349168777239" target={"_blank"} rel="noreferrer"
            >
                <div className="call-icon-container">
                    <img src={WhatsAppLogo} alt='Call us' />
                </div>
                <div className="fw-bolder fs-5">Chat with us on Whatsapp</div>
                <div>+234 916 877 7239</div>
            </a> 
        </div>
    )
}

export default ChatWithUsOnWhatsApp;
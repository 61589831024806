import React from 'react'
import "./EstateManagerSidebar.scss"
import EstateManagerSidebarContent from './EstateManagerSidebarContent/EstateManagerSidebarContent'
 
 
const EstateManagerSidebar = () => {
  return (
        <div className='estateManagerSidebar d-none d-lg-block'>
          <EstateManagerSidebarContent></EstateManagerSidebarContent>
        </div>
  )
}

export default EstateManagerSidebar

import React from 'react'
import "./Transaction.scss"

const Expenses = () => {
    return (
        <div className='transaction'>
            <div className="card">
                <div className="card-header border-0 bg-white">
                    <div className="row">
                        <div className="col-md-10"> 
                        Transaction
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="card-body"> 
                    <div className="no-product">
                        <div className="">
                            <div className="d-block text-center">
                                <svg width="89" height="88" viewBox="0 0 89 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M81.1667 22.0002V30.8735C81.1667 36.6668 77.5001 40.3335 71.7067 40.3335H59.1667V14.7035C59.1667 10.6335 62.5034 7.3335 66.5734 7.3335C70.5701 7.37016 74.2367 8.98349 76.8767 11.6235C79.5167 14.3002 81.1667 17.9668 81.1667 22.0002Z" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.83325 25.6668V77.0002C7.83325 80.0435 11.2799 81.7668 13.6999 79.9335L19.9699 75.2402C21.4366 74.1402 23.4899 74.2868 24.8099 75.6068L30.8966 81.7302C32.3266 83.1602 34.6733 83.1602 36.1033 81.7302L42.2633 75.5702C43.5466 74.2868 45.5999 74.1402 47.0299 75.2402L53.2999 79.9335C55.7199 81.7302 59.1666 80.0068 59.1666 77.0002V14.6668C59.1666 10.6335 62.4666 7.3335 66.4999 7.3335H26.1666H22.4999C11.4999 7.3335 7.83325 13.8968 7.83325 22.0002V25.6668Z" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M33.5 47.7031H44.5" stroke="#C9CACB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M33.5 33.0366H44.5" stroke="#C9CACB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M22.4839 47.6665H22.5168" stroke="#C9CACB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M22.4839 33H22.5168" stroke="#C9CACB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="d-block text-center"> 
                                No transaction recorded yet
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expenses

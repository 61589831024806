/* eslint-disable no-unused-vars */
import * as React from "react";
import "./SellerQuoteResponseModal.scss"
import { Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { toast } from "react-hot-toast";
import { BsCheckCircle } from "react-icons/bs";
import { NavLink } from "react-router-dom"; 
import quoteRequestService from "../../../../../Services/quoteRequestService";


interface IQuoteResponseProps {
  show: boolean;
  setShow: any
  quoteId: string;
  orderReceiveType: string;
}

interface formType {
  unitPrice: number,
  deliveryFee: number
}

const SellerQuoteResponseModal: React.FunctionComponent<IQuoteResponseProps> = (
  props
) => {
  const { show, setShow, quoteId, orderReceiveType } = props;


  const { register, handleSubmit, formState: { errors } } = useForm<formType>()

  const [savingCost, setSavingCost] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  
  const onsubmit = handleSubmit(async (data: any) => {
    setSavingCost(true)

    const serializedData = {
      unitPrice: data.unitPrice,
      deliveryFee: data.deliveryFee ? data.deliveryFee : null
    }

    await quoteRequestService.saveCost(quoteId, serializedData).then((res: any) => { 
      setSavingCost(false)
      toast.success("Quote sent", { duration: 20000, className: 'bg-success text-white' });
      setShowSuccess(true) 
    }, (error: any) => {
      setSavingCost(false) 
      toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
    })
    setSavingCost(false)
  }
  )

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Modal
      className="seller-quote-response-modal ms-auto me-auto"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >

      <Modal.Header closeButton>
        <Modal.Title className="border-0">
          <p id="requestResponse">Respond to request </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="wallet-modal pb-5 ">
        {showSuccess ?
          <div className="success mt-0">
            <BsCheckCircle className="success-icon" />
            <div className="success-info text-center">
              <p>Success</p>
              <div className="success-info-detail">
                <p>
                  You have successfully responded to this quote. 
                </p>
              </div>
            </div> 
              <button
                type="submit"
                className="btn-store-setup"
                onClick={handleClose}>
                Done
              </button> 
          </div> :
          <form onSubmit={onsubmit}>
            <div className="form-input">
              <p>Fill in the details below to send a quote to the buyer</p> 
              
              <div className="form-group mt-3"> 
                <div className="input-wrap">
                  <div className="input-symbol">₦</div>
                  <label htmlFor="unitPrice">Unit Price</label>
                  <input type="number" className="form-control mt-0" placeholder="Unit Price" {...register("unitPrice", { required: "Price field is required" })}></input>
                  {errors.unitPrice && <div className='text-danger'>{errors.unitPrice.message}</div>}
                </div>
              </div>

              {orderReceiveType === "DELIVERY" &&
              <div className="form-group mt-3">
                <div className="input-wrap">
                  <div className="input-symbol">₦</div>
                  <label htmlFor="unitPrice">Delivery fee</label>
                  <input type="number" className="form-control mt-0" placeholder="Delivery Fee" {...register("deliveryFee")}></input>
                  {errors.deliveryFee && <div className='text-danger'>{errors.deliveryFee.message}</div>}
                </div>
              </div>}
              
              <div className="form-group mt-3">
                {savingCost && (
                <button
                  className="btn  rounded-5 wallet-btn w-100 "
                  disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                  Sending response...
                </button>
              )}

              {!savingCost && (
                <button className="btn rounded-5 wallet-btn w-100">
                  Respond to request
                </button>
              )} 
              </div>  
            </div>

            {/* {orderReceiveType === "DELIVERY" &&
              (
                <>

                  <div className="input-wrap">
                    <div className="input-symbol">N</div>
                    <label htmlFor="unitPrice">Delivery fee</label>
                    <input
                      id="unitPrice"
                      type="number"
                      className="form-control"
                      {...register("amountMajor", {
                        required: "this field is required",
                      })}
                    />
                  </div>
                </>
              )
            } */}
            
            {/* {!savingCost && (
              <button className="btn rounded-5 wallet-btn w-100 mt-5">
                Respond to request
              </button>
            )}


            {savingCost && (
              <button
                className="btn  rounded-5 wallet-btn w-100 "
                disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>
                Sending response...
              </button>
            )} */}
          </form>}
      </Modal.Body>
    </Modal>
  );
};

export default SellerQuoteResponseModal;

import React from 'react'
import "./PendingQuoteRequest.scss"
import { Link } from 'react-router-dom';
import { MiniLoader } from '../../../../../../../Components';

const PendingQuoteRequest = () => {
  return (
    <div className='quote-request'>
      <MiniLoader show={false}></MiniLoader>
      <div className="card py-3">
        <div className="card-header w-100">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-9">
                <div className="title">
                Pending Quote Request
                </div>
              </div>
              <div className="col-3">
                <Link to="/seller/my-quotes" className='text-end see-link'>See all</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
        <div className="container-fluid px-1">
          <div className='col-12 text-center'> No item found</div>
        </div>
        </div>
      </div>
  </div>
  )
}

export default PendingQuoteRequest

import { useState } from "react";
import "./SellerSecurity.scss"
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { MiniLoader } from "../../../../../../../Components";
import userService from "../../../../../../../Services/userService";
import SubPageTitle from "../../../Component/Settings/SubPageTitle/SubPageTitle";
 
type formProp = {
  newPassword: string;
  currentPassword: string;
  reNewPassword: string;
}
const SellerSecurity = ()=>{  
  const [loadingRequest,] = useState<boolean>(false)
  const [loadingPassword, setLoadingPassword] = useState<boolean>(false)

  const { register: registerPass, handleSubmit: handleSubmitPass, getValues: getValuesPass, reset: resetPass, formState: { errors: errorsPass }, } = useForm<formProp>()

  const changePassword = handleSubmitPass((data:any) => processPassword(data))
  const processPassword = async (data: { newPassword: any; }) => { 
    setLoadingPassword(true)
    await userService.changePassword({ newPassword: data.newPassword }).then((res:any) => {
      setLoadingPassword(false)
      toast.success("Password updated successfully", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });

      resetPass()
    }, (error:any) => {
      setLoadingPassword(false)
      toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }
  
    return  (
       <div className="seller-profile-page">
            <SubPageTitle
                title="Change Password"
                parents={[
                    {
                        title:"Settings",
                        url: "settings"
                    }
                ]}
            />
            <div className="row justify-content-center">
                <div className="col-12 col-md-9 col-lg-6 profile-item-container mb-4">
                     
                  <MiniLoader show={loadingRequest}/>
                  <div className="card border"> 
                      <div className="card-body"> 
                          <form onSubmit={changePassword}>
                                <div className='form-group my-3'>
                                  <label htmlFor="">Current Password</label>
                                    <input type="password" className="form-control" placeholder='Current password' {...registerPass("currentPassword", { required: "this field cannot be empty" })}/>
                                    <div className="text-danger"> {errorsPass.currentPassword && errorsPass.currentPassword.message} </div>
                                </div>
                                <div className='form-group my-3'>
                                  <label htmlFor="">New Password</label>
                                    <input type="password" className="form-control" placeholder='New password' {...registerPass("newPassword", { required: "this field cannot be empty" })}/>
                                    <div className="text-danger"> {errorsPass.newPassword && errorsPass.newPassword.message} </div>
                                </div>
                                <div className='form-group my-3'>
                                  <label htmlFor="">Confirm New Password</label>
                                    <input type="password" className="form-control" placeholder='Repeat new password' {...registerPass("reNewPassword", {
                                      required: "this field cannot be empty",
                                      validate: (value: any) => {
                                        const { newPassword } = getValuesPass();
                                        return newPassword === value || "Passwords does not match!";
                                      }
                                    })}/>
                                    <div className="text-danger"> {errorsPass.reNewPassword && errorsPass.reNewPassword.message} </div>
                                </div>
                                <div className='form-group my-3 text-center mt-5'>

                                    {!loadingPassword && (<button type="submit" className="btn security-btn-default btn-lg btn-block w-100">Save Password</button>)}
                                  {loadingPassword && (<button type="submit" className="btn security-btn-default btn-lg btn-block w-100" disabled>
                                      Please wait
                                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  </button>)}
                                </div>
                            </form>
                          </div>
                      </div> 

                <Toaster/>
                </div>
            </div>
       </div>
    )

}

export default SellerSecurity;
import React, { FC, PropsWithChildren } from 'react'

import "./BrandSkeleton.scss"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

interface propsType {
  cardCount: number
}
const BrandSkeleton:FC<propsType> = ({ cardCount }) => {
  function Box ({ children }: PropsWithChildren<unknown>) {
    return (
        <div
            style={{
              border: '1px solid #fff',
              display: 'block',
              lineHeight: 2,
              padding: '0rem',
              marginBottom: '0.1rem',
              width: "100%",
            }}
        >
            {children}
        </div>
    )
  }

  return (
    <React.Fragment>
   { [...Array(cardCount)].map((res:any, index:React.Key) => <div key={index} className="brandSkeleton col-6 col-md-2">
        <div className="card product-card border-0 shadow-sm">
            <div className="card-body">
                <Skeleton wrapper={Box} width="170px" height="70px"/>
            </div>
        </div>
    </div>)}
    </React.Fragment>
  )
}

export default BrandSkeleton

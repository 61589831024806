import WhatsAppLogo from "../../../../../../../Assets/Images/icon/whatsapp.png";
import "./ChatWithUsOnWhatsApp.scss";
import NextIcon from "../../../../../../../Assets/Images/icon/next.svg"

const ChatWithUsOnWhatsApp = () => {
    return (
        <div className="give-us-a-call bg-white">
            <a className="w-full d-flex flex-column align-items-center" 
                href="https://wa.me/+234898877666" target={"_blank"} rel="noreferrer"
            >
                <div className="call-icon-container">
                    <img src={WhatsAppLogo} alt='Call us' />
                </div>
                <p>Chat with us on Whatsapp</p>
                <p>+2349168777239</p>
            </a>
            <span className="next-icon">
                <img src={NextIcon} alt=''/>
            </span>
        </div>
    )
}

export default ChatWithUsOnWhatsApp;
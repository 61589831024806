import RatingIcon from "../../Assets/Images/icon/Rating.svg";
import "./Rating.scss";
import {FC} from "react";

interface propTypes {
    ratingValue:number,
    maxRating:number
}

const Rating: FC<propTypes>  = ({ratingValue, maxRating}) => {
    return (
        <div className="rating-component">
            <img className="rating-component-icon me-2" src={RatingIcon} alt=''/>
            {ratingValue && maxRating ? `${ratingValue}/5`: '0/5'}
        </div>
    )
} 

export default Rating
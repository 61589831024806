
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../Helpers/ErrorHandler";   
import invoiceService from "../../../Services/invoiceService";

export type TApiResponse = {
  isSuccess: boolean;
  data:any[];
  isLoading :boolean;
};
 
export const useAllInvoice = (page:number, sortOrder: string) => {
  return useQuery(["invoice"], async () =>  await invoiceService.getAllList(page, sortOrder), {

    enabled: true,
    retry: 1,
    staleTime: Infinity,
    cacheTime: Infinity,
    select: (data:{ data: { data: {dataset: {
      [x: string]: any; data:any[]
} } }}) => { 
      return data.data.data.dataset
    }, 
    onError (error: any) {
      if (Array.isArray((error as any).response.data.error)) {
        (error as any).data.error.forEach((el: any) =>
          ErrorHandler(error,
            () => {
              toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        );
      } else {
        ErrorHandler(error,
          () => {
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    },
  });
};

/* eslint-disable space-before-function-paren */
/* eslint-disable no-unused-vars */
import toast, { Toaster } from "react-hot-toast";
import "./BusinessInfoUpload.scss";
import addFolderIcon from "../../../../Assets/Images/icon/folder-add.svg";
import { Accept, useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { CAC_DOCUMENT_UPLOAD } from "../../../../Constants/SellerDocuments";
import { useOutletContext } from "react-router-dom";
import {
  LegacyRef,
  useCallback,
  useState,
  useEffect,
  SetStateAction,
  FC,
} from "react";
import uploadSellerDocumentService from "../../../../Services/uploadSellerDocumentService";
import { useLoggedInUser } from "../../../../Hooks";



interface propTypes {
  handleFileUpload: any
}


const BusinessInfoUpload: FC<propTypes> = ({ handleFileUpload }) => {
  const dispatch = useDispatch();
  const userObj = useLoggedInUser()

  const [progress, setProgress] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [uploadLoading, setUploadLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [event, setEvent] = useState({ total: 0, loaded: 0 })
  const submitRef = useOutletContext();
  const [files, setFiles] = useState<string[] | any>([]);
  const [files_b, setFiles_b] = useState<string[] | any>([])

  const { getRootProps: getRootFilesProps, getInputProps: getInputFilesProps } = useDropzone({
    onDrop: useCallback<any>(
      (acceptedFiles: {
        map: (arg0: (file: any) => any) => SetStateAction<never[]>;
      }) => {
        // console.log("ac", acceptedFiles);
        setFiles(
          acceptedFiles.map((file: Blob | MediaSource) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      []
    ),
    accept: "image/jpeg, image/png" as unknown as Accept,
    maxFiles: 1,
    onDropAccepted(files, event) {
      handleFileUpload(event, files)
    },
  });

  const { getRootProps: getRootFiles_bProps, getInputProps: getInputFiles_bProps } = useDropzone({
    onDrop: useCallback<any>(
      (acceptedFiles: {
        map: (arg0: (file: any) => any) => SetStateAction<never[]>;
      }) => { 
        setFiles_b(
          acceptedFiles.map((file: Blob | MediaSource) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      []
    ),
    accept: { "image/jpeg, image/png": [] },
    maxFiles: 2,
    onDropAccepted(files, event) {
      handleFileUpload(event, files)
    },
  });

  const deleteFiles = () => {
    setFiles([])
  }
  const deleteFiles_b = () => {
    setFiles_b([])
  }

  const thumbs = files.map((file: any) => (
    <div key={file.name} className="input-div">
      <img
        src={file.preview}
        alt={file.name}
        className="input-icon"
      />
      <aside>{file.name}</aside>
      <span onClick={deleteFiles} style={{ fontSize: "8px" }}>x</span>
    </div>
  ));

  const thumbs_b = files_b.map((file: any) => (
    <div key={file.name} className="input-div">
      <img
        src={file.preview}
        alt={file.name}
        className="input-icon"
      />
      <aside>{file.name}</aside>
      <span onClick={deleteFiles_b} style={{ fontSize: "8px" }}>x</span>
    </div>
  ));


  useEffect(() => {
    files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    files_b.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [files, files_b]);

  const formData = new FormData()
  formData.append('files_a', files)
  formData.append('file_b', files_b)
  console.log('formData: ', formData);

  // const onSubmit = async () => {
  //   await uploadSellerDocumentService.upload("SELLER_CAC_DOCUMENT", userObj.uuid, formData, (event: { loaded: number; total: number }) => {
  //     setProgress(Math.round((100 * event.loaded) / event.total));
  //     setEvent(event)
  //   })
  //     .then((response) => {
  //       setUploadLoading(false)
  //       console.log("FILE UPLOAD", response)
  //       setMessage(response.data.message);
  //     })
  //     .catch((error: any) => {
  //       setUploadLoading(false)
  //       console.log("FILE UPLOAD ERROR", error)
  //       setProgress(0)
  //       setMessage('Could not upload the file!');
  //       // setCurrentFile(undefined)
  //       toast.error(error.message, { duration: 20000, className: 'bg-danger text-white' });
  //     });
  //   const data = { cacCertificate: files[0], identificationCard_front: files_b[0], identificationCard_back: files[1] }
  //   console.log("data", data);
  //   dispatch({ type: CAC_DOCUMENT_UPLOAD, payload: data });
  //   deleteFiles();
  //   deleteFiles_b();
  // };

  return (
    <div className="certificate">
      <div className="row">
        <div className="col-md-8">
          <div className="card cac-form-card p-6  border-0">
            <h3>Upload CAC Certificate</h3>
            <p>
              Upload PDF, JPG of your Company Registration Certificate (CAC)
            </p>
            <div className="card-body p-0">
              <div className="row">
                <div className="cac-form col-12 col-sm-12 col-sm-left col-md-12 col-lg-6 p-0  d-lg-block">

                  <div {...getRootFilesProps()} className="form-group mt-4">
                    <label htmlFor="cacCertificate">
                      {files.length === 0 ? (
                        <div className="input-div">
                          <img
                            className="input-icon"
                            src={addFolderIcon}
                            alt="folder icon"
                          />{" "}
                          <p> Drag and drop or browse your file</p>
                        </div>
                      ) : (
                        <aside>{thumbs}</aside>
                      )}

                      <input {...getInputFilesProps()} />
                    </label>
                  </div>
                  <h3 style={{ marginLeft: "20px", fontSize: "24px" }}>Upload ID Card</h3>
                  <p style={{ marginLeft: "20px" }}>
                    Upload front and back image of your Government Issued
                    Identity Card i.e NIN Card/Slip, International Passport,
                    Driver’s License.
                  </p>
                  <div {...getRootFiles_bProps()} className="form-group mt-4">
                    <label htmlFor="identificationCard">
                      {files_b.length === 0 ? (
                        <div className="input-div ">
                          <img
                            className="input-icon"
                            src={addFolderIcon}
                            alt="folder icon"
                          />{" "}
                          <p>Drag and drop or browse your file</p>
                        </div>
                      ) : (
                        <aside className="d-flex">{thumbs_b}</aside>
                      )}

                      <input {...getInputFiles_bProps()} />
                      {/* id={name}
                      type="file"
                      accept="image/*"
                      className="form-control file-upload-input"
                      onChange={(event) => changeHandler(event, doc)}
                      value={value}
                      name={name} */}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};
export default BusinessInfoUpload;

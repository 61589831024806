import EventBus from './EventBus';
import * as Sentry from "@sentry/react";
import { Logger } from "./Logger";

export function ErrorHandler (error: { response: { status: number; data:any }; message: string; }, alertItem: (e:any)=>void,) {
  Sentry.captureException(error);

  if (error.response && error.response?.data?.detailedError?.standardizedErrorCode === "TokenExpiredError" && error.response.data.error !== 'User credentials are wrong.') {
    EventBus.dispatch("logout", "session token expired");
    localStorage.removeItem('__cbu')
    window.location.href = "/login"
  }

  if (error.response && error.response.status === 401 && error.response.data.error !== 'User credentials are wrong.') {
    EventBus.dispatch("logout", "session timeout");
    return alertItem(error.response.data.error)
  }
  Logger("error", error.response)
  if (error.message === "Network Error" && error.response.data.error !== 'User credentials are wrong.') {
    window.location.href = "network-error"
  } else {
    return alertItem(error.response.data.error)
  }
}

import React, { useEffect, useState } from 'react'
import "./EnterpriseHistory.scss" 
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService'
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter'
import Pagination from '../../../../../../../Components/Pagination/Pagination'
import toast, { Toaster } from 'react-hot-toast'

import { Link } from "react-router-dom"
import { CurrencyFormatter } from '../../../../../../../Helpers/CurrencyFormatter';

const EnterpriseHistory = () => {
  const [sortOrder,] = useState("DESC")
  const [historyData, setHistoryData] = useState([])
  const [page, setPage] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [total, setTotal] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)

  const accountHistory = async () => {
    setLoadingRequest(true)
    await bankingFinanceService.walletHistory(pageNumber, sortOrder)
      .then((res:any) => {
        setLoadingRequest(false)
        setHistoryData(res.data.data.dataset)
        setPage(res.data.data.pageSize)
        setPageNumber(res.data.data.pageNumber)
        setTotal(res.data.data.total) 
      }, (error) => {
        setLoadingRequest(false) 
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    accountHistory()
  }, [])

  return (
        <div className='enterpriseHistory'>
            <div className="row">
                <div className="col-md-10">
                    <div className='history-title'>Transaction History</div>
                </div> 
                <div className="col-md-2">

                {historyData.length === 0 && <div className="dropdown">
                        <Link to="/enterprise/wallet-history">
                            <button className="btn btn-sm border-0" type="button"> 
                                <span>See all Transactions</span>
                            </button> 
                        </Link>
                    </div>}
                </div>
            </div>
            <div className='history-inner mt-4'>
              <table className="table">
                <tbody>
                {historyData.length === 0 && <tr> 
                    <td colSpan={1} className="history-column-empty w-100 text-center">
                        <div className=" w-100 history-column-empty">
                          No transaction yet
                         </div>
                    </td>
                </tr>}
                {historyData.length > 0 && historyData.map((resData:any, index:React.Key) => {
                  return (<tr key={index}>
                        <td className='history-column w-100 py-5 py-sm-4'>
                            <div className="row w-100">
                                <div className="col-8 d-flex align-items-center">
                                {resData === "out"
                                  ? <svg width="17" height="17" className='me-3' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.00141 18.2495C4.81141 18.2495 4.62141 18.1795 4.47141 18.0295C4.18141 17.7395 4.18141 17.2595 4.47141 16.9695L18.4714 2.96945C18.7614 2.67945 19.2414 2.67945 19.5314 2.96945C19.8214 3.25945 19.8214 3.73945 19.5314 4.02945L5.53141 18.0295C5.38141 18.1795 5.19141 18.2495 5.00141 18.2495Z" fill="#00C12B"/>
                                    <path d="M15.27 18.2505H5C4.59 18.2505 4.25 17.9105 4.25 17.5005V7.23047C4.25 6.82047 4.59 6.48047 5 6.48047C5.41 6.48047 5.75 6.82047 5.75 7.23047V16.7505H15.27C15.68 16.7505 16.02 17.0905 16.02 17.5005C16.02 17.9105 15.68 18.2505 15.27 18.2505Z" fill="#00C12B"/>
                                    <path d="M20.5 22.75H3.5C3.09 22.75 2.75 22.41 2.75 22C2.75 21.59 3.09 21.25 3.5 21.25H20.5C20.91 21.25 21.25 21.59 21.25 22C21.25 22.41 20.91 22.75 20.5 22.75Z" fill="#00C12B"/>
                                    </svg>
                                  : <svg width="17" height="17" className='me-3' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 22.75H20.5C20.91 22.75 21.25 22.41 21.25 22C21.25 21.59 20.91 21.25 20.5 21.25H3.5C3.09 21.25 2.75 21.59 2.75 22C2.75 22.41 3.09 22.75 3.5 22.75Z" fill="#FFA685"/>
                                <path d="M4.9986 18.2495C5.18859 18.2495 5.37859 18.1795 5.52859 18.0295L19.5286 4.02945C19.8186 3.73945 19.8186 3.25945 19.5286 2.96945C19.2386 2.67945 18.7586 2.67945 18.4686 2.96945L4.4686 16.9695C4.1786 17.2595 4.1786 17.7395 4.4686 18.0295C4.6186 18.1795 4.8086 18.2495 4.9986 18.2495Z" fill="#FFA685"/>
                                <path d="M19 14.52C19.41 14.52 19.75 14.18 19.75 13.77V3.5C19.75 3.09 19.41 2.75 19 2.75H8.73C8.32 2.75 7.98 3.09 7.98 3.5C7.98 3.91 8.32 4.25 8.73 4.25H18.25V13.77C18.25 14.18 18.59 14.52 19 14.52Z" fill="#FFA685"/>
                                </svg>
                                }
                                    <div>
                                        <b>{resData.description}</b>
                                        <div className='timeTrans'>{TimeConverter(resData.createdAt)}</div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className='amount p-0'>{resData.currencySymbol}{CurrencyFormatter(resData.amountMajor)}</div>
                                    <div className='desc d-sm-none'>
                                        {resData.type === 'external_to_fund_wallet' && <><svg width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4"/></svg> Top up</>}
                                        {resData.type === 'wallet_funds_withdrawal' && <><svg width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4"/> </svg> Withdraw</>}
                                        {resData.type === 'external_to_pay_for_order' && <><svg width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#FFA685"/></svg> Purchase</>}
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className='desc'> 
                                        {resData.type === 'external_to_fund_wallet' && <><svg className='me-3' width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4"/></svg> Top up</>}
                                        {resData.type === 'wallet_funds_withdrawal' && <><svg className='me-3' width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4"/> </svg> Withdraw</>}
                                        {resData.type === 'external_to_pay_for_order' && <><svg className='me-3' width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#FFA685"/></svg> Purchase</>}
                                        {resData.type === 'project_subscription_payment' && <><svg className='me-3' width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#FFA685"/></svg> Subscription payment</>}
                                    </div>
                                </div> 
                            </div>
                        </td>
                    </tr>)
                })}
                </tbody>
              </table>  
            </div>

            {historyData.length > 10 && <div className="pagination">
                {total > 0 && <Pagination
                className="pagination-bar"
                currentPage={pageNumber}
                totalCount={total}
                pageSize={page}
                onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                }
            </div>}
            <Toaster/>
        </div>
  )
}

export default EnterpriseHistory

import { NEW_DELIVERY_ADDRESS } from "../../Constants/CheckoutConstants";

const initialstate = {
  address: null,
  country: null,
  state: null,
  contactFullName: null,
  contactPhoneNumber: null,
  firstname: null,
  lastname: null,
  password: null,
}
const NewDeliveryAddressReducer = (state = initialstate, action: { type: string; payload: number; }) => {
  switch (action.type) {
    case NEW_DELIVERY_ADDRESS:
      return action.payload
    default:
      return state
  }
}

export default NewDeliveryAddressReducer;

import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const productFinancingService = {
  async sendApplication (formData) {
    return axios.post(`/productlease/productlease/request`, formData);
  },
  
  async getStatus () {
    return axios.get('/productlease/productlease/status');
  },

  async exitProductFinance () {
    return axios.post('/productlease/productlease/leaveintent');
  },
  async getHistory (uuid, page, sort) {
    return axios.get(`/productlease/productlease/${uuid}/payments/history?pageNumber=${page}&sortOrder=${sort}`);
  },

  file: async (Uuid, data, imgType) => {
    return await axios.put(`/upload?fileUploadCategory=${imgType}&entityUuid=${Uuid}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
}
export default productFinancingService

import React, { FC, Fragment } from 'react'
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { HorizontalLoader, ImageSrc, Pagination } from '../../../../../../../Components';
import { CurrencyFormatter } from '../../../../../../../Helpers';
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter';
import wareHouseService from '../../../../../../../Services/wareHouseService';
import "./Deliveries.scss"

interface propType {
    isSuccess: boolean;
    loadingRequest: boolean;
    myOrders: any;
    setPage: (value:React.SetStateAction<number>)=>void;
    fetchingDelivery: boolean;
}

    const Deliveries:FC<propType> = ({isSuccess, myOrders, loadingRequest, setPage, fetchingDelivery}) => {
 
    const declicneOrder = async (warehouseUuid:string, deliveryRequestUuid:string) => {
        const loading = toast.loading("please wait");
        await wareHouseService.getSiteOrderDecline(warehouseUuid, deliveryRequestUuid, {status:"DELIVERY_FEE_REJECTED"})
          .then((res)=>{
            // console.log("res", res)
              toast.dismiss(loading)
          }, (error:any) => {  
            // console.log("res error", error)
            toast.dismiss(loading)
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
    }
 

    return (
        <Fragment>
          <div className="delivery "> 
            <table className="table rounded border-0"> 
                    <thead className='rounded'>
                        <tr className='d-none d-md-table-row'> 
                            <th scope="col">Address</th>
                            <th scope="col">Site</th>
                            <th scope="col">Warehouse </th>
                            <th scope="col">Date </th>
                            <th scope="col">Status</th>
                            <th scope="col">Delivery Fee</th>  
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {isSuccess && myOrders?.dataset?.length > 0 && myOrders.dataset?.map((res: { deliveryFeeAmountMajor: any;  uuid:string; wareHouseDetails:{uuid:string; name: string}; deliverySiteDetails: { uuid: string; address: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined; state: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined; country: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined; }; createdAt: any; status: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined; totalAmountMajor: number; orderUuid: any; }, index: React.Key | null | undefined) => {
                        return (
                            <Fragment key={index}>
                        <tr className='d-none d-md-table-row'>
                            <td>
                                {res.deliverySiteDetails.address},
                                {res.deliverySiteDetails.state},
                                {res.deliverySiteDetails.country}.
                            </td>
                            <td> {res.deliverySiteDetails.state} site.</td>
                            <td> {res.wareHouseDetails.name}.</td>
                            <td>  
                                {TimeConverter(res.createdAt)}
                            </td>
                            <td>
                                {res.status === "REQUESTED" && <span className='requested-status'> Requested</span>}
                                {res.status === "DELIVERY_FEE_SET" && <span className='delivery-fee-set-status'>Fee set</span>}
                                {res.status === "DELIVERY_FEE_ACCEPTED" && <span className='delivery-fee-set-status'>Fee accepted</span>} 
                                {res.status === "DELIVERY_ITEMS_SHIPPED" && <span className='delivery-fee-set-status'>Shipped</span>} 
                            </td>
                            <td> {res.deliveryFeeAmountMajor?<>&#8358; {CurrencyFormatter(res.deliveryFeeAmountMajor)}</>: ""}</td> 
                            <td>
                                {res.status === "DELIVERY_FEE_SET" && <Link to={`/enterprise/order-management/ship-to-site/detail/${res.uuid}`}>
                                    <button className='btn status-pending'>Pay Now</button>
                                </Link>}

                                {res.status === "REQUESTED" && <button className='btn status-pending border-danger text-danger' onClick={()=>declicneOrder(res.wareHouseDetails.uuid, res.uuid)}>Cancel</button> }
                            </td> 
                        </tr>  
                        <tr className='d-md-none'>
                            <td>
                                <ImageSrc src={''} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                            </td>
                            <td colSpan={4}>
                                <div className="row"> 
                                    <div className='col-12 profile-title'>
                                    {res.deliverySiteDetails.state} site. 
                                    </div>
                                    <div className='col-12 profile-detail'> 
                                    {res.deliverySiteDetails.address},
                                        {res.deliverySiteDetails.state},
                                        {res.deliverySiteDetails.country}.
                                    </div> 
                                    <div className='col-12 profile-detail'> {res.status}</div>
                                    <div className='col-12 profile-detail'>{ TimeConverter(res.createdAt)}</div>
                                </div>
                            </td>
                            <td className='profile-btn'>
                            <Link className="dropdown-item" to={`/enterprise/ship-to-site/detail/${res.uuid}`}>
                                <button type="button" className="btn btn-sm p-1 border d-md-none">
                                    View
                                </button> 
                            </Link>
                            </td>   
                        </tr>  
                        </Fragment>) 
                    })}
                    {(loadingRequest === false && fetchingDelivery=== false) && myOrders?.length === 0 &&  <tr>
                            <td colSpan={9}>
                                <div className='text-secondary text-center fw-bold'>No item found</div>
                            </td>
                        </tr>}
                    {(loadingRequest === true || fetchingDelivery=== true) && <tr>
                            <td colSpan={9}>
                                <div className='text-secondary text-center fw-bold'><HorizontalLoader></HorizontalLoader></div>
                            </td>
                        </tr>}
                    </tbody> 
                </table> 
            {myOrders?.total >= myOrders?.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                {myOrders?.total && <Pagination
                className="pagination-bar ps-0"
                currentPage={Number(myOrders.pageNumber)}
                totalCount={myOrders.total}
                pageSize={myOrders.pageSize}
                onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                }
            </div>}
     </div>
</Fragment>
    )
}

export default Deliveries

import axios from 'axios'

 
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()


const invoiceService = {
  getAllList: async (page: number, sortOrder: string) => {
    return await axios.get(`invoice?pageNumber=${page}&sortOrder=${sortOrder}`)
  },

  getSingle: async (uuid:string|undefined) => {
    return await axios.get(`procurements/invoice/${uuid}`)
  },
 
  reject: async (uuid:string) => {
    return await axios.get(`procurementinvoiceorders/${uuid}/decline`)
  },

  pay: async (uuid:string, data:any) => {
    return await axios.post(`procurementinvoiceorders/create/procurmentinvoice/${uuid}/WALLET`, data)
  },

  file: async (data: any, imgType: any, onUploadProgress: any) => { 
    return await axios.put(`/upload?fileUploadCategory=${imgType}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  },
  
}
export default invoiceService

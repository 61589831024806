import {FC} from "react";
import "./ContactDetails.scss";

interface propType {
    emailAddress:string,
    phoneNumber:string
}

const ContactDetails:FC<propType> = ({emailAddress, phoneNumber}) => {
    return (
        <div className="buyer-profile-contact-details">
           <p className="title">CONTACT DETAILS</p>
           <div className="item">
                <div className="details mb-2">
                    <p className="details-title">Email Address</p>
                    <p className="details-value">{emailAddress}</p>
                </div>
                <div className="details">
                    <p className="details-title">Phone Number</p>
                    <p className="details-value">{phoneNumber}</p>
                </div>
            </div> 
        </div>
    )
}

export default ContactDetails;
import React, { FC, useEffect, useState } from 'react'
import "./SellerProductVarientItem.scss"
import { Link } from 'react-router-dom'; 
import { PromptModal } from '../../../../../../Components';
import productService from '../../../../../../Services/productService';
import { Renderable, Toast, ValueFunction, toast } from 'react-hot-toast';

interface productItemPropsTypes {
    productItemValues: any
}

const SellerProductVarientItem:FC<productItemPropsTypes> = ({ productItemValues }) => {
  const [alertType,] = useState("trash"); 
  const [alertSubTitle,] = useState("Delete Product");
  const [id, setId] = useState(""); 
  const [alertMessage,] = useState("Are you sure you want to trash this item?");
 

  // eslint-disable-next-line no-unused-vars
  const [showDelete, setShowDelete] = useState(false);

  const handleShowDelete = (prodductUuid: any) => {
    setShowDelete(true);
    setId(prodductUuid)
  }

  const deleteProd = async () => {
    setShowDelete(false)
    const deleteId = toast.loading('Deleting product...');
    await productService.deleteProduct(id)
      .then((res: any) => { 
        toast.dismiss(deleteId);
        toast.success("Product deleted successfully", { duration: 25000, className: 'bg-success text-white', position: "top-right" });
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }, (error: { response: { data: { error: Renderable | ValueFunction<Renderable, Toast>; }; }; }) => {
         toast.error(error.response.data.error, { duration: 25000, className: 'bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    return () => toast.dismiss()
  }, [])

  const checkMultiPrice = () => {
    if (Number(productItemValues.price) !== 0) {
      return 0
    } else if (Number(productItemValues.price) === 0 && productItemValues.localGovernmentAreaPrices && productItemValues?.localGovernmentAreaPrices?.prices.length === 1) {
      return 1
    } else if (Number(productItemValues.price) === 0 && productItemValues.localGovernmentAreaPrices && productItemValues?.localGovernmentAreaPrices?.prices.length >= 2) {
      return 2
    } else if (!productItemValues.localGovernmentAreaPrices && Number(productItemValues.price) === 0) {
      return 3
    }
  }

  return (
    <div className="col-6 col-sm-6 col-md-6  col-lg-6 col-xl-4 p-2 p-sm-3 p-md-3">
        <div className="card popular-card product-card-seller my-1 p-0">
            <div className="card-body text-center p-2 p-sm-3 p-md-3">
                <div className="prodImg-container">
                    <div className="wishicon mb-2 text-end">
                        <div className="dropdown dropleft">
                            <button className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="d-icon d-more-vertical"></i>
                            </button>

                            <ul className="dropdown-menu dropdown-menu-start border-0 shadow" aria-labelledby="dropdownMenuLink"> 
                                <li><Link className="dropdown-item" to={`/seller/product/editvar/${productItemValues.productUuid}`}><i className='d-icon d-file-edit'></i> Edit</Link></li>
                                <li><a className="dropdown-item" href="#" onClick={() => handleShowDelete(productItemValues.productUuid)}><i className='d-icon d-trash'></i> Delete</a></li>
                            </ul>
                        </div>
                    </div>
                   <img src={productItemValues.images[0]?.url} className="w--50"/>
                </div>
                <div className="container-fluid px-2">
                    <div className="row  d-none d-lg-flex">
                        <div className="col-12">
                        <h5 className="popular-title mt-4 text-start">{productItemValues.productName}</h5>
                        </div>
                        <div className="col-4 mt-4 p-0">
                        {Number(productItemValues.price) !== 0 && <div className="prod-price text-start">{productItemValues.currencySymbol}{productItemValues.price}</div>}
                        {checkMultiPrice() === 1 && <div className="prod-price text-start">{productItemValues.currencySymbol}{productItemValues?.localGovernmentAreaPrices?.prices[0]?.price}</div>}
                        {checkMultiPrice() === 2 && <div className="prod-price text-start text-danger font-weight-bold"><Link to={`/seller/product/${productItemValues.productUuid}`} className="text-danger">See price list</Link></div>}

                        </div>
                        <div className="col-8 mt-4 p-0">
                            <div className="prod-rating text-end"> <i className='d-icon d-star text-warning'></i>  {productItemValues.totalNumberOfRatings / 20}/ 5 <span className="ms-1">(245 reviews)</span></div>
                        </div>
                    </div>
                </div>

                  <div className="row d-lg-none">
                    <div className="col-6 text-start">
                    {Number(productItemValues.price) !== 0 && <div className="prod-price text-start pt-2 text-danger font-weight-bold">{productItemValues.currencySymbol}{productItemValues.price}</div>}
                    {checkMultiPrice() === 1 && <div className="prod-price text-start">{productItemValues.currencySymbol}{productItemValues?.localGovernmentAreaPrices?.prices[0]?.price}</div>}
                    {checkMultiPrice() === 2 && <div className="prod-price text-start text-danger font-weight-bold"><Link to={`/seller/product/${productItemValues.productUuid}`} className="text-danger">See price list</Link></div>}

                    </div>
                    <div className="col-6  text-end">
                        <img src={productItemValues.sellerPublicProfile.photoUrl} className="w-50 text-right"/>
                    </div>
                    <div className="col-12">
                    <h5 className="popular-title mt-4 text-start">{productItemValues.productName}</h5>
                    </div>
                </div>
            </div>
            <div className="card-footer bg-white">
            <Link to={`/seller/product/details/${productItemValues.productUuid}`} className="buy-link">View</Link>
            </div>
        </div>
              {/* <EditProduct show={show} setShow={setShow} data={productData}></EditProduct> */}

        <PromptModal showModal={showDelete} alertType={alertType} message={alertMessage} action={ deleteProd} alertSubTitle={alertSubTitle}></PromptModal>

    </div>
  )
}

export default SellerProductVarientItem

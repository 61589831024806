import React, { Fragment } from "react";
import "./Share.scss"

function ShareModal ({ modalVisible, shareData, handleClose }:any) {
  return (
    <>
      <div className={`${"sare share-modal"} ${modalVisible ? "opened" : "closed"}`}>
        <section className="modal-header">
          <h3 className="modal-title">Share Via</h3>
          <button className="close-button" onClick={() => handleClose(false)}>
            &times;
          </button>
        </section>
        <section className="modal-body container-fluid">
          <div className="row">
            <div className="col-12 col-xl-6 p-1">
              <a href={`whatsapp://send?text=${shareData.url}`} data-action="share/whatsapp/share">
              <button className="px-1">
              <i className="fab fa-whatsapp" aria-hidden="true"></i>Whatsapp</button></a>
            </div>
            <div className="col-12 col-xl-6 p-1">
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareData.url}`} target="_blank" rel="noreferrer">
                <button className="px-1">
              <i className="fab fa-facebook" aria-hidden="true"></i> Facebook</button></a>
            </div>
            <div className="col-12 col-xl-6 p-1">
              <a href={`https://msng.link/o/?sdsd==${shareData.url}`} target="_blank" rel="noreferrer"><button className="px-1">
              <i className="fab fa-instagram" aria-hidden="true"></i> Instagram</button></a>
            </div>
            <div className="col-12 col-xl-6 p-1">
              <a href ={`mailto:?subject = Add my distributor link &body = ${shareData.url}`}><button className="px-1">
              <i className="fa fa-envelope" aria-hidden="true"></i> Mail</button></a>
            </div>
            <div className="col-12 col-xl-6 p-1">
              <a href={`https://twitter.com/intent/tweet?url=${shareData.url}&text=Click to add me as a distributor`} target="_blank" rel="noreferrer">
                <button className="px-1">
                  <i className="fab fa-twitter" aria-hidden="true"></i> Twitter</button></a>
            </div>
          </div>
        </section>
        <section className="modal-footer">
          <div className="modal-footer-link">{shareData.url}</div>
          <button className="modal-footer-button">Copy Link</button>
        </section>
      </div>
    </>
  );
}
export default ShareModal;

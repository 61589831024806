import React, { FC , useState } from 'react';
import './ImageCollage.scss';  
import ImageSrc from '../ImageSrc/ImageSrc';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

interface propsType {
    images: Array<{
        mimetype: string;
        url: string;
    }>
} 

const ImageCollage:FC<propsType> = ({ images }) => {

    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const imageFactory = images.map((res) => res.url) 
    
  // Set the size of each image based on the number of images
  const getImageSize = () => {
    switch (images.length) {
      case 1:
        return 'full-height';
      case 2:
        return 'half-height';
      case 3:
        return 'third-height';
      default:
        return 'fourth-height';
    }
  };
  

  return (
    <div className="ImageCollage">
        {/* single image */}
        {getImageSize() === 'full-height' && <div className={`${getImageSize()}`}> 
            {images.map((img: {url: string}, index: number) => (
                <div className="card" key={index} onClick={() => setIsOpen(true)}>
                    <div className="card-body p-0">
                    <img  src={img.url} alt={`Image ${index + 1}`} />
                    </div>
                </div>
            ))}
        </div>}

        {/* two image */}
        {getImageSize() === 'half-height' && <div className={`${getImageSize()}`}> 
            {images.map((img: {url: string}, index: number) => (
                <div className="card" key={index} onClick={() => setIsOpen(true)}>
                    <div className="card-body p-0">
                       <img  src={img.url} alt={`Image ${index + 1}`} />
                    </div>
                </div>
            ))}
        </div>}

         {/* 3 image */}
        {getImageSize() === 'third-height' && <div className={`${getImageSize()}`}> 
            <div className="row">
                <div className="col-md-8">  
                    <div className="main-image shadow-sm" onClick={() => setIsOpen(true)}>
                        <ImageSrc src={images[0]?.url} alt={""} title={""} width={'w-100 lg-image'}></ImageSrc>
                    </div> 
                </div>
                <div className="col-md-4">
                    <div className='row'>
                        <div className="col-6 col-md-12">
                            <div className="card small-image shadow-sm rounded border-0 overflow-hidden" onClick={() => setIsOpen(true)}>
                                <div className="card-body p-0">
                                    <img src={images[1]?.url} className='w-100'></img>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-12">
                            <div className="card small-image shadow-sm rounded mt-2 border-0 overflow-hidden" onClick={() => setIsOpen(true)}>
                                <div className="card-body p-0">
                                    <img src={images[2]?.url} className='w-100'></img>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>
                <div className="col-12 ">
                    <div className='created-time'>Created on: -</div>
                    {/* <div className='created-time'>Created on: { TimeConverter(project.createdAt)}</div> */}
                </div>
            </div>
        </div>}


          {/* 4 image */}
          {getImageSize() === 'fourth-height' && <div className={`${getImageSize()}`}> 
            <div className="row">
                <div className="col-md-8">  
                    <div className="main-image shadow-sm" onClick={() => setIsOpen(true)}>
                        <ImageSrc src={images[0]?.url} alt={""} title={""} width={'w-100 lg-image'}></ImageSrc>
                    </div> 
                </div>
                <div className="col-md-4">
                    <div className='row'>
                        <div className="col-6 col-md-12">
                            <div className="card small-image shadow-sm rounded border-0 overflow-hidden"  onClick={() => setIsOpen(true)}>
                                <div className="card-body p-0">
                                    <img src={images[1]?.url} className='w-100'></img>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-12">
                            <div className="card small-image shadow-sm rounded mt-2 border-0 overflow-hidden"  onClick={() => setIsOpen(true)}>
                                <div className="card-body p-0">
                                    <img src={images[2]?.url} className='w-100'></img>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>
                <div className="col-12 ">
                    <div className="row">
                    { images.map((image, index)=><div key={index} className="col-3">
                            <div className="card tiny-image shadow-sm rounded mt-2 border-0 overflow-hidden" onClick={() => setIsOpen(true)}>
                                <div className="card-body p-0">
                                    <img src={image.url} className='w-100'></img>
                                </div>
                            </div> 
                        </div> )}
                    </div>

                    <div className='created-time'>Created on: -</div>
                    {/* <div className='created-time'>Created on: { TimeConverter(project.createdAt)}</div> */}
                </div>
            </div>
        </div>}
        

        {isOpen && (
                    <Lightbox
                        mainSrc={imageFactory[photoIndex]}
                        nextSrc={imageFactory[(photoIndex + 1) % imageFactory.length]}
                        prevSrc={imageFactory[(photoIndex + imageFactory.length - 1) % imageFactory.length]}
                        onCloseRequest={() =>  setIsOpen(false)}
                        onMovePrevRequest={() =>setPhotoIndex((photoIndex + imageFactory.length - 1) % images.length)}
                        onMoveNextRequest={() =>setPhotoIndex((photoIndex + 1) % images.length)}
                    />
                    )}
    </div>
  );
};

export default ImageCollage;

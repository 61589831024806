/* eslint-disable no-unused-vars */
import { FC, Key, useEffect, useState } from "react"
import "./MultipleVariant.scss"
import TrashIcon from "../../../../../../../Assets/Images/trash.svg"
import ImgUpldIcon from "../../../../../../../Assets/Images/imgUpld.png"
import { DebounceInput } from 'react-debounce-input';


interface IMultipleVaruant {
    handleOnChecked: any
}

const MultipleVariant: FC<IMultipleVaruant> = ({ handleOnChecked }: IMultipleVaruant) => {

    const [addVariantRow, setAddVariantRow] = useState<any>([
        { attribute: "", variable: [] }
    ])
    const [showAddVariantRow,] = useState<boolean>(false)
    const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false)
    const [varantImages, setVariantImages] = useState<any>([])
    const [isOpenInputBox, setIsOpenInputBox] = useState(false)
 
    const [variables, setVariables] = useState<any>([])

  
    const handleAddFormRow = () => {
        const values = [...addVariantRow];
        values.push({
            attribute: "",
            variable: []
        });
        setAddVariantRow(values);
    };

    const handleRemoveFormRow = (index: number) => {

        const values = [...addVariantRow];
        values.splice(index, 1);
        setAddVariantRow(values);
        if (index === 0) {
            handleOnChecked()
        }
    };

    const handleFormRowInputChange = (index: number, event: { target: { name: string; value: string } }) => {
        const values: any[] = [...addVariantRow];
        values[index].attribute = event.target.value;
        setAddVariantRow(values);
    };



    const handleVariablesInputChange = (index: number, event: { target: { name: string; value: string } }) => {
        setVariables((prev: any) => [...prev, event.target.value])
        const values: any[] = [...addVariantRow];
        // const updatedValue: any = event.target.name;
        values[index].variable = [...variables, event.target.value];
        // setAddVariantRow(values);
        // console.log('variant', addVariantRow)
    };



    const removeImage = (i: number) => {
        const filteredItems = varantImages.slice(0, i).concat(varantImages.slice(i + 1, varantImages.length))
        setVariantImages(filteredItems)

    }

    const handleFileUpload = async (event: { target: any }) => {
        setLoadingPhoto(true)
        console.log(event.target.files[0]);

        setVariantImages([...varantImages, ...event.target.files])
        console.log(event.target.files[0].name);
        setLoadingPhoto(false)
    };
    const handleOpenInputBox = (index: number) => {
        if (addVariantRow[index]) {
            setIsOpenInputBox((prev) => !prev)
        }

    }


    const removeVariable = (i: any) => {
        setIsOpenInputBox((prev) => !prev)
        const filteredVariables = variables.filter((vari: any, idx: any) => variables[idx] !== variables[i])
        addVariantRow.variable = filteredVariables
    }


    console.log('variant', addVariantRow);


    return (
        <div className="container-variant">

            <form>

                {addVariantRow.length > 0 &&
                    (

                        addVariantRow.map((field: any, index: number, array: any) => (
                            <>
                                <div key={index} className={showAddVariantRow && Number(index) >= 1 ? "row w-100 sub-section d-none d-flex justify-content-around align-items-center " : "row sub-section   d-flex align-items-center"}>

                                    <div className='col-7 col-lg-6 form-group sec-b ms-5'>
                                        <label htmlFor="attribute"> Attribute</label>
                                        <input type="text" name="attribute" placeholder='' className='input' onChange={(event) => handleFormRowInputChange(index, event)} value={field.attribute} />

                                    </div>
                                    <div className='col-7 col-lg-6 form-group sec-b pt-3 '>
                                        <label htmlFor="variable"> Variable</label>
                                        <div className="w-100 position-relative">
                                            <ul
                                                className='input d-flex align-items-center'
                                                onClick={() => handleOpenInputBox(index)}
                                            >

                                                {addVariantRow[index].variable?.map((varA: any, idx: any) => {
                                                    console.log('id', idx);

                                                    return <li key={idx}
                                                        onClick={() => removeVariable(idx)}
                                                        className='bg-opacity-10 bg-info text-black text-opacity-50 me-3 btn rounded-4 px-2 pt-1'
                                                    >
                                                        {varA}
                                                        <i className=" fa fa-times-circle text-md deleteSpan ms-1" aria-hidden="true"></i>
                                                    </li>
                                                })}

                                            </ul>

                                            {isOpenInputBox && addVariantRow.length - 1 === index &&
                                                <DebounceInput
                                                    className="position-absolute top-50 mt-3 rounded-5 h-50 "
                                                    debounceTimeout={2000}
                                                    name='variables'
                                                    // onChange={e => setVariables((prev: any) => [...prev, e.target.value])}
                                                    onChange={(event:any) => handleVariablesInputChange(index, event)} value={variables}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="trash  pt-4 pe-3">
                                        <span className="btn btn-sm border-0  btn-remove" onClick={() => handleRemoveFormRow(index)}> <img src={TrashIcon} /> </span>
                                    </div>
                                </div>
                                {array.length - 1 === index &&
                                    <div className='col-12 mt-5'>
                                        <a href="" className=" add-new-price text-success" onClick={() => handleAddFormRow()}>
                                            <i className="fa fa-plus text-success me-2"></i> Add Attribute
                                        </a>
                                    </div>}
                            </>

                        ))

                    )}

                <div className="row sub-section  d-flex align-items-center w-100 mt-3 ">

                    <div className='col-9 col-lg-6 form-group sec-b ms-5'>
                        <label htmlFor="localGovt"> Local Govt Area</label>
                        <input type="text" name="localGovt" placeholder='' className='input'
                        // onChange={(event) => handleFormRowInputChange(event)} value={field.variable}
                        />

                    </div>
                    <div className='col-9 col-lg-6 form-group sec-b me-5'>
                        <label htmlFor="price"> Price</label>
                        <input type="number" name="price" placeholder='' className='input'
                        // onChange={(event) => handleFormRowInputChange(index, event)} value={field.variable} 
                        />

                    </div>
                </div>
                <div className="row sub-section ">
                    <div className='form-group variant col-7 col-12 mt-4 px-4'>
                        <label htmlFor="variantTitle"> Variant Title</label>
                        <input type="text" name="variantTitle" placeholder='' className='input '
                        // onChange={(event) => handleFormRowInputChange(index, event)} value={field.variable} 
                        />

                    </div>
                </div>
                <div className="container-fluid px-0 uploadImg my-4">
                    <h4 className='title'>Upload Image</h4>
                    <div className='desc my-2'>At least 1 image, must not exceed 5Mb each. </div>
                    <div className="row">
                        <div className="col-2">
                            <div className='add-img-btn'>
                                <label htmlFor="contained-button-file" ><img src={ImgUpldIcon} ></img></label>
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                multiple
                                id="contained-button-file"
                                className='d-none'
                                style={{ width: '235px', height: '175px' }}
                                onChange={handleFileUpload}
                            />
                            {loadingPhoto && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                        </div>
                        <div className="col-10">
                            <div className="container-fluid px-0">
                                <div className="row">

                                    {varantImages.map((res: any, index: Key) => {
                                        return <div className="col-3" key={index}>
                                            <div className='uploadedImgCont p-1 mb-1'>
                                                <span className='btn rounded-circle p-1'
                                                    onClick={() => removeImage(Number(index))}
                                                >
                                                    <i className='fa fa-times text-danger'></i>
                                                </span>
                                                <img src={URL.createObjectURL(res)} className="w-100"></img>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <button type="submit" className="submit-btn">Save Variant</button>
            </form>
        </div>
    )
}
export default MultipleVariant
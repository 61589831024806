import React, { memo } from 'react' 
import "./Statistics.scss"
 
const Statistics = () => {
     
    return (
        <div className='Statistics'> 
            <div className="row">
                <div className="col-6 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-1">
                                    <div className='icon-wraper'>
                                        <svg width="18" height="18" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="32" height="32" rx="8" fill="#E4E4E5"/>
                                            <path d="M16 16C18.7614 16 21 13.7614 21 11C21 8.23858 18.7614 6 16 6C13.2386 6 11 8.23858 11 11C11 13.7614 13.2386 16 16 16Z" stroke="#616263" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M24.5901 26C24.5901 22.13 20.7402 19 16.0002 19C11.2602 19 7.41016 22.13 7.41016 26" stroke="#616263" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> 
                                    </div>
                                </div>
                                <div className="col-12 col-sm-11">
                                    <div className="title">
                                       Active
                                    </div> 
                                    <div className="value">
                                    0
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-1">
                                    <div className='icon-wraper'>
                                        <svg width="18" height="18" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="32" height="32" rx="8" fill="#E4E4E5"/>
                                            <path d="M16 16C18.7614 16 21 13.7614 21 11C21 8.23858 18.7614 6 16 6C13.2386 6 11 8.23858 11 11C11 13.7614 13.2386 16 16 16Z" stroke="#616263" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M24.5901 26C24.5901 22.13 20.7402 19 16.0002 19C11.2602 19 7.41016 22.13 7.41016 26" stroke="#616263" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> 
                                    </div>
                                </div>
                                <div className="col-12 col-sm-11">
                                    <div className="title">
                                     Inactive Subscribers
                                    </div> 
                                    <div className="value">
                                    0
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 
            </div> 
        </div>
    )
}

export default memo(Statistics)

import React, { useEffect, useState , Key } from 'react'
import { EnterprisePageTitle, HorizontalLoader, ImageSrc } from '../../../../../../../Components'
import "./EnterpriseInvoiceDetails.scss"
import cinderBuildLogo from "../../../../../../../Assets/Images/enterprise/cinderbuild-logo-enterprise.png" 
import { Link, useNavigate, useParams } from 'react-router-dom'
import invoiceService from '../../../../../../../Services/invoiceService'
import { toast, Toaster } from 'react-hot-toast';
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter';
import { BasicRBAC, CurrencyFormatter } from '../../../../../../../Helpers'
import { invoiceType } from '../../../../../../../Models/invoiceType'
import { useGetWalletBalance } from '../../../../../../../Hooks/Queries/Enterprise/useGetWalletBalance'
import { wareHouse } from '../../../../../../../Models/enterprise.types'
import { useAllWarehouse, useSingleWarehouseManager } from '../../../../../../../Hooks/Queries/Enterprise/useAllWarehouse'


type dataType = [
    { productUuid: string; quantity: number }
]

const EnterpriseInvoiceDetails = () => {
   const { invoiceId } = useParams<any>() 
   const [declineRequest, setDeclineRequest] = useState(false)
   const [payRequest, setPayRequest] = useState(false)
  
   const [data, setData] = useState<invoiceType>()
   const [loading, setLoading] = useState(false)
   const [wareHouseUuid, setWarehouseUuid] = useState<string>()

   const [readyList, setReadyList] = useState<dataType | any[]>([])
   
   const navigate = useNavigate()
   
   const getInvoice = async () => {
        setLoading(true)
     await invoiceService.getSingle(invoiceId).then((res)=>{ 
         setData(res.data.data)
         setLoading(false)
     }, error=>{
        setLoading(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
   } 

    useEffect(() => {
        getInvoice()
    }, [invoiceId])
     


    const decline = async (uuid:any) => {
        setDeclineRequest(true) 
        await invoiceService.reject(uuid).then(()=>{
            setDeclineRequest(false)     
            toast.success("Invoice rejected succesfully", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }, error => { 
            setDeclineRequest(false)    
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
           
        })
      }

    const { data:balance } = useGetWalletBalance()

    const payNow = async () => {
        setPayRequest(true)
        if (data && balance?.amountMajor < data?.calculatedTotalCostMajor) { 
            setPayRequest(false)
            toast.error("Insurficient funds in your wallet. Fund your wallet to continue.", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
           return false
        }

        if (data) {

            // const items = data && data?.invoiceItem.map(({productUuid,quantity })=>({
            //     productUuid,
            //     quantity
            // })) 

            await invoiceService.pay(data.uuid, {
                invoiceItems: readyList,
                wareHouseUuid
            }).then((res)=>{   
                console.log("Payment res", res)
                toast.success("Payment successful", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            
                setPayRequest(false)  
                navigate(`/enterprise/payment-completed?action=/enterprise`, { replace: true }) 
                
                
            }, error => { 
                setPayRequest(false)    
                toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            
            })
        }      
    }      
    const access = BasicRBAC
      
    const {data: warehouseData,  isSuccess } = access("allWareHouse") === true? useAllWarehouse() : useSingleWarehouseManager()
    
    const handleWarehouse = (e: { target: { value: string }}) => {
        setWarehouseUuid(e.target.value)
    }
    

    const addToList = (data: { productUuid: string; quantity: number }) => {
        const dataHop:any[] = [...readyList, data]
        
        const checkList = readyList?.find((res: { productUuid: string })=>res.productUuid === data.productUuid)
        if (checkList) {
            const newList = readyList?.filter((res: { productUuid: string })=>res.productUuid !== data.productUuid)
            setReadyList(newList)
        } else { 
            setReadyList(dataHop)
        }
    }

    return (
        <div className='enterpriseInvoiceDetails'>
             <div className="container">
                <EnterprisePageTitle
                    title="#BCF-0395"
                    parent="Invoice"
                    parentUrl="invoice"
                    showTitle={false}
                />

                <div className="row">
                    <div className="col-md-6">
                        <div className="invoice-number">
                        Invoice #CDB-{data?.referenceNumber}
                        </div>
                    </div>
                    {data && data.status !== "ACCEPTED" &&  <div className="col-md-6 text-end"> 
                      {/* /enterprise/make-offer */}
                      <Link to="#"><button className="btn make-offer" onClick={() => alert("Functionality not enabled")}>Make an Offer</button></Link>
                    </div>}
                </div>

                <div className="invoice-tagline">
                  View all your procurement invoice and make payment using the Pay Now button
                </div>

                {loading === true && <HorizontalLoader></HorizontalLoader>}
               <div className="row justify-content-center mt-5 mb-5">
                  <div className="col-md-7">
                    <div className="card">
                        <div className="card-header border-0 bg-white pt-4 pb-4"> 
                            <ImageSrc src={cinderBuildLogo} alt={''} title={undefined} width={'w-25'}></ImageSrc>
                           
                           <div className="row mt-3">
                                <div className="col-md-4">
                                    <div className="label">
                                    Invoice no
                                    </div>
                                    <div className="value">
                                    #CDB-{data?.referenceNumber}    
                                    </div>
                                </div>
                                <div className="col-md-4"> 
                                    <div className="label">
                                    Invoice Date
                                    </div>
                                    <div className="value">
                                    {TimeConverter(data?.createdAt)}
                                    </div>
                                </div>
                                {data && data.status !== "ACCEPTED" && <div className="col-md-4 d-inline-flex">
                                    <button className="btn btn-pill approve d-flex align-items-center justify-content-center"  onClick={payNow} disabled={payRequest}>Approve</button>
                                  
                                    {declineRequest === true? <span className='spinner-border spinner-border-sm'></span>:
                                    <button className="btn btn-pill decline d-flex align-items-center justify-content-center" onClick={()=> decline(data?.uuid)}>Decline</button> }
                                </div>}
                           </div>
                        </div>
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope='col'></th>
                                            <th  className='ps-3' scope='col'>Invoice Items</th>
                                            <th scope='col'>Unit Price</th>
                                            <th scope='col'>Quantity</th>
                                            <th scope='col'>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.invoiceItem.map((res, index:Key)=><tr key={index}>
                                            <td className='ps-3'>
                                                <input type="checkbox" onChange={()=>addToList({
                                                        productUuid: res.productUuid,
                                                        quantity: res.quantity
                                                    })} className='selectCheck'></input>
                                            </td>
                                            <td className='ps-3'>{res.productName}</td>
                                            <td>₦{CurrencyFormatter(res.unitPriceForBuyer)}</td>
                                            <td>{res.quantity}</td>
                                            <td>₦{CurrencyFormatter(res.unitPriceForBuyer * res.quantity)}</td>
                                        </tr> )}
                                        <tr className='footer-data'> 
                                            <td colSpan={3}></td>
                                            <td className='fw-bolder'>Sub Total:</td>
                                            <td className='fw-bolder'>₦{data?.calculatedTotalCostMajor}</td>
                                        </tr>
                                        <tr className='footer-data text-dark d-none'>
                                            <td colSpan={3}></td>
                                            <td><div className='fw-bolder'>0.000% VAT:</div></td>
                                            <td className='fw-bolder'>₦0.00</td>
                                        </tr>
                                        <tr className='footer-data text-dark'>
                                            <td colSpan={3}></td>
                                            <td className='fw-bolder'>Credit:</td>
                                            <td className='fw-bolder'>₦00.00</td>
                                        </tr>
                                        <tr className='footer-data text-dark'>
                                            <td colSpan={3}></td>
                                            <td className='fw-bolder'>Total:</td>
                                            <td className='fw-bolder'>₦{data?.calculatedTotalCostMajor}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                       

                        <div className="card-footer border-0 pb-3">
                            <div className="row mb-2">
                                <div className="col-md-6"> 
                                </div>
                                <div className="col-md-6">
                                    <div className="form-input">
                                        <label htmlFor="formWareHouse">Select Warehouse </label>
                                       <select className='form-control' id="formWareHouse" onChange={handleWarehouse}>
                                        <option value={"undefined"}> -- select option -- </option>
                                        {isSuccess === true && warehouseData?.length >0 && warehouseData?.map((result:wareHouse, index:React.Key)=>{
                                           return <option value={result.uuid} key={index}>{result.name}</option>
                                        })}

                                       </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <button className="btn download">Download</button>
                                    <button className="btn share">Share</button>
                                </div>
                                <div className="col-md-8 text-end">
                                    
                                   {data && data.status !== "ACCEPTED"? 
                                   <button className="btn paynow" onClick={payNow} disabled={payRequest || !wareHouseUuid || wareHouseUuid==="undefined" || readyList.length === 0}>
                                        {payRequest && <span className='spinner-border spinner-border-sm me-2'></span>} 
                                        Pay Now
                                    </button>
                                    :<span className='text-success fw-bolder'>Paid</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <Toaster></Toaster>
        </div>
    )
}

export default EnterpriseInvoiceDetails

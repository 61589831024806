import React, { PropsWithChildren, useState } from 'react'
import "./ShopByBrand.scss"
import { ImageSrc } from '../../../../../Components'
import Skeleton from 'react-loading-skeleton'
import { useAllBrands } from '../../../../../Hooks/Queries/Brands/useAllBrands'
import { Link } from 'react-router-dom'

const ShopByBrand = () => {
  const [pageNumber,] = useState(1)
  const [sortOrder,] = useState("DESC")
  const [pageSize,] = useState(12)
  const { isSuccess, data, isLoading } = useAllBrands(pageNumber, sortOrder, pageSize);

  function Box ({ children }: PropsWithChildren<unknown>) {
    return (
        <div
            style={{
              border: 'none',
              display: 'block',
              lineHeight: 2,
              padding: '1rem',
              marginBottom: '0.5rem',
              width: "100%",
              height: 137,
            }}
        >
            {children}
        </div>
    )
  }

  return (
        <div className="shopByBrand pt-md-5 pb-md-5 d-none">
        <div className="container">
          <div className="row h-100 section-header">
            <div className="col-9 my-auto col-md-11 ps-3">
            <h2>Shop by brand</h2>
            </div>
            <div className="col-3 col-md-1 my-auto position-relative">
              <a href="" className="text-decoration-none">See all</a>
              <span className="d-icon d-arrowhead-right text-primary"></span>
            </div>
          </div>
        </div>

        <div className="container h-100 pt-4">
          <div className="row h-100">
            {isSuccess && data?.map((res: {uuid:string; imageUrl:string; name:string}, index: React.Key) => <div key={index} className="col-4 col-sm-4 col-md-4 col-lg-2">
              <Link to={`/products-by-brand/${res.uuid}`}>
                <div className="card border-0 cart-card bg-white my-2">
                  <div className="card-body sb-cb">
                      <ImageSrc src={res.imageUrl} alt={res.name} title={res.name} width={"w-100"}></ImageSrc>
                  </div>
                </div>
                <div className="card border-0 cart-card bg-white my-2">
                  <div className="card-body sb-cb">
                         <ImageSrc src={res.imageUrl} alt={res.name} title={res.name} width={'w-100'}></ImageSrc>
                        </div>
                        {/* <div className="card-footer text-center bg-white">
                          <Link to={`/products-by-brand/${res.uuid}`} className="text-decoration-none">{res.name}</Link>
                        </div> */}
                    </div>
              </Link>
            </div>)}

            {isLoading === true && [...Array(12)].map((res:any, index:any) => {
              return (<div key={index} className="col-4 col-sm-4 col-md-4 col-lg-2">
                        <div className="card cart-card bg-white my-2 border-0">
                              <div>
                                <Skeleton wrapper={Box} height="70px"/>
                              </div>
                          </div>
                  </div>)
            })}

          </div>
        </div>
      </div>
  )
}

export default ShopByBrand

 
import "./SellerQuoteRequest.scss";
import { Fragment, FunctionComponent, Key, useEffect, useState } from "react";
import toast from "react-hot-toast"; 
import quoteRequestService from "../../../../../../Services/quoteRequestService";
import { Logger } from "../../../../../../Helpers/Logger";
import { HorizontalLoader } from "../../../../../../Components";
import ImageSrc from '../../../../../../Components/ImageSrc/ImageSrc';
import { Link } from 'react-router-dom';
import { TimeConverter } from '../../../../../../Helpers/TimeConverter';
import { CurrencyFormatter } from '../../../../../../Helpers/CurrencyFormatter';

interface ISellerQuoteProps { }
 
const SellerQuoteRequest: FunctionComponent<ISellerQuoteProps> = () => {
    const [myQuoteRequest, setMyQuoteRequest] = useState([])

    const [sortOrder,] = useState("DESC")

    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = useState(1) 
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
 
    const persona = "SELLER"
    const [pendingResquest, setPendingResquest] = useState<boolean>(true)
    
    // eslint-disable-next-line no-unused-vars
    const [pagination, setPagination] = useState({
        pageNumber: "1",
        pageSize: 20,
        total: 1
    })

    const getMyQuoteRequest = async (pendingResquestVal: boolean) => {
        setLoadingRequest(true)
        setMyQuoteRequest([])
        await quoteRequestService.getAllMyRequest(page, sortOrder, persona, pendingResquestVal)
            .then((res: any) => {
                setLoadingRequest(false)
                setMyQuoteRequest(res.data.data.dataset)
                Logger("getMyRequest", res.data.data)
                setPagination({
                    pageNumber: res.data.data.pageNumber,
                    pageSize: res.data.data.pageSize,
                    total: res.data.data.total
                })
                // //Logger("My orders", res)
            }, (error: any) => {
                setLoadingRequest(false)
                toast.error(error.message, { duration: 9000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
    }

    useEffect(() => { 
        const abortController = new AbortController()
        getMyQuoteRequest(pendingResquest) 
        return () => { abortController.abort() }
    }, [page])
 
 
    const filterActive = (status:boolean) =>  {
        setPendingResquest(status) 
        getMyQuoteRequest(status)  
    }
    return (
        <div className="seller-quotes-container">  
            <div className="container top-filter mt-5">
                 <div className="row mt-4 h-100"> 
                    <div className="col-12 col-sm-1  col-xl-4 my-auto">Quote request</div> 
                    <div className="col-4 col-sm-1 col-xl-2"> 
                    </div>
                    <div className="col-12 col-sm-5 col-xl-4">
                      <div className="seach-field">
                        <input type="text" className='search-input form-control' placeholder='Search product...'/>
                        <svg className="search-icon" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6752 11.5657C9.3418 13.8991 5.55859 13.8991 3.22518 11.5657C0.891767 9.23226 0.891767 5.44905 3.22518 3.11564C5.55859 0.782229 9.3418 0.782229 11.6752 3.11564C14.0086 5.44905 14.0086 9.23226 11.6752 11.5657ZM11.6752 11.5657L16.4128 16.3033" stroke="#868484" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>  
                      </div>
                    </div>
                    <div className="col-12 col-sm-5 col-xl-2">
                        <div className="">
                            <select className="form-control btn-filter" onChange={(e: any)=> filterActive(e.target.value)}>
                                <option value={"false"}>Filter Quote</option>
                                <option value={"true"}>Pending Quote</option>
                                <option value={"false"}> Active Quote</option>
                            </select>
                        </div>  
                    </div> 
                </div>
            </div>

          <div className='container p-sm-3 p-md-3 seller-table'>
            <table className="table rounded border-0"> 
                <thead className='rounded border-0'>
                    <tr className='d-none d-md-table-row border-0'>
                        <th scope="col"></th>
                        <th scope="col">Order ID</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Product</th>
                        {!pendingResquest  && <th scope="col">Price</th>}
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>  
                        <th scope="col"></th>  
                    </tr>
                </thead>
                <tbody>
                {myQuoteRequest.length > 0 && myQuoteRequest.map((res:{
                    referenceNumber:number,
                    calculatedTotalCostMajor:number,
                    currencySymbol:string,
                    orderItems:any,
                    status:any,
                    uuid:string,
                    dateCreatedIso8601:any,
                    buyerUserPublicProfile: any,
                    procurementInvoiceUuid:string,
                    product:any
                    }, index: Key | null | undefined) => {
                    return (
                         <Fragment key={index}>
                            <tr className='d-none d-md-table-row'>
                                <td>  
                                </td>  
                                <td> #{Number(index) + 1}</td>
                                <td> {res.buyerUserPublicProfile.firstName}{res.buyerUserPublicProfile.lastName}</td>
                                <td> {res.product && res.product.name}</td> 
                                {!pendingResquest && 
                                <td> {res.currencySymbol} {CurrencyFormatter(res.calculatedTotalCostMajor)}</td>}
                                <td>
                                    {/* <span className='created-status'> {res.status}</span> */}
                                    {(res.status === "CONFIRMED" || res.status === "COMPLETED") && <span className='created-status'> {res.status}</span>} 
                                    {(res.status === "CANCELLED_BY_BUYER" || res.status === "CANCELLED_BY_SELLER" || res.status === "ENDED_WITH_DISPUTES") && <span className='status-pending'> {res.status}</span>}
                                    {(res.status === "CREATED" || res.status === "PENDING"|| res.status === "IN_PROGRESS" || res.status === "AVAILABLE_FOR_PICKUP") &&  <span className='created-status'>{res.status}</span>}
                                </td>
                                <td> { TimeConverter(res.dateCreatedIso8601)}</td> 
                                <td>
                                <div className="btn-group">
                                    <button type="button" className="btn border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className='d-icon d-more-vertical'></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm"> 
                                        <li>
                                            <Link className="dropdown-item" to={`/seller/my-quotes/details/${res.uuid}`}>
                                            <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.16992 7.4375L11.9999 12.5475L20.7699 7.46747" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 21.6091V12.5391" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M9.92963 2.48L4.58963 5.45003C3.37963 6.12003 2.38965 7.80001 2.38965 9.18001V14.83C2.38965 16.21 3.37963 17.89 4.58963 18.56L9.92963 21.53C11.0696 22.16 12.9396 22.16 14.0796 21.53L19.4196 18.56C20.6296 17.89 21.6196 16.21 21.6196 14.83V9.18001C21.6196 7.80001 20.6296 6.12003 19.4196 5.45003L14.0796 2.48C12.9296 1.84 11.0696 1.84 9.92963 2.48Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M16.9998 13.2416V9.5816L7.50977 4.10156" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                            Order Details
                                            </Link>
                                        </li> 
                                    </ul>
                                </div>
                                </td>
                            </tr>  
                            <tr className='d-md-none'>
                                <td>
                                    <ImageSrc src={''} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                                </td>
                                <td colSpan={4}>
                                    <div className="row"> 
                                        <div className='col-12 profile-title'>
                                         {res.product && res.product.name}
                                        </div>
                                        {!pendingResquest && <div className='col-12 profile-detail'>{res.currencySymbol} {CurrencyFormatter(res.calculatedTotalCostMajor)}</div> }
                                        <div className='col-12 profile-detail'>{ TimeConverter(res.dateCreatedIso8601)}</div>
                                    </div>
                                </td>
                                <td className='profile-btn'>
                                <Link className="dropdown-item" to={`/seller/my-quotes/details/${res.uuid}`}>
                                    <button type="button" className="btn btn-sm p-1 border d-md-none">
                                        View
                                    </button> 
                                </Link>
                                </td>   
                            </tr>  
                            </Fragment>) 
                        })}
                        {loadingRequest === false && myQuoteRequest?.length === 0 &&  <tr>
                        <td colSpan={9}>
                            <div className='text-secondary text-center fw-bold'>No item found</div>
                        </td>
                    </tr>}
                {loadingRequest === true && <tr>
                        <td colSpan={9}>
                            <div className='text-secondary text-center fw-bold'><HorizontalLoader></HorizontalLoader></div>
                        </td>
                    </tr>}
                </tbody> 
            </table> 
        </div>
    </div>
    );
};

export default SellerQuoteRequest;

import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { HorizontalLoader } from '../../../../../../../Components'
import { CurrencyFormatter } from '../../../../../../../Helpers'
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter'
import { useAllOrder } from '../../../../../../../Hooks/Queries/Enterprise/useAllOrder'
import "./Orders.scss"

interface propsType {
    setCount: (value:number) => void
}

const Orders:FC<propsType> = ({setCount}) => {
    
    const [sortOrder,] = useState("DESC")
    const [status,] = useState("ALL") 
    const [page,] = useState(1)
    const [persona,] = useState("BUYER") 
    const [paymentVariantActive,] = useState<string|any>(null)
  

    const {data, isLoading, isSuccess, refetch: getOrder } = useAllOrder(page, sortOrder, persona, status, paymentVariantActive)
    
    useEffect(() => {
        getOrder()
      }, [])

      const filteredData = data?.filter((res: { procurementInvoiceUuid: any })=>res.procurementInvoiceUuid !== null)
        
    useEffect(() => {
        data && setCount(filteredData.length)
      }, [data])
      
      

    return (
        <div className='orders'>
             <div className="table-responsive">
            <table className="table table-strip">
                {filteredData?.length>0 && filteredData?.slice(0, 5).map((res: any, index:number) => {
                return res.procurementInvoiceUuid !== null && <tr key={index}>
                    <td>
                        <div className='id'>#{res.referenceNumber}</div>
                        <div className='date'>{ TimeConverter(res.createdAt)}</div>
                    </td>
                    <td className='amount'>{res.currencySymbol} {CurrencyFormatter(res.calculatedTotalCostMajor)}</td> 
                    <td>
                        <Link to={`/enterprise/order-details/${res.orderUuid}`}>
                            <button className='btn btn-pay'>View</button>
                        </Link>
                    </td>
                </tr> 
                })} 
                <tr>
                    <td colSpan={3}>
                        { isLoading && <HorizontalLoader></HorizontalLoader> }
                        { isSuccess && data?.length===0 && <div className='text-center text-danger notfound'>No order found</div> }
                    </td>
                </tr>

            </table>
             <div className="text-center"> 
                 <Link className='see-all w-100' to="/enterprise/order-management"> See all orders</Link> 
             </div>
           </div>
        </div>
    )
}

export default Orders

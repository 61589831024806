import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./CategoryMegaMenu.scss"
import rightarrow from "../../../../Assets/Images/icon/right-arrow.png"
import { useCategory, useFetchBrandsProducts } from '../../../../Hooks'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css' 

const CategoryMegaMenu = () => {
  const [pageNumber,] = useState(1)
  const [sortOrder,] = useState("DESC")
  const [pageSize,] = useState(20)

  const [brands, setBrands] = useState<any[]>([])
  const [catUuid, setCatUuid] = useState<string|undefined>()
  const [brandUuid, setBrandUuid] = useState<string|undefined>()

  const { isSuccess, isLoading, data: categoryList } = useCategory(pageNumber, sortOrder, pageSize);
  
  useEffect(() => {
    if (isSuccess === true) {
      setBrands(categoryList[0]?.brands)
      setBrandUuid(categoryList[0]?.brands[0]?.uuid)
      setCatUuid(categoryList[0]?.uuid)
    }
  }, [categoryList])

  const { isSuccess: isSuccessProduct, isRefetching, isLoading: isLoadingProduct, data: product, refetch: getProduct } = useFetchBrandsProducts(brandUuid, 1, "DESC")

  useEffect(() => {
    brandUuid && getProduct()
  }, [brandUuid, brands])

  const modified_product:any[] = [];

  if (isSuccessProduct && product?.dataset.length > 0) {
    let numberOfColumns = product?.dataset.length / 12;

    if (isSuccessProduct && product?.dataset.length % 12 > 0) {
      numberOfColumns++;
    }

    let index = 0;
    for (let i = 0; i < product?.dataset?.length; i++) {
      if (index % numberOfColumns === 0) {
        index = 0;
      }

      if (!modified_product[index]) {
        modified_product[index] = [];
      }

      modified_product[index].push(product?.dataset[i]);
      index++;
    }
  } 
  function Box ({ children }: PropsWithChildren<unknown>) {
    return (
        <div
            style={{
              border: '1px solid #fff',
              display: 'block',
              lineHeight: 2,
              padding: '0rem',
              marginBottom: '0.1rem',
              width: "100%",
            //   height: 137,
            }}
        >
            {children}
        </div>
    )
  }

  return (
        <li className="nav-item dropdown position-relative category-mega-menu">
          <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button"
            data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
           Categories
          </Link>
          {/* <!-- Dropdown menu --> */}
          <div className="dropdown-menu border-0 shadow mt-0 ms-3 me-5 py-0" aria-labelledby="navbarDropdown">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 col-xl-3 mb-3 mb-xl-0 p-0">
                    <div className="mega-menu-tab">
                        <ul className="list-group">
                            {isSuccess && categoryList?.length > 0 && categoryList?.map((res: {name:string, brands:any[], uuid:string}, index: React.Key) =>
                            <li className={catUuid === res.uuid ? 'list-group-item active border-0 d-flex align-items-center justify-content-between' : 'list-group-item border-0 d-flex align-items-center justify-content-between'} key={index} onClick={() => { setBrands(res.brands); setCatUuid(res.uuid) }}>
                                <div className="d-flex column-gap-2  h-100">
                                    <div className=" my-auto">
                                        <div className="img"></div>
                                    </div>
                                    <div className=" ms-2 my-auto p-0">{res.name}</div>
                                </div>
                                <div className="my-auto text-end">
                                        <img src={rightarrow} alt="" className='nav-icon'/>
                                </div>
                            </li>)}
                        </ul>
                    </div>
                </div>
                <div className="col-md-4 col-xl-3 mb-3 mb-xl-0">
                    <div className="mega-menu-tab-item">
                        <ul className="list-group">
                            <li className="list-group-item border-0 mega-menu-head-title">Brands</li>
                            {brands.length > 0 && brands.map((res:{name:string, uuid:string}, index:React.Key) => <li className="list-group-item border-0" key={index} onClick={() => setBrandUuid(res.uuid)}><Link to="#" className="text-dark text-capitalize">{res.name}</Link></li>)}
                           {isLoading && <><li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            </>}
                            {!isLoading && brands.length === 0 &&
                            <li className="list-group-item border-0"><small className='text-secondary'>No brand found</small> </li>}
                       </ul>
                    </div>
                </div>
                <div className="col-md-4 col-xl-6 mb-3 mb-xl-0">
                    <div className="mega-menu-tab-item">
                      <ul className="list-group">
                         <li className="list-group-item border-0 mega-menu-head-title">Products</li>
                      </ul>
                      <div className="row">
                        { !isLoadingProduct && !isRefetching && isSuccessProduct && product.dataset.length > 0 && modified_product.map((row, index) => (
                          <div className="col-md-6 col-xl-6 mb-3 mb-xl-0" key={index}>
                              <ul className="list-group">
                                 {row.map((res: { productName: string, productUuid:string }, index: React.Key) => (<li className="list-group-item border-0" key={index}><a href={`/product/${res.productUuid}`} className="text-dark text-capitalize">{res.productName}</a> </li>))}
                              </ul>
                          </div>))}

                          {(isLoadingProduct || isRefetching) && <>
                            <div className="col-md-6 mb-3">
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            </div>
                            <div className="col-md-6 mb-3">
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                              <li className="list-group-item border-0"> <Skeleton wrapper={Box} count={1} height={10} width={200}/></li>
                            </div>

                            </>}
                      </div>

                            {!isLoadingProduct && !isRefetching && isSuccessProduct && product.dataset.length === 0 &&
                            <li className="list-group-item border-0"><small className='text-secondary'>No product found</small> </li>}
                    </div>
                </div>

              </div>
            </div>
          </div>
        </li>
  )
}

export default CategoryMegaMenu

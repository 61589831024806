
import React, { useEffect, useState } from 'react'
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../../../Config/DBConfig';
import { ImageSrc } from '../../../../../Components';
import { Logger } from '../../../../../Helpers/Logger';
import PickupLocations from '../PickupLocations/PickupLocations';
import { productType } from '../../../../../Models';
import toast, { Toaster } from 'react-hot-toast';

const CheckoutCartItem = () => {
  const cartData = useLiveQuery(
    async () => await db.cart.toArray()
  );
  const orderDetails = useLiveQuery<any>(async () => await db.orderDetails?.toArray());

  const [queueState, setQueueState] = useState<{sellers:any}>({ sellers: [] })
  const [queueStateTriger, setQueueStateTriger] = useState<boolean>(false)

  const [pickupLocationData, setPickupLocationData] = useState<{sellers:any}>({ sellers: [] })

  const pickupLocations = (e:any, productDetails: { productDetails: productType; productUuid: string; quantity: string;}, address: string) => {
    const arrayItem = pickupLocationData.sellers

    const index = arrayItem.findIndex((obj: { cartItems: { productUuid: string; }[]; }) => {
      return obj.cartItems[0].productUuid === productDetails.productUuid
    });

    if (index >= 0) {
      Logger("pickupFind index", index)
      arrayItem.splice(index, 1)
      Logger("pickupFind new array 0", arrayItem)

      setQueueState({
        sellers: [
          ...arrayItem,
          {
            userUuid: productDetails.productDetails.sellerPublicProfile.userUuid,
            orderReceiveType: "DELIVERY",
            pickupLocationUuid: e.target.value,
            address,
            cartItems: [
              {
                productUuid: productDetails.productUuid,
                quantity: productDetails.quantity,
              }
            ]
          }
        ]
      })

      setQueueStateTriger(!queueStateTriger)
    } else {
      setPickupLocationData({
        sellers: [
          ...pickupLocationData.sellers,
          {
            userUuid: productDetails.productDetails.sellerPublicProfile.userUuid,
            orderReceiveType: "DELIVERY",
            pickupLocationUuid: e.target.value,
            address,
            cartItems: [
              {
                productUuid: productDetails.productUuid,
                quantity: productDetails.quantity,
              }
            ]
          }
        ]
      })
    }
  }

  useEffect(() => {
    setPickupLocationData(queueState)
  }, [queueStateTriger])

  const saveAddress = async () => {
    await db.orderDetails.update(orderDetails[0]?.id, { pickupLocations: pickupLocationData }).then(function (updated) {
      if (updated) {
        toast.success(`pickupLocations address Updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      } else {
        toast.error(`pickupLocations was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      }
    })
  }

  useEffect(() => {
    saveAddress()
  }, [pickupLocationData])

  Logger("epickupLocationData", pickupLocationData)

  return (
    <div className="accordion pt-3" id="accordionExample">
       {cartData && cartData.map((res:any, index:React.Key) => <div className="accordion-item" key={index}>
            <div className="accordion-header border-0 shadow-0" id={`headingOne${Number(index)}`}>
                <div className="cart-item accordion-button border-0" data-bs-toggle="collapse" data-bs-target={`#collapseOne${Number(index)}`} aria-expanded="true" aria-controls={`collapseOne${Number(index)}`}>
                    <div className="row">
                        <div className="col-4 col-md-3 p-0 p-1">
                            <div className="cart-item-list-image">
                                <ImageSrc src={res?.productDetails?.images[0]?.url} alt={res?.productDetails?.productName} title={res?.productDetails?.productName} width=""></ImageSrc>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="cart-item-name pt-2">
                            {res.productDetails.productName}
                            </div>
                            <div className="cart-item-variation">
                            Color: Red
                            </div>

                            <div className="product-attibutes pb-2">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-5 col-md-3 remove-product text-center">Quantity: {res.quantity}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-2  text-end">
                            <div className="cart-item-price text-end">
                            ₦{res.productDetails.unitPriceForBuyer}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id={`collapseOne${Number(index)}`} className={Number(index) === 0 ? "accordion-collapse collapse show border-0 shadow-0" : "accordion-collapse collapse border-0 shadow-0"} aria-labelledby={`headingOne${Number(index)}`} data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    <b>Select a pickup location</b>

                    <PickupLocations productDetails={res} pickupLocations={pickupLocations}></PickupLocations>
                </div>
            </div>
        </div>)}
<Toaster></Toaster>
</div>
  )
}

export default CheckoutCartItem

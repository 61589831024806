import React, { useEffect, useState, FC } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import quoteServices from '../../../../../../../../Services/quoteRequestService'
import "./EnterprisePendingResponse.scss"
import { Link } from 'react-router-dom'
import { TimeConverter } from '../../../../../../../../Helpers/TimeConverter'
import { Pagination } from '../../../../../../../../Components'
import { Logger } from '../../../../../../../../Helpers/Logger'
import QuoteItemImage from "../../../../../../../../Assets/Images/quote_item.png";
import HorizontalLoader from '../../../../../../../../Components/Loader/HorizontalLoader/HorizontalLoader';

interface propsType {
  module?: string;
}
const EnterprisePendingResponse:FC<propsType> = ({module}) => { 
  const [myQuoteRequest, setMyQuoteRequest] = useState([])

  const [sortOrder,] = useState("DESC")

  const [page, setPage] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)


  // eslint-disable-next-line no-unused-vars
  const [persona, setPersona] = useState("BUYER")
  const [pagination, setPagination] = useState({
    pageNumber: "1",
    pageSize: 20,
    total: 1
  })

  const getMyQuoteRequest = async () => {
    setLoadingRequest(true)
    await quoteServices.getAllMyRequest(page, sortOrder, persona, true)
      .then((res:any) => {
        setLoadingRequest(false)
        setMyQuoteRequest(res.data.data.dataset)
        Logger("getMyRequest", res.data.data)
        setPagination({
          pageNumber: res.data.data.pageNumber,
          pageSize: res.data.data.pageSize,
          total: res.data.data.total
        })
        // //Logger("My orders", res)
      }, (error:any) => {
        setLoadingRequest(false)
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    getMyQuoteRequest()
    return () => { abortController.abort() }
  }, [page])

  return (
        <div className='epending-response my-4'>
           <div className='pending-response-inner'>
           <div className='table-responsive'>
             <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Item name</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                {myQuoteRequest.length > 0 && myQuoteRequest.map((res:any, index) => {
                  return (<tr key={index}>
                    <td>
                        <div className='product-image-container'>
                        <img src={ QuoteItemImage} alt='' className='product-image'/>
                        </div>
                    </td>
                    <td>
                        {
                            res.product.name
                        }
                    </td>
                    <td>
                      {res.quantity}
                    </td>
                    <td>
                      { TimeConverter(res.dateCreatedIso8601).split(',')[0]}
                    </td>
                    <td>
                      { TimeConverter(res.dateCreatedIso8601).split(',')[1]}
                    </td>
                    <td><Link to={(module && module ==="estate")? `/estate-managers/quote-request/${res.uuid}`:`/enterprise/quote-request/${res.uuid}`}><button className='btn'>Quote details</button></Link></td>
                  </tr>)
                })}
                {myQuoteRequest.length === 0 && loadingRequest === false && <tr>
                  <td colSpan={6}>
                    <div className='text-secondary text-center fw-bold'>No item found</div>
                  </td>
                </tr>}

                {loadingRequest && <tr>
                  <td colSpan={6}>
                    <div className='text-secondary text-center fw-bold'>
                       <HorizontalLoader></HorizontalLoader>
                    </div>
                  </td>
                </tr>}

                </tbody>
              </table>
              </div> 
          </div>

          {pagination.total >= pagination.pageSize && <div className="pagination w-100 d-flex justify-content-center mb-5">
              {pagination.total && <Pagination
              className="pagination-bar ps-0 pt-0 mt-5 mb-5"
              currentPage={Number(pagination.pageNumber)}
              totalCount={pagination.total}
              pageSize={pagination.pageSize}
              onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
              }
          </div>}
        <Toaster></Toaster>
      </div>
  )
}

export default EnterprisePendingResponse;
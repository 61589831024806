import axios from 'axios'
import { corperateUser } from '../Models/enterprise.types';

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()
 
const corperateUserService = {
  async index (page: number, sortOrder: string) {
    return axios.get(`/cooperate/user?pageNumber=${page}&sortOrder=${sortOrder}`);
  },
  async create (data: corperateUser) { 
    return axios.post("/cooperate/user", data);
  }, 
  async deactivate (userUuid?: string) {
    return axios.patch(`/cooperate/user/${userUuid}`);
  },
  async profile (userUuid?: string) {
    return axios.get(`/cooperate/user/${userUuid}`);
  },
  
  
}
export default corperateUserService

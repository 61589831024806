/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Logger } from "../../../../../../../Helpers/Logger";
import { userProfile } from "../../../../../../../Models";
import userService from "../../../../../../../Services/userService";
import SubPageTitle from "../../../Component/Settings/SubPageTitle/SubPageTitle";

import BusinessInformation from "../../../Component/Settings/BusinessInformation/BusinessInformation";
import BusinessProfileHeader from "../../../Component/Settings/BusinessProfileHeader/BusinessProfileHeader";

const BusinessProfile = () => {

    const initialState: userProfile = {
        userUuid: "",
        isOnProductLease: false,
        firstName: "",
        lastName: "",
        emailAddress: "",
        msisdn: "",
        sellerUniqueCode: "",
        photoUrl: "",
        role: "",
        accountRating: {
            totalRatingsValue: 0,
            totalNumberOfRatings: 0
        },

        businessProfile: {
            businessName: "",
            businessAddress: "",
            businessCACNumber: ""
        }
    }
    const [userProfileInfo, setUserProfile] = useState<userProfile>(initialState)
    // eslint-disable-next-line no-unused-vars
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
    // eslint-disable-next-line no-unused-vars
    const [photo, setPhoto] = useState<any>(null)

    const getProfile = async () => {
        setLoadingRequest(true)
        await userService.getProfile().then((res): void => {
            setLoadingRequest(false)
            setUserProfile(res.data.data)
            Logger(res.data.data)
            setPhoto(res.data.data.photoUrl)
        }, (error: any) => {
            setLoadingRequest(false)
            Logger(error.response)
        })
    }

    useEffect(() => {
        const abortController = new AbortController();
        getProfile()
        return () => abortController.abort();
    }, [])


    return (
        <div className="biz-profile-page">
            <div className="container">
                <SubPageTitle
                    title="Business Profile"
                    parents={[
                        {
                            title: "Settings",
                            url: "settings"
                        }
                    ]}
                />
                <div className="row justify-content-center">
                    <div className="col-12 col-md-9 col-lg-6 profile-item-container mb-4">
                        <div className="mb-4">
                            <BusinessProfileHeader businessName={userProfileInfo.firstName} bizLogo={userProfileInfo.photoUrl} />
                        </div>
                        <div className="mb-2">
                            <BusinessInformation />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default BusinessProfile;
/* eslint-disable space-before-function-paren */
import "./CACDocuments.scss"
import { useCallback, SetStateAction, useState } from "react";
import { useDropzone, Accept } from "react-dropzone";
import SubPageTitle from "../../../../../Component/Settings/SubPageTitle/SubPageTitle"
import addFolderIcon from "../../../../../../../../../Assets/Images/icon/folder-add.svg";

type Props = {}
const CACDocuments = (props: Props) => {
    const [files, setFiles] = useState([])

    const { getRootProps: getRootFilesProps, getInputProps: getInputFilesProps } = useDropzone({
        onDrop: useCallback<any>(
            (acceptedFiles: {
                map: (arg0: (file: any) => any) => SetStateAction<never[]>;
            }) => {
                console.log("ac", acceptedFiles);
                setFiles(
                    acceptedFiles.map((file: Blob | MediaSource) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                );
            },
            []
        ),
        accept: "image/jpeg, image/png" as unknown as Accept,
        maxFiles: 1,
        onDropAccepted(files, event) {
            // handleFileUpload(event, files)
        },
    });
    const thumbs = files.map((file: any) => (
        <div key={file.name} className="input-div">
            <img
                src={file.preview}
                alt={file.name}
                className="input-icon"
            />
            <aside>{file.name}</aside>
            <span style={{ fontSize: "8px" }}>x</span>
        </div>
    ));

    return (
        <div className="cac-document">
            <SubPageTitle
                title="CAC Document"
                parents={[
                    {
                        title: "Business Profile",
                        url: "business-profile"
                    }
                ]}
            />
            <div className="container mt-5">
            <div className="row justify-content-center w-100">
                <div className="col-12 col-md-7">
                    <p className="text text-lg-center">
                        Upload PDF, JPG of your company registration certificate (CAC)
                    </p>
                    <div {...getRootFilesProps()} className="form-group mt-5 ">

                        <label htmlFor="input">
                            {files.length === 0 ?
                                <div className="input-div">
                                    <img
                                        className="input-icon"
                                        src={addFolderIcon}
                                        alt="folder icon"
                                    />{" "}
                                    <p> Drag and drop or browse your file</p>
                                </div>
                                : (
                                    <aside>{thumbs}</aside>
                                )}

                            <input {...getInputFilesProps()} />
                        </label>
                    </div>
                        <button className="btn save mt-5  col-12">Save</button>
                </div>
                </div>
            </div>
        </div>
    )
}
export default CACDocuments
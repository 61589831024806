import React, { useEffect, useMemo, useState } from 'react'
import "./SellerQuoteDetails.scss"
import { Logger } from '../../../../../../Helpers/Logger';
import { toast, Toaster } from 'react-hot-toast';
import { NavLink, useParams } from 'react-router-dom';
import { TimeConverter } from '../../../../../../Helpers/TimeConverter';
import { NumberWithComma } from '../../../../../../Helpers/NumberWithCommer';
import SellerPageOrderSummaryItem from '../../../../../../Components/Seller/SellerPageOrderSummaryItem/SellerPageOrderSummaryItem';
import SellerPageOrderPaymentMethod from '../../../../../../Components/Seller/SellerPageOrderPaymentMethod/SellerPageOrderPaymentMethod';
import SellerPageOrderDeliveryAddress from '../../../../../../Components/Seller/SellerPageOrderDeliveryAddress/SellerPageOrderDeliveryAddress';
import SellerPageTitle from '../../../../../../Components/Seller/SellerPageTitle/SellerPageTitle';
import quoteRequestService from '../../../../../../Services/quoteRequestService';
import SellerQuoteResponseModal from '../../../../Components/Seller/SellerQuoteResponseModal/SellerQuoteResponseModal';
import SellerPageOrderAddInfo from '../../../../Components/Seller/SellerPageOrderAddInfo/SellerPageOrderAddInfo';
 
const SellerQuoteDetails = () => {
  const { quoteId } = useParams<{ quoteId: string }>()
  // eslint-disable-next-line no-unused-vars
  const [quoteDetails, setQuotedetails] = useState<any>(null)
  const [show, setShow] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)

  const getQuote = async (quoteId: string | undefined) => {
    setLoadingRequest(true)
    await quoteRequestService.getSingleRequest(quoteId).then((res) => {
      setQuotedetails(res.data.data)
      Logger("setQuotedetails", res.data)
      setLoadingRequest(false)
    }, (error: any) => {
      setLoadingRequest(false)
      Logger("quotedetails error", error.response)
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }

  useEffect(() => {
    getQuote(quoteId)
  }, [])

  const totalCost = useMemo(() => {
    // eslint-disable-next-line
    return quoteDetails?.deliveryCostMajor || 0 + quoteDetails?.calculatedTotalCostMajor || 0
  }, [quoteDetails])

  console.log("quoteId", quoteId)

  const openResponseModal = () => {
    setShow(true)
  }

  return (
    <div className='SellerQuoteDetails'>
      <div className="container mt-4">
        <div className='d-flex mb-5 justify-content-between align-items-center'>
          <SellerPageTitle title={'Quote details'} parent={'My quotes'} parentUrl={'my-quotes'} variant='true'></SellerPageTitle> {/* eslint-disable-next-line */}
          <div className="d-flex flex-column flex-md-row mt-5 align-items-center justify-content-between">
            <div className="d-flex flex-column flex-md-row">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 4.98307C14.725 4.70807 11.9333 4.56641 9.15 4.56641C7.5 4.56641 5.85 4.64974 4.2 4.81641L2.5 4.98307" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.08301 4.14297L7.26634 3.0513C7.39967 2.25964 7.49967 1.66797 8.90801 1.66797H11.0913C12.4997 1.66797 12.608 2.29297 12.733 3.05964L12.9163 4.14297" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.7087 7.61719L15.167 16.0089C15.0753 17.3172 15.0003 18.3339 12.6753 18.3339H7.32533C5.00033 18.3339 4.92533 17.3172 4.83366 16.0089L4.29199 7.61719" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.6084 13.75H11.3834" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.91699 10.418H12.0837" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <NavLink className="quote-decline ms-2" to={''}>Decline quote</NavLink>
            </div>
            <div className="order-1 order-md-2 seller-order-details-navlink ms-4">
              <button className='btn-track' onClick={openResponseModal}>Respond to request</button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="card order-summary">
              <div className="card-header card-custom-header bg-white">
                <div className="row">
                  <div className="col-sm-6">
                    <p>Order summary</p>
                    <p className='fw-normal'>{quoteDetails?.orderUuid || ""}</p>
                  </div>
                  <div className="col-sm-6 text-end order-time text-end">{TimeConverter(quoteDetails?.dateCreatedIso8601)}</div>
                </div>
              </div>
              <div className="card-body">
                {/* eslint-disable-next-line */}
                <ul className="list-group list-group-flush">
                  {/* eslint-disable-next-line */}
                  {loadingRequest &&
                    <div className='text-secondary text-center fw-bold'>
                      <span className='spinner-border spinner-border-lg'></span>
                    </div>
                  }
                  { quoteDetails &&
                        <SellerPageOrderSummaryItem
                          imageUrl={quoteDetails.product?.images? quoteDetails.product?.images[0].url : ""}
                          name={quoteDetails.product.name}
                          quantity={quoteDetails.quantity}
                          amount={quoteDetails?.unitPriceForBuyer || ""}
                          key={quoteDetails?.orderUuid || ""}
                          color={quoteDetails?.color || 'N/A'}
                          currency={quoteDetails.currencySymbol || ""}
                        /> 
                  }
                </ul>
              </div>
              <div className="card-footer custom-card-footer">
                <div className='d-flex flex-row justify-content-between price-breakdown'
                >
                  <p className="card-footer-item-title">Subtotal</p>
                  <p>₦{NumberWithComma(quoteDetails?.calculatedTotalCostMajor || 0)}</p>
                </div>
                <div className='d-flex flex-row justify-content-between price-breakdown'>
                  <p>Delivery</p>
                  <p>₦{NumberWithComma(quoteDetails?.deliveryCostMajor || 0)}</p>
                </div>
                <div className='d-flex flex-row justify-content-between
                            fw-bold orde-summary-total price-total'
                >
                  <p>Total</p>
                  <p>₦{NumberWithComma(totalCost)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="card order-summary">
              <div className="card-header card-custom-header bg-white">
                <p>Payment and delivery information</p>
              </div>
              <div className="card-body">
                {/* eslint-disable-next-line */}
                <ul className="list-group list-group-flush">
                  {/* eslint-disable-next-line */}
                  {loadingRequest &&
                    <div className='text-secondary text-center fw-bold'>
                      <span className='spinner-border spinner-border-lg'></span>
                    </div>
                  }
                  <SellerPageOrderPaymentMethod
                    method={'card'}
                  />
                  <SellerPageOrderDeliveryAddress
                    address={quoteDetails?.orderLocation?.address}
                    state={quoteDetails?.orderLocation?.state}
                    country={quoteDetails?.orderLocation?.country}
                  />
                  <SellerPageOrderAddInfo
                    contactFullName={`${quoteDetails?.buyerUserPublicProfile?.firstName} ${quoteDetails?.buyerUserPublicProfile?.lastName}`}
                    contactEmail={quoteDetails?.buyerUserPublicProfile?.contactEmail}
                    contactPhoneNumber={quoteDetails?.buyerUserPublicProfile?.contactPhoneNumber}
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SellerQuoteResponseModal show={show} setShow={setShow} quoteId={quoteId as string} orderReceiveType={quoteDetails?.orderReceiveType} />
      <Toaster></Toaster>
    </div>
  )
}

export default SellerQuoteDetails

import React from 'react'
import { Link } from 'react-router-dom'
import "./NewDeliveryItem.scss"

const NewDeliveryItem = () => {
  return (
        <div className='newDeliveryItem'>
           <div className="card">
            <div className="card-body">
                <div className="label">
                  <Link to="/buyer/my-account/address" className='text-decoration-none'><i className='d-icon d-add'></i>  Add new address</Link>
                </div>  
            </div>
           </div>
        </div>
  )
}

export default NewDeliveryItem

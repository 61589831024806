import { FC } from "react"
import { Toaster } from "react-hot-toast";
import "./StoreBanner.scss"

export interface UserProfile {
    firstName: string
    lastName: string
    businessProfile: {
        businessName: string
        cacNumber: string
        businessAddress: string
    }

}

const StoreBanner: FC<UserProfile> = ({ businessProfile }) => {

    return (
        <div className='store-banner'>
            <div className="row banner-container p-3">
                <div className="col-12 banner-container-up">

                </div>
                <div className="col-12 banner-container-down">
                    <div className='banner-label'>
                        <p>{businessProfile.businessName}</p>
                        <span>Description</span>
                    </div>
                    <div className="cust-container">
                        <button className='customize-btn'>
                            Customize Store
                        </button>
                        <img src="" alt="" /> <span>Share link</span>
                    </div>
                </div>


                <Toaster></Toaster>
            </div>
        </div>

    )


}
export default StoreBanner
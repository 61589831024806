import React, { FC, useRef, useState } from 'react'
import "./ProductItem.scss"

import { Link } from 'react-router-dom';
import AddWishList from '../AddWishList/AddWishList'
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';
import { CurrencyFormatter } from '../../../../../Helpers/CurrencyFormatter';
import { Overlay, Tooltip } from 'react-bootstrap';
import { capitalize } from '../../../../../Hooks';

interface propsType {
    data: {
        productUuid: string;
        productName: string;
        productDescription: string;
        minimumQuantity: number;
        maximumQuantity: number;
        unitOfMeasurement: string;
        totalNumberOfRatings: string;
        images:any[];
        sellerPublicProfile: {
            businessProfile: {
                businessName: string;
                usinessAddress: string;
            };
            photoUrl:string;

        };
        unitPriceForBuyer:number;
        unitPromoPriceForBuyer:number;
        currencySymbol:string;
        locationState:string;
        hasVariants: boolean;

    }
}
const ProductItem:FC<propsType> = ({ data }) => { 

  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <div className="productItem col-6 col-sm-4  col-md-6 col-lg-2 p-1">
        <div className="card product-card border-0 shadow-sm">
            <div className="card-body px-0">
                <div className="container-fluid">
                    <div className="row p-0">
                        <div className="col-6">
                           <div className="brand-logo-cont">
                              <img src={data.sellerPublicProfile.photoUrl} className="brand-logo rounded" alt=""/>
                            </div>
                        </div>

                        <div className="col-6 text-end">
                            <AddWishList productUuid={data.productUuid}></AddWishList>
                        </div>

                        <div className="col-12 product-image-outter">
                            <div className="promo d-none">
                                20% off
                            </div>
                            <div className="product-image-container">
                                <Link to={data.hasVariants? `/products/variant/${data.productUuid}`: `/product/${data.productUuid}`}>
                                <ImageSrc src={data?.images[0]?.url} alt={data.productName} title={data.productName} width="product-image mt-3"></ImageSrc>
                                </Link>
                            </div>
                        </div>

                        <div className="col-12">
                            <Link to={data.hasVariants? `/products/variant/${data.productUuid}`: `/product/${data.productUuid}`} className='text-decoration-none'>
                                <h4 className="product-title">
                                    {capitalize(data.productName)} 
                                </h4>
                            </Link>
                        </div>

                        <div className="col-8">
                            {data.unitPromoPriceForBuyer !== 0
                              ? (<div className="price">
                                    <div className="old-price me-1 d-none">
                                    {data.currencySymbol}{CurrencyFormatter(data.unitPromoPriceForBuyer)}
                                    </div>
                                    <div className="new-price">
                                    {data.currencySymbol}{CurrencyFormatter(data.unitPriceForBuyer)}
                                    </div>
                                </div>)
                              : (<div className="price">
                                    <div className="new-price">
                                    {data.unitPriceForBuyer !==0? <>{data.currencySymbol}{CurrencyFormatter(data.unitPriceForBuyer)}</>:<>
                                        <Link to={data.hasVariants? `/products/variant/${data.productUuid}`: `/product/${data.productUuid}`} className='new-price text-decoration-none'>
                                            Request quote
                                        </Link>
                                    </>}
                                    </div>
                                </div>)}
                        </div>

                        <div className="col-4">
                            {data.totalNumberOfRatings && <div className="rating">
                                <span className="d-icon d-star"></span>
                                {data.totalNumberOfRatings}/5
                            </div>}
                        </div>

                        <div className="col-7 mt-2 pe-1">
                            <div className="company-name" 
                                ref={target} 
                                onClick={() => setShow(!show)} >
                               {data.sellerPublicProfile?.businessProfile?.businessName}
                            </div>
                            <Overlay target={target.current} show={show} placement="top">
                                {(props) => (
                                <Tooltip id="overlay-example" {...props}>
                                  {data.sellerPublicProfile?.businessProfile?.businessName}
                                </Tooltip>
                                )}
                            </Overlay>
                        </div>

                        <div className="col-5 mt-2 p-1">
                            <div className="location">
                                <span className="d-icon d-location"></span>
                                 {data.locationState}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProductItem

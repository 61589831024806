import { FC } from "react";
import DocumentUploadCard from "../../../../Component/PLP/DocumentUploadCard/DocumentUploadCard";


interface propTypes {
  handleFileUpload: any
  checkIfExist:any
}

const DocumentUploadInputs:FC<propTypes> = ({handleFileUpload, checkIfExist}) => {
  
    return (
         <div>
             <div className="form-section-header">
          <p className="form-section-title">Upload documents</p>
          <p className="form-section-description">Upload your relevant documents.</p>
         </div>
         <div className="row"> 
          <div className="mb-3 col-12 col-md-6">
              <DocumentUploadCard title="Upload Government Approved ID Card"
                changeHandler={(event:any, fileName:any)=>handleFileUpload(event, fileName)}
                description="Any one of the following is acceptable: National ID Card, Voters card, Nigerian International Passport or Drivers license."
                name="idcard"
                doc="PRODUCT_LEASE_REQUEST_ID_CARD"
                checkIfExist={checkIfExist}
              />
          </div>
          
          <div className="mb-3 col-12 col-md-6">
              <DocumentUploadCard title="Upload your CAC Certificate."
                changeHandler={(event:any, fileName:any)=>handleFileUpload(event, fileName)}
                description="Any one of the following is acceptable: National ID Card, Voters card, Nigerian International Passport or Drivers license."
                name="cac"
                doc="PRODUCT_LEASE_REQUEST_CAC_CERTIFICATE"
                checkIfExist={checkIfExist}
              />
          </div>

          <div className="mb-3 col-12 col-md-6">
              <DocumentUploadCard title="Upload your company bank statement for the last 12 months."
                changeHandler={(event:any, fileName:any)=>handleFileUpload(event, fileName)}
                description="Most banks allow you to request for your bank statement on your bank mobile app or via USSD."
                name="bankStatement" 
                doc="PRODUCT_LEASE_BANK_STATEMENT"
                checkIfExist={checkIfExist}
              />
          </div>

          <div className="mb-3 col-12 col-md-6">
              <DocumentUploadCard title="Upload a recent Utility Bill for the business address (Last three months)"
                changeHandler={(event:any, fileName:any)=>handleFileUpload(event, fileName)}
                description="Most banks allow you to request for your bank statement on your bank mobile app or via USSD."
                name="uBill"
                doc="PRODUCT_LEASE_UTILITY_BILL"
                checkIfExist={checkIfExist}
              />
          </div>

          <div className="mb-3 col-12 col-md-6">
              <DocumentUploadCard title="Distributorship appointment letter"
                changeHandler={(event:any, fileName:any)=>handleFileUpload(event, fileName)}
                description="Letter received from the manufacturer confirming you are a Distributor. "
                name="dApointLetter"
                doc="PRODUCT_LEASE_DISTRIBUTORSHIP_APPOINTMENT_LETTER"
                checkIfExist={checkIfExist}
              />
          </div> 
         </div>
        </div>
    )
}

export default DocumentUploadInputs;
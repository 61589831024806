import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast' 
import { Link, useParams , useNavigate } from 'react-router-dom'
import { EnterprisePageTitle, HorizontalLoader, ImageSrc } from '../../../../../../../Components' 
import wareHouseService from '../../../../../../../Services/wareHouseService'
import "./EnterpriseWareHouseShipToSiteDetail.scss"
 
const EnterpriseWareHouseShipToSiteDetail = () => { 
    const {deliveryRequestUuid} = useParams<{deliveryRequestUuid:string}>() 
   
    const [siteRequest, setSiteRequest] = useState(true)
    const [siteOrderItem, setSiteOrderItem] = useState<any>()
    const [loadingOrder, setLoadingOrder] = useState<boolean>(false)

    
      const getSite = async () => {
        setSiteRequest(true)
        await wareHouseService.getSingleSiteOrder(deliveryRequestUuid)
        .then((res) => { 
            setSiteRequest(false) 
          setSiteOrderItem(res.data.data)
        }, (error:any) => { 
          setSiteRequest(false)
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
      }


      useEffect(()=>{
        getSite()
        return ()=>toast.dismiss()
      }, [])

    const navigate = useNavigate()
    const declicneOrder = async () => {
        setLoadingOrder(true)

        await wareHouseService.getSiteOrderDecline(siteOrderItem.wareHouseDetails.uuid, deliveryRequestUuid, {status:"DELIVERY_FEE_REJECTED"})
          .then((res)=>{
            console.log("res", res)
            setLoadingOrder(false)
            navigate("/enterprise/order-management",{replace: true})
          }, (error:any) => {  
            console.log("res error", error)
            setLoadingOrder(false)
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
    }
  
    return (
        <div className='enterpriseWareHouseShipToSiteDetail'>
             <div className="container">
                <EnterprisePageTitle
                    title={"Ship to site "}
                    parent={"Order management"}
                    parentUrl={`order-management`}
                    showTitle={true}
                />
 
             <div className="row justify-content-center">
                <div className="col-md-7"> 
                    <div className="card mt-5">
                      <div className="card-body mx-md-2"> 
                         {siteRequest ===true && <HorizontalLoader></HorizontalLoader>}
                         {siteRequest ===false && siteOrderItem && <><div className="sit-info mb-4">
                              <div className="site-title">{siteOrderItem.deliverySiteDetails.address} site.</div>
                              <div className="site-address">
                                {siteOrderItem.deliverySiteDetails.address},
                                {siteOrderItem.deliverySiteDetails.state},
                                {siteOrderItem.deliverySiteDetails.country}.
                                </div>
                              <div className="site-contact">{siteOrderItem.deliverySiteDetails.contactFullName},  {siteOrderItem.deliverySiteDetails.contactPhoneNumber}</div>
                              <div className="site-contact fw-bolder">Delivery Fee: &#8358;{siteOrderItem.deliveryFeeAmountMajor}</div> 
                          </div> 
                          <div className="site-product border rounded position-relative p-3">
                          {siteOrderItem?.deliveryItems?.length && siteOrderItem?.deliveryItems?.map((res:any, index:React.Key)=>{
                             return <div  key={index} className="row">
                              <div className="col-10 my-auto">
                                <span className='product-image-container'>
                                  <ImageSrc src={""} alt={res.productName} title={res.productName} width={'product-image'}></ImageSrc>
                                </span>
                                <span className='product-title-container'>{res.productName}</span>  
                              </div>
                              <div className="col-2  my-auto">
                              {res.quantity}
                              </div>
                            </div>  
                              })}
                          </div>
                          </>}
                      </div>
                  </div>

                  {siteRequest ===false && siteOrderItem &&
                   <div className="row mt-5 mb-5">
                    <div className="col-6">
                      <Link to={`/enterprise/warehouse-management/ship-to-site/checkout/${deliveryRequestUuid}`}><button className="btn delivery-btn">Accept and proceed to pay</button></Link>
                    </div>
                    <div className="col-6"> 
                      {loadingOrder===false && <button className="btn go-btn btn-danger bg-danger" onClick={declicneOrder}>Decline</button> }
                      {loadingOrder && <button className="btn go-btn btn-danger bg-danger"><span className='spinner-border spinner-border-sm'></span></button>}
                      
                    </div>
                  </div>
                  }
                </div>
              </div> 
            </div> 
            <Toaster></Toaster>
        </div>
    )
}

export default EnterpriseWareHouseShipToSiteDetail

import React from 'react'
import "./Expenses.scss"

const Expenses = () => {
    return (
        <div className='expenses'>
            <div className="card">
                <div className="card-header border-0 bg-white">
                    <div className="row">
                        <div className="col-md-10"> 
                        Expenses
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="card-body">

                    <div className="no-product">
                    You have no expense yet
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expenses

import React, { Key, useEffect, useState } from 'react'
import "./PopularProjectItems.scss" 
import projectService from '../../../../../../../Services/projectService'
import { projectDetailsType } from '../../../../../../../Models/projectType'
import { CurrencyFormatter } from '../../../../../../../Helpers'
import ImageSrc from '../../../../../../../Components/ImageSrc/ImageSrc';
import { Link, useNavigate } from 'react-router-dom'

const PopularProjectItems = () => {
    const [projectLocations, setProjectLocations] = useState<Array<projectDetailsType>>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    
    const getPopularProject = async () =>{
        setIsLoading(true)
        await projectService.getPopularProject().then((response)=>{
            setProjectLocations(response.data.data)
            setIsLoading(false) 
        }, ()=>{ 
            setIsLoading(false)
        })
    }

    useEffect(()=>{
        getPopularProject()
    }, [])
 
    const navigate = useNavigate()
    return (
        <div className='PopularProjectItems'>
            <div className="container">
                <div className="row mt-5">
                    {!isLoading && projectLocations && projectLocations?.length > 0 && projectLocations.map((project: projectDetailsType, index:Key)=>{
                     return <div className="col-md-4" key={index}>
                        <div className="card border-0 mb-4">
                            <div className="card-image" onClick={()=>{ navigate(`/estate-managers/subscriber-projects/${project.projectUuid}`, {replace: true}) }}>
                                <ImageSrc src={project.images[0].url} alt={''} title={undefined} width={'w-100'}></ImageSrc> 
                                 <div className="featured-text">
                                   {project.type}
                                 </div>
                            </div>
                            <div className="card-body p-1">
                                <div className="row p-0 mt-3"> 
                                    <div className="col-6">
                                        <div className="price">
                                            ₦{CurrencyFormatter(project.costPerSlot)} <small>perunit</small>
                                        </div>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <span className='units'>{project.numberOfSlots} units</span>
                                    </div>
                                </div>

                                <div className="project-title mt-1">
                                    <Link to={`/estate-managers/subscriber-projects/${project.projectUuid}`}> 
                                        {project.name}
                                    </Link>
                                </div>
                                <div className="project-description mt-1">
                                    {project.details}
                                </div>

                                <div className="project-location mt-2">
                                    <svg width="11" height="11" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99992 8.95297C9.14867 8.95297 10.0799 8.02172 10.0799 6.87297C10.0799 5.72422 9.14867 4.79297 7.99992 4.79297C6.85117 4.79297 5.91992 5.72422 5.91992 6.87297C5.91992 8.02172 6.85117 8.95297 7.99992 8.95297Z" stroke="#797A7C" strokeWidth="1.5"/>
                                        <path d="M2.41379 5.66065C3.72712 -0.112681 12.2805 -0.106014 13.5871 5.66732C14.3538 9.05399 12.2471 11.9207 10.4005 13.694C9.06046 14.9873 6.94046 14.9873 5.59379 13.694C3.75379 11.9207 1.64712 9.04732 2.41379 5.66065Z" stroke="#797A7C" strokeWidth="1.5"/>
                                    </svg>
                                    {project.state}
                                </div>
                            </div>
                        </div>
                    </div> 
                       }
                    )} 
                </div>
            </div>
        </div>
    )
}

export default PopularProjectItems

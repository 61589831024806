import React, { useEffect, useMemo, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useAllProduct } from '../../../../../../Hooks' 
import "./EnterpriseProduct.scss"
import { Pagination } from '../../../../../../Components'; 
import { EnterpriseProductItem, EnterpriseProductItemSkeleton } from '../../Components'
import searchsetting4 from "../../../../../../Assets/Images/icon/search-normal.png"
import HorizontalLoader from '../../../../../../Components/Loader/HorizontalLoader/HorizontalLoader';

const EnterpriseProduct = () => {

    const [pageNumber, setPage] = useState(1)
  const [sortOrder,] = useState("DESC")

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number,
    pageSize: number,
    total:number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1
  })
 
  const [brandUuids,] = useState<any[]>([])
  const [categoryUuids,] = useState<any[]>([])  
  const [locationStates,] = useState<any[]>([])

  const { data: product, isLoading, isSuccess, isFetching, refetch: getProduct } = useAllProduct(pageNumber, sortOrder, brandUuids, categoryUuids, locationStates)

  useMemo(() => {
    isSuccess && setPaginationData({
      pageNumber: product?.pageNumber,
      pageSize: product?.pageSize,
      total: product?.total
    })
  }, [product])

  useEffect(() => {
    getProduct()
  }, [pageNumber, sortOrder])
 
  useEffect(() => {
    if (!isFetching) {   
      window.scrollTo(0, 0)
     }
  }, [isFetching])

    return (
        <div className='enterpriseProduct'>

          <div className="page-header">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="page-title">100 Products Available </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="search-container">
                    <input type="text" placeholder='Search' />
                    <img src={searchsetting4} alt="" className="search-icon"/>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="filter-container">
                    <select>
                      <option value="">All product</option>
                    </select>
                    <div className="filter-icon-text">Filter: </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

            <div className="container px-md-5">
                <div className="row">
                   {isSuccess && product?.dataset?.length > 0 && product?.dataset?.map((res, index:React.Key) => <EnterpriseProductItem data={res} key={index}></EnterpriseProductItem>)}
                   {isLoading === true && <EnterpriseProductItemSkeleton cardCount={10}></EnterpriseProductItemSkeleton>}
                </div>
 
                {isFetching===true && <HorizontalLoader></HorizontalLoader>}

                {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                    {paginationData.total && <Pagination
                    className="pagination-bar ps-0"
                    currentPage={Number(paginationData.pageNumber)}
                    totalCount={paginationData.total}
                    pageSize={paginationData.pageSize}
                    onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                    }
                </div>}
            </div> 
            <Toaster></Toaster>
        </div>
    )
}

export default EnterpriseProduct

import React from 'react' 
import { ImageSrc } from '../../../../../../../Components'
import "./ReorderProduct.scss"

const ReorderProduct = () => {
    return (
        <div className='reorderProduct-list'>
             <div className="card">    
                <div className="card-header bg-white">
                    <div className="row">
                        <div className="col-10">
                            Reorder Products
                        </div>
                        <div className="col-2 text-end">
                            <div className="see-all">See all</div>
                        </div>
                    </div>
                </div>
                <div className="card-body p-0">    
                    <div className="enterprise-table">
                        <div className="table-responsive">
                            <table className="table border-0"> 
                                <thead className='rounded'>
                                    <tr className='d-none d-md-table-row  border-0'>
                                        <th scope="col">ID</th>
                                        <th scope="col">Product</th>
                                        <th scope="col" className='text-center'>Reorder Level</th>
                                        <th scope="col" className='text-center'>Reorder Level Max</th>
                                        <th scope="col" className='text-center'>Available in stock</th>
                                        <th scope="col" className='text-center'>Quantity to Reorder</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody className='d-none'>
                                    <tr className='d-none d-md-table-row  border-0'>
                                        <td>001</td>
                                        <td>
                                            <ImageSrc src={''} alt={''} title={undefined} width={'product-image'}></ImageSrc>
                                            Dangote OPC 43 Grade Cement 50 kg Bag</td>
                                        <td className='text-center'> 50</td> 
                                        <td className='text-center'>5000</td>
                                        <td className='text-center'>50</td>
                                        <td className='text-center'>450</td> 
                                        <td>
                                            <button className='btn btn-reorder'>
                                                <svg className="me-2" width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.0655 3.30562H4.04305L5.45305 1.89562C5.67055 1.67812 5.67055 1.31813 5.45305 1.10063C5.23555 0.883125 4.87555 0.883125 4.65805 1.10063L2.28805 3.47062C2.23555 3.52312 2.19805 3.58312 2.16805 3.65062C2.13805 3.71812 2.12305 3.79312 2.12305 3.86812C2.12305 3.94312 2.13805 4.01812 2.16805 4.08562C2.19805 4.15312 2.23555 4.21312 2.28805 4.26562L4.65805 6.63563C4.77055 6.74813 4.91305 6.80062 5.05555 6.80062C5.19805 6.80062 5.34055 6.74813 5.45305 6.63563C5.67055 6.41813 5.67055 6.05813 5.45305 5.84062L4.04305 4.43062H13.0655C13.9955 4.43062 14.753 5.18812 14.753 6.11812V8.60812C14.753 8.91563 15.008 9.17062 15.3155 9.17062C15.623 9.17062 15.878 8.91563 15.878 8.60812V6.11812C15.878 4.56562 14.618 3.30562 13.0655 3.30562Z" fill="#F5A623"/>
                                                    <path d="M15.878 14.1306C15.878 14.0556 15.863 13.9806 15.833 13.9131C15.803 13.8456 15.7655 13.7856 15.713 13.7331L13.343 11.3631C13.1255 11.1456 12.7655 11.1456 12.548 11.3631C12.3305 11.5806 12.3305 11.9406 12.548 12.1581L13.958 13.5681H4.93555C4.00555 13.5681 3.24805 12.8106 3.24805 11.8806V9.39062C3.24805 9.08312 2.99305 8.82812 2.68555 8.82812C2.37805 8.82812 2.12305 9.08312 2.12305 9.39062V11.8806C2.12305 13.4331 3.38305 14.6931 4.93555 14.6931H13.958L12.548 16.1031C12.3305 16.3206 12.3305 16.6806 12.548 16.8981C12.6605 17.0106 12.803 17.0631 12.9455 17.0631C13.088 17.0631 13.2305 17.0106 13.343 16.8981L15.713 14.5281C15.7655 14.4756 15.803 14.4156 15.833 14.3481C15.863 14.2806 15.878 14.2056 15.878 14.1306Z" fill="#F5A623"/>
                                                </svg>
                                                Reorder Now
                                            </button>
                                        </td>
                                    </tr>  
                                    <tr className='d-md-none'>
                                        <td>
                                            <ImageSrc src={''} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                                        </td>
                                        <td colSpan={4}>
                                            <div className="row"> 
                                                <div className='col-12 profile-title'>Micheal Jordan</div>
                                                <div className='col-12 profile-detail'>michealjordan@gmail.com</div> 
                                                <div className='col-12 profile-detail'>Lagos, Warehouse Manager</div>
                                            </div>
                                        </td>
                                        <td className='profile-btn'>

                                            <button type="button" className="btn btn-sm p-1 border d-md-none">
                                                View
                                            </button> 
                                        </td>   
                                    </tr>  
                                </tbody> 
                                <tbody>
                                    <tr>
                                        <td colSpan={7}>
                                            <div className="no-order">You have no reorder product</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReorderProduct

import React, { FC, useEffect, useState } from 'react'
// import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import './SellerWrapper.scss'
// import { useServiceWorker } from '../../../../Hooks/useServiceWorker'
import EventBus from '../../../../Helpers/EventBus'
import { Logout } from '../../../../Helpers/Logout'
import { SellerHeader, SellerMobileHeader, SellerSidebar } from '../../../../Layout';
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider';
// import { Outlet } from 'react-router-dom';
import BusinessProfileUpdate from '../../../Frontend/Interface/Seller/Component/BusinessProfileUpdate/BusinessProfileUpdate';

interface propTypes {
  children: any
}

const SellerWrapper: FC<propTypes> = ({ children }) => { 
  const [show, setShow] = useState(false) 
  
  useEffect(() => {
    EventBus.on("logout", () => {
      Logout()
    });

    return () => {
      EventBus.remove("logout", () => {
        Logout()
      });
    };
  }, []);


  const { user } = useAuth()

  useEffect(()=>{  
    if (user.updateBusinessInfo === true) {
      setShow(true) 
    }  
  }, [user.isSeller === true])

  return (
    <React.Fragment>
      {/* { children } */}
      <div className='seller-wrapper'>
        <SellerMobileHeader />
        <div className="row">
          <div className="col-25 pe-0">
            <SellerSidebar></SellerSidebar>
          </div>
          <div className="col-85 ps-0">
            <SellerHeader></SellerHeader>
            <div>
              {children}
            </div>
          </div>
        </div>
        {/* <Footer></Footer> */}
      </div>
      <BusinessProfileUpdate show={show} handleClose={ ()=>setShow(false)}></BusinessProfileUpdate>
    </React.Fragment>
  )
}

export default SellerWrapper

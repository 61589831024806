import React, { FC, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'; 
import { CurrencyFormatter } from '../../../../../../Helpers';
import { TimeConverter } from '../../../../../../Helpers/TimeConverter';
import ImageSrc from '../../../../../../Components/ImageSrc/ImageSrc';
import HorizontalLoader from '../../../../../../Components/Loader/HorizontalLoader/HorizontalLoader';
import orderService from '../../../../../../Services/orderService'; 
import toast from 'react-hot-toast';
import "./SellerOrders.scss"

 
  interface ISellerOrdersProps { }

  const SellerOrders: FC<ISellerOrdersProps> = () => {
    // eslint-disable-next-line no-unused-vars
    const [status, setStatus] = useState("ALL");
    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = useState(1); 
    // eslint-disable-next-line no-unused-vars
    const [sortOrder, setSortOrder] = useState("DESC");
    // eslint-disable-next-line no-unused-vars
    const [statusInactive, setStatusInactive] = useState("CONFIRMED"); 
    // eslint-disable-next-line no-unused-vars
    const [persona, setPersona] = useState("SELLER");
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  
    // eslint-disable-next-line no-unused-vars
    const [pagination, setPagination] = useState({
      pageNumber: "1",
      pageSize: 20,
      total: 1,
    });

    const [myOrders, setMyOrders] = useState<any[]>([]);

    const getMyOrder = () => {
      setLoadingRequest(true);
      orderService.getAllMyOrder(page, sortOrder, persona, status).then(
        (res: any) => {
          setLoadingRequest(false);
          setMyOrders(res.data.data.dataset);
          setPagination({
            pageNumber: res.data.data.pageNumber,
            pageSize: res.data.data.pageSize,
            total: res.data.data.total,
          });
          console.log("My orders", res);
        },
        (error: any) => {
          setLoadingRequest(false);
          toast.error(error.message, {
            duration: 20000,
            className: "bg-danger text-white",
          });
        }
      );
    };
  
    React.useEffect(() => {
      const abortController = new AbortController();
      getMyOrder();
      abortController.abort();
    }, [status, page]);
  
 
   

    
    return (
        <div className="seller-orders-container">  
            <div className="container top-filter mt-5">
                 <div className="row mt-4 h-100"> 
                    <div className="col-12 col-sm-1  col-xl-1 my-auto">Orders</div>
                    <div className="col-12 col-sm-5  col-xl-4">
                      <div className="seach-field">
                        <input type="text" className='search-input form-control' placeholder='Search product...'/>
                        <svg className="search-icon" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6752 11.5657C9.3418 13.8991 5.55859 13.8991 3.22518 11.5657C0.891767 9.23226 0.891767 5.44905 3.22518 3.11564C5.55859 0.782229 9.3418 0.782229 11.6752 3.11564C14.0086 5.44905 14.0086 9.23226 11.6752 11.5657ZM11.6752 11.5657L16.4128 16.3033" stroke="#868484" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>  
                      </div>
                    </div>
                    <div className="col-4 col-sm-1 col-xl-5">
 
                    </div>
                    <div className="col-12 col-sm-5 col-xl-2">
                       
                        <button className='btn btn-filter dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg className="btn-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 11V17L11 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 17L7 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> 
                            Filter 
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                            <li><a className="dropdown-item" href="#">
                                 All order
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#"> 
                                Export as .doc
                                </a>
                            </li> 
                            <li>
                                <a className="dropdown-item" href="#"> 
                                    Export as .xlsx
                                </a>
                            </li> 
                        </ul>

                    </div> 
                </div>
            </div>

          <div className='container p-sm-3 p-md-3 seller-table'>
            <table className="table rounded border-0"> 
                <thead className='rounded border-0'>
                    <tr className='d-none d-md-table-row border-0'>
                        <th scope="col"></th>
                        <th scope="col">Order ID</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Product</th>
                        <th scope="col">Price</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>  
                        <th scope="col"></th>  
                    </tr>
                </thead>
                <tbody>
                {myOrders.length > 0 && myOrders.map((res:{
                    referenceNumber:number,
                    calculatedTotalCostMajor:number,
                    currencySymbol:string,
                    orderItems:any,
                    status:any,
                    orderUuid:string,
                    createdAt:any,
                    buyerPublicProfile: any,
                    procurementInvoiceUuid:string
                    }, index: React.Key | null | undefined) => {
                    return (
                         <Fragment key={index}>
                            <tr className='d-none d-md-table-row'>
                                <td>
                                {(res.status === "CONFIRMED" || res.status === "COMPLETED") && <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="5.5" cy="5.5" r="5.5" fill="#FFA685"/>
                                    </svg> }
                                    {(res.status === "CANCELLED_BY_BUYER" || res.status === "CANCELLED_BY_SELLER" || res.status === "ENDED_WITH_DISPUTES") && <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4"/>
                                        </svg>}

                                        {(res.status === "CREATED" || res.status === "IN_PROGRESS" || res.status === "AVAILABLE_FOR_PICKUP") && <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="5.5" cy="5.5" r="5.5" fill="#FFA685"/>
                                    </svg> }

                                </td>  
                                <td> #{res.referenceNumber}</td>
                                <td> {res.buyerPublicProfile.firstName}{res.buyerPublicProfile.lastName}</td>
                                <td> {res.orderItems.length > 0 && res.orderItems.map((resInner:any, index:any) => {
                                    return <React.Fragment key={index}>{ resInner.productName}{res.orderItems.length !== (index + 1) ? ", " : ""}</React.Fragment>
                                    })}
                                </td> 
                                <td> {res.currencySymbol} {CurrencyFormatter(res.calculatedTotalCostMajor)}</td>
                                <td>
                                    {(res.status === "CONFIRMED" || res.status === "COMPLETED") && <span className='created-status'> {res.status}</span>} 
                                    {(res.status === "CANCELLED_BY_BUYER" || res.status === "CANCELLED_BY_SELLER" || res.status === "ENDED_WITH_DISPUTES") && <span className='status-pending'> {res.status}</span>}
                                    {(res.status === "CREATED" || res.status === "IN_PROGRESS" || res.status === "AVAILABLE_FOR_PICKUP") &&  <span className='created-status'>{res.status}</span>}
                                </td>
                                <td> { TimeConverter(res.createdAt)}</td> 
                                <td>
                                <div className="btn-group">
                                    <button type="button" className="btn border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className='d-icon d-more-vertical'></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                                    <li>
                                        <a className="dropdown-item d-none" href="#">
                                            <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.4405 8.89844C20.0405 9.20844 21.5105 11.0584 21.5105 15.1084V15.2384C21.5105 19.7084 19.7205 21.4984 15.2505 21.4984H8.74047C4.27047 21.4984 2.48047 19.7084 2.48047 15.2384V15.1084C2.48047 11.0884 3.93047 9.23844 7.47047 8.90844" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 14.9972V3.61719" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M15.3504 5.85L12.0004 2.5L8.65039 5.85" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg> 
                                            Pay Now
                                        </a>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to={`/seller/my-orders/details/${res.orderUuid}`}>
                                            <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.16992 7.4375L11.9999 12.5475L20.7699 7.46747" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 21.6091V12.5391" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M9.92963 2.48L4.58963 5.45003C3.37963 6.12003 2.38965 7.80001 2.38965 9.18001V14.83C2.38965 16.21 3.37963 17.89 4.58963 18.56L9.92963 21.53C11.0696 22.16 12.9396 22.16 14.0796 21.53L19.4196 18.56C20.6296 17.89 21.6196 16.21 21.6196 14.83V9.18001C21.6196 7.80001 20.6296 6.12003 19.4196 5.45003L14.0796 2.48C12.9296 1.84 11.0696 1.84 9.92963 2.48Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M16.9998 13.2416V9.5816L7.50977 4.10156" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                            Order Details
                                            </Link>
                                        </li> 
                                    </ul>
                                </div>
                                </td>
                            </tr>  
                            <tr className='d-md-none'>
                                <td>
                                    <ImageSrc src={''} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                                </td>
                                <td colSpan={4}>
                                    <div className="row"> 
                                        <div className='col-12 profile-title'>
                                        {res.orderItems.length > 0 && res.orderItems.map((resInner:any, index:any) => {
                                            return <React.Fragment key={index}>{ resInner.productName}{res.orderItems.length !== (index + 1) ? ", " : ""}</React.Fragment>
                                        })}
                                        </div>
                                        <div className='col-12 profile-detail'>{res.currencySymbol} {CurrencyFormatter(res.calculatedTotalCostMajor)}</div> 
                                        <div className='col-12 profile-detail'>{ TimeConverter(res.createdAt)}</div>
                                    </div>
                                </td>
                                <td className='profile-btn'>
                                <Link className="dropdown-item" to={`/enterprise/order-details/${res.orderUuid}`}>
                                    <button type="button" className="btn btn-sm p-1 border d-md-none">
                                        View
                                    </button> 
                                </Link>
                                </td>   
                            </tr>  
                            </Fragment>) 
                        })}
                        {loadingRequest === false && myOrders?.length === 0 &&  <tr>
                        <td colSpan={9}>
                            <div className='text-secondary text-center fw-bold'>No item found</div>
                        </td>
                    </tr>}
                {loadingRequest === true && <tr>
                        <td colSpan={9}>
                            <div className='text-secondary text-center fw-bold'><HorizontalLoader></HorizontalLoader></div>
                        </td>
                    </tr>}
                </tbody> 
            </table> 
        </div>
    </div>
    )
}

export default SellerOrders

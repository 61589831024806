import React, { FC, useEffect } from 'react'
import { ADD_TO_CART_QUANTITY } from '../../../../../Constants/CartConstants'
import "./ProductQuantity.scss"
import { useDispatch } from "react-redux" 

interface propsType {
  maximumQuantity: number,
  minimumQuantity: number
}

const ProductQuantity:FC<propsType> = ({minimumQuantity, maximumQuantity}) => {

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch({ type: ADD_TO_CART_QUANTITY, payload: minimumQuantity })
  }, [])
 
  return (
    <div className="productQuantity pb-3">
        <label htmlFor="Qty">Quantity:</label> 
        <input
          type="number"
          id="Qty"
          defaultValue={minimumQuantity}
          max={maximumQuantity} 
          min={minimumQuantity}
          className="form-control"
          placeholder="Enter quantity"
          onChange={(e: { target: { value:string } }) => dispatch({ type: ADD_TO_CART_QUANTITY, payload: e.target.value })}
          required
        />
    </div>
  )
}

export default ProductQuantity

import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import userService from '../../../../../Services/userService';
import country from "../../../../../Data/country.json"
import state from "../../../../../Data/stateNG.json"
import toast, { Toaster } from 'react-hot-toast';
import { ErrorHandler } from '../../../../../Helpers/ErrorHandler';

type formProp = {
    contactFullName:string;
    contactPhoneNumber:string;
    country:string;
    state:string;
    address:string;
    uuid:any;
    isDefault:boolean|undefined;
   }

interface propsType {
    createdAction: Function 
}
const AddDeliveryAddress:FC<propsType> = ({createdAction}) => {
  const [sendingRequest, setSendingRequest] = useState(false); 

  const [selectStates, setSelectedStates] = useState<any>([]) 

  const { register, handleSubmit, formState: { errors } } = useForm<formProp>();
  const [selectedCountry, setCountry] = useState<any>({
    id: "",
    sortname: "",
    name: "",
    phoneCode: ""
  })



  const handleCountryChange = (e:any) => {
    const allState = state[2].data

    setSelectedStates(allState)

    const cont = JSON.parse(e.target.value)
    setCountry(cont)
  }

  const [show, setShow] = useState(true)

  const submit = handleSubmit(async (data:any) => {
    setSendingRequest(true)
    await userService.buyerDeliveryLocation({ ...data, country: selectedCountry.name })
      .then(res => {
        setSendingRequest(false) 
        toast.success("location created", { duration: 9000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        createdAction()
        setShow(false)

      }, (error:any) => {
        setSendingRequest(false) 
     
        ErrorHandler(error, (e)=>{ 
          toast.error(e, { duration: 9000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
        
       })
  });

  useEffect(()=>{ 
    setShow(true)
  }, [])



    return ( 
        <div>
       {show && <div className='QRaddAddress'>
             <form onSubmit={submit} >  
                <div className='form-group mt-3'>
                    <label htmlFor='address'>Street address </label>
                    <textarea className='form-control fc textarea-pk' {...register('address', { required: true })} rows={4} cols={50} id="address" placeholder='e.g 45 Royal Palmswill Estate, Badore Ajah, Lagos, Nigeria.'></textarea>
                    {errors.address && <div className='text-danger'>{errors.address.message}</div>}
                </div>

                <div className="row">
                    <div className="col-md">  
                        <div className='form-group mt-2'>
                        <label htmlFor='name'>Country</label>
                            <select className='form-control' {...register("country", { required: "This field is required", onChange: (e:any) => handleCountryChange(e) })}>
                                <option>--Country--</option>
                                {country.countries.length > 0 && country.countries.map((res:any, index:React.Key) => {
                                    return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                                })}
                            </select>
                            {errors.country && <div className='text-danger'>{errors.country.message}</div>}
                        </div>
                    </div>
                    <div className="col-md"> 
                        <div className='form-group mt-2'>
                            <label htmlFor='name'>State</label>
                            <select className='form-control' {...register("state", { required: "This field is required" })}>
                            <option>--State--</option>
                            {selectStates.map((result: { id: number ; name: string }, index: React.Key) => {
                            return result.name !=='Nationwide' && <option key={index} value={result.name}>{result.name}</option>
                            })}
                            </select>
                            {errors.state && <div className='text-danger'>{errors.state.message}</div>}
                        </div>
                    </div>
                </div>

                    
                <div className="contact-title">
                    Who to contact for delivery
                </div>

                <div className="row">
                    <div className="col-md"> 
                    <div className='form-group mt-2'>
                        <label htmlFor='contactFullName'>Contact name </label>
                        <input className='form-control fc' id="contactFullName" {...register('contactFullName', { required: true })} placeholder='e.g John Doe'></input>
                        {errors.contactFullName && <div className='text-danger'>{errors.contactFullName.message}</div>}
                    </div> 
                    </div>
                    <div className="col-md">
                    <div className='form-group mt-2'>
                        <label htmlFor='name'>Contact phone</label>
                        <input className='form-control fc' id="name" {...register('contactPhoneNumber', { required: true })} placeholder=''></input>
                        {errors.contactPhoneNumber && <div className='text-danger'>{errors.contactPhoneNumber.message}</div>}
                    </div>
                    </div>
                </div> 

                <div className="row mt-5">
                    <div className="col-md-6"> 
                        {!sendingRequest && (<button type="button" onClick={submit} className="btn rounded btn-primary w-100 shadow-sm mt-4">Save Address</button>)}
                        {sendingRequest && (<button type="button" className="btn btn-primary rounded mx-auto btnadd w-100 shadow mt-4 disabled" disabled>
                            Please wait
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>)} 
                    </div>
                    <div className="col-md-6"> 
                        <button type="button" onClick={()=>createdAction()} className="btn btn-dark rounded mx-auto d-block w-100  shadow-sm mt-4"><i className='d-icon d-arrowhead-right'></i></button>
                    </div>
                </div>
            </form>
            <Toaster></Toaster>
        </div>}
        </div>
    )
}

export default AddDeliveryAddress

import React, { useEffect, useState } from 'react'
import "./Login.scss"
import whiteLogo from "../../../../Assets/Images/logo-white.png"
import Logo from "../../../../Assets/Images/cinderBuildLogo.png"
import cinderBg from "../../../../Assets/Images/cinderBg.png"
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { decodeToken } from "react-jwt";
import authService from '../../../../Services/authService';
import { ErrorHandler } from '../../../../Helpers/ErrorHandler';
import Cookie from 'cookie-universal'
import { HeaderMeta } from '../../../../Components'
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider';
import ReactGA from 'react-ga'
import { Footer } from '../../../../Layout' 

type Inputs = {
    phoneNumber: string;
    password: string;
  };

const Login = () => {
  const history = useNavigate();
  const cookies = Cookie()
  cookies.set("referer", history.name)
  const referrer = cookies.get("referer")

  // eslint-disable-next-line no-unused-vars
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [submit, setSubmit] = useState(false)
  const [error, setError] = useState<any>(null)

  const { login } = useAuth()

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();

  const onSubmit = handleSubmit((data:any) => processLogin(data));

  const processLogin = async (data:any) => {

    ReactGA.event({
      category: "Login",
      action: "login",
      label: "login",
      value: data.phoneNumber,
    })

    setSubmit(true)
    await authService.login(data).then((user:any) => {

      const myDecodedToken:any = decodeToken(user.data.data.token); 
      localStorage.setItem('__cbuN', JSON.stringify({ ...user.data.data, ...myDecodedToken, ...user.data, switched: 0 }))
      login({ ...user.data.data, ...myDecodedToken, ...user.data, switched: 0 })

      setSubmit(false)

      if (myDecodedToken.isSeller === true) {
        // redirect to the refereer
        if (referrer) {
          cookies.remove("referer")
          return history(referrer.from, { replace: true })
        } else {
          return history("/", { replace: true })
        }
      } else {
        // redirect to the refereer
        if (referrer) {
          cookies.remove("referer")
          return history(referrer.from, { replace: true })
        } else { 
          if (myDecodedToken.isCooperate === true) { 

            history("/enterprise/dashboard", { replace: true })
            
            } else if (myDecodedToken.isDeveloper === true) { 

              history("/estate-managers/dashboard", { replace: true })

            } else {
            return history("/", { replace: true }) 
          }
        }
      }
    }, (error:any) => {
      setSubmit(false)
      ErrorHandler(error,
        (e) => {
          setError(e)
        })
    })
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
    <div className="login">
        <HeaderMeta title="Login"></HeaderMeta>
        <div className="row h-100">
            <div className="col-md-6 d-none d-md-block">
                <div className="login-right">
                    <div className="container">
                        <div className="login-header">
                            <div className="row">
                                <div className="col-md-2 text-end">
                                    <div className="login-back">
                                    <Link to="/"><span className="d-icon d-arrowhead-left shadow"></span></Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="login-logo">
                                        <img src={whiteLogo} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="login-text">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <h2>Hey! Sign in to your CinderBuild account</h2>
                                    <div className="desc">
                                    Sign in with phone number and password.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={cinderBg} alt="" className='abstImg'/>
                </div>
            </div>
            <div className="col-md-6 my-auto">
                <div className="login-header d-md-none">
                    <div className="row h-100">
                        <div className="col-2">
                            <div className="login-back">
                            <Link to="/"><span className="d-icon d-arrowhead-left"></span></Link>
                            </div>
                        </div>
                        <div className="col-8 text-start ps-0 my-auto">
                            <div className="login-logo">
                               <img src={Logo} alt=""  className='w-75'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="login-form" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <div className="row justify-content-center">
                    <div className="col-md-8">

                        <h1>Sign in</h1>
                        <form onSubmit={onSubmit}>
                            <div className="form-group mb-3">
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <input type="text" id="phoneNumber" className="form-control" {...register("phoneNumber", { required: "field cannot be empty" })}/>
                                <div className="err text-danger">{errors.phoneNumber && errors.phoneNumber.message}</div>
                            </div>
                            <div className="form-group password-container">
                                <input
                                        className="form-control"
                                        id="password-field"
                                        type={!showPassword ? "password" : "text"}
                                        placeholder="Password"
                                        {...register("password", { required: "field cannot be empty" })}
                                />
                                {!showPassword && <button className="btn p-0 field-icon" onClick={() => setShowPassword(true)}><i className="fas fa-eye"></i></button>}
                                {showPassword && <button className="btn p-0 field-icon" onClick={() => setShowPassword(false)}><i className="fas fa-eye-slash" aria-hidden="true"></i></button>}
                              <div className="err text-danger">{errors.password && errors.password.message}</div>
                           </div>

                            {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                            <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                                <div className="text-danger">
                                   {error}
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(null)}></button>
                            </div>}

                            <Link to="/forgot-password" className="forgot-password">Forgot Password ?</Link>

                            <div className="form-group mt-4">
                                {!submit && (<button type="submit" className="btn btn-login">Login</button>)}
                                {submit && (<button type="submit" className="btn btn-login" disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>)}
                            </div>
                            <div className="signup mt-3 dropdown">
                                Don’t have an account? <Link to="/register" className="text-primary dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">Sign up</Link>

                                <ul className="dropdown-menu border-0 shadow-sm">
                                  {/* <li><Link to="/register" className="dropdown-item">Sign up as a Seller</Link></li> */}
                                  <li><Link to="/register" className="dropdown-item">Sign up as a buyer</Link></li>
                                  <li><Link to="/enterprise-setup" className="dropdown-item">Sign up as a Corporate</Link></li>
                                  <li><Link to="/estate-subscribers-setup" className="dropdown-item">Sign up as an Investor</Link></li>
                                  <li><Link to="/estate-managers-setup" className="dropdown-item">Sign up as an Estate Developer</Link></li>
                                </ul>
                            </div> 
                         </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</React.Fragment>
  )
}

export default Login

import React, { useState } from 'react'
import "./Otp.scss"
import whiteLogo from "../../../../Assets/Images/logo-white.png"
import Logo from "../../../../Assets/Images/cinderBuild-logo.png"
import cinderBg from "../../../../Assets/Images/cinderBg.png"
import { Link, useNavigate } from 'react-router-dom'
import { decodeToken } from "react-jwt";
import authService from '../../../../Services/authService';
import { ErrorHandler } from '../../../../Helpers/ErrorHandler';
import Cookie from 'cookie-universal'
import OtpInput from 'react-otp-input';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider'
 
const Otp = () => {
  const [submit, setSubmit] = useState(false)
  const [otp, setOtp] = useState("")
  const [error, setError] = useState<any>(null)
  const { login } = useAuth()
 
  const navigate = useNavigate()

  const cookies = Cookie()
  const referrer = cookies.get("referer")
  const history = useNavigate()

  const { handleSubmit } = useForm({ mode: 'onBlur' });

  const onSubmit = handleSubmit((data:any) => processOtp(data));

  const processOtp = async (data:any) => {
    setSubmit(true) 

    const a:any = localStorage.getItem("temp__reg");
    const userPrev = JSON.parse(a); 
    const Fdata = {
      verificationCode: String(otp),
      phoneNumber: String(userPrev.phoneNumber)
    } 

    await authService.otpReg(Fdata)
      .then(
        (res:any) => {
          setSubmit(false)

          if (Boolean(res.data.status) === false) {
            setError(res.data.message)

          } else { 

            const myDecodedToken:any = decodeToken(res.data.data.token);
          
             login({ ...res.data.data, ...myDecodedToken, ...res.data, switched: 0 })

            // unset temp data
            localStorage.removeItem("temp__reg");
            if (myDecodedToken.isSeller) { 

              navigate("/phone-verify-success", { replace: true });
              
              return true;
            } else {
              if (referrer) {
                cookies.remove("referer")
                window.location.href = referrer
                return history(referrer, { replace: true })
              } else {
                navigate("/", { replace: true });
                return true
              }
            }
          }
        },
        (error:any) => {
          ErrorHandler(error,
            (e) => { 
              setError(e)
             })
          setSubmit(false)
         });
  }

  const handleChangeOtp = (otp:any) => {
    setOtp(otp);
  }
  return (
    <div className="otp">
        <div className="row h-100">
            <div className="col-md-6 d-none d-md-block">
                <div className="login-right">
                    <div className="container">
                        <div className="login-header">
                            <div className="row">
                                <div className="col-md-2 text-end">
                                    <div className="login-back">
                                    <a href=""><span className="d-icon d-arrowhead-left shadow"></span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="login-logo">
                                        <img src={whiteLogo} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="login-text">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <h2>Welcome</h2>
                                    <div className="desc">
                                    Let’s get you all set up, create an account and start using CinderBuild
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={cinderBg} alt=""/>
                </div>
            </div>
            <div className="col-md-6 my-auto">
                <div className="login-header d-md-none">
                    <div className="row h-100">
                        <div className="col-2">
                            <div className="login-back">
                            <a href="" onClick={() => setError(null)}><span className="d-icon d-arrowhead-left"></span></a>
                            </div>
                        </div>
                        <div className="col-8 text-start ps-0 my-auto">
                            <div className="login-logo">
                                <img src={Logo} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="login-form" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <h1 className='text-center'>Verify OTP</h1>
                        <small className='text-center'>A one time password has been sent to your registered phone number.</small>
                        <form onSubmit={onSubmit}>
                            <div className="form-group mt-4 mb-3 container-token text-center">
                            <OtpInput
                                value={otp}
                                onChange={handleChangeOtp}
                                numInputs={4}
                                separator={<span>   </span>}
                                inputStyle={{
                                  width: "3.1rem",
                                  height: "2.5rem",
                                  minHeight: "2.5rem",
                                  margin: "0 .5rem",
                                  fontSize: "1rem",
                                  borderRadius: 4,
                                  backgroundColor: "#ECF1F4",
                                  boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
                                  border: "1px solid rgba(0,0,0,0.1)"
                                }}
                              />

                            </div>

                            {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                            <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                                <div className="text-danger">
                                   {error}
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}

                            <div className="mt-4 text-center">
                            <Link to="/forgot-password" className="resendOtp">Didn’t recieve code? Send again..</Link>
                          </div>

                          <div className="form-group mt-4">
                                {!submit && (<button type="submit" className="btn btn-login">Verify</button>)}
                                {submit && (<button type="submit" className="btn btn-login" disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>)}
                            </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Otp

import React, { FC, useEffect, useState } from 'react'
import "./EnterprisePaymentOption.scss" 
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../../../../../../../Config/DBConfig'
import toast, { Toaster } from 'react-hot-toast'  
import { useAuth } from '../../../../../../../Components/AuthProvider/AuthProvider' 
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService';

interface propsTypes {
    totalCost: number;
    module?:string;
}
 

const EnterprisePaymentOption:FC<propsTypes> = ({ totalCost, module }) => {
  const orderDetails:any[] = useLiveQuery<any>(async () => await db.orderDetails.toArray())
   
  const [paymentVar, setPaymentVar] = useState<string|undefined>()
  
   

  const { user } = useAuth()
 
  const [walletBalance , setWalletBalance] = useState ({
    currency: "",
    currencySymbol: "",
    amountMajor: ""
  })

  useEffect(()=>{
    getMyBalance()
  },[])

  const getMyBalance = async () => {
    // setLoadingRequest(true)
    await bankingFinanceService.getMyBalance()
      .then((res:any) => {
        // setLoadingRequest(false)
        setWalletBalance(res.data.data)
        // Logger("My balance", res.data.data)
      }, (error:any) => {
        // setLoadingRequest(false)
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  const updatePaymentVariant = async (e:any) => {
    setPaymentVar(e.target.value)
    await db.orderDetails.update(orderDetails[0]?.id, { orderPaymentVariant: e.target.value }).then(function (updated) {
      if (updated) {
        toast.success(`Payment Variant Updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      } else {
        toast.error(`Nothing was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      }
    });
  }


  const isWalletFundSufficient = ()=> {
      if (totalCost <= Number(walletBalance.amountMajor)) {
        return true 
      }
      return false
  }

  return (
        <div className='enterprisePaymentOption'>
          
           <ul className="list-group mt-2 list-group-flush ps-1">
                 <li className="list-group-item ps-0 border-0">
                    <input name="paymentVariant" className="form-check-input me-1" type="radio" value="WALLET" id="firstCheckboxStretched1" onChange={updatePaymentVariant} disabled={!user}/>
                    <label className="form-check-label stretched-link ps-2" htmlFor="firstCheckboxStretched1">Wallet</label>
                </li>
                <li className="list-group-item ps-0 border-0">
                    <input name="paymentVariant" className="form-check-input me-1" type="radio" value="CARD" id="secondCheckboxStretched2" onClick={updatePaymentVariant}/>
                    <label className="form-check-label stretched-link ps-2" htmlFor="secondCheckboxStretched2">Card</label>
                </li>
                <li className="list-group-item ps-0 border-0">
                    <input name="paymentVariant" className="form-check-input me-1" type="radio" value="PAY_ON_DELIVERY" id="thirdCheckboxStretched3" onClick={updatePaymentVariant} disabled={!user}/>
                    <label className="form-check-label stretched-link ps-2" htmlFor="thirdCheckboxStretched3">Payment on delivery</label>
                </li>
            </ul>

           {paymentVar && paymentVar === "WALLET" && <div className="wallet border-bottom pb-4 ">
                <div className="card">
                    <div className="card-body">
                        <div className="title">
                        Your balance
                        </div>

                        <div className="price">
                         {`${walletBalance.currencySymbol} ${walletBalance.amountMajor}`}
                        </div>
 
                        {
                          !isWalletFundSufficient() &&
                          <>
                            <div className="alert alert-danger mt-4">
                            Your balance is low. fund wallet to continue shopping.
                            </div>
                            <button className="btn">Fund wallet</button>
                          </>
                        }
                    </div>
                </div>
            </div>}  
 
            <Toaster/>
        </div>
  )
}

export default EnterprisePaymentOption

import React from 'react'

import "./GetStarted.scss"
import MaskGroups from '../../../../../Assets/Images/MaskGroups.png';

const GetStarted = () => {
  return (
    <div className="updates my-5 d-none d-lg-block">
        <div className="container">
            <div className="updates-inner">
                <div className="container-fluid">
                <div className="row h-100">
                    <div className="col-md-7 p-0 my-auto ps-5">
                        <h2>Join Us! Start using <br/>CinderBuild today </h2>
                        <a href="request-access">
                          <button className="btn btn-primary mt-4">Get Started</button>
                        </a>
                    </div>
                    <div className="col-md-5 p-0">
                        <img src={MaskGroups} className="w-100"/>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default GetStarted

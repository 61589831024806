import React, { useEffect } from 'react'
import HeaderMeta from '../../../../Components/HeaderMeta/HeaderMeta';
import "./TermsAndCondition.scss"
import { FooterLarge, Header } from '../../../../Layout';

const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <React.Fragment>
      <Header></Header>
        <div className='termsAndCondition my-5'>
        <HeaderMeta title="Terms and Conditions"></HeaderMeta>
        <div className='container'>
            <div className="row justify-content-center">
                <div className="col-md-9 bg-white p-4">

                <h1 className='fw-bold mb-4'>TERMS &amp; CONDITIONS  </h1>
                    <p>These Terms and Conditions&nbsp;(&quot;Terms&quot;, &quot;Agreement&quot;)&nbsp;are an agreement between CinderBuild Inc (&quot;CinderBuild, the&nbsp;Company&quot;, &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and you (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;). These terms affect your legal rights, endeavor to read them and treat with utmost importance.</p>
                    <p><br/></p>

                    <p>We are a company duly registered in Delaware with our address at 16192 Coastal Highway, Lewes, Delaware, USA. This Agreement including our Privacy Policy sets forth the general terms and conditions of your use of the CinderBuild website, portal, application or a product of the Company, with such name communicated by CinderBuild from time to time, including all features and functionality, the site, content, application, user interface and software associated with our service and any of our products or services (collectively, &quot;Mobile Application&quot; or &quot;Services&quot;).</p>
                    <p><br/></p>
                    <p>By using the Services, you are entering into a binding contract with the Company. Your agreement with us includes these Terms and our Privacy Policy (the &ldquo;Agreements&rdquo;).</p>
                    <p><br/></p>
                    <p>Acceptance of Terms of Use</p>
                    <p>By accessing our Services, visiting or using the Mobile Application, via this site or any other device or application or other technology, you accept and agree to be bound by the Terms. Further, you shall be subject to any additional terms, posted guidelines or rules of use that apply when you use certain products or Services, which may be modified from time to time. All such guidelines are hereby incorporated by reference into the Terms.</p>
                    <p><br/></p>
                    <p>ANY ACCESS, USE OR PARTICIPATION IN THE SERVICES WILL CONSTITUTE ACCEPTANCE OF THE TERMS. IF YOU DO NOT AGREE TO THESE TERMS OF USE, PLEASE DO NOT USE THE SERVICES OR OUR SITE OR MOBILE APPLICATION OR ACCESS OUR SERVICES.</p>
                    <p><br/></p>
                    <p>Accounts</p>
                    <p>You must operate an account with the Company to be able to use the Services or Mobile Application. The Mobile Application can be used on a mobile device running an operating system supported by us. To log onto the Mobile Application, you will need to enter your User ID and other security information that we request, or when available, by using biometric authentication. We may notify you from time to time about changes in the security information. If you create an account in the Mobile Application, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.</p>
                    <p><br/></p>
                    <p>Third Party Service Providers</p>
                    <p>We may employ third party companies and individuals to facilitate our Services (&quot;Service Providers&quot;), to provide the Services on our behalf, to perform certain services or to assist us in analyzing how our Services are used.</p>
                    <p><br/></p>
                    <p>These third parties have access to your Personal Data (as defined in the Privacy Policy) only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                    <p><br/></p>
                    <p>Qualification for Use</p>
                    <p>The User must have the contractual capacity to enter a binding contract with us and must not be barred from doing so under any applicable laws. You also warrant that any registration information that you submit to the Company is true, accurate and complete, and you always agree to keep it that way and the Company will not be held liable for any loss or damage that may arise in this respect.</p>
                    <p><br/></p>
                    <p>Consent</p>
                    <p>Using the Mobile Application, you consent to receiving electronic communication from the Company relating to your account. We may communicate with you by electronic mail (e-mail), short message service (sms) or by posting notices on the Mobile Application or through other methods. For contractual purposes, you consent to receive communications from us electronically and you agree that all agreements, notices, disclosures and other communications that we provide you electronically satisfy any legal requirement that such communication be in writing. You also consent to receiving certain other communication from us, such as newsletters, special offers, questionnaires, customer surveys or other announcements via email, sms or other methods. You may opt out of receiving non-transactional communications, including marketing communications from us by following the directions in our e-mail to &ldquo;unsubscribe&rdquo; from our mailing list, or by sending an e-mail request to&nbsp;<a href="mailto:cb_support@cinderbuild.com">cb_support@cinderbuild.com</a>. Please be aware that if you choose not to receive such communication, certain offers attached to services you have chosen may be affected. We will still communicate with you in connection with transactional communications, including but not limited to servicing your account and customer services.</p>
                    <p><br/></p>
                    <p>Suspension of Mobile Application</p>
                    <p>The Company can, at any time, suspend your use of the Mobile Application in any of the following situations;</p>
                    <ol>
                        <li>
                            <p>Where concerns exist about the security of the Mobile Application;</p>
                        </li>
                        <li>
                            <p>Where it is suspected that your account on the Mobile Application has been used fraudulently or in an unauthorized way;</p>
                        </li>
                        <li>
                            <p>Where it is suspected that your account on the Mobile Application triggers certain AML/CFT risks;</p>
                        </li>
                        <li>
                            <p>Where there is a significantly increased risk that you will be unable to repay any overdraft or fulfill an obligation on any of your accounts;</p>
                        </li>
                        <li>
                            <p>Where there are legal or regulatory obligations we have to meet.</p>
                        </li>
                    </ol>
                    <p><br/></p>
                    <p>We may give you advance notice of any suspension and tell you why. However, we will not do so if this would compromise our reasonable security measures or if it is unlawful to do this. Occasionally, we may not be able to contact you to give you advance notice. If you have entered incorrect log on details on the Mobile Application on several occasions, we will suspend your access to the Mobile Application. If this happens, you can reset your log on details within the Mobile Application by identifying yourself and answering our security questions. Otherwise, you will need to call us on our customer care lines or email us at&nbsp;<a href="mailto:cb_support@cinderbuild.com">cb_support@cinderbuild.com</a></p>
                    <p><br/></p>
                    <p>Links to Other Applications</p>
                    <p>Although our Services may be linked to other service providers and applications, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked mobile application, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of any businesses or individuals or the content of their mobile applications. We do not assume any responsibility or liability for the actions, products, services, and content of any other third-parties. You should carefully review the legal statements and other conditions of use of any mobile application which you access through a link from this Mobile Application. Your linking to any other off-site mobile applications is at your own risk.</p>
                    <p><br/></p>
                    <p>Your Submissions &amp; Information</p>
                    <p>You grant us the right to use the materials or information that you submit to the Site and/or provide to us, including but not limited to, questions, reviews, comments, and suggestions (collectively, &lsquo;Submissions&rsquo;). When you post comments or reviews on the Site, you also grant us the right to use the name that you submit or your username in connection with such review, comment, or other content. You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of any Submissions. We may, but shall not be obligated to, publish, remove or edit your Submissions.</p>
                    <p><br/></p>
                    <p>You consent to and authorize the use by us of any information provided by you (including Personal Data) for the purposes of sending informational and promotional e-mails to you where you agree. Your agreement shall constitute your consent for the purpose of the provisions of any spam control laws (whether in Delaware or elsewhere). You may subsequently opt out of receiving promotional e-mails by clicking on the appropriate hyperlink in any promotional e-mail.</p>
                    <p><br/></p>
                    <p>You acknowledge that you have read and agree to the Privacy Policy on the Website and consent to our collection, use and disclosure of your Personal Data for the purposes as set out in the Privacy Policy.</p>
                    <p><br/></p>
                    <p>Prohibited Uses</p>
                    <p>In addition to other terms as set forth in the Agreement, you are prohibited from using the Mobile Application or its content:</p>
                    <ol>
                        <li>
                            <p>For any unlawful purpose;</p>
                        </li>
                        <li>
                            <p>To solicit others to perform or participate in any unlawful acts;</p>
                        </li>
                        <li>
                            <p>To violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;</p>
                        </li>
                        <li>
                            <p>To infringe upon or violate our intellectual property rights or the intellectual property rights of others;</p>
                        </li>
                        <li>
                            <p>To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age,national origin, or disability;</p>
                        </li>
                        <li>
                            <p>To submit false or misleading information;</p>
                        </li>
                        <li>
                            <p>To upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related mobile application, other mobile applications, or the Internet;</p>
                        </li>
                        <li>
                            <p>To collect or track the personal information of others;</p>
                        </li>
                        <li>
                            <p>To spam, phish, pharm, pretext, spider, crawl, or scrape;</p>
                        </li>
                        <li>
                            <p>For any obscene or immoral purpose; or</p>
                        </li>
                        <li>
                            <p>To interfere with or circumvent the security features of the Service or any related mobile application, other mobile applications, or the Internet. We reserve the right to terminate your use of the Service or any related mobile application for violating any of the prohibited uses.</p>
                        </li>
                    </ol>
                    <p><br/></p>
                    <p>Intellectual Property Rights</p>
                    <p>This Agreement does not transfer to you any intellectual property owned by the Company or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with the Company. All trademarks, service marks, graphics and logos used in connection with our Mobile Application or Services, are trademarks or registered trademarks of the Company. Other trademarks, service marks, graphics and logos used in connection with our Mobile Application or Services may be the trademarks of other third parties. Your use of our Mobile Application and Services grants you no right or license to reproduce or otherwise use the Company or third-party trademarks.</p>
                    <p><br/></p>
                    <p>Copyright Infringement</p>
                    <p>All rights reserved. No part of the Mobile Application may be Copied, reproduced, ripped, recorded, re-engineered, decompiled, disassembled, modified, reproduced, distributed, or transmitted in any form or by any means either electronic or mechanical methods, without the prior written permission of the Company.</p>
                    <p><br/></p>
                    <p>Technology Limitations &amp; Modifications</p>
                    <p>The Company will make reasonable efforts to keep the Mobile Application operational. However, certain technical difficulties or maintenance may, from time to time, result in temporary interruptions. The Company reserves the right, periodically and at any time, to modify or discontinue, temporarily or permanently, functions and features of the Mobile Application, with or without notice, all without liability to you for any interruption, modification, or discontinuation of the Mobile Application Service or any function or feature thereof. You understand and agree that we have no obligation to maintain, support, upgrade, or update the Services, or to provide all or any specific content through the Service.</p>
                    <p><br/></p>
                    <p>Limitation of Liability</p>
                    <p>You agree that, to the extent permitted by applicable law, your sole and exclusive remedy for any problems or dissatisfaction with the Services, the third-party applications or the third-party application content is to uninstall any CinderBuild software and to stop using the Services, the third-party applications or the third-party application content.</p>
                    <p><br/></p>
                    <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE COMPANY, ITS OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES, AFFILIATES, SUCCESSORS, ASSIGNS, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL (INCLUDING LOSS OF USE, DATA, BUSINESS, OR PROFITS) DAMAGES, ARISING OUT OF THE USE OR INABILITY TO USE THE SERVICES, REGARDLESS OF ANY LEGAL THEORY, WITHOUT REGARD TO WHETHER THE COMPANY HAS BEEN WARNED OF THE POSSIBILITY OF THOSE DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE, THE AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES, SHALL NOT BE MORE THAN THE AMOUNTS PAID BY YOU TO THE COMPANY DURING THE PRIOR THREE MONTHS IN QUESTION.</p>
                    <p><br/></p>
                    <p>Nothing in these Agreements removes or limits the Company&rsquo;s liability for fraud, fraudulent misrepresentation, death or personal injury caused by its negligence.</p>
                    <p><br/></p>
                    <p>Disclaimer Warranty</p>
                    <p>Our Mobile Application is provided on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis without warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement. No warranty is provided that the Mobile Application will be free from defects or viruses or that operation of the Mobile Application will be uninterrupted. Your use of the Mobile Application and any other material or services downloaded or made available to you through the software is at your own discretion and risk, and you are solely responsible for any damage resulting from their use.</p>
                    <p><br/></p>
                    <p>Restrictions</p>
                    <p>You shall not:</p>
                    <ol>
                        <li>
                            <p>Modify, revise or create any derivative works of the Mobile Application;</p>
                        </li>
                        <li>
                            <p>Decompile, reverse engineer or otherwise attempt to derive the source code for the Mobile Application;</p>
                        </li>
                        <li>
                            <p>Redistribute, sell, rent, lease, sublicense, or otherwise transfer rights to the Mobile Application; or</p>
                        </li>
                        <li>
                            <p>Remove or alter any proprietary notices, legends, symbols or labels in the Mobile Application, including, but not limited to, any trademark, logo or copyright.</p>
                        </li>
                    </ol>
                    <p><br/></p>
                    <p>Indemnification</p>
                    <p>You agree to indemnify and hold the Company and its affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys&apos; fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Mobile Application or Services or any willful misconduct on your part.</p>
                    <p><br/></p>
                    <p>No Waiver</p>
                    <p>Our failure to enforce these Terms shall not constitute a waiver of these terms, and such failure shall not affect the right later to enforce these Terms. We would still be entitled to exercise our rights in any other situation where you breach these Terms.</p>
                    <p><br/></p>
                    <p>Rights of Third Parties</p>
                    <p>A person or entity who is not a party to these Terms shall have no right to enforce any provision of these Terms, regardless of whether such person or entity has been identified by name, as a member of a class or as answering a particular description. For the avoidance of doubt, nothing in this Paragraph shall affect the rights of any permitted assignee or transferee of these Terms.</p>
                    <p><br/></p>
                    <p>Severability</p>
                    <p>All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.</p>
                    <p><br/></p>
                    <p>Dispute Resolution</p>
                    <p>The validity, construction and performance of this Agreement shall be governed by the laws of the State of Delaware. Parties shall use their best endeavors to amicably settle any dispute or difference of opinion arising from or in connection with this Agreement through mutual discussions in line with our Dispute Resolution and Complaint Management Control Framework.</p>
                    <p>All disputes should be logged formally by sending an email to&nbsp;<a href="mailto:cb_support@cinderbuild.com">cb_support@cinderbuild.com</a> within 90 days of the transaction date. Where the Parties are unable to resolve the dispute through mutual discussions, the dispute or difference of opinion shall be referred to mediation conducted by their legal representatives or financial auditors, where financial matters are involved. Failing which the dispute shall finally be referred to arbitration in accordance with the Laws of the State of Delaware, or any amendment thereto.</p>
                    <p>The place of arbitration shall be where the Complainant is resident and there shall be a sole arbitrator who shall be appointed jointly by the parties. The language to be used in the arbitral proceedings shall be English. The arbitral award shall be final and binding between the Parties. The reference of any dispute to arbitration shall not prevent the Parties from seeking any form of protective reliefs/injunctions/specific performance from a court of law via motion or other originating processes for the protection of any res pending the constitution of the Arbitral Panel and/or final award.</p>
                    <p><br/></p>
                    <p>Changes &amp; Amendments</p>
                    <p>We reserve the right to modify this Agreement or its policies relating to the Mobile Application or Services at any time, effective upon posting of an updated version of this Agreement in the Mobile Application. When we do, we will send you an email to notify you. Continued use of the Mobile Application after any such changes shall constitute your consent to such changes.</p>
                    <p><br/></p>
                    <p>Acceptance of these terms</p>
                    <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Mobile Application or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Mobile Application and its Services.</p>
                    <p><br/></p>
                    <p>Contact Us</p>
                    <p>If you have any questions about this Agreement, please contact&nbsp;<a href="mailto:cb_support@cinderbuild.com">cb_support@cinderbuild.com</a>. This T&amp;C was last updated on 22nd July 2022.</p>
                    <p><br/></p>
                    <p><br/></p>
                </div>
            </div>
        </div> 
        </div>
    <FooterLarge></FooterLarge>
    </React.Fragment>
  )
}

export default TermsAndCondition

import React, { useEffect, useState } from 'react'
import "./HeaderSearchBox.scss"
import searchNormal from "../../Assets/Images/icon/search-normal.png"
import searchsetting4 from "../../Assets/Images/icon/setting-4.png"
// import toast from 'react-hot-toast'
import searchService from '../../Services/searchService'
import { Link, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import { useAllProduct } from '../../Hooks'
import { ProductFilters } from '../../Pages/Frontend/Components'

const HeaderSearchBox = () => {
  const [toogleSearchOverlay, setTogleSearchOverlay] = useState(false)

  const [searchResult, setSearchResult] = useState([])
  const [searchPhrase, setSearchPhrase] = useState<string>()
  const [searchLoading, setSearchLoading] = useState(false)
  const [sortOrder,] = useState("ASC")
  const [pageNumber,] = useState(1)
  const naviate = useNavigate()
  
  const searchText = async (e: { target: { value: string; }; }) => {
    setSearchLoading(true)
    setSearchPhrase(e.target.value)
    const data = { searchWord: e.target.value }

    await searchService.index(data, pageNumber, sortOrder).then((res:any) => {
      setSearchResult(res.data.data)
      setSearchLoading(false)
      setTogleSearchOverlay(true) 
    }, (error) => {
      setSearchLoading(false) 

      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [brandUuids, setBrandUuids] = useState<any[]>([])
  const [categoryUuids, setCategoryUuids] = useState<any[]>([])
  // eslint-disable-next-line no-unused-vars
  const [locationStates, setLocationStates] = useState<any[]>([])

  const { isFetching, refetch: getProduct } = useAllProduct(pageNumber, sortOrder, brandUuids, categoryUuids, locationStates)

  useEffect(() => {
    if (isFetching) {
      // toast.loading("Processing...", { position: "bottom-right" })
    } else {
      toast.dismiss()
      handleClose()
      // navigate("/products", { replace: true })
      // toast.success("Done", { duration: 7000, className: 'custom-alert bg-white text-dark', position: "bottom-right" });
    }
  }, [isFetching])

  return (
    <div className="search">
        <div className={toogleSearchOverlay === true ? "search-overlay" : ""} onClick={() => setTogleSearchOverlay(!toogleSearchOverlay)}></div>
          <div className={toogleSearchOverlay === true ? "search-result shadow search-result-frame" : ""}>
            <ul className="list-group">
            {searchResult.length > 0 && toogleSearchOverlay === true && !searchLoading && searchResult.map((res:any, index:React.Key) => {
              return (<li key={index} className="list-group-item border-0"><Link to={`/product/${res.productUuid}`} className="text-dark" onClick={() =>
                setTogleSearchOverlay(false)}>{res.productName}</Link></li>)
            })}
          </ul>
        </div>

        <div className='header-search-box'> 
            <input type="text" autoComplete="off" onClick={() => setTogleSearchOverlay(!toogleSearchOverlay)} onChange={searchText} name="searchText" className={toogleSearchOverlay === true
              ? "search-field shadow"
              : "search-field"} placeholder="What would you like to buy"
                onKeyPress={(e: any) => {
                  if (e.nativeEvent.charCode === 13) {
                    naviate(`/search-product/${searchPhrase}`, { replace: true });
                  }
                }}/>
             <div className="search-icon"><img src={searchNormal} className="search-nav-icon" alt=""/></div>
             {searchLoading === false
               ? !searchPhrase
                   ? <div className="filter-icon" onClick={() => {
                     setTogleSearchOverlay(false);
                     handleShow();
                     naviate(`/products`, { replace: true })
                   }}>
                    <img src={searchsetting4} className="search-nav-icon" alt=""/>
                  </div>
                   : <div className="filter-icon" onClick={() => {
                     naviate(`/search-product/${searchPhrase}`, { replace: true })
                     setTogleSearchOverlay(false)
                   }}>
                   <i className="d-icon d-send search-nav-icon"></i>
                 </div>
               : <div className="filter-icon"><span className='spinner-border spinner-border-sm text-secondary'></span></div>}
        </div>

        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog header-search-box-dialog">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h5 className="modal-title fw-bolder" id="exampleModalLabel">Filter Result</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form>

                  <div className="form-group">
                      <select className='form-control'>
                        <option>Category</option>
                      </select>
                    </div>
                    <div className="form-group mt-3">
                      <select className='form-control'>
                        <option>Brand</option>
                      </select>
                    </div>
                    <div className="form-group mt-3">
                      <select className='form-control'>
                        <option>Location</option>
                      </select>
                    </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <input type="text" className='form-control' placeholder='Min price'/>
                    </div>
                    <div className="col-6">
                      <input type="text" className='form-control' placeholder='Max price'/>
                    </div>
                    <div className="col-12 mt-4">
                      <button className='btn apply'>Apply filter</button>
                    </div>
                    <div className="col-12 mt-3">
                        <button className='btn apply-outline'>Clear</button>
                    </div>
                  </div>

                  </form>
                </div>
              </div>
            </div>
        </div>

        <ProductFilters show={show} handleClose={handleClose} categoryFilter={function (value: string): void {
          setCategoryUuids([value])
        } } brandFilter={function (value: string): void {
          setBrandUuids([value])
        } } action={function (): void {
          getProduct()
          naviate("/products", { replace: true })
        } }
        
        isFetching={isFetching}></ProductFilters>

        <Toaster></Toaster>
    </div>
  )
}

export default HeaderSearchBox

import { useState } from "react";
import "./AddToCart.scss";
import ShoppingCart from "../../../../../../../../Assets/Images/icon/shopping-cart.png";

const AddToCart = ()=>{
    const [quantity, setQuantity] = useState(0);
    return (
        <form className="saved-item-add-to-cart-form">
           <div className="mb-3">
                <label htmlFor="add-to-cart-quantity" className="form-label custom-label">Quantity:</label>
                <input type="number" className="form-control add-to-cart-quantity-input" id="add-to-cart-quantity" placeholder="Enter quantity"
                    name="quanitity"
                    onChange={(evt)=>setQuantity(Number(evt.target.value))}
                />
            </div>
            {/* eslint-disable-next-line */}
            <button className={quantity>0 ? "btn add-to-cart-btn w-100": "btn add-to-cart-btn-disable w-100"} disabled={quantity>0 ? false: true}>
                <img src={ShoppingCart} alt='' className=""/>
                Add to cart
            </button>
        </form>
    )
}

export default AddToCart;
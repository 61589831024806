import { FC, useEffect, useState } from "react";
import { HorizontalLoader } from "../../../../../../../Components"; 
import EnterpriseSubPageTitle from "../../../../../../../Components/Enterprise/EnterpriseSubPageTitle/EnterpriseSubPageTitle";
import { Logger } from "../../../../../../../Helpers/Logger";
import { userProfile } from "../../../../../../../Models";
import userService from "../../../../../../../Services/userService";  
import { ContactDetails, ProfileHeader } from "../../../Components";
import "./EnterpriseSettingsProfile.scss"
 
interface propsType {
  module?: string
}
const EnterpriseSettingsProfile:FC<propsType> = ({ module })=>{

    const initialState:userProfile = {
        userUuid: "",
        isOnProductLease: false,
        firstName: "",
        lastName: "",
        emailAddress: "",
        msisdn: "",
        sellerUniqueCode: "",
        photoUrl: "",
        role: "",
        accountRating: {
          totalRatingsValue: 0,
          totalNumberOfRatings: 0
        },
    
        businessProfile: {
          businessName: "",
          businessAddress: "",
          businessCACNumber: ""
        }
      }
      const [userProfileInfo, setUserProfile] = useState<userProfile>(initialState)
       
       const [loadingRequest, setLoadingRequest] = useState<boolean>(false) 
      // eslint-disable-next-line no-unused-vars
      const [photo, setPhoto] = useState<any>(null)
       
    const getProfile = async () => {
        setLoadingRequest(true)
        await userService.getProfile().then((res): void => {
          setLoadingRequest(false)
          setUserProfile(res.data.data)
          setPhoto(res.data.data.photoUrl)
        }, (error:any) => {
          setLoadingRequest(false)
          Logger(error.response)
        })
      }
      
      useEffect(() => {
        const abortController = new AbortController();
        getProfile()
        return () => abortController.abort();
      }, [])

    
    return  (
       <div className="enterpriseSettingsProfile">
            <EnterpriseSubPageTitle
                title="Profile"
                parents={[
                    {
                        title:"Settings",
                        url: "settings"
                    }
                ]} 
            />
            <div className="row justify-content-center">
            {loadingRequest && <HorizontalLoader></HorizontalLoader>}
            {!loadingRequest &&  <div className="col-12 col-md-9 col-lg-6 profile-item-container mb-4">
                    <div className="mb-4">
                        <ProfileHeader name={userProfileInfo.firstName + ' ' +userProfileInfo.lastName } picture='' module="estate"/>
                    </div>
                   
                    <div className="mb-2">
                        <ContactDetails emailAddress={userProfileInfo.emailAddress}
                            phoneNumber={userProfileInfo.msisdn}
                            module="estate"
                        />
                    </div>
                    
                </div>}
            </div>
       </div>
    )

}

export default EnterpriseSettingsProfile;
import React, { useState } from 'react'
import "./Brands.scss"
import searchNormal from "./../../../../Assets/Images/icon/search-normal.png"
import { Link } from 'react-router-dom'
import { useFetchBrands } from '../../../../Hooks/Queries/Brands/useFetchBrands' 
import ImageSrc from '../../../../Components/ImageSrc/ImageSrc';
import { BrandSkeleton } from '../../Components'

const Brands = () => {
  const [page,] = useState<number>(1)
  const [sortOrder,] = useState("DESC")
  const [pageSize,] = useState<number>(25)
  const { isSuccess, data, isLoading } = useFetchBrands(page, sortOrder, pageSize)
 
  return (
        <div className="brands pt-5 pb-5">
        <div className="container">
          <div className="row section-header">
            <div className="col-6 col-md-8 ps-4">
            <h2>All Brands</h2>
            </div>
            <div className="col-6 col-md-4 position-relative">
              <form action="">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Search brand"/>
                  <div className="search-icon"><img src={searchNormal} className="nav-icon" alt=""/></div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="container h-100 pt-4">
          <div className="row h-100">
            {isSuccess && data?.map((res:{imageUrl:string, name:string, uuid:string}, index:React.Key) => {
              return <div className="col-6 col-md-2" key={index}>
                <Link to={`/products-by-brand/${res.uuid}`}>
                    <div className="card border-0 text-decoration-none">
                        <div className="card-body">
                         <ImageSrc src={res.imageUrl} alt={res.name} title={res.name} width={'w-100'}></ImageSrc>
                        </div>
                        <div className="card-footer text-center bg-white">
                          <Link to={`/products-by-brand/${res.uuid}`} className="text-decoration-none">{res.name}</Link>
                        </div>
                    </div>
                </Link>
            </div>
            })}

            {isLoading && <BrandSkeleton cardCount={12}></BrandSkeleton>}

          </div>
        </div>
      </div>
  )
}

export default Brands

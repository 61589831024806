import React, { useEffect, useState } from 'react' 
import country from "../../../../../Data/country.json"
import state from "../../../../../Data/stateNG.json"
import userService from '../../../../../Services/userService'
import "./DeliveryAddress.scss"
import DeliveryAddressItems from './DeliveryAddressItems/DeliveryAddressItems'
import NewDeliveryItem from './NewDeliveryItem/NewDeliveryItem'
import { DeliveryAddressType } from '../../../../../Models/deliveryAddressType';
import { db } from '../../../../../Config/DBConfig'
import { useLiveQuery } from 'dexie-react-hooks'
import { useAuth } from '../../../../../Components/AuthProvider/AuthProvider'
import { AddNewDeliveryAddress } from '../..'
import { useForm } from 'react-hook-form'
import { ErrorHandler } from '../../../../../Helpers/ErrorHandler'
import { Modal } from 'react-bootstrap'; 
import toast from 'react-hot-toast'


type formProp = {
  contactFullName:string;
  contactPhoneNumber:string;
  country:string;
  state:string;
  address:string;
  uuid:any;
  isDefault:boolean|undefined;
 }

const DeliveryAddress = () => {
  const [fetchingRequest, setFetchingRequest] = useState(false);
  const [dataRes, setDataRes] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState<DeliveryAddressType | undefined>();
  const { user } = useAuth()


  const getAddress = async () => {
    setFetchingRequest(true)
    await userService.getBuyerDeliveryLocation()
      .then((res) => {
        setFetchingRequest(false)
        setDataRes(res.data.data)
      }, (error:any) => {
        setFetchingRequest(false)
        toast.error(error?.response?.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    if (user) { 
      getAddress()
    } 
  }, [])

  const orderDetails = useLiveQuery<any>(async () => await db.orderDetails.toArray())

  const selectAddress = async (addressObj:DeliveryAddressType) => {
    setSelectedAddress(addressObj)

    await db.orderDetails.update(orderDetails[0]?.id, { deliveryAddressUuid: addressObj }).then(function (updated) {
      if (updated) {
        toast.success(`Delivery address selected`, { duration: 1000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      } else {
        toast.error(`Nothing was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      }
    });
  }



  const [showEdit, setShowEdit] = useState(false);
  const [dataResEdit, setDataResEdit] = useState<formProp>(); 
  const handleCloseEdit = () => setShowEdit(false); 
  const [sendingRequestEdit, setSendingRequestEdit] = useState(false);   

  const [selectStates, setSelectedStates] = useState<any>([]) 
  const [selectedCountry, setCountry] = useState<any>({
    id: "",
    sortname: "",
    name: "",
    phoneCode: ""
  })

  const handleCountryChange = (e:any) => {
    const allState = state[2].data

    setSelectedStates(allState)

    const cont = JSON.parse(e.target.value)
    setCountry(cont)
  }


  const { register: register2, handleSubmit: handleSubmit2, setValue, formState: { errors: errors2 } } = useForm<formProp>({ defaultValues: { isDefault: false } });



  const deleteAddress = async (uuid: string) => { 
    const deleting = toast.loading("Deleting address...")
    
    await userService.deleteBuyerDeliveryLocation(uuid)
      .then(async (res) => { 

        if (user) { 
         await getAddress()
        } 

        toast.success("Deleted Successfully", { duration: 9000, className: 'bg-white text-dark' });
        toast.dismiss(deleting)
  
      }, (error:any) => {
        toast.dismiss(deleting) 

        ErrorHandler(error, (e)=>{ 
          toast.error(e, { duration: 9000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })

       })
  }
 
  useEffect(() => { 
    if (dataResEdit) { 
      setValue("contactFullName", dataResEdit?.contactFullName)
      setValue("contactPhoneNumber", dataResEdit?.contactPhoneNumber )
      setValue("country", dataResEdit?.country)
      setValue("state", dataResEdit?.state)
      setValue("address", dataResEdit?.address)
      setValue("isDefault", dataResEdit?.isDefault)
      
    }
  }, [dataResEdit])

   
  const submit2 = handleSubmit2(async (data:any) => { 
    setSendingRequestEdit(true)
    await userService.buyerDeliveryLocationEdit(dataResEdit?.uuid, { ...data, country: selectedCountry.name })
      .then(res => {
        setSendingRequestEdit(false)
        handleCloseEdit()
        toast.success("location updated", { duration: 9000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        getAddress()
      }, (error:any) => {
        setSendingRequestEdit(false) 
        console.log("error error", error.response)
        ErrorHandler(error, (e)=>{ 
          toast.error(e, { duration: 9000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
      })
  });

  return (
        <div className='deliveryAddress'>
             <div className="mt-4">
                <div className="row">

                      {user && dataRes.length > 0 && dataRes.map((res, index:React.Key) => <div key={index} className="col-12 col-sm-6 col-md-6  col-lg-4 mt-1 px-1">
                          <DeliveryAddressItems
                            addressDetails={res}
                            index={Number(index)}
                            selectAddress={selectAddress}
                            selectedAddress={selectedAddress}
                            deleteItem={(data:any)=> deleteAddress(data.uuid)}
                            setShowEdit={(val:boolean)=> setShowEdit(val)}
                            setDataResEdit={(data:any)=> setDataResEdit(data)}  
                            remoteAddress={true}
                          />
                      </div>)}

                      {user && dataRes.length > 0
                        ? <div className="col-12 col-sm-6 col-md-6  col-lg-4 mt-1 px-1">
                          {fetchingRequest && <span className='spinner-border spinner-border-sm'></span>}
                          <NewDeliveryItem/>
                      </div>
                        : <AddNewDeliveryAddress editSelectedAddress={0}></AddNewDeliveryAddress>}
                  </div>
              </div>

              {selectedAddress && <div className="form-input defaultAddress mt-4">
                  <label htmlFor='defaultAddress'>
                      <input type="checkbox" id="defaultAddress" className=''/> <span className='mt-0'>Save this as my default address</span>
                  </label>
              </div>}



            <Modal
                show={showEdit}
                onHide={handleCloseEdit}
                backdrop="static"
                keyboard={false}
                contentClassName="addNewAddressBookModal"
              >
                <form onSubmit={submit2} >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit location</Modal.Title>
                  </Modal.Header>
                  <Modal.Body
                className="rounded"> 
                  
                  <div className='form-group mt-3'>
                        <label htmlFor='address2'>Street address </label>
                        <textarea className='form-control fc textarea-pk' {...register2('address', { required: true })} rows={4} cols={50} id="address2" placeholder='e.g 45 Royal Palmswill Estate, Badore Ajah, Lagos, Nigeria.'></textarea>
                        {errors2.address && <div className='text-danger'>{errors2.address.message}</div>}
                      </div>

                      <div className="row">
                          <div className="col-md">  
                            <div className='form-group mt-2'>
                              <label htmlFor='name'>Country</label>
                                  <select className='form-control' {...register2("country", { required: "This field is required", onChange: (e:any) => handleCountryChange(e) })}>
                                      <option>--Country--</option>
                                      {country.countries.length > 0 && country.countries.map((res:any, index:React.Key) => {
                                        return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                                      })}
                                  </select>
                                  {errors2.country && <div className='text-danger'>{errors2.country.message}</div>}
                              </div>
                          </div>
                          <div className="col-md"> 
                            <div className='form-group mt-2'>
                                <label htmlFor='name'>State</label>
                                <select className='form-control' {...register2("state", { required: "This field is required" })}>
                                <option>--State--</option>
                                {selectStates.map((result: { id: number ; name: string }, index: React.Key) => {
                                  return <option key={index} value={result.name}>{result.name}</option>
                                })}
                                </select>
                                {errors2.state && <div className='text-danger'>{errors2.state.message}</div>}
                            </div>
                        </div>
                      </div>
 
                      <div className="contact-title">
                          Who to contact for delivery
                      </div>

                      <div className="row">
                        <div className="col-md"> 
                          <div className='form-group mt-2'>
                            <label htmlFor='contactFullName'>Contact name </label>
                            <input className='form-control fc' id="contactFullName" {...register2('contactFullName', { required: true })} placeholder='e.g John Doe'></input>
                            {errors2.contactFullName && <div className='text-danger'>{errors2.contactFullName.message}</div>}
                          </div> 
                        </div>
                        <div className="col-md">
                          <div className='form-group mt-2'>
                            <label htmlFor='name'>Contact phone number</label>
                            <input className='form-control fc' id="name" {...register2('contactPhoneNumber', { required: true })} placeholder=''></input>
                            {errors2.contactPhoneNumber && <div className='text-danger'>{errors2.contactPhoneNumber.message}</div>}
                          </div>
                        </div>
                      </div>
 
                      <div className='form-group mt-3 defaultaddress-row'>
                        <input type="checkbox" id="defaultAddress" {...register2('isDefault')} className='checkbox-pk' />  
                        <label htmlFor='defaultAddress' className="d-flex align-items-center pt-2">Make Default Address </label>
                        {errors2.isDefault && <div className='text-danger'>{errors2.isDefault.message}</div>}
                      </div> 
                  </Modal.Body>
                  <Modal.Footer> 
                      {!sendingRequestEdit && (<button type="submit" className="btn btn-primary w-100 border-0">Update Address</button>)}
                      {sendingRequestEdit && (<button type="submit" className="btn btn-primary border-0" disabled>
                          Please wait
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>)}

                  </Modal.Footer>
                </form>
            </Modal>
        </div>
  )
}

export default DeliveryAddress

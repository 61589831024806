import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const orderService = {
  async getAllMyOrder (pageNumber, sortOrder, persona, status, paymentVariant = 'null', paymentStatus = 'BUYER_PAYMENT_PENDING') {
    return axios.get(`/orders?pageNumber=${pageNumber}&sortOrder=${sortOrder}&persona=${persona}&status=${status}&paymentVariant=${paymentVariant}&paymentStatus=${paymentStatus}`);
  },
  async getSingleOrder (orderUid) {
    return axios.get(`/orders/${orderUid}`);
  },
  async buyerConfirmOrder  (orderUid, status) {
    return axios.put(`/orders/${orderUid}/statusupdate/${status}`);
  },
  async buyerConfirmReview (orderUid, data) {
    return axios.post(`/orders/${orderUid}/review`, data);
  },
  async buyerOrderDispute (orderUid, data) {
    return axios.post(`/orders/${orderUid}/dispute`, data);
  },
  async cancelOrder (orderUid) {
    return axios.get(`/orders/${orderUid}/cancel`);
  },
  async orderReceipt (reference) {
    return await axios.get(`/payments/orders/${reference}`);
  },

  async getAllMySellerOrder (pageNumber, sortOrder, persona, status) {
    return axios.get(`/orders?pageNumber=${pageNumber}&sortOrder=${sortOrder}&persona=${persona}&status=${status}`);
  },
  async getAllMyDeliveryOrder ( pageNumber, sortOrder) {
    return axios.get(`/warehouse/delivery-to-site?pageNumber=${pageNumber}&sortOrder=${sortOrder}`);
  } ,
 async lastOrder () {
    return axios.get(`/orders/lastorderitems`); 
  }
  
}
export default orderService

import React, { FC, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import bankingFinanceService from "../../../../../../Services/bankingFinanceService";
import { Wallet, History, BankDetails } from "../../Component";
import "./BankingFinance.scss"

interface propType { }

const BankingFinance: FC<propType> = () => {

  const [, setLoadingRequest] = useState<boolean>(false);

  const [myBankAccount, setMyBankAccount] = useState({
    bankCode: null,
    bankName: null,
    bankAccountNumber: null,
    bankAccountName: null,
  });

  const [myBalance, setMyBalance] = useState({
    currency: "",
    currencySymbol: "",
    amountMajor: ""
  })

  const getMyBankDetails = async () => {
    setLoadingRequest(true);
    await bankingFinanceService.getMyBankAccount().then(
      (res: any) => {
        setLoadingRequest(false);
        console.log("My bank details", res);
        setMyBankAccount(res.data.data);
      },
      (error: any) => {
        setLoadingRequest(false);
        toast.error(error.message, {
          duration: 20000,
          className: "bg-danger text-white",
        });
      }
    );
  };

  const getMyBalance = async () => {
    setLoadingRequest(true)
    await bankingFinanceService.getMyBalance()
      .then((res: any) => {
        setLoadingRequest(false)
        setMyBalance(res.data.data)
        // Logger("My balance", res.data.data)
      }, (error: any) => {
        setLoadingRequest(false)
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    getMyBankDetails();
    getMyBalance();
  }, []);

  return (
    <div className="seller-banking-finance mt-4">
      <p className="heading ms-3">Banking & Finance</p>
      <div className="container">
        <div className="row px-3">
          <div className="col-12 col-md-7">
            <BankDetails myBankAccount={myBankAccount}></BankDetails>
          </div>
          <div className="col-12 col-md-5">
            <Wallet myBalance={myBalance}></Wallet>
          </div>
          <div className="col-lg-12 mt-2 ">
            <History></History>
          </div>
        </div>
        <Toaster></Toaster>
      </div>
    </div>
  );
};

export default BankingFinance;

import React, { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { TOGGLE_CART_DRAWER } from '../../../../../Constants/CartConstants';
import "./CartDrawer.scss"
import { Link } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../../../Config/DBConfig';
import { ImageSrc } from '../../../../../Components';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';
import { CurrencyFormatter } from '../../../../../Helpers';

const CartDraweEnterprise = () => {
  const dispatch = useDispatch()
  const handleClose = (placement:OffcanvasPlacement|undefined) => dispatch({ type: TOGGLE_CART_DRAWER, payload: { active: false, placement } })

  const [totalCost, setTotalCost] = useState<number>(0);

  const cartDrawer = useSelector((state: {cartDrawer:{active:boolean, placement: OffcanvasPlacement | undefined}}) => state.cartDrawer)

  const cartData = useLiveQuery(
    async () => await db.cart.toArray()
  );

  useLiveQuery(
    async () => {
      const cartItems = await db.cart.toArray()
      let newCost = 0
      cartItems.length > 0 && cartItems.map((res:any) => {
        newCost += res.productDetails.unitPriceForBuyer * res.quantity;
        setTotalCost(newCost);
        return 1
      })
    }) 

  const orderDetailList = useLiveQuery<any>(async () => await db.orderDetails?.toArray());

  return (
      <Offcanvas show={cartDrawer.active} onHide={() => handleClose(cartDrawer.placement)} placement={cartDrawer.placement} className={cartDrawer.placement === "bottom" ? 'cartDrawer h-auto' : "cartDrawer"} responsive={cartDrawer.placement === "bottom" ? "lg" : ""}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="border-bottom pb-4">Product has been added to your shopping cart</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="container-fluid">
              <div className="items-in-cart title">
              Items in your cart
              </div>
          </div>

          <div className="container-fluid mt-3">

          {cartData?.length
            ? cartData.map((item, index:React.Key) => {
              return <div className="row" key={index}>
            <div className="col-4">
                <div className="cart-item-list-image">
                     <ImageSrc src={item?.productDetails?.images[0]?.url} alt={item?.productDetails?.productName} title={item?.productDetails?.productName} width="w-100 h-100 cart-drawal-image"></ImageSrc>
                </div>
            </div>
            <div className="col-8">
                <div className="cart-item-name pt-2">
                    {item?.productDetails?.productName}
                </div>

                <div className="cart-item-price">
                    {item.productDetails.currencySymbol} {item.productDetails.unitPriceForBuyer}
                </div>

                <div className="cart-item-quantiy">
                    Quantity:  {item.quantity}
                </div>
                {/* <div className="cart-item-variation">
                    Color: Red
                </div> */}

            </div>
        </div>
            }

            )
            : <div className='text-danger'>Cart is empty</div>}
          </div>

          <div className="container-fluid border-top mt-3 pt-3">

            <div className="row mb-3">
              <div className="col-6 text-start total-name">Subtotal</div>
              <div className="col-6 text-end total-value">₦{CurrencyFormatter(totalCost) }</div>
            </div>

            <Link to="/enterprise/cart" onClick={() => handleClose(cartDrawer.placement)}>
              <button className='btn btn-warning view-cart-btn view-cart-btn-ent'>View cart</button>
            </Link>

            { orderDetailList && orderDetailList[0]?.wareHouseUuid && <Link to="/enterprise/checkout" onClick={() => handleClose(cartDrawer.placement)}>
              <button className="btn btn-outline-warning checkout-btn checkout-btn-ent" onClick={() => handleClose(cartDrawer.placement)}>Checkout</button>
            </Link> }
            {orderDetailList && orderDetailList.length === 0 && <button className="btn checkout-btn mt-4 w-100 disabled">Checkout</button>}

          </div>
        </Offcanvas.Body>
      </Offcanvas>
  )
}

export default CartDraweEnterprise

import React, { FC, useEffect, useState } from 'react'
import { DeliveryAddressType, DeliveryDetailsType } from '../../../../../Models/deliveryAddressType'
import "./AddNewDeliveryAddress.scss"

import country from "../../../../../Data/country.json"
import state from "../../../../../Data/stateNG.json"
import { useDispatch } from 'react-redux';
import { NEW_DELIVERY_ADDRESS } from '../../../../../Constants/CheckoutConstants'
import toast from 'react-hot-toast'
import { db } from '../../../../../Config/DBConfig'
import { useLiveQuery } from 'dexie-react-hooks'
import { useAuth } from '../../../../../Components/AuthProvider/AuthProvider'
import { useForm } from 'react-hook-form'

interface propsType {
    editSelectedAddress:DeliveryAddressType|number
}

const AddNewDeliveryAddress:FC<propsType> = () => {
  const [selectStates, setSelectedStates] = useState<any>([])
  
  const {register, setValue } = useForm<DeliveryDetailsType>({ defaultValues: { signup:false } })

  const [fields, updateFields] = useState({
    address: null,
    country: null,
    state: null,
    contactFullName: null,
    contactPhoneNumber: null,
    firstname: null,
    lastname: null,
    password: null,
  })
  const { user } = useAuth()

  const dispatch = useDispatch()

  const handleCountryChange = (e:any) => {
    const allState = state[2].data

    setSelectedStates(allState)
  }

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    updateFields({
      ...fields,
      [name]: value,
    })
  }

  const orderDetails = useLiveQuery<any>(async () => await db.orderDetails.toArray())

  const deliveryInfo = orderDetails? orderDetails[0]: undefined;

  const saveAddress = async () => {
    await db.orderDetails.update(orderDetails[0]?.id, { newDeliveryAddress: { ...fields, contactFullName: fields.firstname + ' ' + fields.lastname } })
      .then(function (updated) {
        if (updated) {
          // toast.success(`Delivery address info captured`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        } else {
          toast.error(`Nothing was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }
      });
  }
  
console.log("orderDetails", orderDetails)
  useEffect(() => {
    orderDetails && saveAddress()
    dispatch({ type: NEW_DELIVERY_ADDRESS, payload: fields })
  }, [fields])


  useEffect(()=>{
    if (deliveryInfo && deliveryInfo.newDeliveryAddress) { 
      setValue("address", deliveryInfo?.newDeliveryAddress?.address)
      setValue("contactFullName", deliveryInfo?.newDeliveryAddress?.contactFullName)
      setValue("contactPhoneNumber", deliveryInfo.newDeliveryAddress?.contactPhoneNumber)
      setValue("country", deliveryInfo?.newDeliveryAddress?.country)
      setValue("email", deliveryInfo?.newDeliveryAddress?.email)
      setValue("firstname", deliveryInfo?.newDeliveryAddress?.firstname)
      setValue("lastname", deliveryInfo?.newDeliveryAddress?.lastname)
      setValue("state", deliveryInfo?.newDeliveryAddress?.state)
      setValue("signup", deliveryInfo?.newDeliveryAddress?.signup)
    }
  }, [deliveryInfo])

   

  return (
        <div className='addNewDeliveryAddress'>
            <div className="row">
                <div className="col-md-6 title">Enter your delivery details:</div>
                <div className="col-md-6 text-end title-req"><span className='text-danger'>*</span> Required field</div>
            </div>
          <form action="">
            <div className='form-input'>
                <label htmlFor="street">Street * </label>
                <input type="text"
                id="street" 
                className='form-control' 
                {...register('address', { required: "field is required", onChange: (e) => handleChange(e)})}
                />
            </div>
            <div className="row">

                <div className="col-md-6">
                    <div className='form-input'>
                        <label htmlFor="country">Country * </label>
                        <select className='form-control' id="country"
                         {...register('country', { required: "field is required", onChange: (e) => { handleChange(e); handleCountryChange(e) }})}
                          >
                            <option>--Country--</option>
                            {country.countries.length > 0 && country.countries.map((res:any, index:React.Key) => {
                              return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className='form-input'>
                        <label htmlFor="city">State * </label>
                        <select className='form-control' id="city" 
                         {...register('state', { required: "field is required", onChange: (e) => handleChange(e)})}
                        >
                            <option>--State--</option>
                            {selectStates.map((result: { id: string | number | readonly string[] | undefined; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => {
                              return result.name !=='Nationwide' && <option key={index} value={JSON.stringify(result)}>{result.name}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>

              <hr/>
            <div className="row mt-5">
                <div className="col-md-6">
                    <div className='title'>Enter your contact details:</div>
                    <div className='title-desc'>We use this information to keep you informed of the delivery.</div>
                </div>
                <div className="col-md-6 text-end title-req"><span className='text-danger'>*</span> Required field</div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className='form-input'>
                        <label htmlFor="first_name">First name *</label>
                        <input type="text" id="first_name"  className='form-control'
                        placeholder='Enter your first name'
                        {...register('firstname', { required: "field is required", onChange: (e) => handleChange(e)})}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className='form-input'>
                        <label htmlFor="last_name">Last name *</label>
                        <input type="text" id="last_name" 
                            {...register('lastname', { required: "field is required", onChange: (e) => handleChange(e)})}
                            className='form-control'
                            placeholder='Enter your last name'
                          />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className='form-input'>
                        <label htmlFor="email">Email address * </label>
                        <input type="text" id="email" 
                          {...register('email', { required: "field is required", onChange: (e) => handleChange(e)})} 
                        className='form-control'
                        placeholder='Enter your email address'
                         onChange={(e) => handleChange(e)}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className='form-input position-relative'>
                        <label htmlFor="phone_number">Phone number * </label>
                        <input type="text" id="phone_number"
                          {...register('contactPhoneNumber', { required: "field is required", onChange: (e) => handleChange(e)})} 
                        className='form-control phoneContact'
                        placeholder=''
                        />
                        <div className='countryCode'>+234</div>
                    </div>
                </div>

                {!user
                  ? <div className="col-md-12">
                    <div className='form-input'>
                        <label htmlFor="consent" className='pb-0 mb-0 consent-text d-flex align-items-center'><input type="checkbox" id="consent" 
                        {...register('signup', { required: "field is required", onChange: (e) => handleChange(e)})} 
                           className='me-2 mb-0'
                          />Use my contact details to sign me up, you will need to create your password later to complete sign up.
                        </label>
                    </div>
                </div>
                  : <div className='mt-4'></div>}
            </div> 

          </form>
        </div>
  )
}

export default AddNewDeliveryAddress

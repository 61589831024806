import { FC, useState } from "react"; 
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import userService from "../../../../../../Services/userService"; 
import toast, { Toaster } from "react-hot-toast";
import './BusinessProfileUpdate.scss'
import { useAuth } from "../../../../../../Components/AuthProvider/AuthProvider";
 
 
interface propsType {
  show: boolean;
  handleClose: ()=>void; 
}

interface formProps {
  businessName: string,
  businessAddress: string,
  cacNumber: string
}

const BusinessProfileUpdate:FC<propsType> = ({ show, handleClose}) => {
  
  const { register: registerPass, handleSubmit: handleSubmitPass, formState: { errors: errorsPass }, } = useForm<formProps>()
  
  const { logout } = useAuth()

  const [loadingBusiness, setLoadingBusiness] = useState<boolean>(false)
  
  const changeBusiness = handleSubmitPass((data:any) => processBusiness(data))

  const processBusiness = async (data: { newPassword: any; }) => {
     
    setLoadingBusiness(true)

    await userService.changeBusiness(data).then((res:any) => {

      setLoadingBusiness(false)

      toast.success("Business profile updated successfully, Please relogin.", { duration: 20000, className: 'bg-success text-white', position: "top-right" });
  
       setTimeout(()=>{
         logout();
       }, 2000)

    }, (error:any) => {

      setLoadingBusiness(false) 

      toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });

    })
  }

    return  (
      <Modal show={show} onHide={handleClose}  backdrop="static" contentClassName="businessProfileUpdate">
      <Modal.Header>
        <Modal.Title>Update Business Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <form onSubmit={changeBusiness}>
              <div className='form-group my-3'>
                  <input type="text" className="form-control" placeholder='Business Name' {...registerPass("businessName", { required: "this field cannot be empty" })}/>
                  {errorsPass.businessName && <div className="text-danger"> {errorsPass.businessName.message} </div>}
              </div>

              <div className='form-group my-3'>
                  <textarea className="form-control" placeholder='Business address' {...registerPass("businessAddress", { required: "this field cannot be empty" })}/>
                  {errorsPass.businessAddress && <div className="text-danger"> {errorsPass.businessAddress.message} </div>}
              </div>

              <div className='form-group my-3'>
                  <input type="text" className="form-control" placeholder='Business CAC' {...registerPass("cacNumber", { required: "this field cannot be empty" })}/>
                  {errorsPass.cacNumber && <div className="text-danger"> {errorsPass.cacNumber.message} </div>}
              </div>

              <div className='form-group my-3 text-center mt-5'> 
                {!loadingBusiness && (<button type="submit" className="btn seller-btn-primary btn-lg btn-block w-100 fs-6 fw-bolder">Save Profile</button>)}
                {loadingBusiness && (<button type="submit" className="btn seller-btn-primary btn-lg btn-block w-100" disabled>
                    Please wait
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>)}
              </div>
          </form>
      </Modal.Body>
      <Toaster></Toaster>
    </Modal>
    )

}

export default BusinessProfileUpdate;
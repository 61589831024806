import React, { useEffect, useState } from 'react'
import "./Checkout.scss"

import prodImg from "../../../../Assets/Images/products/prod-img-1.png"
import mastercard from "../../../../Assets/Images/mastercard.png"
import Visa from "../../../../Assets/Images/Visa.png"
import CheckoutCartItem from '../../Components/Checkout/CheckoutCartItem/CheckoutCartItem'
import OrderReceiveType from '../../Components/Checkout/OrderReceiveType/orderReceiveType'
import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../../../../Config/DBConfig'

import { DeliveryAddress, DeliveryAddressItems, LoginCard } from '../../Components'
import PaymentOption from '../../Components/Checkout/PaymentOption/PaymentOption'
import { HeaderMeta, ImageSrc } from '../../../../Components'
import { DeliveryAddressType } from '../../../../Models/deliveryAddressType' 
import { useSelector } from 'react-redux'
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider'
import PickupContactInformation from '../../Components/Checkout/PickupContactInformation/PickupContactInformation'
import { CurrencyFormatter } from '../../../../Helpers'
import toast, { Toaster} from 'react-hot-toast';

const Checkout = () => {
  const [orderType, setOrderType] = useState("delivery")
  const [step, setStep] = useState(1)
  const [totalCost, setTotalCost] = useState<number>(0);
  const { user } = useAuth()

  const orderDetails = useLiveQuery<any[]>(async () => await db.orderDetails.toArray())

  const editSelectedAddress = useSelector((state: { editDeliveryAddress: DeliveryAddressType|number }) => state.editDeliveryAddress)
 
  useLiveQuery<any>(
    async () => {
      const result:any[] = await db.orderDetails.toArray() || []
      setOrderType(result[0]?.deliveryType)
    }
  );

  useLiveQuery(
    async () => {
      const cartItems = await db.cart.toArray()
      let newCost = 0
      cartItems.length > 0 && cartItems.map((res:any) => {
        newCost += res.productDetails.unitPriceForBuyer * res.quantity;
        setTotalCost(newCost);
        return 1
      })
    })

  const [deliveryInfo, setDeliveryInfo] = useState(true)

  const deliveryAddress = useSelector((state: {newDeliveryAddress:any}) => state.newDeliveryAddress)
  const pickupContactInformation = useSelector((state: {pickupContactInformation:any}) => state.pickupContactInformation)

  const checkDeliveryProps = ():void => {
    // field validation
    if (orderType === "PICKUP" && orderDetails && orderDetails[0]?.pickupLocations?.sellers.length > 0) {
      setDeliveryInfo(false)
    }

    if (orderType === "PICKUP" && pickupContactInformation.msisdn !== null && pickupContactInformation.emailAddress !== null && pickupContactInformation.fullName !== null) {
      setDeliveryInfo(false)
    }

    if (orderType === "DELIVERY" && user === null && deliveryAddress.address !== null && deliveryAddress.country !== null && deliveryAddress.state !== null && deliveryAddress.contactPhoneNumber !== null && deliveryAddress.firstname !== null && deliveryAddress.lastname !== null) {
      setDeliveryInfo(false)
    }

    if (orderType === "DELIVERY" && user !== null && deliveryAddress.address !== null && deliveryAddress.country !== null && deliveryAddress.state !== null && deliveryAddress.contactPhoneNumber !== null && deliveryAddress.firstname !== null && deliveryAddress.lastname !== null) {
      setDeliveryInfo(false)
    }

    if (orderType === "DELIVERY" && user !== null && orderDetails && orderDetails[0].deliveryAddressUuid && orderDetails[0].deliveryAddressUuid.uuid) {
      setDeliveryInfo(false)
    }
  }

  useEffect(() => {
    checkDeliveryProps()
  }, [orderDetails, deliveryAddress])


  const deleteAddress = async (id:any) => {  
    if (orderDetails) {
    await db.orderDetails.update(orderDetails[0].id, { deliveryAddressUuid: null }).then(()=>{  
      toast.success("Address removed", { duration: 9000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      setStep(1)
      })
    }
  }

  return (
        <div className='checkout'>
            <HeaderMeta title={'Checkout'}></HeaderMeta>
            <div className="container my-2">
                <div className="ps-2">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">Cart </a></li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section>
            <div className="container">
                <div className="row">
                <div className="col-md-8">
                    <h1>Checkout</h1>

                    <div className="steps mt-5">
                       <span className="badge">1</span><span onClick={() => setStep(1)}>Have your order delivered or picked up?</span>
                    </div>

                    {step === 1 && <React.Fragment>
                       <OrderReceiveType></OrderReceiveType>
                       {/* Toggle if user want to edit the address */}
                        {editSelectedAddress === 0 && <div className="cart-item-list mt-5">

                        {user
                          ? orderType === "PICKUP"
                            ? <div className="select-pickup">Select a pickup location for each item</div>
                            : <div className="select-pickup">Select or enter a delivery location</div>
                          : ""}

                            {orderType === "PICKUP" && <CheckoutCartItem></CheckoutCartItem>}
                            {orderType === "PICKUP" && !user && <PickupContactInformation></PickupContactInformation>}
                            {orderType === "DELIVERY" && <DeliveryAddress></DeliveryAddress>}

                        </div>}

                        <div className="continue-shoping">
                          <button className="btn btn-primary" onClick={() => setStep(2)} disabled={deliveryInfo}>Continue</button>
                        </div>
                    </React.Fragment>}

                    {orderDetails && step === 2 && orderDetails[0].deliveryAddressUuid &&
                    <div className="row mt-3">
                       <div className="col-md-4 col-12">
                            <DeliveryAddressItems addressDetails={orderDetails[0].deliveryAddressUuid} index={0} selectAddress={() => true} selectedAddress={undefined}  
                              deleteItem={(data:any)=> deleteAddress(data)}
                              setShowEdit={function (value: boolean): void {  alert("Function not enabled") } } 
                              setDataResEdit={function (value: any): void {  alert("Function not enabled")  } }  
                              remoteAddress={false}
                              ></DeliveryAddressItems>
                       </div>
                    </div>
                    }

                    <div className="border-top  mt-5 pt-4">
                        <div className="steps">
                            <span className={step === 1 ? "inactive badge" : "badge"}>2</span><span className={step === 1 ? "inactive" : ""}>Payment options</span>
                        </div>

                        {step === 2 && <React.Fragment>
                            <PaymentOption totalCost={totalCost}></PaymentOption>
                        </React.Fragment>}

                    </div>
                </div>

                <div className="col-md-4">
                    {!user ? <LoginCard></LoginCard> : ""}
                    <hr></hr>
                    <div className="cart-summary">
                        <div className="card border-0">
                        <div className="card-header border-0">
                            Summary
                        </div>
                        <div className="card-body">
                            <div className="row">
                            <div className="col-6 cart-el">
                                Subtotal
                            </div>
                            <div className="col-6 cart-el">
                                ₦{CurrencyFormatter( totalCost)}
                            </div> 
                            <div className="col-12 text-left">
                                <hr/>
                            </div>
                            <div className="col-6 ">
                                <div className="cart-total">Total</div>
                            </div>
                            <div className="col-6">
                                <div className="cart-total"> ₦{CurrencyFormatter(totalCost)}</div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="payment-option">
                    Acceptable payment methods
                    <div className="">
                        <img src={mastercard} className=""/>
                        <img src={Visa} className=""/>
                    </div>
                    </div>
  
                    <div className="cart-products">
                    <div className="your-order">
                        Your order
                    </div>
                    <div className="row mt-3">
                        <div className="col-2">
                            <ImageSrc src={prodImg} alt={''} title={undefined} width={'w-100'}></ImageSrc>
                        </div>
                        <div className="col-2">
                            <ImageSrc src={prodImg} alt={''} title={undefined} width={'w-100'}></ImageSrc>
                        </div>
                    </div>
                    </div>
                    <hr/>
                </div>
                </div>
                <hr/>
            </div>
        </section>
        <Toaster></Toaster>
    </div>
  )
}

export default Checkout

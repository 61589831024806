import "./PLPApplicationForm.scss";
import BuyerPageTitle from "../../../../../../../Components/Buyer/BuyerPageTitle/BuyerPageTitle";
import  Stepper  from "../../../../../../../Components/Buyer/Stepper/Stepper";
import {MemoizedStep as Step} from "../../../../../../../Components/Buyer/Stepper/Step/Step";
import { useState, useEffect, useCallback } from "react";
import {MemoizedBasicDetailsInput} from "./BasiDetailsInputs/BasicDetailsInput";
import CompanyDetailsInput from "./CompanyDetailsInput/CompanyDetailsInput";
import DefferedPaymentDetailsInput from "./DefferedPaymentDetailsInput/DefferedPaymentDetailsInput";
import DocumentUploadInputs from "./DocumentUploadInputs/DocumentUploadInputs";
import {useFormik} from "formik";
import { FormValuesType, InitialFormValues, ValidationSchema } from "./PLPApplicationFormConfig";
import { Logger } from '../../../../../../../Helpers/Logger';
import toast, { Toaster } from "react-hot-toast";
import productFinancingService from "../../../../../../../Services/productFinancingService";
import AlertModal from "../../../../../../../Components/AlertModal/AlertModal";
import { useNavigate } from "react-router-dom";

const totalSteps = 4;
 
const PLPApplicationForm = () => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [visitedSteps, setVisitedSteps] = useState<number[]>([1]);
    const [images, setImages] = useState<any>([]) 
    const [showModal, setShowModal] = useState(false)
    const [alertType, setAlertType] = useState("")
    const [alertMessage, setAlertMessage] = useState("")
    const navigate = useNavigate()
    
    const handleNext = useCallback(()=>{
       
        // add new step to list of visited steps
           setVisitedSteps(oldVisitedSteps=> {
               const isStepVisited = oldVisitedSteps.includes(currentStep+1);
               if (isStepVisited) {
                return oldVisitedSteps; 
               }
               oldVisitedSteps.push(currentStep+1)
               return oldVisitedSteps;
           }
           )
   
            // increase step
           setCurrentStep(oldStep=>oldStep+1)
       
   },[currentStep])

   const handlePrevious = useCallback(()=>{

       // remove last step
       setVisitedSteps(oldVisitedSteps=> {
           oldVisitedSteps.pop()
           return oldVisitedSteps;
       })

       setCurrentStep(oldStep=>oldStep-1)
   },[currentStep])


    useEffect(()=>{
        scroll(0,20)
    },[currentStep])
    
    const showPreviousBtn =  ()=> {
        if (visitedSteps?.length>1) {
            return true;
        }

        return false;
    }

    const formik = useFormik({
        validationSchema:ValidationSchema,
        initialValues:InitialFormValues,
        onSubmit: (values:FormValuesType)=>{ 
            processForm(values) 
        }
    })

    const showNextBtn =  ()=> {
        if (visitedSteps?.length<totalSteps) {
            return true;
        }

        return false;
    }
    const showSubmitBtn =  ()=> {
        if (visitedSteps?.length===totalSteps) {
            return true;
        }

        return false;
    }


    const handleFileUpload =  async (event: any, fileName:any) => { 
        // Logger("fileName", fileName);
        // Logger("event", event.target.files[0]);
        setImages([...images, { file: event.target.files[0], name: fileName }])
        await startUpload22("8ee11dd5-4b19-492b-8c7c-10ea553fb245", event.target.files[0], fileName)
       };


      const checkIfExist = (fName:string) => { 
        const found = images.some((el: { name: any }) => el.name === fName);
        return found;
      }

      Logger("Images", images) 
      Logger("formik.errors", formik.errors)


      const processForm = async (formData:FormValuesType) => {
       const load = toast.loading("Sending application, please wait...", { duration: 20000, className: 'bg-white text-dark',  position: "top-right" });
        // Logger("formData data00", formData)
     
        await productFinancingService.sendApplication(formData)
          .then(async (res:any) => { 
            toast.dismiss(load)
            Logger("Add product success", res)
            await startUpload(res.data.data.uuid)
          }, async (error:any) => { 
            toast.dismiss(load)
            await startUpload("8ee11dd5-4b19-492b-8c7c-10ea553fb245")
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            Logger("Add plp error", error.response)
          })
      }

    const startUpload = async (plpUuid:any) => {
        const arraySize = images.length
        for (const [index, currentProductImage] of images.entries()) {
          const formData = new FormData();
          Logger("PRODUCT FINANCE FILE", currentProductImage.file)
          // Update the formData object
          formData.append(
            "file",
            currentProductImage.file
          );
          Logger("PRODUCT FINANCE FILE 2", formData) 
    
          await productFinancingService.file(plpUuid, formData, currentProductImage.name).then((res:any) => {
            // Logger("FILE UPLOAD", res)
            toast.success("Image saved successfully", { duration: 10000, className: 'bg-white shadow-md text-secondary',  position: "top-right" });
            
            if (index === arraySize - 1) {
              toast.success("Upload completed", { duration: 20000, className: 'bg-white shadow-md text-dark', position: "top-right" });
    
              setShowModal(true)
              setAlertType("success")
              setAlertMessage("You have successfully applied for the product leasing program.")
    
              setTimeout(() => {
                toast.dismiss()
                return navigate("/buyer/product-leasing-program", {replace:true})
              }, 4000)
            }
          }, (error:any) => { 
            toast.error(error.response.data.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            Logger("IMAGE UPLOAD ERROR", error.response)
          })
        }
    }
    



    const startUpload22 = async (plpUuid:any, images:any, name:any) => {
          const formData = new FormData(); 
          // Update the formData object
          formData.append(
            "file",
            images
          );
          Logger("PRODUCT FINANCE FILE 2", formData) 
    
          await productFinancingService.file(plpUuid, formData, name).then((res:any) => {
            // Logger("FILE UPLOAD", res)
            toast.success("Image saved successfully", { duration: 10000, className: 'bg-white shadow-md text-secondary',  position: "top-right" });
            
             toast.success("Upload completed", { duration: 20000, className: 'bg-white shadow-md text-dark', position: "top-right" });
    
              setShowModal(true)
              setAlertType("success")
              setAlertMessage("You have successfully applied for the product leasing program.")
     
          }, (error:any) => { 
            toast.error(error.response.data.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            Logger("IMAGE UPLOAD ERROR", error.response)
          }) 
    }
    return (
        <div className="buyer-plp-application-form-page">
            <BuyerPageTitle title="Apply for PLP" parent='Product Leasing Program'
                parentUrl='product-leasing-program'
            />

            <div className="stepper-container">
                <Stepper currentStep={currentStep} visitedStep={visitedSteps} totalSteps={totalSteps}>
                    <Step currentStep={currentStep} visitedSteps={visitedSteps} 
                        step={1}
                    />
                    <Step currentStep={currentStep} visitedSteps={visitedSteps} 
                        step={2} 
                    />
                    <Step currentStep={currentStep} visitedSteps={visitedSteps} 
                        step={3}
                    />
                    <Step currentStep={currentStep} visitedSteps={visitedSteps} 
                        step={4}
                    />
               </Stepper>
            </div>
            <form className="plp-application-form" onSubmit={formik.handleSubmit}>
                {
                    currentStep===1 && <MemoizedBasicDetailsInput formikValues={formik.values}
                        handleChange={formik.handleChange} handleBlur= {formik.handleBlur}
                        formikErrors={formik.errors} formikTouchedValues={formik.touched} 
                    />
                }

                {
                    currentStep===2 && <CompanyDetailsInput companyAddressValue={formik.values.companyAddress}
                        companyNameValue={formik.values.companyName} cacNumberValue={formik.values.cacNumber}
                        jobTitlevValue={formik.values.jobTitle} handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                        businessTypeValue={formik.values.businessType}
                        formikErrors={formik.errors} formikTouchedValues={formik.touched}
                    />
                }

                {
                    currentStep===3 && <DefferedPaymentDetailsInput
                        formikValues={formik.values} handleChange={formik.handleChange}
                        handelBlur={formik.handleBlur}
                        formikErrors={formik.errors} formikTouchedValues={formik.touched}
                    />
                }
                {
                    currentStep === 4 && <DocumentUploadInputs handleFileUpload={(event:any, filename:string)=>handleFileUpload(event, filename)} checkIfExist={checkIfExist}/>
                }

                <div className="control-btns-container">
                    {showPreviousBtn() && <button className="plp-form-btn btn btn-outline-primary" type="button" onClick={handlePrevious}>Previous</button>}
                    {showNextBtn() && <button className="plp-form-btn btn" type="button" onClick={handleNext}>Next</button>}
                    {showSubmitBtn() &&  <button className="plp-form-btn btn" type="submit">Submit application</button>}
                </div>
            </form>
           <AlertModal showModal={showModal} alertType={alertType} message={alertMessage}></AlertModal>
           <Toaster></Toaster>
        </div>
    )
}

export default PLPApplicationForm;

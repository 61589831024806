import React, {  FC, useEffect, useState } from 'react' 
import "./EnterpriseWareHouseManagement.scss" 
import state from "../../../../../../Data/stateNG.json" 
import toast, { Toaster } from 'react-hot-toast';
import Modal from 'react-bootstrap/esm/Modal';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import wareHouseService from '../../../../../../Services/wareHouseService';
import { wareHouse } from '../../../../../../Models/enterprise.types';
import { useAllWarehouse, useSingleWarehouseManager } from '../../../../../../Hooks/Queries/Enterprise/useAllWarehouse';
import HorizontalLoader from '../../../../../../Components/Loader/HorizontalLoader/HorizontalLoader';
import { BasicRBAC } from '../../../../../../Helpers/BasicRBAC';

  interface propsType {
    module?: string;
  }
const EnterpriseWareHouseManagement:FC<propsType> = ({ module }) => {
    const [showEdit, setShowEdit] = useState(false); 
    const [editUuid, setEditUuid] = useState(""); 
    const [show, setShow] = useState(false); 
    const [sendingDeleteRequest, setSendingDeleteRequest] = useState(false); 
    
    const [sendingRequest, setSendingRequest] = useState(false);  
    const [sendingRequestEdit, setSendingRequestEdit] = useState(false);  
    
    const [dataEdit, setDataEdit] = useState<wareHouse>({
      name: "",
      contactFullName: "",
      contactPhoneNumber: "",
      country: "",
      state: "",
      uuid: "",
      isDefault: false
    }); 
    
    
    const access = BasicRBAC
    
    const handleClose = () => setShow(false); 
    const handleCloseEdit = () => setShowEdit(false); 
    
    
    const handleShow = () => setShow(true);
    
    const { register, handleSubmit, formState: { errors } } = useForm<wareHouse>({defaultValues: { isDefault:false, country: "Nigeria"}});

    const { register: registerEdit, setValue, handleSubmit: handleSubmitEdit , formState: { errors: errorsError } } = useForm<wareHouse>({defaultValues: { isDefault:false, country: "Nigeria"}});
  
  
    const submit = async (data:any) => {
      setSendingRequest(true)
      await wareHouseService.create(data)
        .then((res: any) => {
          setSendingRequest(false)
          handleClose()
          toast.success("Warehouse created", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        
          setTimeout(()=>{
            refetch()
          }, 3000)
        }, (error:any) => {
          setSendingRequest(false)
          console.log("buyerPickUpLocation", error.response)
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    };
    
    const {data, isLoading, isSuccess, refetch } = access("allWareHouse") === true? useAllWarehouse() : useSingleWarehouseManager()

    useEffect(() => {
      refetch()
    }, [])  

    const submitEdit = async (data:any) => {
      setSendingRequestEdit(true)
      await wareHouseService.update(data, dataEdit.uuid)
        .then((res: any) => {
          setSendingRequestEdit(false)
          setShowEdit(false)
          toast.success("Warehouse updated", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        
          setTimeout(()=>{
            refetch()
          }, 3000)
          
        }, (error:any) => {
          setSendingRequestEdit(false)
          console.log("buyerPickUpLocation", error.response)
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    };

  
  
    
    useEffect(()=>{
      setValue("name",  dataEdit?.name)
      setValue("contactFullName",  dataEdit?.contactFullName)
      setValue("contactPhoneNumber",  dataEdit?.contactPhoneNumber)
      setValue("country",  dataEdit?.country)
      setValue("state",  dataEdit?.state)
     
    }, [dataEdit])


    const deleteWareHouse = async (wareHouseUuid:string) => {
      setSendingDeleteRequest(true)
      setEditUuid(wareHouseUuid)
      await wareHouseService.delete(wareHouseUuid)
        .then((res: any) => {
          setSendingDeleteRequest(false)
          setShowEdit(false)
          toast.success("Warehouse delete", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        
          setTimeout(()=>{
            refetch()
          }, 3000)
        }, (error:any) => {
          setSendingDeleteRequest(false)
          console.log("buyerPickUpLocation", error.response)
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    };


return (
<div className='enterpriseWareHouseManagement'> 
    <div className="container">

        <div className="heading-text mt-4">
            Buy building materials in warehouses and ship to sites anytime
        </div>

        <div className="row">
            <div className="col-8 col-md-9">   
                <div className="heading-count">
                 {isLoading === true && isSuccess === false? <span className='spinner-border spinner-border-sm'></span> : data?.length} warehouse{data?.length > 1 && 's'} created
                </div>
            </div>

            {access('createWarehouse') === true && <div className="col-4 col-md-3 d-flex justify-content-center text-end align-items-end">
                <button className={module && module ==="estate"? 'btn btn-outline-primary btn-sm addnew-address addnew-address-estate d-md-none': 'btn btn-outline-primary btn-sm addnew-address d-md-none'} onClick={handleShow}><i className='fa fa-plus'></i> Add</button>
                <button className={module && module ==="estate"? 'btn btn-outline-primary btn-sm addnew-address addnew-address-estate btn-addnew d-none d-md-block': 'btn btn-outline-primary btn-sm addnew-address btn-addnew d-none d-md-block'} onClick={handleShow}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 12H18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 18V6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> New Warehouse </button> 
          </div>}
        </div>
            <div className="">
            {isLoading === true && isSuccess === false && <HorizontalLoader></HorizontalLoader>}
                <div className="warehouse-item-container mb-4"> 
                      <div className='row mb-5'>
                           
                          {isSuccess === true && data?.length >0 && data?.map((result:wareHouse, index:React.Key)=>{
                             return <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                              <div className="card">
                                <Link to={module && module==="estate"? `/estate-managers/warehouse-management/${result.uuid}`:`/enterprise/warehouse-management/${result.uuid}`}>
                                    <div className="card-header border-0 pt-4 bg-white rounded fw-bold"> 
                                        <div className="warehouse-name">{result.name}</div>
                                    </div>
                                </Link>
                                <div className="card-body">  
                                  <div> 
                                    <svg className="me-1" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9999 14.1675C9.86988 14.1675 8.12988 12.4375 8.12988 10.2975C8.12988 8.1575 9.86988 6.4375 11.9999 6.4375C14.1299 6.4375 15.8699 8.1675 15.8699 10.3075C15.8699 12.4475 14.1299 14.1675 11.9999 14.1675ZM11.9999 7.9375C10.6999 7.9375 9.62988 8.9975 9.62988 10.3075C9.62988 11.6175 10.6899 12.6775 11.9999 12.6775C13.3099 12.6775 14.3699 11.6175 14.3699 10.3075C14.3699 8.9975 13.2999 7.9375 11.9999 7.9375Z" fill="#292D32"/>
                                        <path d="M11.9997 22.76C10.5197 22.76 9.02969 22.2 7.86969 21.09C4.91969 18.25 1.65969 13.72 2.88969 8.33C3.99969 3.44 8.26969 1.25 11.9997 1.25C11.9997 1.25 11.9997 1.25 12.0097 1.25C15.7397 1.25 20.0097 3.44 21.1197 8.34C22.3397 13.73 19.0797 18.25 16.1297 21.09C14.9697 22.2 13.4797 22.76 11.9997 22.76ZM11.9997 2.75C9.08969 2.75 5.34969 4.3 4.35969 8.66C3.27969 13.37 6.23969 17.43 8.91969 20C10.6497 21.67 13.3597 21.67 15.0897 20C17.7597 17.43 20.7197 13.37 19.6597 8.66C18.6597 4.3 14.9097 2.75 11.9997 2.75Z" fill="#292D32"/>
                                    </svg>
                                    {result.state}
                                  </div> 
                                  <div> 
                                     <svg  className="me-1" width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12.1605 11.62C12.1305 11.62 12.1105 11.62 12.0805 11.62C12.0305 11.61 11.9605 11.61 11.9005 11.62C9.00055 11.53 6.81055 9.25 6.81055 6.44C6.81055 3.58 9.14055 1.25 12.0005 1.25C14.8605 1.25 17.1905 3.58 17.1905 6.44C17.1805 9.25 14.9805 11.53 12.1905 11.62C12.1805 11.62 12.1705 11.62 12.1605 11.62ZM12.0005 2.75C9.97055 2.75 8.31055 4.41 8.31055 6.44C8.31055 8.44 9.87055 10.05 11.8605 10.12C11.9105 10.11 12.0505 10.11 12.1805 10.12C14.1405 10.03 15.6805 8.42 15.6905 6.44C15.6905 4.41 14.0305 2.75 12.0005 2.75Z" fill="#333333"/>
                                          <path d="M12.1696 22.55C10.2096 22.55 8.23961 22.05 6.74961 21.05C5.35961 20.13 4.59961 18.87 4.59961 17.5C4.59961 16.13 5.35961 14.86 6.74961 13.93C9.74961 11.94 14.6096 11.94 17.5896 13.93C18.9696 14.85 19.7396 16.11 19.7396 17.48C19.7396 18.85 18.9796 20.12 17.5896 21.05C16.0896 22.05 14.1296 22.55 12.1696 22.55ZM7.57961 15.19C6.61961 15.83 6.09961 16.65 6.09961 17.51C6.09961 18.36 6.62961 19.18 7.57961 19.81C10.0696 21.48 14.2696 21.48 16.7596 19.81C17.7196 19.17 18.2396 18.35 18.2396 17.49C18.2396 16.64 17.7096 15.82 16.7596 15.19C14.2696 13.53 10.0696 13.53 7.57961 15.19Z" fill="#333333"/>
                                      </svg> {result.contactFullName}, {result.contactPhoneNumber}
                                  </div> 
                                </div>
                                <div className="card-footer bg-white text-start p-0 py-2"> 
                                   <button className={module && module === "estate"? "btn btn-estate": "btn"} onClick={()=>{ setDataEdit(result); setShowEdit(true) }}> Edit</button>
                                   {sendingDeleteRequest===true && editUuid===result.uuid? <span className='spinner-border spinner-border-sm'></span>: <button className={module && module === "estate"? "btn btn-estate": "btn"} onClick={()=>deleteWareHouse(result.uuid)}> Delete</button>}
                                </div>
                              </div>
                            </div> 
                          } )}

                        </div>
                  </div>
              </div> 


                      <Modal
                          show={show}
                          onHide={handleClose}
                          backdrop="static"
                          keyboard={false}
                          contentClassName="addNewAWareHouseModal"
                        >
                          <form onSubmit={handleSubmit(submit)}>
                            <Modal.Header closeButton>
                              <Modal.Title>Create New Warehouse</Modal.Title>
                            </Modal.Header>
                            <Modal.Body
                          className="rounded">
                            
                            <div className='form-group mt-3'>
                                  <label htmlFor='address'>Warehouse name </label>
                                  <input className='form-control fc textarea-pk' {...register('name', { required: true })}  id="name" placeholder=''/>
                                  {errors.name && <div className='text-danger'>{errors.name.message}</div>}
                                </div>

                                <div className="row"> 
                                    <div className="col-md"> 
                                      <div className='form-group mt-2'>
                                         <label htmlFor='name'>Location</label>
                                          <input type="hidden" value="Nigeria" {...register('country', { required: true })}/>
                                          <select className='form-control' {...register("state", { required: "This field is required" })}>
                                          <option>--State--</option>
                                          {state[2]?.data?.map((result, index: React.Key) => {
                                            return <option key={index} value={result.name}>{result.name}</option>
                                          })}
                                          </select>
                                          {errors.state && <div className='text-danger'>{errors.state.message}</div>}
                                      </div>
                                  </div>
                                </div>

                                 
                                <div className="row">
                                  <div className="col-md"> 
                                    <div className='form-group mt-2'>
                                      <label htmlFor='contactFullName'>Warehouse manager&lsquo;s name</label>
                                      <input className='form-control fc' id="contactFullName" {...register('contactFullName', { required: true })} placeholder='e.g John Doe'></input>
                                      {errors.contactFullName && <div className='text-danger'>{errors.contactFullName.message}</div>}
                                    </div> 
                                  </div>
                                  <div className="col-md">
                                    <div className='form-group mt-2'>
                                      <label htmlFor='name'>Warehouse manager&lsquo;s contact</label>
                                      <input className='form-control fc' id="name" {...register('contactPhoneNumber', { required: true })} placeholder=''></input>
                                      {errors.contactPhoneNumber && <div className='text-danger'>{errors.contactPhoneNumber.message}</div>}
                                    </div>
                                  </div>
                                </div> 

                            </Modal.Body>
                            <Modal.Footer>

                                {!sendingRequest && (<button type="submit" className={module && module === "estate"? "btn btn-estate w-100 border-0": "btn w-100 border-0"} onClick={handleSubmit(submit)}>Create Warehouse</button>)}
                                {sendingRequest && (<button type="submit" className={module && module === "estate"? "btn btn-estate border-0": "btn border-0"} disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>)}

                            </Modal.Footer>
                          </form>
                      </Modal>
 

 

                      <Modal
                          show={showEdit}
                          onHide={handleCloseEdit}
                          backdrop="static"
                          keyboard={false}
                          contentClassName="addNewAWareHouseModal"
                        >
                          <form onSubmit={handleSubmitEdit(submitEdit)}>
                            <Modal.Header closeButton>
                              <Modal.Title>Edit Warehouse</Modal.Title>
                            </Modal.Header>
                            <Modal.Body
                          className="rounded">
                            
                            <div className='form-group mt-3'>
                                  <label htmlFor='address'>Warehouse name </label>
                                  <input type="text" className='form-control fc textarea-pk' {...registerEdit('name', { required: true })}  id="name" placeholder=''/>
                                  {errorsError.name && <div className='text-danger'>{errorsError.name.message}</div>}
                                </div>

                                <div className="row"> 
                                    <div className="col-md"> 
                                      <div className='form-group mt-2'>
                                         <label htmlFor='name'>Location</label>
                                          <input type="hidden" value="Nigeria" {...registerEdit('country', { required: true })}/>
                                          <select className='form-control' {...registerEdit("state", { required: "This field is required" })}>
                                          <option>--State--</option>
                                          {state[2]?.data?.map((result, index: React.Key) => {
                                            return <option key={index} value={result.name}>{result.name}</option>
                                          })}
                                          </select>
                                          {errorsError.state && <div className='text-danger'>{errorsError.state.message}</div>}
                                      </div>
                                  </div>
                                </div>

                                 
                                <div className="row">
                                  <div className="col-md"> 
                                    <div className='form-group mt-2'>
                                      <label htmlFor='contactFullName'>Warehouse manager&lsquo;s contact name </label>
                                      <input type="text" className='form-control fc' id="contactFullName" {...registerEdit('contactFullName', { required: true })} placeholder='e.g John Doe'></input>
                                      {errorsError.contactFullName && <div className='text-danger'>{errorsError.contactFullName.message}</div>}
                                    </div> 
                                  </div>
                                  <div className="col-md">
                                    <div className='form-group mt-2'>
                                      <label htmlFor='name'>Warehouse manager&lsquo;s contact</label>
                                      <input type="text" className='form-control fc' id="name" {...registerEdit('contactPhoneNumber', { required: true })} placeholder=''></input>
                                      {errorsError.contactPhoneNumber && <div className='text-danger'>{errorsError.contactPhoneNumber.message}</div>}
                                    </div>
                                  </div>
                                  


                                  <div className="col-md-12">
                                    <div className='form-group mt-2 default-wh'>
                                      <label htmlFor='isDefault'>
                                      <input type="checkbox" className='fc' id="isDefault" value="true" {...registerEdit('isDefault', { required: true })} placeholder=''></input>
                                      Make this your default warehouse.</label>
                                      {errorsError.isDefault && <div className='text-danger'>{errorsError.isDefault.message}</div>}
                                    </div>
                                  </div>
                                </div> 

                            </Modal.Body>
                            <Modal.Footer>

                                {sendingRequestEdit===false && (<button type="submit" className={module && module==="estate"? "btn btn-estate w-100 border-0": "btn w-100 border-0"} onClick={handleSubmitEdit(submitEdit)}>Update Warehouse</button>)}
                                {sendingRequestEdit && (<button type="submit" className={module && module==="estate"? "btn btn-estate w-100 border-0": "btn w-100 border-0"} disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>)}

                            </Modal.Footer>
                          </form>
                      </Modal>
                <Toaster/> 
                </div>
        </div>
    )
}

export default EnterpriseWareHouseManagement

import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import "./AddBankAccount.scss"
import { useForm } from 'react-hook-form';
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService';
import toast, { Toaster } from 'react-hot-toast';
// eslint-disable-next-line no-unused-vars
import { GetLength } from '../../../../../../../Helpers/getLength'
import BuyerPageTitle from '../../../../../../../Components/Buyer/BuyerPageTitle/BuyerPageTitle';

interface formProps {
  bankName:string;
  accountNumber:string;
  bankCode:string;
}
const AddBankAccount = () => {
  const navigate = useNavigate()

  const [show, setShow] = useState(false); 
  const [savingBankDetails, setSavingBankDetails] = useState(false);
  const [nameSearchRequest, setNameSearchRequest] = useState(false); 
  const [allBank, setAllBank] = useState([]); 
  const [selectedBankObj, setSelectedBank] = useState<any>(null);
  // eslint-disable-next-line no-unused-vars
  const [myAccountNumber, setMyAccountNumber] = useState();
  const [myAccountDetails, setMyAccountDetails] = useState<any>(null);

  const handleClose = () => setShow(false); 

  const { handleSubmit, register, formState: { errors } } = useForm<formProps>()

  const onsubmit = handleSubmit((data:any) => processForm(data))

  const processForm = async (data: any) => {
    setSavingBankDetails(true)
    const bankName = JSON.parse(data.bankName).name
 
    await bankingFinanceService.addBankAccount({ ...data, bankName, bankCode: selectedBankObj.code })
      .then((res:any) => {
        setSavingBankDetails(false)
        toast.success("Bank Account Added Succesfully", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        setTimeout(() => {
          navigate("/buyer/banking-and-finance", { replace: true })
        }, 500)
      }, (error:any) => {
        setSavingBankDetails(false) 
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  const getAllBank = async () => {
    await bankingFinanceService.getAllBank()
      .then((res) => { 
        setAllBank(res.data.data)
      }, (error:any) => { 
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    getAllBank()
  }, [])

  const selectedBank = (e: any) => {
    setSelectedBank(JSON.parse(e.target.value)) 
  }

  const selectBankNumber = (e: { target: { value: any; }; }) => { 
    setMyAccountNumber(e.target.value)
    if (GetLength(e.target.value) >= 10) {
      setNameSearchRequest(true)
      bankingFinanceService.VerifyAccount({
        accountNumber: e.target.value,
        bankCode: selectedBankObj.code
      }).then((res) => { 
        setNameSearchRequest(false)
        setMyAccountDetails(res.data.data)
      }, (error) => {
        setNameSearchRequest(false)
        setMyAccountDetails(false)
        toast.error(error.message, { duration: 20000, className: 'bg-white text-light' }); 
      })
    }
  }

  return (
    <div className='add-bank-account'>
      <div className="container mt-4">
      <BuyerPageTitle title={'Add New Bank Detail'} parent={"Banking and Finance"} parentUrl={'banking-and-finance'}></BuyerPageTitle>

           <div className="container-fluid mt-4">
             <div className="add-bank-account-cont">
              <div className="row h-100">
                <div className="col-md-6 offset-md-3">
                  <form onSubmit={onsubmit}>
                      <h4 className='add-bank-account-title my-3'>Enter your bank details</h4>
                      <div className='form-input'>
                          <div className='form-input-wrap'>
                              <select id="bankName" className='form-control ps-2 mt-3' placeholder='Bank Name' {...register("bankName", { required: "This field is required", onChange: (e) => { selectedBank(e) } })}>
                                <option>--select bank--</option>
                                {allBank.length > 0 && allBank.map((res:any, index:React.Key) => {
                                  return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                                })}
                              </select>
                              {errors.bankName && <div className="text-danger">{errors.bankName.message}</div>}
                          </div>
                          <div className='form-input-wrap'>
                              <input id="accountNumber" type="number" className='form-control ps-2 mt-3' {...register("accountNumber", { required: "This field is required", onChange: (e) => selectBankNumber(e) })} placeholder="Account Number" disabled={!selectedBankObj || nameSearchRequest}/>
                              {nameSearchRequest && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> }
                              {myAccountDetails !== false && myAccountDetails !== null && <span className='text-success fw-bolder'>{myAccountDetails.account_name}</span> }
                              {myAccountDetails === false && <span className='text-danger'>Invalid account number</span> }

                              {errors.accountNumber && (<div className="text-danger">{errors.accountNumber.message}</div>)}
                          </div>
                          <div className='form-input-wrap'>
                              <input id="bankCode" type="hidden" className='form-control ps-2 mt-3' placeholder='Bank Code' value={selectedBankObj && selectedBankObj.code} {...register("bankCode")}/>
                              {errors.bankCode && (<div className="text-danger">{errors.bankCode.message}</div>) }
                          </div>
                          {/* <button className='btn btn-primary w-100 add-btn mt-4 text-light' onClick={handleShow}> Add Bank Account </button> */}
                          {!savingBankDetails && <button className='btn btn-primary w-100 add-btn mt-4 text-light'> Add Bank Account </button>}
                          {savingBankDetails && <button className="btn btn-primary w-100 add-btn mt-4 text-light" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Saving...
                          </button> }
                      </div>
                  </form>
                </div>
               </div>

             </div>
           </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        contentClassName="rounded-confirm-bank"
        centered
      >
        <Modal.Header closeButton className='border-0'>
          <Modal.Title>Confirm Bank</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form>
            <div className='form-input'>
                <label htmlFor='amount' className="bankConfirmPass mb-1 mt-5">Enter password to confirm bank</label>

                <div className='form-input-wrap'>
                    <input id="amount" className='form-control--add-bank ps-2 mt-3' placeholder='Name on Card'/>
                </div>
                <button className='btn btn-primary w-100 addbank-btn mt-4 mb-5 text-light fw-bold'>Continue</button>

            </div>
        </form>
        </Modal.Body>
      </Modal>
      <Toaster/>
  </div>
  )
}

export default AddBankAccount

import { useQuery } from "@tanstack/react-query";
// import toast from "react-hot-toast";
import { ErrorHandler } from "../../../Helpers/ErrorHandler";
import brandService from "../../../Services/brandService";

export const useFetchBrands = (
  page:number,
  sortOrder: string,
  pageSize:number,
) => {
  const context = useQuery(["fetchBrands"], () => brandService.getAll(page, sortOrder, pageSize),
    {
      enabled: true,
      retry: false,
      select: (data:{ data: { data: {dataset: {
        [x: string]: any; data:any[]
    } } }}) => data.data.data.dataset,
      onSuccess (data) {
        // toast.success("success", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      },
      onError (error: any) {
        if (Array.isArray((error as any).data.error)) {
          (error as any).data.error.forEach((el: any) =>
            ErrorHandler(error,
              () => {
                // toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
              })
          );
        } else {
          ErrorHandler(error,
            () => {
              // toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        }
      },
    }
  );

  return context;
};

import axios from 'axios'
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const brandService = {
  getAll: async (pageNumber, sortOrder, pageSize) => {
    return await axios.get(`/products/brands/all?pageNumber=${pageNumber}&sortOrder=${sortOrder}&pageSize=${pageSize}`)
  },
  getCategoryByBrand: async (brandUUid, isAddProduct = false) => {
    return await axios.get(`/products/brands/${brandUUid}?isAddProduct=${isAddProduct}`)
  },
  getBrandByCategory: async (categoryUuid, isAddProduct = false) => {
    return await axios.get(`/products/brands/${categoryUuid}?isAddProduct=${isAddProduct}`)
  },

  createBrand: async (categoryUuids, brandName) => {
    return await axios.post('/admin/brand/create', {
      name: brandName,
      categoryUuids: [
        categoryUuids
      ]
    })
  },

  file: async (brandUuid, data) => {
    return await axios.put(`/upload?fileUploadCategory=BRAND_PHOTO&entityUuid=${brandUuid}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

}
export default brandService

import React, { FC } from 'react'

import "./EnterpriseSidebarContent.scss";
import {  Link, NavLink } from 'react-router-dom'; 
import 'react-confirm-alert/src/react-confirm-alert.css';  
import { ImageSrc } from '../../../../Components'; 

import cinderBuildLogo from "../../../../Assets/Images/enterprise/cinderbuild-logo-enterprise.png"

import chartSquare from "../../../../Assets/Images/enterprise/chart-square.svg"
import bank from "../../../../Assets/Images/enterprise/bank.svg"
import dolarSquare from "../../../../Assets/Images/enterprise/dollar-square.svg"
import messageQuestion from "../../../../Assets/Images/enterprise/message-question.svg"
import repeat from "../../../../Assets/Images/enterprise/repeat.svg"
import settings from "../../../../Assets/Images/enterprise/setting-2.svg"
import shop from "../../../../Assets/Images/enterprise/shop.svg"
import shopingBag from "../../../../Assets/Images/enterprise/shopping-bag.svg" 
import archiveBook from "../../../../Assets/Images/enterprise/archive-book.svg" 
import shoppingCart from "../../../../Assets/Images/enterprise/shopping-cart.svg" 
import { BasicRBAC } from '../../../../Helpers';
import { tipsModel } from '../../../../Models';

 interface propsType {
  element?: tipsModel;
 }

const EnterpriseSidebarContent:FC<propsType> = ({element}) => {
const access = BasicRBAC


  
  return (
      <React.Fragment>
         <div className="esc">
          <ul className="nav flex-column mb-4 ps-2">
            <li className="nav-item my-1 mt-3 mb-4 ps-3 d-none d-lg-block">
              <ImageSrc src={cinderBuildLogo} alt={'CinderBuild'} title={'CinderBuild'} width={'enterprise-logo'}></ImageSrc>
            </li>
            {access('Dashboard') === true && <li className="nav-item my-1 bg-white"  ref={element?.dashboard1}>
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to="/enterprise/dashboard"> 
                <img src={chartSquare} alt="" className='icons'/>
                <span className='my-auto'>Dashboard</span>
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}
     
           
            {access('Products') === true && <li className="nav-item my-1" ref={element?.products2}>
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/enterprise/products'> 
                <img src={shopingBag} alt="" className='icons'/>
                Products 
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}
 

            {access('Warehouse-Management') === true && <li className="nav-item my-1" ref={element?.warehouseManagement3}>
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/enterprise/warehouse-management'> 
                <img src={shop} alt="" className='icons'/>
                Warehouse Management 
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}
 

            {access('Order-Management') === true && <li className="nav-item my-1"  ref={element?.orderManagement4}>
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/enterprise/order-management'> 
                 <img src={shoppingCart} alt="" className='icons'/>
                Order Management 
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> }

            {access('Order-Management') === true && <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/enterprise/quote-request'> 
                 <img src={shoppingCart} alt="" className='icons'/>
                Quote Request
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> }

          {access('Delivery-Orders') === true && <li className="nav-item my-1"  ref={element?.deliveryOrders5}>
            <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
              to='/enterprise/delivery-orders'> 
              <img src={shoppingCart} alt="" className='icons'/>
              Delivery Requests
              <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
            </NavLink>
          </li> }
            
            {access('Reorder') === true && <li className="nav-item my-1" ref={element?.reorder6}>
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/enterprise/reorder'> 
                <img src={repeat} alt="" className='icons'/> 
                Reorder
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}
            
            {access('User-Management') === true && <li className="nav-item my-1" ref={element?.userManagement7}>
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/enterprise/user-management'> 
                 <img src={archiveBook} alt="" className='icons'/>  
                User Management 
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}
 

            {access('Banking-And-Finance') === true && <li className="nav-item my-1" ref={element?.bankingAndFinance8}>
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/enterprise/banking-and-finance'> 
                <img src={bank} alt="" className='icons'/>
                Banking & Finance
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}
 

            {access('PLP') === true && <li className="nav-item my-1" ref={element?.plp9}>
              <NavLink className={({ isActive }) => isActive ? "nav-link disabled h-100" : "nav-link text-light h-100"}
                to='#'> 
                {/* to='/enterprise/plp'>  */}
                 <img src={dolarSquare} alt="" className='icons'/>
                Product Lease Program
                <i className="d-icon d-lock-close me-3 float-end my-auto position-absolute ms-3"></i>
              </NavLink>
            </li>}
            
            {access('Settings') === true && <li className="nav-item my-1" ref={element?.settings10}>
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/enterprise/settings'> 
                <img src={settings} alt="" className='icons'/> 
                Settings
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> }
 
            <ul className="nav flex-column mt-5 message-container"> 
              <li className="nav-item my-1">
                <div className='message text-center w-100'>
                  <div className="icon"> 
                    <div className="icon-wrapper">
                       <img src={messageQuestion} alt="" className='icons'/> 
                    </div>
                  </div>
                  <div className="heading">Support Center</div>
                  <div className='desc'>Need help navigating your corporate account? Please contatct us for support </div>
                  <Link to="/enterprise/support-center"><button className='btn btn-outline-warning'  ref={element?.callCenter11}>Get Support</button></Link>
                </div>
              </li>
            </ul> 
          </ul> 
        </div> 
     </React.Fragment>

  )
}

export default EnterpriseSidebarContent

import React, { FC, PropsWithChildren } from 'react'

import "./ProductItemSkeleton.scss"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

interface propsType {
  cardCount: number
}
const ProductItemSkeleton:FC<propsType> = ({ cardCount }) => {
  function Box ({ children }: PropsWithChildren<unknown>) {
    return (
        <div
            style={{
              border: '1px solid #fff',
              display: 'block',
              lineHeight: 2,
              padding: '0rem',
              marginBottom: '0.1rem',
              width: "100%",
            //   height: 137,
            }}
        >
            {children}
        </div>
    )
  }

  return (
    <React.Fragment>
   { [...Array(cardCount)].map((res:any, index:React.Key) => <div key={index} className="productItemSkeleton col-6 col-sm-6 col-md-6 col-lg-2 p-1">
        <div className="card product-card border-0 shadow-sm">
            <div className="card-body px-0">
                 <div className="container-fluid">
                    <div className="row p-0">
                        <div className="col-6">
                           <Skeleton wrapper={Box} count={1} height={30} width={30}/>
                        </div>
                        <div className="col-6 text-end">
                           <Skeleton wrapper={Box} count={1} height={30} width={30}/>
                        </div>
                        <div className="col-12">
                            <Skeleton wrapper={Box} count={1} height={10} width={30}/>
                            <Skeleton wrapper={Box} count={1} height={100} width={'220px'}/>
                        </div>
                        <div className="col-12">
                          <h4 className="product-title">
                            <Skeleton wrapper={Box} count={1} height={20}/>
                          </h4>
                        </div>
                        <div className="col-8">
                            <div className="price">
                                <div className="old-price">
                                    <Skeleton wrapper={Box} count={1} height={8} width={30}/>
                                </div>
                                <div className="new-price">
                                    <Skeleton wrapper={Box} count={1} height={8} width={30}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="rating">
                                <Skeleton wrapper={Box} count={1} height={8} width={30}/>
                            </div>
                        </div>
                        <div className="col-8 mt-2">
                            <div className="company-name">
                                <Skeleton wrapper={Box} count={1} height={8} width={90}/>
                            </div>
                        </div>
                        <div className="col-4 mt-2">
                            <div className="location">
                                <Skeleton wrapper={Box} count={1} height={8} width={30}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)}
    </React.Fragment>
  )
}

export default ProductItemSkeleton

import React from 'react'
import SellerNavbar from '../NavMenu/SellerNavbar'
import "./SellerHeader.scss"

const SellerHeader = () => {
    return (
        <div className='seller-header d-none d-lg-block'>
            <SellerNavbar/>
        </div>
    )
}

export default SellerHeader

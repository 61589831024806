import React from 'react'
import "./BuyerDashboard.scss"

const BuyerDashboard = () => {
  return (
        <div className='buyerDashboard'>

        </div>
  )
}

export default BuyerDashboard

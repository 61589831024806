import React from 'react'
import "./Testimony.scss"
import testimonyUser from "../../../../../Assets/Images/kelex.jpeg"
import TestimonyAds from './TestimonyAds/TestimonyAds'

const Testimony = () => {
  return (
    <div className='home-testimony'>
        <div className="container h-100">
            <div className="row h-100">
                <div className="col-lg-6 position-relative">
                    <TestimonyAds></TestimonyAds>
                </div>
                <div className="col-lg-6">
                    <div className="testimony">
                        <div className="card py-4 ps-3">
                            <div className="card-header bg-white border-0">
                               TESTIMONIAL
                            </div>
                            <div className="card-body pb-5">
                            CinderBuild has contributed to the growth of our sales in general. It has been an interesting and remarkable journey so far and we are sincerely appreciative of the contribution of your online platform to the outreach created for our products. However, we look forward to doing more business with you.
                            </div>
                            <div className="card-footer bg-white border-0">
                                <div className="row">
                                    <div className="col-3 col-md-2">
                                        <img src={testimonyUser} className="rounded-circle avarter" alt=""/>
                                    </div>
                                    <div className="col-9 col-md-5">
                                        <b>Kelex Mega Investment LTD</b>
                                        <div><small>Manager</small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Testimony

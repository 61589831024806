/* eslint-disable no-unused-vars */
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import "./BusinessBasicInfoBody.scss";
import SecuritySafeOutlineIcon from "../../../../../../../Assets/Images/icon/security-safe-outline.svg";


interface propType {
    businessName: string;
    businessAddress: string;
    cacNumber: string
}

const BusinessBasicInfoBody: FC<propType> = ({ businessName, businessAddress, cacNumber }) => {
    const defaultValues = {
        businessName, businessAddress, cacNumber
    }
    const [activate, setActivate] = useState(true)

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<propType>({
        defaultValues
    });

    const onSubmit = handleSubmit((data) => {
        setActivate(true)
    });

    return (
        <div className="business-basic-info">
            <form className="form" onSubmit={onSubmit}>
                <div className="form-group my-3">
                    <label htmlFor="emailAddeess"> Business Name</label>
                    <input
                        className="form-control"
                        type="text"
                        placeholder={businessName}
                        {...register("businessName")}
                    />
                    <div className="text-danger">
                        {" "}
                        {errors.businessName && errors.businessName.message}{" "}
                    </div>
                </div>

                <div className="form-group mt-4 ">
                    <label htmlFor="businessAddress"> Business Address</label>
                    <input
                        className="form-control"
                        type="text"
                        placeholder={businessAddress}
                        {...register("businessAddress")}
                    />
                    <div className="text-danger">
                        {" "}
                        {errors.businessAddress && errors.businessAddress.message}{" "}
                    </div>

                </div>
                <div className="form-group mt-4 ">
                    <label htmlFor="businessAddress"> Business CAC</label>
                    <input
                        className="form-control"
                        type="text"
                        placeholder={cacNumber}
                        {...register("cacNumber")}
                    />
                    <div className="text-danger">
                        {" "}
                        {errors.cacNumber && errors.cacNumber.message}{" "}
                    </div>

                </div>
                <div className="form-group mt-5 text-center">

                    <button
                        className="form-control submit"
                        type="submit"
                    >
                        Save Profile
                    </button>
                </div>
            </form>
        </div>
    );
};

export default BusinessBasicInfoBody;

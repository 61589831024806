import React from 'react'
import "./BreadCrumb.scss"

const BreadCrumb = () => {
  return (
        <div className="container my-2">
            <div className="ps-2">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">Category </a></li>
                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">Electric cables </a></li>
                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">Armoured cable</a></li>
                    </ol>
                </nav>
            </div>
        </div>
  )
}

export default BreadCrumb

import "./BuyerPageTitleWithoutBreadcumbs.scss";
import {FC} from "react";

interface propTypes {
    title:string;
}

const BuyerPageTitleWithoutBreadcumbs: FC<propTypes> = ({title})=>{
    return (
        <h2 className="buyer-page-title-without-breadcumbs">
            {title}
        </h2>
    )
} 

export default BuyerPageTitleWithoutBreadcumbs;
import React, { FC } from 'react';
import "./SellerPageOrderStatus.scss";

interface propTypes {
    status:string;
}

const SellerPageOrderStatus:FC<propTypes> = ({ status }) => {
    return (
        <>
            {  
                (status === "CONFIRMED" || status === "COMPLETED") && 
                <div className='order-status bg-custom-success text-success'>
                    {status?.replace('_', ' ')}
                </div>
            }
            {  
                (status === "CANCELLED_BY_BUYER" || status === "CANCELLED_BY_SELLER" ||  
                status === "ENDED_WITH_DISPUTES") && 
                <div className='order-status bg-custom-danger text-danger'>
                    {status?.replace('_', ' ')}
                </div>
            }
            {  
                (status === "IN_PROGRESS" || status === "AVAILABLE_FOR_PICKUP") && 
                <div className='order-status bg-custom-warning text-warning'>
                    {status?.replace('_', ' ')}
                </div>
            }
        </>
    )
}

export default SellerPageOrderStatus;

import { REG_STEP } from "../../Constants/SellerDocuments";

const initialState = {
   step: 1
}


const StepRegReducer = (state = initialState, action: { type: string; payload: any; }) => {
    switch (action.type) {
        case REG_STEP: {
            return { ...state, step: action.payload };
        }
        default:
            return state
    }
}

export default StepRegReducer
import React, { FC, useEffect, useState } from 'react'
import "./Revenue.scss"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
// import { faker } from '@faker-js/faker';

import { Bar } from 'react-chartjs-2';
import {MiniLoader} from '../../../../../../../Components';

interface RevenuePropTypes {
  statsData: any;
  statsLoaading: boolean
}
const Revenue: FC<RevenuePropTypes> = ({ statsData, statsLoaading }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const yearArr = [`${selectedYear}-01`, `${selectedYear}-02`, `${selectedYear}-03`, `${selectedYear}-04`, `${selectedYear}-05`, `${selectedYear}-06`, `${selectedYear}-07`, `${selectedYear}-08`, `${selectedYear}-09`, `${selectedYear}-10`, `${selectedYear}-11`, `${selectedYear}-12`];

  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const [filteredDatas, setFilteredDatas] = useState([])

  const [datas, setDatas] = useState<any>({
    labels,
    datasets: [],
  })
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        display: false, 
       
      },
      
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
      label: {
        display: false,
      },
    },

    scales: {
      y: {
        display: false,
        grid: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        }
      }, 
    } 
  };

  useEffect(() => {
    if (statsLoaading === false) {
      setDatas({
        labels,
        datasets: [
          {
            label: '',
            // data: labels.map((res: any, index: any) => filteredDatas[index]),
            data: [2, 5, 6, 9, 6, 2, 7, 3, 8, 5, 7, 9],
            backgroundColor: '#34BA96',
            borderRadius: 4,
            barPercentage: 0.4,
            border: 0,
            font: {
              size: 1
            }

          }
        ],
      })
    }
  }, [statsData, filteredDatas])

  const yearSelection = (e: { target: { value: any; }; }) => {
    setSelectedYear(e.target.value)
  }

  const getYearData = () => {
    const dataSet: any = [];
    yearArr.forEach((v, index) => {
      const c = statsData?.monthEarnings.filter((res: { monthISO8601: any; }) => {
        return res.monthISO8601 === v
      })

      if (c.length > 0) {
        dataSet.push(c[0].totalEarningsMajor)
        // dataSet.push({
        //   friendlyMonth: labels[index],
        //   monthISO8601: `${selectedYear}-0${index + 1}`,
        //   totalEarningsMajor: c[0].totalEarningsMajor
        // })
      } else {
        dataSet.push(0)
        // dataSet.push({
        //   friendlyMonth: labels[index],
        //   monthISO8601: `${selectedYear}-0${index + 1}`,
        //   totalEarningsMajor: 0
        // })
      }
    })
    setFilteredDatas(dataSet)
  }

  useEffect(() => {
    getYearData()
  }, [selectedYear, statsData])

  return (
    <div className='revenue'>
      <MiniLoader show={statsLoaading}></MiniLoader>
      <div className="card shadow-0 border">
        <div className="card-header ">
          <div className="row pt-2">
            <div className="col-8 col-lg-9 sales">
              Sales performance
            </div>
            <div className="col-4 col-lg-3 text-end ">
              <select className='select-control' onChange={yearSelection}>
                <option>This year</option>
                <option>2022</option>
              </select>
            </div>
          </div>
        </div>
        <div className="card-body col-12 w-100">
          <Bar options={options} data={datas} />
        </div>
      </div>
    </div>
  )
}

export default Revenue

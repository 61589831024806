import React, { useEffect, useState } from "react";
import "./EnterpriseSettingsAddressBook.scss"
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast"; 
import userService from "../../../../../../../Services/userService";
import country from "../../../../../../../Data/country.json"
import state from "../../../../../../../Data/stateNG.json" 
import { Modal } from "react-bootstrap";
import { SubPageTitle } from "../../../../Buyer/Component";
import { ErrorHandler } from "../../../../../../../Helpers/ErrorHandler";
 
 type formProp = {
  contactFullName:string;
  contactPhoneNumber:string;
  country:string;
  state:string;
  address:string;
  uuid:any;
  isDefault:boolean|undefined;
 }

const EnterpriseSettingsAddressBook = ()=>{  
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false); 
  const [sendingRequestEdit, setSendingRequestEdit] = useState(false); 
  
  const [fetchingRequest, setFetchingRequest] = useState(false);
  const [deletingRequest, setDeletingRequest] = useState<boolean|string>(false);

  const [dataRes, setDataRes] = useState([]); 

  const handleClose = () => setShow(false); 


  const [dataResEdit, setDataResEdit] = useState<formProp>(); 
  const handleCloseEdit = () => setShowEdit(false); 
 
  const handleShow = () => setShow(true);

  const [selectStates, setSelectedStates] = useState<any>([]) 
  const [selectedCountry, setCountry] = useState<any>({
    id: "",
    sortname: "",
    name: "",
    phoneCode: ""
  })

  const handleCountryChange = (e:any) => {
    const allState = state[2].data

    setSelectedStates(allState)

    const cont = JSON.parse(e.target.value)
    setCountry(cont)
  }

  const { register, handleSubmit, formState: { errors } } = useForm<formProp>({ defaultValues: { isDefault: false } });

  const { register: register2, handleSubmit: handleSubmit2, setValue, formState: { errors: errors2 } } = useForm<formProp>({ defaultValues: { isDefault: false } });

  const submit = handleSubmit(async (data:any) => {
    setSendingRequest(true)
    await userService.buyerDeliveryLocation({ ...data, country: selectedCountry.name })
      .then(res => {
        setSendingRequest(false)
        handleClose()
        toast.success("location updated", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        getAddress()
      }, (error:any) => {
        setSendingRequest(false)
        console.log("buyerPickUpLocation", error.response)
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  });

  const getAddress = async () => {
    setFetchingRequest(true)
    await userService.getBuyerDeliveryLocation()
      .then((res) => {
        setFetchingRequest(false)
        // console.log("rr",res.data.data)
        setDataRes(res.data.data)
      }, (error:any) => {
        setFetchingRequest(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  const deleteAddress = async (uuid: string) => {
    setDeletingRequest(uuid)
    const deleting = toast.loading("Deleting address...")
    await userService.deleteBuyerDeliveryLocation(uuid)
      .then((res) => {
        setDeletingRequest(false)
        toast.success("Deleted Successfully", { duration: 20000, className: 'bg-white text-dark' });
        toast.dismiss(deleting)
        getAddress()
      }, (error:any) => {
        toast.dismiss(deleting)
        setDeletingRequest(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    getAddress();
  }, [])

  useEffect(() => { 
    if (dataResEdit) {
      console.log("dataResEdit", dataResEdit)
      setValue("contactFullName", dataResEdit?.contactFullName)
      setValue("contactPhoneNumber", dataResEdit?.contactPhoneNumber )
      setValue("country", dataResEdit?.country)
      setValue("state", dataResEdit?.state)
      setValue("address", dataResEdit?.address)
      setValue("isDefault", dataResEdit?.isDefault)
      
    }
  }, [dataResEdit])

  



  const submit2 = handleSubmit2(async (data:any) => {
    console.log("handleSubmit2", data)
    setSendingRequestEdit(true)
    await userService.buyerDeliveryLocationEdit(dataResEdit?.uuid, { ...data, country: selectedCountry.name })
      .then(res => {
        setSendingRequestEdit(false)
        handleCloseEdit()
        toast.success("location added", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        getAddress()
      }, (error:any) => {
        setSendingRequestEdit(false) 
        ErrorHandler(error, (e)=>{ 
          toast.error(e, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
      })
  });
  
    return  (
       <div className="buyer-profile-page">
        <div className="row">
          <div className="col-8 col-md-9"> 
              <SubPageTitle
                    title="Address Book"
                    parents={[
                        {
                            title:"My Account",
                            url: "my-account"
                        }
                    ]}
                />
          </div>
          <div className="col-4 col-md-3 d-flex justify-content-center text-end align-items-end">
                <button className='btn btn-outline-primary btn-sm addnew-address d-md-none' onClick={handleShow}><i className='fa fa-plus'></i> Add</button>
                <button className='btn btn-outline-primary btn-sm addnew-address btn-addnew d-none d-md-block' onClick={handleShow}><i className='fa fa-plus'></i>  Add new address </button> 
          </div>
        </div>
            <div className="address-book">
                <div className="profile-item-container mb-4"> 
                      <div className='row mb-5'>
                            {!fetchingRequest && dataRes.length > 0 && dataRes.map((res:{name:string, isDefault:boolean; state:string, contactFullName:string, country:string, contactPhoneNumber:string, address:string, uuid: string}, index:React.Key) => {
                              return <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3">
                              <div className="card">
                                <div className="card-header border-0 pt-4 bg-white rounded fw-bold"> 
                                  <div className="row">
                                    <div className="col-md">
                                      <div className="item-count">{Number(index) + 1}</div>
                                    </div>
                                    <div className="col-md">
                                      {res.isDefault ===true && <div className="default-item">Default Address</div>}
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body"> 
                                  <div className="mb-4 fAddres">{res.address +","+ res.state +", "+ res.country}</div> 
                                  <div> 
                                    <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12.1605 11.62C12.1305 11.62 12.1105 11.62 12.0805 11.62C12.0305 11.61 11.9605 11.61 11.9005 11.62C9.00055 11.53 6.81055 9.25 6.81055 6.44C6.81055 3.58 9.14055 1.25 12.0005 1.25C14.8605 1.25 17.1905 3.58 17.1905 6.44C17.1805 9.25 14.9805 11.53 12.1905 11.62C12.1805 11.62 12.1705 11.62 12.1605 11.62ZM12.0005 2.75C9.97055 2.75 8.31055 4.41 8.31055 6.44C8.31055 8.44 9.87055 10.05 11.8605 10.12C11.9105 10.11 12.0505 10.11 12.1805 10.12C14.1405 10.03 15.6805 8.42 15.6905 6.44C15.6905 4.41 14.0305 2.75 12.0005 2.75Z" fill="#333333"/>
                                          <path d="M12.1696 22.55C10.2096 22.55 8.23961 22.05 6.74961 21.05C5.35961 20.13 4.59961 18.87 4.59961 17.5C4.59961 16.13 5.35961 14.86 6.74961 13.93C9.74961 11.94 14.6096 11.94 17.5896 13.93C18.9696 14.85 19.7396 16.11 19.7396 17.48C19.7396 18.85 18.9796 20.12 17.5896 21.05C16.0896 22.05 14.1296 22.55 12.1696 22.55ZM7.57961 15.19C6.61961 15.83 6.09961 16.65 6.09961 17.51C6.09961 18.36 6.62961 19.18 7.57961 19.81C10.0696 21.48 14.2696 21.48 16.7596 19.81C17.7196 19.17 18.2396 18.35 18.2396 17.49C18.2396 16.64 17.7096 15.82 16.7596 15.19C14.2696 13.53 10.0696 13.53 7.57961 15.19Z" fill="#333333"/>
                                          </svg>{res.contactFullName+","+ res.contactPhoneNumber}
                                  </div> 
                                </div>
                                <div className="card-footer bg-white text-start p-0 py-2">
                                  {deletingRequest && deletingRequest === res.uuid && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                  {deletingRequest !== res.uuid && <button className="btn text-primary" onClick={()=>{ setShowEdit(true); setDataResEdit(res) }}> Edit</button>}
                                  {deletingRequest !== res.uuid && <button onClick={() => deleteAddress(res.uuid)} className="btn text-primary"> Delete</button>}
                                </div>
                              </div>
                            </div>
                            })}
                            
                            {sendingRequest === false && dataRes.length === 0 && <div className="col-md-12 text-center text-danger">
                                    No location found
                          </div>}
                            {sendingRequest === true && dataRes.length === 0 && <div className="col-md-12 text-center text-danger">
                                  <span className="spinner-border spinner-border-sm"></span>
                          </div>}
                        </div>
                          </div>
                      </div> 


                      <Modal
                          show={show}
                          onHide={handleClose}
                          backdrop="static"
                          keyboard={false}
                          contentClassName="addNewAddressBookModal"
                        >
                          <form onSubmit={submit} >
                            <Modal.Header closeButton>
                              <Modal.Title>Add new location</Modal.Title>
                            </Modal.Header>
                            <Modal.Body
                          className="rounded">
                            
                            <div className='form-group mt-3'>
                                  <label htmlFor='address'>Street address </label>
                                  <textarea className='form-control fc textarea-pk' {...register('address', { required: true })} rows={4} cols={50} id="address" placeholder='e.g 45 Royal Palmswill Estate, Badore Ajah, Lagos, Nigeria.'></textarea>
                                  {errors.address && <div className='text-danger'>{errors.address.message}</div>}
                                </div>

                                <div className="row">
                                    <div className="col-md">  
                                      <div className='form-group mt-2'>
                                        <label htmlFor='name'>Country</label>
                                            <select className='form-control' {...register("country", { required: "This field is required", onChange: (e:any) => handleCountryChange(e) })}>
                                                <option>--Country--</option>
                                                {country.countries.length > 0 && country.countries.map((res:any, index:React.Key) => {
                                                  return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                                                })}
                                            </select>
                                            {errors.country && <div className='text-danger'>{errors.country.message}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md"> 
                                      <div className='form-group mt-2'>
                                         <label htmlFor='name'>State</label>
                                          <select className='form-control' {...register("state", { required: "This field is required" })}>
                                          <option>--State--</option>
                                          {selectStates.map((result: { id: number ; name: string }, index: React.Key) => {
                                            return <option key={index} value={result.name}>{result.name}</option>
                                          })}
                                          </select>
                                          {errors.state && <div className='text-danger'>{errors.state.message}</div>}
                                      </div>
                                  </div>
                                </div>

                                
                                <div className="contact-title">
                                   Who to contact for delivery
                                </div>

                                <div className="row">
                                  <div className="col-md"> 
                                    <div className='form-group mt-2'>
                                      <label htmlFor='contactFullName'>Contact name </label>
                                      <input className='form-control fc' id="contactFullName" {...register('contactFullName', { required: true })} placeholder='e.g John Doe'></input>
                                      {errors.contactFullName && <div className='text-danger'>{errors.contactFullName.message}</div>}
                                    </div> 
                                  </div>
                                  <div className="col-md">
                                    <div className='form-group mt-2'>
                                      <label htmlFor='name'>Contact phone number</label>
                                      <input className='form-control fc' id="name" {...register('contactPhoneNumber', { required: true })} placeholder=''></input>
                                      {errors.contactPhoneNumber && <div className='text-danger'>{errors.contactPhoneNumber.message}</div>}
                                    </div>
                                  </div>
                                </div>


                                <div className='form-group mt-3 defaultaddress-row'>
                                  <input type="checkbox" id="defaultAddress" className='checkbox-pk' {...register('isDefault')} />  
                                  <label htmlFor='defaultAddress' className="d-flex align-items-center pt-2">Make Default Address </label>
                                  {errors.isDefault && <div className='text-danger'>{errors.isDefault.message}</div>}
                                </div>

                            </Modal.Body>
                            <Modal.Footer>

                                {!sendingRequest && (<button type="submit" className="btn btn-primary w-100 border-0">Save Address</button>)}
                                {sendingRequest && (<button type="submit" className="btn btn-primary border-0" disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>)}

                            </Modal.Footer>
                          </form>
                      </Modal>


 

                      <Modal
                          show={showEdit}
                          onHide={handleCloseEdit}
                          backdrop="static"
                          keyboard={false}
                          contentClassName="addNewAddressBookModal"
                        >
                          <form onSubmit={submit2} >
                            <Modal.Header closeButton>
                              <Modal.Title>Edit location</Modal.Title>
                            </Modal.Header>
                            <Modal.Body
                          className="rounded"> 
                            
                            <div className='form-group mt-3'>
                                  <label htmlFor='address2'>Street address </label>
                                  <textarea className='form-control fc textarea-pk' {...register2('address', { required: true })} rows={4} cols={50} id="address2" placeholder='e.g 45 Royal Palmswill Estate, Badore Ajah, Lagos, Nigeria.'></textarea>
                                  {errors2.address && <div className='text-danger'>{errors2.address.message}</div>}
                                </div>

                                <div className="row">
                                    <div className="col-md">  
                                      <div className='form-group mt-2'>
                                        <label htmlFor='name'>Country</label>
                                            <select className='form-control' {...register2("country", { required: "This field is required", onChange: (e:any) => handleCountryChange(e) })}>
                                                <option>--Country--</option>
                                                {country.countries.length > 0 && country.countries.map((res:any, index:React.Key) => {
                                                  return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                                                })}
                                            </select>
                                            {errors2.country && <div className='text-danger'>{errors2.country.message}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md"> 
                                      <div className='form-group mt-2'>
                                         <label htmlFor='name'>State</label>
                                          <select className='form-control' {...register2("state", { required: "This field is required" })}>
                                          <option>--State--</option>
                                          {selectStates.map((result: { id: number ; name: string }, index: React.Key) => {
                                            return <option key={index} value={result.name}>{result.name}</option>
                                          })}
                                          </select>
                                          {errors2.state && <div className='text-danger'>{errors2.state.message}</div>}
                                      </div>
                                  </div>
                                </div>

                                
                                <div className="contact-title">
                                   Who to contact for delivery
                                </div>

                                <div className="row">
                                  <div className="col-md"> 
                                    <div className='form-group mt-2'>
                                      <label htmlFor='contactFullName'>Contact name </label>
                                      <input className='form-control fc' id="contactFullName" {...register2('contactFullName', { required: true })} placeholder='e.g John Doe'></input>
                                      {errors2.contactFullName && <div className='text-danger'>{errors2.contactFullName.message}</div>}
                                    </div> 
                                  </div>
                                  <div className="col-md">
                                    <div className='form-group mt-2'>
                                      <label htmlFor='name'>Contact phone number</label>
                                      <input className='form-control fc' id="name" {...register2('contactPhoneNumber', { required: true })} placeholder=''></input>
                                      {errors2.contactPhoneNumber && <div className='text-danger'>{errors2.contactPhoneNumber.message}</div>}
                                    </div>
                                  </div>
                                </div>


                                <div className='form-group mt-3 defaultaddress-row'>
                                  <input type="checkbox" id="defaultAddress" {...register2('isDefault')} className='checkbox-pk' />  
                                  <label htmlFor='defaultAddress' className="d-flex align-items-center pt-2">Make Default Address </label>
                                  {errors2.isDefault && <div className='text-danger'>{errors2.isDefault.message}</div>}
                                </div>

                            </Modal.Body>
                            <Modal.Footer>

                                {!sendingRequestEdit && (<button type="submit" className="btn btn-primary w-100 border-0">Update Address</button>)}
                                {sendingRequestEdit && (<button type="submit" className="btn btn-primary border-0" disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>)}

                            </Modal.Footer>
                          </form>
                      </Modal>

                <Toaster/> 
       </div>
    )

}

export default EnterpriseSettingsAddressBook;
import React, { FC } from 'react'
import "./ProductItem.scss"

import { Link } from 'react-router-dom';
import AddWishList from '../AddWishList/AddWishList'
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';
import { CurrencyFormatter } from '../../../../../Helpers';

interface propsType {
    data: {
        productUuid: string;
        productName: string;
        productDescription: string;
        minimumQuantity: number;
        maximumQuantity: number;
        unitOfMeasurement: string;
        totalNumberOfRatings: string;
        images:any[];
        sellerPublicProfile: {
            businessProfile: {
                businessName: string;
                usinessAddress: string;
            };
            photoUrl:string;

        };
        unitPriceForBuyer:number;
        unitPromoPriceForBuyer:number;
        currencySymbol:string;

    }
}
const ProductItemNoCol:FC<propsType> = ({ data }) => {
  return (
        <div className="card product-card border-0 shadow-sm">
            <div className="card-body px-0">
                <div className="container-fluid">
                    <div className="row p-0">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3">
                                    <div className="brand-logo-cont">
                                        <img src={data.sellerPublicProfile.photoUrl} className="brand-logo rounded" alt=""/>
                                    </div>
                                </div>

                                <div className="col-9 text-end">
                                    <AddWishList productUuid={data.productUuid}></AddWishList>
                                </div> 
                            </div>
                        </div>
                        
                        <div className="col-12 product-image-outter">
                            <div className="promo d-none">
                                20% off
                            </div>
                            <div className="product-image-container">
                                <Link to={`/product/${data.productUuid}`}>
                                <ImageSrc src={data?.images[0]?.url} alt={data.productName} title={data.productName} width="product-image mt-3 w-100"></ImageSrc>
                                </Link>
                            </div>
                        </div>

                        <div className="col-12">
                            <Link to={`/product/${data.productUuid}`} className='text-decoration-none'>
                                <h4 className="product-title product-title-related" style={{height:"40px"}}>
                                    {data.productName}
                                </h4>
                            </Link>
                        </div>

                        <div className="col-8">
                            {data.unitPromoPriceForBuyer !== 0
                              ? (<div className="price">
                                    <div className="old-price">
                                    {data.currencySymbol}{data.unitPromoPriceForBuyer}
                                    </div>
                                    <div className="new-price">
                                    {data.currencySymbol}{CurrencyFormatter(data.unitPriceForBuyer)} 
                                    </div>
                                </div>)
                              : (<div className="price">
                                    <div className="new-price">
                                    {data.unitPriceForBuyer !==0? <>{data.currencySymbol}{CurrencyFormatter(data.unitPriceForBuyer)}</>:<>
                                        <Link to={`/product/${data.productUuid}`} className='new-price text-decoration-none'>
                                            Request quote
                                        </Link>
                                    </>}
                                    </div>
                                </div>)}
                        </div>

                        <div className="col-4">
                            {data.totalNumberOfRatings && <div className="rating">
                                <span className="d-icon d-star"></span>
                                {data.totalNumberOfRatings}/5
                            </div>}
                        </div>

                        <div className="col-8 mt-2">
                            <div className="company-name">
                               {data.sellerPublicProfile?.businessProfile?.businessName}
                            </div>
                        </div>

                        <div className="col-4 mt-2">
                            <div className="location">
                                <span className="d-icon d-location"></span>
                                Lagos
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ProductItemNoCol


import { Toaster, toast } from "react-hot-toast";
import BuyerPageTitleWithoutBreadcumbs from "../../../../../../../Components/Buyer/BuyerPageTitleWithougtBreadcumbs/BuyerPageTitleWithoutBreadcumbs";
import { useState, useEffect } from "react";
import bankingFinanceService from "../../../../../../../Services/bankingFinanceService";
import { Link } from "react-router-dom"; 
import productFinancingService from "../../../../../../../Services/productFinancingService"
import { Logger } from "../../../../../../../Helpers/Logger"; 
import BankAccount from "../../../../Buyer/Component/PLP/BankAccount/BankAccount";
import { AccountBalance } from "../../../../Buyer/Component";
import History from "../../../../Buyer/Component/PLP/History/History";
import "./EnterprisePLPMainPage.scss"; 

const EnterprisePLPMainPage = ()=>{

    const [loadingRequest, setLoadingRequest] = useState(false);
    const [myBalance, setMyBalance] = useState({
        currency: "",
        currencySymbol: "",
        amountMajor: ""
    })

    const [leaseStatusDetails, setLeaseStatusDetails] = useState({
        uuid: "",
        principalAmountMajor: 0,
        interestRatePercentage: 0,
        nextLeasePaymentDueDateUtc: "",
        totalLoanAmountDueMajor: 0,
        currency: "",
        createdAtUtc: "",
        creditScore: 0
    })
 
    const [uuid, setUuid] = useState('');

    const getMyBalance = async () => {
        // setLoadingRequest(true)
        await bankingFinanceService.getMyBalance()
          .then((res:any) => {
            // setLoadingRequest(false)
            setMyBalance(res.data.data)
            Logger("My balance", res.data.data)
          }, (error:any) => {
            // setLoadingRequest(false)
            toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }

      const getProductLeaseStatus = async () => {
        setLoadingRequest(true)
        await productFinancingService.getStatus()
          .then((res:any) => { 
            Logger("productFinancingService", res)
            setLoadingRequest(false)
            setLeaseStatusDetails(res.data.data)
            setUuid(res.data?.data?.uuid) 
          }, (error:any) => {
            setLoadingRequest(false)
            toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    
      useEffect(() => {
        getMyBalance()
        getProductLeaseStatus();
      }, [])
 
     Logger("leaseStatusDetails", leaseStatusDetails)
    return (
        <div className='enterprisePLPMainPage'>
            <div className="container mt-4">
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <BuyerPageTitleWithoutBreadcumbs title={'Product Leasing Program'}/>
                <Link to='apply'><button className="btn plp-apply-btn">Apply for PLP</button></Link>
            </div>

            <div className="row mt-4">
            {(leaseStatusDetails?.uuid ==="" || leaseStatusDetails === null) && <div className="col-12 noPLP">
                 No approved application yet
              </div>}

            {(loadingRequest===false && leaseStatusDetails !== null) && leaseStatusDetails.totalLoanAmountDueMajor !==0 &&  <div className="col-12 col-md-7">
                    <BankAccount
                        principalAmount={leaseStatusDetails?.principalAmountMajor}
                        dateOpened= {leaseStatusDetails?.createdAtUtc}
                        currency={leaseStatusDetails?.currency}
                        amountDue={leaseStatusDetails?.totalLoanAmountDueMajor}
                        nextPaymentDueDate= {leaseStatusDetails?.nextLeasePaymentDueDateUtc}
                    ></BankAccount>
                </div>}

                {(loadingRequest===false && leaseStatusDetails !== null) && leaseStatusDetails.totalLoanAmountDueMajor !==0 && <div className="col-12 col-md-5 mt-4 mt-lg-0">
                    <AccountBalance myBalance={myBalance}></AccountBalance>
                </div>}

                  <div className='col-12'>
                    <div className="my-5 history-container">
                        <History uuid={uuid}></History>
                    </div>
                </div>
            </div>
            <Toaster></Toaster>
        </div>
    </div>
    )

}

export default EnterprisePLPMainPage;
import React, { useEffect, useState, useMemo } from 'react'
import "./SellerOrdersDetails.scss"
import orderService from '../../../../../../Services/orderService';
import { Logger } from '../../../../../../Helpers/Logger';
import { toast, Toaster } from 'react-hot-toast';
import { NavLink, useParams } from 'react-router-dom';
import { TimeConverter } from '../../../../../../Helpers/TimeConverter';
import { NumberWithComma } from '../../../../../../Helpers/NumberWithCommer';
import SellerPageOrderStatus from '../../../../../../Components/Seller/SellerPageOrderStatus/SellerPageOrderStatus';
import SellerPageOrderSummaryItem from '../../../../../../Components/Seller/SellerPageOrderSummaryItem/SellerPageOrderSummaryItem';
import SellerPageOrderPaymentMethod from '../../../../../../Components/Seller/SellerPageOrderPaymentMethod/SellerPageOrderPaymentMethod';
import SellerPageOrderDeliveryAddress from '../../../../../../Components/Seller/SellerPageOrderDeliveryAddress/SellerPageOrderDeliveryAddress';
import SellerPageTitle from '../../../../Components/Seller/SellerPageTitle/SellerPageTitle';
import SellerPageOrderContactDetails from '../../../../../../Components/Seller/SellerPageOrderContactDetails/SellerPageOrderContactDetails';
 
const SellerOrdersDetails = () => {
  const { orderId } = useParams<{ orderId: string }>()
  // eslint-disable-next-line no-unused-vars
  const [orderDetails, setOrderdetails] = useState<any>(null)
  // eslint-disable-next-line no-unused-vars
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)

  const getOrder = async (orderId: string | undefined) => {
    setLoadingRequest(true)
    await orderService.getSingleOrder(orderId).then((res) => {
      setOrderdetails(res.data.data)
      Logger("setOrderdetails", res.data)
      setLoadingRequest(false)
    }, (error: any) => {
      setLoadingRequest(false)
      Logger("orderdetails error", error.response)
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }

  useEffect(() => {
    getOrder(orderId)
  }, [])

  const totalCost = useMemo(() => {
    // eslint-disable-next-line
    return orderDetails?.deliveryCostMajor || 0 + orderDetails?.calculatedTotalCostMajor || 0
  }, [orderDetails])

  console.log("orderId", orderId)

  return (
    <div className='seller-order-details'>
      <div className="container mt-4">
        <div className='d-flex mb-5 justify-content-between align-items-center'>
          <SellerPageTitle title={'Order details'} parent={'My orders'} parentUrl={'my-orders'} variant='true'></SellerPageTitle> {/* eslint-disable-next-line */}
          <div className="d-flex flex-column flex-md-row">
            {/* {orderDetails?.status &&  */}
            <div className='mt-2 order-2 order-md-1 mb-md-0'>
              <SellerPageOrderStatus status={orderDetails?.status} />
            </div>
            {/* // } */}
            <NavLink className="order-1 order-md-2 seller-order-details-navlink ms-4" to={`/seller/my-orders/order-track/${orderId}`}>
              <button className='btn-track'>Track order</button>
            </NavLink>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="card order-summary">
              <div className="card-header card-custom-header bg-white">
                <div className="row">
                  <div className="col-sm-6">
                    <p>Order summary</p>
                    <p className='fw-normal'>{orderDetails?.referenceNumber}</p>
                  </div>
                  <div className="col-sm-6 text-end order-time text-end">{TimeConverter(orderDetails?.createdAt)}</div>
                </div>
              </div>
              <div className="card-body">
                {/* eslint-disable-next-line */}
                <ul className="list-group list-group-flush">
                  {/* eslint-disable-next-line */}
                  {loadingRequest &&
                    <div className='text-secondary text-center fw-bold'>
                      <span className='spinner-border spinner-border-lg'></span>
                    </div>
                  }
                  {
                    orderDetails?.orderItems?.map((orderItem: any) => {
                      return (
                        <SellerPageOrderSummaryItem
                          imageUrl={orderItem.images[0].url}
                          name={orderItem.productName}
                          quantity={orderItem.quantity}
                          amount={orderItem.unitPriceForBuyer}
                          key={orderItem.orderUuid}
                          color={orderItem.color || 'N/A'}
                          currency={orderDetails.currencySymbol}
                        />
                      )
                    })
                  }
                </ul>
              </div>
              <div className="card-footer custom-card-footer">
                <div className='d-flex flex-row justify-content-between price-breakdown'
                >
                  <p className="card-footer-item-title">Subtotal</p>
                  <p>₦{NumberWithComma(orderDetails?.calculatedTotalCostMajor || 0)}</p>
                </div>
                <div className='d-flex flex-row justify-content-between price-breakdown'>
                  <p>Delivery</p>
                  <p>₦{NumberWithComma(orderDetails?.deliveryCostMajor || 0)}</p>
                </div>
                <div className='d-flex flex-row justify-content-between
                            fw-bold orde-summary-total price-total'
                >
                  <p>Total</p>
                  <p>₦{NumberWithComma(totalCost)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="card order-summary">
              <div className="card-header card-custom-header bg-white">
                <p>Payment and delivery information</p>
              </div>
              <div className="card-body">
                {/* eslint-disable-next-line */}
                <ul className="list-group list-group-flush">
                  {/* eslint-disable-next-line */}
                  {loadingRequest &&
                    <div className='text-secondary text-center fw-bold'>
                      <span className='spinner-border spinner-border-lg'></span>
                    </div>
                  }
                  <SellerPageOrderPaymentMethod
                    method={'card'}
                  />
                  <SellerPageOrderDeliveryAddress
                    address={orderDetails?.orderLocation?.address}
                    state={orderDetails?.orderLocation?.state}
                    country={orderDetails?.orderLocation?.country}
                  />
                  <SellerPageOrderContactDetails
                    contactFullName={orderDetails?.orderLocation.contactFullName}
                    contactEmail={orderDetails?.orderLocation.contactEmail}
                    contactPhoneNumber={orderDetails?.orderLocation.contactPhoneNumber}
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster></Toaster>
    </div>
  )
}

export default SellerOrdersDetails

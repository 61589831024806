import {FC} from "react";
import { ErrorMessage } from "../../../../Component";
 
interface propTypes {
  companyNameValue:string ;
  businessTypeValue:string;
  cacNumberValue:string;
  companyAddressValue :string;
  jobTitlevValue:string;
  handleChange:any;
  handleBlur:any;
  formikErrors:any;
  formikTouchedValues:any;

}

const CompanyDetailsInput:FC<propTypes> = (prop) => {
  const {
    companyNameValue, businessTypeValue,
    cacNumberValue, companyAddressValue,
    jobTitlevValue, handleChange,
    handleBlur, formikErrors, formikTouchedValues
  } = prop;

    return (
         <div className="BuyercompanyDetailsInput">
             <div className="form-section-header">
          <div className="form-section-title">Company details</div>
          <div className="form-section-description">Fill in the details of your company.</div>
         </div>
         <div className="row">
          <div className="mb-4 col-12">
              <label  className="form-label">How is your business registered?</label>
                <div className="form-check d-flex align-items-center">
                  <input className="form-check-input me-2" type="radio" name="businessType" id="flexRadioDefault1" checked
                    value={businessTypeValue? businessTypeValue : "Business name"} onBlur={handleBlur} onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Business name
                  </label>
                </div>
                <div className="form-check d-flex align-items-center">
                  <input className="form-check-input me-2" type="radio" name="businessType" id="flexRadioDefault2"
                    value={businessTypeValue? businessTypeValue : "Limited Liability Company"} onChange={handleChange} onBlur={handleBlur}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                      Limited Liability Company
                  </label>
                </div>
                {
                  formikErrors.businessType && formikTouchedValues.businessType &&
                  <ErrorMessage error={formikErrors.businessType}/>
                }
          </div>
          <div className="mb-3 col-12 col-md-6">
              <label htmlFor="RCNumberFormControlInput" className="form-label">BN or RC Number (CAC)</label>
              <input type="text" className="form-control form-input" id="RCNumberFormControlInput"
                name="cacNumber" value={cacNumberValue} onChange={handleChange} onBlur={handleBlur}
              />
              {
                  formikErrors.cacNumber && formikTouchedValues.cacNumber &&
                  <ErrorMessage error={formikErrors.cacNumber}/>
                }
          </div>
          
          <div className="mb-3 col-12 col-md-6">
              <label htmlFor="companyNameFormControlInput" className="form-label">Company name</label>
              <input type="text" className={`form-control ${formikErrors.companyName && formikTouchedValues.companyName ? 'form-input-error' :'form-input'}`} id="companyNameFormControlInput"
                name="companyName" value={companyNameValue} onChange={handleChange} onBlur={handleBlur}
              />
              {
                  formikErrors.companyName && formikTouchedValues.companyName &&
                  <ErrorMessage error={formikErrors.companyName}/>
              }
          </div>
          <div className="mb-3 col-12 col-md-6">
              <label htmlFor="companyAddressFormControlInput" className="form-label">Company address</label>
              <input type="text" className={`form-control ${formikErrors.companyAddress && formikTouchedValues.companyAddress ? 'form-input-error' :'form-input'}`} id="companyAddressFormControlInput"
                name="companyAddress" value={companyAddressValue} onChange={handleChange} onBlur={handleBlur}
              />
              {
                  formikErrors.companyAddress && formikTouchedValues.companyAddress &&
                  <ErrorMessage error={formikErrors.companyAddress}/>
              }
          </div>
          <div className="mb-3 col-12 col-md-6">
              <label htmlFor="jobTitleFormControlInput" className="form-label">Job title</label>
              <input type="text" className={`form-control ${formikErrors.jobTitle && formikTouchedValues.jobTitle ? 'form-input-error' :'form-input'}`} id="jobTitleFormControlInput"
                name="jobTitle" value={jobTitlevValue} onChange={handleChange} onBlur={handleBlur}
              />
              {
                  formikErrors.jobTitle && formikTouchedValues.jobTitle &&
                  <ErrorMessage error={formikErrors.jobTitle}/>
              }
          </div>
         </div>
        </div>
    )
}

export default CompanyDetailsInput;
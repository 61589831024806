
import React, { FC, useState } from 'react'
import "./AddToCartButton.scss"
import cartLight from "../../../../../Assets/Images/icon/cart-light.png"
import toast, { Toaster } from 'react-hot-toast'
import { productType } from '../../../../../Models/productType';
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'
import { db } from '../../../../../Config/DBConfig';
import { useLiveQuery } from 'dexie-react-hooks'; 
import { TOGGLE_CART_DRAWER } from '../../../../../Constants/CartConstants';

import QuoteRequest from '../QuoteRequest/QuoteRequest';
import { useAuth } from '../../../../../Components/AuthProvider/AuthProvider';

import Cookie from 'cookie-universal'

interface propsType {
  module?: string
}
const AddToCartButton:FC<propsType> = ({module}) => {
  const [loadingCart, setLoadingCart] = useState(false)
  const [alreadyAdded, setAlreadyAdded] = useState(false)

  const [show, setShow] = useState(false);

  const { user } = useAuth()

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { data: productDetail } = useQuery<productType>(['productsSingleData'], {
    enabled: false,
    select: (data: any) => data.data.data,
  })

  const cartQty = useSelector((state:{cartQty:number}) => state.cartQty)

  const addtocart = async () => {
    setLoadingCart(true);

    try { 
       await db.cart.add({
        productUuid: productDetail?.productUuid,
        quantity: cartQty,
        productDetails: productDetail
      });
      setLoadingCart(false);
      setAlreadyAdded(true)
      dispatch({ type: TOGGLE_CART_DRAWER, payload: { active: true, placement: "end" } })

      toast.success(`Added to cart`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    } catch (error) {
      setAlreadyAdded(false)
      setLoadingCart(false);
      toast.error(`Failed to add ${name}: ${error}`, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    }
  }

  useLiveQuery(
    async () => {
      const carts = db.cart.where({ productUuid: productDetail?.productUuid }).toArray()

      setAlreadyAdded((await carts).length >= 1) 
    }
  );

  const unAuthQuote = () => { 
    const cookies = Cookie()
    cookies.set("referer", navigate.name)
 
    if (confirm("Hi, login is required to send a quote request. Click 'ok' to proceed to login page.") === true) {
      return navigate("/login", { replace: true })
    }
    return false
  }

  return (
    <React.Fragment>
      {/* {productDetail?.price} */}
      {/* {alreadyAdded.toString()} */}
      {productDetail?.price !== 0? <React.Fragment>
          {!loadingCart && (!productDetail?.isOnCart === false || !alreadyAdded) && <button className="btn btn-add-cart mb-4" onClick={addtocart}><img src={cartLight}/> Add to cart</button>}
          {!loadingCart && (productDetail?.isOnCart || alreadyAdded) && <Link to={`${module !==undefined? '/'+ module:""}/cart`}><button className="btn btn-add-cart mb-4"><img src={cartLight}/> Already Added to cart</button></Link>}
          {loadingCart && <button className="btn btn-add-cart mb-4" disabled>
            <span className='spinner-border spinner-border-sm'></span>
            Adding to cart</button>}  
      </React.Fragment>
      : 
       <React.Fragment>
        <div className="request-quote-desc-text">Click &apos;Request for Quote&apos; button below to get the best price instantly.</div>
        {user ? <button className="btn btn-add-cart mb-4" onClick={()=>setShow(true)}>Request For Quote</button>:
         <button className="btn btn-add-cart mb-4" onClick={unAuthQuote}>Request For Quote</button>}
      </React.Fragment>}
       <QuoteRequest productDetail={productDetail} show={show} hide={(val:boolean) => setShow(val)}></QuoteRequest>
      <Toaster></Toaster>
    </React.Fragment>
  )
}

export default AddToCartButton

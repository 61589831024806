import React from 'react'

const WalletIcon = () => {
    return (
        <svg className="me-1" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 9H7" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.0002 10.9731V13.0331C22.0002 13.5831 21.5602 14.0331 21.0002 14.0531H19.0402C17.9602 14.0531 16.9702 13.2631 16.8802 12.1831C16.8202 11.5531 17.0602 10.9631 17.4802 10.5531C17.8502 10.1731 18.3602 9.95312 18.9202 9.95312H21.0002C21.5602 9.97312 22.0002 10.4231 22.0002 10.9731Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.48 10.55C17.06 10.96 16.82 11.55 16.88 12.18C16.97 13.26 17.96 14.05 19.04 14.05H21V15.5C21 18.5 19 20.5 16 20.5H7C4 20.5 2 18.5 2 15.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.50999 16.75 3.54999C19.33 3.84999 21 5.76 21 8.5V9.95001H18.92C18.36 9.95001 17.85 10.17 17.48 10.55Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg> 
    )
}

export default WalletIcon

import React, { FC, useState } from 'react'
import { EnterpriseAccountUpdate } from '../../../../Components'
import EnterpriseSecondaryWalletTransferModal from '../../../../Components/EnterpriseBankingAndFinance/EnterpriseSecondaryWalletTransfer/EnterpriseSecondaryWalletTransferModal'
import "./BankingAndFinance.scss"
 

interface propsType{
  module?: string
}
const BankingAndFinance:FC<propsType> = ({ module }) => {

  const [showSecondaryWalletTransfer, setShowSecondaryWalletTransfer] = useState(false)
  const [showBankUpdateRequest, setShowBankUpdateRequest] = useState(false)
 


  return (
        <div className='enterprise-bankingAndFinance'>
            <div className="container mt-4"> 
               <div className="row justify-content-center"> 
                  <div className="col-md-6">
                      <div className="row">
                        <div className="col-6">
                            <div className="card" onClick={()=>setShowBankUpdateRequest(true)}>
                              <div className="card-body">
                                <div>
                                 <div className="card-container"> 
                                    <div className={module && module==='estate'? "card-icon-estate": "card-icon"}> 
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M21 11.7462H3C2.04 11.7462 1.25 10.9562 1.25 9.99623V6.67622C1.25 5.99622 1.71998 5.30623 2.34998 5.05623L11.35 1.45625C11.73 1.30625 12.27 1.30625 12.65 1.45625L21.65 5.05623C22.28 5.30623 22.75 6.00622 22.75 6.67622V9.99623C22.75 10.9562 21.96 11.7462 21 11.7462ZM12 2.83625C11.96 2.83625 11.92 2.8362 11.9 2.8462L2.90997 6.44624C2.84997 6.47624 2.75 6.60622 2.75 6.67622V9.99623C2.75 10.1362 2.86 10.2462 3 10.2462H21C21.14 10.2462 21.25 10.1362 21.25 9.99623V6.67622C21.25 6.60622 21.16 6.47624 21.09 6.44624L12.09 2.8462C12.07 2.8362 12.04 2.83625 12 2.83625Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                                          <path d="M22 22.75H2C1.59 22.75 1.25 22.41 1.25 22V19C1.25 18.04 2.04 17.25 3 17.25H21C21.96 17.25 22.75 18.04 22.75 19V22C22.75 22.41 22.41 22.75 22 22.75ZM2.75 21.25H21.25V19C21.25 18.86 21.14 18.75 21 18.75H3C2.86 18.75 2.75 18.86 2.75 19V21.25Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                                          <path d="M4 18.75C3.59 18.75 3.25 18.41 3.25 18V11C3.25 10.59 3.59 10.25 4 10.25C4.41 10.25 4.75 10.59 4.75 11V18C4.75 18.41 4.41 18.75 4 18.75Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                                          <path d="M8 18.75C7.59 18.75 7.25 18.41 7.25 18V11C7.25 10.59 7.59 10.25 8 10.25C8.41 10.25 8.75 10.59 8.75 11V18C8.75 18.41 8.41 18.75 8 18.75Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                                          <path d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V11C11.25 10.59 11.59 10.25 12 10.25C12.41 10.25 12.75 10.59 12.75 11V18C12.75 18.41 12.41 18.75 12 18.75Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                                          <path d="M16 18.75C15.59 18.75 15.25 18.41 15.25 18V11C15.25 10.59 15.59 10.25 16 10.25C16.41 10.25 16.75 10.59 16.75 11V18C16.75 18.41 16.41 18.75 16 18.75Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                                          <path d="M20 18.75C19.59 18.75 19.25 18.41 19.25 18V11C19.25 10.59 19.59 10.25 20 10.25C20.41 10.25 20.75 10.59 20.75 11V18C20.75 18.41 20.41 18.75 20 18.75Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                                          <path d="M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                                          <path d="M12 9.25C10.76 9.25 9.75 8.24 9.75 7C9.75 5.76 10.76 4.75 12 4.75C13.24 4.75 14.25 5.76 14.25 7C14.25 8.24 13.24 9.25 12 9.25ZM12 6.25C11.59 6.25 11.25 6.59 11.25 7C11.25 7.41 11.59 7.75 12 7.75C12.41 7.75 12.75 7.41 12.75 7C12.75 6.59 12.41 6.25 12 6.25Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                                      </svg> 
                                    </div>
                                  </div>
                                    <div className="card-title text-center mt-3">
                                        Request Bank <br></br>Account Update 
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="col-6">
                           <div className="card pe-none">
                              <div className="card-body">

                                    <div className="lock">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11" stroke="#565656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12.0004 14.5984C12.3317 14.5984 12.6003 14.3298 12.6003 13.9984C12.6003 13.6671 12.3317 13.3984 12.0004 13.3984C11.669 13.3984 11.4004 13.6671 11.4004 13.9984C11.4004 14.3298 11.669 14.5984 12.0004 14.5984Z" stroke="#565656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z" stroke="#565656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#565656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> 
                                    </div>
                                <div>
                                 <div className="card-container"> 
                                    <div className={module && module==='estate'? "card-icon-estate": "card-icon"}> 
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                          <path d="M15.5 2V9.85999C15.5 10.3 14.98 10.52 14.66 10.23L12.34 8.09003C12.15 7.91003 11.85 7.91003 11.66 8.09003L9.34003 10.23C9.02003 10.52 8.5 10.3 8.5 9.85999V2H15.5Z" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                          <path d="M13.25 14H17.5" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                          <path d="M9 18H17.5" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> 
                                    </div>
                                  </div>
                                    <div className="card-title text-center mt-3">
                                    Request Account<br></br> Statement 
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        <div className="col-6 d-none">
                           <div className="card pe-none">
                              <div className="card-body">
                                    <div className="lock">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11" stroke="#565656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12.0004 14.5984C12.3317 14.5984 12.6003 14.3298 12.6003 13.9984C12.6003 13.6671 12.3317 13.3984 12.0004 13.3984C11.669 13.3984 11.4004 13.6671 11.4004 13.9984C11.4004 14.3298 11.669 14.5984 12.0004 14.5984Z" stroke="#565656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z" stroke="#565656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#565656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> 
                                    </div>
                                <div>
                                 <div className="card-container"> 
                                    <div className={module && module==='estate'? "card-icon-estate": "card-icon"}> 
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.4405 8.89844C20.0405 9.20844 21.5105 11.0584 21.5105 15.1084V15.2384C21.5105 19.7084 19.7205 21.4984 15.2505 21.4984H8.74047C4.27047 21.4984 2.48047 19.7084 2.48047 15.2384V15.1084C2.48047 11.0884 3.93047 9.23844 7.47047 8.90844" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12 2V14.88" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15.3504 12.6484L12.0004 15.9984L8.65039 12.6484" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg> 
                                    </div>
                                  </div>
                                    <div className="card-title text-center mt-3">
                                    Request Withdrawal <br></br>from Secondary Wallet 
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        <div className="col-6 d-none">
                           <div className="card" onClick={()=>setShowSecondaryWalletTransfer(true)}>
                              <div className="card-body">
                                <div>
                                 <div className="card-container"> 
                                    <div className={module && module==='estate'? "card-icon-estate": "card-icon"}> 
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.49977 13.24V11.51C2.49977 9.44001 4.18977 7.75 6.25977 7.75H17.7398C19.8098 7.75 21.4998 9.44001 21.4998 11.51V12.95H19.4798C18.9198 12.95 18.4098 13.17 18.0398 13.55C17.6198 13.96 17.3798 14.55 17.4398 15.18C17.5298 16.26 18.5198 17.05 19.5998 17.05H21.4998V18.24C21.4998 20.31 19.8098 22 17.7398 22H2.25977" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M2.5 12.4113V7.84132C2.5 6.65132 3.23 5.59128 4.34 5.17128L12.28 2.17128C13.52 1.70128 14.85 2.62131 14.85 3.95131V7.7513" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M22.5588 13.9731V16.0331C22.5588 16.5831 22.1188 17.0331 21.5588 17.0531H19.5988C18.5188 17.0531 17.5288 16.2631 17.4388 15.1831C17.3788 14.5531 17.6188 13.9631 18.0388 13.5531C18.4088 13.1731 18.9188 12.9531 19.4788 12.9531H21.5588C22.1188 12.9731 22.5588 13.4231 22.5588 13.9731Z" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7 12H14" stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg> 
                                    </div>
                                  </div>
                                    <div className="card-title text-center mt-3">
                                   Primary Wallet to <br></br>Secondary Wallet Transfer 
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div> 
              </div> 
          </div>
          <EnterpriseSecondaryWalletTransferModal showModal={showSecondaryWalletTransfer} hide={ (value: boolean) =>setShowSecondaryWalletTransfer(value) } module={module}></EnterpriseSecondaryWalletTransferModal>
          <EnterpriseAccountUpdate showModal={showBankUpdateRequest} handleClose={() =>setShowBankUpdateRequest(false)} module={module}></EnterpriseAccountUpdate>
      </div>
  )
}

export default BankingAndFinance

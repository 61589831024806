import React, { FC, useState } from 'react'
import "./ProjectStatusUpdate.scss"
import projectService from '../../../../../../../Services/projectService'
import { useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
 
interface propsType {
    handleHide: ()=>void
}
 
const ProjectStatusUpdate:FC<propsType> = ({handleHide}) => {
    const { projectUuid } = useParams<{projectUuid:string}>() 
    const [loading, setLoading] = useState<boolean>(false)
  
    const processForm = async () => {
        setLoading(true)  
       await projectService.updateStatus(projectUuid, "CLOSED").then(()=>{
        setLoading(false)
        toast.success("Project closed succesfully", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        handleHide()

        setTimeout(()=>{
          location.reload()
        }, 2000)

       }, error => {
        setLoading(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
   
       })
    }
    return (
        <div className='ProjectStatusUpdate'>
            <div className="container">
                <div className="text-center mb-3">
                    <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.9997 36.6668C29.1663 36.6668 36.6663 29.1668 36.6663 20.0002C36.6663 10.8335 29.1663 3.3335 19.9997 3.3335C10.833 3.3335 3.33301 10.8335 3.33301 20.0002C3.33301 29.1668 10.833 36.6668 19.9997 36.6668Z" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M15.2832 24.7165L24.7165 15.2832" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M24.7165 24.7165L15.2832 15.2832" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg> 
                </div>
                <div className="title">Close Project?</div>
                <div className="description">Are you sure you want to close this project?</div>
 
                <div className="row mt-4 justify-content-center">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btn btn-cancel w-100" type='button' onClick={()=>handleHide()}>Cancel</button>
                            </div>
                            <div className="col-md-6">
                                <button className="btn btn-proceed w-100" type='button' disabled={loading} onClick={()=> processForm()}>{loading && <span className='spinner-border spinner-border-sm'></span>} Close Project</button>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <Toaster></Toaster>
        </div>
    )
}

export default ProjectStatusUpdate

import React, { useState, FC , Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import "./EnterpriseWalletWithdrawalModal.scss" 
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService';
import toast, { Toaster } from 'react-hot-toast'; 
 
 

interface propsType { 
  showModal:boolean;
  hide: (value:boolean)=>void
}

const EnterpriseWalletWithdrawalModal:FC<propsType> = ({showModal, hide}) => {
  const navigate = useNavigate()

  const [show, setShow] = useState(false);  
  const [amount, setAmount] = useState(0)  
 
  const [show2, setShow2] = useState(false) 
  const [password, setPassword] = useState("")
  const [withdrawaLRequest, setWithdrawaLRequest] = useState(false);

 

  const handleClose = () => setShow(false)
  const handleClose2 = () => setShow2(false)

  const submit = async () => {
    setWithdrawaLRequest(true)

    await bankingFinanceService.walletWithdrawal({ password, amountMajor: amount })
      .then((res) => {
        setWithdrawaLRequest(false) 
        setShow2(true)
        handleClose()
      }, (error:any) => {
        setWithdrawaLRequest(false) 
        toast.error(error.response.data.error, { duration: 25000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  return ( 
       <Fragment>
          <Modal 
            show={showModal} 
            onHide={()=>hide(false)}
            backdrop="static"
            keyboard={false}
            contentClassName="enterpriseWalletWithdrawal-modal">  
              <Modal.Header closeButton className='border-0'>
                <Modal.Title>Withdraw</Modal.Title>
              </Modal.Header> 
              <Modal.Body> 
                  <div className='form-input'> 
                      <div className='form-input-wrap mt-3'>
                        <label htmlFor="">Amount </label>
                          <input id="accountNumber" type="number" className='form-control ps-2 mt-2' placeholder='₦0.00' onChange={(e:any) => setAmount(e.target.value)}/>
                        </div> 

                        <button className='btn w-100 add-btn mt-5 text-light'  onClick={() => { setShow(true); hide(false) }}> Withdraw </button> 
                  </div> 
            </Modal.Body>
          </Modal>
 
          <Modal show={show} onHide={handleClose} contentClassName="customModal fws">
              <Modal.Header closeButton>
                <Modal.Title className="fw-bolder">Authorize Transaction</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="row mt-3 pb-4">
                    <div className="col-12">
                     <div className="form-group mb-5">
                        <label htmlFor="">Enter password to confirm transaction</label>
                        <input type="password" className="form-control" onChange={(e:any) => setPassword(e.target.value)} autoComplete="off"/>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                    {!withdrawaLRequest && <button className='btn apply btn-primary' onClick={submit}>Confirm Transaction</button>}
                         {withdrawaLRequest && <button className="btn apply btn-primary" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Processing request...
                          </button> }
                    </div>
                  </div>
              </Modal.Body>
            </Modal>

            <Modal show={show2} onHide={handleClose2} contentClassName="customModal fws">
              <Modal.Body>
                <div className="container">
                <div className="row justify-content-center mt-4 mb-4">
                  <div className="col-3">
                    <svg width="100%" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M124.688 -3.75V126.562H-5.15625V-3.75H124.688Z" fill="white"/>
                      <g opacity="0.851687">
                      <path d="M60.0002 10.4922C86.8817 10.4922 108.707 32.3179 108.707 59.1994C108.707 86.081 86.8817 107.907 60.0002 107.907C33.1187 107.907 11.293 86.081 11.293 59.1994C11.293 32.3179 33.1187 10.4922 60.0002 10.4922Z" fill="#F5A623" fillOpacity="0.3"/>
                      </g>
                      <path d="M60 17.8984C82.8952 17.8984 101.484 36.4876 101.484 59.3828C101.484 82.278 82.8952 100.867 60 100.867C37.1048 100.867 18.5156 82.278 18.5156 59.3828C18.5156 36.4876 37.1048 17.8984 60 17.8984Z" fill="#F5A623"/>
                      <path d="M40.6641 61.0547L52.7344 72.8906L77.1094 47.6953" stroke="white" strokeWidth="7.03125" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>

                  <div className="col-12 text-center mb-4">

                  <div className='fw-bold'>Success!</div>
                  <div className='details'>You have successfully withdrawn N{amount} from your wallet.</div>
                  </div>

                  <div className="col-12">
                    <button className='btn apply btn-primary w-100' onClick={() => { setShow2(false); navigate("/enterprise/banking-and-finance", { replace: true }) }}>Done</button>
                  </div>
                </div>
                </div>

              </Modal.Body>
            </Modal>
          <Toaster/> 
      </Fragment>
  )
}

export default EnterpriseWalletWithdrawalModal

import React, { ReactNode } from 'react'
import { Offcanvas } from 'react-bootstrap' 
import "./OffcanvasDrawer.scss" 
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas'

interface propsType {
    show: boolean;
    placement: OffcanvasPlacement | undefined;
    handleClose: (value:OffcanvasPlacement | undefined )=>void; 
    title: string;
    description: string|undefined;
    children:ReactNode;
}

const OffcanvasDrawer:React.FC<propsType> = ({show, placement, handleClose, title, description, children}) => { 
  return (
      <Offcanvas show={show} onHide={() => handleClose(placement)} placement={placement} className={placement === "bottom" ? 'OffcanvasDrawer h-auto' : "OffcanvasDrawer"} responsive={placement === "bottom" ? "lg" : ""}>
        <Offcanvas.Header closeButton className='border-bottom'>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body> 
            <div className='description'>{description}</div> 
            {children}
        </Offcanvas.Body>
      </Offcanvas>
  )
}

export default OffcanvasDrawer


import React from 'react'
import { Link } from 'react-router-dom'
import { MiniLoader } from '../../../../../../../Components';
import cement from "../../../../../../../Assets/Images/dangote_cement.png"
import "./BestSelling.scss"

const BestSelling = () => {
  return (
        <div className='best-selling'>
          <MiniLoader show={false}></MiniLoader>
          <div className="card py-3">
            <div className="card-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    <div className="title">Best Selling Items</div> 
                  </div>
              <div className="col-3 text-end">
                    <select className='select-control'>
                      <option>January</option>
                      <option>February</option>
                    </select>
                  </div>
                  <div className="col-3 text-end">
                    <select className='select-control'>
                      <option>2023</option>
                      <option>2022</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
               <div className="container-fluid">
                {}
                 <div className="row d-none">
                   <div className="col-md-6 mb-4">
                    <div className="container-fluid px-1">
                      <div className="row">
                        <div className="col-3 text-center">
                          <img src={cement} className="w--75"/>
                        </div>
                        <div className="col-9 p-0">
                          <div>
                          <Link to="" className='recent-prod-title'>Dangote OPC 43 Grade Cement 50 kg Bag</Link>
                          </div>
                          <div className='recent-prod-price'>₦3,500</div>
                        </div>
                      </div>
                      </div>
                   </div>
                   <div className="col-md-6 mb-3">
                    <div className="container-fluid px-1">
                      <div className="row">
                        <div className="col-3 text-center">
                          <img src={cement} className="w--75"/>
                        </div>
                        <div className="col-9 p-0">
                          <div>
                          <Link to="" className='recent-prod-title'>Dangote OPC 43 Grade Cement 50 kg Bag</Link>
                          </div>
                          <div className='recent-prod-price'>₦3,500</div>
                        </div>
                      </div>
                      </div>
                   </div>
                   <div className="col-md-6 mb-3">
                    <div className="container-fluid px-1">
                      <div className="row">
                        <div className="col-3 text-center">
                          <img src={cement} className="w--75"/>
                        </div>
                        <div className="col-9 p-0">
                          <div>
                          <Link to="" className='recent-prod-title'>Dangote OPC 43 Grade Cement 50 kg Bag</Link>
                          </div>
                          <div className='recent-prod-price'>₦3,500</div>
                        </div>
                      </div>
                      </div>
                   </div>
                   <div className="col-md-6 mb-3">
                    <div className="container-fluid px-1">
                      <div className="row">
                        <div className="col-3 text-center">
                          <img src={cement} className="w--75"/>
                        </div>
                        <div className="col-9 p-0">
                          <div>
                          <Link to="" className='recent-prod-title'>Dangote OPC 43 Grade Cement 50 kg Bag</Link>
                          </div>
                          <div className='recent-prod-price'>₦3,500</div>
                        </div>
                      </div>
                      </div>
                   </div>
                   <div className='col-12 text-center'> No item found</div>
                 </div>
                   <div className='col-12 text-center'> No item found</div>
               </div>
            </div>
          </div>
        </div>
  )
}

export default BestSelling

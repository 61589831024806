import React from 'react'
import BuyerPageTitle from '../../../../Components/Buyer/BuyerPageTitle/BuyerPageTitle'
import { Link, useSearchParams } from 'react-router-dom';
import "./BuyerUnauthPaymentCompleted.scss"

const BuyerUnauthPaymentCompleted = () => {
    const [searchParams] = useSearchParams();
    const action = searchParams.get('action')
 
    return (
        <div className='buyerUnauthPaymentCompleted'>
            <div className="container mt-3 ">
                <BuyerPageTitle title={'Payment Successful'} parent={"Home"} parentUrl={"/"} showTitle={false}></BuyerPageTitle> 
                <div className="row mt-5 justify-content-center align-items-center">
                    <div className="col-md-6 my-auto">
                        <div className="card mb-5 border-0">
                            <div className="card-body pb-4">
                                <svg width="100%" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M124.688 -3.75V126.562H-5.15625V-3.75H124.688Z" fill="white"/>
                                    <g opacity="0.851687">
                                    <path d="M60.0002 10.4922C86.8817 10.4922 108.707 32.3179 108.707 59.1994C108.707 86.081 86.8817 107.907 60.0002 107.907C33.1187 107.907 11.293 86.081 11.293 59.1994C11.293 32.3179 33.1187 10.4922 60.0002 10.4922Z" fill="#28a745" fillOpacity="0.3"/>
                                    </g>
                                    <path d="M60 17.8984C82.8952 17.8984 101.484 36.4876 101.484 59.3828C101.484 82.278 82.8952 100.867 60 100.867C37.1048 100.867 18.5156 82.278 18.5156 59.3828C18.5156 36.4876 37.1048 17.8984 60 17.8984Z" fill="#28a745"/>
                                    <path d="M40.6641 61.0547L52.7344 72.8906L77.1094 47.6953" stroke="white" strokeWidth="7.03125" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <div className="title">Payment Successful</div>
                                <div className="description text-secondary">
                                  Information about this transaction has been sent to your email. 
                                </div>
                                <Link to={action? action : ""}> 
                                   <button className='btn'>Close</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default BuyerUnauthPaymentCompleted

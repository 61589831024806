import React, { FC } from 'react'
import toast, { Toaster } from 'react-hot-toast' 
import { db } from '../../../../../../../../Config/DBConfig'
import "./EnterpriseCartElement.scss"
import { Logger } from '../../../../../../../../Helpers/Logger';
import { ImageSrc } from '../../../../../../../../Components'; 
interface propType {
  item:any
}

const EnterpriseCartElement:FC<propType> = ({ item }) => {
  Logger("item", item)  
 
  
  const updateQty = (e:{target:{value:string}}) => {
    db.cart.update(item.id, { quantity: e.target.value }).then(function (updated) {
      if (updated) {
        toast.success(`Updated cart`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      } else {
        toast.error(`Nothing was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      }
    });
  }

  const removeFromCart = () => {
    db.cart.where({ productUuid: item?.productUuid }).delete();
  }


  
  return (
    <div className="enterprise-cart-item">
      <div className="enterprise-cart-item-inner">
        <div className="container-fluid">
            <div className="row">
                <div className="col-4 col-md-4 col-xl-2">
                    <div className="cart-item-list-image">
                        <ImageSrc src={item?.productDetails?.images[0]?.url} alt={item?.productDetails?.productName} title={item?.productDetails?.productName} width="w-100"></ImageSrc>
                    </div>
                </div>

                <div className="col-4 col-md-6  col-xl-8">
                    <div className="cart-item-name pt-2">
                        {item?.productDetails?.productName}
                    </div>

                    <div className="cart-item-variation">
                        Color: Red
                    </div>

                    <div className="product-attibutes pb-2">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-4 col-md-4 text-center product-qty">
                                    <input type="number" className="form-control p-0 border-0" defaultValue={item.quantity} onChange={(e) => updateQty(e)}/>
                                </div>
                                <div className="col-6 col-md-2 remove-product text-center">
                                  <button className="btn p-0 btn-sm" onClick={removeFromCart}>Remove product</button>
                                </div>
                                <div className="col-4 col-md-3 d-none d-md-block save-product text-center">
                                <button className="btn p-0 btn-sm">Move to saved list</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-4 col-md-2">
                    <div className="cart-item-price">
                        {item.productDetails.currencySymbol} {item.productDetails.unitPriceForBuyer}
                    </div>
                </div>
            </div>
        </div> 
        <Toaster></Toaster>
    </div>
     
  </div>
  )
}

export default EnterpriseCartElement

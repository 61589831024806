import { FC, Fragment, Key } from 'react'
import "./EditVariationList.scss"

interface propsType {
    variation: any[];
    handleRemoveVariation: (index:number) => void
}
const EditVariationList:FC<propsType> = ({ variation, handleRemoveVariation }) => {
    console.log("variation", variation)
 
    variation.map((res:any) =>
        res.images.map((resin:any, index:number) =>  console.log("image operations(" + index + ")", resin.name))
      )
    

    return (
        <Fragment>
        {variation.map((res: { variable_title: string,  attribute: any[], variable_price:number, images: any[]}, index)=>{
         return  <div className="editVariationList accordion" id="accordionExample" key={index}>
            <div className="accordion-item border-0">
                <div className="row h-100">
                    <div className="col-md-11">
                        <h2 className="accordion-header" id={`#headingOne${index}`}>
                            <button className="accordion-button text-center" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${index}`} aria-expanded="true" aria-controls={`collapseOne${index}`}>
                            {res.variable_title}
                            </button>
                        </h2>
                    </div>
                    <div className="col-md-1 my-auto">
                        <button className='btn btn-sm' type="button" onClick={()=> handleRemoveVariation(Number(index))}>
                            <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.9997 7.31364C20.9797 7.31364 20.9497 7.31364 20.9197 7.31364C15.6297 6.77718 10.3497 6.57474 5.11967 7.1112L3.07967 7.31364C2.65967 7.35413 2.28967 7.05047 2.24967 6.62535C2.20967 6.20022 2.50967 5.83583 2.91967 5.79535L4.95967 5.59291C10.2797 5.04632 15.6697 5.25888 21.0697 5.79535C21.4797 5.83583 21.7797 6.21035 21.7397 6.62535C21.7097 7.0201 21.3797 7.31364 20.9997 7.31364Z" fill="#FF0000"/>
                                <path d="M8.50074 6.29014C8.46074 6.29014 8.42074 6.29014 8.37074 6.28002C7.97074 6.20916 7.69074 5.81441 7.76074 5.40953L7.98074 4.08355C8.14074 3.11184 8.36074 1.76562 10.6907 1.76562H13.3107C15.6507 1.76562 15.8707 3.16245 16.0207 4.09367L16.2407 5.40953C16.3107 5.82453 16.0307 6.21928 15.6307 6.28002C15.2207 6.35087 14.8307 6.06745 14.7707 5.66258L14.5507 4.34672C14.4107 3.46611 14.3807 3.29404 13.3207 3.29404H10.7007C9.64074 3.29404 9.62074 3.43575 9.47074 4.3366L9.24074 5.65245C9.18074 6.02697 8.86074 6.29014 8.50074 6.29014Z" fill="#FF0000"/>
                                <path d="M15.2104 23.5282H8.79039C5.30039 23.5282 5.16039 21.5747 5.05039 19.9957L4.40039 9.80287C4.37039 9.38787 4.69039 9.02348 5.10039 8.99311C5.52039 8.97287 5.87039 9.28665 5.90039 9.70165L6.55039 19.8945C6.66039 21.433 6.70039 22.0099 8.79039 22.0099H15.2104C17.3104 22.0099 17.3504 21.433 17.4504 19.8945L18.1004 9.70165C18.1304 9.28665 18.4904 8.97287 18.9004 8.99311C19.3104 9.02348 19.6304 9.37775 19.6004 9.80287L18.9504 19.9957C18.8404 21.5747 18.7004 23.5282 15.2104 23.5282Z" fill="#FF0000"/>
                                <path d="M13.6601 17.9597H10.3301C9.92008 17.9597 9.58008 17.6156 9.58008 17.2006C9.58008 16.7856 9.92008 16.4414 10.3301 16.4414H13.6601C14.0701 16.4414 14.4101 16.7856 14.4101 17.2006C14.4101 17.6156 14.0701 17.9597 13.6601 17.9597Z" fill="#FF0000"/>
                                <path d="M14.5 13.9128H9.5C9.09 13.9128 8.75 13.5687 8.75 13.1537C8.75 12.7387 9.09 12.3945 9.5 12.3945H14.5C14.91 12.3945 15.25 12.7387 15.25 13.1537C15.25 13.5687 14.91 13.9128 14.5 13.9128Z" fill="#FF0000"/>
                            </svg>  
                        </button>
                    </div>
                </div>
            
            <div id={`collapseOne${index}`} className={Number(index) === 0? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={`#headingOne${index}`} data-bs-parent="#accordionExample">
                <div className="accordion-body">
                {res.attribute.map((result: any, index: Key) => { 
                    return (<div className="row" key={index}>
                        <div className="col-md-6">
                            <div className="form-input mt-3">
                                <label htmlFor={`attibute${Number(index)}`}>Attribute</label>
                                <input id={`attibute${Number(index)}`} className="form-control" defaultValue={result.name}/>
                                  
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-input mt-3">
                                <label htmlFor="max">Variables</label>  
                                <div className="input-tag form-control">
                                    <ul className="input-tag__tags">
                                        { result.value.map((tag: any, i:number) => (
                                           <li key={i}>
                                            {tag}
                                             </li>
                                        ))} 
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-input mt-3">
                                <label htmlFor="max">Variable title</label>
                                <input type="text" id="max" className="form-control" defaultValue={res.variable_title}/>
                             </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-input mt-3">
                                <label htmlFor="price">Variable price</label>
                                <input type="text" id="price" className="form-control" defaultValue={res.variable_price}/>
                             </div>
                        </div> 
                   </div> )
                    })}
                   <div className="row">
                       {res.images.map((res:any, index:Key) => {
                            return <div className="col-6 col-md-3" key={index}> 
                                <div className='uploadedImgvariation card mb-1 p-0 mt-3'>
                                    <div className="card-body p-0 text-center">
                                        <button type="button" className='btn rounded-circle p-1'> <i className='fa fa-times text-dark'></i></button>
                                        <div className="image-wrap">
                                       <img src={res?.name? URL.createObjectURL(res): res } className="w-75 px-3"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}  
                    </div>
                </div>
            </div>
            </div> 
        </div>
         })}
    </Fragment>)
}

export default EditVariationList

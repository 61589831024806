import React, { useState } from 'react'

import "./EstateManagerSidebarContent.scss";
import {  Link, NavLink, useNavigate } from 'react-router-dom'; 
import 'react-confirm-alert/src/react-confirm-alert.css';  
import { ImageSrc, ModalPrompt } from '../../../../Components';  
import logoutIco from "../../../../Assets/Images/logout.png"
import cinderBuildLogo from "../../../../Assets/Images/estate/cinderbuild-estate-logo.png"

import dashboard from "../../../../Assets/Icons/dashboard.png"
import bank from "../../../../Assets/Images/enterprise/bank.svg"
import dolarSquare from "../../../../Assets/Images/enterprise/dollar-square.svg"
import messageQuestion from "../../../../Assets/Images/estate/message-question.png" 
import settings from "../../../../Assets/Images/enterprise/setting-2.svg"
import shop from "../../../../Assets/Images/enterprise/shop.svg"
import shopingBag from "../../../../Assets/Images/enterprise/shopping-bag.svg"   
import shoppingCart from "../../../../Assets/Images/enterprise/shopping-cart.svg"
import { useDispatch } from 'react-redux';
import { ACTIVEMENU } from '../../../../Constants/MobileMenuConstants';
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider';

 
const EstateManagerSidebarContent = () => {
 
  const dispatch = useDispatch() 
  
  function setLink (name:string) {
    dispatch({type: ACTIVEMENU, payload: name})
  }

  const { user, logout } = useAuth()
  
  const [logoutShow, setLogoutShow] = useState<boolean>(false)


  function logoutNow ():void { 
    setLogoutShow(!logoutShow)
  };
  const navigate = useNavigate()
  
  return (
      <React.Fragment>
         <div className="emsc">
          <ul className="nav flex-column mb-4 ps-2">
            <li className="nav-item my-1 mt-3 mb-4 ps-3 d-none d-lg-block">
              <ImageSrc src={cinderBuildLogo} alt={'CinderBuild'} title={'CinderBuild'} width={'enterprise-logo'}></ImageSrc>
            </li>
            {user.isDeveloper && <li className="nav-item my-1 bg-white">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to="/estate-managers/dashboard"
                onClick={()=>setLink('Dashboard')}> 
                <img src={dashboard} alt="" className='icons'/>
                <span className='my-auto'>Dashboard</span>
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}
     
           
            {user.isDeveloper && <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/project-management'
                onClick={()=>setLink('Project Management')}> 
                <img src={shopingBag} alt="" className='icons'/>
                Project Management 
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> }
            
            {user.isInvestor && <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/subscriber-projects'
                onClick={()=>setLink('Project')}> 
                <img src={shopingBag} alt="" className='icons'/>
                Projects 
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}

            {user.isInvestor && <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/subscriber-transactions'
                onClick={()=>setLink('Project Transactions')}> 
                <img src={shopingBag} alt="" className='icons'/>
                Project Transactions
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}

            {user.isInvestor && <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/subscriber-portfolio'
                onClick={()=>setLink('My Portfolio')}> 
                <img src={shopingBag} alt="" className='icons'/>
                My Portfolio
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}

            {user.isDeveloper && <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/quote-request'
                onClick={()=>setLink('Quote Request')}> 
                <img src={shopingBag} alt="" className='icons'/>
                  Quote Request
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}
            
 
            {user.isDeveloper && <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/order-management'
                onClick={()=>setLink('Order Management')}> 
                 <img src={shoppingCart} alt="" className='icons'/>
                Order Management 
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>} 

            {user.isDeveloper && <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/warehouse-management'  onClick={()=>setLink(' Warehouse Management ')} > 
                <img src={shop} alt="" className='icons'/>
                Warehouse Management 
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>}

            {user.isDeveloper &&  <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/investor-management'  onClick={()=>setLink('Subscriber Management')}> 
                 <img src={shoppingCart} alt="" className='icons'/>
                 Subscriber Management
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> }
 
            
           
            <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/banking-and-finance'> 
                <img src={bank} alt="" className='icons'/>
                Banking & Finance
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>
             
            {user.isDeveloper && <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/mortgage-card'>  
                 <img src={dolarSquare} alt="" className='icons'/>
                Mortgage Card 
              </NavLink>
            </li>}
            
           <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/estate-managers/settings'> 
                <img src={settings} alt="" className='icons'/> 
                Settings
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> 

           <li className="nav-item my-1">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='#'
                onClick={logoutNow}> 
                <img src={logoutIco} alt="" className='icons'/> 
                Logout
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li> 
 
            <ul className="nav flex-column mt-5 message-container"> 
              <li className="nav-item my-1">
                <div className='message text-center w-100'>
                  <div className="icon"> 
                    <div className="icon-wrapper">
                       <img src={messageQuestion} alt="" className='icons'/> 
                    </div>
                  </div>
                  <div className="heading">Support Center</div>
                  <div className='desc'>Need help navigating your corporate account? Please contatct us for support </div>
                  <Link to="/estate-managers/support-center"><button className='btn btn-outline-white'>Get Support</button></Link>
                </div>
              </li>
            </ul> 
          </ul> 

          <ModalPrompt 
            showModal={logoutShow} 
            action={()=>{
              logout()
              navigate('/login', { replace: true })
            }} 
            closeModal={()=> setLogoutShow(false)}
            message={'Are you sure you want to logout?'}/> 
        </div> 
     </React.Fragment>

  )
}

export default EstateManagerSidebarContent

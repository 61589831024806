import React, { useEffect, useState }  from 'react' 
import { useForm } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { EnterprisePageTitle } from '../../../../../../../Components'
import { corperateUser } from '../../../../../../../Models/enterprise.types'
import corperateUserService from '../../../../../../../Services/corperateUserService' 
import wareHouseService from '../../../../../../../Services/wareHouseService'
import "./EnterpriseEditUser.scss"
 
 
const EnterpriseEditUser = () => {
  const {uuid} = useParams<{uuid:string}>()
  const navigate = useNavigate()

  const [sendingRequest, setSendingRequest] = useState(false); 
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState({}); 

  const [warehouse, setWarehouse] = useState([]); 
   
   const [selectedOption, setselectedOption] = useState<string>("")

   const handleChange = (event: React.SetStateAction<any>) => { 
      console.log(`Option selected:`, event) 
    setselectedOption(event.target.value)

  }; 

   const {register, handleSubmit, setValue, reset, formState: {errors}} = useForm<corperateUser>()
 

   const submit = handleSubmit(async (data)=>{

       setSendingRequest(true)
       await corperateUserService.create({...data, role: selectedOption}).then((res)=>{

        setSendingRequest(false)
        toast.success("Corperate user created successfully", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        reset()
       }, error => {
        setSendingRequest(false)
        console.log("buyerPickUpLocation", error.response)
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
     
       })
   })


   const getUserProfile = async () => { 
    await corperateUserService.profile(uuid)
      .then((res: { data: { data: corperateUser } }) => { 
        console.log("rr",res.data.data)
        setData(res.data.data)
        setValue("firstName", res.data.data?.firstName)
        setValue("lastName", res.data.data?.lastName)
        setValue("phoneNumber", res.data.data?.msisdn)
        setValue("emailAddress", res.data.data?.emailAddress)
      }, (error:any) => { 
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

 
  const getWareHouse = async () => { 
    await wareHouseService.index()
      .then((res) => { 
        console.log("rr",res.data.data)
        setWarehouse(res.data.data)
      }, (error:any) => { 
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    getUserProfile();
    getWareHouse();
  }, [uuid]) 
 
 

    return (
        <div className='enterpriseEditUser'>
              <div className="container">
                <EnterprisePageTitle
                    title="User Profile"
                    parent="User Management"
                    parentUrl="user-management"
                    showTitle={false}
                />
              <div className="form-container">
                <button onClick={()=>navigate("/enterprise/user-management", {replace:true})} className='btn btn-back p-0 border-0'> 
                    <svg className="me-3" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.9998 19.9181L8.47984 13.3981C7.70984 12.6281 7.70984 11.3681 8.47984 10.5981L14.9998 4.07812" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg> 
                    User Profile</button>
                <form onSubmit={submit}>
                <div className="row mt-4">
                    <div className="col-12 col-md-6">
                        <div className="form-input">
                            <label htmlFor="firstname">First Name</label>
                            <input type="text" id="firstname" className="form-control" {...register("firstName")}/>
                            {errors.firstName && <div className='text-danger'>{errors.firstName.message}</div>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-input">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" id="lastName" className="form-control" {...register("lastName")}/>
                            {errors.lastName && <div className='text-danger'>{errors.lastName.message}</div>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-input">
                            <label htmlFor="emailAddress">Email Address</label>
                            <input type="text" id="emailAddress" className="form-control" {...register("emailAddress")}/>
                            {errors.emailAddress && <div className='text-danger'>{errors.emailAddress.message}</div>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-input">
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <input type="text" id="phoneNumber" className="form-control" {...register("phoneNumber")}/>
                            {errors.phoneNumber && <div className='text-danger'>{errors.phoneNumber.message}</div>}
                        </div>
                    </div>
                    <div className="col-12">
                        <hr />
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='role-title'>Assign User Role</div>
                        <div className="form-input">
                            <label htmlFor="firstname">Role </label> 
                                <input className="form-control dropdown-toggle" value={selectedOption} data-bs-toggle="dropdown" aria-expanded="false"/>
                                    
                                <ul className="dropdown-menu">
                                    <li className="dropdown-item"><input type="checkbox" id="wareMgr" onChange={handleChange} value="WARE_HOUSE_LEVEL"/> <label htmlFor='wareMgr'>Warehouse Manager</label></li>
                                    <li className="dropdown-item"><input type="checkbox" id="accountMngr" onChange={handleChange} value="ACCOUNT_LEVEL"/> <label htmlFor='accountMngr'>Account Manager</label></li> 
                                </ul>  
                        </div>
                    </div>


                    <div className="col-12 col-md-6">  
                        <div className="form-input mt-md-5">
                            <label htmlFor="warehouse" className='mt-md-3'>Assign to warehouse</label>
                            <select id="warehouse" className="form-control" {...register("wareHouseUuid")} >
                                {warehouse.map((res:{uuid:string, name:string}, index: React.Key | null | undefined)=><option key={index} value={res.uuid}>{res.name}</option>)}
                            </select>
                            {errors.wareHouseUuid && <div className='text-danger'>{errors.wareHouseUuid.message}</div>}
                        </div>
                    </div>

                    <div className="col-md-12"> 

                       {sendingRequest ===false && <button type="submit" className="btn btn-add" disabled>Update User</button>}
                       {sendingRequest ===true && <button type="button" className="btn btn-add" disabled>
                           <span className='spinner-border spinner-border-sm'></span>
                        </button>}
                    </div> 
                </div>
              </form>
              </div>
            </div>
            <Toaster></Toaster>
        </div>
    )
}

export default EnterpriseEditUser
 


import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import "./ModalPrompt.scss" 
import  Radium from 'radium'; 
import { bounce } from 'react-animations';


interface ModalPromptModalPropType {
    showModal: boolean;
    action: Function;
    closeModal: Function;
    message: string;
}

const ModalPrompt:FC<ModalPromptModalPropType> = ({ showModal, closeModal, action, message }) => {
  const [show, setShow] = useState(false)
  const handleClose = () => {
    closeModal()
    setShow(false)
  }

  useEffect(() => {
    
    setShow(showModal)
    
  }, [showModal])

  function init () {
    action()
    handleClose()
  }

  const styles:any = {
    bounce: { 
      animation: '1s',
      animationName: Radium.keyframes(bounce, 'bounce')
    }
  }

  return (   
    <Radium.StyleRoot> 
            <Modal show={show} onHide={handleClose} contentClassName="modal-prompt-inner-main" style={styles.bounce}>
                <Modal.Header closeButton className='border-0'> </Modal.Header>
                <Modal.Body>
                    <div className='row justify-content-center'>
                        <div className='col-3 text-center my-auto'>
                            <div className='icon-container rounded-circle shadow-md'>
                                <i className='d-icon d-information-circle'></i>
                            </div>
                        </div>

                        <div className='contText mt-4 col-12  text-center my-auto' dangerouslySetInnerHTML={{ __html: message }}></div> 

                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'> 
                    <div className="row w-100 justify-content-center"> 
                        <div className='col-6 col-md-4 text-end'>
                            <button  className='btn btn-pill btn-sm btn-outline-dark me-2' onClick={init}>
                                Yes
                            </button> 
                        </div>
                        <div className='col-6 col-md-4 text-start'>
                            <button  className='btn btn-sm btn-outline-danger ms-2' onClick={handleClose}>
                                No
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
             </Modal>  
    </Radium.StyleRoot>
  )
}

export default ModalPrompt

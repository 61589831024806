import React from 'react'
import { Link } from 'react-router-dom'
import happyMan from "../../../../../../Assets/Images/man-happy.png"
import "./TestimonyAds.scss"

const TestimonyAds = () => {
  return (
    <React.Fragment>

        <div className='testimonyAds'>
            <div className="container-fluid">
                <div className="row h-100">
                    <div className="col-6 col-sm-5 col-md-6 my-auto">
                        <div className="ps-3 testimony-ads-content">
                            <h2>
                                Buy Now<br/> Pay Later
                            </h2>
                            <div className="desc">Apply for Product Financing</div>
                            <Link to={`/buyer`}><button className="btn">Apply Now</button></Link>
                        </div>
                    </div>
                    <div className="col-6 col-sm-7 col-md-6 align-self-end">
                        <div className="testimonial-image">
                          <img src={happyMan} className="w-100" alt=""/>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </React.Fragment>
  )
}

export default TestimonyAds

import axios from 'axios'
import { wareHouse } from '../Models/enterprise.types';
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()
 
const wareHouseService = {
  async index () {
    return axios.get("/warehouse");
  },
  
  async create (data: wareHouse) {
    return axios.post("/warehouse", data);
  }, 

  async update (data: wareHouse, wareHouseUuid:string) {
    return axios.patch(`/warehouse/${wareHouseUuid}`, {
      name: data.name,
      contactFullName: data.contactFullName,
      contactPhoneNumber: data.contactPhoneNumber,
      isDefault: data.isDefault
    });
  },

  async delete ( wareHouseUuid:string) {
    return axios.delete(`/warehouse/${wareHouseUuid}`);
  }, 

  async getDetails ( wareHouseUuid:string) {
    return axios.get(`/warehouse/${wareHouseUuid}`);
  }, 
  
  async getInventory ( wareHouseUuid:string|undefined, pageNumber:number, sortOrder:string) {
    return axios.get(`/warehouse/${wareHouseUuid}/product_purchase?pageNumber=${pageNumber}&sortOrder=${sortOrder}`);
  }, 
  
  async orderItems ( wareHouseUuid:string|undefined, pageNumber:number, sortOrder:string) {
    // return axios.get(`/reorderlevel/${wareHouseUuid}?pageNumber=${pageNumber}&sortOrder=${sortOrder}`);
    return axios.get(`/reorderlevel/${wareHouseUuid}`);
  }, 

  async reorderLevel ( data: {  wareHouseUuid: string | undefined, productUuid: string, level: number  }) {
     return axios.post('/reorderlevel', data);
  }, 

  async getSiteOrder ( siteId: string| undefined) {
    return axios.get(`/warehouse/delivery-to-site/${siteId}/site`);
 }, 

 async getSingleSiteOrder ( siteId: string| undefined) {
   return axios.get(`/warehouse/delivery-to-site/${siteId}`);
}, 

 async getSiteOrderAccept ( wareHouseUuid: string, deliveryRequestUuid:string | undefined, data:{status:string, paymentVariant:string}) {
  return axios.post(`/warehouse/${wareHouseUuid}/delivery-to-site/${deliveryRequestUuid}/ACCEPT`, data);
},

async getSiteOrderDecline ( wareHouseUuid: string| undefined, deliveryTositeUuid:string| undefined, data:{status:string}) {
 return axios.post(`/warehouse/${wareHouseUuid}/delivery-to-site/${deliveryTositeUuid}/DECLINE`, data);
} 
  
}
export default wareHouseService

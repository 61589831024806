import React, { useEffect } from 'react'
import "./Products.scss" 
import { ProductItem, ProductItemSkeleton } from '../../Components' 
import {   useGetProduct } from '../../../../Hooks'
import { HorizontalLoader } from '../../../../Components'
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query'

const ProductVariants = () => {   


 

  const { productUuid } = useParams<{ productUuid:string }>()

  const queryClient = useQueryClient();
  const { isSuccess, data: productDetail, isLoading, isFetching, refetch: getProduct } = useGetProduct(productUuid);

  useEffect(() => {
        
    window.scrollTo(0, 0)
    getProduct()

    return () => {
      queryClient.invalidateQueries(['productsSingle'])
    }
  }, [productUuid])
   
  useEffect(() => {
   console.log("productDetail", productDetail)
  }, [productDetail])
  

  return (
        <div className='products mb-5'>
            <div className="container my-2"> 
              <div className="ps-2">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/">Home</a></li>
                      <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">All Products</a></li>
                    </ol>
                  </nav>
                </div> 
                {isLoading && <span className='spinner-border spinner-border-sm'></span>}
              <div className="row section-header mt-5">
                <div className="col-12 col-md-7 col-xl-9 ps-3 my-auto">
                  <h2><b className="me-1">{(isLoading === true || isFetching === true) ? <span className='spinner-border spinner-border-sm'></span> : productDetail?.variantsProducts.length + ' products variants available'}</b> </h2>
                </div>
                <div className="col-4 col-md-2 col-xl-1 p-0 my-auto text-end">
                   
                </div>
                <div className="col-4 col-md-1 p-0 my-auto">

                  
                </div>
                <div className="col-4 col-md-2 col-xl-1 p-xl-0 my-auto">
                 </div>
              </div>
            </div>

            <div className="container">
                <div className="row">
                   {isSuccess && isFetching === false && productDetail?.variantsProducts?.length > 0 && productDetail.variantsProducts?.map((res, index:React.Key) => <ProductItem data={res} key={index}></ProductItem>)}
                   {(isLoading === true || isFetching=== true) && <ProductItemSkeleton cardCount={10}></ProductItemSkeleton>}
                </div>
                
                <div>
                   {(isLoading === true || isFetching === true) && <HorizontalLoader></HorizontalLoader>}
                </div>
 
            </div>  
        </div>
  )
}

export default ProductVariants

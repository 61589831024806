import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import "./BuyerPageTitle.scss"

interface propsTypes {
    title:string;
    parent:string|null;
    parentUrl:string|null;
    showTitle?:boolean;
}
const BuyerPageTitle:FC<propsTypes> = ({ title, parent, parentUrl , showTitle}) => {
  return (
        <div className='buyerPageTitle'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    {parent && <li className="breadcrumb-item"><Link to={`/buyer/${parentUrl}`}>{parent}</Link></li>}
                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                </ol>
            </nav>
            {showTitle? <h2 className='ps-0 ms-0'>{title}</h2> : ""}
        </div>
  )
}

export default BuyerPageTitle

import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import statsService from '../../../../../../Services/statsService'
import { BestSelling, PendingQuoteRequest, Revenue, Statistics } from '../../Component'
import "./SellerHome.scss"
 
const SellerHome = () => {
  const [statsData, setStatsData] = useState({
    totalRevenueMajor: 0,
    totalRevenueCurrency: "NGN",
    totalRevenueCurrencySymbol: "₦",
    totalOrdersCount: 0,
    totalPendingOrdersCount: 0,
    totalPendingQuoteRequestsCount: 0,
    monthEarnings: [],
    yearEarnings: []
  })
  const [statsLoaading, setStatsLoading] = useState(false)

  const getStats = async () => {
    setStatsLoading(true)
    await statsService.getAll().then((res: any) => {
      console.log("STATISTICS INFORMATION", res.data.data)
      setStatsData(res.data.data)
      setStatsLoading(false)
    }, (error: any) => {
      console.log(error)
      setStatsLoading(false)
      toast.error("", { duration: 20000, className: 'bg-danger text-white' });
    })
  }

  useEffect(() => {
    getStats()
  }, [])



  return (
    <div className='home-seller-inner pb-5'>
      <div className="page-title">Account Overview</div>
      <div className='container-fluid px-0'>
         <div className="row">   
          <div className="col-md-8 col-home">
            <Revenue statsLoaading={statsLoaading} statsData={statsData}></Revenue>
          </div>

          <div className="col-md-4 col-home">
            <Statistics statsLoaading={statsLoaading} statsData={statsData}></Statistics>
          </div>

          <div className="col-md-8 col-home bottom-row">
            <BestSelling></BestSelling>
          </div>
          <div className="col-md-4 col-home bpttom-row">
            <PendingQuoteRequest></PendingQuoteRequest>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellerHome

import React, { useEffect, useState, FC } from 'react'
import "./StoreAddProduct.scss"
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import productService from '../../../../../../../Services/productService';
import toast, { Toaster } from 'react-hot-toast';
// import { BRAND_FAILURE, BRAND_SUCCESS } from '../../../../../../../../Constants/BrandConstants';
import brandService from '../../../../../../../Services/brandService';
import country from "../../../../../../../Data/country.json"
import state from "../../../../../../../Data/stateNG.json"
import lgas from "../../../../../../../Data/lgaNG.json"
import Modal from 'react-bootstrap/esm/Modal';
import { CATEGORY_SUCCESS, CATEGORY_FAILURE } from '../../../../../../../Constants/CategoryConstants';
import { CurrencyFormatter, FilterSearch } from '../../../../../../../Helpers';
import { CalculateCommission } from '../../../../../../../Helpers/CalculateCommission';

interface propType {
  showNewProductModal: boolean;
  setShowNewProductModal: any;
  setShowParentModal: any;
}
const StoreAddProduct: FC<propType> = ({ showNewProductModal, setShowNewProductModal, setShowParentModal }) => {
  const { handleSubmit, register } = useForm()

  const [categories, setCatgories] = useState([])
  const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false)
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState<boolean>(false)
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false)
  const [comp, setComp] = useState(1)
  const [checkMainPrice, setCheckMainPrice] = useState(0)

  const dispatch = useDispatch()
  const [brands, setBrands] = useState([])
  const [images, setImages] = useState<any>([])

  // eslint-disable-next-line no-unused-vars
  const [loadingBr, setLoadingBr] = useState<boolean>(false)

  // eslint-disable-next-line no-unused-vars
  const [uploadedProduct, setUploadedProduct] = useState<any>({})
  const [savePreviewData, setSavePreviewData] = useState({ description: "", price: "", minQty: "", maxQty: "", contactFullName: "", contactPhoneNumber: "", address: "", locationState: '{}' })

  const [selectStates, setSelectedStates] = useState<any>([])

  const [selectLgas, setSelectedLgas] = useState<any>([])

  // eslint-disable-next-line no-unused-vars
  const [selectedCountry, setCountry] = useState<any>({
    id: "",
    sortname: "",
    name: "",
    phoneCode: ""
  })
  const [showLgaPriceRow, setShowLgaPriceRow] = useState<boolean>(false)

  const [lgaPriceRow, setLgaPriceRow] = useState([
    { localGovernmentArea: "", price: "0" }
  ])
  const [toggleBrandDropDown, setToggleBrandDropDown] = useState(false)

  const [activeCategory, setActiveCategory] = useState("");
  const [activeBrandUuid, setActiveBrandUuid] = useState("");

  const getAllCategory = async () => {
    setLoader(true)
    await productService.getAllCategory().then((res: any) => {
      setLoader(false)
      setCatgories(res.data.data)
      dispatch({ type: CATEGORY_SUCCESS, data: res.data.data })
    }, (error: any) => {
      toast.error(error.message, { duration: 20000, className: 'bg-danger text-white' });
      dispatch({ type: CATEGORY_FAILURE, data: error.message })
    })
  }

  useEffect(() => {
    const abortController = new AbortController()
    // getBrand()
    getAllCategory()
    return () => abortController.abort()
  }, [])

  const onsubmit = handleSubmit((data: any) => {
    processForm(data)
  })

  const processForm = async (data: any) => {
    if (activeBrandUuid === "") {
      toast.error('Brand field cannot be empty', { duration: 20000, className: 'bg-danger text-white' });
      return 0
    }

    // serialize data
    const datas = {
      name: data.name,
      description: data.description,
      categoryUuid: data.categoryUuid,
      brandUuid: activeBrandUuid,
      price: data.price ? data.price : 0,
      locationState: JSON.parse(data.locationState).name,
      minQty: data.minQty,
      maxQty: data.maxQty,
      newPickupAddress: {
        contactPhoneNumber: data.contactPhoneNumber,
        contactFullName: data.contactFullName,
        state: JSON.parse(data.locationState).name,
        country: JSON.parse(data.country).name,
        address: data.address
      },
      localGovernmentAreaPrices: lgaPriceRow[0].price === "0" ? null : lgaPriceRow
      //   pickupAddressUuid: data
    }
    console.log("data00", datas)

    setLoadingProduct(true)
    await productService.createProduct(datas)
      .then(async (res: any) => {
        setLoadingProduct(false)
        setUploadedProduct(res.data.data)
        console.log("Add product success", res)
        toast.success("Proccessing images", { duration: 20000, className: 'bg-white shadow-md text-secondary' });
        await startUpload(res.data.data.productUuid)
      }, (error: any) => {
        setLoadingProduct(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
        console.log("Add product error", error.response)
      })
  }

  const handleFileUpload = async (event: { target: any }) => {
    setLoadingPhoto(true)
    console.log(event.target.files[0]);

    setImages([...images, ...event.target.files])
    console.log(event.target.files[0].name);
    setLoadingPhoto(false)
  };

  const startUpload = async (productUuid: any) => {
    const arraySize = images.length
    for (const [index, currentProductImage] of images.entries()) {
      const formData = new FormData();

      // Update the formData object
      formData.append(
        "file",
        currentProductImage
      );
      await productService.file(productUuid, formData).then((res: any) => {
        console.log("FILE UPLOAD", currentProductImage)
        toast.success("Photo saved successfully", { duration: 20000, className: 'bg-white shadow-md text-secondary' });
        setLoadingPhoto(false)
        if (index === arraySize - 1) {
          toast.success("Upload completed", { duration: 20000, className: 'bg-success shadow-md text-white' });
          // handleShow()
          setShowNewProductModal(true);
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        }
      }, (error: any) => {
        setLoadingPhoto(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
        console.log(error.response)
        console.log(error)
      })
    }
  }

  const handleCountryChange = (e: any) => {
    const allState = state[2].data

    setSelectedStates(allState)

    const cont = JSON.parse(e.target.value)
    setCountry(cont)
  }

  const savePreview = handleSubmit((data: any) => {
    console.log(data)
    setComp(0)
    setSavePreviewData(data)
  })

  const getLocation = JSON.parse(savePreviewData?.locationState)

  const  checkPrice = (value: any) => {
    setCheckMainPrice(value)
  }

  const getBrandByCategory = async (catUuid: string) => {
    console.log("catUuid catUuid", catUuid)
    setActiveCategory(catUuid)
    setLoadingBr(true)
    const isAddProduct = true;
    await brandService.getCategoryByBrand(catUuid, isAddProduct).then((data: any) => {
      console.log("getCategoryByBrand all", data)
      if (data.data.status && data.data.data !== null) {
        setBrands(data.data.data)
      } else {
        setLoadingBr(false)
        setBrands([])
        console.log("getCategoryByBrand error", data.data.message)
      }
    }, (error: any) => {
      setBrands([])
      setLoadingBr(false)
      console.log("getCategoryByBrand error", error.response)
      //   dispatch({ type: BRAND_FAILURE, data: error.response.data.error })
    })
  }

  const removeImage = (i: number) => {
    const filteredItems = images.slice(0, i).concat(images.slice(i + 1, images.length))
    setImages(filteredItems)
  }

  const handleAddFormRow = (e: any) => {
    e.preventDefault()
    const values = [...lgaPriceRow];
    values.push({
      localGovernmentArea: "",
      price: ""
    });
    setLgaPriceRow(values);
  };

  const handleRemoveFormRow = (index: number) => {
    const values = [...lgaPriceRow];
    values.splice(index, 1);
    setLgaPriceRow(values);
  };

  const handleFormRowInputChange = (index: number, event: { target: { name: string; value: string } }) => {
    const values: any[] = [...lgaPriceRow];
    const updatedValue: any = event.target.name;
    values[index][updatedValue] = event.target.value;
    setLgaPriceRow(values);
  };

  const handleState = (e: any) => {
    const state_id = JSON.parse(e.target.value).id;
    const localGovt: any = lgas[2].data;
    const getLga: any = localGovt.filter((res: any) => Number(res.state_id) === Number(state_id))
    setSelectedLgas(getLga)
  }

  const togleBrDrop = () => {
    setToggleBrandDropDown(!toggleBrandDropDown)
  }

  // eslint-disable-next-line no-unused-vars
  const handleChangeBrand = (e: any) => {
    console.log(e)
  }
  const handleActiveBrand = (e: any) => {
    console.log(e)
  }

  const [brandFilter, setBrandFilter] = useState<any>([])

  const [activeBrand, setActiveBrand] = useState({
    imageUrl: "",
    name: "",
    productsCount: "",
    uuid: ""
  })
  const [brandSearch, setBrandSearch] = useState(true)

  const handleOnChangeBrand = async (e: { target: { value: any; }; }) => {
    console.log("search categories", categories)
    const value = e.target.value;
    setActiveBrand({
      imageUrl: "",
      name: value,
      productsCount: "",
      uuid: ""
    })
    if (value.length > 2) {
      const search = await FilterSearch(brands, value);
      console.log("search STATEsk", search)
      setBrandFilter(search)
      if (search.length >= 1 && value.length > 1) setBrandSearch(true)
      if (search.length === 0) setBrandSearch(false)
    } else {
      console.log("search STATEsd", brands)
      console.log("search STATEs vale", value.length)
      setBrandFilter(brands)
      if (value.length === 0) setBrandSearch(true)
      if (value.length > 2) setBrandSearch(false)
    }
  }

  const createBrand = async () => {
    const toastId = toast.loading("Adding new brand, please wait")
    await brandService.createBrand(activeCategory, activeBrand.name).then((data: any) => {
      console.log("createBrand", data.data.data)
      setActiveBrandUuid(data.data.data.uuid)
      setBrandSearch(true)
      toast.dismiss(toastId);
      toast.success("Brand added", { duration: 20000, className: 'bg-success shadow-md text-white' });
    }, (error: any) => {
      toast.dismiss(toastId);
      console.log("createBrand error", error.response)
      toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger shadow-sm text-white' });
    })
  }

  const handleClose = () => {
    setShowNewProductModal(false);
    setShowParentModal(true);
  };

  const brandAction = (data: any) => {
    setActiveBrandUuid(data.uuid)
    handleActiveBrand(data);
    setActiveBrand(data);
    togleBrDrop()
  }

  return (
    <div className='addproduct'>

      <Modal show={showNewProductModal} onHide={handleClose}>
        {comp === 1 && <>
          <Modal.Header closeButton>
            <Modal.Title><h4 className="modal-title">Add Product</h4></Modal.Title>
          </Modal.Header>
          <Modal.Body className='addproduct-modal'>

            <form onSubmit={onsubmit}>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Product Name' {...register("name", { required: "This field is required" })}></input>
                {/* {errors.name && <div className='text-danger'>{errors.name.message}</div>} */}
              </div>
              <div className='form-group'>
                <select className='form-control' {...register("categoryUuid", { required: "This field is required", onChange: (e) => getBrandByCategory(e.target.value) })}>
                  <option>--Select product category--</option>
                  {categories.length > 0 && categories.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((res: any, index: React.Key) => {
                    return <option key={index} value={res.uuid}>{res.name}</option>
                  })}
                </select>
                {/* {errors.categoryUuid && <div className='text-danger'>{errors.categoryUuid.message}</div>} */}
              </div>

              <div className='form-group'>
                <div className="dropdown-form-element">
                  <div className="dropdown-select">
                    <input type="text" onClick={togleBrDrop} className="form-control" value={activeBrand.name} placeholder="Type or select brand from list" {...register("brandUud", { required: "This field is required", onChange: (e) => handleOnChangeBrand(e) })}></input>
                    {!brandSearch && <span className="bg-success text-white select-btn" onClick={() => createBrand()}>
                      <i className="fa fa-plus"></i>
                    </span>}
                  </div>
                  {toggleBrandDropDown && brandFilter.length > 0 && <ul className="dropdown-list shadow border rounded">
                    {brandFilter.length > 0 && brandFilter.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((res: any, index: React.Key) => {
                      return <li className="dropdown-item" onClick={() => brandAction(res)} key={index}>{res.name}</li>
                    })}
                  </ul>}
                  {/* {errors.brandUud && <div className='text-danger'>{errors.brandUud.message}</div>} */}
                </div>
              </div>

              <div className='form-group d-none'>
                <input type="number" className='form-control' value={0} placeholder='Price (N)' {...register("price", { onChange: (e: any) => checkPrice(e.target.value) })}></input>
                {checkMainPrice !== 0 && <div className='commissionText'>Price shown to buyer: {CurrencyFormatter(Number(checkMainPrice) + Number(CalculateCommission(checkMainPrice)))}</div>}
                {/* {errors.price && <div className='text-danger'>{errors.price.message}</div>} */}
              </div>

              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-6">
                    <div className='form-group'>
                      <input type="number" className='form-control' placeholder='Min Order Quantity' {...register("minQty", { required: "This field is required" })}></input>
                      {/* {errors.minQty && <div className='text-danger'>{errors.minQty.message}</div>} */}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className='form-group'>
                      <input type="number" className='form-control' placeholder='Max Order Quanitity' {...register("maxQty", { required: "This field is required" })}></input>
                      {/* {errors.maxQty && <div className='text-danger'>{errors.maxQty.message}</div>} */}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className='form-group'>
                      <select className='form-control' {...register("country", { required: "This field is required", onChange: (e: any) => handleCountryChange(e) })}>
                        <option>--Country--</option>
                        {country.countries.length > 0 && country.countries.map((res: any, index: React.Key) => {
                          return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                        })}
                      </select>
                      {/* {errors.country && <div className='text-danger'>{errors.country.message}</div>} */}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className='form-group'>
                      <select className='form-control' {...register("locationState", { required: "This field is required", onChange: (e) => handleState(e) })}>
                        <option>--State--</option>
                        {selectStates.map((result: { id: string | number | readonly string[] | undefined; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => {
                          return <option key={index} value={JSON.stringify(result)}>{result.name}</option>
                        })}
                      </select>
                      {/* {errors.locationState && <div className='text-danger'>{errors.locationState.message}</div>} */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div>
                      {lgaPriceRow.length > 0 &&
                        (<>
                          {
                            lgaPriceRow.map((field, index: number, array) => (
                              <>
                                <div key={index} className={showLgaPriceRow && Number(index) >= 1 ? "row my-1 d-none" : "row my-1"}>
                                  <div className="col-6">
                                    <div className='form-group'>
                                      <select className='form-control' onChange={(event) => handleFormRowInputChange(index, event)} name="localGovernmentArea" >
                                        {field.localGovernmentArea ? <option>{field.localGovernmentArea}</option> : <option>--choose Lga--</option>}
                                        {selectLgas.length > 0 && selectLgas.map((res: { name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => <option key={index}>{res.name}</option>)}
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-5">
                                    <div className='form-group'>
                                      <input type="number" name="price" placeholder='Price for local government' className='form-control' onChange={(event) => handleFormRowInputChange(index, event)} value={field.price} />
                                      {array.length - 1 === index && <div className='mt-2 text-end'><a href="" className="btn btn-outline-success px-2 p-0 add-new-price" onClick={(e) => handleAddFormRow(e)}> <i className="fa fa-plus"></i> Add Price</a></div>}
                                    </div>
                                  </div>
                                  <div className="col-1 mt-4 p-0">
                                    {index >= 1 && array.length > 1 && <span className="btn btn-sm btn-danger border-0 rounded-circle btn-remove" onClick={() => handleRemoveFormRow(index)}> <i className='fa fa-minus'></i> </span>}
                                  </div>
                                </div>
                                {index === 0 && lgaPriceRow.length >= 2 && <div className="toggle-form-row"><span className='btn btn-sm' onClick={() => setShowLgaPriceRow(!showLgaPriceRow)}>{!showLgaPriceRow ? "Hide" : "Show"}</span></div>}
                              </>
                            ))
                          }
                        </>)}
                    </div>
                  </div>

                  <div className="col-6">
                    <div className='form-group'>
                      <input type="text" className='form-control' placeholder='Contact Name' {...register("contactFullName", { required: "This field is required" })}></input>
                      {/* {errors.contactFullName && <div className='text-danger'>{errors.contactFullName.message}</div>} */}
                    </div>
                  </div>

                  <div className="col-6">
                    <div className='form-group'>
                      <input type="number" className='form-control' placeholder='Contact Phone' {...register("contactPhoneNumber", { required: "This field is required" })}></input>
                      {/* {errors.contactPhoneNumber && <div className='text-danger'>{errors.contactPhoneNumber.message}</div>} */}
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <input className='form-control' placeholder='Address' {...register("address", { required: "This field is required" })}></input>
                  {/* {errors.address && <div className='text-danger'>{errors.address.message}</div>} */}
                </div>
              </div>
              <div className="container-fluid px-0 uploadImg my-4">
                <h4 className='title'>Upload Image</h4>
                <div className='desc my-2'>Atleast 1 image of the product </div>
                <div className="row">
                  <div className="col-2">
                    <div className='addbtn'>
                      <label htmlFor="contained-button-file"><i className='d-icon d-add'></i></label>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      id="contained-button-file"
                      className='d-none'
                      onChange={handleFileUpload}
                    />
                    {loadingPhoto && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                  </div>
                  <div className="col-10">
                    <div className="container-fluid px-0">
                      <div className="row">

                        {images.map((res: any, index: React.Key) => {
                          return <div className="col-3" key={index}>
                            <div className='uploadedImgCont p-1 mb-1'>
                              <span className='btn rounded-circle p-1' onClick={() => removeImage(Number(index))}> <i className='fa fa-times text-danger'></i></span>
                              <img src={URL.createObjectURL(res)} className="w-100"></img>
                            </div>
                          </div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='inst mt-2'>Each picture must not exceed 5 Mb, Supported formats are *.jpg, *.gif and *.png</div>
              </div>

              <div className='form-group'>
                <textarea className='form-control' placeholder='Description' {...register("description", { required: "This field is required" })}></textarea>
                {/* {errors.description && <div className='text-danger'>{errors.description.message}</div>} */}
              </div>

              <div className='btn-container mt-5 row'>
                <div className='col-12 col-sm-6'>
                  <a className='btn btn-preview w-100 mb-1' onClick={savePreview}>Preview</a>
                </div>
                <div className='col-12 col-sm-6'>
                  {!loadingProduct && (<button type="submit" className="btn btn-upload w-100 mb-1" disabled={images.length === 0}>Upload</button>)}
                  {loadingProduct && (<button type="submit" className="btn btn-upload w-100 mb-1" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>)}
                </div>
              </div>
            </form>
          </Modal.Body></>
        }

        {comp === 0 && <>
          <Modal.Header closeButton>
            <Modal.Title>

              <h4 className="modal-title"> <button className='btn' onClick={() => setComp(1)}><i className='d-icon d-arrowhead-left'></i> </button> Add new product - Preview</h4></Modal.Title>
          </Modal.Header>
          <Modal.Body className='addproduct-modal'>
            {images.length > 0 && <div className="col-12">
              <div className='uploadedImgCont p-1 mb-1'>
                <img src={URL.createObjectURL(images[0])} className="w-100"></img>
              </div>
            </div>
            }

            <div className="container-fluid px-0">
              <div className="row">

                {images.length > 0 && images.map((res: any, index: React.Key) => {
                  return <div className="col-3" key={index}>
                    <div className='uploadedImgCont p-1 mb-1'>
                      <img src={URL.createObjectURL(res)} className="w-100"></img>
                    </div>
                  </div>
                })}
                {images.length === 0 && <div className="col-12 text-danger">
                  No image selected
                </div>
                }

              </div>
            </div>
            <h4>{getLocation.name}</h4>
            <p>{savePreviewData.description}</p>

            <div className='container-fluid'>
              <div className="row">
                <div className="col-6 mt-2">
                  <div className='text-capitalize fw-bold'>price</div>
                  <div>{savePreviewData.price}</div>
                </div>
                <div className="col-6 mt-2">
                  <div className='text-capitalize fw-bold'>min Qty</div>
                  <div>{savePreviewData.minQty}</div>
                </div>
                <div className="col-6 mt-2">
                  <div className='text-capitalize fw-bold'>max Qty</div>
                  <div>{savePreviewData.maxQty}</div>
                </div>
                <div className="col-6 mt-2">
                  {/* {console.log(JSON.parse(savePreviewData?.locationState))} */}
                  <div className='text-capitalize fw-bold'>State</div>
                  <div>{getLocation.name}</div>
                </div>
                <div className="col-6 mt-2">
                  <div className='text-capitalize fw-bold'>contact Full Name</div>
                  <div>{savePreviewData.contactFullName}</div>
                </div>
                <div className="col-6 mt-2">
                  <div className='text-capitalize fw-bold'>contact Phone Number</div>
                  <div>{savePreviewData.contactPhoneNumber}</div>
                </div>
                <div className="col-6 mt-2">
                  <div className='text-capitalize fw-bold'>Address</div>
                  <div>{savePreviewData.address}</div>
                </div>
              </div>
              <div className='mt-5'>
                {!loadingProduct && (<button type="submit" className="btn-upload-prev border-0" onClick={onsubmit}>Upload</button>)}
                {loadingProduct && (<button type="submit" className="btn btn-upload-prev border-0" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>)}
              </div>
            </div>

            <div className="container-fluid px-0">
              <div className="row">
              </div>
            </div>

          </Modal.Body>

        </>}
      </Modal>
      <Toaster />
    </div>
  )
}

export default StoreAddProduct

// import { useForm } from "react-hook-form";
import { FC } from "react";
import { Toaster } from "react-hot-toast"; 
import "./BusinessInformation.scss"; 
import { ErrorMessage } from "../Seller/Component";

interface propTypes {
  businessName: string,
  cacNumber: string,
  businessAddress: string
  handleChange: any;
  handleBlur: any;
  formikErrors: any;
  formikTouchedValues: any;

}

const BusinessInformation: FC<propTypes> = (prop) => {
  const { businessName, cacNumber, businessAddress, handleChange, handleBlur, formikErrors, formikTouchedValues} = prop
 

  return (
    <div className="business-info">
      <div className="row">
        <div className="col-md-8 ">
          <div className="card biz-form-card p-6  border-0">
            <p className="d-none d-md-block">
              Enter your business information to start selling on Cinderbuild
            </p>
            <div className="card-body p-0">
              <div className="row">
                <div className="biz-form col-4 col-md-12 col-lg-6 p-0   d-md-block d-lg-block">
                  <div className="form-group mt-4">
                    <label htmlFor="businessName">Business Name</label>
                    <input
                      className="form-control input"
                      id='businessName'
                      type="text"
                      name="businessName"
                      value={businessName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Business Name "
                    />
                    {
                      formikErrors.businessName && formikTouchedValues.businessName &&
                      <ErrorMessage error={formikErrors.businessName} />
                    }


                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="cacNumber">CAC Number</label>
                    <input
                      className="form-control input"
                      id='cacNumber'
                      type="text"
                      name="cacNumber"
                      value={cacNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="cacNumber"
                    />
                    {
                      formikErrors.cacNumber && formikTouchedValues.cacNumber &&
                      <ErrorMessage error={formikErrors.cacNumber} />
                    }

                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="businessAddress">Business Address</label>
                    <input
                      className="form-control input"
                      id='businessAddress'
                      type="text"
                      name="businessAddress"
                      value={businessAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Business Address "
                    />
                    {
                      formikErrors.businessAddress && formikTouchedValues.businessAddress &&
                      <ErrorMessage error={formikErrors.busibessAddress} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};
export default BusinessInformation;

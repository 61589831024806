import React, { FC } from 'react' 
import "./OrderCompletedDetails.scss"
import locationslected from "../../../../../Assets/Images/icon/location-slected.png"
import profile from "../../../../../Assets/Images/icon/profile.png"
import { useAuth } from '../../../../../Components/AuthProvider/AuthProvider'

interface propsType {
  orderDetails:any
}
const OrderCompletedDetails:FC<propsType> = ({ orderDetails }) => {
  console.log("orderDetails ---", orderDetails)
  const { user } = useAuth()
  return (
        <div className='orderCompletedDetails mt-4'>
           <div className="card border rounded">
            <div className="card-header bg-white  border-0 title py-2">
               Payment and delivery information
            </div>
            <div className="card-body">
                <ul className="list-group order-details border-0">
                    <li className="list-group-item d-block p-0 py-2  border-start-0 border-end-0 rounded-0">
                        <div className="title mb-2">
                            Payment method
                        </div>
                        <div className="value">{orderDetails && orderDetails[0]?.orderPaymentVariant}</div>
                    </li>

                    {orderDetails && orderDetails[0].deliveryType === "DELIVERY" && <li className="list-group-item p-0 py-2 d-block border-top-0 border-start-0 border-end-0 rounded-0  ">
                        <div className="title mb-2">
                        <img src={locationslected}></img> Delivery address
                        </div>
                        <div className='value'>{orderDetails && orderDetails[0]?.deliveryAddressUuid?.address}</div>
                    </li>}
                    {orderDetails && orderDetails[0].orderReceiveType === "DELIVERY" && <li className="list-group-item p-0 py-2 d-block border-top-0 border-start-0 border-bottom-0 border-end-0 rounded-0  ">
                        <div className="title mb-2">
                          <img src={profile}></img> Contact details
                        </div>
                         <div className="value">{orderDetails && orderDetails[0]?.deliveryAddressUuid?.contactFullName}</div>
                         <div className="value">{orderDetails && orderDetails[0]?.deliveryAddressUuid?.contactPhoneNumber}</div>
                    </li>}
                    {!user && <li className="list-group-item p-0 py-2 d-block border-top-0 border-start-0 border-bottom-0 border-end-0 rounded-0  ">
                        <div className="title mb-2">
                          <img src={profile}></img> Contact details
                        </div>
                        {orderDetails && orderDetails[0]?.pickupContactInformation && <>
                         <div className="value"><b>Full Name : </b>{orderDetails[0]?.pickupContactInformation?.fullName}</div>
                         <div className="value"><b>Phone : </b>{orderDetails[0]?.pickupContactInformation?.msisdn}</div>
                         <div className="value"><b>Email : </b>{orderDetails[0]?.pickupContactInformation?.emailAddress}</div>
                         </>}

                        {orderDetails && orderDetails[0]?.newDeliveryAddress && <>
                         <div className="value"><b>Full Name : </b>{ orderDetails[0]?.newDeliveryAddress?.contactFullName}</div>
                         <div className="value"><b>Phone : </b>{orderDetails[0]?.newDeliveryAddress?.contactPhoneNumber}</div>
                         <div className="value"><b>Email : </b>{orderDetails[0]?.newDeliveryAddress?.email}</div>
                         </>}
                    </li>}
                    {orderDetails && orderDetails[0].deliveryType === "PICKUP" && <li className="list-group-item p-0 py-2 d-block border-top-0 border-start-0 border-bottom-0 border-end-0 rounded-0  ">
                        <div className="title mb-2">
                          <img src={profile}></img> Pickup Address
                        </div>
                        {orderDetails[0].pickupLocations.sellers.map((res: { address: string }, index:React.Key) => {
                          return <div className="value" key={index}>- {orderDetails && res.address}</div>
                        })}
                     </li>}
                </ul>
             </div>
           </div>
        </div>
  )
}

export default OrderCompletedDetails

import React, { FC, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import "./ChekoutSigup.scss"
import { NEW_DELIVERY_ADDRESS } from '../../../../../Constants/CheckoutConstants';
import { db } from '../../../../../Config/DBConfig';
import { useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { ErrorHandler } from '../../../../../Helpers/ErrorHandler';
import authService from '../../../../../Services/authService';
import { Logger } from '../../../../../Helpers/Logger';
import Cookie from 'cookie-universal';

interface propTypes {
    orderDetails: any
}

const ChekoutSigup:FC<propTypes> = ({ orderDetails }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [preview, setPreview] = useState<boolean>(true)

  const location = useLocation();

  const cookies = Cookie()
  cookies.set("referer", location.pathname)

  const navigate = useNavigate();
  const [error, setError] = useState<any>(null)

  // eslint-disable-next-line no-unused-vars
  const [submit, setSubmit] = useState(false)

  const dispatch = useDispatch()

  const [fields, updateFields] = useState({
    address: null,
    country: null,
    state: null,
    contactFullName: null,
    contactPhoneNumber: null,
    firstname: null,
    lastname: null,
    password: null,
  })

  const deliveryiNFO = orderDetails?.newDeliveryAddress

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target; 
    updateFields({
      ...fields,
      [name]: value,
    })

    const urgentData = {
      ...fields,
      [name]: value,
    }

    saveAddress(urgentData)
    dispatch({ type: NEW_DELIVERY_ADDRESS, payload: fields })
  }

  useEffect(() => {
    updateFields(deliveryiNFO)
  }, [deliveryiNFO])

  const saveAddress = async (urgentData: { address?: string | null; country?: string | null; state?: string | null; contactFullName?: string | null; contactPhoneNumber?: string | null; firstname: string | null; lastname: string | null; password?: string | null; }) => {
    Logger("{ name, value }", fields)
    await db.orderDetails.update(orderDetails.id, { newDeliveryAddress: { ...urgentData, contactFullName: urgentData.firstname + ' ' + urgentData.lastname } })
      .then(function (updated) {
        if (updated) {
          // toast.success(`Delivery address added`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        } else {
          toast.error(`Nothing was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }
      });
  }

  const processSignup = async () => {
    setSubmit(true)

    const datas = {
      lastName: deliveryiNFO.lastname,
      firstName: deliveryiNFO.firstname,
      countryLongName: "Nigeria",
      phoneNumber: deliveryiNFO.contactPhoneNumber,
      password: deliveryiNFO.password,
      findUsOption: "normal_user",
      isSeller: false,
      role: "normal_user",
      emailAddress: deliveryiNFO.email,
      defaultSellerUniqueCode: ""
    }
    Logger("datas", datas)
    await authService.register(datas)
      .then(
        (data:any) => {
          setSubmit(false)
          // Logger("data", data)
          if (data.data.status === false) {
            // Logger(data.message)
            setError(data.data.message)
            setSubmit(false)
          } else if (data.data.status === true && data.data.data.verificationCode) {
            localStorage.setItem("temp__reg", JSON.stringify(datas))
            navigate("/otp", { replace: true });
          }
        },
        (error:any) => {
          setSubmit(false)
          ErrorHandler(error,
            () => {
              setError(error.message)
              toast.error(error.response.data.error, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        });
  }

  const [terms, setTerms] = useState(false)
  const handleTerms = (e: any) => {
    setTerms(e.target.value)
  }
  return (
        <div className='chekoutSigup'>
            <div className="desc">
            Your sign up process is in progress. You need to create your password to complete your sign up.
            {/* We have begin signing you up on CinderBuild using the contact details you provided. You need to create your password to complete your sign up. */}
            </div>

            <div className="card">
            <div className="card-body">
                {preview
                  ? <div className='row'>
                <div className="col-6">
                    <div className="instruction">* You are signing up as a buyer</div>
                </div>
                <div className="col-6 text-end">
                    <div className="edit-title-values">
                        <button className='btn btn-sm p-1 edit' onClick={() => setPreview(!preview)}>
                            Edit
                        </button>
                    </div>
                </div>
                <div className="col-6">
                    <div className="title">First name</div>
                    <div className="title-value">{deliveryiNFO?.firstname}</div>
                </div>
                <div className="col-6">
                    <div className="title">Last name</div>
                    <div className="title-value">{deliveryiNFO?.lastname}</div>
                </div>
                <div className="col-6">
                    <div className="title">Email address</div>
                    <div className="title-value">{deliveryiNFO?.email}</div>
                </div>
                <div className="col-6">
                    <div className="title">Phone number</div>
                    <div className="title-value">{deliveryiNFO?.contactPhoneNumber}</div>
                </div>
                </div>
                  : <div className="row">
                    <div className="col-6">
                        <div className="instruction">* You are signing up as a buyer</div>
                    </div>
                    <div className="col-6 text-end"></div>
                    <div className="col-md-6">
                        <div className='form-input'>
                            <label htmlFor="first_name">First name *</label>
                            <input type="text"
                                id="first_name"
                                name="firstname"
                                className='form-control'
                                placeholder='Enter your first name'
                                defaultValue={deliveryiNFO ? deliveryiNFO.firstname : ""}
                                onChange={(e) => handleChange(e)}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='form-input'>
                            <label htmlFor="last_name">Last name *</label>
                            <input
                            type="text"
                            id="last_name"
                            name="lastname"
                            className='form-control'
                            placeholder='Enter your last name'
                            defaultValue={deliveryiNFO ? deliveryiNFO.lastname : ""}
                            onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='form-input'>
                            <label htmlFor="email">Email address * </label>
                            <input
                            type="text"
                            id="email"
                            name="email"
                            className='form-control'
                            placeholder='Enter your email address'
                            defaultValue={deliveryiNFO ? deliveryiNFO.email : ""}
                            onChange={(e) => handleChange(e)}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='form-input position-relative'>
                            <label htmlFor="phone_number">Phone number * </label>
                            <input type="text" id="phone_number" name="contactPhoneNumber" className='form-control phoneContact' placeholder=''
                            onChange={(e) => handleChange(e)}
                            defaultValue={deliveryiNFO ? deliveryiNFO.contactPhoneNumber : ""}
                            />
                            <div className='countryCode'>+234</div>
                        </div>
                    </div>
                  </div>

  }
            </div>
            </div>

                <div className="section-title">Create password</div>
                <div className="section-label">Password</div>
                <div className="form-group password-container">
                    <input
                            className="form-control"
                            id="password-field"
                            type={!showPassword ? "password" : "text"}
                            placeholder="Password"
                            name="password"
                            onChange={(e) => handleChange(e)}
                            required
                            autoComplete='off'
                    />
                    {!showPassword && <button className="btn p-0 field-icon" onClick={() => setShowPassword(true)}><i className="fas fa-eye"></i></button>}
                    {showPassword && <button className="btn p-0 field-icon" onClick={() => setShowPassword(false)}><i className="fas fa-eye-slash" aria-hidden="true"></i></button>}
                </div>

                <label htmlFor="terms_conditions" className="d-flex align-items-center terms">
                <input type="checkbox" id="terms_conditions" className="me-2" onChange={handleTerms}/> <span> I accept the <Link to="">Terms & conditions</Link> and <Link to="">Privacy policy</Link></span>
            </label>
            {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                  <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                  <div className="text-danger">
                  {error}
                  </div>
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>}

            {!submit && (<button className='btn btn-primary' onClick={processSignup} disabled={!terms}>Complete sign up</button>)}
            {submit && (<button type="submit" className="btn btn-primary" disabled>
                Please wait
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>)}

           <Toaster></Toaster>
     </div>
  )
}

export default ChekoutSigup

import React from 'react' 
import { Navigate, Outlet } from 'react-router-dom'   
import { useAuth } from '../AuthProvider/AuthProvider'
import EstateManagerWrapper from '../../Pages/Skeleton/WrapperAuth/EstateManagerWrapper/EstateManagerWrapper'

const ProtectedEstateManagers = () => {
 
  const { user } = useAuth()  
  
  if (!user) { 
    return <Navigate to="/login" />
  }  
  
  switch (true) {
    case user.isInvestor:
      return <EstateManagerWrapper> <Outlet/></EstateManagerWrapper> 
    case user.isDeveloper:
      return <EstateManagerWrapper> <Outlet/></EstateManagerWrapper> 
    case user.isCooperate:
      return <Navigate to="/enterprise/dashboard" />
    case user.isSeller:
      return <Navigate to="/seller/dashboard" />
    default:
      return <Navigate to="/" />
  }
}

export default ProtectedEstateManagers;


 
import React, { ChangeEvent, useEffect , useMemo, useState } from 'react'
import "./EstateSubscriptionDetails.scss"
import { CbdModal, EstatePageTitle, HorizontalLoader } from '../../../../../../../Components'
import { Link, useNavigate, useParams } from 'react-router-dom'
// import esatet2447 from "../../../../../../../Assets/Images/estate/sub-project.png"
import WalletInformation from '../../../Components/EstateDashboard/WalletInformation/WalletInformation';
import toast, { Toaster } from 'react-hot-toast'
import { projectDetailsType } from '../../../../../../../Models/projectType'
import projectService from '../../../../../../../Services/projectService'
import { CurrencyFormatter } from '../../../../../../../Helpers'
import ImageSrc from '../../../../../../../Components/ImageSrc/ImageSrc';
import { SubscribeToProject } from '../../../Components/Index';
import Swal from 'sweetalert2'

export interface createSubscriptionType
    {
        projectUuid: string|undefined,
        numberOfSlot: number|undefined,
        totalCost: number|undefined
      }

const EstateSubscriptionDetails = () => {

    const [balance, setBalance] = useState(0)

    const { numberOfSlot } = useParams<{numberOfSlot:string|undefined}>()
    
    const { projectUuid } = useParams<{ projectUuid: string|undefined }>()
       
    const [showStatusModal, setShowStatusModal] = useState(false) 

    const [loadingRequest, setLoadingRequest] = useState(false)  
    const [project, setProject] = useState<projectDetailsType>()

    const [manualAmountToBePaid, setManualAmountToBePaidHandler] = useState(0)
    
    const getProject = async () => { 
        setLoadingRequest(true)
        await projectService.getSingle(projectUuid).then((response: { data: { data: React.SetStateAction<projectDetailsType | undefined> } })=>{
          console.log("projectService", response) 
          setLoadingRequest(false)
          setProject(response.data.data)
        }, error => { 
            setLoadingRequest(false) 
            toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
     
            console.log("projectService error", error)
        })
    }

    useEffect(()=>{
        getProject()
    }, [projectUuid])

    const remainingBalanceRaw = useMemo(()=>{
        return project &&  ((project?.costPerSlot * Number(numberOfSlot)) - (project?.costPerSlot * Number(numberOfSlot)) * (project.initialInvestmentPercentage/100)) 
   }, [numberOfSlot, project])

   const totalCost = useMemo(()=>{
             return project && ((project?.costPerSlot * Number(numberOfSlot)) * (project.initialInvestmentPercentage/100) )
        }, [numberOfSlot, project])

//    const remainingBalance = useMemo(()=>{
//         return remainingBalanceRaw && (CurrencyFormatter(remainingBalanceRaw))
//     }, [remainingBalanceRaw])


    const totalProjectAmount = project && (project?.costPerSlot * Number(numberOfSlot))

    const monthlyPayment = useMemo(()=>{
        return project && remainingBalanceRaw? CurrencyFormatter(Number(remainingBalanceRaw) / Number(project?.duration)):0.0
    }, [remainingBalanceRaw])

    const amountToBePayable =  project && ((project?.costPerSlot * Number(numberOfSlot)) * (project.initialInvestmentPercentage/100))
    const amountToBePaid =  project && (manualAmountToBePaid === 0? ((project?.costPerSlot * Number(numberOfSlot)) * (project.initialInvestmentPercentage/100)): manualAmountToBePaid)

    // the remaining amount calculated if a user change the default amount to be paid or the default will be calcualted based on the initial preset percentage
    const newRemaningAmount = totalProjectAmount && amountToBePaid && (totalProjectAmount - amountToBePaid)
 
    const monthlyPayable = project && newRemaningAmount && (newRemaningAmount / project.duration)

    const [terms, setTerm] = useState(true)
    const [request, setRequest] = useState(false)
    
    const navigate = useNavigate()

    
    const createSubscription = async () =>{
        setRequest(true) 
        const newTotalCost = manualAmountToBePaid === 0? totalCost: manualAmountToBePaid;
        await projectService.createSubscription({ projectUuid, numberOfSlot:Number(numberOfSlot), totalCost: newTotalCost }, 'WALLET').then((response)=>{
            setRequest(false)
            
            Swal.fire({
                title: "Congratulations!",
                text: "Your have succesfully invested in this project",
                icon: "success",
                confirmButtonText: "Ok",
              }).then((result) => { 
                if (result.isConfirmed) {  
                    navigate(`/estate-managers/subscriber-portfolio/${response.data.data.projectSubscriptionUuid}`, { replace: true })  
                }  
              });
            
            console.log("Create Subscription response", response)
        }, (error) =>{
            setRequest(false)   
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: { 
                  cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
              });
              swalWithBootstrapButtons.fire({
                title: "Oops!",
                text: error.response.data.error,
                icon: "warning",
                showCancelButton: true, 
                showConfirmButton: false, 
                cancelButtonText: "close",
                reverseButtons: true
              });

            console.log("project subscription Service error", error)
        })
    }

  

    return (
        <div className='EstateSubscriptionDetails'> 
            <div className="container">
                {project && <EstatePageTitle
                    title={project?.name}
                    parent="Projects"
                    parentUrl="subscriber-projects"
                    showTitle={true}
                />}
            </div>
            <div className="container">
                {loadingRequest && <HorizontalLoader></HorizontalLoader>}
                {project && <div className="row">
                    <div className="col-md-7 mt-2">
                         <div className="card border-light shadow-sm p-md-3">
                            <div className="card-image">
                                <ImageSrc src={project.images[0]?.url} alt={project.name} title={project.name} width={'w-100'}></ImageSrc>
                                {/* <img src={esatet2447} className='w-100'></img> */}
                                {numberOfSlot && <div className="featured-text">
                                   {numberOfSlot} unit
                                </div>}
                            </div>
                            <div className="card-body p-1">
                                <div className="row p-0 mt-3"> 
                                    <div className="col-6">
                                        <div className="price">
                                            ₦{CurrencyFormatter(project?.costPerSlot * Number(numberOfSlot))} <small className='d-none'>total cost</small>
                                        </div>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <span className='units'>
                                            <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00187 8.95297C9.15063 8.95297 10.0819 8.02172 10.0819 6.87297C10.0819 5.72422 9.15063 4.79297 8.00187 4.79297C6.85312 4.79297 5.92188 5.72422 5.92188 6.87297C5.92188 8.02172 6.85312 8.95297 8.00187 8.95297Z" stroke="#797A7C" strokeWidth="1.5"/>
                                                <path d="M2.41184 5.66065C3.72517 -0.112681 12.2785 -0.106014 13.5852 5.66732C14.3518 9.05399 12.2452 11.9207 10.3985 13.694C9.0585 14.9873 6.9385 14.9873 5.59184 13.694C3.75184 11.9207 1.64517 9.04732 2.41184 5.66065Z" stroke="#797A7C" strokeWidth="1.5"/>
                                            </svg>{project.state}</span>
                                    </div>
                                </div>

                                <div className="project-title mt-1"> 
                                    {project.name}
                                </div>
                                <div className="project-description mt-1">
                                    {project.details}
                                </div>
 

                                <div className="details mt-3">
                                    <ul>
                                        <li>
                                            <span>Project Type:</span>
                                             {project.type}
                                        </li>
                                        <li>
                                            <span>Current Stage:</span>
                                             -
                                        </li>
                                        <li>
                                            <span>Number of Units:</span> 
                                            {numberOfSlot}
                                        </li>
                                        <li>
                                            <span>Initial Percentage:</span>
                                            {project.initialInvestmentPercentage}%
                                        </li>
                                        <li>
                                            <span>Duration:</span> 
                                            {project.duration} months
                                        </li>
                                    </ul>
                                </div>
                                 
                                    <button className='btn w-100 mt-4' onClick={()=>setShowStatusModal(true)}>
                                        <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.0819 11.9999C16.0819 13.9799 14.4819 15.5799 12.5019 15.5799C10.5219 15.5799 8.92188 13.9799 8.92188 11.9999C8.92188 10.0199 10.5219 8.41992 12.5019 8.41992C14.4819 8.41992 16.0819 10.0199 16.0819 11.9999Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12.4998 20.2707C16.0298 20.2707 19.3198 18.1907 21.6098 14.5907C22.5098 13.1807 22.5098 10.8107 21.6098 9.4007C19.3198 5.8007 16.0298 3.7207 12.4998 3.7207C8.96984 3.7207 5.67984 5.8007 3.38984 9.4007C2.48984 10.8107 2.48984 13.1807 3.38984 14.5907C5.67984 18.1907 8.96984 20.2707 12.4998 20.2707Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> Edit Number of nits
                                    </button> 
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 mt-2">
                         <div className="card border-light shadow-sm">
                            <div className="card-body"> 
                              <div className="amount-label">Amount to be Paid</div>
                               <div className="amount">₦{CurrencyFormatter(amountToBePaid??0)}</div>
                                
                                <label htmlFor="amountToBePayable" className="amount-label">Set initial payment</label> 
                                <input type="range" id="amountToBePayable" className="form-range" min={amountToBePayable} max={totalProjectAmount} step="50" onChange={(event:ChangeEvent<HTMLInputElement>):void=>{ setManualAmountToBePaidHandler(Number(event.target.value)) }}></input>

                                 <div className="card additiontal-details my-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6 fw-bolder">Remaining amount:</div>  
                                            <div className="col-6 text-end">₦{CurrencyFormatter((totalProjectAmount??0) - (amountToBePaid??0))}</div>
                                            <div className="col-6 fw-bolder">Monthly payment:</div>
                                            <div className="col-6 text-end d-none">₦{monthlyPayment}</div>
                                            <div className="col-6 text-end">₦{CurrencyFormatter((monthlyPayable??0))}</div>
                                        </div> 
                                
                                    </div>
                                 </div>
                                 {balance < ((project?.costPerSlot * Number(numberOfSlot)) * (project.initialInvestmentPercentage/100)) && <div className="alert">
                                  Your balance is low. fund wallet to continue 
                               </div>}

                               <WalletInformation balance={(amount)=>setBalance(amount)}></WalletInformation>
                               
                               <div className="terms mt-5 mb-3">
                                    <input type='checkbox' onChange={(e)=>setTerm(!terms)}></input>
                                    I accept the <Link to="/terms-and-conditions">Terms and Conditions</Link>
                               </div>
                               <button className='btn btn-pay-now' onClick={createSubscription} disabled={terms || request}>
                                {request && <span className='spinner-border spinner-border-sm'></span>}
                                Pay Now
                               </button>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>


            <CbdModal showModal={showStatusModal} closeModal={ () => setShowStatusModal(false)} title={''}>
                <SubscribeToProject handleClose={() => setShowStatusModal(false)} numberOfSlot={numberOfSlot}></SubscribeToProject>
            </CbdModal>

            <Toaster></Toaster>
        </div>
    )
}

export default EstateSubscriptionDetails

import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import "./EstatePageTitle.scss"

interface propsTypes {
    title:string;
    parent:string|null;
    parentUrl:string|null;
    showTitle?:boolean|undefined;
}
const EstatePageTitle:FC<propsTypes> = ({ title, parent, parentUrl, showTitle }) => {
  return (
        <div className='estatePageTitle'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/estate-managers/">Home</Link></li>
                    {parent && <li className="breadcrumb-item"><Link to={`/estate-managers/${parentUrl}`}>{parent}</Link></li>}
                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                </ol>
            </nav>
            {showTitle !==false && <h2 className='ps-0 ms-0'>{title}</h2>}
        </div>
  )
}

export default EstatePageTitle

import React, { useEffect, useState } from 'react'
import "./EnterpriseReorder.scss"   
import { EnterprisePageTitle, HorizontalLoader, ImageSrc } from '../../../../../../Components'; 
import toast, { Toaster } from 'react-hot-toast';
import wareHouseService from '../../../../../../Services/wareHouseService';
import { useAllWarehouse } from '../../../../../../Hooks/Queries/Enterprise/useAllWarehouse';
import { db } from '../../../../../../Config/DBConfig';
import { useDispatch } from 'react-redux';
import { TOGGLE_CART_DRAWER } from '../../../../../../Constants/CartConstants'; 
import productService from '../../../../../../Services/productService';
 
const EnterpriseReorder = () => { 
   const [warehouseProduct, setWarehouseProduct] = useState<any>([])
   const [warehouseProductProcess, setWarehouseProductProcess] = useState(false)

     

      const {data:warehouse, isLoading, isSuccess, isError, error, refetch } = useAllWarehouse()
      console.log("pppppp", {warehouse, isLoading, isSuccess, isError, error })
     
      useEffect(() => {
       refetch()
       }, [])  
     
      const getReorder = async (e: { target: { value: string | undefined; }; }) => {
        setWarehouseProductProcess(true)
        await wareHouseService.orderItems(e.target.value, 1, 'DESC')
        .then((res) => { 
            setWarehouseProductProcess(false)
          console.log("rr 000000",res.data.data)
          setWarehouseProduct(res.data.data)
        }, (error:any) => { 
            setWarehouseProductProcess(false)
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
      }


      const dispatch = useDispatch()

      const addtocart = async (dataPayload:any) => {
       
        const loader = toast.loading("Adding to cart, please wait a moment.")
        console.log("productService", dataPayload)
           
        try {
           await  productService.getSingleProduct(dataPayload.produtUuid).then(async (res:any)=>{
             console.log("productService", res.data.data)
           
                // Add the new cart item!
             await db.cart.add({
                productUuid: res.data.data.productUuid,
                quantity: dataPayload.wareHouseDetail.totalQuantity,
                productDetails: res.data.data
            });  

            toast.dismiss(loader)
            dispatch({ type: TOGGLE_CART_DRAWER, payload: { active: true, placement: "end" } })
         
           })
        } catch (error) {  
            toast.dismiss(loader)
            toast.error(`Failed to add ${name}: ${error}`, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }
      }

      
      const addBulkToCart = async () => {
       if ( warehouseProduct.length > 0) {
        // eslint-disable-next-line no-unused-vars
        for (const [index, warehouseProductData] of warehouseProduct.entries()) {
            await addtocart(warehouseProductData)
        } 
      } 
    }
    

    return (
        <div className='enterpriseReorder'>
             <div className="container">
                <EnterprisePageTitle
                    title="Reordering"
                    parent="Order Management"
                    parentUrl="order-management"
                    showTitle={false}
                />

                <div className="row">

                    <div className="col-12">
                       <div className="desc-top"> 
                       Select warehouse to preview list of items to reorder. This list of items can be edited once you get to your cart with the Reorder Now button
                       </div>
                    </div>
                    
                    <div className="col-12 col-lg-3"> 
                        <div className="filter-warehouse">
                            <select className='btn-filter' name="wareHouseUuid" id="" onChange={getReorder}>
                                <option>All Warehouses:</option>
                                {isSuccess && warehouse.length > 0 && warehouse.map((res: { name:string; uuid:string }, index:React.Key)=><option key={index} value={res.uuid}>{res.name}</option>)}
                            </select>

                            <div className='filter-icon'> 
                            Warehouses: 
                            </div>  
                        </div> 
                    </div>


                    {isSuccess && warehouseProduct.length > 0? 
                        <div className="col-8 col-lg-3"> 
                            <button className='btn btn-new-reorder' onClick={()=>addBulkToCart()}> 
                                Reorder {isSuccess && warehouseProduct.length > 0? warehouseProduct.length : 0} Product{warehouseProduct?.length > 1 && 's'} Now
                            </button> 
                        </div>: "" }
            </div>
            {isSuccess && warehouseProduct.length > 0?  <React.Fragment>
                <div className="row mt-4">
                    <div className="col-12 col-lg-6">
                       <div className="reorder-count">{isSuccess && warehouseProduct.length > 0? warehouseProduct.length : 0} Product{warehouseProduct?.length > 1 && 's'} to reorder</div>
                    </div>
                    <div className="col-12 col-lg-3">
                      <div className="seach-field">
                        <input type="text" className='search-input form-control' placeholder='Search product'/>
                        <svg className="search-icon" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6752 11.5657C9.3418 13.8991 5.55859 13.8991 3.22518 11.5657C0.891767 9.23226 0.891767 5.44905 3.22518 3.11564C5.55859 0.782229 9.3418 0.782229 11.6752 3.11564C14.0086 5.44905 14.0086 9.23226 11.6752 11.5657ZM11.6752 11.5657L16.4128 16.3033" stroke="#868484" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>  
                      </div>
                    </div>
                    <div className="col-8 col-lg-3">

                        <div className="filter-product">
                            <select className='btn-filter' name="" id="">
                                <option>All Product</option>
                            </select>

                            <div className='filter-icon'> 
                                Filter: 
                            </div>  
                        </div> 
                    </div> 
                </div>


                <div className="enterprise-table">
                   <div className="table-responsive">
                        <table className="table rounded"> 
                            <thead className='rounded'>
                                <tr className='d-none d-md-table-row'>
                                <th scope="col">ID</th>
                                <th scope="col">Product</th>
                                <th scope="col" className='text-center'>Reorder point</th> 
                                <th scope="col" className='text-center'>Available in stock</th>
                                <th scope="col" className='text-center'>Quantity to Reorder</th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {isSuccess && warehouseProduct.length > 0? warehouseProduct.map((res:{level:number, productName: string, avalailableInStock:number, wareHouseDetail:{totalQuantity:number}}, index: number)=><React.Fragment key={index}> 
                                <tr className='d-none d-md-table-row'>
                                    <td>00{Number(index+1)}</td>
                                    <td>
                                        <ImageSrc src={''} alt={''} title={undefined} width={'product-image'}></ImageSrc>
                                        {res.productName}</td>
                                    <td className='text-center'>{res.level}</td> 
                                    <td className='text-center'>{res.avalailableInStock}</td> 
                                    <td className='text-center'>{res.wareHouseDetail.totalQuantity}</td> 
                                    <td>
                                       {res.avalailableInStock >= res.wareHouseDetail.totalQuantity && <button className='btn btn-reorder' onClick={()=>addtocart(res)}>
                                            <svg className="me-2" width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.0655 3.30562H4.04305L5.45305 1.89562C5.67055 1.67812 5.67055 1.31813 5.45305 1.10063C5.23555 0.883125 4.87555 0.883125 4.65805 1.10063L2.28805 3.47062C2.23555 3.52312 2.19805 3.58312 2.16805 3.65062C2.13805 3.71812 2.12305 3.79312 2.12305 3.86812C2.12305 3.94312 2.13805 4.01812 2.16805 4.08562C2.19805 4.15312 2.23555 4.21312 2.28805 4.26562L4.65805 6.63563C4.77055 6.74813 4.91305 6.80062 5.05555 6.80062C5.19805 6.80062 5.34055 6.74813 5.45305 6.63563C5.67055 6.41813 5.67055 6.05813 5.45305 5.84062L4.04305 4.43062H13.0655C13.9955 4.43062 14.753 5.18812 14.753 6.11812V8.60812C14.753 8.91563 15.008 9.17062 15.3155 9.17062C15.623 9.17062 15.878 8.91563 15.878 8.60812V6.11812C15.878 4.56562 14.618 3.30562 13.0655 3.30562Z" fill="#F5A623"/>
                                                <path d="M15.878 14.1306C15.878 14.0556 15.863 13.9806 15.833 13.9131C15.803 13.8456 15.7655 13.7856 15.713 13.7331L13.343 11.3631C13.1255 11.1456 12.7655 11.1456 12.548 11.3631C12.3305 11.5806 12.3305 11.9406 12.548 12.1581L13.958 13.5681H4.93555C4.00555 13.5681 3.24805 12.8106 3.24805 11.8806V9.39062C3.24805 9.08312 2.99305 8.82812 2.68555 8.82812C2.37805 8.82812 2.12305 9.08312 2.12305 9.39062V11.8806C2.12305 13.4331 3.38305 14.6931 4.93555 14.6931H13.958L12.548 16.1031C12.3305 16.3206 12.3305 16.6806 12.548 16.8981C12.6605 17.0106 12.803 17.0631 12.9455 17.0631C13.088 17.0631 13.2305 17.0106 13.343 16.8981L15.713 14.5281C15.7655 14.4756 15.803 14.4156 15.833 14.3481C15.863 14.2806 15.878 14.2056 15.878 14.1306Z" fill="#F5A623"/>
                                            </svg>
                                            Reorder Now
                                        </button>}
                                    </td>
                                </tr>
                                <tr className='d-md-none'>
                                    <td>
                                        <ImageSrc src={''} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                                    </td>
                                    <td colSpan={4}>
                                        <div className="row"> 
                                            <div className='col-12 profile-title'>{res.productName}</div>
                                            <div className='col-12 profile-detail'>Level: {res.level}</div> 
                                            <div className='col-12 profile-detail'>Available in stock: {res.avalailableInStock}</div>
                                        </div>
                                    </td>
                                    <td className='profile-btn'>

                                    {res.avalailableInStock >= res.wareHouseDetail.totalQuantity && <button className='btn btn-reorder' onClick={()=>addtocart(res)}>
                                            <svg className="me-2" width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.0655 3.30562H4.04305L5.45305 1.89562C5.67055 1.67812 5.67055 1.31813 5.45305 1.10063C5.23555 0.883125 4.87555 0.883125 4.65805 1.10063L2.28805 3.47062C2.23555 3.52312 2.19805 3.58312 2.16805 3.65062C2.13805 3.71812 2.12305 3.79312 2.12305 3.86812C2.12305 3.94312 2.13805 4.01812 2.16805 4.08562C2.19805 4.15312 2.23555 4.21312 2.28805 4.26562L4.65805 6.63563C4.77055 6.74813 4.91305 6.80062 5.05555 6.80062C5.19805 6.80062 5.34055 6.74813 5.45305 6.63563C5.67055 6.41813 5.67055 6.05813 5.45305 5.84062L4.04305 4.43062H13.0655C13.9955 4.43062 14.753 5.18812 14.753 6.11812V8.60812C14.753 8.91563 15.008 9.17062 15.3155 9.17062C15.623 9.17062 15.878 8.91563 15.878 8.60812V6.11812C15.878 4.56562 14.618 3.30562 13.0655 3.30562Z" fill="#F5A623"/>
                                                <path d="M15.878 14.1306C15.878 14.0556 15.863 13.9806 15.833 13.9131C15.803 13.8456 15.7655 13.7856 15.713 13.7331L13.343 11.3631C13.1255 11.1456 12.7655 11.1456 12.548 11.3631C12.3305 11.5806 12.3305 11.9406 12.548 12.1581L13.958 13.5681H4.93555C4.00555 13.5681 3.24805 12.8106 3.24805 11.8806V9.39062C3.24805 9.08312 2.99305 8.82812 2.68555 8.82812C2.37805 8.82812 2.12305 9.08312 2.12305 9.39062V11.8806C2.12305 13.4331 3.38305 14.6931 4.93555 14.6931H13.958L12.548 16.1031C12.3305 16.3206 12.3305 16.6806 12.548 16.8981C12.6605 17.0106 12.803 17.0631 12.9455 17.0631C13.088 17.0631 13.2305 17.0106 13.343 16.8981L15.713 14.5281C15.7655 14.4756 15.803 14.4156 15.833 14.3481C15.863 14.2806 15.878 14.2056 15.878 14.1306Z" fill="#F5A623"/>
                                            </svg> 
                                        </button>}
                                    </td>   
                                </tr> 
                                </React.Fragment>)
                                : <tr>
                                    <td  colSpan={7} className="text-center">No item found</td>
                                 </tr>}

                                {warehouseProductProcess? <tr>
                                    <td colSpan={7} className="text-center">loading...</td>
                                </tr>: ""}
                            </tbody> 
                        </table>
                    </div> 
                </div>
                </React.Fragment>:""}
                
                <div className='text-center'>
                {warehouseProductProcess && <HorizontalLoader></HorizontalLoader>}
                </div>
            </div>
            <Toaster></Toaster>
        </div>
    )
}

export default EnterpriseReorder


import React, { useEffect, useState, FC } from 'react'
import CloseIcon from "../../../../../../Assets/Images/icon/close_icon.svg";
import Modal from 'react-bootstrap/Modal';
import "./SellerRequestBankAccountUpdate.scss"
import { useForm } from 'react-hook-form'; 
import bankingFinanceService from '../../../../../../Services/bankingFinanceService';
import toast, { Toaster } from 'react-hot-toast'; 
import { GetLength } from '../../../../../../Helpers/getLength' 
import { useMyBankDetails } from '../../../../../../Hooks/Queries/Enterprise/useMyBankDetails';
import { HorizontalLoader } from '../../../../../../Components';
import { Link } from 'react-router-dom';


 
interface propTypes  {
    showModal:boolean;
    handleClose:any;
} 

interface formProps {
    bankName:string;
    accountNumber:string;
    bankCode:string;
  }
  
const SellerRequestBankAccountUpdate:FC<propTypes> = ({showModal, handleClose}) => {
    
 
    const [savingBankDetails, setSavingBankDetails] = useState(false);
    const [nameSearchRequest, setNameSearchRequest] = useState(false); 
    const [allBank, setAllBank] = useState([]); 
    const [selectedBankObj, setSelectedBank] = useState<any>(null);
    // eslint-disable-next-line no-unused-vars
    const [myAccountNumber, setMyAccountNumber] = useState();
    const [myAccountDetails, setMyAccountDetails] = useState<any>(null);
  
   
    const { handleSubmit, register, formState: { errors } } = useForm<formProps>()
  
    const onsubmit = handleSubmit((data:any) => processForm(data))
  
    const processForm = async (data: any) => {
      setSavingBankDetails(true)
      const bankName = JSON.parse(data.bankName).name
   
      await bankingFinanceService.requestBankAccountUpdate({ ...data, bankName, bankCode: selectedBankObj.code })
        .then((res:any) => {
          setSavingBankDetails(false)
          handleClose()
          toast.success("Bank Account Update Request Sent Succesfully", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
           
        }, (error:any) => {
          setSavingBankDetails(false) 
          handleClose()
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    }
  
    const getAllBank = async () => {
      await bankingFinanceService.getAllBank()
        .then((res) => { 
          setAllBank(res.data.data)
        }, (error:any) => {   
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
       
        })
    }
  
    useEffect(() => {
      getAllBank()
    }, [])
  
    const selectedBank = (e: any) => {
      setSelectedBank(JSON.parse(e.target.value)) 
    }
  
    const selectBankNumber = async (e: { target: { value: any; }; }) => { 
      setMyAccountNumber(e.target.value)
      if (GetLength(e.target.value) >= 10) {
        setNameSearchRequest(true)
       await bankingFinanceService.VerifyAccount({
          accountNumber: e.target.value,
          bankCode: selectedBankObj.code
        }).then((res) => { 
          setNameSearchRequest(false)
          setMyAccountDetails(res.data.data)
        }, (error) => {
          setNameSearchRequest(false)
          setMyAccountDetails(false)
          toast.error(error.message, { duration: 20000, className: 'bg-white text-secondary' }); 
        })
      }
    }



  const {isSuccess, isLoading, data } = useMyBankDetails()
   console.log({isSuccess, isLoading, data })
    return (
        <Modal show={showModal} onHide={handleClose} className="seller-update-bank"> 
            <Modal.Header className="custom-modal-header"> 
                <div className="d-flex flex-row justify-content-between w-100">
                    <div className="custom-modal-close-btn order-2"
                        onClick={handleClose}
                    >   
                        <img src={CloseIcon} alt=''/>
                    </div>
                    <p className="custom-modal-title order-1">Request Account Update</p>
                </div> 
            </Modal.Header>  
            <Modal.Body className="custom-modal-body-buyer">
              {isLoading===true && <HorizontalLoader></HorizontalLoader>}
 
              {isSuccess===true && data ? <React.Fragment>
                <form onSubmit={onsubmit}> 
                      <div className='form-input'>
                          <div className='form-input-wrap'>
                            <label htmlFor="">Bank Name</label>
                              <select id="bankName" className='form-control ps-2 mt-2' placeholder='Bank Name' {...register("bankName", { required: "This field is required", onChange: (e) => { selectedBank(e) } })}>
                                <option>--select bank--</option>
                                {allBank.length > 0 && allBank.map((res:any, index:React.Key) => {
                                  return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                                })}
                              </select>
                              {errors.bankName && <div className="text-danger">{errors.bankName.message}</div>}
                          </div>
                          <div className='form-input-wrap mt-3'>
                            <label htmlFor="">Account Number </label>
                              <input id="accountNumber" type="number" className='form-control ps-2 mt-2' {...register("accountNumber", { required: "This field is required", onChange: (e) => selectBankNumber(e) })} placeholder="Account Number" disabled={!selectedBankObj || nameSearchRequest}/>
                              {nameSearchRequest && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> }
                              {/* {myAccountDetails !== false && myAccountDetails !== null && <span className='text-success fw-bolder'>{myAccountDetails.account_name}</span> } */}
                              {myAccountDetails === false && <span className='text-danger'>Invalid account number</span> }

                              {errors.accountNumber && (<div className="text-danger">{errors.accountNumber.message}</div>)}
                          </div>
                          <div className='form-input-wrap'>
                              <input id="bankCode" type="hidden" className='form-control ps-2 mt-3' placeholder='Bank Code' value={selectedBankObj && selectedBankObj.code} {...register("bankCode")}/>
                              {errors.bankCode && (<div className="text-danger">{errors.bankCode.message}</div>) }
                          </div> 

                          {myAccountDetails !== null && <div className='form-input-wrap'>
                              <input id="bankCode" type="text" className='form-control ps-2 mt-3 disabled' readOnly placeholder='Bank Code' value={myAccountDetails && myAccountDetails.account_name}/>
                              {errors.bankCode && (<div className="text-danger">{errors.bankCode.message}</div>) }
                          </div> }

                          {!savingBankDetails && <button className='btn w-100 mt-4 add-btn text-light'> Send Request </button>}
                          {savingBankDetails && <button className="btn w-100 mt-4 add-btn text-light" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Saving...
                          </button> }
                      </div>
                  </form> 
                </React.Fragment> :
                <React.Fragment>
                  <div className='container'>
                       Your account is currently not eligible to access this service, Click <Link to="/enterprise/banking-and-finance" className='text-warning'>Here</Link> to setup your bank account information.
                  </div>
                </React.Fragment> }
            </Modal.Body> 
            <Toaster></Toaster>
        </Modal>
    )
}

export default SellerRequestBankAccountUpdate;
import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { EnterprisePageTitle } from '../../../../../../Components'
import { Logger } from '../../../../../../Helpers/Logger'
import bankingFinanceService from '../../../../../../Services/bankingFinanceService' 
import "./EnterpriseBankingAndFinance.scss" 
import { EnterpriseAccountBalance, EnterpriseBankDetails, EnterpriseHistory } from '../../Components'
 

const EnterpriseBankingAndFinance = () => {
     // eslint-disable-next-line no-unused-vars
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
  const [myBalance, setMyBalance] = useState({
    currency: "",
    currencySymbol: "",
    amountMajor: ""
  })

  
  const [myBankAccount, setMyBankAccount] = useState({
    bankCode: null,
    bankName: null,
    bankAccountNumber: null,
    bankAccountName: null,

  })

  const getMyBankDetails = async () => {
    setLoadingRequest(true)
    await bankingFinanceService.getMyBankAccount()
      .then((res:any) => {
        setLoadingRequest(false)
        Logger("My bank details", res)
        setMyBankAccount(res.data.data)
      }, (error:any) => {
        setLoadingRequest(false)
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  const getMyBalance = async () => {
    setLoadingRequest(true)
    await bankingFinanceService.getMyBalance()
      .then((res:any) => {
        setLoadingRequest(false)
        setMyBalance(res.data.data)
        Logger("My balance", res.data.data)
      }, (error:any) => {
        setLoadingRequest(false)
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }


  useEffect(() => {
    getMyBalance()
    getMyBankDetails()
  }, [])
  
    return (
        <div className='enterpriseBankingAndFinance'>
            
            <div className="container mt-4">
               <EnterprisePageTitle title={'Banking and Finance'} parent={null} parentUrl={null}></EnterprisePageTitle>

               <div className="row">
                  <div className="col-12 col-md-7">
                      <EnterpriseBankDetails myBankAccount={myBankAccount}></EnterpriseBankDetails>
                  </div>

                  <div className="col-12 col-md-5 mt-4 mt-lg-0">
                      <EnterpriseAccountBalance myBalance={myBalance}></EnterpriseAccountBalance>
                  </div>

                  <div className='col-12'>
                    <div className="my-5 history-container">
                        <EnterpriseHistory></EnterpriseHistory>
                    </div>
                </div>
                
              </div>
              <Toaster></Toaster>
          </div>
        </div>
    )
}

export default EnterpriseBankingAndFinance

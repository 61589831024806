import React from 'react'
import cbcast1 from "./../../../../Assets/Images/cbcast1.jpg"
import cbcast2 from "./../../../../Assets/Images/cbcast2.jpg"
import cbcast3 from "./../../../../Assets/Images/cbcast3.jpg"
import cbcast4 from "./../../../../Assets/Images/cbcast4.jpg"
// import cbcast5 from "./../../../../Assets/Images/cbcast5.jpg"
import "./Resources.scss"
import { HeaderMeta } from '../../../../Components'
import { Link } from 'react-router-dom'

const Resources = () => {
    return (
        <div className='resources'>
            <HeaderMeta title={'Resources'}></HeaderMeta>
            <div className="container my-2">
                <div className="ps-2">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">Resources </a></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container my-2">
                <h1 className='page-title'>
                Resources
                </h1>
            </div> 
            <div className="cb-cast-list pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 my-5">
                            <div className="row">
                                <div className="col-md-6">
                                     <div className="card border-0 my-2">
                                        <Link to="/resources/post-title" className='w-100'>
                                          <img src={cbcast1} alt="" className='cast-item rounded w-100'/>
                                       </Link>
                                        <div className="card-body">
                                            <div className='post-type'>Resources</div>
                                            <Link to="/resources/post-title">
                                               <h2>The Future of Sustainable Construction: Green Building Techniques</h2>
                                            </Link>
                                            <div className='post-by'>By CinderBuild</div>
                                        </div>
                                    </div>  

                                </div>
                                <div className="col-md-6">
                                     <div className="card border-0 my-2">
                                        <Link to="/resources/post-title" className='w-100'>
                                           <img src={cbcast2} alt="" className='cast-item rounded w-100'/>
                                        </Link>
                                       <div className="card-body">
                                            <div className='post-type'>Resource</div>
                                            <Link to="/resources/post-title">
                                              <h2>Exploring Innovative Materials in Modern Construction</h2>
                                            </Link>
                                            <div className='post-by'>By CinderBuild</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                     <div className="card border-0 my-2">
                                        <Link to="/resources/post-title" className='w-100'>
                                           <img src={cbcast3} alt="" className='cast-item rounded w-100'/>
                                         </Link>
                                        <div className="card-body">
                                            <div className='post-type'>Resources</div>
                                            <Link to="/resources/post-title">
                                              <h2>From Blueprint to Reality: A Step-by-Step Guide to Building Construction</h2>
                                            </Link>
                                            <div className='post-by'>By CinderBuild</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                     <div className="card border-0 my-2">
                                        <Link to="/resources/post-title" className='w-100'>
                                           <img src={cbcast4} alt="" className='cast-item rounded w-100'/>
                                        </Link>
                                        <div className="card-body">
                                            <div className='post-type'>Resources</div>
                                            <Link to="/resources/post-title">
                                              <h2>The Importance of Safety in Construction: Best Practices and Tips</h2>
                                            </Link>
                                            <div className='post-by'>By CinderBuild</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resources

import React, { useState } from 'react'
import "./LoginCard.scss"
import { ErrorHandler } from '../../../../../Helpers/ErrorHandler';
import { decodeToken } from 'react-jwt';
import authService from '../../../../../Services/authService';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import ReactGA from 'react-ga'
import { useLocalStorage } from '../../../../../Hooks/UseLocalStorage';

type Inputs = {
    phoneNumber: string;
    password: string;
  };

const LoginCard = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [submit, setSubmit] = useState(false)
  const [error, setError] = useState<any>(null)

  const [, setUser] = useLocalStorage('__cbuN', null)

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();

  const onSubmit = handleSubmit((data:any) => processLogin(data));

  const processLogin = async (data:any) => {
    ReactGA.event({
      category: "Login",
      action: "login",
      label: "login on checkout",
      value: data.phoneNumber,
    })

    setSubmit(true)
    await authService.login(data).then((user:any) => {
      const myDecodedToken:any = decodeToken(user.data.data.token);

      setUser({ ...user.data.data, ...myDecodedToken, ...user.data, switched: 0 })

      setSubmit(false)

      return navigate('/checkout', { replace: true });
    }, (error:any) => { 
      setSubmit(false)
      ErrorHandler(error,
        (e) => {
          setError(e)
        })
    })
  }

  return (
        <div className='loginCard mb-4 mt-4 mt-0'>
           <div className="card">
            <div className="card-body">
                <h1>Sign In</h1>
                <form onSubmit={onSubmit}>
                    <div className="form-group mb-3">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input type="text" id="phoneNumber" className="form-control" autoComplete='off' {...register("phoneNumber", { required: "field cannot be empty" })}/>
                        <div className="err text-danger">{errors.phoneNumber && errors.phoneNumber.message}</div>
                    </div>
                    <div className="form-group password-container">
                        <input
                                className="form-control"
                                id="password-field"
                                type={!showPassword ? "password" : "text"}
                                placeholder="Password"
                                 autoComplete='off'
                                {...register("password", { required: "field cannot be empty" })}
                        />
                        {!showPassword && <button className="btn p-0 field-icon" onClick={() => setShowPassword(true)}><i className="fas fa-eye"></i></button>}
                        {showPassword && <button className="btn p-0 field-icon" onClick={() => setShowPassword(false)}><i className="fas fa-eye-slash" aria-hidden="true"></i></button>}
                        <div className="err text-danger">{errors.password && errors.password.message}</div>
                    </div>

                    {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                    <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                        <div className="text-danger">
                            {error}
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(null)}></button>
                    </div>}

                    <Link to="/forgot-password" className="forgot-password">Forgot Password ?</Link>

                    <div className="form-group mt-4">
                        {!submit && (<button type="submit" className="btn btn-login">Login</button>)}
                        {submit && (<button type="submit" className="btn btn-login" disabled>
                            Please wait
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>)}
                    </div>
                    <div className="signup mt-3">
                        Don’t have an account? <Link to="/register" className="text-primary">Sign up</Link>
                    </div>
                </form>
            </div>
           </div>
        </div>
  )
}

export default LoginCard

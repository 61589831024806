import React, { useState } from 'react'
import "./orderReceiveType.scss"
import pickup from "../../../../../Assets/Images/icon/pickup.png"
import delivery from "../../../../../Assets/Images/icon/delivery.png"
import { db } from '../../../../../Config/DBConfig'
import toast, { Toaster } from 'react-hot-toast'
import { useLiveQuery } from 'dexie-react-hooks'
import { EDIT_DELIVERY_ADDRESS } from '../../../../../Constants/CheckoutConstants'
import { useDispatch } from 'react-redux'

const OrderReceiveType = () => {
  const [orderType, setOrderType] = useState("")
  const dispatch = useDispatch()
  
  const orderDetails = useLiveQuery<any>(
    async () => {
      const result:any[] = await db.orderDetails?.toArray() || []

      result && setOrderType(result[0]?.deliveryType)
      return result;
    }
  );

  const updateType = async (orderTypeParam: string) => {
    setOrderType(orderTypeParam)

    dispatch({ type: EDIT_DELIVERY_ADDRESS, payload: 0 })

    const detailsCount = await db.orderDetails?.count()

    if (detailsCount === 0) {
      await db.orderDetails.add({ deliveryType: orderTypeParam }).then(function (updated) {
        if (updated) {
          // toast.success(`Delivery Type Selected`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        } else {
          toast.error(`Nothing was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }
      });
    } else { 
      await db.orderDetails.update(orderDetails[0]?.id, { deliveryType: orderTypeParam }).then(function (updated) {
        if (updated) {
          // toast.success(`Delivery Type Updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        } else {
          toast.error(`Nothing was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }
      });
    }
  }
  return (
        <div className='orderReceiveType'>
            <div className="ordertype mt-3">
                <div className="container px-1">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <button className={orderType === "DELIVERY" ? "btn active w-100" : "btn w-100"} onClick={() => updateType("DELIVERY") }>
                            <img src={delivery} alt=""/>
                            Delivery</button>
                        </div>
                        <div className="col-12 col-lg-6">
                            <button className={orderType === "PICKUP" ? "btn active w-100" : "btn w-100"} onClick={() => updateType("PICKUP") }>
                            <img src={pickup} alt=""/>
                            Pickup</button>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster></Toaster>
        </div>
  )
}

export default OrderReceiveType

import React, { useEffect, useState } from 'react'
import EnterpriseSidebarContent from '../../../../../../Layout/Enterprise/Sidebar/EnterpriseSidebarContent/EnterpriseSidebarContent';
import { tipsModel } from '../../../../../../Models';
import { useSelector } from 'react-redux';

const EnterPriseMobileMenuTutorial = () => {
 
  const element = useSelector((state: { tutorialRefs: tipsModel; }) => state.tutorialRefs)
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    
    setTimeout(()=>{ 
        setShowMenu(true)
    }, 2000)
     
  }, [element])
  
    return (
        <div>
            {showMenu === true && <EnterpriseSidebarContent element={element}></EnterpriseSidebarContent>}
        </div>
    )
}

export default EnterPriseMobileMenuTutorial

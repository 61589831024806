import React, { PropsWithChildren, useEffect, useState } from 'react'

import "./SellerSingleProducts.scss"
import arrowdown from '../../../../../../../Assets/Images/arrow-down.png';
import arrowtop from '../../../../../../../Assets/Images/arrow-top.png';
import girl from '../../../../../../../Assets/Images/girl.png';
import { Rating } from 'react-simple-star-rating'
import $ from 'jquery';
import { useParams } from 'react-router';
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import productService from '../../../../../../../Services/productService';
import { Logger } from '../../../../../../../Helpers/Logger';
import { ErrorHandler } from '../../../../../../../Helpers/ErrorHandler'; 
import ProductVariantList from '../../../Component/CreateProduct/ProductVariantList/ProductVariantList';
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter';
import { Link } from 'react-router-dom';

interface SingleProductsPropType {
  productName: string|undefined;
  productDescription: string|undefined;
  brand: { name:string|undefined };
  locationState:string|undefined;
  maximumQuantity:number|undefined;
  minimumQuantity:number|undefined;
  price:number|undefined;
  currencySymbol:string|undefined;
  sellerPublicProfile:any;
  images:any;
  localGovernmentAreaPrices: any;
  uuid:string;
  createdAt: string;
}
const SellerSingleProducts = () => {
  const { productUuid } = useParams<{ productUuid:string }>()
  const [ratingValue, setRating] = useState(0)
  const [qty, setQty] = useState<any>(1)
  const [loading, setLoading] = useState(false)
  const [productDetail, setProductDetail] = useState<SingleProductsPropType>({
    productName: '',
    productDescription: '',
    brand: { name: "" },
    locationState: "",
    maximumQuantity: 100,
    minimumQuantity: 1,
    price: 0,
    currencySymbol: "",
    localGovernmentAreaPrices: [
      {
        uuid: "",
        localGovernmentArea: "",
        price: 0,
        currency: 0
      }
    ],
    sellerPublicProfile: {
      userUuid: "",
      firstName: "",
      lastName: "",
      photoUrl: "",
      accountRating: {
        totalRatingsValue: 0,
        totalNumberOfRatings: 0
      },
      businessProfile: {
        businessName: "",
        businessAddress: ""
      }
    },
    images: [{
      url: ""
    }],
    uuid: "",
    createdAt: ""
  })

  // eslint-disable-next-line no-unused-vars
  const [sortOrder, setSortOrder] = useState("DESC")
  const [catUuid, setCatUuid] = useState("") 
  const [productVariant, setProductVariant] = useState<any[]>([])
  const [loadingVariant, setLoadingVariant] = useState(false)

  const handleRating = (rate: number) => {
    setRating(rate)
    // other logic
  }

  const remove = () => {
    if (qty !== 0) {
      setQty(qty - 1);
    }
  }
  const add = () => {
    setQty(qty + 1);
  }

  const [imgSwitch, setImgSwitch] = useState("")
  function imageSwitch (url:string) {
    setImgSwitch(url)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    productDetail && setImgSwitch(productDetail.images[0]?.url)
    return () => toast.dismiss()
  }, [productDetail])

  $(document).ready(() => {
    const next = $("#nexts")
    const previous = $("#previouss")
    $(document).ready(() => {
      $(next).click(() => {
        $(".ulcont").css({
          'margin-top': '-303px',
          transition: 'all 1.5s ease-in-out'
        });
      });
      $(previous).click(() => {
        $(".ulcont").css("margin-top", "0");
      });
    });
  });

  const getProduct = (productUuid:string) => {
    setLoading(true)
    productService.getProduct(productUuid).then((res:any) => {
      setLoading(false)
      setProductDetail(res.data.data)
      setCatUuid(res.data.data.category.uuid)
      Logger(res.data)
    }, (error:any) => {
      toast.error(error.message, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
      setLoading(false)
    })
  }

  useEffect(() => {
    if (productUuid) {
      getProduct(productUuid)
    }
      
  }, [productUuid, sortOrder, catUuid])

  Logger(loading, productDetail)

  function Box ({ children }: PropsWithChildren<unknown>) {
    return (
        <div
            style={{
              border: '1px solid #fff',
              display: 'block',
              lineHeight: 2,
              padding: '0rem',
              marginBottom: '0.0rem',
              width: "100%",
            //   height: 137,
            }}
        >
            {children}
        </div>
    )
  }

  const getProductVariant = async () => {
    setLoadingVariant(true)
    await productService.getProductVariant(productUuid).then((res:any) => {
      setProductVariant(res.data.data)
      setLoadingVariant(false)
      Logger("setProductVariant", res.data.data)
    }, (error:any) => {
      setLoadingVariant(false)
      ErrorHandler(error,
        () => {
          toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
        })
    })
  }

  useEffect(() => {
    getProductVariant()
  }, [])


  const deleteProd = async () => { 
    const deleteId = toast.loading('Deleting product...');
    await productService.deleteProduct(productUuid)
      .then((res) => {
        console.log(res)
        toast.dismiss(deleteId);
        toast.success("Product deleted successfully", { duration: 25000, className: 'bg-white text-dark', position: "top-right" });
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }, (error) => {
        console.log(error.response.data.error)
        toast.error(error.response.data.error, { duration: 25000, className: 'bg-white text-dark', position: "top-right" });
      })
  }

  return (
    <div className="prodDetails">
        <div className="prodDetails-inner mb-5 bg-white shadow-md vh-75">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                 <div className="prodDetails-left m-4">
                  <div className="row">
                    <div className="col-2 d-none d-xl-block">
                        <div className="vertScrolImg">
                          <div className="controls text-center">
                            <a id="previouss" title="previous-image">
                              <img src={arrowtop}/>
                              </a>
                          </div>
                          <div id="image-slider" className="d-flex justify-content-center align-item-center ">
                          {productDetail.productName && <ul className="px-4 ulcont">
                          {productDetail.images.map((res:any, index: React.Key) => {
                            // return <li key={index} className="mb-1"><a href=""><img src={res.url} className="w-100"/></a></li>
                            return <li key={index} className="mb-1"><img src={res.url} onClick={() => imageSwitch(res.url)} className="w-100"/></li>
                          })}
                            </ul>}
                             {!productDetail.productName && <ul className="px-4 ulcont">
                              <li className="mb-1"> <Skeleton wrapper={Box} count={1} height={30} width={50}/></li>
                              <li className="mb-1"> <Skeleton wrapper={Box} count={1} height={30} width={50}/></li>
                              <li className="mb-1"> <Skeleton wrapper={Box} count={1} height={30} width={50}/></li>
                              <li className="mb-1"> <Skeleton wrapper={Box} count={1} height={30} width={50}/></li>
                              <li className="mb-1"> <Skeleton wrapper={Box} count={1} height={30} width={50}/></li>
                              <li className="mb-1"> <Skeleton wrapper={Box} count={1} height={30} width={50}/></li>
                              <li className="mb-1"> <Skeleton wrapper={Box} count={1} height={30} width={50}/></li>
                            </ul>}
                          </div>
                          <div className="controls text-center">
                            <a id="nexts" title="next-image">
                              <img src={arrowdown}/>
                            </a>
                          </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-10">
                      <div className="card card-block d-flex border-0">
                        <div className="card-body align-items-center d-flex justify-content-center">
                        {/* {productDetail.productName && <img src={productDetail?.images[0]?.url} className="prod-img"/>} */}
                        {productDetail.productName && productDetail.images[0] && <img src={imgSwitch} className="prod-img w-100"/>}
                        {!productDetail.productName && <Skeleton wrapper={Box} count={1} height={230} width={250}/>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="text-end card  border-0 m-md-4">
                  <div className="card-body">
                    <Link to={`/seller/product/edit/${productUuid}`}>
                      <button className='btn btn-info me-2'><i className='d-icon d-pencil text-white'></i></button>
                    </Link>
                    <button onClick={()=>deleteProd} className='btn btn-danger'><i className='d-icon d-trash'></i></button>
                  </div>
                </div>
                 {productDetail.productName && <div className="prodDetails-right card m-md-4 pb-md-5 shadow-sm rounded">
                    <div className="card-body">
                       <h1 className="prod-detail-title">{productDetail.productName}</h1>
                       <div className="my-3">Brand: {productDetail.brand.name}</div>
                       <div className="my-3">Product Location:  <span className="text-primary ms-3">{productDetail.locationState}</span></div>
                       <div className="rating my-3">
                        <Rating onClick={handleRating} initialValue={ratingValue} size={20} /> {ratingValue / 20}/ 5   <span className="text-primary ms-3">(0 reviews)</span>
                      </div>
                        <div className="qtycont my-3 d-none">
                        <span className="qty me-4">Quantity: </span>
                            <button className="btn btn-light shadow-lg border" onClick={remove}>-</button>
                            <input className="qtyInput border text-center" max={productDetail.maximumQuantity} min={productDetail.minimumQuantity} value={qty}/>
                            <button className="btn btn-light shadow-lg border" onClick={add}>+</button>
                        </div>

                        <div className="my-1">Maximum quantity: {productDetail.maximumQuantity}</div>
                        <div className="my-1">Minimum quantity: {productDetail.minimumQuantity}</div>
                        <div className="my-1">Time created: {TimeConverter(productDetail.createdAt)}</div>
                        <div className="price my-4">Price: {productDetail.currencySymbol}{productDetail.price}</div>
                    </div>
                 </div>}

                 {!productDetail.productName && <div className="prodDetails-right card m-md-4 shadow-sm border-0 rounded">
                    <div className="card-body">
                       <h1 className="prod-detail-title">
                          <Skeleton wrapper={Box} count={1} height={30} width={250}/>
                       </h1>
                       <div className="my-3"><Skeleton wrapper={Box} count={1} height={20}/></div>
                       <div className="my-3"> <Skeleton wrapper={Box} count={1} height={20}/></div>
                       <div className="rating my-3">
                       <Skeleton wrapper={Box} count={1} height={20} width={200}/>
                      </div>
                       <div className="rating my-3">
                       <Skeleton wrapper={Box} count={1} height={20} width={200}/>
                      </div>
                       <div className="price my-2">
                        <Skeleton wrapper={Box} count={1} height={50} width={130}/>
                       </div>
                    </div>
                 </div>}
              </div>
            </div>
          </div>
       </div>

       <div className="container-fluid">
        <div className="">
          <div className="row">
            <div className="col-md-12"> 

              {productVariant.length > 0 && <div className='mt-5'>
                    <ProductVariantList ProductVariantList={productVariant} loadingVariant={loadingVariant}/>
                </div>}

              <div className="card prod-description border-0 shadow-md shadow-md mt-4 pb-5">
                <div className="card-body">
                   <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Description</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Reviews</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                   <div className="text-secondary">
                    {productDetail.productDescription || <>
                        <Skeleton wrapper={Box} count={1} height={10} width={"90%"}/>
                        <Skeleton wrapper={Box} count={1} height={10} width={"50%"}/>
                        <Skeleton wrapper={Box} count={1} height={10} width={"70%"}/>
                    </>}
                   </div>
                   </div>
                    <div className="tab-pane fade review" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="rating my-3 mb-5 text-danger">
                           No review found
                        </div>

                        <div className="rating my-3 mb-5 d-none">
                            <Rating onClick={handleRating} initialValue={ratingValue} size={20} /> {ratingValue / 20}/ 5   <span className="text-primary ms-3">(245 reviews)</span>
                        </div>

                        <ul className="list-group border-0 d-none">
                          <li className="list-group-item border-0 border-bottom">
                            <div className="row">
                              <div className="col-4 col-sm-2">
                                  <img src={girl} className="rounded-circle shadow w-75 p-1"/>
                              </div>
                              <div className="col-6  col-sm-3 ps-0 text-start mb-4 pt-3">
                                <div className="review-name">Arlene McCoy</div>
                                <div className="review-date">14 days ago</div>
                              </div>
                            <div className="col-7"></div>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet interdum massa. Cras
                              pulvinar dolor lacus, in accumsan eros consequat eget. Nam mattis sit amet urna vel accumsan.
                              Ut vestibulum efficitur quam, ac posuere arcu congue et. Etiam eu mi porta, Nam mattis sit...</p>
                          </li>
                          <li className="list-group-item border-0 border-bottom">
                            <div className="row">
                              <div className="col-4 col-sm-2">
                                  <img src={girl} className="rounded-circle shadow w-75 p-1"/>
                              </div>
                              <div className="col-6  col-sm-3 ps-0 text-start mb-4 pt-3">
                                <div className="review-name">Arlene McCoy</div>
                                <div className="review-date">14 days ago</div>
                              </div>
                            <div className="col-7"></div>
                            </div>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet interdum massa. Cras
                              pulvinar dolor lacus, in accumsan eros consequat eget. Nam mattis sit amet urna vel accumsan.
                              Ut vestibulum efficitur quam, ac posuere arcu congue et. Etiam eu mi porta, Nam mattis sit...</p>
                          </li>
                          <li className="list-group-item border-0 border-bottom">
                            <div className="row">
                            <div className="col-4 col-sm-2">
                                  <img src={girl} className="rounded-circle shadow w-75 p-1"/>
                            </div>
                            <div className="col-6  col-sm-3 ps-0 text-start mb-4 pt-3">
                                <div className="review-name">Arlene McCoy</div>
                                <div className="review-date">14 days ago</div>
                            </div>
                            <div className="col-7"></div>
                            </div>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet interdum massa. Cras
                              pulvinar dolor lacus, in accumsan eros consequat eget. Nam mattis sit amet urna vel accumsan.
                              Ut vestibulum efficitur quam, ac posuere arcu congue et. Etiam eu mi porta, Nam mattis sit...</p>
                          </li>
                        </ul>
                      </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-5">
            </div>
          </div>
        </div>
       </div>

      <Toaster/>
    </div>
  )
}

export default SellerSingleProducts

import React, { FC, useState } from 'react'

import "./BuyerSidebarContent.scss";
import {
  Link, NavLink,
  // Redirect
} from 'react-router-dom';
import useLoggedInUser from '../../../../Hooks/useLoggedInUser';
import toast, { Toaster } from 'react-hot-toast';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import userService from '../../../../Services/userService';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { ImageSrc } from '../../../../Components';
import cinderBuildLogo from "../../../../Assets/Images/cinderBuild-logo.png"
import headphone from "../../../../Assets/Images/headset.svg"

interface formType {
  businessName:string;
  businessAddress:string;
  cacNumber:string
}

interface propsType {
  ref? : any
}

const BuyerSidebarContent:FC<propsType> = ({ref}) => {
  const isLoggedIn = useLoggedInUser() 
  const [loadingBusiness, setLoadingBusiness] = useState<boolean>(false)

  const { register: registerPass, handleSubmit: handleSubmitPass, formState: { errors: errorsPass }, } = useForm<formType>()

  const switchAccount = () => {
    toast.loading("Switching account in progress, please wait...");
    setTimeout(() => {
      localStorage.setItem('__cbuN', JSON.stringify({ ...isLoggedIn, isSeller: true, switched: 0 }))
      toast.dismiss()
      window.location.href = "/seller";
    }, 2000)
  }

  function Logout () {
  };

  const [show, setShow] = useState(false);

  const switchtoseller = async () => {
    const loading = toast.loading("Switching account in progress, please wait...");
    await userService.upgradeToSeller().then((res) => {
      // Logger("userService success", res)
      toast.dismiss(loading)
      setShow(true)
    }, (error:any) => {
      // Logger("userService", error.response)
      toast.dismiss(loading)
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }

  const changeBusiness = handleSubmitPass((data:any) => processBusiness(data))
  
  const processBusiness = async (data: { newPassword: any; }) => {
    // Logger(data)
    setLoadingBusiness(true)
    await userService.changeBusiness(data).then((res:any) => {
      setLoadingBusiness(false)
      toast.success("Business profile updated successfully", { duration: 20000, className: 'bg-success text-white', position: "top-right" });

      toast.success("Switch completed", { duration: 20000, className: 'bg-white text-dark' });

      localStorage.setItem('__cbuN', JSON.stringify({ ...isLoggedIn, isSeller: true, switched: 0 }))
      window.location.href = "/seller"
    }, (error:any) => {
      setLoadingBusiness(false)
      //   //Logger(error.response.data.error)
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }
  return (
      <React.Fragment>
         <div className="bsc">
          <ul className="nav flex-column mb-4 ps-2">
            <li className="nav-item my-1 mt-3 mb-4 ps-3 d-none d-lg-block">
              <NavLink to="/">
                <ImageSrc src={cinderBuildLogo} alt={'CinderBuild'} title={'CinderBuild'} width={'buyer-logo'}></ImageSrc>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to="/buyer/banking-and-finance" ref={ref}>
                {/* <i className="d-icon d-wallet"></i> */}
                {/* <ImageSrc src={bf} title='' alt='' width='w-20 me-3'></ImageSrc> */}
                <svg viewBox="0 0 25 24" className='w-20 me-3 bf-icon' xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.74 2H5.84C3.65 2 2.5 3.15 2.5 5.33V7.23C2.5 9.41 3.65 10.56 5.83 10.56H7.73C9.91 10.56 11.06 9.41 11.06 7.23V5.33C11.07 3.15 9.92 2 7.74 2Z"/>
                  <path d="M19.1714 2H17.2714C15.0914 2 13.9414 3.15 13.9414 5.33V7.23C13.9414 9.41 15.0914 10.56 17.2714 10.56H19.1714C21.3514 10.56 22.5014 9.41 22.5014 7.23V5.33C22.5014 3.15 21.3514 2 19.1714 2Z"/>
                  <path d="M19.1714 13.4297H17.2714C15.0914 13.4297 13.9414 14.5797 13.9414 16.7597V18.6597C13.9414 20.8397 15.0914 21.9897 17.2714 21.9897H19.1714C21.3514 21.9897 22.5014 20.8397 22.5014 18.6597V16.7597C22.5014 14.5797 21.3514 13.4297 19.1714 13.4297Z"/>
                  <path d="M7.74 13.4297H5.84C3.65 13.4297 2.5 14.5797 2.5 16.7597V18.6597C2.5 20.8497 3.65 21.9997 5.83 21.9997H7.73C9.91 21.9997 11.06 20.8497 11.06 18.6697V16.7697C11.07 14.5797 9.92 13.4297 7.74 13.4297Z"/>
                </svg>

                <span className='my-auto'>Banking & Finance</span>
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                to='/buyer/my-orders/paid' ref={ref}>
                <svg className='w-20 me-3' viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 2H4.24001C5.32001 2 6.17 2.93 6.08 4L5.25 13.96C5.11 15.59 6.39999 16.99 8.03999 16.99H18.69C20.13 16.99 21.39 15.81 21.5 14.38L22.04 6.88C22.16 5.22 20.9 3.87 19.23 3.87H6.32001" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M16.75 22C17.4404 22 18 21.4404 18 20.75C18 20.0596 17.4404 19.5 16.75 19.5C16.0596 19.5 15.5 20.0596 15.5 20.75C15.5 21.4404 16.0596 22 16.75 22Z" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8.75 22C9.44036 22 10 21.4404 10 20.75C10 20.0596 9.44036 19.5 8.75 19.5C8.05964 19.5 7.5 20.0596 7.5 20.75C7.5 21.4404 8.05964 22 8.75 22Z" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.5 8H21.5" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

              {/* <i className="d-icon d-cart-shopping me-3"></i> */}
               My orders
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"}
                 to="/buyer/my-quotes/active-request" ref={ref}>
                {/* <i className="d-icon d-browser-cost me-3"></i> */}
                <svg className='w-20 me-3' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M15.5 2V9.85999C15.5 10.3 14.98 10.52 14.66 10.23L12.34 8.09003C12.15 7.91003 11.85 7.91003 11.66 8.09003L9.34003 10.23C9.02003 10.52 8.5 10.3 8.5 9.85999V2H15.5Z" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M13.25 14H17.5" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9 18H17.5" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                My Quote Requests
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i> 
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link position-relative disabled text-secondary" to='/buyer/product-leasing-program' ref={ref}> 
                 <svg className='w-20 me-3' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.0008 22H16.0008C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22Z" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M2.62109 11.2695C4.87109 12.8095 7.41109 13.7395 10.0011 14.0295" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

              Product Leasing Program<i className='d-icon d-lock-close d-none'></i>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to="/buyer/saved" ref={ref}>
              {/* <i className="d-icon d-heart-like me-3"></i> */}
              <svg className='w-20 me-3' viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.12 20.8096C12.78 20.9296 12.22 20.9296 11.88 20.8096C8.98 19.8196 2.5 15.6896 2.5 8.68961C2.5 5.59961 4.99 3.09961 8.06 3.09961C9.88 3.09961 11.49 3.97961 12.5 5.33961C13.51 3.97961 15.13 3.09961 16.94 3.09961C20.01 3.09961 22.5 5.59961 22.5 8.68961C22.5 15.6896 16.02 19.8196 13.12 20.8096Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              Saved
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>

              </NavLink>
            </li>

            <li className="nav-item mt-4 ps-3">
               Account
            </li>

            <li className="nav-item my-1 d-none">
              <span className="nav-link" role="button" onClick={switchtoseller} ref={ref}>
                {/* <i className="d-icon d-browser-cost me-3"></i> */}
                <svg className='w-20 me-3' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M3.41016 22C3.41016 18.13 7.26015 15 12.0002 15C12.9602 15 13.8902 15.13 14.7602 15.37" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19.4917 17.9805H16.5117" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M18 16.5195V19.5095" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                Upgrade to seller
              </span>
            </li>

            <li className="nav-item">
              <Link className="nav-link-0" to="/buyer/my-account"> 
              <span className="nav-link" role="button"> 
                <svg className='w-20 me-3' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M3.41016 22C3.41016 18.13 7.26015 15 12.0002 15C12.9602 15 13.8902 15.13 14.7602 15.37" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19.4917 17.9805H16.5117" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M18 16.5195V19.5095" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

               My Account
              </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link-0" to="/buyer/my-account/address"> 
              <span className="nav-link" role="button"> 
                <svg className='w-20 me-3' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M3.41016 22C3.41016 18.13 7.26015 15 12.0002 15C12.9602 15 13.8902 15.13 14.7602 15.37" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M19.4917 17.9805H16.5117" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M18 16.5195V19.5095" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                Address Book
              </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="" ref={ref}>
                {/* <i className="d-icon d-notification-on me-3"></i> */}
                <svg className='w-20 me-3' viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.65859 10.87C9.55859 10.86 9.43859 10.86 9.32859 10.87C6.94859 10.79 5.05859 8.84 5.05859 6.44C5.05859 3.99 7.03859 2 9.49859 2C11.9486 2 13.9386 3.99 13.9386 6.44C13.9286 8.84 12.0386 10.79 9.65859 10.87Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M16.9112 4C18.8512 4 20.4112 5.57 20.4112 7.5C20.4112 9.39 18.9113 10.93 17.0413 11C16.9613 10.99 16.8713 10.99 16.7812 11" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M4.65875 14.56C2.23875 16.18 2.23875 18.82 4.65875 20.43C7.40875 22.27 11.9188 22.27 14.6688 20.43C17.0888 18.81 17.0888 16.17 14.6688 14.56C11.9288 12.73 7.41875 12.73 4.65875 14.56Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M18.8398 20C19.5598 19.85 20.2398 19.56 20.7998 19.13C22.3598 17.96 22.3598 16.03 20.7998 14.86C20.2498 14.44 19.5798 14.16 18.8698 14" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Share app
              </Link>
            </li>

            <li className="nav-item mt-5">
              <NavLink className={({ isActive }) => isActive ? "nav-link active h-100" : "nav-link h-100"} to='/buyer/contact-us' ref={ref}>                
              <img src={headphone} className='w-22 me-3'></img> 
                Contact us
                <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
              </NavLink>
            </li>

            <li className="nav-item">
              <Link className="nav-link logout-active" to="#" onClick={() => Logout()} ref={ref}>
                {/* <i className="d-icon d-output me-3"></i> */}
                <svg className='w-20 me-3 fw-bold' viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.39844 7.56023C9.70844 3.96023 11.5584 2.49023 15.6084 2.49023H15.7384C20.2084 2.49023 21.9984 4.28023 21.9984 8.75023V15.2702C21.9984 19.7402 20.2084 21.5302 15.7384 21.5302H15.6084C11.5884 21.5302 9.73844 20.0802 9.40844 16.5402" stroke="#E45A5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M15.5011 12H4.12109" stroke="#E45A5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.35 8.65039L3 12.0004L6.35 15.3504" stroke="#E45A5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <span className="text-danger">Logout</span>

                <i className="d-icon d-arrowhead-right me-3 float-end my-auto text-danger"></i>
              </Link>
            </li>
          </ul>
          <ul className="nav flex-column mt-5  d-none">
            <li className="nav-item my-1">
              {isLoggedIn?.switched === 0 && <Link className="nav-link" to="#oooooo" onClick={()=>switchtoseller()}><i className="d-icon d-store me-3"></i>Upgrade to seller </Link>}
              {(isLoggedIn?.switched === 1) && <Link className="nav-link" to="#" onClick={switchAccount}><i className="d-icon d-store me-3"></i>Switch to seller</Link>}
            </li>
            <li className="nav-item my-1">
              <Link className="nav-link" to="#"> <i className="d-icon d-external me-3"></i>Share App</Link>
            </li>
          </ul>
        </div>

      <Modal show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        contentClassName="businessProfileModal">
        <Modal.Header closeButton>
          <Modal.Title> <div className='fs-5 fw-bolder'>Upgrade to seller</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fs-6 desc">Fill in the details below to upgrade your buyer account to seller.</div>
            <form onSubmit={changeBusiness}>
                <div className='form-group my-3'>
                    <label htmlFor="">Business name</label>
                    <input type="text" className="form-control" placeholder='Enter your business name' {...registerPass("businessName", { required: "this field cannot be empty" })}/>
                    {errorsPass.businessName && <div className="text-danger"> { errorsPass.businessName.message} </div>}
                </div>
                <div className='form-group my-3'>
                    <label htmlFor="">Business address</label>
                    <textarea className="form-control" placeholder='Enter your business address' {...registerPass("businessAddress", { required: "this field cannot be empty" })}/>
                    {errorsPass.businessAddress && <div className="text-danger"> { errorsPass.businessAddress.message} </div>}
                </div>
                <div className='form-group my-3'>
                    <label htmlFor="">Business CAC</label>
                    <input type="text" className="form-control" placeholder='Enter your business CAC number' {...registerPass("cacNumber", { required: "this field cannot be empty" })}/>
                    {errorsPass.cacNumber && <div className="text-danger"> {errorsPass.cacNumber && errorsPass.cacNumber.message} </div>}
                </div>
                <div className='form-group my-3 text-center mt-5'>

                    {!loadingBusiness && (<button type="submit" className="btn btn-primary rounded-pill btn-lg btn-block w-100 fs-6 fw-bolder">Upgrade to seller</button>)}
                  {loadingBusiness && (<button type="submit" className="btn btn-primary btn-lg btn-block w-100" disabled>
                      Please wait
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>)}
                </div>
            </form>
        </Modal.Body>
      </Modal>

        <Toaster></Toaster>
     </React.Fragment>

  )
}

export default BuyerSidebarContent

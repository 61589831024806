import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';
import { projectType } from '../Models/projectType';
import { createSubscriptionType } from '../Pages/Frontend/Interface/EstateManagers/Interface/EstateSubscriberProjects/EstateSubscriptionDetails/EstateSubscriptionDetails';
import { SeatchformType } from '../Pages/Frontend/Interface/EstateManagers/Components/EstateHome/EstateHeroSection/EstateHeroSection';

AxiosInterceptor()

const projectService = {
    async index (status: string, pageNumber: number,  sortOrder: string) {
        return axios.get(`/project/myprojects?status=${status}&pageNumber=${pageNumber}&sortOrder=${sortOrder}`);
    },

    async create (data: projectType) {
        return axios.post('/project/create', data);
    },

    update (projectUuid:string|undefined, data: projectType) {
        return axios.put(`/project/${projectUuid}`, data);
    },

    updateStatus (projectUuid:string|undefined, status: string) {
        return axios.patch(`/project/updatestatus/${projectUuid}/${status}`);
    },
    
    async getSingle (projectUuid: string|undefined) {
        return axios.get(`/project/myprojects/${projectUuid}`);
    },
    
    async getSubscribers (projectUuid: string|undefined) {
        return axios.get(`/project/subscriptions/${projectUuid}`);
    },
    
    file: async (Uuid: string, data:FormData, imgType:string) => {
        return await axios.put(`/upload?fileUploadCategory=${imgType}&entityUuid=${Uuid}`, data, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        });
    },

    async getTopLocations () {
        return axios.get(`/project/toplocationprojects`);
    },
    
    async getPopularProject () {
        return axios.get(`/project/popularprojects`);
    },
    async createSubscription (data:createSubscriptionType, projectSubscriptionPaymentVariant:string) {
        return axios.post(`/project/subscription/${projectSubscriptionPaymentVariant}`, data);
    },
    async getSubscriberProjects (pageNumber: number) {
        return axios.get(`/project?pageNumber=${pageNumber}&sortOrder=DESC&status=ACTIVE`);
    },

    async getUnSubscribedProjects (pageNumber: number) {
        return axios.get(`/project/unsubscriptionprojects?pageNumber=${pageNumber}&sortOrder=DESC`);
    },
    
    async getMyProjectPortfolio ( pageNumber: number, sortOrder:string) {
        return axios.get(`/investor/portfolio?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=ALL`);
    },
    async getMyProjectPortfolioDetails ( subscriptionUuid: string|undefined) {
        return axios.get(`/investor/portfolio/${subscriptionUuid}`);
    },

    async projectSearch ( data: SeatchformType) {
        return axios.post(`/project/filterproject`, data);
    },
    
}
export default projectService
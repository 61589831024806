import { useState, useRef, FC, Key, JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, createRef, useEffect } from 'react'
import "./EditCreateVariation.scss"
import { UploadSvg } from '../../../Interface/CreateProduct/CreateProduct' 
import EditVariationList from './EditVariationList/EditVariationList'
 
 
interface propsType {
    setVariationParient: Function,
    variations? :any
}

const EditCreateVariation:FC<propsType> = ({setVariationParient, variations:variationPar}) => {
    
   const [variables, setVariables] = useState<any[]>([[]])
   const [variations, setVariations] = useState<any[]>([])

   const tagInput = useRef<any>(null)

   const formRef = useRef<any[]>([createRef(), createRef(), createRef()]);

   const [images, setImages] = useState<any>([])
   const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false)  
   const [tempFields, setTempFields] = useState<any>({attribute: []})  
 

   useEffect(() => {
    setVariations(variationPar)
   }, [variationPar])
   
  const removeTag = (i:any, pi:number) => {
    const newTags = [...variables];
    newTags[pi].splice(i, 1);
    setVariables(newTags);
  }

   
  const inputKeyDown = (e:any, i:number) => {
    const val = e.target.value;
 
    if (val.indexOf(',') > -1) {  
      if (variables[i] && variables[i].find((tag:any) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
    
      const newValue = val.charAt(0).toUpperCase() + val.slice(1).replace(/,/g, '')
 
       const mainTemp = tempFields.attribute
       mainTemp[i] = { name: tempFields?.attribute[i].name, value: [...tempFields?.attribute[i].value, newValue]}

      console.log("parentEl 00009", mainTemp, ) 

      setTempFields({...tempFields,  attribute: mainTemp});
     
      const mainVariable = variables 

      mainVariable[i] = [...mainVariable[i], newValue]
       
      setVariables([...mainVariable, []]);

      tagInput.current.value = null; 

 
    }
  }


  const [attributeRow, setAttributeRow] = useState([{
    name: "",
    value: "",
  }])
 
  const handleRemoveFormRow = (index: number) => {
   
    const values = [...attributeRow];
    values.splice(index, 1);
    setAttributeRow(values);
 
  };

 

  const AddField = () => {   
    setAttributeRow([...attributeRow, {
        name: "",
        value: ""
      }]);
  }




  const handleFileUpload = async (event: { target: any }) => {
    setLoadingPhoto(true) 
    setImages([...images, ...event.target.files]) 
    setLoadingPhoto(false)
  };


  const removeImage = (i:number) => {
    const filteredItems = images.slice(0, i).concat(images.slice(i + 1, images.length))
    setImages(filteredItems)
   }

  
   const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setTempFields({
      ...tempFields,
      [name]: value,
    })
  }


  const handleChangeMerge = (e: { target: { name: any; value: any } }, i:number) => {
    const { value } = e.target;
    const mainArr = tempFields.attribute
    mainArr[i] = {
        name: value,
        value: []
    }
    setTempFields({
        ...tempFields,
        attribute : mainArr
    }) 
  }
  

   
   const submit = () => {  
    setVariations([...variations, {...tempFields, images}])
    setVariables([[]])
    setTempFields({attribute: []})
    setImages([])
    setAttributeRow([{
        name: "",
        value: "",
      }])

      formRef.current.map((res, i)=> {
        console.log("MMM", res)
        if (res.current) {
            res.current.value = ""
        }
           return  i
        })
        setVariationParient([...variations, {...tempFields, images}])
   }


   const handleRemoveVariation = (index: number) => {
   
    const values = [...variations];
    values.splice(index, 1);
    setVariations(values);
 
  };



    return ( 
        <div className="col-12 editCreateVariation"> 
            <div className="card mt-4 h-100 mb-4">
                <div className="card-body">  
                
                   {variations.length > 0 && <div className="card mt-4 h-100 mb-4">
                        <div className="card-body">
                           <EditVariationList variation={variations} handleRemoveVariation={(index:number)=>handleRemoveVariation(index)}></EditVariationList>
                        </div>
                    </div>} 

                  {attributeRow.map((result: any, index: Key) => { 
                    return <div className="row" key={index}>
                        <div className="col-md-6">
                            <div className="form-input mt-3">
                                <label htmlFor={`attibute${Number(index)}`}>Attribute</label>
                                <select id={`attibute${Number(index)}`} className="form-control" ref={formRef.current[0]}  name={`attibute${Number(index)}`}
                                  onChange = { (e)=>handleChangeMerge(e, Number(index)) } placeholder='Select Attibute'>
                                    <option className="size">--choose--</option>
                                    <option className="size">Size</option>
                                    <option value="color">Color</option>
                                </select> 
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-input mt-3">
                                <label htmlFor="max">Variables </label>  
                                <div className="input-tag form-control">
                                    <ul className="input-tag__tags">
                                        { variables[Number(index)] && variables[Number(index)].map((tag: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined, i:number) => (
                                            <li key={i}>
                                            {tag}
                                            <button type="button" onClick={() => { removeTag(i, Number(index)); }}>+</button>
                                            </li>
                                        ))}
                                        <li className="input-tag__tags__input">
                                            <input type="text" name={`attibuteValue${Number(index)}`} onChange={(e)=>inputKeyDown(e, Number(index))} ref={tagInput}/>
                                        </li>
                                    </ul>
                                </div>
                                <small className='text-secondary'>(seperate multiple options with comma)</small>
                            </div>
                        </div>

                        <div className="col-md-1 my-auto">
                            {Number(index) !== 0 && <div className="form-input mt-3">  
                                <button className='btn' type="button" onClick={()=>handleRemoveFormRow(Number(index))}>
                                    <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.9997 7.31364C20.9797 7.31364 20.9497 7.31364 20.9197 7.31364C15.6297 6.77718 10.3497 6.57474 5.11967 7.1112L3.07967 7.31364C2.65967 7.35413 2.28967 7.05047 2.24967 6.62535C2.20967 6.20022 2.50967 5.83583 2.91967 5.79535L4.95967 5.59291C10.2797 5.04632 15.6697 5.25888 21.0697 5.79535C21.4797 5.83583 21.7797 6.21035 21.7397 6.62535C21.7097 7.0201 21.3797 7.31364 20.9997 7.31364Z" fill="#FF0000"/>
                                        <path d="M8.50074 6.29014C8.46074 6.29014 8.42074 6.29014 8.37074 6.28002C7.97074 6.20916 7.69074 5.81441 7.76074 5.40953L7.98074 4.08355C8.14074 3.11184 8.36074 1.76562 10.6907 1.76562H13.3107C15.6507 1.76562 15.8707 3.16245 16.0207 4.09367L16.2407 5.40953C16.3107 5.82453 16.0307 6.21928 15.6307 6.28002C15.2207 6.35087 14.8307 6.06745 14.7707 5.66258L14.5507 4.34672C14.4107 3.46611 14.3807 3.29404 13.3207 3.29404H10.7007C9.64074 3.29404 9.62074 3.43575 9.47074 4.3366L9.24074 5.65245C9.18074 6.02697 8.86074 6.29014 8.50074 6.29014Z" fill="#FF0000"/>
                                        <path d="M15.2104 23.5282H8.79039C5.30039 23.5282 5.16039 21.5747 5.05039 19.9957L4.40039 9.80287C4.37039 9.38787 4.69039 9.02348 5.10039 8.99311C5.52039 8.97287 5.87039 9.28665 5.90039 9.70165L6.55039 19.8945C6.66039 21.433 6.70039 22.0099 8.79039 22.0099H15.2104C17.3104 22.0099 17.3504 21.433 17.4504 19.8945L18.1004 9.70165C18.1304 9.28665 18.4904 8.97287 18.9004 8.99311C19.3104 9.02348 19.6304 9.37775 19.6004 9.80287L18.9504 19.9957C18.8404 21.5747 18.7004 23.5282 15.2104 23.5282Z" fill="#FF0000"/>
                                        <path d="M13.6601 17.9597H10.3301C9.92008 17.9597 9.58008 17.6156 9.58008 17.2006C9.58008 16.7856 9.92008 16.4414 10.3301 16.4414H13.6601C14.0701 16.4414 14.4101 16.7856 14.4101 17.2006C14.4101 17.6156 14.0701 17.9597 13.6601 17.9597Z" fill="#FF0000"/>
                                        <path d="M14.5 13.9128H9.5C9.09 13.9128 8.75 13.5687 8.75 13.1537C8.75 12.7387 9.09 12.3945 9.5 12.3945H14.5C14.91 12.3945 15.25 12.7387 15.25 13.1537C15.25 13.5687 14.91 13.9128 14.5 13.9128Z" fill="#FF0000"/>
                                    </svg> 
                                </button>
                            </div>}
                        </div>
                    </div>
                   })}
                    <div className="row h-100">
                        <div className="col-12 add-attribute">
                            <button className='btn' type="button" onClick={AddField}>
                                <i className='d-icon d-add me-3 fa-1x'></i>
                                Add Attribute 
                            </button> 
                        </div>
                        <div className="col-md-6">
                            <div className="form-input mt-3">
                                <label htmlFor="variable_title">Variable title</label>
                                <input type="text" id="variable_title" className="form-control" ref={formRef.current[1]} name="variable_title"  onChange = { handleChange }/>
                             </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-input mt-3">
                                <label htmlFor="var_price">Variable price</label>
                                <input type="text" id="var_price" className="form-control" ref={formRef.current[2]} name="variable_price"  onChange = { handleChange }/>
                             </div>
                        </div>
                        <div className="col-md-6">
                            <div className="upload-container mt-4">  
                                <div className='title'>Upload Image</div>
                                <div className='instruction'>At least 1 Image, must not exceed 5 Mb each</div>
                            </div>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-3">  
                            <div className="upload-container mt-3">
                                <div className="card upload-card">
                                    <div className="card-body"> 
                                        <label htmlFor="containedButtonFileVariable"> 
                                            <UploadSvg></UploadSvg>
                                            <div className="text">
                                                Drag and drop, or browse your files
                                            </div> 
                                        <input
                                              type="file"
                                              accept="image/*"
                                              multiple
                                              id="containedButtonFileVariable"
                                              className='d-none'
                                              onChange={handleFileUpload}
                                            />
 
                                        </label>
                                        {loadingPhoto && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                    </div>
                                </div>
                            </div> 
                        </div> 
                        {images.map((res:any, index:Key) => {
                            return <div className="col-6 col-md-3" key={index}>
                                <div className='uploadedImgvariation card mb-1 p-0 mt-3'>
                                    <div className="card-body p-0 text-center">
                                        <button type="button" className='btn rounded-circle p-1' onClick={() => removeImage(Number(index))}> <i className='fa fa-times text-dark'></i></button>
                                        <div className="image-wrap">
                                            <img src={URL.createObjectURL(res)} className="w-75 px-3"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })} 
                    </div> 

                    <div className='row'>
                        <div className="col-md-6">  
                            <div className="upload-button">
                                <button className='btn btn-create' type="button" onClick={submit} >Save Variant</button>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div> 
    )
}

export default EditCreateVariation

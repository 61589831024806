import React, { useEffect, useState } from 'react'
import "./EstateManagersSubscriberSetup.scss" 
import Logo from "../../../../../../Assets/Images/cinderBuildLogo.png" 
import { Link, useNavigate } from 'react-router-dom'  
import ReactGA from 'react-ga'    
import { HeaderMeta } from '../../../../../../Components' 
import { Footer } from '../../../../../../Layout' 
import whiteLogo from "../../../../../../Assets/Images/logo-white.png"  
import { useForm } from 'react-hook-form'
import authService from '../../../../../../Services/authService'
import toast, { Toaster } from 'react-hot-toast'

 type formType = { 
    firstName: string,
    lastName: string,
    phoneNumber: string,
    countryLongName: string,
    emailAddress: string,
    password: string, 
}

const EstateManagersSubscriberSetup = () => {   
 
  const [submit, setSubmit] = useState(false)
  const [error, setError] = useState<any>(null)
  const [checked, setChecked] = useState<boolean>(true)
  
  const navigate = useNavigate()
  
  

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    window.scrollTo(0, 0)
  }, []) 

  const {register, handleSubmit, formState: {errors}} = useForm<formType>()
  const onSubmit = handleSubmit((formData)=>processForm(formData)) 

const processForm = async (formData: formType)=>{ 
     
      console.log("form data", formData)
    
      setSubmit(true) 
      const subscriberData = {...formData, role: 'investor', countryLongName: 'Nigeria'}
      await authService.registerSubscriber(subscriberData).then((response)=>{
        setSubmit(false)
        console.log("Create Subscription response", response)
        localStorage.setItem("temp__reg", JSON.stringify(subscriberData))
           
        navigate("/estate-subscribers-otp") 
       }, (error) =>{
        setSubmit(false)  
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
 
        console.log("project subscription Service error", error)
    })
    
     
}
 

  return (
    <React.Fragment>
        <div className="estate-managers-subscribers-setup">
        <HeaderMeta title="Login"></HeaderMeta>
        <div className="row h-100">
            <div className="col-md-6 d-none d-md-block">
                <div className="login-right">
                    <div className="container">
                        <div className="login-header">
                            <div className="row">
                                <div className="col-md-2 text-end">
                                    <div className="login-back">
                                    <Link to="/estate-home"><span className="d-icon d-arrowhead-left shadow"></span></Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="login-logo">
                                        <img src={whiteLogo} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div> 
                </div>
            </div>
            <div className="col-md-6 my-auto">
                <div className="login-header d-md-none">
                    <div className="row h-100">
                        <div className="col-2">
                            <div className="login-back">
                            <Link to="/estate-home"><span className="d-icon d-arrowhead-left"></span></Link>
                            </div>
                        </div>
                        <div className="col-8 text-start ps-0 my-auto">
                            <div className="login-logo">
                               <img src={Logo} alt=""  className='w-75'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="login-form">
                <div className="row justify-content-center">
                    <div className="col-md-8 mt-5 mb-5">
                        <div className="esetup-title">Signup</div> 
  
                        <form onSubmit={onSubmit}>
                        <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                            <div className="form-group mb-3 has-validation">
                                <label htmlFor="firstName">First Name</label>
                                <input type="text" id="firstName" 
                                {...register("firstName", { required: "field is required" })}
                                className={errors.firstName ? 'form-control is-invalid' :'form-control'}  
                                placeholder='First Name'/> 
                            </div> 
                                <div className="form-group mb-3">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input type="text" id="lastName" 
                                     {...register("lastName", { required: "field is required"} )}
                                    className={errors.lastName ? 'form-control is-invalid' :'form-control'}  
                                    placeholder='Last name'
                                    />
                                </div> 
                                <div className="form-group mb-3">
                                    <label htmlFor="emailAddress">Email Address</label>
                                    <input type="text" id="emailAddress" 
                                     {...register("emailAddress", { required: "field is required"})}
                                    className={errors.emailAddress ? 'form-control is-invalid' :'form-control'}
                                    placeholder='Email Address'/>
                                 </div>  
                                <div className="form-group mb-3">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input 
                                        type="number" 
                                        id="phoneNumber" 
                                        {...register("phoneNumber", { required: "field is required"})}
                                        className={errors.phoneNumber ? 'form-control is-invalid' :'form-control'}
                                        placeholder='Phone number' /> 
                                </div>  
                                <div className="form-group mb-3">
                                    <label htmlFor="password">Password</label>
                                    <input 
                                        type="password" 
                                        id="password" 
                                        {...register("password", { required: "field is required"})}
                                        className={errors.password ? 'form-control is-invalid' :'form-control'}
                                        placeholder='Password' /> 
                                </div>  
                            </div>

                            <div className="terms">
                                <label htmlFor="">
                                   <input type="checkbox" onChange={()=>setChecked(!checked)}></input>I accept the Terms & conditions and Privacy policy
                                </label>
                            </div>

                            <div className="signin-text">
                               Already have an account? <Link to="/login">Log in</Link>
                            </div>


                                {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                                <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                                    <div className="text-danger">
                                    {error}
                                    </div>
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(null)}></button>
                                </div>}


                                <div className="form-group mt-5">  
                                    {!submit && (<button type="submit" className="btn btn-login fw-bolder w-100" disabled={checked}>Sign up</button>)}
                                    {submit && (<button type="submit" className="btn btn-login fw-bolder w-100" disabled>
                                        Please wait
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>)}  
                                </div> 

                                <div className="signin-text text-center mt-4">
                                Want to list a property?  <Link to="/estate-managers-setup">Join as a Developer?</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Toaster></Toaster>
    <Footer></Footer> 
</React.Fragment>
  )
}

export default EstateManagersSubscriberSetup

import React, {FC} from "react";
import "./BuyerPageOrderTrackingCard.scss";

type propTypes = {
    orderId?:String,
    children?:React.ReactNode | React.ReactNode[],
    isLoading:boolean,
    isUpdatingOrderStatus:boolean
}

const BuyerPageOrderTrackingCard:FC<propTypes> = ({orderId, isLoading, isUpdatingOrderStatus, children})=> {
    return (
        <div className="card order-tracking-card">
            <div className="card-header order-tracking-card-header">
                Order ID: <span>{orderId}</span>
            </div>
            <div className="card-body">
                    { 
                        (!isLoading && !isUpdatingOrderStatus) &&
                        <ul className="list-group list-group-flush">
                            {children}
                        </ul>
                    }
                    {   
                        (isLoading || isUpdatingOrderStatus) &&
                        <div className='text-secondary text-center fw-bold'>
                            <span className='spinner-border spinner-border-lg'></span>
                        </div>
                    }
            </div>
        </div>
    )
}

export default BuyerPageOrderTrackingCard;

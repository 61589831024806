import React, { FC } from 'react';
import DeliveryIcon from "../../../../../../../Assets/Images/icon/location-slected.png";
import "./EnterpriseOrderDeliveryAddress.scss";

interface propsTypes {
    name:string;
    state:string;
    contactPhoneNumber:string;
    contactFullName:string;
}

 

const EnterpriseOrderDeliveryAddress:FC<propsTypes> = ({ name, state, contactPhoneNumber, contactFullName }:propsTypes) => {
    return (
        <li className='list-group-item order-delivery-address'>
           <div className='d-flex flex-row justify-content-start order-delivery-address-title align-items-center'>
            <img width='20px' height='20px' className='m-0' src={DeliveryIcon} alt=''/>
            <p className='fw-normal list-item-title col ml-12px'>Warehouse Information</p>
           </div> 
           <div className='fw-normal ms-4 ps-2'>        
            {
                name && state &&
                <>
                    <div className='m-0'>{name},</div>
                    <div className='m-0'>{`${contactFullName}`}</div>
                    <div className='m-0'>{`${contactPhoneNumber}`}</div>
                    <div className='m-0'>{`${state}`}</div>
                </> 
            }          
           </div>
        </li>
    )
}
export default EnterpriseOrderDeliveryAddress;

import React, { useState, FC, useEffect} from 'react'
import "./EstateQuoteRequestPayNow.scss"

import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';  
import cartService from '../../../../../../../Services/cartService';
import { savePaymentType } from '../../../../../../../Helpers/savePaymentType';
import wareHouseService from '../../../../../../../Services/wareHouseService';
 
interface propsType {
  orderDetails: any, 
  orderid:any, 
  onHide:any,
  module?: string,
  show: boolean
}
const EstateQuoteRequestPayNow:FC<propsType> = ({module, orderDetails, orderid, onHide, show}) => {
 
  const navigate = useNavigate()
  const [fields, updateFields] = useState({ paymentVariant: "", wareHouseUuid: "" })
  const [loadingOrder, setLoadingOrder] = useState<boolean>()

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    
    updateFields({
      ...fields,
      [name]: value,
    })
  }
 
  const onSubmit = async () => {
    const deliveryAddressUuid = orderDetails.orderReceiveType === "DELIVERY" ? { deliveryAddressUuid: orderDetails.deliverAddressUuid } : {}
    const locationUuid =  orderDetails.orderReceiveType === "PICKUP" ? { locationUuid: orderDetails.sellerPickupLocation?.uuid } : {}

    setLoadingOrder(true)

    await cartService.deliveryDetailsQuoteReq(
      { 
        orderReceiveType: orderDetails.orderReceiveType,
        ...locationUuid,
        ...deliveryAddressUuid,
        wareHouseUuid: fields.wareHouseUuid
      },
      orderid,
      fields.paymentVariant
    ).then(async (res:any) => {
      setLoadingOrder(false)  

      if (res.data.data.paymentTransactionStatus === "paid") {
       return navigate("/estate-managers/order-management", {replace:true})

      } else if (res.data.data.paymentTransactionStatus === "unpaid" || fields.paymentVariant === "PAY_ON_DELIVERY") {
       return navigate("/estate-managers/order-management", {replace:true})

      } else {
 
        await savePaymentType("ESTATE_QUOTE_PAYMENT").then(async ()=>{
           window.location.href = res.data.data.paymentProviderDetails.paymentProviderRedirectUrl;
        })

      }
      return onHide
    }, (error:any) => {
      setLoadingOrder(false) 
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }


const [wareHouse, setWareHouse] = useState<Array<{name: string; uuid: string}>>()

  const getWareHouse = async () =>{
    await wareHouseService.index().then((result)=>{
      console.log("wareHouseService", result.data.data)
      setWareHouse(result.data.data)
    }, error => {
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" }); 
    })
  }


  useEffect(() => {
    getWareHouse()
  }, [])


  return (
        <Modal 
          show={show}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          contentClassName="esqrPayModal"
          onHide={onHide} 
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Payment Option
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form>
  
          <div>Payment Option <span className='text-danger fw-bolder'>*</span></div>
            <div className="form-group my-3">
              <select className="form-control" onChange={handleChange}
                      name="paymentVariant">
                  <option>--choose payment method--</option>
                  <option value="WALLET">Wallet</option>
                  <option value="CARD">Card</option>
                  <option value="PAY_ON_DELIVERY">Pay On Delivery</option>
              </select>
            </div>
 
            <div className="form-group my-3">
              <label htmlFor="wareHouseUuid"></label>
              <select className="form-control" id="wareHouseUuid" onChange={handleChange}
                      name="wareHouseUuid">
                  <option>--choose payment method--</option> 
                  {wareHouse && wareHouse.map((ware, index)=><option key={index} value={ware.uuid}>{ware.name}</option>)}
              </select>
            </div>

            </form>
          </Modal.Body>
          <Modal.Footer>

            {!loadingOrder && <Button className={module && module==='estate' ? "btn btn-primary-estate w-100 rounded" : "btn btn-primary w-100 rounded"} onClick={() => onSubmit()}  disabled={!fields.paymentVariant} type="submit">Pay Now</Button>}
            {loadingOrder && <Button className={module && module==='estate'? "btn btn-primary-estate w-100 rounded disabled": "btn btn-primary w-100 rounded disabled"} type="button" disabled={true}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            please wait...
            </Button>}
          </Modal.Footer>
        </Modal>
  );
}

export default EstateQuoteRequestPayNow


import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../Helpers/ErrorHandler"; 
import orderService from "../../../Services/orderService";

export type TApiResponse = {
  isSuccess: boolean;
  data:any[];
  isLoading :boolean;
};

 
export const useAllOrderDelivery = (page:number, sortOrder: string) => {
  return useQuery(["enterprise_order_delivery"], async () =>  await orderService.getAllMyDeliveryOrder(page, sortOrder), {

    enabled: true,
    retry: 1,
    staleTime: Infinity,
    cacheTime: Infinity,
    select: (data:{ data: { data:any }}) => { 
      return data.data.data
    },
    onSuccess (data) {
      // toast.success("success", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    },
    onError (error: any) { 
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any)?.data?.error?.forEach((el: any) =>
          ErrorHandler(error,
            (e) => {
              toast.error(e, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        );
      } else {
        ErrorHandler(error,
          (e) => {
            toast.error(e, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    },
  });
};

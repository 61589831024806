import { Toaster } from "react-hot-toast";
import "./PhoneVerifySuccess.scss";
import { BsCheckCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

type Props = {};
const PhoneVerifySuccess = (props: Props) => {
  const navigate = useNavigate();

  const handleClick = () => navigate("/store-registration");

  return (
    <div className="success">
      <BsCheckCircle className="success-icon" />
      <div className="success-info">
        <p>Verification Successful</p>
        <div className="success-info-detail">
          <p>
            Your phone number has been successfully verified. Now you can start
            selling on CinderBuild.
          </p>
          <p>But first, you need to set up your CinderBuild Store.</p>
        </div>
      </div>{" "}
      <button
        type="submit"
        className="btn-store-setup"
        onClick={() => handleClick()}>
        Set up my CinderBuild store
      </button>
      <Toaster />
    </div>
  );
};
export default PhoneVerifySuccess;

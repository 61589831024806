import React, { useEffect, useState, FC } from 'react' 
import "./EnterpriseQuoteDetails.scss"
import quoteRequestService from '../../../../../../Services/quoteRequestService';
import { Logger } from '../../../../../../Helpers/Logger';
import { toast, Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { TimeConverter } from '../../../../../../Helpers/TimeConverter';
import BuyerPageOrderSummaryItem from '../../../../../../Components/Buyer/BuyerPageOrderSummaryItem/BuyerPageOrderSummaryItem';
import { NumberWithComma } from '../../../../../../Helpers/NumberWithCommer';
import BuyerPageOrderPaymentMethod from '../../../../../../Components/Buyer/BuyerPageOrderPaymentMethod/BuyerPageOrderPaymentMethod';
import BuyerPageOrderDeliveryAddress from '../../../../../../Components/Buyer/BuyerPageOrderDeliveryAddress/BuyerPageOrderDeliveryAddress';
import BuyerPageOrderContactDetails from '../../../../../../Components/Buyer/BuyerPageOrderContactDetails/BuyerPageOrderContactDetails';
import BuyerPageOrderStatus from '../../../../../../Components/Buyer/BuyerPageOrderStatus/BuyerPageOrderStatus';
import { EnterpriseQuoteRequestPayNow } from '../../Components';
import { EnterprisePageTitle } from '../../../../../../Components';
import { EstateQuoteRequestPayNow } from '../../../EstateManagers/Components/Index';
import { useAuth } from '../../../../../../Components/AuthProvider/AuthProvider';
 
interface propsType {
  module?: string
}
const EnterpriseQuoteDetails:FC<propsType> = ({module}) => {
  const { quoteId } = useParams<{quoteId:string}>()

  const [quoteDetails, setQuoteDetails] = useState<any>(null) 

  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
 
  const [modalShow, setModalShow] = React.useState(false);
 
  const getQuote = async (quoteId: string| undefined) => {
    setLoadingRequest(true)
    await quoteRequestService.getSingleRequest(quoteId).then((res) => {
      setQuoteDetails(res.data.data)
      Logger("setQuotedetails", res.data)
      setLoadingRequest(false)
    }, (error:any) => {
      setLoadingRequest(false)
      Logger("quotedetails error", error.response)
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }

  useEffect(() => {
    getQuote(quoteId)
  }, [])

  const { user } = useAuth()
  

  return (
        <div className='enterprise-quotes-details'>
            <div className="container mt-4">
               <div className='d-flex mb-5 justify-content-between align-items-center'>
                <EnterprisePageTitle title={'Quote Request details'} parent={'My quotes'} parentUrl={'quote-request'}></EnterprisePageTitle>               
                <div className="d-flex flex-row">

                  {quoteDetails?.status && 
                    <BuyerPageOrderStatus status={quoteDetails?.status}/>
                  }

                  {quoteDetails?.status==="PENDING" && <button className='btn btn-sm fw-bolder text-danger ms-4'>{quoteDetails?.status}</button>}
                 
                  {quoteDetails?.sellerResponse && 
                   quoteDetails?.status !== "DECLINED_BY_SELLER" && 
                   quoteDetails?.status !== "ENDED_BY_BUYER" &&   
                   <button className={module && module==='estate'? "buyer-quote-details-navlink-estate ms-4":"buyer-quote-details-navlink ms-4"} onClick={() => setModalShow(true)}>
                    Pay for order
                   </button>} 
                </div>
               </div>

               <div className="row">
                <div className="col-md-6 mb-4">
                   <div className="card quote-order-summary">
                        <div className="card-header card-custom-header bg-white">
                         <div className="row">
                            <div className="col-sm-6">
                              <p className='text-capitalize'>quote summary</p>
                              <p className='fw-normal'>{quoteDetails?.referenceNumber}</p>
                            </div>
                            <div className="col-sm-6 text-end order-time text-end">{TimeConverter(quoteDetails?.dateCreatedIso8601)}</div>
                         </div>
                        </div>
                       <div className="card-body"> 
                        <ul className="list-group list-group-flush"> 
                          { loadingRequest && 
                            <div className='text-secondary text-center fw-bold'>
                              <span className='spinner-border spinner-border-lg'></span>
                            </div>
                          }
                        
                             {quoteDetails && <BuyerPageOrderSummaryItem
                                  imageUrl={quoteDetails.product.imgUrl}
                                  name={quoteDetails?.product?.name}
                                  quantity={quoteDetails?.quantity}
                                  amount={quoteDetails?.sellerResponse?.unitPriceForBuyer}
                                  key={quoteDetails?.uuid}
                                  color={quoteDetails?.color || 'N/A'}
                                  currency={quoteDetails?.currencySymbol}
                            ></BuyerPageOrderSummaryItem>}
                        </ul>
                       </div>
                       <div className="card-footer custom-card-footer">
                          <div className='d-flex flex-row justify-content-between price-breakdown'
                          >
                             <p className="card-footer-item-title">Subtotal</p>
                             <p> ₦{(quoteDetails && quoteDetails?.quantity * quoteDetails?.sellerResponse?.unitPriceForBuyer)  || 0}</p>
                          </div>
                          <div className='d-flex flex-row justify-content-between price-breakdown'>
                             <p>Delivery</p>
                             <p>₦{(quoteDetails && NumberWithComma(quoteDetails?.sellerResponse?.deliveryFee)) || 0}</p>
                          </div>
                          <div className='d-flex flex-row justify-content-between
                            fw-bold orde-summary-total price-total'
                          >
                             <p>Total</p>
                             <p>₦{quoteDetails && quoteDetails?.sellerResponse?.deliveryFee + (quoteDetails?.quantity * quoteDetails?.sellerResponse?.unitPriceForBuyer)}</p>
                          </div>
                       </div>
                   </div>
                </div>
                <div className="col-md-6 mb-4">
                   <div className="card quote-order-summary">
                        <div className="card-header card-custom-header bg-white">
                          <p>Payment and delivery information</p>
                        </div>
                       <div className="card-body">
                          {/* eslint-disable-next-line */}
                        <ul className="list-group list-group-flush">
                          {/* eslint-disable-next-line */}
                          { loadingRequest && 
                            <div className='text-secondary text-center fw-bold'>
                              <span className='spinner-border spinner-border-lg'></span>
                            </div>
                          }
                          <BuyerPageOrderPaymentMethod
                                method={'card'}
                          />
                          <BuyerPageOrderDeliveryAddress
                            address= {quoteDetails?.address}
                            state={quoteDetails?.quoteLocation?.state}
                            country={quoteDetails?.quoteLocation?.country}
                          />
                          <BuyerPageOrderContactDetails
                            contactFullName={quoteDetails?.quoteLocation?.contactFullName}
                            contactEmail={quoteDetails?.quoteLocation?.contactEmail}
                            contactPhoneNumber={quoteDetails?.orderLocation?.contactPhoneNumber}
                          />
                        </ul>
                       </div>
                   </div>
                </div>
               </div>
             </div>

             {!user.isDeveloper && <EnterpriseQuoteRequestPayNow
                show={modalShow}
                onHide={() => setModalShow(false)}
                orderid={quoteId}
                orderDetails={quoteDetails} 
                module={module}
              />}
              
              {user.isDeveloper && <EstateQuoteRequestPayNow
                show={modalShow}
                onHide={() => setModalShow(false)}
                orderid={quoteId}
                orderDetails={quoteDetails} 
                module={module}
              />}
             <Toaster></Toaster>
        </div>
  )
}

export default EnterpriseQuoteDetails

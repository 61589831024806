import React from 'react'
import "./HeroSection.scss"
import B360deg from "../../../../../Assets/Images/B360deg.svg"
import { Link } from 'react-router-dom'
import { BasicRBAC } from '../../../../../Helpers'
import { useAuth } from '../../../../../Components/AuthProvider/AuthProvider'

const HeroSection = () => {
        
    const {user} = useAuth()
    const access = BasicRBAC
  

    return (
        <div className='heroSection'>
            <div className="container">
                <div className="row h-100">
                    <div className="col-md-6 my-auto">
                        <div className="title">
                        Builder 360™
                        </div>
                        <div className="content">
                        Your purchases on CinderBuild are rewarded. The more you purchase, the more you unlock advanced business solutions that increase your market share, expand your reach and grow your business exponentially.
                        </div>
                        {user && access("enterprice-get-started") === true? 
                        <Link to="/enterprise/products">
                            <button className="btn btn-primary"> Shop Now </button>
                        </Link>
                        :<Link to="/enterprise-setup/">
                            <button className="btn btn-primary"> Get Started </button>
                        </Link>}
                    </div>
                    <div className="col-md-6 text-center">
                        <img src={B360deg} alt="Builder 360" className='b360image'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection

import React from 'react' 
import NavMenu from '../NavMenu/NavMenu' 
import "./EstateManagerHeader.scss"
  

const EstateManagerHeader = () => { 
  
  return (
        <div className='estate-manager-header d-none d-lg-block'>
          <NavMenu></NavMenu>
        </div>
  )
}

export default EstateManagerHeader

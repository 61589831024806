import "./ProfileHeader.scss";
import {FC} from "react";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
 
interface propType {
    name:string,
    picture:string
}

const ProfileHeader:FC<propType> = ({name, picture}) => {
    return (
        <div className="profileHeader">
            <div className="pictureAndName">
                <ProfileAvatar picture={picture}/>
                <div className="profileName">{name}</div>
            </div> 
        </div>
    )
}

export default ProfileHeader;

import React, { FC, useEffect } from 'react' 
import './EstateManagerWrapper.scss'
import EventBus from '../../../../Helpers/EventBus'
import { Logout } from '../../../../Helpers/Logout'
import { Footer } from '../../../../Layout' 
import { useSelector } from 'react-redux'
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas' 
    
import EstateManagerSidebar from '../../../../Layout/EstateManager/Sidebar/EstateManagerSidebar'
import EstateManagerHeader from '../../../../Layout/EstateManager/Header/EstateManagerHeader'
import EstateManagerMobileHeader from '../../../../Layout/EstateManager/MobileHeader/EstateManagerMobileHeader'
import { AccountVerificationDrawer } from '../../../Frontend/Interface/EstateManagers/Components/Index'
 
interface propTypes {
  children: any
}

const EstateManagerWrapper:FC<propTypes> = ({ children }) => {

  const estateVerify = useSelector((state: {estateVerify:{active:boolean, placement: OffcanvasPlacement | undefined}}) => state.estateVerify)
  
   useEffect(() => {
    EventBus.on("logout", () => {
      Logout()
    });

    return () => {
      EventBus.remove("logout", () => {
        Logout()
      });
    };
  }, []);

   
 
   
  return (
    <React.Fragment>
      <div className='estate-managers-wraper container-xxxxl bg-white'> 
      <EstateManagerMobileHeader></EstateManagerMobileHeader>
        <div className="row">
          <div className="col-25 pe-0">
           {document.body.clientWidth >= 991 && <EstateManagerSidebar></EstateManagerSidebar>  }
          </div>
          <div className="col-85">
              <EstateManagerHeader></EstateManagerHeader>
                {estateVerify.active && <AccountVerificationDrawer></AccountVerificationDrawer>}
                { children } 
              <Footer></Footer>
          </div>
        </div>
      </div> 
    </React.Fragment>
  )
}
  
export default EstateManagerWrapper

import React from 'react'
import './Footer.scss' 
 
const Footer = () => { 
  return ( 
    <React.Fragment>  
      <div className="footer-credit text-center">
           &copy; 2022 CinderBuild.com - All rights reserved.
      </div>  
    </React.Fragment>
  )
}

export default Footer

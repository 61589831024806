import React from 'react'
import "./AdsSection.scss"
import ads8 from "../../../../../Assets/Images/ads8.png"
import ads81 from "../../../../../Assets/Images/ads7-1.png"
// import ads5 from "../../../../../Assets/Images/ads5.png"
// import ads51 from "../../../../../Assets/Images/ads5-1.png" 
import ads6 from "../../../../../Assets/Images/ads6.png"
import ads61 from "../../../../../Assets/Images/ads6-1.png"
import ads7 from "../../../../../Assets/Images/ads7.png"
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';
import { Link } from 'react-router-dom' 
import { BasicRBAC } from '../../../../../Helpers/BasicRBAC';
import { useAuth } from '../../../../../Components/AuthProvider/AuthProvider'

const AdsSection = () => { 
  const {user} = useAuth()
  const access = BasicRBAC
    
  return (
        <div className='home-adsSection'>
          <div className="container">
            <div className="row justify-content-center"> 

           
              <div className="col-md-4">
                <div className="ads">
                  <Link to={user && access("enterprice-get-started") === false? "/products":"/register"}>
                    <ImageSrc src={ads7} alt={''} title={''} width={'w-100'}></ImageSrc>
                  </Link>
                </div>
              </div>


              {/* <div className="col-md-6">
                  <div className="ads">
                  <Link to="/register">
                      <ImageSrc src={ads5} alt={''} title={''} width={'w-100'}></ImageSrc>
                    </Link>
                    <div className="ads-element"> 
                      <ImageSrc src={ads51} alt={''} title={''} width={'w-100'}></ImageSrc>
                    </div>
                  </div>
              </div> */} 

            <div className="col-12 col-md-4">
                  <div className="ads">
                    <Link to="/enterprise-home">
                      <ImageSrc src={ads8} alt={''} title={''} width={'w-100'}></ImageSrc>
                    </Link>

                      <div className="ads-element"> 
                        <Link to="/enterprise-home">
                            <ImageSrc src={ads81} alt={''} title={''} width={'w-100'}></ImageSrc>
                        </Link>
                      </div>
                  </div>
              </div>

            <div className="col-md-4">
              <div className="ads">
                  <Link to={user && access("enterprice-get-started") === false? "/products":"/register"}>
                  <ImageSrc src={ads6} alt={''} title={''} width={'w-100'}></ImageSrc>
                </Link>
                <div className="ads-element"> 
                  <Link to={user && access("enterprice-get-started") === false? "/products":"/register"}>
                     <ImageSrc src={ads61} alt={''} title={''} width={'w-100'}></ImageSrc>
                  </Link>
                </div>
              </div>
            </div>

            </div>
          </div>
        </div>
  )
}

export default AdsSection

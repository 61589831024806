import React, { useState, useEffect, FC } from 'react'
import { Link } from 'react-router-dom'
import "./Invoice.scss"
import HorizontalLoader from '../../../../../../../Components/Loader/HorizontalLoader/HorizontalLoader';
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter';
import { useAllInvoice } from '../../../../../../../Hooks/Queries/Enterprise/useAllInvoice';
 
interface propsType {
    setCount: (value:number) => void
}

const Invoice:FC<propsType> = ({setCount}) => { 
    const [page,] = useState(1) 
    const [sortOrder,] = useState("DESC")
    const {data:invoiceData, isLoading, isSuccess } = useAllInvoice(page, sortOrder)
    
    useEffect(() => {
        invoiceData && setCount(invoiceData.length)
      }, [invoiceData])
 
      
    return (
        <div className='invoice'>
           <div className="table-responsive">
            <table className="table table-strip">
            {isSuccess ===true && invoiceData.length > 0 && invoiceData.slice(0, 5).map((res: any, index: React.Key)=>{
                return <tr key={index}>
                    <td>
                        <div className='id'>#{res.referenceNumber}</div>
                        <div className='date'>{TimeConverter(res.createdAt)}</div>
                    </td>
                    <td className='amount'>₦ {res.calculatedTotalCostMajor}</td>
                    <td> 
                        <Link to={`/enterprise/invoice/${res.uuid}`} className="btn btn-approve">
                           View 
                        </Link> 
                    </td> 
                </tr> 
                }) }
                {isSuccess ===true && isLoading=== false && invoiceData.length === 0 && <tr>
                    <td colSpan={4} className="text-center">No invoice found</td>
                </tr>}
                {isSuccess ===false && isLoading=== true && <tr>
                    <td colSpan={4} className="text-center"><HorizontalLoader></HorizontalLoader></td>
                </tr>}

            </table>
             <div className="text-center"><Link to="/enterprise/invoice" className='see-all text-center'>See all invoices</Link> </div>
           </div> 
        </div>
    )
}

export default Invoice

/* eslint-disable no-unused-vars */
/* eslint-disable multiline-ternary */
import "./StoreRegistration.scss";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
// import { useLocalStorage } from "../../../../Hooks/UseLocalStorage";
import { Stepper } from "../../../../Components";
import { Step } from "../../../../Components/Buyer/Stepper/Step/Step";
import { useFormik } from "formik";
import { BankInfomation, BusinessInfomation, BusinessInfoUpload } from "..";
import toast, { Toaster } from "react-hot-toast";
import AlertModal from "../../../../Components/AlertModal/AlertModal";
import { FormValuesType, InitialFormValues } from "./storeRegConfig";
import bankingFinanceService from "../../../../Services/bankingFinanceService";
import onBoardingService from "../../../../Services/onboardingService";
import uploadSellerDocumentService from "../../../../Services/uploadSellerDocumentService";
import { useLoggedInUser } from "../../../../Hooks";

const StoreRegistration = () => {
  const userObj = useLoggedInUser()
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [visitedSteps, setVisitedSteps] = useState<number[]>([1]);
  const [images, setImages] = useState<any>([])
  const [showModal, setShowModal] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [message, setMessage] = useState("")
  const [progress, setProgress] = useState(0)
  const [event, setEvent] = useState({ total: 0, loaded: 0 })


  const navigate = useNavigate()
  
  const totalSteps = 3
    const handleNext = useCallback(() => {

    // add new step to list of visited steps
    setVisitedSteps(oldVisitedSteps => {
      const isStepVisited = oldVisitedSteps.includes(currentStep + 1);
      if (isStepVisited) {
        return oldVisitedSteps;
      }
      oldVisitedSteps.push(currentStep + 1)
      return oldVisitedSteps;
    }
    )

    // increase step
    setCurrentStep(oldStep => oldStep + 1)

  }, [currentStep])

  const handlePrevious = useCallback(() => {

    // remove last step
    setVisitedSteps(oldVisitedSteps => {
      oldVisitedSteps.pop()
      return oldVisitedSteps;
    })

    setCurrentStep(oldStep => oldStep - 1)
  }, [currentStep])


  useEffect(() => {
    scroll(0, 20)
  }, [currentStep])

  const showPreviousBtn = () => {
    if (visitedSteps?.length > 1) {
      return true;
    }

    return false;
  }
  const showNextBtn = () => {
    if (visitedSteps?.length < totalSteps) {
      return true;
    }

    return false;
  }
  const showSubmitBtn = () => {
    if (visitedSteps?.length === totalSteps) {
      return true;
    }

    return false;
  }
  const handleFileUpload = async (event: any, fileName: any) => {
    // Logger("fileName", fileName);
    // Logger("event", event.target.files[0]);
    setImages([...images, { file: event.target.files[0], name: fileName }])
    await uploadDoc("8ee11dd5-4b19-492b-8c7c-10ea553fb245", event.target.files[0], fileName)
  };


  const formik = useFormik({
    initialValues: InitialFormValues,
    onSubmit: (values: FormValuesType) => {
      processForm(values)
      console.log("form data", values)
      navigate('/seller') 
    }
  })


  const processForm = async (formData: FormValuesType) => {
    const load = toast.loading("Sending application, please wait...", { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
    // Logger("formData data00", formData)
    const { businessName, cacNumber, businessAddress, bankName, accountNumber } = formData
    const formDataObject_bus = {
      businessName,
      businessAddress,
      cacNumber
    }
    const formDataObject_bank = {
      bankName : JSON.parse(bankName).name,
      accountNumber,
      bankCode: JSON.parse(bankName).code
    }


    await onBoardingService.businessInfo(formDataObject_bus).then((res: any) => {
      if (res.data.status) { 
        toast.success(res.data.data, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" })
      }
    }, error => {
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
    await bankingFinanceService.addBankAccount({formDataObject_bank })
      .then((res: any) => {
        // setSavingBankDetails(false)
        toast.success("Bank Account Added Succesfully", { duration: 20000, className: 'bg-success text-white' });
        setTimeout(() => {
          return navigate('/seller')
        }, 1000)
      }, (error: any) => {
        // setSavingBankDetails(false) 
        toast.error(error.message, { duration: 20000, className: 'bg-danger text-white' });
      })

  }


  const uploadDoc = async (Uuid: any, images: any, name: any) => {
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "file",
      images
    ); 

    await uploadSellerDocumentService.upload("SELLER_CAC_DOCUMENT", userObj.uuid, formData, (event: { loaded: number; total: number }) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
      setEvent(event)
    })
      .then((response) => {

        console.log("FILE UPLOAD", response)
        setMessage(response.data.message);
      })
      .catch((error: any) => {
        console.log("FILE UPLOAD ERROR", error)
        setProgress(0)
        setMessage('Could not upload the file!');
        // setCurrentFile(undefined)
        toast.error(error.message, { duration: 20000, className: 'bg-danger text-white' });
      });

    // setSelectedFiles(undefined)
  }

  return (
    <div className="store-registration-form-page">

      <h3>Set up your CinderBuild store</h3>

      <div className="stepper-container">
        <Stepper currentStep={currentStep} visitedStep={visitedSteps} totalSteps={totalSteps}>
          <Step currentStep={currentStep} visitedSteps={visitedSteps}
            step={1}
          />
          <Step currentStep={currentStep} visitedSteps={visitedSteps}
            step={2}
          />
          <Step currentStep={currentStep} visitedSteps={visitedSteps}
            step={3}
          />
        </Stepper>
      </div>
      <form className="store-registration-form" onSubmit={formik.handleSubmit}>
        {
          currentStep === 1 && <BusinessInfomation businessName={formik.values.businessName}
            handleChange={formik.handleChange} handleBlur={formik.handleBlur}
            formikErrors={formik.errors} formikTouchedValues={formik.touched} cacNumber={formik.values.cacNumber} businessAddress={formik.values.businessAddress} />
        }

        {
          currentStep === 2 && <BusinessInfoUpload handleFileUpload={(event: any, filename: string) => handleFileUpload(event, filename)}

          />
        }

        {
          currentStep === 3 && <BankInfomation
            bankName={formik.values.bankName} accountNumber={formik.values.accountNumber} handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formikErrors={formik.errors} formikTouchedValues={formik.touched}
          />
        }

        <div className="btns-container">
          {showPreviousBtn() && <button className="store-form-btn btn back " type="button" onClick={handlePrevious}>Back</button>}
          {(currentStep === 1 && showNextBtn()) && <button className="btn-lg btn" type="button" onClick={handleNext}>Continue</button>}
          {(currentStep !== 1 && showNextBtn()) && <button className="store-form-btn btn" type="button" onClick={handleNext}>Continue</button>}
          {showSubmitBtn() && <button className="store-form-btn btn" type="submit">Submit application</button>}
        </div>
      </form>
      <AlertModal showModal={showModal} alertType={alertType} message={message}></AlertModal>
      <Toaster></Toaster>
    </div >
  );
};
export default StoreRegistration;

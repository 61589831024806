import React from 'react'
import cbcast1 from "./../../../../Assets/Images/cbcast1.jpg"
import cbcast2 from "./../../../../Assets/Images/cbcast2.jpg"
import cbcast3 from "./../../../../Assets/Images/cbcast3.jpg"
import cbcast4 from "./../../../../Assets/Images/cbcast4.jpg"
import cbcast5 from "./../../../../Assets/Images/cbcast5.jpg"
import "./CBcast.scss"
import { HeaderMeta } from '../../../../Components'

const CBcast = () => {
    return (
        <div className='cbcast'>
            <HeaderMeta title={'CB Cast'}></HeaderMeta>
            <div className="container my-2">
                <div className="ps-2">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">CB Cast </a></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container my-2">
                <h1 className='page-title'>
                    CB Cast
                </h1>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-9"> 
                    <div className="card featured-cast border-0 rounded" style={{backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.73)), url(' + cbcast5 + ')'}}>
                        <div className="card-body"> 
                            <div className='featured-type'>Featured</div>  
                            <h2>The Future of Sustainable Construction: Green Building Techniques</h2>
                            <div className='post-by'>By CinderBuild</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cb-cast-list pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 my-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card cast-item border-0 my-2" style={{backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.93)), url(' + cbcast1 + ')'}}>
                                        <div className="card-body">
                                            <div className='post-type'>CB Cast</div>
                                            <h2>The Future of Sustainable Construction: Green Building Techniques</h2>
                                            <div className='post-by'>By CinderBuild</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card cast-item border-0 my-2" style={{backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.93)), url(' + cbcast2 + ')'}}>
                                        <div className="card-body">
                                            <div className='post-type'>Resource</div>
                                            <h2>Exploring Innovative Materials in Modern Construction</h2>
                                            <div className='post-by'>By CinderBuild</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card cast-item border-0 my-2" style={{backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.93)), url(' + cbcast3 + ')'}}>
                                        <div className="card-body">
                                            <div className='post-type'>CB Cast</div>
                                            <h2>From Blueprint to Reality: A Step-by-Step Guide to Building Construction</h2>
                                            <div className='post-by'>By CinderBuild</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card cast-item border-0 my-2" style={{backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.93)), url(' + cbcast4 + ')'}}>
                                        <div className="card-body">
                                            <div className='post-type'>CB Cast</div>
                                            <h2>The Importance of Safety in Construction: Best Practices and Tips</h2>
                                            <div className='post-by'>By CinderBuild</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CBcast

/* eslint-disable no-unused-vars */
import * as React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { toast } from "react-hot-toast"; 
import brandService from "../../../../../Services/brandService";
import ImageUploadComponent from "../../../../../Helpers/ImageUploadComponent/ImageUploadComponent";


interface ICreateBrandProps {
    show: boolean;
    setShow: any;
    activeCategory: any;
}

const CreateBrandModal = (props: ICreateBrandProps) => {
    const { show, setShow, activeCategory } = props;

    const [saving, setSaving] = React.useState(false)
    const [showPrev, setShowPrev] = React.useState(false)
    const [loadingPhoto, setLoadingPhoto] = React.useState(false)
    const [images, setImages] = React.useState<any>([])

    const [brandUuid, setBrandUuid] = React.useState<any>('')


    const { handleSubmit, register } = useForm()

    // const formik = useFormik({
    //     initialValues: InitialFormValues,
    //     onSubmit: (values: FormValuesType) => {
    //         processForm(values)
    //         console.log("form data", values)
    //         navigate('/seller')
    //     }
    // })


    const onsubmit = handleSubmit(async (data: any) => {
        setSaving(true)
        const toastId = toast.loading("Adding new brand, please wait")
        await brandService.createBrand(activeCategory, data.brand).then((res: any) => {
            // console.log("createBrand", res.data.data)
            toast.dismiss(toastId);
            setBrandUuid(res.data.data.uuid)
            startUpload(res.data.data.uuid)
            toast.success("Brand added", { duration: 20000, className: 'bg-success shadow-md text-white' });
        }, (error: any) => {
            toast.dismiss(toastId);
            // console.log("createBrand error", error.response)
            toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger shadow-sm text-white' });
        })

    })

    const handleClose = () => {
        setShow(false)
    }

    // const removeImage = () => {
    //     setImage('')
    //     setShowPrev((prev) => !prev)
    // }

    // const handleFileUpload = async (event: { target: any }) => {
    //     setLoadingPhoto(true)
    //     console.log(event.target.files[0]);
    //     setImage(event.target.files[0])
    //     setLoadingPhoto(false)
    // };

    const handleFileUpload = async (event: any, fileName: any,) => {
        // Logger("fileName", fileName);
        // console.log("event", event.target.files[0]);
        setImages({ file: event.target.files[0], name: fileName })
        await startUpload(brandUuid)
    };



    const startUpload = async (brandUuid: any) => {

        const formData = new FormData();

        // Update the formData object FilterSearch
        formData.append(
            "file",
            images
        );

        await brandService.file(brandUuid, formData).then((res: any) => {
            // console.log("FILE UPLOAD", images)
            toast.success("Brand logo saved successfully", { duration: 20000, className: 'bg-white shadow-md text-secondary' });
            setLoadingPhoto(false)

            toast.success("Upload completed", { duration: 20000, className: 'bg-success shadow-md text-white' });
            // handleShow(
            setTimeout(() => {
                window.location.reload()
            }, 3000)

        }, (error: any) => {
            setLoadingPhoto(false)
            toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
            // console.log(error.response)
            // console.log(error)
        })
    }


    const removeImage = (i: number) => {
        const filteredItems = images.slice(0, i).concat(images.slice(i + 1, images.length))
        setImages(filteredItems)
      }
    


    return (
        <div className="create-brand">
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >

                <Modal.Header closeButton>
                    <Modal.Title className="border-0">
                        <p id="createBrand">Create Brand</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="wallet-modal pb-5 rounded">

                    <form onSubmit={onsubmit}>
                        <div className="form-input">
                            <label htmlFor="brand">Brand Name</label>
                            <input
                                id="brand"
                                type="text"
                                className="form-control mt-3 rounded-5 "
                                {...register("brand", {
                                    required: "This field is required",
                                })}
                            />
                        </div>
                        <div className="mt-3 ">
                            <label>Brand Logo </label>

                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <ImageUploadComponent handleFileUpload={(event: any, filename: string) => handleFileUpload(event, filename)} />
                        </div>

                        {images.length > 0 && <div className="row d-flex"> 
                            {images.map((res: any, index: React.Key) => {
                            return <div className="col-3 ms-4 " key={index}>
                                <div className='uploadedImgCont bg-white'>
                                <img src={URL.createObjectURL(res)} className="prev-img" />
                                <span className='btn rounded-circle border-dark circle d-flex align-items-center justify-content-center' style={{ width: "20px", height: "25px" }} onClick={() => removeImage(Number(index))}> <i className='fa fa-times'></i></span>

                                </div>
                            </div>
                            })}
                        </div>}

                        {!saving && (
                            <button type="submit" className="btn btn-success rounded-5 py-3 w-100 mt-4">
                                Respond to request
                            </button>
                        )}
                        {saving && (
                            <button
                                className="btn btn-primary w-100 wallet-btn mt-4 mb-3"
                                disabled>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"></span>
                                Sending response...
                            </button>
                        )}
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CreateBrandModal;
import React, { useEffect, useMemo, useState } from 'react'
import "./Products.scss"
import setting4 from "../../../../Assets/Images/icon/setting-4.png"
import sort from "../../../../Assets/Images/icon/sort.png"
import { ProductFilters, ProductItem, ProductItemSkeleton } from '../../Components'
import toast, { Toaster } from 'react-hot-toast';
import { useAllProduct } from '../../../../Hooks'
import { HorizontalLoader, Pagination } from '../../../../Components'

const Products = () => {
  const [pageNumber, setPage] = useState(1)
  const [sortOrder, setOrder] = useState("DESC")

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number,
    pageSize: number,
    total:number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1
  })

  const [brandUuids, setBrandUuids] = useState<any[]>([])
  const [categoryUuids, setCategoryUuids] = useState<any[]>([])
  // eslint-disable-next-line no-unused-vars
  const [locationStates, setLocationStates] = useState<any[]>([])

  const { data: product, isLoading, isSuccess, isFetching, refetch: getProduct } = useAllProduct(pageNumber, sortOrder, brandUuids, categoryUuids, locationStates)

  useMemo(() => {
    isSuccess && setPaginationData({
      pageNumber: product?.pageNumber,
      pageSize: product?.pageSize,
      total: product?.total
    })
  }, [product])

  useEffect(() => {
    getProduct()
  }, [pageNumber, sortOrder])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (isFetching) {
      // toast.loading("Processing...", { position: "bottom-right" })
    } else {
      toast.dismiss()
      handleClose()

      window.scrollTo(0, 0)
      toast.success("Done", { duration: 1000, className: 'custom-alert bg-white text-dark', position: "bottom-right" });
    }
  }, [isFetching])

  return (
        <div className='products mb-5'>
            <div className="container my-2"> 
              <div className="ps-2">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/">Home</a></li>
                      <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">All Products</a></li>
                    </ol>
                  </nav>
                </div> 
                {isLoading && <span className='spinner-border spinner-border-sm'></span>}
              <div className="row section-header mt-5">
                <div className="col-12 col-md-7 col-xl-9 ps-3 my-auto">
                  <h2><b className="me-1">{(isLoading === true || isFetching === true) ? <span className='spinner-border spinner-border-sm'></span> : paginationData.total + ' products available'}</b> </h2>
                </div>
                <div className="col-4 col-md-2 col-xl-1 p-0 my-auto text-end">
                  <button className="btn" onClick={() => handleShow()}>
                    <img src={setting4} className="nav-icon me-1" alt=""/> Filter
                  </button>
                </div>
                <div className="col-4 col-md-1 p-0 my-auto">

                  <div className="dropdown">
                    <button className="btn p-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={sort} className="nav-icon me-1" alt=""/> Sorting
                  </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="#" onClick={() => setOrder("DESC")}> <i className="d-icon d-arrow-up"></i> Descending Order</a></li>
                      <li><a className="dropdown-item" href="#" onClick={() => setOrder("ASC")}> <i className="d-icon d-arrow-down"></i> Ascending Order</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-4 col-md-2 col-xl-1 p-xl-0 my-auto">
                  <button className="btn btn-popular p-1 fw-bolder"> {sortOrder} </button>
                </div>
              </div>
            </div>

            <div className="container">
                <div className="row">
                   {isSuccess && isFetching === false && product?.dataset?.length > 0 && product?.dataset?.map((res, index:React.Key) => <ProductItem data={res} key={index}></ProductItem>)}
                   {(isLoading === true || isFetching=== true) && <ProductItemSkeleton cardCount={10}></ProductItemSkeleton>}
                </div>
                
                <div>
                   {(isLoading === true || isFetching === true) && <HorizontalLoader></HorizontalLoader>}
                </div>

                {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                    {paginationData.total && <Pagination
                    className="pagination-bar ps-0"
                    currentPage={Number(paginationData.pageNumber)}
                    totalCount={paginationData.total}
                    pageSize={paginationData.pageSize}
                    onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                    }
                </div>}
            </div>
            <ProductFilters show={show} handleClose={handleClose} categoryFilter={function (value: string): void {
              setCategoryUuids(value === 'null' ? [] : [value])
            } } brandFilter={function (value: string): void {
              setBrandUuids(value === 'null' ? [] : [value])
            } } action={function (): void {
              getProduct()
            } }
            isFetching={isFetching}></ProductFilters>

         <Toaster/>
        </div>
  )
}

export default Products

import React, { useState, FC, JSXElementConstructor, ReactElement, ReactFragment, ReactPortal } from 'react'
import "./CreateVariation.scss"  
import Chrome from 'react-color/lib/components/chrome/Chrome'
 
  
interface varPropsType {
    variables: any[], 
    index: React.Key, 
    removeTag: (i:any, index:number)=>void, 
    inputKeyDown: (i:any, index:number)=>void,
    handleChangeComplete: (e:any, index:number, attri:string)=>void,
    showColorPicker: boolean, 
    variableColor: any, 
    tagInput:any, 
    decideColorPicker: ()=>void,
    formRef:any,
    handleChangeMerge: (e:any, index:number) =>void,
    selectedAttributes: any[],
    handleRemoveFormRow:(val:number)=>void,
    setShowColorPicker: Function
}

export const Variable:FC<varPropsType> = ({
        variables, 
        index , 
        removeTag, 
        inputKeyDown, 
        handleChangeComplete, 
        showColorPicker,
        variableColor, 
        tagInput, 
        decideColorPicker, 
        formRef,
        handleChangeMerge,
        selectedAttributes,
        handleRemoveFormRow,
        setShowColorPicker
    })=>{
     
       const  [fieldValue, setFieldValue] = useState("") 
       const  [showPalete, setShowPalete] = useState(false)

           
  const clearInput = (e:any) => {
    const val = e.target.value;  
    if (val.indexOf(',') > -1) {   
        setFieldValue("")
        tagInput.current.value = ''
    } 

  }

 

 

    return  <>
            <div className="row"> 

                <div className="col-md-5">
                    <div className="form-input mt-3">
                        <label htmlFor="">Size product (e.g Large, medium, small)</label>  
                        <div className="input-tag form-control">
                            <ul className="input-tag__tags">
                                { variables[0] && variables[0].map((tag: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined, i:number) => (
                                    <li key={i}>
                                    {tag}
                                    <button type="button" onClick={() => { removeTag(i, 0); }}>+</button>
                                    </li>
                                ))}
                                <li className="input-tag__tags__input">
                                    <input type="text" name="attibuteValue0" value={fieldValue} onChange={(e)=>{ setFieldValue(e.target.value); inputKeyDown(e, 0); clearInput(e); }} ref={tagInput} onClick={()=>handleChangeMerge({target:{value:"size"}}, 0)}/> 
                                </li>
                            </ul>
                        </div>

                        <small className='text-secondary'>(seperate multiple options with comma)</small>
                    </div>
                </div>

                <div className="col-md-2">
                    <div className="form-input mt-3">  
                        <label htmlFor="">Measurement Units </label>  
                        <select className='form-control'>
                            <option>--select option--</option>
                            <option>MM</option>
                            <option>CM</option>
                            <option>INCHE</option>
                        </select>
                    </div> 
                </div>


                <div className="col-md-3">
                    <div className="form-input mt-3"> 
                        <label htmlFor="">Does your variation have a color attribute </label>  
                        <select className='form-control' onChange={(e)=> {
                            setShowPalete(e.target.value==="Yes") 
                        }}>
                            <option>--choose--</option>
                            <option>Yes</option>
                            <option>No</option> 
                        </select>
                    </div> 
                </div>

                {showPalete === true && <div className="col-md-2 position-relative text-center"> 
                    <div className="form-input mt-3 d-flex justify-content-center"> 
                        <label htmlFor="">Pick a color </label> 
                    <div className='selected-color' style={variables[1] && {backgroundColor: variables[1][0]}} onClick={()=>{ decideColorPicker(); handleChangeMerge({ target: { value: "color" }}, 1) }}></div>
                    {showColorPicker ===true && <div className='selectColor input-tag__tags__input'> 
                        <div className='closeButtonWrapper d-none'>
                            <button className='btn bg-danger rounded-circle p-1' type="button" onClick={()=> setShowColorPicker(false)}><i className='d-icon d-close-circle text-white'></i></button> 
                        </div> 
                         <div className='block-picker-item'>
                            <Chrome 
                                color={variables[1] && variables[1][0]}
                                onChangeComplete={ (e:any)=> handleChangeComplete(e, 1, "attibuteValue1") }
                            ></Chrome>  
                        </div>
                    </div>}
                   </div>
                </div>}
            </div>
 
    </>
}
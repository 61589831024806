import React, { useEffect, useState }  from 'react'
import "./EstateInvestorManagementDetails.scss"
import { EstatePageTitle, HorizontalLoader } from '../../../../../../../Components'
import investorService from '../../../../../../../Services/investorService'
import { useParams } from 'react-router-dom' 
import { investorType } from '../../../../../../../Models/investorType'
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter';
import { CurrencyFormatter } from '../../../../../../../Helpers/CurrencyFormatter';
 
const EstateInvestorManagementDetails = () => {
    const { investorUuid } = useParams<{investorUuid: string|undefined}>()
    const [request, setRequest] = useState(false)
    const [investor, setInvestor] = useState<investorType>()

    const getInvestorDetail = async () => {
        setRequest(true)
      await investorService.getSingle(investorUuid).then((response)=>{
        console.log("investorService response", response)
        setRequest(false)
        setInvestor(response.data.data)
      }, (error)=>{
        console.log("error", error)
        setRequest(false)
      })
    }

    useEffect(() => { 
        getInvestorDetail()
    }, [investorUuid])
    return (
        <div className='EstateInestorManagementDetails pt-4'>
            <div className="container">
                <div className="top-header">

                    {investor && <EstatePageTitle
                        title={investor.project.name}
                        parent="Subscriber Management"
                        parentUrl="investor-management"
                        showTitle={false}
                    /> }

                    {request && <HorizontalLoader></HorizontalLoader>}

                    {investor && <div className="row mt-4">
                        <div className="col-md-8">  
                            <div className="page-title"> 
                                <h2>{ investor.project.name }</h2>  
                                <span className="open">{ investor.project.status }</span>  
                            </div>
                        </div> 
                    </div>}
                </div> 
            </div>
 
            {investor && <div className='container mt-3'>
                <div className="row"> 
                    <div className="col-md-8">  
                        <div className="project-content mt-3">
                            <div className="row">
                                <div className="col-3 mt-3">
                                    <div className="label">Project Type:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{investor.project.type}</div>
                                </div> 

                                <div className="col-3 mt-3">
                                    <div className="label">Amount Remaining:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">₦{CurrencyFormatter(investor.amountRemainingMinor)}</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Initial Amount:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">₦{CurrencyFormatter(investor.initialAmountMinor) }</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Total Amount:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">₦{CurrencyFormatter(investor.totalAmountMinor) }</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Amount per Slot:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">₦{CurrencyFormatter(investor.project.costPerSlot) }</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Number Of Subscribed Units:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{investor.numberOfSlots }</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Project Details:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{investor.project.details}</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Current Stage:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">-</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Number of Units:</div>
                                </div> 
                                <div className="col-9 mt-3">
                                    <div className="amount">{investor.numberOfSlots}</div>
                                </div> 
                                <div className="col-3 mt-3">
                                    <div className="label">Cost per Units:</div>
                                </div> 
                                <div className="col-9 mt-3">
                                    <div className="amount">{investor.project.costPerSlot}</div>
                                </div> 

                                <div className="col-3 mt-3">
                                    <div className="label">Start Date:</div>
                                </div> 
                                <div className="col-9 mt-3">
                                    <div className="amount">{TimeConverter(investor.project.startDate)}</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Initial Percentage:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{investor.project.initialInvestmentPercentage}%</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Duration:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{investor.project.duration} months</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Address</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{investor.project.address}</div>
                                </div>
                                
                                <hr  className='mt-3'/>
                                <div className="col-3 mt-3">
                                    <div className="label">Investor name</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{investor.investorPublicProfile.firstName} {investor.investorPublicProfile.lastName}</div>
                                </div>



                                <div className="col-3 mt-3">
                                    <div className="label">Investor Phone Number</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{investor.investorPublicProfile.phoneNumber}</div>
                                </div> 

                            </div>
                        </div>
                    </div>
                </div>
            </div> }
        </div>
    )
}

export default EstateInvestorManagementDetails

import React, { useState } from 'react'
import "./ForgotPassword.scss"
import whiteLogo from "../../../../Assets/Images/logo-white.png"
import Logo from "../../../../Assets/Images/cinderBuild-logo.png"
import cinderBg from "../../../../Assets/Images/cinderBg.png"
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import authService from '../../../../Services/authService';
import { HeaderMeta } from '../../../../Components'

interface propsType {
  phoneNumber: string
}
const ForgotPassword = () => {
  const history = useNavigate();
  const [submit, setSubmit] = useState(false)

  const [error, setError] = useState<any>(null)

  // eslint-disable-next-line no-unused-vars
  const [selectedCountry, setSelectedCountry] = useState<any>({
    name: 'Nigeria',
    iso2: 'NG',
    phoneCode: '234',
    currency: 'NGN',
    currencySymbol: '₦',
    image: {
      url: 'https://res.cloudinary.com/tradegrid/image/upload/v1644746374/nigeria-gfdaf34ede_640_o1zj6m.png',
      fileCloudProvider: '',
      keyFromCloudProvider: ''
    }
  });

  const { register, handleSubmit, formState: { errors } } = useForm<propsType>();

  const onSubmit = handleSubmit((data:any) => processLogin(data));

  const processLogin = async (data:any) => {
    setSubmit(true)

    await authService.resetPassword({ ...data, countryIso2: selectedCountry.iso2 }).then((user:any) => {
      // Logger("user", user)
      localStorage.setItem("temp__reg", JSON.stringify({ ...data, countryIso2: selectedCountry.iso2 }))

      return history("/forgot-password-success", { replace: true })
    }, (error:any) => {
      setError(error.response.data.error)
      setSubmit(false)
    })
  }

  return (
    <div className="ForgotPassword">
    <HeaderMeta title="Forgot Password"></HeaderMeta>
        <div className="row h-100">
            <div className="col-md-6 d-none d-md-block">
                <div className="login-right">
                    <div className="container">
                        <div className="login-header">
                            <div className="row">
                                <div className="col-md-2 text-end">
                                    <div className="login-back">
                                    <a href=""><span className="d-icon d-arrowhead-left shadow"></span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="login-logo">
                                        <img src={whiteLogo} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="login-text">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <h2>Welcome</h2>
                                    <div className="desc">
                                    Login to account to continue using  CinderBuild
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={cinderBg} alt="" className='abstImg'/>
                </div>
            </div>
            <div className="col-md-6 my-auto">
                <div className="login-header d-md-none">
                    <div className="row h-100">
                        <div className="col-2">
                            <div className="login-back">
                            <a href=""><span className="d-icon d-arrowhead-left"></span></a>
                            </div>
                        </div>
                        <div className="col-8 text-start ps-0 my-auto">
                            <div className="login-logo">
                                <img src={Logo} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="login-form" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <div className="row justify-content-center">
                    <div className="col-md-8">

                        <h1>Forgot Password</h1>
                        <small>Please enter the phone number used to create your CinderBuild account below:</small>

                        <form onSubmit={onSubmit} >
                            <div className="form-group mb-3 mt-4">
                                <input type="text" className="form-control" placeholder='Phone Number' {...register("phoneNumber", { required: "field cannot be empty" })}/>
                                <div className="err text-danger">{errors.phoneNumber && errors.phoneNumber.message}</div>
                            </div>

                            {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                            <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                                <div className="text-danger">
                                   {error}
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}
                            <div className="form-group mt-4">
                                {!submit && (<button type="submit" className="btn btn-login">Recover  Password</button>)}
                                {submit && (<button type="submit" className="btn btn-login" disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>)}
                            </div>
                            <div className="signup mt-3">
                               Remember password? <Link to="/login" className='text-primary'>Back to Login</Link>
                            </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ForgotPassword

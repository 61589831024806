import { FC, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import Message from "../../../../../../../Assets/Images/icon/message.svg";
import "./EnterpriseFaqItem.scss";
import CloseIcon from "../../../../../../../Assets/Images/icon/close_icon.svg";

interface propType {
    question:string,
    answer:string
}

const EnterpriseFaqItem: FC<propType> = ({question, answer}) => {

    const [showAnswer, setShowAnswer]  = useState(false);
    
    const handleClose =  (evt:any)=>{
        evt.stopPropagation();
        console.log("Hello")
        setShowAnswer(false)
    }

    const handleOpen = ()=>{
        setShowAnswer(true);
    }

    return (
        <li className="enterpriseFaqItem-item-list"
            onClick={handleOpen}
        >
             
            <div className="icon-wrap">
                <img src={Message} alt='icon'/> 
            </div>
            {question}?
            <Modal className="faq-item-answer-modal d-flex align-items-center"  show={showAnswer} onHide={()=>{}}>
                <ModalHeader>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <h3>
                            {question}
                        </h3>
                        <div className="custom-modal-close-btn order-2"
                            onClick={handleClose}
                        >
                            <img src={CloseIcon} alt=''/>
                        </div>
                 </div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        {answer}
                    </div>
                </ModalBody>
            </Modal>
        </li>
    )
}

export default EnterpriseFaqItem;

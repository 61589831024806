import React, { FC, useEffect, useState , Fragment } from 'react'
import "./QuoteRequest.scss"
import { productType } from '../../../../../Models/productType';
import { useForm } from 'react-hook-form';
import quoteRequestService from '../../../../../Services/quoteRequestService'; 
import EventBus from '../../../../../Helpers/EventBus';
import { useNavigate, Link } from 'react-router-dom'; 
import userService from '../../../../../Services/userService';
import toast, { Toaster } from 'react-hot-toast'; 
import Offcanvas from 'react-bootstrap/Offcanvas';
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../../Components/AuthProvider/AuthProvider';
import AddDeliveryAddress from './AddDeliveryAddress';
  
interface propsType {
    productDetail:productType | undefined;
    show:boolean;
    hide: (value:boolean)=>void
}

interface formType  {
    notes:string;
    quantity:string;
    // orderReceiveType:string;
    productUuid:string;
    sellerPickupLocationUuid:string;
    deliverAddressUuid:string;
}

const QuoteRequestDrawal:FC<propsType> = ({productDetail, show, hide}) => {

  const [quoteRequestLoading, setQuoteRequest] = useState(false);

  const cartQty:any  = useSelector((state: { cartQty: number})=> state.cartQty);

  const [qty, setQty] = useState<number>(0)

  const [hideBtn, setHideBtn] = useState(true)
  const [terms, setTerms] = useState(false)

  const [orderTpe, setOrderType] = useState<string>("null")

  const { register, handleSubmit, reset, formState: { errors } } = useForm<formType>();
  
  const navigate = useNavigate()

  const handleClose = () => {
    hide(false); 
    toggleDeliveryAddress()
  } 

  const requestQuote = handleSubmit((data:any) => processRequestQuote(data));

  const processRequestQuote = async (data:any) => { 
    setQuoteRequest(true)

    if (orderTpe==="null") {
      setQuoteRequest(false)
      setTerms(true)
      toast.error("Please select a delivery method'", { duration: 44420000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      
      return false
    }
 
    await quoteRequestService.sendQuoteRequest({...data, orderReceiveType: orderTpe})
      .then((res) => {
        toast.success("Quote Request sent Successfully'", { duration: 44420000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        setQuoteRequest(false)
        handleClose()
        reset()
        setTimeout(() => {
          return navigate("/buyer/my-quotes", {replace:true})
        }, 2000)
      }, (error) => {
        setQuoteRequest(false)
        // Logger("error", error.response)

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout", "session timeout");
        }

        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }



  const [deliveryLocation, setDeliveryLocation] = useState([])

  const getAddress = async () => {
    await userService.getBuyerDeliveryLocation()
      .then((res) => {
        setDeliveryLocation(res.data.data)
      }, (error:any) => {
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }
  const { user } = useAuth()

  useEffect(() => {
    setQty(cartQty? cartQty: productDetail?.minimumQuantity)
    user && getAddress()
  }, [cartQty])
  
  
  const handleTerms = () => { 
    setTerms(!terms)
  }


  const [ShowNewDeliverAddress, setShowNewDeliverAddress] = useState(false)

  const toggleDeliveryAddress = () => {   
    getAddress()
  }

    return (  
      <Fragment>
      <Offcanvas 
      show={show} 
      onHide={handleClose} 
      placement="end" className="quoteModal">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="pb-4">{!ShowNewDeliverAddress? "Request a quote": "New Delivery address"}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
          <form onSubmit={requestQuote}>
             {!ShowNewDeliverAddress && <div className="bg--light">
                <div className="container-fluid">
                  <div className="row req-order-preview">
                    <div className="col-4 my-auto mx-auto text-center">
                      <div className="p-2 text-center">
                      {productDetail?.images[0] && <ImageSrc src={productDetail?.images[0]?.url} alt="" title="" width="w-100"/>}
                      </div>
                    </div>
                    <div className="col-8 my-auto">
                      <span className="prod-title">{productDetail?.productName}</span>
                    </div> 
                  </div>
                  </div>
                </div>}

                <div className="pt-0 mt-0">
                {!ShowNewDeliverAddress &&  <Fragment>
                <div className="form-group my-4">
                  <label className="textarea-label">Quantity</label>
                  <input {...register("quantity", { required: "field is required", max: productDetail?.maximumQuantity, min: productDetail?.minimumQuantity })} className="form-control  deliveryLocation mx-auto d-block" type="number" placeholder="Quantity" value={qty}
                  onChange={(e) => setQty(Number(e.target.value))}
                  onKeyDown={ (e:any) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault() }/>

                  <div className='d-inline-height text-start'>
                       <span className="product-min-order text-secondary me-2">Min: {productDetail?.minimumQuantity}</span>
                       <span className="product-min-order text-secondary">Max: {productDetail?.maximumQuantity}</span>
                  </div>
                  {errors.quantity && <div className="text-danger">{errors.quantity.message}</div>}

                    {errors.quantity && errors.quantity.type === "max" && (
                        <div className="text-danger product-min-order">`Quantity must not be more than {productDetail?.maximumQuantity}</div>
                    )}

                    {errors.quantity && errors.quantity.type === "min" && (
                      <div className="text-danger product-min-order">Quantity must not be less than {productDetail?.minimumQuantity}</div>
                    )}
                  </div>

                   <label className="textarea-label">Message to the seller: {productDetail?.sellerPublicProfile.firstName}  {productDetail?.sellerPublicProfile?.lastName}</label>
                  <textarea className="form-control messageInput" {...register("notes", { required: "field is required" })} placeholder="Message"></textarea>
                  {errors.notes && <div className="text-danger">{errors.notes.message}</div>}
                  <label>

                    <div className="textarea-label mt-3"> 
                    Would you like your order delivered or picked up?
                    </div>

                    </label>
                  <div className="container-radio mt-2 row">
                      <div className="col-6">
                        <div className="radio">
                          <button type="button" className={orderTpe==="DELIVERY"? "radio-btn active":"radio-btn"} onClick={(e:any) => setOrderType("DELIVERY") }>Door Delivery</button>
                          </div>
                      </div>

                      <div className="col-6">
                        <div className="radio">
                          <button type="button" className={orderTpe==="PICKUP"? "radio-btn active":"radio-btn"} onClick={(e:any) => setOrderType("PICKUP") }>Pick Up</button>
                         </div>
                      </div>
                    </div>
                    </Fragment>}

                    {/* orderTpe */}

                   {orderTpe === 'DELIVERY' && <div className="form-group mt-2 mb-5">

                    <div className='text-end'>
                      <div className="accordion" id="accordionExample">
                        {ShowNewDeliverAddress===false && hideBtn && <button className="btn new-delivery-address mb-1 p-1 fw-bold collapsed" onClick={()=>setShowNewDeliverAddress(true)} type='button' data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Create new delivery address</button>}

                        <div id="collapseOne" className={ShowNewDeliverAddress===true? "accordion-collapse collapse show":"accordion-collapse collapse"} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <AddDeliveryAddress createdAction={()=>{ toggleDeliveryAddress(); setHideBtn(true); setShowNewDeliverAddress(false) }}></AddDeliveryAddress>
                            </div>
                          </div>
                      </div>
                    </div>
                       
                    {!ShowNewDeliverAddress && <select className="form-control deliveryLocation mx-auto d-block" placeholder="Delivery Address" {...register("deliverAddressUuid", { required: "Select or create a delivery address" })}>
                          <option value="">--Select delivery address--</option>
                          {deliveryLocation.length > 0 && deliveryLocation.map((res:{ name:string, address:string, uuid:string }, index:React.Key) => {
                            return (<option key={index} value={res.uuid}>{res.name} - {res.address}</option>)
                          })}
                      </select> }
                      {errors.deliverAddressUuid && <small className='text-danger'>{errors.deliverAddressUuid.message}</small>}
                    </div>}

                    {orderTpe === 'PICKUP' && <div className="form-group mt-2 mb-5">
                      <select className="form-control deliveryLocation mx-auto d-block" placeholder="Pickup Address" {...register("sellerPickupLocationUuid", { required: "Select a pickup address" })}>
                      <option value="">--Select pickup address--</option>
                        {productDetail && productDetail?.sellerPickupLocations?.length > 0 && productDetail?.sellerPickupLocations.map((res:{ name:string, address:string, uuid:string }, index:React.Key) => {
                          return (<option key={index} value={res.uuid}>{res.name} - {res.address}</option>)
                        })}
                      </select>
                      {errors.sellerPickupLocationUuid && <small className='text-danger'>{errors.sellerPickupLocationUuid.message}</small>}
                    </div>}

                      <input type="hidden" value={productDetail?.productUuid} {...register("productUuid", { required: "field is required" })}/>
                  </div>

                  {!ShowNewDeliverAddress && <div className="form-group my-3 d-inline tandc">
                      <label htmlFor="terms"><input type="checkbox" id="terms" className='ms-checkbox' required  onChange={handleTerms} checked={terms}/> <span>I Accept the <Link to="/terms-and-conditions">Terms & Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link></span></label>
                  </div>}

                  {!ShowNewDeliverAddress && <Fragment>
                  {!quoteRequestLoading && <button className="btn btn-primary mx-auto d-block w-100 req-btn shadow mt-4" type="submit" disabled={!terms}>
                    Request Quote
                  </button>}
                  {quoteRequestLoading && <button className="btn btn-danger mx-auto d-block w-100 req-btn shadow mt-4"
                    type="button" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Please wait...
                    </button>}
                    </Fragment>}
              </form>
              <Toaster></Toaster>
        </Offcanvas.Body>
      </Offcanvas>
      </Fragment>
    )
}

export default QuoteRequestDrawal

import axios from 'axios'
import { sitesType } from '../Models/enterprise.types';

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()
 
const sitesService = {
  async index (wareHouseUuid: string|undefined) {
    return axios.get(`/warehouse/${wareHouseUuid}/sites`);
  },

  async create (wareHouseUuid: string, data:sitesType) {
    return axios.post(`/warehouse/${wareHouseUuid}/sites`, data);
  }, 

  async update (data: sitesType, siteUuid:string) {
    return axios.put(`/deliverylocations/${siteUuid}`, {
      address: data.address,
      contactFullName: data.contactFullName,
      contactPhoneNumber: data.contactPhoneNumber,
      country: data.country,
      name: data.name, 
      isDefault: data.isDefault
    });
  },

  async delete ( wareHouseUuid:string) {
    return axios.delete(`/deliverylocations/${wareHouseUuid}`);
  }, 

  async getDetails ( wareHouseUuid:string) {
    return axios.get(`/warehouse/${wareHouseUuid}`);
  },  

  async moveToSite ( wareHouseUuid:string, data: any) {
    return axios.post(`/warehouse/${wareHouseUuid}/delivery-to-site`, data);
  }, 
 
  
}
export default sitesService

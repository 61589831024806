import React, { FC } from 'react';
import './SellerPageOrderSummaryItem.scss';
import { NumberWithComma } from '../../../Helpers/NumberWithCommer';
import ImageSrc from '../../ImageSrc/ImageSrc';

interface propsTypes {
    imageUrl:string;
    color?:string;
    name:string;
    quantity:Number;
    amount:Number;
    currency:string;
}

const SellerPageOrderSummaryItem:FC<propsTypes> = (prop:propsTypes) => {
    const { imageUrl, color, name, quantity, amount, currency } = prop;
    return (
        <li className='list-group-item order-summary-item'>
            <div className='d-flex justify-content-between'>
                <div className='d-flex flex-row'>
                    <ImageSrc width='product-image' alt={name} src={imageUrl} title={name} />
                    <div className='item-description d-flex flex-column justify-content-between'
                    >
                         <div className='d-flex flex-column'>
                            <p className='fw-bold mb-2'>{name}</p>
                            <p>Color: {color}</p>
                         </div>
                         <p>{`Quantity: ${quantity}`}</p>
                    </div>
                </div>
                <div>
                    <p className='amount'>{`${currency}${NumberWithComma(amount)}`}</p>
                </div> 
            </div>  
        </li>
    )
}
export default SellerPageOrderSummaryItem

import { MOBILE_MENU } from "../../Constants/MobileMenuConstants";

 
const MobileMenuReducer = (state = false, action: { type: string; payload: boolean; }) => {
  switch (action.type) {
    case MOBILE_MENU:
      return action.payload
    default:
      return state
  }
}

export default MobileMenuReducer;

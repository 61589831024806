import React from 'react'
import { HeaderMeta, ImageSrc } from '../../../../Components'
import "./EnterpriceHome.scss"
import enterpriseCargo from "../../../../Assets/Images/enterpriseCargo.png"
import enterprise1 from "../../../../Assets/Images/enterprise/1.png"
import enterprise2 from "../../../../Assets/Images/enterprise/2.png"
import enterprise3 from "../../../../Assets/Images/enterprise/3.png"
import enterprise4 from "../../../../Assets/Images/enterprise/4.png"
import { HomeNewsletter } from '../../Components'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider'
import { BasicRBAC } from '../../../../Helpers/BasicRBAC';

const EnterpriceHome = () => {
    const { user } = useAuth()
    const access = BasicRBAC
    
    return ( 
        <div className='enterpriceHome'>
        <HeaderMeta title="Enterprise Account"></HeaderMeta>
            <div className="container">
                <div className="enterprise-header py-4 px-3">
                    <div className="row">
                        <div className="col-md-6 my-auto">
                            <div className="page-title">
                            CinderBuild for Corporate
                            </div> 
                        </div>
                        <div className="col-md-6 position-relative overflow-hidden">
                           <ImageSrc src={enterpriseCargo} alt={''} title={undefined} width={'w-100'} ></ImageSrc> 
                        </div>
                    </div>
                </div>

                <div className="enterprise-intro">
                    <div className="text-center w-100"> 
                        <h3 className="enterprise-intro-title">
                        Why CinderBuild for Corporate?
                        </h3>
                    </div>

                    <div className="row py-5">
                        <div className="col-12 col-sm-6 col-md">
                            <div className="card border-0 mt-3" data-aos="fade-up" data-aos-duration="9000">
                                <div className="card-body d-flex align-items-center px-3">
                                    Store purchased items virtually
                                </div>
                            </div>
                        </div> 

                        <div className="col-12 col-sm-6 col-md">
                            <div className="card border-0 mt-3" data-aos="fade-up" data-aos-duration="7000">
                                <div className="card-body d-flex align-items-center px-3">
                                Earn stock points as you purchase
                                </div>
                            </div>
                        </div> 

                        <div className="col-12 col-sm-6 col-md">
                            <div className="card border-0 mt-3" data-aos="fade-up" data-aos-duration="5000">
                                <div className="card-body d-flex align-items-center px-3">
                                Centralized Procurement
                                </div>
                            </div>
                        </div> 

                        <div className="col-12 col-sm-6 col-md">
                            <div className="card border-0 mt-3" data-aos="fade-up" data-aos-duration="3000">
                                <div className="card-body d-flex align-items-center px-3">
                                Real-time digital inventory management
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md">
                            <div className="card border-0 mt-3" data-aos="fade-up" data-aos-duration="1000">
                                <div className="card-body d-flex align-items-center px-3">
                                Avoid price inflation
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                


                <div className="hiw"> 
                        <div className="hiw-title">
                        How it works
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">

                                <div className="card border-0 mt-3" data-aos="fade-up" data-aos-duration="9000">
                                    <div className="card-image">
                                        <ImageSrc src={enterprise1} alt={''} title={undefined} width={''}></ImageSrc>
                                    </div>
                                    <div className="card-body"> 
                                        <div className="tag">Step 1</div>
                                        <div className="title">Register under 2 minutes</div>
                                        <div className="desc">
                                        Simply click the “Get started” button below to upgrade your account if you’re not registered as a Corporate buyer. By signing up for this service, you&apos;ll gain access to the amazing benefits and features we offer.  
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                               
                                <div className="card border-0 mt-3" data-aos="fade-up" data-aos-duration="7000">
                                    <div className="card-image">      
                                       <ImageSrc src={enterprise2} alt={''} title={undefined} width={''}></ImageSrc> 
                                    </div>
                                    <div className="card-body"> 
                                        <div className="tag">Step 2</div>
                                        <div className="title">Create your first warehouse</div>
                                        <div className="desc">
                                        Once you’ve signed up, you’ll need to create a warehouse to store and monitor your cloud inventory real-time. 
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3"> 

                                <div className="card border-0 mt-3" data-aos="fade-up" data-aos-duration="5000">
                                    <div className="card-image">
                                       <ImageSrc src={enterprise3} alt={''} title={undefined} width={''}></ImageSrc> 
                                    </div>
                                    <div className="card-body"> 
                                        <div className="tag">Step 3</div>
                                        <div className="title">Purchase product and store in the cloud warehouse</div>
                                        <div className="desc">
                                        Avoid inflation-driven cost overruns by bulk purchasing at the current selling price and make stock withdrawals as desired.
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                               
                               <div className="card border-0 mt-3"  data-aos="fade-up" data-aos-duration="3000">
                                    <div className="card-image">
                                       <ImageSrc src={enterprise4} alt={''} title={undefined} width={''}></ImageSrc> 
                                    </div>
                                    <div className="card-body"> 
                                        <div className="tag">Step 4</div>
                                        <div className="title">Create sites and ship products anytime</div>
                                        <div className="desc">
                                        Multiple delivery locations can be created as you draw down your desired product and volume at each instance whilst selecting your preferred delivery date.
                                        </div>
                                    </div>
                                </div>
                                 
                            </div>
                        </div>
                    </div> 
  
                    <div className="text-center gst">
                        {user && access("enterprice-get-started") === true? 
                        <Link to="/enterprise/products">
                            <button className='btn btn-primary rounded-pill'> Shop Now </button>
                        </Link>
                        :<Link to="/enterprise-setup/">
                            <button className='btn btn-primary rounded-pill'> Get Started </button>
                        </Link>} 
                    </div>

                   <HomeNewsletter></HomeNewsletter>

            </div>
        </div>
    )
}

export default EnterpriceHome

import { combineReducers } from 'redux';
import {
  CartDrawerReducer,
  CartQuantityReducer,
  EditDeliveryAddressReducer,
  CartDrawerMobReducer,
  NewDeliveryAddressReducer,
  PickupContactInformationReducer,
  DocumentUploadReducer,
  StepRegReducer,
  TutorialTipsRefReducers,
  MobileMenuReducer,
  ActiveMenuReducer
} from "."
import AccountVerificationReducer from './AccountVerification/AccountVerificationReducer';

const rootReducer = combineReducers({
  cartQty: CartQuantityReducer,
  cartDrawer: CartDrawerReducer,
  cartDrawerMob: CartDrawerMobReducer,
  editDeliveryAddress: EditDeliveryAddressReducer,
  newDeliveryAddress: NewDeliveryAddressReducer,
  pickupContactInformation: PickupContactInformationReducer,
  documentUpload: DocumentUploadReducer,
  step: StepRegReducer,
  tutorialRefs: TutorialTipsRefReducers,
  mobileMenu: MobileMenuReducer,
  estateVerify: AccountVerificationReducer, 
  activeMenu: ActiveMenuReducer,
});

export default rootReducer;

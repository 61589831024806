import React, { useState, useEffect, useMemo }  from 'react';
import "./BuyerOrderTrackingPage.scss"
import { useParams } from 'react-router-dom';
import BuyerPageTitle from '../../../../../../Components/Buyer/BuyerPageTitle/BuyerPageTitle'; 
import { Logger } from '../../../../../../Helpers/Logger';
import { toast, Toaster } from 'react-hot-toast'
import orderService from '../../../../../../Services/orderService';
import { TimeConverter } from '../../../../../../Helpers/TimeConverter';
import BuyerPageOrderSummaryItem from '../../../../../../Components/Buyer/BuyerPageOrderSummaryItem/BuyerPageOrderSummaryItem';
import { NumberWithComma } from '../../../../../../Helpers/NumberWithCommer';
import BuyerPageOrderPaymentMethod from '../../../../../../Components/Buyer/BuyerPageOrderPaymentMethod/BuyerPageOrderPaymentMethod';
import BuyerPageOrderDeliveryAddress from '../../../../../../Components/Buyer/BuyerPageOrderDeliveryAddress/BuyerPageOrderDeliveryAddress';
import BuyerPageOrderContactDetails from '../../../../../../Components/Buyer/BuyerPageOrderContactDetails/BuyerPageOrderContactDetails';
import BuyerPageOrderTrackingCard from '../../Component/BuyerPageOrderTrackingCard/BuyerPageOrderTrackingCard';
import BuyerPageOrderTrackingCardItem from '../../../../../../Components/Buyer/BuyerPageOrderTrackingCardItem/BuyerPageOrderTrackingCardItem';
import StatusIcon from "../../../../../../Assets/Images/icon/status.svg";
import CreatedIcon from "../../../../../../Assets/Images/icon/created.svg";
import TruckIcon from "../../../../../../Assets/Images/icon/truck.svg";
import BuyerPageDisputeModal from '../../../../../../Components/Buyer/BuyerPageDisputeModal/BuyerPageDisputeModal';

const BuyerOrderTrackingPage = ()=>{
    const { orderId } = useParams<{orderId:string}>() 
  const [orderDetails, setOrderdetails] = useState<any>(null) 
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
  // eslint-disable-next-line no-unused-vars
  const [orderConfirmed, setOrderConfirmed] = useState<boolean>(false); 
  const [showDisputeModal, setShowDisputeModal]  = useState<boolean>(false);
  const [loadingOrderStatusChange, setLoadingOrderStatusChange] = useState<boolean>(false)

  const getOrder = async (orderId: string| undefined) => {
    setLoadingRequest(true)
    await orderService.getSingleOrder(orderId).then((res) => {
      setOrderdetails(res.data.data)
      Logger("setOrderdetails", res.data)
      setLoadingRequest(false)
    }, (error:any) => {
      setLoadingRequest(false)
      Logger("orderdetails error", error.response)
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }

  useEffect(() => {
    getOrder(orderId)
  }, [])

  const contructTrackingListItems = useMemo (()=>{
      const trackingList:any = [];
      type statusDataArrayFormat= [string,string,string,string]

      const allStatus:statusDataArrayFormat =[
        'Created',
        'In progress',
        'Ready for delivery',
        'Delivery confirmed'
      ]

      const statusIcons:statusDataArrayFormat = [
        CreatedIcon,
        StatusIcon,
        StatusIcon,
        TruckIcon

      ]

      for ( let i:number=0; i<4; i++) {
        if (orderDetails?.statusHistory[i]) {
          trackingList.push({
            status:allStatus[i],
            dateTimeInISO8601:orderDetails.statusHistory[i].dateTimeInISO8601,
            icon:statusIcons[i]
          })
          
          if (orderDetails?.statusHistory[i].status==='CONFIRMED' &&orderDetails?.statusHistory[i].dateTimeInISO8601) {
            setOrderConfirmed(true)
          }
          continue;
         }

         trackingList.push(
          {
            status:allStatus[i],
            dateTimeInISO8601:'',
            icon:statusIcons[i]
          }
         )
         
      }
      return trackingList;

  },[orderDetails])

  const totalCost = useMemo(() => {
    // eslint-disable-next-line
    return orderDetails?.deliveryCostMajor || 0 + orderDetails?.calculatedTotalCostMajor || 0 
  }, [orderDetails])

  const confirmOrder = async (orderUid:string | undefined, status:string)=>{
    setLoadingOrderStatusChange(true); 
    orderService.buyerConfirmOrder(orderUid,status).then((res)=>{
      setLoadingOrderStatusChange(false)  
      setOrderdetails(res.data.data)
      setOrderConfirmed(true);
     }).catch((error:any)=>{
      setLoadingOrderStatusChange(false)
      Logger("orderdetails error", error.response)
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
     })
  }

  const handleShowDispute = ()=>setShowDisputeModal(true);
  const handleCloseDispute = ()=>setShowDisputeModal(false);

  return (
        <div className='buyerOrderTracking'>
            <div className="container mt-4">

              <div className="d-sm-none"> <BuyerPageTitle title={'Track order'} parent={'My orders'} parentUrl={'my-orders'}></BuyerPageTitle>  </div>
               
               <div className='d-flex mb-5 justify-content-between align-items-center'>
               <div className="d-none d-sm-block"><BuyerPageTitle title={'Track order'} parent={'My orders'} parentUrl={'my-orders'}></BuyerPageTitle>  </div>
                <div className='d-block d-md-flex align-items-center btn-row'> 
                    <button className="btn dispute-btn mb-md-0 btn-outline-primary"
                      onClick={handleShowDispute}
                    >
                      Raise dispute
                    </button>  
                    {orderDetails && orderDetails?.orderReceiveType === 'PICKUP'
                        ?  
                       <button className="btn btn-primary ms-4 confirm-delivery-btn" onClick={()=>confirmOrder(orderId,'CONFIRMED')} disabled={ orderDetails && orderDetails?.status !== "IN_PROGRESS"}>Confirm {orderDetails?.orderReceiveType === 'PICKUP' ? "Pickup" : "Delivery"}</button>
                         
                        :  
                       <button className="btn btn-primary ms-4 confirm-delivery-btn" onClick={()=>confirmOrder(orderId,'CONFIRMED')} disabled={ orderDetails && orderDetails?.status !== "IN_PROGRESS"}>Confirm {orderDetails?.orderReceiveType === 'PICKUP' ? "Pickup" : "Delivery"}</button>
                         } 
                </div>
               </div>
               <div className='row'>
                <div className="col-12">
                  <BuyerPageOrderTrackingCard
                    orderId={orderDetails?.referenceNumber}
                    isLoading={loadingRequest}
                    isUpdatingOrderStatus={loadingOrderStatusChange}
                  >
                    {
                      contructTrackingListItems?.map((item:any) => {
                        return (
                          <BuyerPageOrderTrackingCardItem
                            itemIcon={item.icon}
                            itemValue={item.dateTimeInISO8601}
                            itemTitle={item.status}
                            key={item.status}
                          />
                        )
                      })
                    }
                  </BuyerPageOrderTrackingCard>
                </div>
               </div>
               <div className="row">
                <div className="col-md-6 mb-4">
                   <div className="card order-summary">
                        <div className="card-header card-custom-header bg-white">
                         <div className="row">
                            <div className="col-sm-6">
                              <p>Order summary</p>
                              <p className='fw-normal'>{orderDetails?.referenceNumber}</p>
                            </div>
                            <div className="col-sm-6 text-end order-time text-end">{TimeConverter(orderDetails?.createdAt)}</div>
                         </div>
                        </div>
                       <div className="card-body">
                        {/* eslint-disable-next-line */}
                        <ul className="list-group list-group-flush">
                          {/* eslint-disable-next-line */}
                          { loadingRequest && 
                            <div className='text-secondary text-center fw-bold'>
                              <span className='spinner-border spinner-border-lg'></span>
                            </div>
                          }
                        {
                          orderDetails?.orderItems?.map((orderItem:any) => {
                            return (
                              <BuyerPageOrderSummaryItem
                                  imageUrl={orderItem.images[0].url}
                                  name={orderItem.productName}
                                  quantity={orderItem.quantity}
                                  amount={orderItem.unitPriceForBuyer}
                                  key={orderItem.orderUuid}
                                  color={orderItem.color || 'N/A'}
                                  currency={orderDetails.currencySymbol}
                              />
                            )
                          })
                         }
                        </ul>
                       </div>
                       <div className="card-footer">
                          <div className='d-flex flex-row justify-content-between price-breakdown'
                          >
                             <p>Subtotal</p>
                             <p>₦{NumberWithComma(orderDetails?.calculatedTotalCostMajor || 0)}</p>
                          </div>
                          <div className='d-flex flex-row justify-content-between price-breakdown'>
                             <p>Delivery</p>
                             <p>₦{NumberWithComma(orderDetails?.deliveryCostMajor || 0)}</p>
                          </div>
                          <div className='d-flex flex-row justify-content-between
                            fw-bold orde-summary-total price-total'
                          >
                             <p>Total</p>
                             <p>₦{NumberWithComma(totalCost)}</p>
                          </div>
                       </div>
                   </div>
                </div>
                <div className="col-md-6 mb-4">
                   <div className="card order-summary">
                        <div className="card-header card-custom-header bg-white">
                          <p>Payment and delivery information</p>
                        </div>
                       <div className="card-body">
                          {/* eslint-disable-next-line */}
                        <ul className="list-group list-group-flush">
                          {/* eslint-disable-next-line */}
                          { loadingRequest && 
                            <div className='text-secondary text-center fw-bold'>
                              <span className='spinner-border spinner-border-lg'></span>
                            </div>
                          }
                          <BuyerPageOrderPaymentMethod
                                method={'card'}
                          />
                          <BuyerPageOrderDeliveryAddress
                            address= {orderDetails?.orderLocation?.address}
                            state={orderDetails?.orderLocation?.state}
                            country={orderDetails?.orderLocation?.country}
                          />
                          <BuyerPageOrderContactDetails
                            contactFullName={orderDetails?.orderLocation.contactFullName}
                            contactEmail={orderDetails?.orderLocation.contactEmail}
                            contactPhoneNumber={orderDetails?.orderLocation.contactPhoneNumber}
                          />
                        </ul>
                       </div>
                   </div>
                </div>
               </div>
                {/* Dispute modal */}
                <BuyerPageDisputeModal showDisputeModal={showDisputeModal}
                  handleClose = {handleCloseDispute} orderDetails={orderDetails}
                />
             </div>

             <Toaster></Toaster>
        </div>
  )
}

export default BuyerOrderTrackingPage;

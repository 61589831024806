import React from "react"
import { useLiveQuery } from 'dexie-react-hooks'; 
import CartElement from "./CartElement/CartElement";
import "./CartItem.scss"
import { db } from "../../../../../Config/DBConfig";

const CartItem = () => {
  const cartData = useLiveQuery(
    async () => await db.cart.toArray()
  );

  return (
        <div className='cartItem'>
            <div className="cart-item-list">
                {cartData?.length ? cartData.map((res, index:React.Key) => <CartElement key={index} item={res}></CartElement>) : <div className='text-danger'>Cart is empty</div>}
            </div>
        </div>
  )
}

export default CartItem

import {FC} from "react";
import "./InputError.scss";

interface propTypes {
    error:string
}

const InputError:FC<propTypes> = ({error})=>{
    return (
        <span className="error-message"> 
            {error}
        </span>
    )
}

export default InputError
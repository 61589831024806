import React from 'react'
import { Link } from 'react-router-dom'
import "./Builder360.scss"

const Builder360 = () => {
    return (
        <div className='builder360'>
            <div className="card">
                <div className="card-header">
                Builder 360™
                </div>
                <div className="card-body">
                    <div className='desc'>
                        The more you purchase, the more you unlock advanced business solutions that increases your market share, expand your reach and grow your business exponentially.
                    </div>

                    <div className="menu mt-2">
                        <div className="row">
                            <div className="col-6 col-md-4">
                                {/* <Link to="/enterprise/plp"> */}
                                <Link to="#">
                                    <div className="card active"> 
                                        <div className="card-body">
                                            <div className="lock">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.0004 14.5984C12.3317 14.5984 12.6003 14.3298 12.6003 13.9984C12.6003 13.6671 12.3317 13.3984 12.0004 13.3984C11.669 13.3984 11.4004 13.6671 11.4004 13.9984C11.4004 14.3298 11.669 14.5984 12.0004 14.5984Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg> 
                                            </div>
                                            <div>
                                                <div className="icon-container text-center w-100 ">
                                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.59 6.98344C20.74 6.04344 19.32 5.57344 17.26 5.57344H17.02V5.53344C17.02 3.85344 17.02 1.77344 13.26 1.77344H11.74C7.98004 1.77344 7.98004 3.86344 7.98004 5.53344V5.58344H7.74004C5.67004 5.58344 4.26004 6.05344 3.41004 6.99344C2.42004 8.09344 2.45004 9.57344 2.55004 10.5834L2.56004 10.6534L2.63749 11.4667C2.65176 11.6165 2.73242 11.7518 2.85831 11.8342C3.09812 11.9911 3.49946 12.2498 3.74004 12.3834C3.88004 12.4734 4.03004 12.5534 4.18004 12.6334C5.89004 13.5734 7.77004 14.2034 9.68004 14.5134C9.77004 15.4534 10.18 16.5534 12.37 16.5534C14.56 16.5534 14.99 15.4634 15.06 14.4934C17.1 14.1634 19.07 13.4534 20.85 12.4134C20.91 12.3834 20.95 12.3534 21 12.3234C21.3968 12.0992 21.8083 11.8229 22.1835 11.5523C22.2965 11.4708 22.3688 11.3447 22.3842 11.2062L22.4 11.0634L22.45 10.5934C22.46 10.5334 22.46 10.4834 22.47 10.4134C22.55 9.40344 22.53 8.02344 21.59 6.98344ZM13.59 13.8334C13.59 14.8934 13.59 15.0534 12.36 15.0534C11.13 15.0534 11.13 14.8634 11.13 13.8434V12.5834H13.59V13.8334ZM9.41004 5.57344V5.53344C9.41004 3.83344 9.41004 3.20344 11.74 3.20344H13.26C15.59 3.20344 15.59 3.84344 15.59 5.53344V5.58344H9.41004V5.57344Z" fill="#F5A623"/>
                                                        <path d="M21.3733 13.7349C21.7269 13.5666 22.1342 13.8469 22.0988 14.2369L21.7398 18.1907C21.5298 20.1907 20.7098 22.2307 16.3098 22.2307H8.68984C4.28984 22.2307 3.46984 20.1907 3.25984 18.2007L2.91913 14.4529C2.88409 14.0674 3.28205 13.7874 3.63468 13.947C4.7741 14.4625 6.87724 15.3771 8.17641 15.7174C8.34072 15.7604 8.47361 15.878 8.54556 16.0319C9.15253 17.33 10.469 18.0207 12.3698 18.0207C14.252 18.0207 15.585 17.3034 16.194 16.0021C16.266 15.8481 16.3991 15.7305 16.5635 15.6873C17.943 15.3243 20.1816 14.3019 21.3733 13.7349Z" fill="#F5A623"/>
                                                    </svg> 
                                                </div>
                                                <div className="title">
                                                PLP 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            
                            <div className="col-6 col-md-4"> 
                                <Link to="/enterprise/warehouse-management">
                                    <div className="card"> 
                                        <div className="card-body">
                                            <div className="lock">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.0004 14.5984C12.3317 14.5984 12.6003 14.3298 12.6003 13.9984C12.6003 13.6671 12.3317 13.3984 12.0004 13.3984C11.669 13.3984 11.4004 13.6671 11.4004 13.9984C11.4004 14.3298 11.669 14.5984 12.0004 14.5984Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg> 
                                            </div>
                                            <div>
                                                <div className="icon-container text-center w-100 ">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.74 12.9089C21.48 12.0489 21.05 11.2989 20.48 10.6889C19.75 9.85888 18.78 9.28888 17.69 9.03888C17.14 6.53888 15.6 4.73888 13.41 4.06888C11.03 3.32888 8.27 4.04888 6.54 5.85888C5.02 7.44888 4.52 9.63888 5.11 11.9689C3.11 12.4589 2.12 14.1289 2.01 15.7189C2 15.8289 2 15.9289 2 16.0289C2 17.9089 3.23 20.0189 5.97 20.2189H16.35C17.77 20.2189 19.13 19.6889 20.17 18.7389C21.8 17.3089 22.4 15.0789 21.74 12.9089Z" fill="#F5A623"/>
                                                    </svg> 
                                                </div>
                                                <div className="title">
                                                Cloud Warehouse
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            
                            
                            <div className="col-6 col-md-4"> 
                                <Link to="">
                                    <div className="card active"> 
                                        <div className="card-body">
                                            <div className="lock">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.0004 14.5984C12.3317 14.5984 12.6003 14.3298 12.6003 13.9984C12.6003 13.6671 12.3317 13.3984 12.0004 13.3984C11.669 13.3984 11.4004 13.6671 11.4004 13.9984C11.4004 14.3298 11.669 14.5984 12.0004 14.5984Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg> 
                                            </div>
                                            <div>
                                                <div className="icon-container text-center w-100 ">
                                                    <svg width="35" height="21" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M27.8445 11.8908C27.7733 12.2228 27.596 12.5229 27.3394 12.7453L26.6516 13.3577L17.1733 6.66785C16.8147 6.7554 16.4743 6.90652 16.1687 7.11378L13.2993 8.75786V8.7581C13.0923 8.8658 12.8608 8.91786 12.6276 8.9085C11.7537 8.89603 10.919 8.54317 10.301 7.92523C10.236 7.85255 10.2043 7.75636 10.2135 7.65945C10.2228 7.56254 10.272 7.47379 10.3492 7.41477L15.0669 4.19084H15.0671C15.5154 3.9668 16.0022 3.83007 16.5015 3.78785L23.4278 2.39081C23.6933 2.31765 23.9737 2.31765 24.239 2.39081C24.6233 2.48148 24.9476 2.73719 25.1256 3.08933L27.8445 11.8908Z" fill="#F5A623"/>
                                                        <path d="M32.1919 10.9469L28.795 11.9841L25.7666 2.06608L32.072 0.140625L34.2124 7.15038C34.4479 7.92179 34.3673 8.75516 33.9883 9.46731C33.6096 10.1793 32.9634 10.7116 32.1919 10.9471L32.1919 10.9469Z" fill="#F5A623"/>
                                                        <path d="M3.15114 0L8.61119 2.01302L5.45106 10.5838L2.20434 9.38656C1.61377 9.17499 1.13283 8.73484 0.870126 8.16509C0.607464 7.59539 0.584675 6.94387 0.807041 6.35735L3.15114 0Z" fill="#F5A623"/>
                                                        <path d="M27.0316 15.4867C27.0328 15.8729 26.8779 16.243 26.6018 16.5131C26.3492 16.7472 26.023 16.8866 25.679 16.907C25.3353 16.9276 24.9946 16.8283 24.7159 16.6259H24.6837L23.9207 16.0886C24.1049 16.3373 24.2047 16.6386 24.2054 16.9483C24.2078 16.9948 24.2078 17.0413 24.2054 17.0879C24.1807 17.3232 24.0977 17.5487 23.9636 17.7435C23.8048 17.9687 23.5846 18.1436 23.3294 18.2474C23.0742 18.3511 22.7945 18.3796 22.5237 18.3292C22.3301 18.2887 22.147 18.2081 21.9863 18.0927L21.5941 17.8188L21.1751 17.5233H21.1748C21.2552 17.6633 21.3116 17.8159 21.3415 17.9745C21.4149 18.3544 21.3317 18.7476 21.111 19.0654C20.8903 19.383 20.5509 19.598 20.1693 19.6618C19.7879 19.7256 19.3966 19.6325 19.0848 19.4039L18.5796 19.0494V19.0491C18.6473 19.2202 18.682 19.4027 18.6818 19.5865V19.6563V19.6565C18.6688 19.9559 18.5616 20.2435 18.3755 20.4786C18.1291 20.7655 17.7837 20.9485 17.4081 20.9914C17.0324 21.0344 16.6544 20.9336 16.3497 20.7096L11.2291 17.1632C11.1324 17.0934 10.106 16.0133 10.106 16.0133L6.64551 12.3326H6.64575C6.38213 12.0776 6.2087 11.7439 6.15137 11.3817L9.59023 2.04828C9.72312 1.8144 9.9191 1.6225 10.1556 1.4944C10.3919 1.36607 10.6596 1.30682 10.9283 1.32289L17.1827 1.72061L17.1825 1.72037C17.3573 1.73212 17.5286 1.77578 17.6876 1.84942C17.7824 1.87557 17.8743 1.91155 17.9616 1.95689L18.6548 2.38147L16.0327 2.91879C15.5004 2.97253 14.9869 3.14332 14.5282 3.41845L9.8106 6.64239H9.81036C9.52299 6.83285 9.32821 7.13485 9.27304 7.47521C9.21739 7.83455 9.3107 8.2013 9.53114 8.49059C10.3261 9.34478 11.4377 9.83439 12.6044 9.84471C13.005 9.85958 13.402 9.76675 13.7544 9.57605L16.6183 7.93718L17.0053 7.71698L26.623 14.4817C26.6657 14.5256 26.7053 14.5722 26.7413 14.6216C26.9289 14.871 27.0306 15.1745 27.0313 15.4866L27.0316 15.4867Z" fill="#F5A623"/>
                                                    </svg> 
                                                </div>
                                                <div className="title">
                                                CB-Branding
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            
                            
                            <div className="col-6 col-md-4"> 
                                <Link to="">
                                    <div className="card active"> 
                                        <div className="card-body">
                                            <div className="lock">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.0004 14.5984C12.3317 14.5984 12.6003 14.3298 12.6003 13.9984C12.6003 13.6671 12.3317 13.3984 12.0004 13.3984C11.669 13.3984 11.4004 13.6671 11.4004 13.9984C11.4004 14.3298 11.669 14.5984 12.0004 14.5984Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg> 
                                            </div>
                                            <div>
                                                <div className="icon-container text-center w-100 ">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z" fill="#F5A623"/>
                                                        <path d="M17.7727 14.3595C14.6023 12.5468 9.43182 12.5468 6.23864 14.3595C4.79545 15.1781 4 16.2989 4 17.4976C4 18.6963 4.79545 19.8073 6.22727 20.6259C7.81818 21.542 9.90909 22 12 22C14.0909 22 16.1818 21.542 17.7727 20.6259C19.2045 19.7975 20 18.6865 20 17.4781C19.9886 16.2891 19.2045 15.1684 17.7727 14.3595Z" fill="#F5A623"/>
                                                        <path d="M11.8102 19.08L14.3302 16.56C14.5802 16.32 14.5802 15.92 14.3302 15.68C14.0902 15.44 13.6902 15.44 13.4502 15.68L11.3702 17.76L10.5502 16.94C10.3102 16.7 9.91023 16.7 9.67023 16.94C9.43023 17.18 9.43023 17.58 9.67023 17.82L10.9302 19.08C11.0502 19.19 11.2102 19.26 11.3702 19.26C11.5302 19.26 11.6902 19.2 11.8102 19.08Z" fill="white"/>
                                                    </svg> 
                                                </div>
                                                <div className="title">
                                                Hirelly
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link> 
                            </div>
                            
                            
                            <div className="col-6 col-md-4"> 
                                <Link to="">
                                    <div className="card active"> 
                                        <div className="card-body">
                                            <div className="lock">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.0004 14.5984C12.3317 14.5984 12.6003 14.3298 12.6003 13.9984C12.6003 13.6671 12.3317 13.3984 12.0004 13.3984C11.669 13.3984 11.4004 13.6671 11.4004 13.9984C11.4004 14.3298 11.669 14.5984 12.0004 14.5984Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg> 
                                            </div>
                                            <div>
                                                <div className="icon-container text-center w-100 ">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.9996 21.9331C6.95961 21.9331 2.84961 17.8331 2.84961 12.7831V10.9031C2.84961 10.5131 3.16961 10.2031 3.54961 10.2031C3.92961 10.2031 4.24961 10.5231 4.24961 10.9031V12.7831C4.24961 17.0531 7.71961 20.5231 11.9896 20.5231C16.2596 20.5231 19.7296 17.0531 19.7296 12.7831V10.9031C19.7296 10.5131 20.0496 10.2031 20.4296 10.2031C20.8096 10.2031 21.1296 10.5231 21.1296 10.9031V12.7831C21.1496 17.8331 17.0396 21.9331 11.9996 21.9331Z" fill="#F5A623"/>
                                                        <path d="M12.0004 2C8.64039 2 5.90039 4.74 5.90039 8.1V12.79C5.90039 16.15 8.64039 18.89 12.0004 18.89C15.3604 18.89 18.1004 16.15 18.1004 12.79V8.1C18.1004 4.74 15.3604 2 12.0004 2ZM14.1804 10.59C14.1104 10.86 13.8604 11.04 13.5904 11.04C13.5404 11.04 13.4804 11.03 13.4304 11.02C12.4104 10.74 11.3304 10.74 10.3104 11.02C9.98039 11.11 9.65039 10.92 9.56039 10.59C9.47039 10.27 9.66039 9.93 9.99039 9.84C11.2204 9.5 12.5204 9.5 13.7504 9.84C14.0804 9.93 14.2704 10.26 14.1804 10.59ZM15.0304 7.82C14.9404 8.07 14.7104 8.22 14.4604 8.22C14.3904 8.22 14.3204 8.21 14.2504 8.18C12.7204 7.62 11.0404 7.62 9.51039 8.18C9.19039 8.3 8.84039 8.14 8.72039 7.82C8.61039 7.51 8.77039 7.16 9.09039 7.04C10.8904 6.39 12.8704 6.39 14.6604 7.04C14.9804 7.16 15.1404 7.51 15.0304 7.82Z" fill="#F5A623"/>
                                                    </svg> 
                                                </div>
                                                <div className="title">
                                                CB-Cast
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            
                            
                            <div className="col-6 col-md-4"> 
                                <Link to="">
                                    <div className="card active"> 
                                        <div className="card-body">
                                            <div className="lock">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.0004 14.5984C12.3317 14.5984 12.6003 14.3298 12.6003 13.9984C12.6003 13.6671 12.3317 13.3984 12.0004 13.3984C11.669 13.3984 11.4004 13.6671 11.4004 13.9984C11.4004 14.3298 11.669 14.5984 12.0004 14.5984Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg> 
                                            </div>
                                            <div>
                                                <div className="icon-container text-center w-100 ">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.7997 2.21048C15.3897 1.80048 14.6797 2.08048 14.6797 2.65048V6.14048C14.6797 7.60048 15.9197 8.81048 17.4297 8.81048C18.3797 8.82048 19.6997 8.82048 20.8297 8.82048C21.3997 8.82048 21.6997 8.15048 21.2997 7.75048C19.8597 6.30048 17.2797 3.69048 15.7997 2.21048Z" fill="#F5A623"/>
                                                        <path d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM11.5 17.75H7.5C7.09 17.75 6.75 17.41 6.75 17C6.75 16.59 7.09 16.25 7.5 16.25H11.5C11.91 16.25 12.25 16.59 12.25 17C12.25 17.41 11.91 17.75 11.5 17.75ZM13.5 13.75H7.5C7.09 13.75 6.75 13.41 6.75 13C6.75 12.59 7.09 12.25 7.5 12.25H13.5C13.91 12.25 14.25 12.59 14.25 13C14.25 13.41 13.91 13.75 13.5 13.75Z" fill="#F5A623"/>
                                                    </svg> 
                                                </div>
                                                <div className="title">
                                                CB-Report
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Builder360

import React, { useState, useRef, FC, Key, createRef, useEffect } from 'react'
import "./CreateVariation.scss"
import { UploadSvg } from '../../../Interface/CreateProduct/CreateProduct' 
import VariationList from './VariationList/VariationList'
import { Color } from 'react-color'
import { GetColorName } from 'hex-color-to-color-name'; 
import { Variable } from './Variable'

 
interface propsType {
    setVariationParient: Function,
    variations? :any,
    setImages:Function
}

const CreateVariation:FC<propsType> = ({setVariationParient, variations:variationPar, setImages:setDefaultImage}) => {
    
   const [variables, setVariables] = useState<any[]>([[]])
   const [variations, setVariations] = useState<any[]>([])

   const tagInput = useRef<any>(null)

   const formRef = useRef<any[]>([createRef(), createRef(), createRef()]);

   const [images, setImages] = useState<any>([])
   const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false)  
   const [tempFields, setTempFields] = useState<any>({attribute: []})  

   const [showColorPicker, setShowColorPicker] = useState(false)
   // eslint-disable-next-line no-unused-vars
   const [selectedColor, setSelectedColor] = useState("")
   const [selectedAttributes, setSelectedAttributes] = useState<any[]>([])
 

   const [showForm, setShowForm] = useState(true)
   
        
   useEffect(() => {
    setVariations(variationPar)
   }, [variationPar])
   
  const removeTag = (i:any, pi:number) => {
    const newTags = [...variables];
    newTags[pi].splice(i, 1);
    setVariables(newTags);
  }

   
  const inputKeyDown = (e:any, i:number) => {
    const val = e.target.value;
 
    if (val.indexOf(',') > -1) {  
      if (variables[i] && variables[i].find((tag:any) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
    // delete from inpute field
    //   tagInput.current.value = '';  

      const newValue = val.charAt(0).toUpperCase() + val.slice(1).replace(/,/g, '')
       
      console.log("inputKeyDown 001", tempFields.attribute)

      const mainTemp = tempFields.attribute
      mainTemp[i] = { name: tempFields?.attribute[i].name, value: [...tempFields?.attribute[i].value, newValue]}
  
      setTempFields({...tempFields,  attribute: mainTemp});
     
      const mainVariable = variables 

      mainVariable[i] = [...mainVariable[i], newValue]
       
      setVariables([...mainVariable, []]);
      console.log("inputKeyDown", [...mainVariable, []])
    //   tagInput.current.value = '';   
    } 
  }


  const [attributeRow, setAttributeRow] = useState([{
    name: "",
    value: "",
  }])
 
  const handleRemoveFormRow = (index: number) => {
   
    const values = [...attributeRow];
    values.splice(index, 1);
    setAttributeRow(values);
 
  };

 

  // eslint-disable-next-line no-unused-vars
  const AddField = () => {   
    setAttributeRow([...attributeRow, {
        name: "",
        value: ""
      }]);
  }




  const handleFileUpload = async (event: { target: any }) => {
    setLoadingPhoto(true) 
    setImages([...images, ...event.target.files]) 
    setDefaultImage(event.target.files)
    setLoadingPhoto(false)
  };


  const removeImage = (i:number) => {
    const filteredItems = images.slice(0, i).concat(images.slice(i + 1, images.length))
    setImages(filteredItems)
   }

  
   const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    console.log("handleChange", { name, value })
    setTempFields({
      ...tempFields,
      [name]: value,
    })
  }


  const handleChangeMerge = (e: { target: { name: any; value: any } }, i:number) => {
    const { value } = e.target;
    console.log("{ value }", { value })
    const mainArr = tempFields.attribute
    mainArr[i] = {
        name: value,
        value: []
    }
    setTempFields({
        ...tempFields,
        attribute : mainArr
    }) 
    setSelectedAttributes([...selectedAttributes, value])
     setSelectedColor(value) 
  }
  

   
   const submit = () => {  

    setShowForm(false)
    setVariations([...variations, {...tempFields, images}])
    setVariables([[]])
    setTempFields({attribute: []})
    setImages([])
    setAttributeRow([{
        name: "",
        value: "",
      }])

      formRef.current.map((res, i)=> {
        console.log("MMM", res)
        if (res.current) {
            res.current.value = ""
        }
           return  i
        })

        console.log("variations 0008", variations)
        console.log("variations 0009", [...variations, {...tempFields, images}])

        setVariationParient([...variations, {...tempFields, images}])
   }


   const handleRemoveVariation = (index: number) => {
   
    const values = [...variations];
    values.splice(index, 1);
    setVariations(values);
 
  };

  const [variableColor, setvariableColor] = useState<Color | undefined>()

    const handleChangeComplete = (color: any, i: number, name:string) => {
        console.log("color", color)
 
        setvariableColor(color)

        // eslint-disable-next-line no-unused-vars
        const getColorName = GetColorName(color.hex); 

        /// /////////////////
        const newValue = color.hex
       
        console.log("inputKeyDown 001", tempFields.attribute)
         const mainTemp = tempFields.attribute
         mainTemp[i] = { name: tempFields?.attribute[i].name, value: [newValue]}
    
        setTempFields({...tempFields,  attribute: mainTemp});
       
        const mainVariable = variables 
  
        mainVariable[i] = [newValue]
         
        setVariables([...mainVariable, []]);
         console.log("inputKeyDown 005", [...mainVariable, []])
 
    }


    const decideColorPicker = () => { 
            setShowColorPicker(!showColorPicker) 
    }


    return ( 
        <div className="col-12 createVariation"> 
            <div className="card mt-4 h-100 mb-4">
                <div className="card-body">  
                
                   {variations?.length > 0 && <div className="card mt-4 h-100 mb-4">
                        <div className="card-body">
                           <VariationList variation={variations} handleRemoveVariation={(index:number)=>handleRemoveVariation(index)}></VariationList>
                        </div>
                    </div>} 
                   

                    {showForm === false && <div className="row justify-content-center">
                        <div className="col-md-3">
                            <button className='btn btn-dark-outline border' type='button' onClick={ ()=>setShowForm(!showForm)}>
                                Add more variation
                            </button>
                        </div>
                    </div>}
                   {showForm===true && <React.Fragment>
                    <Variable  
                        variables={variables}
                        index={0}
                        removeTag={removeTag}
                        inputKeyDown={inputKeyDown}
                        handleChangeComplete={handleChangeComplete}
                        showColorPicker={showColorPicker}
                        variableColor={variableColor}
                        tagInput={tagInput}
                        decideColorPicker={decideColorPicker} 
                        formRef={formRef} 
                        handleChangeMerge={handleChangeMerge} 
                        selectedAttributes={selectedAttributes} 
                        handleRemoveFormRow={handleRemoveFormRow}
                        setShowColorPicker={setShowColorPicker}></Variable>
                                 

                    <div className="row h-100"> 

                        <div className="col-md-6">
                            <div className="form-input mt-3">
                                <label htmlFor="variable_title">Choose a suitable title for your variation</label>
                                <input type="text" id="variable_title" className="form-control" ref={formRef.current[1]} name="variable_title"  onChange = { handleChange }/>
                             </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-input mt-3">
                                <label htmlFor="var_price">Variable price</label>
                                <input type="text" id="var_price" className="form-control" ref={formRef.current[2]} name="variable_price"  onChange = { handleChange }/>
                             </div>
                        </div>

                        <div className="col-md-6">
                            <div className="upload-container mt-4">  
                                <div className='title'>Upload Image</div>
                                <div className='instruction'>At least 1 Image, must not exceed 5 Mb each</div>
                            </div>
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-3">  
                            <div className="upload-container mt-3">
                                <div className="card upload-card">
                                    <div className="card-body"> 
                                        <label htmlFor="containedButtonFileVariable"> 
                                            <UploadSvg></UploadSvg>
                                            <div className="text">
                                                Drag and drop, or browse your files
                                            </div> 
                                        <input
                                              type="file"
                                              accept="image/*"
                                              multiple
                                              id="containedButtonFileVariable"
                                              className='d-none'
                                              onChange={handleFileUpload}
                                            />
 
                                        </label>
                                        {loadingPhoto && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                    </div>
                                </div>
                            </div> 
                        </div> 
                        {images.map((res:any, index:Key) => {
                            return <div className="col-6 col-md-3" key={index}>
                                <div className='uploadedImgvariation card mb-1 p-0 mt-3'>
                                    <div className="card-body p-0 text-center">
                                        <button type="button" className='btn rounded-circle p-1' onClick={() => removeImage(Number(index))}> <i className='fa fa-times text-dark'></i></button>
                                        <div className="image-wrap">
                                            <img src={URL.createObjectURL(res)} className="w-75 px-3"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })} 
                    </div> 
                    <div className='row'>
                        <div className="col-md-6">  
                            <div className="upload-button">
                                <button className='btn btn-create' type="button" onClick={submit} >Save Variant</button>
                            </div>
                        </div>
                    </div> 

                    </React.Fragment>}
                </div>
            </div>
        </div> 
    )
}

export default CreateVariation
 
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import "./SellerPageTitle.scss"

interface propsTypes {
    title: string;
    parent: string | null;
    parentUrl: string | null;
    variant?: string | null;
}
const SellerPageTitle: FC<propsTypes> = ({ title, parent, parentUrl }) => {
    return (
        <div className='SellerPageTitle'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/seller/">Home</Link></li>
                    {parent && <li className="breadcrumb-item"><Link to={`/seller/${parentUrl}`}>{parent}</Link></li>}
                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                </ol>
            </nav>
            <div className='title ps-0 ms-0'>{title}</div>
        </div>
    )
}

export default SellerPageTitle

import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import orderService from "../../../../../../Services/orderService";
import "./BuyerPageDisputeForm.scss";
import { Logger } from '../../../../../../Helpers/Logger';

interface propsTypes {
    orderDetails:any
}

type formProp = {
    disputeType:string;
    disputeText:string;
}
const BuyerPageDisputeForm:FC<propsTypes> = ({orderDetails}) => {


  const { handleSubmit, register, formState: { errors } } = useForm<formProp>()  
  const [disputeRequest, setDisputeRequest] = useState<boolean>(false)

  const onSubmit = handleSubmit((data: any) => {
    processSubmit(data)
  })
  const processSubmit = async (data: any) => {
    setDisputeRequest(true)
    Logger("COMPLETED", data)
    await orderService.buyerOrderDispute(orderDetails?.orderUuid, data).then((res: { data: { data: any } }) => {
      Logger(res)
      setDisputeRequest(false)
      toast.success("Message sent successful", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
 
    }, (error:any) => {
      setDisputeRequest(false)
      Logger("orderdetails error", error.response)
      toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    })
  }

  
    return (
        <form className="buyer-dispute-form" onSubmit={onSubmit}>
            <div className="mb-3">
                <label htmlFor="edispute-title" className="form-label buyer-dispute-form-label">Dispute type</label>
                <select id='dispute-title' {...register("disputeType", { required: "this field cannot be empty" })} className="form-select form-select-lg ps-3 buyer-dispute-form-field-rounded">
                    <option>Product was damaged</option>
                    <option>Supplier came later than planned</option>
                    <option>Supplier did not deliver</option>
                </select>
                  {errors.disputeType && <div className="text-danger"> {errors.disputeType.message} </div>}
            </div>
            <div className="mb-3">
                <label htmlFor="dispute-details" className="form-label buyer-dispute-form-label">Dispute details</label>
                <textarea className="form-control" id="dispute-details" rows={3}  {...register("disputeText", { required: "this field cannot be empty" })}></textarea>
                {errors.disputeText && <div className="text-danger"> {errors.disputeText.message} </div>}
            </div>

            {!disputeRequest && <button className="btn btn-primary w-100 buyer-dispute-form-field-rounded mt-4">Raise dispute</button>}
            {disputeRequest && (<button type="submit" className="btn btn-primary w-100 buyer-dispute-form-field-rounded mt-4" disabled>
                    Sending...
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>)} 
        </form>
    )
}

export default BuyerPageDisputeForm;

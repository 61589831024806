import React, { useState } from 'react'
import { HeaderMeta, ImageSrc } from '../../../../../../Components' 
import prodImg from "../../../../../../Assets/Images/products/prod-img-1.png"
import mastercard from "../../../../../../Assets/Images/mastercard.png"
import Visa from "../../../../../../Assets/Images/Visa.png"
import "./EnterpriseCheckout.scss" 
import { useSelector } from 'react-redux';
import { useLiveQuery } from 'dexie-react-hooks';
import { Logger } from '../../../../../../Helpers/Logger'; 
import { DeliveryAddressType } from '../../../../../../Models/deliveryAddressType';
import { db } from '../../../../../../Config/DBConfig';
import EnterprisePaymentOption from '../../Components/EnterpriseCart/PaymentOption/EnterprisePaymentOption'
import { useForm } from 'react-hook-form'
import EnterprisePageTitle from '../../../../../../Components/Enterprise/EnterprisePageTitle/EnterprisePageTitle';
import toast from 'react-hot-toast'
import { ErrorHandler } from '../../../../../../Helpers/ErrorHandler'
import cartService from '../../../../../../Services/cartService'
import { Link, useNavigate } from 'react-router-dom';
import { savePaymentType } from '../../../../../../Helpers/savePaymentType'

const EnterpriseCheckout = () => { 
    const [loadingOrder, setLoadingOrder] = useState(false);
     
    const [totalCost, setTotalCost] = useState<number>(0); 
 
    const orderDetails:any[] = useLiveQuery<any>(async () => await db.orderDetails.orderBy('id').limit(1).sortBy("desc"))

    const navigate = useNavigate()

    const editSelectedAddress = useSelector((state: { editDeliveryAddress: DeliveryAddressType|number }) => state.editDeliveryAddress)
    Logger("editSelectedAddress", editSelectedAddress)
   
    useLiveQuery(
      async () => {
        const cartItems = await db.cart.toArray()
        let newCost = 0
        cartItems.length > 0 && cartItems.map((res:any) => {
          newCost += res.productDetails.unitPriceForBuyer * res.quantity;
          setTotalCost(newCost);
          return 1
        })
      })
  
      const { register, handleSubmit, watch } = useForm()

      const cartItems:any = useLiveQuery<any>(async () => await db.cart.toArray())
 
      const onSubmit = async (data:any) => {
        setLoadingOrder(true) 
        const arraySize = cartItems.length
        for (const [index, currentProductImage] of cartItems.entries()) { 
  
          await cartService.add({ productUuid: currentProductImage.productUuid, quantity: currentProductImage.quantity })
            .then((res:any) => {
              setLoadingOrder(false);
   
   
              if (index === arraySize - 1) {
                processOrder()
              }
            }, (error: any) => {
              setLoadingOrder(false);
              Logger("Add product error", error.response)
              ErrorHandler(error,
                () => {
                  toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
                })
            })
        }
      }


      const processOrder = async () => {

        setLoadingOrder(true)

        const newPickupLoc = cartItems.map((item:any) =>
        ({
         userUuid: item.productDetails.sellerPublicProfile.userUuid,
         orderReceiveType: "WARE_HOUSE",
         cartItems: [
           {
             productUuid: item.productUuid,
             quantity: item.quantity
           }
         ]
       }));
     console.log("orderDetails ================", orderDetails)
        await cartService.deliveryDetails( 
            {
              sellers: newPickupLoc,
              wareHouseUuid: orderDetails[0].wareHouseUuid
            } ,
          orderDetails[0].orderPaymentVariant
        ).then(async (res:any) => {
          setLoadingOrder(false)

          Logger("ORDER created", res)

          await savePaymentType('CORPORATE_BUY').then(async ()=>{
            savePaymentDetail(res)

            if (res) { 
              toast.success("Order succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
  
              if (orderDetails[0].orderPaymentVariant === "CARD") {
                savePaymentDetail(res) 
                toast.success("Order succesful, redirecting to payment terminal", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });  
                  
                await db.cart.clear().then(()=>{   
                   window.location.href = res.data.data.paymentProviderDetails.paymentProviderRedirectUrl; 
                })
  
              } else if (orderDetails[0].orderPaymentVariant === "WALLET" && res?.data.data.paymentTransactionStatus === "paid") {
                
                toast.success("Payment succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
               
                 setTimeout(()=>{ 
                    navigate(`/enterprise/payment-completed?action=/enterprise/order-management`, { replace: true })
                 }, 2000)
    
              } else if (orderDetails[0].orderPaymentVariant === "PAY_ON_DELIVERY" && res.data.status === true && res.data.data.paymentTransactionStatus === "unpaid") {
                toast.success("Pay on delivery Order succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
                
                setTimeout(()=>{ 
                  navigate(`/enterprise/order-management`, { replace: true })
               }, 3000)
  
              }
    
            }
          })
         
        }, (error:any) => {
          setLoadingOrder(false)

          ErrorHandler(error,
            () => {
              toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        })
      }

      

      const savePaymentDetail = async (data: any) => {
        await db.orderDetails.update(orderDetails[0]?.id, { paymentDetails: data.data.data }).then(function (updated) {
          if (updated) {
            toast.success(`Order detail saved`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            return true
          } else {
            toast.error(`Order detail was not updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            return false
          }
        });
      }

    
    return (
        <div className='enterpriseCheckout'>
            <div className="container">
             <HeaderMeta title={'Checkout'}></HeaderMeta>
             <EnterprisePageTitle  title={'Home'}  parent={""} parentUrl={""}  showTitle={false}></EnterprisePageTitle>
            
                <div className="row">
                <div className="col-md-8 payment-detail">
                    <h1>Checkout</h1> 

                    <div className="card mt-5">
                      <div className="card-header bg-white fw-bolder border-0">  
                          Payment options 
                      </div>
                      <div className="card-body"> 
                        <EnterprisePaymentOption totalCost={totalCost} module="enterprise"></EnterprisePaymentOption>
                       
                    </div>
                   </div>
                </div>

                <div className="col-md-4"> 
                    <hr></hr>
                    <div className="cart-summary">
                        <div className="card border-0">
                        <div className="card-header border-0">
                            Summary
                        </div>
                        <div className="card-body">
                            <div className="row">
                            <div className="col-6 cart-el">
                                Subtotal
                            </div>
                            <div className="col-6 cart-el">
                                ₦{totalCost}
                            </div> 
                            <div className="col-12 text-left">
                                <hr/>
                            </div>
                            <div className="col-6 ">
                                <div className="cart-total">Total</div>
                            </div>
                            <div className="col-6">
                                <div className="cart-total"> ₦{totalCost}</div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="payment-option">
                      Acceptable payment methods
                      <div className="">
                          <img src={mastercard} className=""/>
                          <img src={Visa} className=""/>
                      </div>
                    </div>

                    <div className="cart-products">
                      <div className="your-order">
                          Your order
                      </div>
                      <div className="row mt-3">
                          <div className="col-2">
                              <ImageSrc src={prodImg} alt={''} title={undefined} width={'w-100'}></ImageSrc>
                          </div>
                          <div className="col-2">
                              <ImageSrc src={prodImg} alt={''} title={undefined} width={'w-100'}></ImageSrc>
                          </div>
                      </div>
                    </div>
                    <hr/>  
                    <div className='paymentOption'>
                      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                          <label htmlFor="terms" className="d-flex align-items-center">
                              <input type="checkbox" id="terms" {...register("terms", { required: "this field is required" })} className="me-2"/> <span> I accept the <Link to="/terms-and-conditions" className='text-warn'>Terms & Conditions</Link> and <Link to="/privacy-policy" className='text-warn'>Privacy Policy</Link></span>
                          </label> 
                          {!loadingOrder && <button className="btn pay-btn-active" disabled={!watch("terms")}>Pay ₦{totalCost}</button>}
                          {loadingOrder && <button className={"btn pay-btn-active"} disabled={!watch("terms")}>Pay ₦{totalCost} <span className='spinner-border spinner-border-sm'></span></button>}
                      </form>
                    </div>
                </div>
                </div>
                <hr/>
            </div> 
        </div>
    )
}

export default EnterpriseCheckout

import React from 'react'
import NavMenu from '../NavMenu/NavMenu'
import SubNavMenu from '../SubNavMenu/SubNavMenu'
import "./BuyerHeader.scss"

const BuyerHeader = () => {
  return (
        <div className='buyer-header d-none d-lg-block'>
          <NavMenu></NavMenu>
          <SubNavMenu></SubNavMenu>
        </div>
  )
}

export default BuyerHeader

import React from 'react'
import { Outlet } from "react-router-dom";
import "./EnterpriseSettings.scss"

const EnterpriseSettings = () => {
    return (
        <div className='enterpriseSettings'>
            <div className="container">
                <div className="my-account-outlet">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default EnterpriseSettings

 
import { Fragment, useEffect, useState, SetStateAction } from 'react'
import toast, { Renderable, Toast, Toaster, ValueFunction } from 'react-hot-toast'
import { useAuth } from '../../../../../../Components/AuthProvider/AuthProvider'
 
import productService from '../../../../../../Services/productService'
 
import "./Products.scss"
import { HorizontalLoader, ImageSrc, Pagination } from '../../../../../../Components'
import { TimeConverter } from '../../../../../../Helpers/TimeConverter'
import { Link } from 'react-router-dom';
 


const SellerProducts: any = () => {
  const { user } = useAuth() 
  const [productList, setProductList] = useState([])  
  const [pageNumber, setPageNumber] = useState(1)
  const [loadingReqest, setLoadingrRequest] = useState(false)
 
  const [sortOrder,] = useState("DESC")
  // eslint-disable-next-line no-unused-vars
  const [paginationData, setPaginationData] = useState({
    pageNumber: "1",
    pageSize: 20,
    total: 1,
  })
 
  const getProductList = (pageNumber: number, sortOrder: string) => {
    setLoadingrRequest(true)
    productService.getSellerProduct(pageNumber, sortOrder, user.uuid)
      .then((res: any) => {
        setProductList(res.data.data.dataset)
        setPaginationData({
          pageNumber: res.data.data.pageNumber,
          pageSize: res.data.data.pageSize,
          total: res.data.data.total
        }) 
        setLoadingrRequest(false)
        console.warn("All product", res.data.data.dataset)
      }, (error: any) => {
        setLoadingrRequest(false)
        console.log("All product error", error.response)
        console.log("All product error", error.message)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    getProductList(pageNumber, sortOrder)
    abortController.abort()
  }, [pageNumber, sortOrder])

  const deleteProd = async (productUuid:string) => { 
    const deleteId = toast.loading('Deleting product...');
    await productService.deleteProduct(productUuid)
      .then((res) => {
        console.log(res)
        toast.dismiss(deleteId);
        toast.success("Product deleted successfully", { duration: 25000, className: 'bg-white text-dark', position: "top-right" });
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }, (error) => {
        console.log(error.response.data.error)
        toast.error(error.response.data.error, { duration: 25000, className: 'bg-white text-dark', position: "top-right" });
      })
  }


  const deactivate = async (productUuid:string)=> {
    const deleteId = toast.loading('Deactivating product...');
    await productService.deactivateProduct(productUuid)
      .then((res: any) => {
        console.log(res)
        toast.dismiss(deleteId);
        toast.success("Product deactivated successfully", { duration: 25000, className: 'bg-white text-dark', position: "top-right" });
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }, (error: { response: { data: { error: Renderable | ValueFunction<Renderable, Toast> } } }) => {
        console.log(error.response.data.error)
        toast.error(error.response.data.error, { duration: 25000, className: 'bg-white text-dark', position: "top-right" });
      })
  }

  const activateProduct = async (productUuid:string)=> {
    const deleteId = toast.loading('Activating product...');
    await productService.deactivateProduct(productUuid)
      .then((res: any) => {
        console.log(res)
        toast.dismiss(deleteId);
        toast.success("Product Activated successfully", { duration: 25000, className: 'bg-white text-dark', position: "top-right" });
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }, (error: { response: { data: { error: Renderable | ValueFunction<Renderable, Toast> } } }) => {
        console.log(error.response.data.error)
        toast.error(error.response.data.error, { duration: 25000, className: 'bg-white text-dark', position: "top-right" });
      })
  }

  //
 
  return (
    <div className='sellerProducts'>
        <div className="container">  

            <div className="seller-product-filter mt-5">
                <div className="row mt-4 h-100"> 
                    <div className="col-sm-3 col-xl-5 my-auto">
                      <div className="product-count mt-2">{productList.length > 0 && productList.length} Products</div> 
                    </div>
                    <div className="col-sm-3 col-md-3">
                      <div className="seach-field mt-2 d-none">
                          <input type="text" className='search-input form-control' placeholder='Search product'/>
                          <svg className="search-icon" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.6752 11.5657C9.3418 13.8991 5.55859 13.8991 3.22518 11.5657C0.891767 9.23226 0.891767 5.44905 3.22518 3.11564C5.55859 0.782229 9.3418 0.782229 11.6752 3.11564C14.0086 5.44905 14.0086 9.23226 11.6752 11.5657ZM11.6752 11.5657L16.4128 16.3033" stroke="#868484" strokeWidth="1.5" strokeLinecap="round"/>
                          </svg>  
                      </div>
                    </div>
                    <div className="col-sm-3  col-lg-2"> 
                        <div className="filter-product mt-2 d-none">
                            <select className='btn-filter' name="" id="">
                                <option>All Product</option>
                            </select>

                            <div className='filter-icon'> 
                                Filter: 
                            </div>  
                        </div> 
                    </div> 
                    <div className="col-sm-3 col-lg-2"> 
                        <div className="add-new-product-wraper mt-2">
                          <Link to="/seller/product/create">
                              <button className='btn'>Add Product</button>
                          </Link>
                        </div> 
                    </div> 
                </div> 
            </div>

            <div className="row"> 
            {productList.length > 0?  <Fragment> 

                <div className="seller-table">
                   <div className="table-responsive">
                        <table className="table rounded"> 
                            <thead className='rounded'>
                                <tr className='d-none d-md-table-row'> 
                                <th scope="col" className='ps-4'>Product</th>
                                <th scope="col" className='text-center'>Price</th> 
                                <th scope="col" className='text-center'>Category</th>
                                <th scope="col" className='text-center'>State</th>
                                {/* <th scope="col" className='text-center'>Active</th> */}
                                <th scope="col" className='text-center'>Date</th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {productList.length > 0? productList.map((res: { price:number, isActive:boolean, productUuid:string, images:any, locationState:string; category:any; currencySymbol:string, productName: string, createdAt:string; avalailableInStock:number, wareHouseDetail:{totalQuantity:number}}, index: number)=><Fragment key={index}> 
                                <tr className='d-none d-md-table-row'> 
                                    <td className='ps-4'>
                                        <ImageSrc src={res.images[0]?.url || ""} alt={res.productName} title={res.productName} width={'product-image'}></ImageSrc>
                                        {res.productName}
                                    </td>
                                    <td className='text-center price-text'>{res.currencySymbol}{res.price}</td> 
                                    <td className='text-center'>{res.category.name}</td> 
                                    <td className='text-center'>{res.locationState}</td> 
                                    {/* <td className='text-center'>{res.isActive? <span className='text-success fw-bolder'>Active</span>: <span className='text-danger fw-bolder'>Inactive</span>}</td>  */}
                                    <td className='text-center'>{ TimeConverter(res.createdAt)}</td> 
                                    <td className='text-center'>
                                        <div className="btn-group dropdown">
                                            <button type="button" className="btn border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className='d-icon d-more-vertical'></i>
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow">
                                                <li>
                                                  <Link className="dropdown-item" to={`/seller/product/edit/${res.productUuid}`}> 
                                                    Edit
                                                    </Link>
                                                </li>
                                                <li>
                                                  <Link className="dropdown-item" to={`/seller/product/details/${res.productUuid}`}> 
                                                    View Details
                                                    </Link>
                                                </li> 
                                                {res.isActive === true && <li className="d-none">
                                                  <Link className="dropdown-item text-danger fw-bolder" to={'#'} onClick={()=>deactivate(res.productUuid)}> 
                                                    Deactivate
                                                  </Link>
                                                </li> }
                                                {res.isActive === false && <li className="d-none">
                                                  <Link className="dropdown-item text-success fw-bolder" to={'#'} onClick={()=>activateProduct(res.productUuid)}> 
                                                    Activate
                                                  </Link>
                                                </li> }
                                                
                                                <li>
                                                  <Link className="dropdown-item" to={''} onClick={()=>deleteProd(res.productUuid)}> 
                                                    Delete
                                                  </Link>
                                                </li> 
                                            </ul>
                                          </div>
                                      </td> 
                                   
                                </tr>
                                <tr className='d-md-none'>
                                    <td>
                                       <ImageSrc src={res.images[0]?.url || ""} alt={res.productName} title={res.productName} width={'product-image'}></ImageSrc>
                                    </td>
                                    <td colSpan={4}>
                                        <div className="row"> 
                                            <div className='col-12 profile-title'>{res.productName}</div>
                                            <div className='col-12 profile-detail'>Price: {res.price}</div> 
                                            <div className='col-12 profile-detail'>Sold: </div>
                                        </div>
                                    </td>  
                                </tr> 
                                </Fragment>)
                                : <tr>
                                    <td  colSpan={7} className="text-center">No item found</td>
                                 </tr>}

                                {loadingReqest? <tr>
                                    <td colSpan={7} className="text-center">loading...</td>
                                </tr>: ""}
                                <td colSpan={7} className="text-center"> 
                                  {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                                      {
                                        paginationData.total && <Pagination
                                        className="pagination-bar ps-0 "
                                        currentPage={Number(paginationData.pageNumber)}
                                        totalCount={paginationData.total}
                                        pageSize={paginationData.pageSize}
                                        onPageChange={(page: SetStateAction<number>) => setPageNumber(page)}/>
                                      }
                                  </div>}
                                </td>
                            </tbody> 
                        </table>
                    </div> 
                </div>
                </Fragment>:""}

               
                
                
                <div className='text-center'>
                {loadingReqest && <HorizontalLoader></HorizontalLoader>}
                </div>
            </div>
            <Toaster></Toaster>
        </div>
    </div>
  )
}

export default SellerProducts
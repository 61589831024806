import {FC} from "react";
import "./EnterpriseSettingsNavCard.scss";
import  {Link} from "react-router-dom";

interface propsType {
    title:string;
    icon:any;
    description:string;
    link:string;
}

const EnterpriseSettingsNavCard: FC<propsType> = ({title, icon, description,link}) => {
    return (
        <li className="enterpriseSettings-nav-card">
            <Link className="nav-link" to={link}>
                <div className="icon-container">
                    {icon}
                </div>
                <div className="link-details">
                    <p className="title">{title}</p>
                    <p className="description">{description}</p>
                </div>
            </Link>
        </li>
    )
}

export default EnterpriseSettingsNavCard;
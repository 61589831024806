import React from 'react'
import { HeroSection } from '../../Components'
import "./Builder360.scss"
import sucessGuy from "../../../../Assets/Images/sucess-guy.png"
import warehouse from "../../../../Assets/Images/warehouse.png"
import bulkorder from "../../../../Assets/Images/bulkorder.png"
import hire from "../../../../Assets/Images/hire.png"
import hirelogo from "../../../../Assets/Images/hirelly-logo.png"
import podcast from "../../../../Assets/Images/podcast.png"
import report from "../../../../Assets/Images/report.png"
import star from "../../../../Assets/Images/star.png"
import NewsLetter from '../../Components/Home/NewsLetter/NewsLetter'
import { Navigation, Pagination } from 'swiper'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider'
import { BasicRBAC } from '../../../../Helpers/BasicRBAC';

const Builder360 = () => { 
  
    const pagination = {
      clickable: true,
      renderBullet: function (index:any, className:any) {
        // eslint-disable-next-line no-useless-escape
        return '<span class=\"' + className + '\">' + (index + 1) + '</span>';
      }
    }

    const { user } = useAuth()
    const access = BasicRBAC
  
    
    return (
        <div className='builder360'> 
            <HeroSection></HeroSection>

            <div className="mid-heading mid-heading-mb text-center mb-4">
                Get more with Builder 360™
            </div>

            <div className="container">
                <div className="row mt-5 h-100">
                    <div className="col-md-6 my-auto"> 
                        <div className="mid-heading"> 
                         PLP (Product Lease Program)
                        </div>
                        <div className="row-content mt-4">Unlock CinderBuild’s credit facility. Purchase building <br></br>materials now and pay later.</div>
                    </div>

                    <div className="col-md-6 text-end">
                        <img src={sucessGuy} alt="" className='section-img'/>
                    </div>
                </div>

                <div className="row mt-3 h-100">
                    <div className="col-md-6 text-start d-none d-md-block" data-aos="fade-right"> 
                       <img src={warehouse} alt="" className='section-img'/>
                    </div>

                    <div className="col-md-6 my-auto" data-aos="fade-up-left">
                        <div className="ms-md-5 ps-md-2">
                            <div className="mid-heading"> 
                            Cloud Warehouse
                            </div>
                            <div className="row-content mt-4">Beat inflation by storing your products virtually and draw down as needed. Get access to real-time inventory tracking, order, and logistics management.</div>
                       </div>
                   </div>

                   <div className="col-md-6 text-start d-md-none" data-aos="fade-right"> 
                       <img src={warehouse} alt="" className='section-img'/>
                    </div>
                </div>


                <div className="row mt-3 h-100">
                    <div className="col-md-6 my-auto" data-aos="fade-up-right"> 
                        <div className="mid-heading"> 
                         CB-Branding
                        </div>
                        <div className="row-content mt-4">With over 10000+ daily website visits, you can grow your business and visibility by leveraging our customer base through billboards, commercial campaigns, and website ads.</div>
                   
                    </div>

                    <div className="col-md-6 text-end" data-aos="fade-up-left">
                       <img src={bulkorder} alt="" className='section-img'/>
                    </div>
                </div>

                <div className="row mt-3 h-100">
                    <div className="col-md-6 text-start d-none d-md-block" data-aos="fade-up-right"> 
                       <img src={hire} alt="" className='section-img' />
                    </div>

                   
                    <div className="col-md-6 my-auto" data-aos="fade-up-left"> 
                        <div className="ms-md-5 ps-md-2">
                            <div className="mid-heading"> 
                            <img src={hirelogo} className="w-25"/>
                            </div>
                            <div className="row-content mt-4">Get connected to reliable and high-quality professionals that are needed for your construction projects from skilled builders to architects.</div>
                        </div>
                    </div>

                    <div className="col-md-6 text-start d-md-none" data-aos="fade-up-right"> 
                       <img src={hire} alt="" className='section-img' />
                    </div>
                </div>


                <div className="row mt-3 h-100"> 
                   <div className="col-md-6 text-end d-none d-sm-block d-md-none" data-aos="fade-up-left">
                       <img src={podcast} alt="" className='section-img'/>
                    </div>

                    <div className="col-md-6 my-auto" data-aos="fade-up-right">
                        <div className="mid-heading"> 
                        CB-Cast
                        </div>
                        <div className="row-content mt-4">Looking to grow your sales and brand awareness, our Podcast session is a great tool for establishing relationships with your customers and showcasing your business.</div> 
                    </div>
                    <div className="col-md-6 text-end d-sm-none d-md-block" data-aos="fade-up-left">
                       <img src={podcast} alt="" className='section-img'/>
                    </div>
                </div>
 

                <div className="row mt-3 h-100">
                    <div className="col-md-6 text-start d-none d-md-block" data-aos="fade-up-right"> 
                       <img src={ report} alt="" className='section-img' />
                    </div>
                    <div className="col-md-6 my-auto" data-aos="fade-up-left">
                        <div className="ms-md-5 ps-md-2">
                            <div className="mid-heading"> 
                            CB-Report
                            </div>
                            <div className="row-content mt-4">Contains up-to-date information on industry trends and opportunities in the construction industry.</div>
                   
                       </div>
                    </div>

                    <div className="col-md-6 text-start d-md-none" data-aos="fade-up-right"> 
                       <img src={ report} alt="" className='section-img' />
                    </div>
                </div>




                <div className='showcase'>
                    <h5 className="showcase-title text-center">Product Access Criteria</h5>
                        <Swiper
                            slidesPerView={"auto"}
                            spaceBetween={30}
                            pagination={pagination} 
                            className="mySwiper" 
                            modules={[Pagination, Navigation]} 
                            loop={true}
                            loopFillGroupWithBlank={true} 
                        >
                            <SwiperSlide>
                                   <div className="card product-card w-100">
                                            <div className="card-header">
                                                <div className="icon-wraper">
                                                    <img src={star}></img> 
                                                </div>
                                            </div>
                                            <div className="card-body p-0 border-0"> 

                                                <div className="card-inner-header text-center">
                                                    <div>
                                                        <div>Cloud Warehouse</div>
                                                        <div>CB-Report</div>
                                                </div>
                                                </div>

                                                <ul className="list-group">
                                                    <li className="list-group-item">
                                                        <div>
                                                            <div className='item-title'>Spending Requirement</div>
                                                            <div className='item-value'>≥₦5,000,000</div>
                                                        </div>
                                                    </li> 
                                                </ul>
                                            </div> 
                                        </div> 
                                        </SwiperSlide>

                                        <SwiperSlide>
                                         <div className="card product-card w-100">
                                            <div className="card-header">
                                            <div className="icon-wraper">
                                                <img src={star}></img> 
                                                <img src={star} className="ms-2"></img> 
                                            </div>
                                            </div>
                                            <div className="card-body p-0 border-0"> 

                                                <div className="card-inner-header text-center">
                                                    <div> 
                                                        <div>HIRELLY</div>
                                                </div>
                                                </div>

                                                <ul className="list-group">
                                                    <li className="list-group-item"> 
                                                        <div>
                                                            <div className='item-title'>Spending Requirement</div>
                                                            <div className='item-value'>≥₦5,000,000</div>
                                                        </div>
                                                    </li> 
                                                    <li className="list-group-item"> 
                                                        <div>
                                                            <div className='item-title text-center'>Minimum Monthly spend</div>
                                                            <div className='item-value text-center'>₦3,000,000</div>
                                                        </div>
                                                    </li>  
                                                </ul>
                                            </div>  
                                        </div>  
                                    </SwiperSlide>

                                  <SwiperSlide>
                                        <div className="card product-card w-100">
                                            <div className="card-header">
                                            <div className="icon-wraper">
                                                <img src={star}></img> 
                                                <img src={star} className="ms-2"></img> 
                                                <img src={star} className="ms-2"></img> 
                                            </div>
                                            </div>
                                            <div className="card-body p-0 border-0"> 

                                                <div className="card-inner-header text-center">
                                                    <div> 
                                                        <div className='text-uppercase'>CB-Branding</div>
                                                </div>
                                                </div>

                                                <ul className="list-group">
                                                    <li className="list-group-item"> 
                                                        <div>
                                                            <div className='item-title'>Spending Requirement</div>
                                                            <div className='item-value'>≥ ₦35,000,000</div>
                                                        </div>
                                                    </li> 
                                                    <li className="list-group-item"> 
                                                        <div>
                                                            <div className='item-title text-center'>Minimum Monthly spend</div>
                                                            <div className='item-value text-center'>₦10,000,000</div>
                                                        </div>
                                                    </li>  
                                                </ul>
                                            </div> 
                                        </div> 
                                        </SwiperSlide>
                                 
                                       <SwiperSlide>
                                         <div className="card product-card w-100">
                                            <div className="card-header">
                                            <div className="icon-wraper">
                                                <img src={star}></img> 
                                                <img src={star} className="ms-2"></img> 
                                                <img src={star} className="ms-2"></img> 
                                                <img src={star} className="ms-2"></img> 
                                            </div>
                                            </div>
                                            <div className="card-body p-0 border-0"> 

                                                <div className="card-inner-header text-center">
                                                    <div> 
                                                        <div className='text-uppercase'>CB-CAST</div>
                                                </div>
                                                </div>

                                                <ul className="list-group">
                                                    <li className="list-group-item"> 
                                                        <div>
                                                            <div className='item-title'>Spending Requirement</div>
                                                            <div className='item-value'>≥ ₦40,000,000</div>
                                                        </div>
                                                    </li> 
                                                    <li className="list-group-item"> 
                                                        <div>
                                                            <div className='item-title text-center'>Minimum Monthly spend</div>
                                                            <div className='item-value text-center'>₦20,000,00</div>
                                                        </div>
                                                    </li>  
                                                </ul>
                                            </div> 
                                        </div>
                                    </SwiperSlide>

                                   <SwiperSlide>
                                         <div className="card product-card w-100">
                                            <div className="card-header">
                                            <div className="icon-wraper">
                                                <img src={star}></img> 
                                                <img src={star} className="ms-2"></img> 
                                                <img src={star} className="ms-2"></img> 
                                                <img src={star} className="ms-2"></img> 
                                                <img src={star} className="ms-2"></img> 
                                            </div>
                                            </div>
                                            <div className="card-body p-0 border-0"> 

                                                <div className="card-inner-header text-center">
                                                    <div> 
                                                        <div className='text-uppercase'>PLP</div>
                                                </div>
                                                </div>

                                                <ul className="list-group">
                                                    <li className="list-group-item"> 
                                                        <div>
                                                            <div className='item-title'>Spending Requirement</div>
                                                            <div className='item-value'>≥ ₦60,000,000</div>
                                                        </div>
                                                    </li> 
                                                    <li className="list-group-item"> 
                                                        <div>
                                                            <div className='item-title text-center'>Minimum Monthly spend</div>
                                                            <div className='item-value text-center'>₦25,000,000</div>
                                                        </div>
                                                    </li>  
                                                </ul>
                                            </div>  
                                    </div>  
                            </SwiperSlide> 
                        </Swiper>

                        <div className="text-center">
                            {user && access("enterprice-get-started") === true? 
                            <Link to="/enterprise/products">
                                <button className='btn btn-primary btn-360-getstarted'> Shop Now </button>
                            </Link>
                            :<Link to="/enterprise-setup/">
                                <button className='btn btn-primary btn-360-getstarted'> Get Started </button>
                            </Link>} 
                        </div>
                </div>


 
            </div>
            <div className="bg-newsletter">
               <NewsLetter></NewsLetter> 
            </div>
        </div> 
    )
}

export default Builder360 
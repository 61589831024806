import React, { useState } from 'react'
import "./NewPassword.scss"
import whiteLogo from "../../../../Assets/Images/logo-white.png"
import Logo from "../../../../Assets/Images/cinderBuild-logo.png"
import cinderBg from "../../../../Assets/Images/cinderBg.png"
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import authService from '../../../../Services/authService';
import { HeaderMeta } from '../../../../Components'
import { Logger } from '../../../../Helpers/Logger';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'; 
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider'

interface formTypes{
  newPassword:string;
  reNewPassword:string;
}
const NewPassword = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showPassword2, setShowPassword2] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)
  const [submit, setSubmit] = useState(false)
 
  const { user } = useAuth();
  const history = useNavigate();

  const formSchema = yup.object().shape({
    newPassword: yup.string()
      .required("Password is required")
      .min(6, "Password length should be at least 6 characters")
      .max(12, "Password cannot exceed more than 12 characters"),
    reNewPassword: yup.string()
      .required("Confirm Password is required")
      .min(6, "Password length should be at least 6 characters")
      .max(12, "Password cannot exceed more than 12 characters")
      .oneOf([yup.ref("newPassword")], "Passwords do not match")
  });

  const { register, formState: { errors }, handleSubmit } = useForm<formTypes>({
    mode: "onTouched",
    resolver: yupResolver(formSchema)
  });

  const onSubmit = handleSubmit((data:any) => processForm(data))
  const processForm = async (data:any) => {
    setSubmit(true)

    await authService.changePassword({ newPassword: data.newPassword }).then(() => {
 
      setSubmit(false)

      if (user && user.isSeller === true) {
        return history("/seller", { replace: true })
      } else {
        return history("/", { replace: true })
      }

    }, (error:any) => {
      Logger(error.response)
      setSubmit(false)
    })
  }

  return (
    <div className="newpassword">
        <HeaderMeta title="Login"></HeaderMeta>
        <div className="row h-100">
            <div className="col-md-6 d-none d-md-block">
                <div className="login-right">
                    <div className="container">
                        <div className="login-header">
                            <div className="row">
                                <div className="col-md-2 text-end">
                                    <div className="login-back">
                                    <a href=""><span className="d-icon d-arrowhead-left shadow"></span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="login-logo">
                                        <img src={whiteLogo} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="login-text">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <h2>Hey! Log in to your CinderBuild account</h2>
                                    <div className="desc">
                                        Log In to account with your phone number and password.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={cinderBg} alt="" className='abstImg'/>
                </div>
            </div>
            <div className="col-md-6 my-auto">
                <div className="login-header d-md-none">
                    <div className="row h-100">
                        <div className="col-2">
                            <div className="login-back">
                            <a href=""><span className="d-icon d-arrowhead-left"></span></a>
                            </div>
                        </div>
                        <div className="col-8 text-start ps-0 my-auto">
                            <div className="login-logo">
                                <img src={Logo} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="login-form" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <div className="row justify-content-center">
                    <div className="col-md-8">

                        <h1 className='mb-0'>New Password</h1>
                        <small>Your new password must be different from previous used passwords</small>
                        <form onSubmit={onSubmit}>
                            <div className="form-group mt-4 mb-3 password-container">
                                <input
                                  className="form-control w-100"
                                  id="password"
                                  type={!showPassword2 ? "password" : "text"}
                                  placeholder="Password"
                                  {...register("newPassword", { required: "field cannot be empty" })}
                            />
                            {!showPassword2 && <button type="button" className="btn p-0 field-icon" onClick={() => setShowPassword2(true)}><i className="fas fa-eye" onClick={() => setShowPassword2(true)}></i></button>}
                            {showPassword2 && <button type="button" className="btn p-0 field-icon" onClick={() => setShowPassword2(false)}><i className="fas fa-eye-slash" aria-hidden="true" onClick={() => setShowPassword(false)}></i></button>}

                                <div className="err text-danger">{errors.newPassword && errors.newPassword.message}</div>
                            </div>

                            <div className="form-group password-container">
                                <input
                                      className="form-control"
                                      id="repassword-field"
                                      type={!showPassword ? "password" : "text"}
                                      placeholder="Retype Password"
                                      {...register("reNewPassword", {
                                        required: "field cannot be empty"
                                      })}
                                />

                                {!showPassword && <button className="btn p-0 field-icon" onClick={() => setShowPassword(true)}><i className="fas fa-eye"></i></button>}
                                {showPassword && <button className="btn p-0 field-icon" onClick={() => setShowPassword(false)}><i className="fas fa-eye-slash" aria-hidden="true"></i></button>}
                              <div className="err text-danger">{errors.reNewPassword && errors.reNewPassword.message}</div>
                           </div>

                            {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                            <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                                <div className="text-danger">
                                   {error}
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(null)}></button>
                            </div>}
                            <div className="form-group mt-4">
                                {!submit && (<button type="submit" className="btn btn-login">Reset Password</button>)}
                                {submit && (<button type="submit" className="btn btn-login" disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>)}
                            </div>
                            <div className="signup mt-3">
                                Remember your password? <Link to="/login" className="text-primary">Back to Login</Link>
                            </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewPassword

import React, { useEffect } from 'react'
import { HomeAdsSection,  HomeCategory, HomeNewsletter, HomeShopByBrand, HomeSlider, HomeTestimony } from '../../Components'
import ReactGA from 'react-ga';
import HeaderMeta from '../../../../Components/HeaderMeta/HeaderMeta';
 
const Home = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  return (
        <div className="home">
          <HeaderMeta title="Home"></HeaderMeta> 
           <HomeSlider></HomeSlider>
           {/* <HomeBestDeals></HomeBestDeals> */}
           <HomeCategory></HomeCategory>
           <HomeAdsSection></HomeAdsSection>
           {/* <HomeBestSeller></HomeBestSeller> */}
           <HomeShopByBrand></HomeShopByBrand>
           <HomeTestimony></HomeTestimony>
           <HomeNewsletter></HomeNewsletter>
        </div>
  )
}

export default Home

import axios from 'axios'
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const uploadSellerDocumentService = {
  upload (imgType, Uuid, formData, onUploadProgress) {
    return axios.put(`/upload?fileUploadCategory=${imgType}&entityUuid=${Uuid}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }

}

export default uploadSellerDocumentService;

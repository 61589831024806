import React from 'react'
 
import "./AboutUs.scss" 
import visionleft from "../../../../Assets/Images/vision-left.png"
import GetStarted from '../../Components/About/GetStarted/GetStarted'
import PageHeader from '../../Components/PageHeader/PageHeader'
import { FooterLarge, Header } from '../../../../Layout'

const AboutUs = () => {
  return (
    <React.Fragment>
    <Header></Header>
    <div className="about">
      <div className="about-inner">
        <PageHeader title="About" pageTagline="Africa’s Building Materials Marketplace" slider={true}/>
        <div className="about-head-tab d-none">
          <div className="container d-flex justify-content-center">
              <div className="about-head-tab-inner w-100">
                 <div className="container-fluid pt-2">
                  <div className="row">
                    <div className="col-4">
                      <button className="btn tab-btn">About Us</button>
                    </div>
                    <div className="col-4">
                      <button className="btn" >Our Teams</button>
                    </div>
                    <div className="col-4">
                      <button className="btn">Partners</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container about-content text-center">
          <h2>Who We Are</h2>
          <div className="p-2 p-md-2">
              <p>We are the number one market place for Building and Construction materials in Africa. we partner with more than 80% of the leading product marketers, to deliver exceptional buying experiences through our digital platform.</p>

              <p>In each of these countries, we partner with more than 80% of the leading buyers or off-takers, to deliver exceptional trading experiences through our digital platform.</p>

              <p>Our teams are formed from world-class professionals, some of whom are top-tier strategy consultants, sourcing experts, and supply chain managers, who collectively strive to build additional efficiencies to the platform, with an ultimate aim of enhancing the CinderBuild Seller benefits and experience.</p>
          </div>
        </div>
      </section>

      <section className='d-none'>
         <div className="vision-container">
          <div className="row h-100">
              <div className="col-md-6 my-auto p-0">
                <div className="ourVision-left">
                  <img src={visionleft} className="w-100 h-100"/>
                </div>
              </div>
              <div className="col-md-6 my-auto p-0 ourVision-right-cont">
                <div className="ourVision-right">
                <h2>Our Vision</h2>
                <div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit gravida risus tellus dignissim lectus. Risus nisi, lectus fermentum luctus at nec.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit gravida risus tellus dignissim lectus. Risus nisi, lectus fermentum luctus at nec.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit gravida risus tellus dignissim lectus. Risus nisi, lectus fermentum luctus at nec.
                </div>
                </div>
              </div>
            </div>
          </div>
      </section>

      <section className='d-none'>
         <div className="core-values-container">
          <div className="container">
          <div className="core-values-desc text-center my-5">
            <h2>Our Core Values</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit gravida risus tellus dignissim lectus. Risus nisi, lectus fermentum luctusrmentum luctus at nec.</p>
          </div>
            <div className="row h-100">
                <div className="col-md-9">
                  <div className="row h-100">
                      <div className="col-md-7">
                         <div className="card card-small bg-1 mb-2">
                            <div className="card-body py-5">
                              <h5>Trust</h5>
                              <p>Lorem ipsum dolor sit amet, consectetur </p>
                            </div>
                          </div>
                      </div>
                      <div className="col-md-5">
                         <div className="card card-small bg-2 mb-2">
                            <div className="card-body py-5">
                              <h5>Security</h5>
                              <p>Lorem ipsum dolor sit amet, consectetur </p>
                            </div>
                          </div>
                      </div>
                      <div className="col-md-7">
                         <div className="card card-small bg-3 mt-2">
                            <div className="card-body py-5">
                              <h5>Trust</h5>
                              <p>Lorem ipsum dolor sit amet, consectetur </p>
                            </div>
                          </div>
                      </div>
                      <div className="col-md-5">
                         <div className="card card-small bg-4 mt-2">
                            <div className="card-body py-5">
                              <h5>Trust</h5>
                              <p>Lorem ipsum dolor sit amet, consectetur </p>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>

                <div className="col-md-3">
                    <div className="card card-hory w-100 bg-5">
                      <div className="card-body py-5">
                       <h5>Strength</h5>
                       <p>Lorem ipsum dolor sit amet, consectetur</p>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <GetStarted/>
      </section>
    </div>
    <FooterLarge></FooterLarge>
    </React.Fragment>
  )
}

export default AboutUs


import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../Helpers/ErrorHandler";  
import wareHouseService from '../../../Services/wareHouseService';
import { useAuth } from "../../../Components/AuthProvider/AuthProvider";
 
export type TApiResponse = {
  isSuccess: boolean;
  data:any[];
  isLoading :boolean;
};
 
export const useAllWarehouse = () => {
  return useQuery(["warehouse_list"], async () =>  await wareHouseService.index(), {

    enabled: true,
    retry: 1,
    staleTime: Infinity,
    cacheTime: Infinity,
    select: (data:{ data: { data: {
      [x: string]: any; data:any[]
}  }}) => { 
      return data.data.data
    },
    onSuccess (data) {
      // toast.success("success", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    },
    onError (error: any) {
      if (Array.isArray((error as any).response.data.error)) {
        (error as any).data.error.forEach((el: any) =>
          ErrorHandler(error,
            () => {
              toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        );
      } else {
        ErrorHandler(error,
          () => {
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    },
  });
};



 
export const useSingleWarehouse = (wareHouseUuid:string) => {
  
  return useQuery(["warehouse_single"], async () =>  await wareHouseService.getDetails(wareHouseUuid), {

    enabled: true,
    retry: 1,
    staleTime: Infinity,
    cacheTime: Infinity,
    select: (data:{ data: { data: {
      [x: string]: any; data:any[]
}  }}) => {  
      return data.data.data
    },
    onSuccess (data) {
      // toast.success("success", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    },
    onError (error: any) {
      if (Array.isArray((error as any).response.data.error)) {
        (error as any).data.error.forEach((el: any) =>
          ErrorHandler(error,
            () => {
              toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        );
      } else {
        ErrorHandler(error,
          () => {
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    },
  });
};








 
export const useSingleWarehouseManager = () => {

  const { user } = useAuth()
  
  return useQuery(["warehouse_single_warehouse_manager"], async () =>  await wareHouseService.getDetails(user.warehouseUuid), {

    enabled: true,
    retry: 1,
    staleTime: Infinity,
    cacheTime: Infinity,
    select: (data:{ data: { data: {
      [x: string]: any; data:any[]
}  }}) => {  
     const seralize = data.data.data
      return new Array(
        {  
          name: seralize.name,
          state: seralize.state,
          country: seralize.country,
          contactFullName: seralize.contactFullName,
          contactPhoneNumber: seralize.contactPhoneNumber,
          isDefault: seralize.isDefault,
          uuid: seralize.uuid,
        }

        
        )
    },
    onSuccess (data) {
      // toast.success("success", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    },
    onError (error: any) {
      if (Array.isArray((error as any).response.data.error)) {
        (error as any).data.error.forEach((el: any) =>
          ErrorHandler(error,
            () => {
              toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        );
      } else {
        ErrorHandler(error,
          () => {
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    },
  });
};
import { Toaster , toast } from "react-hot-toast";
import "./BankInformation.scss";

import { Key, FC, useState, useEffect } from "react"  
import bankingFinanceService from "../../../../Services/bankingFinanceService";   
import { ErrorMessage } from "../Seller/Component";
 

interface propTypes {
  bankName: string,
  accountNumber: string,
  handleChange: any;
  handleBlur: any;
  formikErrors: any;
  formikTouchedValues: any;

}


const BankInfomation: FC<propTypes> = (prop) => {
  const { bankName, accountNumber, handleChange, handleBlur, formikErrors, formikTouchedValues } = prop

 
  const [allBank, setAllBank] = useState([])
  
  const getAllBank = async () => {
    await bankingFinanceService.getAllBank()
      .then((res) => { 
        setAllBank(res.data.data)
      }, (error: any) => { 
        toast.error(error.message, { duration: 20000, className: 'bg-danger text-white' });
      })
  }
  

  useEffect(() => {
    getAllBank()
  }, [])

  return (
    <div className="business-info">
      <div className="row">
        <div className="col-md-8">
          <div className="card bank-form-card p-6  border-0">
            <h3>Add Bank Account</h3>
            <p className="d-none d-md-block">
              This is where the money you withdraw from your CinderBuild wallet
              will be sent to.{" "}
            </p>
            <div className="card-body p-0">
              <div className="row">
                <div className="bank-form col-4 col-md-12 col-lg-6 p-0   d-md-block d-lg-block">
                  <div className="form-group mt-4">
                    <label htmlFor="bankName">
                      Bank Name
                      <select
                        className="form-control bank-input"
                        id="bankName"
                        name='bankName'
                        value={bankName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Bank Name "
                      >
                        <option>--select bank--</option>
                        {allBank.length > 0 && allBank.map((res: any, index: Key) => {
                          return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                        })}
                      </select>
                      {formikErrors.bankName && formikTouchedValues.bank && <ErrorMessage error={formikErrors.businessName} />}

                    </label>
                  </div>
                  <div className="form-group mt-4">
                    <label htmlFor="accountNumber">
                      Account Number
                      <input
                        className="form-control bank-input"
                        id='accountNumber'
                        type="text"
                        name="accountNumber"
                        value={accountNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Account Number "
                      />
                      {
                        formikErrors.businessName && formikTouchedValues.businessName &&
                        <ErrorMessage error={formikErrors.businessName} />
                      }

                      {/* {nameSearchRequest && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                      {myAccountDetails !== false && myAccountDetails !== null && <span className='text-success fw-bolder'>{myAccountDetails.account_name}</span>}
                      {myAccountDetails === false && <span className='text-danger'>Invalid account number</span>} */}
                    </label>
                  </div>
                  <div className="form-group mt-4">
                    <input
                      className="form-control bank-input"
                      type="text"
                      placeholder="Glory Store Enterprise Ltd "
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div >
  );
};
export default BankInfomation;

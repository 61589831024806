import React, { FC, useState } from 'react'
import './Footer.scss' 
import logo from "./../../Assets/Images/cinderBuild-logo.png";
import { Link } from 'react-router-dom';

interface propsType {
  module?: string
}

const FooterLarge:FC<propsType> = ({module}) => {
  const [isActive, setActive] = useState<any>({ isActive: false, menu: 0 });

  const handleToggle = (data:any) => {
    setActive({ isActive: !isActive, ...data });
  };
  
  return ( 
    <React.Fragment> 
       <footer className={module && module ==='estate'? "footer footer-estate fixed-footer": "footer fixed-footer"} id="footer">
          <div className="container"> 
            <div className="row mt-3">
              <div className={isActive.isActive && isActive.menu === 1 ? "col-sm-12 col-md-6 col-lg-6 widget active" : "col-sm-12 col-md-6 col-lg-6 widget"}>

              <img src={logo} alt="LOGO" className="footer-logo-item d-none d-md-block mb-2"/>

              <h4 onClick={() => handleToggle({ isActive: true, menu: 1 })} className={isActive.isActive && isActive.menu === 1 ? "footer-widget-title mb-3 active d-md-none" : "footer-widget-title mb-3 d-md-none"}>Contact Information</h4>

              <div className="widget_content footer-widget-content"> 
                    <ul className="m-0 p-0 list-group">
                      <li className="list-group-item border-0 ps-0">
                           <div className="border-0 location-address">16192 Coastal Highway, Lewes, Delaware, USA</div>
                       </li>
                      <li className="list-group-item border-0 ps-0">
                           <div className="border-0 location-address pt-0 mt-0">Vibranium Valley (Regus), 42 Concord Way, Local Airport Road, Ikeja, Lagos.</div>
                       </li>
                      <li className="list-group-item border-0 ps-0">
                         <div className="border-0 location-address">cb_support@cinderbuild.com</div>
                       </li>
                      <li className="list-group-item border-0 ps-0"> 
                          <div className="border-0 location-address">Whatsapp: +2349168777239, Call: +2347001236202</div>
                       </li>
                    </ul>
                </div> 
              </div>
              <div className={isActive.isActive && isActive.menu === 2 ? "col-sm-12 col-md-6 col-lg-2 widget active" : "col-sm-12 col-md-6 col-lg-2 widget"}>
                <h4 onClick={() => handleToggle({ isActive: true, menu: 2 })} className={isActive.isActive && isActive.menu === 2 ? "footer-widget-title mb-3 active" : "footer-widget-title mb-3"}>Company</h4>

                <div className="widget_content footer-widget-content pb-5">
                  <nav className="nav flex-column mb-3">
                    <Link className="nav-link" to="/about">About us</Link>
                    {/* <Link className="nav-link" to="/buyer/">Contact us</Link> */}
                    <Link className="nav-link" to="/privacy-policy">Privacy policy</Link>
                    <Link className="nav-link" to="/terms-and-conditions">Terms & Conditions</Link>
                  </nav>
                </div> 
              </div>
              <div className={isActive.isActive && isActive.menu === 4 ? "col-sm-12 col-md-6 col-lg-2 widget active" : "col-sm-12 col-md-6 col-lg-2 widget"}>
                <h4 onClick={() => handleToggle({ isActive: true, menu: 4 })} className={isActive.isActive && isActive.menu === 4 ? "footer-widget-title mb-3 active" : "footer-widget-title mb-3"}>Features</h4>

                <div className="widget_content footer-widget-content">
                  <nav className="nav flex-column mb-3">
                    <Link className="nav-link active" to="/buyer">CinderBuild for Buyers</Link>
                    {/* <Link className="nav-link" to="/seller">CinderBuild for Sellers</Link> */}
                    <Link className="nav-link" to="/enterprise-home">CinderBuild for Corporates</Link>
                    <Link className="nav-link" to="/estate-home">CinderBuild for Estate Developers</Link>
                    <Link className="nav-link" to="/estate-home">CinderBuild for Estate Investors</Link>
                  </nav>
                </div>
              </div>

              <div className={isActive.isActive && isActive.menu === 3 ? "col-sm-12 col-md-6 col-lg-2 widget active" : "col-sm-12 col-md-6 col-lg-2 widget"}>
                <h4 onClick={() => handleToggle({ isActive: true, menu: 3 })} className={isActive.isActive && isActive.menu === 3 ? "footer-widget-title mb-3 active" : "footer-widget-title mb-3"}>Product</h4>

                <div className="widget_content footer-widget-content">
                  <nav className="nav flex-column mb-3">
                    <a className="nav-link active" href="/products-by-category/e40d9bba-8ccb-4daa-a36f-51ba9eee575e">Cement</a>
                    <a className="nav-link" href="/products-by-category/b7d897ec-1e07-4d07-a299-8227318555d5">Electrical</a>
                    <a className="nav-link" href="/products-by-category/40f9e3e7-fa9b-40c6-9613-8126a2b833b0">Roofing</a>
                    <a className="nav-link" href="/products-by-category/ce241bd1-9d93-4e36-8e68-cb039bafc047">Flooring</a>
                    <a className="nav-link" href="/products-by-category/df0ee341-dffa-4f33-91e0-99025b418b6e">Doors & Accessories</a>
                  </nav>
                </div>
              </div>
              <div className={isActive.isActive && isActive.menu === 5 ? "col-sm-12 col-md-6 col-lg-2 widget active d-none" : "col-sm-12 col-md-6 col-lg-2 widget d-none"}>
                <h4 onClick={() => handleToggle({ isActive: true, menu: 5 })} className={isActive.isActive && isActive.menu === 5 ? "footer-widget-title mb-3 active" : "footer-widget-title mb-3"}>Resources</h4>

                <div className="widget_content footer-widget-content">
                  <nav className="nav flex-column mb-3">
                    <a className="nav-link" href="#">How to sell fast on CinderBuild</a>
                    <a className="nav-link" href="#">Learn how to post strategically and make the best sales on CinderBuild</a>
                    <a className="nav-link" href="#">Top 10 best selling brands on CinderBuild</a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </footer>
 
        <div className="footer-credit text-center">
            &copy; 2022 CinderBuild.com - All rights reserved.
        </div>  
    </React.Fragment>
  )
}

export default FooterLarge

import React, { PropsWithChildren } from 'react'
import "./StoreProductItem.scss"
import Skeleton from 'react-loading-skeleton';

const StoreProductItemNone = () => {
  function Box ({ children }: PropsWithChildren<unknown>) {
    return (
        <div
            style={{
              border: '1px solid #fff',
              display: 'block',
              lineHeight: 2,
              padding: '0rem',
              marginBottom: '0.1rem',
              width: "100%",
            //   height: 137,
            }}
        >
            {children}
        </div>
    )
  }

  return (
    <div className="col-6 col-sm-6 col-md-6  col-lg-6 col-xl-3 p-2 p-sm-3 p-md-3">
        <div className="card popular-card my-1 p-0">
            <div className="card-body text-center p-2 p-sm-3 p-md-3">
                <div className="wishicon mb-1 text-end">
                {/* <i className="fa fa-light fa-heart"></i> */}
                <Skeleton wrapper={Box} count={1} height={20} width={20}/>
                </div>
                <Skeleton wrapper={Box} count={1} height={120}/>
                <div className="row  d-none d-lg-flex">
                    <div className="col-12 w-100 text-end">
                    <Skeleton wrapper={Box} count={1} height={40} width={60}/>
                    </div>
                    <div className="col-12">
                    <h5 className="popular-title mt-2 text-start">
                      <Skeleton wrapper={Box} count={1} height={5}/>
                    </h5>
                    </div>

                </div>

                  <div className="row d-lg-none">
                    <div className="col-6 text-start">
                       <div className="prod-price text-start pt-2 text-danger font-weight-bold"><Skeleton wrapper={Box} count={1} height={5}/></div>
                    </div>
                    <div className="col-6  text-end">
                    <Skeleton wrapper={Box} count={1} height={15}/>
                    </div>
                </div>
            </div>
            <div className="card-footer bg-white">
              <Skeleton wrapper={Box} count={1} height={20}/>
            </div>
        </div>
    </div>
  )
}

export default StoreProductItemNone

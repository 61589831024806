import "./EnterpriseSettingsMain.scss";   
import { EnterpriseSettingsNavCard } from "../../../Components"
import { EnterprisePageTitle } from "../../../../../../../Components";
import { FC } from "react";
import { useAuth } from "../../../../../../../Components/AuthProvider/AuthProvider";

interface propsType { 
    module?: string
}

const Main:FC<propsType> = ({module})=>{

    const { user } = useAuth()
    return (
        <div className="enterpriseSettingsMain mb-4">
             <EnterprisePageTitle
                title="Settings"
                parent=""
                parentUrl=""
                showTitle={false}
             />
            <div className="row justify-content-center my-5">
                <div className="col-12 col-md-9 col-lg-6 nav-card-container">
                    <div className="mb-2" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="9000">
                        <EnterpriseSettingsNavCard
                            title="Profile"
                            description="Update personal information."
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M17.08 14.1528C14.29 12.2928 9.73996 12.2928 6.92996 14.1528C5.65996 15.0028 4.95996 16.1528 4.95996 17.3828C4.95996 18.6128 5.65996 19.7528 6.91996 20.5928C8.31996 21.5328 10.16 22.0028 12 22.0028C13.84 22.0028 15.68 21.5328 17.08 20.5928C18.34 19.7428 19.04 18.6028 19.04 17.3628C19.03 16.1328 18.34 14.9928 17.08 14.1528Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            </svg>
                            }
                            link="profile"
                        />
                    </div>
                    {!user.isInvestor && <div className="mb-2" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="3000">
                        <EnterpriseSettingsNavCard
                            title="Business Profile"
                            description="Update business information."
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M17.08 14.1528C14.29 12.2928 9.73996 12.2928 6.92996 14.1528C5.65996 15.0028 4.95996 16.1528 4.95996 17.3828C4.95996 18.6128 5.65996 19.7528 6.91996 20.5928C8.31996 21.5328 10.16 22.0028 12 22.0028C13.84 22.0028 15.68 21.5328 17.08 20.5928C18.34 19.7428 19.04 18.6028 19.04 17.3628C19.03 16.1328 18.34 14.9928 17.08 14.1528Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            </svg>
                            }
                            link="profile-business"
                        />
                    </div>}
                    <div className="mb-2" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="5000">
                        <EnterpriseSettingsNavCard
                            title="Banking and Finance"
                            description="Update banking & finance information"
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"} stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7 11H5V18H7V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M11 11H9V18H11V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M15 11H13V18H15V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M19 11H17V18H19V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M21.37 5.75375L12.37 2.15375C12.17 2.07375 11.83 2.07375 11.63 2.15375L2.63 5.75375C2.28 5.89375 2 6.30375 2 6.68375V10.0037C2 10.5538 2.45 11.0037 3 11.0037H21C21.55 11.0037 22 10.5538 22 10.0037V6.68375C22 6.30375 21.72 5.89375 21.37 5.75375ZM12 8.50375C11.17 8.50375 10.5 7.83375 10.5 7.00375C10.5 6.17375 11.17 5.50375 12 5.50375C12.83 5.50375 13.5 6.17375 13.5 7.00375C13.5 7.83375 12.83 8.50375 12 8.50375Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            </svg>
                            }
                            link="bank-and-finance"
                        />
                    </div>
                    <div className="mb-2" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="3000">
                        <EnterpriseSettingsNavCard
                            title="Security"
                            description="Change Password"
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"} stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7 11H5V18H7V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M11 11H9V18H11V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M15 11H13V18H15V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M19 11H17V18H19V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M21.37 5.75375L12.37 2.15375C12.17 2.07375 11.83 2.07375 11.63 2.15375L2.63 5.75375C2.28 5.89375 2 6.30375 2 6.68375V10.0037C2 10.5538 2.45 11.0037 3 11.0037H21C21.55 11.0037 22 10.5538 22 10.0037V6.68375C22 6.30375 21.72 5.89375 21.37 5.75375ZM12 8.50375C11.17 8.50375 10.5 7.83375 10.5 7.00375C10.5 6.17375 11.17 5.50375 12 5.50375C12.83 5.50375 13.5 6.17375 13.5 7.00375C13.5 7.83375 12.83 8.50375 12 8.50375Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            </svg>
                            }
                            link="security"
                        />
                    </div>
                    {!user.isInvestor && <div className="mb-2" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-duration="1000">
                        <EnterpriseSettingsNavCard
                            title="Notification"
                            description="Request account statement, secondary wallet withdrawal, bank account update."
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"} stroke={ module && module==="estate"? "#3A0CA3": "#F5A623"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7 11H5V18H7V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M11 11H9V18H11V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M15 11H13V18H15V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M19 11H17V18H19V11Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            <path d="M21.37 5.75375L12.37 2.15375C12.17 2.07375 11.83 2.07375 11.63 2.15375L2.63 5.75375C2.28 5.89375 2 6.30375 2 6.68375V10.0037C2 10.5538 2.45 11.0037 3 11.0037H21C21.55 11.0037 22 10.5538 22 10.0037V6.68375C22 6.30375 21.72 5.89375 21.37 5.75375ZM12 8.50375C11.17 8.50375 10.5 7.83375 10.5 7.00375C10.5 6.17375 11.17 5.50375 12 5.50375C12.83 5.50375 13.5 6.17375 13.5 7.00375C13.5 7.83375 12.83 8.50375 12 8.50375Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                            </svg>
                            }
                            link="notification"
                        />
                    </div> }
                </div>
            </div>
        </div>
    )
}

export default Main;
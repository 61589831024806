import { collection, doc, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'; 
import React, { useState , useEffect } from 'react'
import { EnterprisePageTitle, HeaderMeta } from '../../../../../../Components'
import { useAuth } from '../../../../../../Components/AuthProvider/AuthProvider';
import { timeFormat } from '../../../../../../Helpers/TimeFormater';
import { firbaseDB } from '../../../../../../Services/firebaseInitService';
import "./EnterpriseNotification.scss"

const EnterpriseNotification = () => {
    const [notification, setNotification] = useState<any>([]);
    const [notificationByDate, setNotificationByDate] = useState<any>([]);
     
    const { user } = useAuth()
    
    const getNotice = async () => {  
         onSnapshot(query(collection(doc(firbaseDB, "notification_updates", user.uuid), "notifications"), where('isRead', '==', false), orderBy('createdAt', "desc"), limit(20)), 
         (querySnapshot:any)=>{
           const notice = querySnapshot.docs.map((data: { data: any; }) => data.data())
 
            setNotification(notice)

         })

    }
    useEffect(()=>{
        getNotice()
    }, [])
    
   
    useEffect(()=>{
       
        const data =  notification
          
          // this gives an object with dates as keys
          const groups = data.reduce((groups:any, game:any) => {
            // const date:any = game.time.split('T')[0];

          const date:any =  new Date(game.createdAt.seconds * 1000).toISOString().split('T')[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(game);
            return groups;
          }, {});
 
          const today = new Date().toJSON().slice(0,10)
          const yesterday = new Date(new Date().setDate(new Date().getDate()-1)).toJSON().slice(0,10)
          // Edit: to add it in the array format instead
          const groupArrays = Object.keys(groups).map((date) => { 
            if (today===date) {
            return {
              date,
              dateAlias: "Today",
              notice: groups[date]
            };
           } else  if (date===yesterday) {
            return {
                date,
              dateAlias: "Yesterday",
              notice: groups[date]
            };
           } else {
              return {
                date,
                dateAlias: "All",
                notice: groups[date]
              };
           }
          });
          setNotificationByDate(groupArrays)
    }, [notification])
  

    return (
        <div className='enterpriseNotification'>
            <HeaderMeta title={'All Notifications'}></HeaderMeta>
            <div className="container">
                <EnterprisePageTitle  title={'All Notifications'}  parent={""} parentUrl={""}  showTitle={false}></EnterprisePageTitle>
            
                <div className="notification-container">
                    <div className="row justify-content-center">
                        {notification.length>0? notificationByDate.map((res:any, index:React.Key)=> <div key={index} className="col-md-7"> 
                            <div className="card">
                                <div className="card-header bg-white border-0">
                                {res.dateAlias}
                                </div>
                                <div className="card-body p-0 rounded-0">
                                    <div className="list-group rounded-0 border-0">
                                        {res.notice.map((res:any, index:number)=><a key={index} href="#" className={`list-group-item borf list-group-item-action ${index ===2? "active": ""}`} aria-current="true">
                                            <div className="row">
                                                <div className="col-1">
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.9999 22.2498C11.1799 22.2498 10.3599 21.9898 9.6799 21.4798L4.46991 17.5798C3.49991 16.8498 2.91992 15.6898 2.91992 14.4798V1.75977H21.0799V14.4798C21.0799 15.6898 20.4999 16.8498 19.5299 17.5798L14.3199 21.4798C13.6399 21.9898 12.8199 22.2498 11.9999 22.2498ZM4.41992 3.24977V14.4698C4.41992 15.2098 4.7799 15.9198 5.3699 16.3698L10.5799 20.2698C11.4199 20.8998 12.5899 20.8998 13.4299 20.2698L18.6399 16.3698C19.2299 15.9198 19.5899 15.2098 19.5899 14.4698V3.24977H4.41992Z" fill="#292D32"/>
                                                        <path d="M22 3.25H2C1.59 3.25 1.25 2.91 1.25 2.5C1.25 2.09 1.59 1.75 2 1.75H22C22.41 1.75 22.75 2.09 22.75 2.5C22.75 2.91 22.41 3.25 22 3.25Z" fill="#292D32"/>
                                                        <path d="M16 8.75H8C7.59 8.75 7.25 8.41 7.25 8C7.25 7.59 7.59 7.25 8 7.25H16C16.41 7.25 16.75 7.59 16.75 8C16.75 8.41 16.41 8.75 16 8.75Z" fill="#292D32"/>
                                                        <path d="M16 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H16C16.41 12.25 16.75 12.59 16.75 13C16.75 13.41 16.41 13.75 16 13.75Z" fill="#292D32"/>
                                                    </svg> 
                                                </div>
                                                <div className="col-10">
                                                    <div className="content">
                                                    {res.body}
                                                    </div>
                                                    <div className="time">
                                                    {timeFormat(res.createdAt.seconds)}  
                                                    </div> 
                                                </div>
                                                <div className="col-1">
                                                    <button className='btn p-0 border-0 rounded-circle'>
                                                        <svg width="20" height="20" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 28.1227C21.875 28.1227 27.5 22.3734 27.5 15.3465C27.5 8.31959 21.875 2.57031 15 2.57031C8.125 2.57031 2.5 8.31959 2.5 15.3465C2.5 22.3734 8.125 28.1227 15 28.1227Z" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M11.4629 18.9618L18.5379 11.7305" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M18.5379 18.9618L11.4629 11.7305" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>  
                                                    </button>
                                                </div>
                                            </div>
                                        </a>)} 
                                      </div>

                                </div>
                            </div>
                        </div>) : "No notitification found"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnterpriseNotification

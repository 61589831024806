import React, { FC, useState } from 'react'
import "./AddProduct.scss"
import searchService from '../../../../../../../Services/searchService'
import toast, { Toaster } from 'react-hot-toast'
import { productType } from '../../../../../../../Models'

type propsType = {
    setParentUuid: (val: string)=>void
}

const SearchParentProduct:FC<propsType> = ({setParentUuid}) => { 

    const [toogleSearchOverlay, setTogleSearchOverlay] = useState(false)
  const [searchResult, setSearchResult] = useState([]) 
  const [searchLoading, setSearchLoading] = useState(false)
  const [sortOrder,] = useState("ASC")
  const [searchWord, setSearchWord] = useState<string>()
  const [pageNumber,] = useState(1)
  
    const searchText = async (e: { target: { value: string; }; }) => {
        setSearchLoading(true) 
        const data = { searchWord: e.target.value }
        setSearchWord(e.target.value)
        await searchService.index(data, pageNumber, sortOrder).then((res:any) => {
          setSearchResult(res.data.data)
          setSearchLoading(false)
          setTogleSearchOverlay(true) 
        }, (error) => {
          setSearchLoading(false) 
    
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
      }

    return (
        <div className='addproduct'>
            <input className='form-control' value={searchWord} type="text" onChange={searchText} placeholder='Search for parent product.'></input>
            <div className={toogleSearchOverlay === true ? "search-result shadow search-result-frame" : ""}>
                <ul className="list-group">
                    {searchResult.length > 0 && toogleSearchOverlay === true && !searchLoading && searchResult.map((res:productType, index:React.Key) => {
                      return (<li key={index} className="list-group-item border-0" onClick={()=>{ setSearchWord(res.productName); setParentUuid(res.productUuid); setTogleSearchOverlay(false) }}>{res.productName}</li>)
                    })}
                </ul>
            </div>
            <Toaster></Toaster>
        </div>
    )
}

export default SearchParentProduct

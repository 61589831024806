import { useQuery } from "@tanstack/react-query";
// import toast from "react-hot-toast";
import { ErrorHandler } from "../../../Helpers/ErrorHandler";
import productService from "../../../Services/productService";

export const useFetchBrandsProducts = (brandUuid:string|undefined, pageNumber:number, sortOrder:string) => {
  const context = useQuery(["fetchBrandsProducts"], () => productService.getAllBrandProduct(brandUuid, pageNumber, sortOrder),
    {
      enabled: false,
      retry: false,
      select: (data:{ data: { data:{ dataset:any[], pageNumber: number, pageSize: number, total: number } } }) => data.data.data,
      onSuccess (data) {
        // toast.success("success", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      },
      onError (error: any) { 
            ErrorHandler(error,
              () => {
                // toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
              }) 
      },
    }
  );

  return context;
};

import Sort from "../../../Assets/Images/icon/sort.png"; 
import {FC, useEffect, useState} from "react";
import "./SortDropDown.scss";

type optionType = {
    name:string,
    value:string
}

interface proptypes {
    options:optionType[],
    setSortOrder:any
} 

const SortDropDown:FC<proptypes> = ({options, setSortOrder}) => {
    
    const [showDropDown, setShowDropDown] = useState(false);
    
    useEffect (()=>{
        const hideDropDownMenu =  ()=>{
            setShowDropDown(false);
        }
        window.addEventListener('click', hideDropDownMenu)

        return ()=>{
            window.removeEventListener('click',hideDropDownMenu);
        }
    },[])

    const handleToggle = (evt:any)=>{
        evt.stopPropagation();
        if (showDropDown) {
            setShowDropDown(false)
            return
        }
        setShowDropDown(true)
    }

    return (
        <div className="custom-dropdown">
            <button className="btn p-1 btn-title" type="button" onClick={handleToggle}>
                <img src={Sort} className="nav-icon me-1" alt=""/> Sorting
            </button>
            {
                showDropDown && 
                <ul className="custom-dropdown-menu">
                    {
                        options?.map((option:optionType,index:number)=>{
                            return <li key={index} className="custom-dropdown-item"><a  href="#" onClick={() => setSortOrder(option.value)}>
                                    {option.name}
                                </a></li>
                        })
                    }
                </ul>
            }
        </div>
    )
}

export default SortDropDown;
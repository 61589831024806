import * as yup from "yup";

  
export const ValidationSchema =  yup.object({ 
        emailAddress : yup.string().email("Invalid email format").required("Your email address is required"),
        lastName: yup.string().required("Last name is required"),
        firstName: yup.string().required("First name is required"),
        phoneNumber: yup.string().required("Your phone number is required"),
        password: yup.string().required("First name is required"),
        companyName: yup.string().required("Company name is required"),
        cacNumber: yup.string().required("CAC number is required"),
        address: yup.string().required("Address is required")
})

export const InitialFormValues = {
        emailAddress: "",
        lastName: "", 
        firstName: "",
        phoneNumber: "",
        password: "",
        companyName:"", 
        cacNumber:"", 
        address:"", 
}

export interface FormValuesType  { 
        emailAddress: string,
        lastName: string, 
        firstName: string,
        phoneNumber: string,
        password: string,
        companyName: string, 
        cacNumber: string, 
        address: string, 
}

export interface FormErrorsType  {
        emailAddress: string,
        lastName: string, 
        firstName: string,
        phoneNumber: string,
        password: string,
        companyName: string, 
        cacNumber: string, 
        address: string, 
}
import React, { useState, FC , Fragment } from 'react'
import { Modal } from 'react-bootstrap' 
import "./EnterpriseSecondaryWalletTransferModal.scss" 
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService';
import toast, { Toaster } from 'react-hot-toast';  
import { Logger } from '../../../../../../../Helpers/Logger';
 
 

interface propsType { 
  showModal:boolean;
  hide: (value:boolean)=>void;
  module?: string;
}

const EnterpriseSecondaryWalletTransferModal:FC<propsType> = ({showModal, hide, module}) => {
  
 
  const [amount, setAmount] = useState(0)  
 
  const [show2, setShow2] = useState(false)  
  const [withdrawaLRequest, setWithdrawaLRequest] = useState(false);

 
 
  const handleClose2 = () => setShow2(false)

  const submit = async () => {
    setWithdrawaLRequest(true)

    await bankingFinanceService.secondaryWalletTransfer({ amountMajor: amount })
      .then((res) => {
        setWithdrawaLRequest(false) 
        setShow2(true)
        hide(false) 
      }, (error:any) => {
        Logger("banking Finance Service", error.response)
        setWithdrawaLRequest(false) 
        toast.error(error.response.data.error, { duration: 25000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

 
 
  return (  
    <Fragment>
    <Modal 
      show={showModal} 
      onHide={()=>hide(false)}
      backdrop="static"
      keyboard={false}
      contentClassName="enterpriseSecondaryWalletTransfer-modal">  
        <Modal.Header closeButton className='border-0'>
          <Modal.Title>Transfer From Secondary To Primary Wallet</Modal.Title>
        </Modal.Header> 
        <Modal.Body> 
            <div className='form-input'> 
                <div className='form-input-wrap mt-3'>
                  <label htmlFor="">Amount </label>
                    <input id="accountNumber" type="number" className='form-control ps-2 mt-2' placeholder='₦0.00' onChange={(e:any) => setAmount(e.target.value)}/>
                  </div>  
                  <div className="row mt-3 pb-4">
                      <div className="col-12 mt-4">
                        {!withdrawaLRequest && <button className={module && module==='estate'? "btn apply-estate btn-primary" : 'btn apply btn-primary'} onClick={submit}>Transfer</button>}
                            {withdrawaLRequest && <button className={module && module==='estate'? "btn apply-estate btn-primary" : 'btn apply btn-primary'}  disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Processing request...
                              </button> }
                        </div>
                  </div>
            </div> 
      </Modal.Body>
    </Modal>
 
      <Modal show={show2} onHide={handleClose2} contentClassName="customModal fws">
        <Modal.Body>
          <div className="container">
          <div className="row justify-content-center mt-4 mb-4">
            <div className="col-3">
              <svg width="100%" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M124.688 -3.75V126.562H-5.15625V-3.75H124.688Z" fill="white"/>
                <g opacity="0.851687">
                <path d="M60.0002 10.4922C86.8817 10.4922 108.707 32.3179 108.707 59.1994C108.707 86.081 86.8817 107.907 60.0002 107.907C33.1187 107.907 11.293 86.081 11.293 59.1994C11.293 32.3179 33.1187 10.4922 60.0002 10.4922Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"} fillOpacity="0.3"/>
                </g>
                <path d="M60 17.8984C82.8952 17.8984 101.484 36.4876 101.484 59.3828C101.484 82.278 82.8952 100.867 60 100.867C37.1048 100.867 18.5156 82.278 18.5156 59.3828C18.5156 36.4876 37.1048 17.8984 60 17.8984Z" fill={ module && module==="estate"? "#3A0CA3": "#F5A623"}/>
                <path d="M40.6641 61.0547L52.7344 72.8906L77.1094 47.6953" stroke="white" strokeWidth="7.03125" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>

            <div className="col-12 text-center mb-4">

            <div className='fw-bold'>Success!</div>
            <div className='details'>You have successfully transfered &#8358;{amount} from your secondary wallet to primary wallet.</div>
            </div>

            <div className="col-12">
              <button className={module && module==='estate'? "btn btn3 w-100" : 'btn btn2 apply btn-primary w-100'}  onClick={() => { setShow2(false) }}>Done</button>
            </div>
          </div>
          </div>

        </Modal.Body>
      </Modal>
    <Toaster/> 
    </Fragment>
  )
}

export default EnterpriseSecondaryWalletTransferModal
import { AnyObject } from "yup/lib/types";
import { TUTORIAL_REFS } from "../../Constants/TutorialTipsConstants";

 
const TutorialTipsRefReducers = (state = null, action: { type: string; payload: AnyObject; }) => {
  switch (action.type) {
    case TUTORIAL_REFS:
      return action.payload
    default:
      return state
  }
}

export default TutorialTipsRefReducers;

import axios from 'axios'


 export const AxiosInterceptor = () => {
    
     axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
     axios.defaults.headers.post['Content-Type'] = 'application/json'; 
    return axios.interceptors.request.use(function (config) {
      // Do something before request is sent 
      config.headers['Access-Control-Allow-Origin'] = "*";
      // config.headers['Content-Type'] ='application/json'; 
    
      const userObject = localStorage.getItem('__cbuN')
      if (userObject !== null && userObject !== "null" && userObject) {
        const token = userObject && JSON.parse(userObject).token
        config.headers['X-Access-Token'] = token
      }
    
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
 
}
 

import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../Helpers/ErrorHandler";
import productService from "../../../Services/productService";

export const useFetchCategoryInfo = (catUuid:string|undefined) => {
  const context = useQuery(["fetchBrandsInfo"], () => productService.getCategoryInfo(catUuid),
    {
      enabled: true,
      retry: false,
      select: (data:{ data: { data: {imageUrl:string, name: string, bannerUrl: string} } }) => data.data.data,
      onSuccess (data) {
        // toast.success("success", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      },
      onError (error: any) {
        if (Array.isArray((error as any).data.error)) {
          (error as any).data.error.forEach((el: any) =>
            ErrorHandler(error,
              () => {
                toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
              })
          );
        } else {
          ErrorHandler(error,
            () => {
              toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        }
      },
    }
  );

  return context;
};

import React, { useState } from 'react'
import "./ForgotPasswordSuccess.scss"
import whiteLogo from "../../../../Assets/Images/logo-white.png"
import Logo from "../../../../Assets/Images/cinderBuild-logo.png"
import cinderBg from "../../../../Assets/Images/cinderBg.png"
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import authService from '../../../../Services/authService';
import { decodeToken, isExpired } from 'react-jwt'
import OtpInput from 'react-otp-input'
import { Logger } from '../../../../Helpers/Logger';
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider'

const ForgotPasswordSuccess = () => {
  const [submit, setSubmit] = useState(false)
  const [otp, setOtp] = useState("")
  const [error, setError] = useState<any>(null)
  const [success, setSuccess] = useState<any>(null)

  const { handleSubmit } = useForm({ mode: 'onBlur' });

  const navigate = useNavigate()
  const onSubmit = handleSubmit((data:any) => processLogin(data));

  const { login } = useAuth()

  const processLogin = async (data:any) => {
    setSubmit(true)
    // Logger(data)

    const a:any = localStorage.getItem("temp__reg");
    const userPrev = JSON.parse(a);
    // Logger("a", userPrev)
    const Fdata = {
      otp: String(otp),
      phoneNumber: String(userPrev.phoneNumber),
      countryIso2: String(userPrev.countryIso2)
    }
    // Logger("verifyPin", Fdata)

    authService.verifyPin(Fdata)
      .then(
        (res:any) => {
          // Logger(Fdata)
          // Logger("data", res)
          setSubmit(false)

          if (Boolean(res.data.status) === false) {
            setError(error.response.data.message)
          } else {
            // Logger("wewrewrwrwe", res.data.data.token)
            const myDecodedToken:any = decodeToken(res.data.data.token);
            // eslint-disable-next-line no-unused-vars
            const isMyTokenExpired = isExpired(res.data.token);
            // Logger("User details", myDecodedToken)

            // localStorage.setItem('__cbu', data.data.data.token);
            // localStorage.setItem('__cbu', JSON.stringify(myDecodedToken));
            localStorage.setItem('__cbuN', JSON.stringify({ ...res.data.data, ...myDecodedToken, ...res.data, switched: 0 }))
            login({ ...res.data.data, ...myDecodedToken, ...res.data, switched: 0 })

            // unset temp data
            localStorage.removeItem("temp__reg");
            if (myDecodedToken.isSeller) {
              navigate("/new-password", { replace: true })
            } else {
              navigate("/new-password", { replace: true })
            }
          }
        },
        (error:any) => {
          // Logger(error.response)
          setSubmit(false)

          setError(error.response.data.message)
        });
  }

  const handleChangeOtp = (otp:any) => {
    setOtp(otp);
    // Logger(otp)
  }

  const sendAgain = async () => {
    const a:any = localStorage.getItem("temp__reg");
    const userPrev = JSON.parse(a);
    // Logger("a", userPrev)

    const Fdata = {
      phoneNumber: String(userPrev.phoneNumber),
      countryIso2: String(userPrev.countryIso2)
    }
    setSuccess("Resending token, please wait...")

    setTimeout(() => {
      setSuccess(null)
    }, 3000)

    await authService.resetPassword(Fdata).then((user:any) => {
      setSuccess("Token resent")
    }, (error:any) => {
      Logger(error.response)
      setError(error.response.data.error)
      setSubmit(false)
    })
  }
  return (
    <div className="ForgotPasswordSuccess">
       <div className="row h-100">
            <div className="col-md-6 d-none d-md-block">
                <div className="login-right">
                    <div className="container">
                        <div className="login-header">
                            <div className="row">
                                <div className="col-md-2 text-end">
                                    <div className="login-back">
                                    <a href=""><span className="d-icon d-arrowhead-left shadow"></span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="login-logo">
                                        <img src={whiteLogo} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="login-text">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <h2>Welcome</h2>
                                    <div className="desc">
                                    Let’s get you all set up, create an account and start using CinderBuild
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={cinderBg} alt="" className='abstImg'/>
                </div>
            </div>
            <div className="col-md-6 my-auto">
                <div className="login-header d-md-none">
                    <div className="row h-100">
                        <div className="col-2">
                            <div className="login-back">
                            <a href="" onClick={() => setError(null)}><span className="d-icon d-arrowhead-left"></span></a>
                            </div>
                        </div>
                        <div className="col-8 text-start ps-0 my-auto">
                            <div className="login-logo">
                                <img src={Logo} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="login-form" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <h1 className='text-center'> Verify Phone Number</h1>
                        <small className='text-center'>  Enter confirmation code sent to your phone number.</small>
                        <form onSubmit={onSubmit}>
                            <div className="form-group mt-4 mb-3 container-token text-center">
                            <OtpInput
                                value={otp}
                                onChange={handleChangeOtp}
                                numInputs={4}
                                separator={<span>   </span>}
                                inputStyle={{
                                  width: "3.1rem",
                                  height: "2.5rem",
                                  minHeight: "2.5rem",
                                  margin: "0 .5rem",
                                  fontSize: "1rem",
                                  borderRadius: 4,
                                  backgroundColor: "#ECF1F4",
                                  boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
                                  border: "1px solid rgba(0,0,0,0.1)"
                                }}
                              />

                            </div>

                            {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                            <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                                <div className="text-danger">
                                {error}
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}

                            {success !== null && <div className="alert alert-success d-flex align-items-center mt-2 alert-dismissible fade show">
                            <i className="d-icon d-warning text-success bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                                <div className="text-success">
                                {success}
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}

                          <div className="mt-4 text-center">
                            <Link to="#" onClick={() => sendAgain()} className="resendOtp">Didn’t recieve code? Send again..</Link>
                          </div>

                          <div className="form-group mt-4">
                                {!submit && (<button type="submit" className="btn btn-login">Verify</button>)}
                                {submit && (<button type="submit" className="btn btn-login" disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>)}
                            </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ForgotPasswordSuccess

 
import { db } from '../../../../../Config/DBConfig';
import cartService from '../../../../../Services/cartService';
import { savePaymentType } from '../../../../../Helpers/savePaymentType';
import { Logger } from '../../../../../Helpers/Logger';
import toast from 'react-hot-toast'; 
import { ErrorHandler } from '../../../../../Helpers/ErrorHandler';

export const PickUpOrderUnRegisteredUser = async (orderDetails:any, setLoadingOrder:any, savePaymentDetail:any, navigate:any ) => {
 
  
    // remove address of excess field
    const newPickupLoc = orderDetails[0].pickupLocations.sellers.map((item:any) => {
      delete item.address;
      return {...item, orderReceiveType: orderDetails[0].deliveryType};
    });

    Logger("PICKUP", {
      data: {
        sellers: newPickupLoc
      },
      orderPaymentVariant: orderDetails[0].orderPaymentVariant
    })

    const buyerDetails = orderDetails[0]?.pickupContactInformation

   return await cartService.deliveryDetailsUnauth(
      {
        sellers: newPickupLoc,
        buyer: buyerDetails
      }
    ).then(async (res:any) => {

      setLoadingOrder(false)

      await savePaymentType("UNAUTH_BUYER_BUY").then(async ()=>{
        await db.cart.clear()
        await db.orderDetails.clear()
  
        Logger("ORDER created unauth", res)
  
        if (orderDetails[0].orderPaymentVariant === "CARD") {
          savePaymentDetail(res) 
  
          window.location.href = res.data.data.paymentProviderDetails.paymentProviderRedirectUrl;
  
        } else if (orderDetails[0].orderPaymentVariant === "WALLET" && res?.data.data.paymentTransactionStatus === "paid") {
          
          toast.success("Payment succesful, information about this order has been sent to your email address.", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          
          setTimeout(()=>{ 
            navigate(`/`, { replace: true })
         }, 4000)
  
        } else if (orderDetails[0].orderPaymentVariant === "PAY_ON_DELIVERY" && res.data.status === true && res.data.data.paymentTransactionStatus === "unpaid") {
          toast.success("Pay on delivery Order succesful, information about this order has been sent to your email address.", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          
          setTimeout(()=>{ 
            navigate(`/`, { replace: true })
         }, 4000)
  
        }
      })
     
    }, (error:any) => {
      setLoadingOrder(false)
      console.warn("ORDER ERROR: ", error.response)

      ErrorHandler(error,
        () => {
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    })
} 

import React, { useCallback, useEffect, useState } from 'react'
import "./EstateManagersSetup.scss" 
import Logo from "../../../../../../Assets/Images/cinderBuildLogo.png" 
import { Link, useNavigate } from 'react-router-dom'  
import ReactGA from 'react-ga'  
import {useFormik} from "formik"; 
import toast, { Toaster } from 'react-hot-toast'
import { FormValuesType, InitialFormValues, ValidationSchema } from './EstateManagersSetupFormConfig'
import authService from '../../../../../../Services/authService'
import { ErrorHandler } from '../../../../../../Helpers/ErrorHandler'
import { HeaderMeta, Stepper } from '../../../../../../Components'
import { Step } from '../../../../../../Components/Buyer/Stepper/Step/Step'
import { Footer } from '../../../../../../Layout'
import { EstateManagersCompanyInformation, EstateManagersPersonalInformation } from '../../Components/Index' 
import whiteLogo from "../../../../../../Assets/Images/logo-white.png"  

  
const totalSteps = 2;

const EstateManagersSetupOtp = () => {   
  const [currentStep, setCurrentStep] = useState<number>(1); 
  const [visitedSteps, setVisitedSteps] = useState<number[]>([1]);
 
  const [submit, setSubmit] = useState(false)
  const [error, setError] = useState<any>(null)

  const navigate = useNavigate()
  
  

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    window.scrollTo(0, 0)
  }, []) 


  useEffect(()=>{
    scroll(0,20)
},[currentStep])

const showPreviousBtn =  ()=> {
    if (visitedSteps?.length>1) {
        return true;
    }

    return false;
}

const showNextBtn =  ()=> {
    if (visitedSteps?.length<totalSteps) {
        return true;
    }

    return false;
}


const showSubmitBtn =  ()=> {
    if (visitedSteps?.length===totalSteps) {
        return true;
    }

    return false;
}


const handleNext = useCallback(()=>{
       
    // add new step to list of visited steps
       setVisitedSteps(oldVisitedSteps=> {
           const isStepVisited = oldVisitedSteps.includes(currentStep+1);
           if (isStepVisited) {
            return oldVisitedSteps; 
           }
           oldVisitedSteps.push(currentStep+1)
           return oldVisitedSteps;
       }
       )

        // increase step
       setCurrentStep(oldStep=>oldStep+1)
   
},[currentStep])

const handlePrevious = useCallback(()=>{

   // remove last step
   setVisitedSteps(oldVisitedSteps=> {
       oldVisitedSteps.pop()
       return oldVisitedSteps;
   })

   setCurrentStep(oldStep=>oldStep-1)
},[currentStep])



const formik = useFormik({
    validationSchema:ValidationSchema,
    initialValues:InitialFormValues,
    onSubmit: (values:FormValuesType)=>{ 
        processForm(values)
    }
})

const processForm = async (formData:FormValuesType)=>{ 
    
      ReactGA.event({
        category: "Estate managers module",
        action: "Registration",
        label: "registration",
        value: Number(formData.phoneNumber),
      })
  
      setSubmit(true)
  
        const data:any = {
            ...formData,  
            countryLongName: "Nigeria", 
            emailAddress: formData.emailAddress,   
            phoneNumber: formData.phoneNumber.toString(),    
            role: "developer", 
          }
    
        delete data?.businessEmail 
    

      await authService.registerMortgage(data).then((res) => {
        setSubmit(false) 

        if (res.data.status) {
           localStorage.setItem("temp__reg", JSON.stringify(data))
           
           navigate("/estate-otp", {replace:true})

           toast.success(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
     
        } else { 
            setError(res.data.message)
        }

        

      }, (error:any) => {

        // console.log("error", error.response)
        setSubmit(false)
        ErrorHandler(error,
            (e) => {
              setError(e)
            })
      })
      console.log("form data", data)
     
}

console.log("formik.errors", formik.errors)

  return (
    <React.Fragment>
        <div className="estate-managers-setup">
        <HeaderMeta title="Login"></HeaderMeta>
        <div className="row h-100">
            <div className="col-md-5 d-none d-md-block">
                <div className="login-right">
                    <div className="container">
                        <div className="login-header">
                            <div className="row">
                                <div className="col-md-2 text-end">
                                    <div className="login-back">
                                    <Link to="/"><span className="d-icon d-arrowhead-left shadow"></span></Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="login-logo">
                                        <img src={whiteLogo} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
 
                </div>
            </div>
            <div className="col-md-7 my-auto">
                <div className="login-header d-md-none">
                    <div className="row h-100">
                        <div className="col-2">
                            <div className="login-back">
                            <Link to="/"><span className="d-icon d-arrowhead-left"></span></Link>
                            </div>
                        </div>
                        <div className="col-8 text-start ps-0 my-auto">
                            <div className="login-logo">
                               <img src={Logo} alt=""  className='w-75'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="login-form">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="esetup-title">Set up your Real Estate Developer Account</div> 

                            <div className="stepper-container">
                                <Stepper currentStep={currentStep} visitedStep={visitedSteps} totalSteps={totalSteps}>
                                    <Step currentStep={currentStep} visitedSteps={visitedSteps} 
                                        step={1}
                                    />
                                    <Step currentStep={currentStep} visitedSteps={visitedSteps} 
                                        step={2} 
                                    /> 
                                </Stepper>
                            </div> 

                        <form onSubmit={formik.handleSubmit}  data-aos="fade-up">
                            { currentStep===1 && <EstateManagersCompanyInformation 
                                formikValues={formik.values}
                                handleChange={formik.handleChange} 
                                handleBlur= {formik.handleBlur}
                                formikErrors={formik.errors} 
                                formikTouchedValues={formik.touched}>
                                </EstateManagersCompanyInformation>}

                            { currentStep===2 && <EstateManagersPersonalInformation
                                firstNameValue={formik.values.firstName}
                                lastNameValue={formik.values.lastName}
                                phoneNumberValue={formik.values.phoneNumber} 
                                passwordValue={formik.values.password}  
                                handleBlur={formik.handleBlur}
                                handleChange={formik.handleChange} 
                                formikErrors={formik.errors} 
                                formikTouchedValues={formik.touched}>
                                </EstateManagersPersonalInformation>}

                                {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                                <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                                    <div className="text-danger">
                                    {error}
                                    </div>
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(null)}></button>
                                </div>}

                                <div className="form-group mt-4"> 
                                    <div className="row">
                                        {showPreviousBtn() && <div className="col-6 col-md-6"> 
                                            <button className="btn btn-login-back fw-bolder" type="button" onClick={handlePrevious}>Back</button>
                                        </div>}
                                        {showNextBtn() && <div className="col-12">
                                            <button className="btn btn-login fw-bolder w-100" type="button" onClick={handleNext}>Continue</button> 
                                    </div>}
                                    {showSubmitBtn() && <div className="col-md-6 col-6">

                                        {!submit && (<button type="submit" className="btn btn-login fw-bolder">Submit</button>)}
                                        {submit && (<button type="submit" className="btn btn-login fw-bolder" disabled>
                                            Please wait
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>)} 
                                        </div>}
                                    </div> 
                                </div> 
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    <Footer></Footer>
    <Toaster></Toaster>
</React.Fragment>
  )
}

export default EstateManagersSetupOtp

import * as yup from "yup";

export const ValidationSchema = yup.object({

})

export const InitialFormValues = {
    businessName: '',
    businessAddress: '',
    cacNumber: '',
    bankName: '',
    accountNumber: '',
}

export interface FormValuesType {
    businessName: string;
    businessAddress: string;
    cacNumber: string;
    bankName: string;
    accountNumber: string;
}

export interface FormErrorsType {
    businessName: string;
    businessAddress: string;
    cacNumber: string;
    bankName: string;
    accountNumber: string;
}
import React from 'react'
import "./EstateHome.scss"
import { EstateHeroSection, PopularProjectItems, TopLocation } from '../../Components/Index'
import { FooterLarge } from '../../../../../../Layout'

const EstateHome = () => {
    return (
        <div className='EstateHome container-xxxxl'>
            <EstateHeroSection></EstateHeroSection>
 
            <TopLocation></TopLocation>
            <div className='container'>
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <div className="title">
                            Popular Projects
                        </div>
                        <div className="description">
                            Checkout projects in our top location
                        </div>
                    </div>
                </div>
            </div>

            <div className="container popular-projects mb-5">
                <PopularProjectItems></PopularProjectItems> 
            </div> 
            <div className="mt-5">
               <FooterLarge module="estate"></FooterLarge> 
            </div>
        </div>
    )
}

export default EstateHome

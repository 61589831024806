
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../Helpers/ErrorHandler";  
import sitesService from "../../../Services/sitesService";

export type TApiResponse = {
  isSuccess: boolean;
  data:any[];
  isLoading :boolean;
};

 
export const useAllSite = ( uuid:string|undefined) => {
  return useQuery(["enterprise_site"], async () =>  await sitesService.index(uuid), {

    enabled: true,
    retry: 1,
    staleTime: Infinity,
    cacheTime: Infinity,
    select: (data:{ data: { data: {
      [x: string]: any; data:any[]
    } } }) => { 
      return data.data.data
    },
    onSuccess (data) {
      // toast.success("success", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    },
    onError (error: any) { 
      if (Array.isArray((error as any).response.data.error)) {
        (error as any).response.data.error.forEach((el: any) =>
          ErrorHandler(error,
            () => {
              toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        );
      } else {
        ErrorHandler(error,
          () => {
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    },
  });
};

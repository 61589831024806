import React, { FC } from 'react'

import "./MiniLoader.scss"

interface propstype {
    show: boolean
  }

const MiniLoader:FC<propstype> = ({ show }) => {
  return (
          <div className={show ? "minloader" : "minloader d-none" }>
              <div className="row h-100">
                  <div className="col-md-12 my-auto">
                      <div className="d-flex justify-content-center align-items-center">
                          <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  )
}

export default MiniLoader

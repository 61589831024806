import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const userService = {

  getProfile: async () => {
    return await axios.get("/profile");
  },
  requestcall: async () => {
    return await axios.get("/profile/requestcall");
  }, 
  upgradeToSeller: async () => {
    return await axios.get("/profile/upgradeToSeller");
  },
  changePassword: async (data: any) => {
    return await axios.put("/profile/newpassword", data);
  },
  changeBusiness: async (data: any) => {
    return await axios.put("/onboarding/businessinfo", data);
  },
  
  upload: async (userUuid: string, data: any) => {
    // { file: data }
    return await axios.put(`/upload?fileUploadCategory=USER_PHOTO&entityUuid=${userUuid}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  sellerDocument: async () => {
    return await axios.get("/profile/seller/document");
  },

  sellerPickUpLocation: async (data:{name:string, address:string}) => {
    return await axios.post("/pickuplocations", data);
  },

  buyerDeliveryLocation: async (data:any) => {
    return await axios.post("/deliverylocations", data);
  },

  buyerDeliveryLocationEdit: async (locationUuid:string, data:any) => {
    return await axios.put(`/deliverylocations/${locationUuid}`, data);
  },

  getBuyerDeliveryLocation: async () => {
    return await axios.get("/deliverylocations");
  },

  getSelectedDeliveryLocation: async (locationUuid:string) => {
    return await axios.get(`/deliverylocations/${locationUuid}`);
  },

  deleteBuyerDeliveryLocation: async (uuid:string) => {
    return await axios.delete(`/deliverylocations/${uuid}`);
  },

  allSellerPickUpLocation: async () => {
    return await axios.get("/pickuplocations");
  },

  deleteSellerPickUpLocation: async (uuid:string) => {
    return await axios.delete(`/pickuplocations/${uuid}`);
  },
  updateSellerPickUpLocation: async (data:any, uuid:string) => {
    return await axios.put(`/pickuplocations/${uuid}`, data);
  },
  getSellerRetailers: async (page: any, sortOrder: any) => {
    return await axios.get(`/profile/linkedbuyers?pageNumber=${page}&sortOrder=${sortOrder}`);
  },
  sendRetailInvite: async (data: {phoneNumber:number}) => {
    return await axios.post(`/profile/linkedbuyers/invite`, data);
  },
  acceptingIV: async (distributorCode: string) => {
    return await axios.patch(`/profile/defaultseller/acceptinvite?sellerUniqueCode=${distributorCode}`)
  },
  unlinkDistributor: async () => {
    return await axios.patch('profile/unlinkseller')
  },
  unlinkBuyer: async (userUuid:string) => {
    return await axios.patch(`/profile/unlinkbuyer?userUuid=${userUuid}`)
  },
  requestCall: async () => {
    return await axios.get(`/profile/requestcall`)
  },

}

export default userService

import {Link, useNavigate} from "react-router-dom";
import {FC} from "react";
import "./EnterpriseSubPageTitle.scss";
import BackArrow from  "../../../Assets/Images/icon/backArrow.svg";

type parentsType = {
    title:string,
    url:string
}

interface propsTypes {
    title:string;
    parents:parentsType[];
}

const EnterpriseSubPageTitle: FC<propsTypes> = ({title, parents})=> {
    
    const navigate = useNavigate();
    return (
        <div className='enterpriseSubPageTitle'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item custom-breadcumbs"><Link to="/enterprise/">Home</Link></li>
                    {parents && parents.map(( parent: any, index:number)=>{
                            return (
                                <li key={index} className="breadcrumb-item custom-breadcumbs">
                                    <Link to={`/enterprise/${parent.url}`}>{parent.title}</Link>
                                </li>
                            )
                    })}
                    <li className="breadcrumb-item active custom-breadcumbs-active" aria-current="page">{title}</li>
                </ol>
            </nav>
            <div className="d-flex flex-row  align-items-center">
                <div className="backArrow " onClick={()=>navigate(-1)}>
                    <img src={BackArrow} alt=''/>
                </div>
                <h2>{title}</h2>
            </div>
        </div>
    )
}

export default EnterpriseSubPageTitle;
import React, {FC, useMemo} from "react";
import {  CustomTimeFormat } from "../../../Helpers/TimeConverter";
import "./BuyerPageOrderTrackingCardItem.scss"

type propTypes = {
    itemIcon:string,
    itemTitle:string,
    itemValue:string
}

const BuyerPageOrderTrackingCardItem: FC<propTypes> = ({itemIcon, itemTitle, itemValue}) => {
    
    const formattedTimeAndDate = useMemo(()=>{
        const timeAndDateObjects= CustomTimeFormat(itemValue);
        return `${timeAndDateObjects.day}, ${timeAndDateObjects.date} ${timeAndDateObjects.month} ${timeAndDateObjects.year} ${timeAndDateObjects.hour}:${timeAndDateObjects.minute}:${timeAndDateObjects.second}`
    },[itemValue])    
    
    return (
            <li className={itemValue ? "list-group-item d-flex flex-row order-tracking-card-item align-items-start" : "list-group-item d-flex flex-row order-tracking-card-item-invalid align-items-start"}>
                <div className="d-flex flex-column">
                    <img src={itemIcon} alt='' 
                        className={
                            itemTitle==="Delivery confirmed" && itemValue ? 
                            "item-icon-green-bg" : "item-icon"
                        }
                    />
                    <span className="dotted-vertical-line mt-4"></span>
                </div>
                <div className="d-flex flex-column ms-4">
                    <h4 className="item-title">{itemTitle}</h4>
                    <p className="item-value">{itemValue? formattedTimeAndDate : ''}</p>
                </div>
            </li>
        )
}

export default BuyerPageOrderTrackingCardItem;

import { ADD_TO_CART_QUANTITY } from "../../Constants/CartConstants";

const CartQuantityReducer = (state = 0, action: { type: string; payload: number; }) => {
  switch (action.type) {
    case ADD_TO_CART_QUANTITY:
      return action.payload
    default:
      return state
  }
}

export default CartQuantityReducer;

import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const productService = {
  async getAllCategoryProduct (catUuid, pageNumber, sortOrder) {
    return axios.get(`/products/category/${catUuid}?pageNumber=${pageNumber}&sortOrder=${sortOrder}`);
  },
  async getAllCategory (pageNumber, sortOrder, pageSize) {
    return axios.get(`/products/categories/all?pageNumber=${pageNumber}&sortOrder=${sortOrder}&pageSize=${pageSize}`);
  },
  async getCategoryInfo (productUuid) {
    return axios.get(`/products/categoryInfo/${productUuid}`);
  },
  async getBrandInfo (brandUuid) {
    return axios.get(`/products/brandInfo/${brandUuid}`);
  },

  async getAllBrand () {
    return axios.get('/products/brands/all');
  },

  async getAllProduct (pageNumber, sortOrder, brandUuids = [], categoryUuids = [], locationStates = []) {
    return axios.post(`/products/catalogue/for/guest?pageNumber=${pageNumber}&sortOrder=${sortOrder}`,
      {
        brandUuids: [...brandUuids],
        categoryUuids: [...categoryUuids],
        locationStates: [...locationStates],
        searchWord: ''
      }
    );
  },
  async getAllDistributorProduct (pageNumber, sortOrder) {
    return axios.post(`products/catalogue/for/loggedin?pageNumber=${pageNumber}&sortOrder=${sortOrder}`,
      {
        brandUuids: [],
        categoryUuids: [],
        locationStates: [],
        searchWord: '',
        forOnlyDefaultLinkedSeller: true
      }
    );
  },

  async getAllProductUnauth (pageNumber, sortOrder) {
    return axios.get(`/products/catalogue/for/guest?pageNumber=${pageNumber}&sortOrder=${sortOrder}`,
      {
        brandUuids: [],
        categoryUuids: [],
        locationStates: [],
        searchWord: ''
      }
    );
  },

  async getAllBrandProduct (brandUuid, pageNumber, sortOrder) {
    return await axios.get(`/products/brand/${brandUuid}?pageNumber=${pageNumber}&sortOrder=${sortOrder}`)
  },
  async getSingleProduct (productUuid) {
    return await axios.get(`/products/guest/${productUuid}?productUuid=${productUuid}`);
  },
  async getProduct (productUuid) {
    return await axios.get(`/products/guest/${productUuid}?productUuid=${productUuid}`);
  },
  async deleteProductImage (productUuid, keyFromCloudProvider) {
    return await axios.delete(`/products?productUuid=${productUuid}&keyFromCloudProvider=${keyFromCloudProvider}`);
  },

  async getAll (pageNumber, sortOrder) {
    alert(pageNumber)
    return await axios.get(`/products?pageNumber=${pageNumber}&sortOrder=${sortOrder}`)
  },
  async getAllProductSearch (pageNumber, sortOrder, brandUuids, categoryUuids, locationStates) {
    return await axios.post(`/products/catalogue/for/loggedin?pageNumber=${pageNumber}&sortOrder=${sortOrder}`,
      {
        brandUuids: [
          brandUuids
        ],
        categoryUuids: [
          categoryUuids
        ],
        locationStates: [
          locationStates
        ],
        lga: [],
        searchWord: '',
        forOnlyDefaultLinkedSeller: false
      }
    );
  },
  async getProductByCategory (id, pageNumber, sortOrder) {
    return await axios.get(`/products/category/${id}?pageNumber=${pageNumber}&sortOrder=${sortOrder}`)
  },

  async getProductLocation () {
    return await axios.get('/products/available/locationstates')
  },
  async getProductVariant (productUuid) {
    return await axios.get(`/products/variants/${productUuid}`)
  },

  async getSavedProduct (pageNumber, sortOrder) {
    return await axios.get(`/savedproducts?pageNumber=${pageNumber}&sortOrder=${sortOrder}`)
  },

  async savedProduct (productUuid) {
    return await axios.post(`/savedproducts/${productUuid}`)
  },
  async deleteSavedProduct (productUuid) {
    return await axios.delete(`/savedproducts/${productUuid}`)
  },

  async getProductByFilter (filter, pageNumber, sortOrder) {
    return await axios.post(`/products/catalogue/for/guest?pageNumber=${pageNumber}&sortOrder=${sortOrder}`, filter)
  },

  async createProduct (data) {
    return await axios.post('/products/create', data)
  },
  async updateProduct (productUuid, data) {
    return await axios.put(`/products/${productUuid}`, data)
  },

  async deleteProduct (productUuid) {
    return await axios.delete(`/products/${productUuid}`)
  },

  file: async (proUuid, data) => {
    return await axios.put(`/upload?fileUploadCategory=PRODUCT_PHOTO&entityUuid=${proUuid}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  async getSellerProduct (pageNumber, sortOrder, uuid) {
    return axios.get(`/products/seller/${uuid}?userUuid=${uuid}&pageNumber=${pageNumber}&sortOrder=${sortOrder}`,
      {
        brandUuids: [],
        categoryUuids: [],
        locationStates: [],
        searchWord: ''
      }
    );
  },

  async variant (productUuid, data) {
    return await axios.post(`/products/create/variant/${productUuid}`, data)
  },
  async deactivateProduct (productUuid) {
    return await axios.put(`/products/deactivate/${productUuid}`)
  },
  async activateProduct (productUuid) {
    return await axios.put(`/products/activate/${productUuid}`)
  },
  

}
export default productService

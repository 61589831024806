import axios from 'axios'
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const searchService = {
  async index (data, pageNumber, sortOrder) {
    return axios.get(`/products/search?searchWord=${data.searchWord}`);
    // return axios.post(`/products/search?pageNumber=${pageNumber}&sortOrder=${sortOrder}`, data);
  },

  async searchProduct (text) {
    return axios.get(`/products/search?searchWord=${text}`);
  }

}
export default searchService

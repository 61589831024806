import "./BankAccountItemCard.scss";
import {FC} from "react";

interface propType {
    item:string,
    value:string
}

const BankAccountItemCard: FC<propType> = ({item, value}) =>{
    return (
        <div className="bankAccountItemCard">
            <div className="item">{item}</div>
            <div className="value">{value}</div>
        </div> 
    )
}

export default BankAccountItemCard;

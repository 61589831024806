import React from 'react'
import "./ProductAds.scss" 

import ads8 from "../../../../../Assets/Images/ads8.png"
import ads81 from "../../../../../Assets/Images/ads7-1.png"
import ads6 from "../../../../../Assets/Images/ads6.png"
import { ImageSrc } from '../../../../../Components'
import { Link } from 'react-router-dom'
import ads61 from "../../../../../Assets/Images/ads6-1.png"

const ProductAds = () => {
  return (
    <div className="ps-md-5">
        <div className="card ads-big border-0">
            <div className="card-body p-0"> 
                <Link to="/register">
                  <ImageSrc src={ads6} alt={''} title={''} width={'w-100'}></ImageSrc>

                <div className="ads-element"> 
                   <ImageSrc src={ads61} alt={''} title={''} width={'w-100'}></ImageSrc>
                </div>
                </Link>
            </div>
        </div>

        <div className="card ads-small border-0">
            <div className="card-body p-0">
                <Link to="/enterprise-home">
                    <ImageSrc src={ads8} alt={''} title={''} width={'w-100'}></ImageSrc>
                </Link>

                    <div className="ads-element"> 
                    <ImageSrc src={ads81} alt={''} title={''} width={'w-100'}></ImageSrc>
                    </div>   
                </div>
        </div>
    </div>
  )
}

export default ProductAds

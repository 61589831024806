import React, { FC, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import "./AlertModal.scss"

interface AlertModalPropType {
    showModal: boolean;
    alertType: string;
    message: string;
}

const AlertModal:FC<AlertModalPropType> = ({ showModal, alertType, message }) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  useEffect(() => {
    
    setShow(showModal)

  }, [showModal])
  
  return (
        <div className='alert-modal'>
            <Modal show={show} onHide={handleClose} className="alert-modal-inner-main" dialogClassName="modal-90w" contentClassName="alert-modal-inner">
                <Modal.Header closeButton className='border-0'> </Modal.Header>
                <Modal.Body>
                    <div className='text-center mt-2 mt-md-5'>
                        <div className='bg-primary icon-container rounded-circle shadow-md'><i className='d-icon d-check'></i></div>
                        <div className='alertType mt-3'>{alertType}</div>
                        <div className='contText' dangerouslySetInnerHTML={{ __html: message }}></div>
                        {/* {message} */}
                        <div className="d-grid gap-2 mb-4 mt-md-5 modal-alert-text-font">
                            <Button variant="primary" size="lg" className='btn0' onClick={handleClose}>
                                Done
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
             </Modal>
        </div>
  )
}

export default AlertModal

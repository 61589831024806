import { useAuth } from "../Components/AuthProvider/AuthProvider"


 export const BasicRBAC = (element:string) => {

    const accessWHL:any[] = [
        "Dashboard", 
        "Warehouse-Management", 
        "Product-Count", 
        "AccountStats", 
        "Delivery-Orders"
    ]
   
    const accessWADM:any[] = [
        "Dashboard", 
        "Warehouse-Management", 
        "Product-Count", 
        "AccountStats", 
        "WalletInformation", 
        "AccountStats", 
        "Procurement", 
        "Builder360", 
        "Product-Count", 
        "Expenses", 
        "Reorder-Product", 
        "Dashboard",
        "Products",
        "Warehouse-Management",
        "Order-Management", 
        "Reorder",
        "User-Management",
        "Banking-And-Finance",
        "PLP",
        "Settings",
        "cart",
        "savedList",
        "createWarehouse",
        "allWareHouse",
        "My-Account",
        "Quote-request",
        "enterprice-get-started"
    ]

    const {user} = useAuth()

    if (user.cooperateUserRole === "WARE_HOUSE_LEVEL") { 
        return accessWHL.includes(element) 
    } else {
        return accessWADM.includes(element) 
    }
}
 

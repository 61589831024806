import React, { useState } from 'react'  
import "./EstateManagersPersonalInformation.scss"


  
const EstateManagersPersonalInformation = (prop:any) => {
    const {
        handleChange, 
        handleBlur, 
        formikErrors,
        formikTouchedValues,
        firstNameValue,
        lastNameValue,
        phoneNumberValue,
        passwordValue
    } = prop; 

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  
    return ( 
        <div className='enterprisePersonalInformation'>
            <div className="form-group mb-3">
                <label htmlFor="firstName">First Name</label>
                <input type="text" id="firstName"
                 className={`form-control ${formikErrors.firstName && formikTouchedValues.firstName ? 'form-input-error' :'form-input'}`}  
                 placeholder='e.g John Doe'
                 value={firstNameValue}  onChange={handleChange}  onBlur ={handleBlur}/>
             </div> 
             
              <div className="form-group mb-3">
                <label htmlFor="lastName">Last Name</label>
                <input type="text" id="lastName"
                 className={`form-control ${formikErrors.lastName && formikTouchedValues.lastName ? 'form-input-error' :'form-input'}`}  
                 placeholder='e.g John Doe'
                 value={lastNameValue}  onChange={handleChange}  onBlur ={handleBlur}/>
             </div> 

            <div className="form-group mb-3">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input type="number" id="phoneNumber" 
                 className={`form-control ${formikErrors.phoneNumber && formikTouchedValues.phoneNumber ? 'form-input-error' :'form-input'}`}  
                 value={phoneNumberValue}  onChange={handleChange}  onBlur ={handleBlur}/> 
            </div>   

            <div className="form-group mb-3 password-container">
                <label htmlFor="password-field">Password</label>
                <input
                        className={`form-control ${formikErrors.password && formikTouchedValues.password ? 'form-input-error' :'form-input'}`}  
                        id="password-field"
                        type={!showPassword ? "password" : "text"}
                        placeholder="Password" 
                        name="password"
                        value={passwordValue}  onChange={handleChange}  onBlur ={handleBlur}
                />
                {!showPassword && <button className="btn p-0 field-icon border-0" onClick={() => setShowPassword(true)}><i className="fas fa-eye"></i></button>}
                {showPassword && <button className="btn p-0 field-icon border-0" onClick={() => setShowPassword(false)}><i className="fas fa-eye-slash" aria-hidden="true"></i></button>} 
            </div>


            <div className="form-group mb-3 password-container">
                <label htmlFor="phoneNumber">Confirm Password</label>
                <input
                        className="form-control"
                        id="password-field"
                        type={!showConfirmPassword ? "password" : "text"}
                        placeholder="Password" 
                />
                {!showConfirmPassword && <button className="btn p-0 field-icon border-0" onClick={() => setShowConfirmPassword(true)}><i className="fas fa-eye"></i></button>}
                {showConfirmPassword && <button className="btn p-0 field-icon border-0" onClick={() => setShowConfirmPassword(false)}><i className="fas fa-eye-slash" aria-hidden="true"></i></button>} 
            </div>
  
        </div>
    )
}

export default EstateManagersPersonalInformation

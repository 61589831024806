import React from 'react'
import "./ResourcesDetails.scss"
import cbcast3 from "./../../../../../Assets/Images/cbcast3.jpg"

const ResourcesDetails = () => {
    return (
        <div className='resourcesDetails'>
           <div className="card featured-cast border-0 rounded-0" style={{backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 1.0)), url(' + cbcast3 + ')'}}>
                    <div className="card-body">  
                        <div className='back-arrow'>
                           <i className='d-icon d-arrowhead-left'></i> Go Back
                        </div>  
                        <h2>10 Essential Tools Every Construction Worker Should Have</h2>
                        <div className='post-by'>By CinderBuild</div>
                    </div>
                </div>


                <div className="container my-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                                    
                            <div>Introduction:</div>
                            <div>When it comes to building construction, having the right tools is crucial for efficiency, safety, and quality workmanship. Whether you&apos;re a seasoned construction professional or a DIY enthusiast, having a well-equipped toolbox is essential. In this blog post, we will discuss the ten essential tools that every construction worker should have in their arsenal. From basic hand tools to specialized equipment, let&lsquo;s explore the must-haves for any construction project.</div>
                            <div>
                                <ol>
                                    <li> 
                                        <div>Tape Measure:</div>
                                        <div>
                                            A tape measure is a fundamental tool for accurate measurements. Whether you&apos;re measuring dimensions, marking layout lines, or ensuring precise cuts, a reliable tape measure is indispensable.
                                        </div>
                                    </li>
                                    <li> 
                                        <div>Claw Hammer:</div>
                                        <div> 
                                            A claw hammer is a versatile tool used for driving nails and removing them when necessary. Look for a hammer with a comfortable grip and a balanced weight for better control.
                                        </div>
                                    </li>
                                    <li> 
                                        <div>Screwdriver Set:</div>
                                        <div> 
                                        A set of screwdrivers with various sizes and types of heads is essential for any construction project. Whether it&apos;s tightening screws, assembling furniture, or installing electrical fixtures, a good screwdriver set is a must-have.
                                        </div>
                                    </li>
                                    <li> 
                                        <div>Utility Knife:</div>
                                        <div> 
                                        A utility knife is a handy tool for cutting materials such as drywall, insulation, or packaging. Look for one with a retractable blade and a comfortable handle for easy and safe use.
                                        </div>
                                    </li>
                                    <li> 
                                        <div>Level:</div>
                                        <div> 
                                        A level ensures that surfaces are perfectly horizontal or vertical. It&apos;s crucial for ensuring straight lines, aligning fixtures, and maintaining balance in construction projects.
                                        </div>
                                    </li>
                                    <li> 
                                        <div>Power Drill:</div>
                                        <div> 
                                        A power drill is a versatile tool that can drive screws, drill holes, and even mix materials. Invest in a quality cordless drill with multiple speed settings and a variety of drill bits for maximum flexibility.
                                        </div>
                                    </li>
                                </ol>

                                <div> Conclusion:</div>
                                <div> Having the right tools is essential for successful building construction. The ten essential tools discussed in this blog post provide a solid foundation for any construction project. Remember to invest in high-quality tools, prioritize safety, and always maintain your equipment for optimal performance. With these essential tools by your side, you&apos;ll be well-prepared to tackle any construction challenge that comes your way.</div>
                            </div>
                        
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default ResourcesDetails


import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../Helpers/ErrorHandler";
import { productType } from "../../../Models";
import productService from "../../../Services/productService";

export const useGetProduct = (productUuid: string|undefined) => {
  return useQuery(["productsSingleData"], () => productService.getSingleProduct(productUuid), {
    enabled: false,
    retry: 1,
    select: (data:{data: {data:productType}}) => {
      return data.data.data
    },
    onSuccess (data) {
      // toast.success("success", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    },
    onError (error: any) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          ErrorHandler(error,
            () => {
              toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        );
      } else {
        ErrorHandler(error,
          () => {
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    },
  });
};

import React, { FC } from 'react'
import "./DeliveryAddressItems.scss"
import user from "../../../../../../Assets/Images/user-sketch.png"
import { DeliveryAddressType } from '../../../../../../Models/deliveryAddressType'
 
interface propsTypes {
    addressDetails: DeliveryAddressType;
    index:number;
    selectAddress: (value:DeliveryAddressType)=>void;
    selectedAddress?:DeliveryAddressType | undefined;
    deleteItem : (value:any)=>void,
    setShowEdit : (value:boolean)=>void, 
    setDataResEdit : (value:any)=>void, 
    remoteAddress : boolean;
}

const DeliveryAddressItems:FC<propsTypes> = ({ addressDetails, index, selectAddress, selectedAddress, deleteItem, setShowEdit, setDataResEdit, remoteAddress }) => {
 
  return (
        <div className={selectedAddress?.uuid === addressDetails?.uuid ? "deliveryAddressItems card active" : "deliveryAddressItems card"} onClick={() => selectAddress(addressDetails)}>
            <div className="card-body">
                <div className="row mb-4">
                    <div className="col-4">
                        <div className='label-index'>{index + 1}</div>
                    </div>
                    <div className="col-8">
                        { addressDetails.isDefault === true && <div className="addressType">
                            <button className='btn'>Default Address</button>
                        </div>}
                    </div>
                </div>
              <div className="street-name">
                 {addressDetails.address}
              </div>
              <div className="user-info mt-3">
                <img src={user} alt="" />  {addressDetails.contactFullName},  {addressDetails.contactPhoneNumber}
              </div>
              <div className='button-container'>
                {remoteAddress === true && <button className='btn'  onClick={()=>{ setShowEdit(true); setDataResEdit(addressDetails) }}>Edit</button>}
                <button className='btn' onClick={()=>deleteItem(addressDetails) }>Delete</button>
              </div>
            </div>
        </div>
  )
}

export default DeliveryAddressItems

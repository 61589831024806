import React, { useEffect, useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'; 
import "./AccountVerificationDrawer.scss" 
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas'; 
import { TOGGLE_VERIFY_DRAWER } from '../../../../../../Constants/VerifyConstants'; 
import VerificationDocumentUploadCard from '../VerificationDocumentUploadCard/VerificationDocumentUploadCard';
import { Logger } from '../../../../../../Helpers/Logger';
import toast, { Toaster } from 'react-hot-toast';
import projectService from '../../../../../../Services/projectService';
import { useAuth } from '../../../../../../Components/AuthProvider/AuthProvider';
import authService from '../../../../../../Services/authService';

const AccountVerificationDrawer = () => {
  const dispatch = useDispatch()
  const [images, setImages] = useState<any>([]) 
  const { user } = useAuth() 

  const [uploadProcess, setUploadProcess] = useState<boolean>(false)

  const handleClose = (placement:OffcanvasPlacement|undefined) => dispatch({ type: TOGGLE_VERIFY_DRAWER, payload: { active: false, placement } })

 
  const estateVerify = useSelector((state: {estateVerify:{ active:boolean, placement: OffcanvasPlacement | undefined}}) => state.estateVerify)
 
 
  const changeHandler =  async (event: any, fileName:any) => { 
    Logger("fileName", {fileName, event}); 
    setImages([...images, { file: event.target.files[0], name: fileName }]) 
   };


  const checkIfExist = (fName:string) => { 
    const found = images.some((el: { name: any }) => el.name === fName);
    return found;
  }

  const startUploading = async () => {
    setUploadProcess(true)
    const arraySize = images.length
    for (const [index, currentProductImage] of images.entries()) {
      const formData = new FormData();
      Logger("PRODUCT FINANCE FILE", currentProductImage.file)
      // Update the formData object
      formData.append(
        "file",
        currentProductImage.file
      ); 

      await projectService.file(user.uuid, formData, currentProductImage.name).then((res:any) => {
        
        toast.success("Uploading your documents", { duration: 10000, className: 'bg-white shadow-md text-secondary',  position: "top-right" });
        
        if (index === arraySize - 1) { 
          handleClose(estateVerify.placement)
          setUploadProcess(false)
          toast.success("Documents uploaded succesfully and pending verification and approval", { duration: 20000, className: 'bg-white shadow-md text-dark', position: "top-right" });
 
        }
      }, (error:any) => { 
        setUploadProcess(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        Logger("IMAGE UPLOAD ERROR", error.response)
      })
    }
  }

const [status, setStatus] = useState<string>()
  const getStatus = async () => {
    await authService.verifyActivation().then((response)=>{ 
      setStatus(response.data.data)
    }, error => {
      setUploadProcess(false)
      toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" }); 
    })
  }

  useEffect(()=>{
    getStatus()
  }, [])

  return (
      <Offcanvas show={estateVerify.active} onHide={() => handleClose(estateVerify.placement)} placement={estateVerify.placement} className={estateVerify.placement === "bottom" ? 'AccountVerificationDrawer h-auto' : "AccountVerificationDrawer"} responsive={estateVerify.placement === "bottom" ? "lg" : ""}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="border-bottom pb-4">Account Verification</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body> 
          <div className="container-fluid">
              <div className="items-in-cart title">
             Kindly provide all information to get your account verified.
              </div>
          </div>

          <div className="container-fluid mt-3">

          {status ==="pending" && <div className="text-danger fw-bolder">
                Your request for account activation is currently under review
            </div>}

            {status ==="active" && <div className="text-success fw-bolder">
                Your account activation request has already been approved
            </div>}

            <VerificationDocumentUploadCard title="6 Months Bank Statement"
                  changeHandler={changeHandler}
                  description="Any one of the following is acceptable: National ID Card, Voters card, Nigerian International Passport or Drivers license."
                  name="idcard"
                  doc="BANK_STATEMENT"
                  checkIfExist={checkIfExist}
                  
                />

            <VerificationDocumentUploadCard title="CAC 7"
                  changeHandler={changeHandler}
                  description="Any one of the following is acceptable: National ID Card, Voters card, Nigerian International Passport or Drivers license."
                  name="idcard"
                  doc="CAC_CERTIFICATE"
                  checkIfExist={checkIfExist}
                />

            <VerificationDocumentUploadCard title="Government Approved ID Card"
                  changeHandler={changeHandler}
                  description="Any one of the following is acceptable: National ID Card, Voters card, Nigerian International Passport or Drivers license."
                  name="idcard"
                  doc="GOVERNMENT_APPROVED_ID"
                  checkIfExist={checkIfExist}
                />
              
          <VerificationDocumentUploadCard title="Recent Utility Bill"
                changeHandler={changeHandler}
                description="Any one of the following is acceptable: National ID Card, Voters card, Nigerian International Passport or Drivers license."
                name="idcard"
                doc="RECENT_UTILITY_BILL"
                checkIfExist={checkIfExist}
              />
              
            <button className='btn verify-btn' onClick={startUploading} disabled={uploadProcess || (status !=="inactive")}> {uploadProcess && <span className='spinner-border spinner-border-sm'></span>} Verify Account</button>
          </div> 
        </Offcanvas.Body>
        <Toaster/>
      </Offcanvas>
  )
}

export default AccountVerificationDrawer

import "./SellerNavbar.scss";
import { IoNotificationsOutline } from "react-icons/io5"; 
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../Components/AuthProvider/AuthProvider"; 
import logoutIco from "../../../Assets/Images/logout.png"
import { ModalPrompt } from "../../../Components";
import { useState } from 'react'; 

type Props = {};
const SellerNavbar = (props: Props) => {

  const { user, logout } = useAuth() 
  const [logoutShow, setLogoutShow] = useState(false)
  const navigate = useNavigate()
  
  function logoutNow ():void {
    setLogoutShow(!logoutShow)
  };

  return ( 
    <div className="seller-navbar p-0">
      <nav className="navbar  navbar-expand-lg w-100"> 
            <h3 className="navbar-brand">Dashboard</h3>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02"> 
              
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">   
                 <li className="nav-item">
                    <div className="notif-container">
                      <Link className="text-dark" to="/seller/notification">
                        <IoNotificationsOutline className="notif-icon fa-1x" />
                        <span> Notification </span>
                        <span></span>
                      </Link>
                    </div>
                 </li>

                 <li className="nav-item">
                   <div className="profile-container dropdown-toggle" id='navbarDropdown2' role="button" data-bs-toggle="dropdown" aria-haspopup='true' aria-expanded="false">
                      <span> {user?.firstName.charAt(0)}</span>
                      <div className="profile">
                        {/* <div className="store-name">Glory Store</div> */}
                        <div className="store-name">{user && `Hi ${user?.firstName}`}</div>
                        <div className="user-name">{user?.firstName} {user?.lastName}</div>
                      </div> 
                    </div>
            
                    <ul className="dropdown-menu dropdown-menu-end border-0 shadow" aria-labelledby='navbarDropdown2'>
                      <li><Link className="dropdown-item text-danger" to="#" onClick={logoutNow}> <img src={logoutIco}></img> Log out</Link></li>
                    </ul>
                 </li>
              </ul> 
            </div> 
        </nav>

      <div className="row d-none">
      <div className="col-sm-6">
        <h3 className="logo-text">Dashboard</h3>
      </div>

      <div className="nav-right-container col-sm-6">
        <div className="notif-container">
          <IoNotificationsOutline className="notif-icon" />
          <span> Notification </span>
          <span></span>
        </div>
        <div className="profile-container dropdown-toggle" id='navbarDropdown2' role="button" data-bs-toggle="dropdown" aria-haspopup='true' aria-expanded="false">
          <span> {user?.firstName.charAt(0)}</span>
          <div className="profile">
            {/* <div className="store-name">Glory Store</div> */}
            <div className="store-name">{user && `Hi ${user?.firstName}`}</div>
            <div className="user-name">{user?.firstName} {user?.lastName}</div>
          </div> 
        </div>
 
        <ul className="dropdown-menu dropdown-menu-end border-0 shadow" aria-labelledby='navbarDropdown2'>
          <li><Link className="dropdown-item text-danger" to="#" onClick={logoutNow}> <img src={logoutIco}></img> Log out</Link></li>
        </ul>
      </div> 

      <ModalPrompt 
        showModal={logoutShow} 
        action={()=>{
          logout()
          navigate('/login', { replace: true })
        }} 
        closeModal={()=> setLogoutShow(false)}
        message={'Are you sure you want to logout?'}/>
    </div> 
    </div>
  );
};
export default SellerNavbar;

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "./CartMenu.scss"
import cart from "../../../../Assets/Images/icon/shopping-cart.png"
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../../Config/DBConfig'
import { useDispatch } from 'react-redux';
import { TOGGLE_CART_DRAWER } from '../../../../Constants/CartConstants'

const CartMenu = () => {
  const [cartCount, setCartCount] = useState(0)

  const dispatch = useDispatch()
  const handleShow = () => dispatch({ type: TOGGLE_CART_DRAWER, payload: { active: true, placement: "end" } })

  useLiveQuery(
    async () => {
      const carts = db.cart.toArray()

      setCartCount((await carts)?.length)
    }
  );

  return (
    <React.Fragment>
      <Link className="cartMenu nav-link nav-responsive" to="#" onClick={handleShow}> <img src={cart} alt="" className="nav-icon"/>{cartCount ? <span className='cart-count'>{cartCount}</span> : ""} Cart</Link>
    </React.Fragment>
  )
}

export default CartMenu

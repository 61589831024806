import React, { FC } from 'react'
import { productType } from '../../../../../Models'
// eslint-disable-next-line no-unused-vars
import toast, { Toaster } from 'react-hot-toast';

interface propTypes{
    productDetails:{
        productDetails: productType;
        productUuid: string;
        quantity: string;
    }
    pickupLocations: (e:any,
      productDetails:{
      productDetails: productType;
      productUuid: string;
      quantity: string;
       },
       value:string
      )=>void
}
const PickupLocations:FC<propTypes> = ({ productDetails, pickupLocations }) => {
  return (
        <ul className="list-group mt-2 list-group-flush">
            {productDetails.productDetails.sellerPickupLocations.map((res:{uuid:string, address:string}, index:React.Key) => <li className="list-group-item ps-0" key={index}>

                <input className="form-check-input me-1"
                    name={`pickuplocation${productDetails.productUuid}`}
                    type="radio"
                    value={res.uuid}
                    id={`spickup${productDetails.productUuid}${index}`}
                    onClick={(e:any) => pickupLocations(e, productDetails, res.address)}/>

                <label className="form-check-label stretched-link" htmlFor={`spickup${productDetails.productUuid}${index}`}>{res.address}</label>
            </li>)}
            <Toaster></Toaster>
        </ul>
  )
}

export default PickupLocations

import React, { PropsWithChildren, useState } from 'react'
import "./EnterpriseSimilarProduct.tsx.scss" 
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper"; 
import Skeleton from 'react-loading-skeleton'  
import { useAllProduct } from '../../../../../../../Hooks'; 
import EnterpriseProductItemNoCol from '../ProductItem/EnterpriseProductItemNoCol';

const EnterpriseSimilarProduct = () => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)

  const pagination = {
    clickable: true,
    renderBullet: function (index:any, className:any) {
      // eslint-disable-next-line no-useless-escape
      return '<span class=\"' + className + '\">' + (index + 1) + '</span>';
    }
  }

  const [pageNumber,] = useState(1)
  const [sortOrder,] = useState("DESC")
  const { data: productList, isLoading, isSuccess } = useAllProduct(pageNumber, sortOrder, [], [], [])

  console.log("kkkk", { isSuccess, productList, isLoading })

  function Box ({ children }: PropsWithChildren<unknown>) {
    return (
        <div
            style={{
              border: '1px solid #fff',
              display: 'block',
              lineHeight: 2,
              padding: '0rem',
              marginBottom: '0.1rem',
              width: "100%",
            //   height: 137,
            }}
        >
            {children}
        </div>
    )
  }

  return (
    <section className='esimilarProduct'>
      <div className="bestdeals mb-5 pt-md-5">
      {isSuccess && productList && <div className="container my-4 mt-5">
            <div className="row section-header">
                <div className="col-8 col-sm-11 ps-3">
                <h2>Similar products</h2>
                </div>

                <div className="col-4 col-sm-1 d-flex justify-content-end mt-2 mb-md-4">
                <div className="carousel-control-container text-end position-relative">
                    <button ref={(node) => setPrevEl(node)} className="carousel-control-prev" type="button">
                    <span className="d-icon d-arrowhead-left islarge text-secondary" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                    </button>
                    <button ref={(node) => setNextEl(node)} className="carousel-control-next bg-danger" type="button">
                    <span className="d-icon d-arrowhead-right islarge text-secondary" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                    </button>
                </div>
                </div>
            </div>
        </div>}
       <div className='container'>
        {isSuccess && productList && <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            pagination={pagination}
            // modules={[Pagination]}
            className="mySwiper"
          /// ////
            modules={[Pagination, Navigation]}
            // slidesPerView={3}
            // slidesPerGroup={3}
            loop={true}
            loopFillGroupWithBlank={true}

            navigation={{ prevEl, nextEl }}
          >
            {isSuccess && productList.dataset?.map((res:any, index: React.Key) =>
              (Number(index) < 6 && <SwiperSlide key={index}>
                 <EnterpriseProductItemNoCol data={res}></EnterpriseProductItemNoCol> 
                </SwiperSlide>))}
          </Swiper>}

        {isLoading === true && <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              pagination={pagination}
              className="mySwiper"
              modules={[Pagination, Navigation]}
              loop={true}
              loopFillGroupWithBlank={true}
              navigation={{ prevEl, nextEl }}
            >
            { [...Array(5)].map((res:any, index:React.Key) => <SwiperSlide key={index}>
                <div className="card product-card border-0 shadow-sm">
                    <div className="card-body px-0">
                        <div className="container-fluid">
                            <div className="row p-0">
                                <div className="col-6">
                                  <Skeleton wrapper={Box} count={1} height={30} width={30}/>
                                </div>
                                <div className="col-6 text-end">
                                  <Skeleton wrapper={Box} count={1} height={30} width={30}/>
                                </div>
                                <div className="col-12">
                                    <Skeleton wrapper={Box} count={1} height={10} width={30}/>
                                    <Skeleton wrapper={Box} count={1} height={100} width={'100%'}/>
                                </div>
                                <div className="col-12">
                                  <h4 className="product-title">
                                    <Skeleton wrapper={Box} count={1} height={20}/>
                                  </h4>
                                </div>
                                <div className="col-8">
                                    <div className="price">
                                        <div className="old-price">
                                            <Skeleton wrapper={Box} count={1} height={8} width={30}/>
                                        </div>
                                        <div className="new-price">
                                            <Skeleton wrapper={Box} count={1} height={8} width={30}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="rating">
                                        <Skeleton wrapper={Box} count={1} height={8} width={30}/>
                                    </div>
                                </div>
                                <div className="col-8 mt-2">
                                    <div className="company-name">
                                        <Skeleton wrapper={Box} count={1} height={8} width={90}/>
                                    </div>
                                </div>
                                <div className="col-4 mt-2">
                                    <div className="location">
                                        <Skeleton wrapper={Box} count={1} height={8} width={30}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>)}
            </Swiper>}
        </div>
    </div>
</section>
  )
}

export default EnterpriseSimilarProduct

import React, { FC, useEffect, useState , LegacyRef } from 'react' 
import notificationIcon from "../../../../Assets/Images/icon/notification.svg" 
import "./Notification.scss"

// import { collection, query, orderBy, onSnapshot } from "firebase/firestore"
import { firbaseDB } from '../../../../Services/firebaseInitService'
import { Link } from 'react-router-dom';
import { collection, doc, limit, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider'; 
import { timeFormat } from '../../../../Helpers/TimeFormater';

interface propsType {
    forwardedRef?: LegacyRef<HTMLLIElement>|undefined;
}

const Notification:FC<propsType> = ({ forwardedRef }) => { 
    const [notification, setNotification] = useState([]);
     
    const { user } = useAuth()
    
    const getNotice = async () => {  
         onSnapshot(await query(collection(doc(firbaseDB, "notification_updates", user.uuid), "notifications"), where('isRead', '==', false), orderBy('createdAt', "desc"), limit(3)), 
         (querySnapshot:any)=>{
           const notice = querySnapshot.docs.map((data: { data: any; }) => data.data())
 
            setNotification(notice)

         })

    }
    useEffect(()=>{
        getNotice()
    }, [])
   

    const readReceipt = (data:any) => {  
        const noticeObj:any = query(collection(doc(firbaseDB, "notification_updates", user.uuid), "notifications", data.id ))
       
        updateDoc( noticeObj,  {
             isRead: true  
          });
          }

    
    return (
        <li className="notice-comp nav-item dropdown" ref={forwardedRef}>
            <a className="nav-link nav-responsive dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img src={notificationIcon} alt="" className="nav-icon"/>
            Notification
            </a>
            <ul className="dropdown-menu shadow-sm" aria-labelledby="navbarDropdown3">
                {notification.length>0 && notification.map((res:any, index)=><li key={index} className="dropdown-item w-100" > 
                    <div className="row">
                        <div className="col-2">
                            <button className='btn p-0' onClick={() => readReceipt(res)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0019 22.2517C11.1819 22.2517 10.3619 21.9917 9.68185 21.4817L4.47186 17.5817C3.50186 16.8517 2.92188 15.6917 2.92188 14.4817V1.76172H21.0818V14.4817C21.0818 15.6917 20.5019 16.8517 19.5319 17.5817L14.3219 21.4817C13.6419 21.9917 12.8219 22.2517 12.0019 22.2517ZM4.42188 3.25172V14.4717C4.42188 15.2117 4.78186 15.9217 5.37186 16.3717L10.5818 20.2717C11.4218 20.9017 12.5919 20.9017 13.4319 20.2717L18.6419 16.3717C19.2319 15.9217 19.5919 15.2117 19.5919 14.4717V3.25172H4.42188Z" fill="#292D32"/>
                                <path d="M22 3.25H2C1.59 3.25 1.25 2.91 1.25 2.5C1.25 2.09 1.59 1.75 2 1.75H22C22.41 1.75 22.75 2.09 22.75 2.5C22.75 2.91 22.41 3.25 22 3.25Z" fill="#292D32"/>
                                <path d="M16 8.75H8C7.59 8.75 7.25 8.41 7.25 8C7.25 7.59 7.59 7.25 8 7.25H16C16.41 7.25 16.75 7.59 16.75 8C16.75 8.41 16.41 8.75 16 8.75Z" fill="#292D32"/>
                                <path d="M16 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H16C16.41 12.25 16.75 12.59 16.75 13C16.75 13.41 16.41 13.75 16 13.75Z" fill="#292D32"/>
                            </svg> 
                            </button>
                        </div>
                        <div className="col-10">
                            <div className='text'>
                                {res.title}
                             </div>
                            <div className='date'>
                               {timeFormat(res.createdAt.seconds)} 
                            </div>
                        </div>
                    </div> 
                </li> )}

                <li className="dropdown-item w-100 notice-bottom"> 
                  <Link to="/enterprise/notification">See All Notifications</Link> 
                </li>
            </ul>
        </li>
    )
}

export default Notification

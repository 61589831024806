import React, { useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'; 
import "./Upload.scss"
import { Logger } from '../../../../../../../Helpers/Logger';
import { HorizontalLoader } from '../../../../../../../Components';
import invoiceService from '../../../../../../../Services/invoiceService';

export const Upload = () => {
    const [uploadSuccess, setUploadSuccess]  = useState(false)
    const [uploading, setUploading]  = useState(false)
    const inputFile = useRef<any>(null) 
 
    // eslint-disable-next-line no-unused-vars
    const [uploadProgressInfo, setUploadProgressInfo]  = useState({
        progress: 0
      })

    const onButtonClick = () => { 
        inputFile?.current?.click();
      };

    const uploadFile = async (e: any) => {
        setUploading(true) 
        
        const formData = new FormData();
        formData.append(
            "file",
            e.target.files[0]
          ); 

         
 

        await invoiceService.file(formData, "PROCURMENT_LIST", (event: { loaded: number; total: number; }) => {
            setUploadProgressInfo({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((res:any) => {
            setUploadSuccess(true) 
            setUploading(false)
        }, (error:any) => { 
            setUploading(false)
            toast.error(error.response.data.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            Logger("IMAGE UPLOAD ERROR", error.response)
          })
           
    }

    return (
        <div className='upload'>
           {uploadSuccess === false &&  <div className="upload-screen">
                <div className="upload-heading">
                    Upload Procurement List
                </div>

                <div className="upload-desc">
                    You procurement list can be in the following format: .doc .xlsx .pdf
                </div>

                <form encType='multipart/form-data'>
                    <div className="card mx-4 mb-5"> 
                    {uploading === true && <div className="card-body"><HorizontalLoader></HorizontalLoader></div>}
                    {uploading === false && <div className="card-body">
                        <input type='file' id='file' ref={inputFile} name="file" accept="image/*" className="d-none" onChange={uploadFile}/>
                            <div className="text-center upload-icon" onClick={onButtonClick}>
                                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.25">
                                    <path d="M57.2391 23.3574C57.5374 23.9821 57.0569 24.6693 56.3646 24.6693H6.33301C5.78072 24.6693 5.33301 24.2216 5.33301 23.6693V17.1226C5.33301 10.6159 10.613 5.33594 17.1197 5.33594H23.3063C27.653 5.33594 29.013 6.74927 30.7463 9.06927L34.4797 14.0293C35.3063 15.1226 35.413 15.2559 36.9597 15.2559H44.3997C50.0571 15.2559 54.9497 18.5624 57.2391 23.3574Z" fill="#292D32"/>
                                    <path d="M58.6164 29.6607C58.6145 29.1097 58.1673 28.6641 57.6164 28.6641L6.33299 28.665C5.78071 28.665 5.33301 29.1127 5.33301 29.665V44.3983C5.33301 52.265 11.733 58.665 19.5997 58.665H44.3997C52.2663 58.665 58.6663 52.265 58.6663 44.3983L58.6164 29.6607ZM38.6663 44.665H34.1597V49.3317C34.1597 50.425 33.253 51.3317 32.1597 51.3317C31.0397 51.3317 30.1597 50.425 30.1597 49.3317V44.665H25.333C24.2397 44.665 23.333 43.7583 23.333 42.665C23.333 41.5717 24.2397 40.665 25.333 40.665H30.1597V35.9983C30.1597 34.905 31.0397 33.9983 32.1597 33.9983C33.253 33.9983 34.1597 34.905 34.1597 35.9983V40.665H38.6663C39.7597 40.665 40.6663 41.5717 40.6663 42.665C40.6663 43.7583 39.7597 44.665 38.6663 44.665Z" fill="#292D32"/>
                                    </g>
                                </svg> 
                            </div>
                            <div className="label" onClick={onButtonClick}>Drag and drop, or browse your files</div>
                        </div>}
                    </div>
                </form>
            </div>}
            {uploadSuccess === true && <div className="upload-success-screen">
                <div className="success-icon">
                    <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M38.5 13.4844C52.0865 13.4844 63.1191 24.517 63.1191 38.1035C63.1191 51.6901 52.0865 62.7227 38.5 62.7227C24.9135 62.7227 13.8809 51.6901 13.8809 38.1035C13.8809 24.517 24.9135 13.4844 38.5 13.4844Z" stroke="#00C12B" strokeWidth="4"/>
                        <path d="M26.0928 39.1738L33.8379 46.7686L49.4785 30.6016" stroke="#00C12B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg> 
                </div>
                <div className="success-text">
                    Success!
                </div>
                <div className="success-description">
                    You will get a notification <br></br>with invoice soon.
                </div> 
            </div>}
            <Toaster></Toaster>
        </div>
    )
}

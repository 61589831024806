import React, { useEffect, useMemo, useState } from 'react'
import "./ProductByCategory.scss"
import settings from "../../../../Assets/Images/icon/setting-4.png"
import sort from "../../../../Assets/Images/icon/sort.png"
import { useParams } from 'react-router-dom'
import { ProductItem, ProductItemSkeleton } from '../../Components'
import { useFetchCategoryInfo, useFetchCategoryProducts } from '../../../../Hooks'
import HeaderMeta from '../../../../Components/HeaderMeta/HeaderMeta';
import { Pagination } from '../../../../Components' 

const ProductByCategory = () => {
  const { catUuid } = useParams<{catUuid:string|undefined}>()

  const [pageNumber, setPage] = useState(1)

  const [sortOrder,] = useState("DESC")

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number,
    pageSize: number,
    total:number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1
  })

  const { data: dataInfo, refetch: getInfo } = useFetchCategoryInfo(catUuid)
  console.log("dataInfo", dataInfo)

  const { isSuccess, isLoading, isFetching, data: product, refetch: getProduct } = useFetchCategoryProducts(catUuid, pageNumber, sortOrder)

  useEffect(() => {
    getInfo()
    window.scrollTo(0,0)
  }, [catUuid])

  useEffect(() => {
    getProduct()
  }, [pageNumber])

  useMemo(() => {
    isSuccess && setPaginationData({
      pageNumber: product?.pageNumber,
      pageSize: product?.pageSize,
      total: product?.total
    })
  }, [product])

  return (
        <div className='productByCategory mb-5'>
         {dataInfo && <HeaderMeta title={`Category > ${dataInfo.name}`}></HeaderMeta>}
            <div className="container my-2">
                <div className="ps-2">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/">Home</a></li>
                      <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">Category</a></li>
                      <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">{dataInfo && dataInfo.name}</a></li>
                    </ol>
                  </nav>
                </div>

             <div className="prod-by-cat-ads">
              <div className="row h-100">
                <div className="col-md-12">
                 <div 
                 style={{ backgroundImage: 'url(' + dataInfo?.bannerUrl + ')' }}
                 className="large-ads text-center  d-flex justify-content-center align-items-center text-white"> 
                  <img src={dataInfo?.bannerUrl? dataInfo?.bannerUrl: ""} alt={dataInfo?.name} title={dataInfo?.name} className='w-100'/>
                  <h1>{dataInfo && dataInfo.name}</h1>
                 </div>
                </div>
              </div>
            </div>

            <div className="row section-header  mt-5">
                  <div className="col-12 col-md-9 ps-3">
                    <h2><b className="me-1">{isLoading ? <span className='spinner-border spinner-border-sm'></span> : paginationData.total}</b> products available</h2>
                  </div>
                  <div className="col-4 col-md-1 p-0">
                    <button className="btn">
                      <img src={settings} className="nav-icon me-1" alt=""/> Filter
                    </button>
                  </div>
                  <div className="col-4 col-md-1 p-0">
                    <button className="btn p-1">
                      <img src={sort} className="nav-icon me-1" alt=""/> Sorting
                    </button>
                  </div>
                  <div className="col-4 col-md-1 p-0 d-flex align-items-center">
                    <button className="btn btn-popular p-1"> Popularity </button>
                  </div>
                </div>
            </div>
            <div className="container">

                {/* <!--Start Product row--> */}
                <div className="row">
                  {(isLoading === false || isFetching === false) && isSuccess && product?.dataset?.length > 0 && product?.dataset?.map((res, index:React.Key) => <ProductItem data={res} key={index}></ProductItem>)}
                  {(isLoading === true || isFetching === true) && <ProductItemSkeleton cardCount={10}></ProductItemSkeleton>}
                </div>
                {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                    {paginationData.total && <Pagination
                    className="pagination-bar ps-0"
                    currentPage={Number(paginationData.pageNumber)}
                    totalCount={paginationData.total}
                    pageSize={paginationData.pageSize}
                    onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                    }
                </div>}
                  {/* <!--End Product row--> */}
              </div>
             </div>
  )
}

export default ProductByCategory

import { TOGGLE_CART_DRAWER } from "../../Constants/CartConstants";

const CartDrawerReducer = (state = false, action: { type: string; payload: number; }) => {
  switch (action.type) {
    case TOGGLE_CART_DRAWER:
      return action.payload
    default:
      return state
  }
}

export default CartDrawerReducer;

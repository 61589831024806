import React, { FC } from "react";
import "./Statistics.scss";

interface StatisticsPropTypes {
  statsData: any;
  statsLoaading: boolean;
}
const Statistics: FC<StatisticsPropTypes> = ({ statsData, statsLoaading }) => {
  console.log(statsData);
  return (
    <div className="statistics">
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12 stats-card border py-3 rounded mt-1">
            <div className="row">
              <div className="col-2">
                {/* <i className="d-icon d-chart-upward stats-icon-2"></i> */}
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="#FFEAEE" />
                  <path d="M19.5 19.6695V18.6995C19.5 16.4495 21.31 14.2395 23.56 14.0295C26.24 13.7695 28.5 15.8795 28.5 18.5095V19.8895" stroke="#FB607F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20.9998 34H26.9998C31.0198 34 31.7398 32.39 31.9498 30.43L32.6998 24.43C32.9698 21.99 32.2698 20 27.9998 20H19.9998C15.7298 20 15.0298 21.99 15.2998 24.43L16.0498 30.43C16.2598 32.39 16.9798 34 20.9998 34Z" stroke="#FB607F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M27.4955 24H27.5045" stroke="#FB607F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20.4945 24H20.5035" stroke="#FB607F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div className="col-10">
                <div className="stats-label">Total Sales</div>
                <div className="stats-amount">
                  {statsLoaading
                    ? "loading..."
                    : `${statsData.totalOrdersCount}`}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 stats-card border py-3 rounded mt-1">
            <div className="row">
              <div className="col-2">
                {/* <i className="d-icon d-arrow-down stats-icon-1"></i> */}
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="#DCF5FF" />
                  <path d="M34 24V29C34 32 32 34 29 34H19C16 34 14 32 14 29V24C14 21.28 15.64 19.38 18.19 19.06C18.45 19.02 18.72 19 19 19H29C29.26 19 29.51 19.01 29.75 19.05C32.33 19.35 34 21.26 34 24Z" stroke="#10BDFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M29.7514 19.05C29.5114 19.01 29.2614 19 29.0014 19H19.0014C18.7214 19 18.4514 19.02 18.1914 19.06C18.3314 18.78 18.5314 18.52 18.7714 18.28L22.0214 15.02C23.3914 13.66 25.6114 13.66 26.9814 15.02L28.7314 16.79C29.3714 17.42 29.7114 18.22 29.7514 19.05Z" stroke="#10BDFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M34 24.5H31C29.9 24.5 29 25.4 29 26.5C29 27.6 29.9 28.5 31 28.5H34" stroke="#10BDFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

              </div>
              <div className="col-10">
                <div className="stats-label">Revenue</div>
                <div className="stats-amount">
                  {statsLoaading
                    ? "loading..."
                    : `${statsData.totalRevenueCurrencySymbol}${statsData.totalRevenueMajor}`}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 stats-card border py-3 rounded mt-1">
            <div className="row">
              <div className="col-2">
                {/* <i className="d-icon d-check stats-icon-3"></i> */}
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="#E1D8FF" />
                  <path d="M19.5 19.6695V18.6995C19.5 16.4495 21.31 14.2395 23.56 14.0295C26.24 13.7695 28.5 15.8795 28.5 18.5095V19.8895" stroke="#7F59FE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20.9998 34H26.9998C31.0198 34 31.7398 32.39 31.9498 30.43L32.6998 24.43C32.9698 21.99 32.2698 20 27.9998 20H19.9998C15.7298 20 15.0298 21.99 15.2998 24.43L16.0498 30.43C16.2598 32.39 16.9798 34 20.9998 34Z" stroke="#7F59FE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M27.4955 24H27.5045" stroke="#7F59FE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20.4945 24H20.5035" stroke="#7F59FE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div className="col-10">
                <div className="stats-label">Withdrawals</div>
                <div className="stats-amount">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;

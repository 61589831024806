import React, { useState, useEffect } from "react";
import BuyerPageTitleWithoutBreadcumbs from "../../../../../../Components/Buyer/BuyerPageTitleWithougtBreadcumbs/BuyerPageTitleWithoutBreadcumbs"
import SortDropDown from "../../../../../../Components/Buyer/SortDropDown/SortDropDown";
import { WordPluralizer } from "../../../../../../Helpers/WordPluralizer";
import BuyerSavedListCard from "../../Component/BuyerSavedList/BuyerSavedListProductCard/BuyerSavedListCard";
import "./SavedList.scss";
import AddToCart from "../../Component/BuyerSavedList/BuyerSavedListProductCard/AddToCartForm/AddToCart";
import {Pagination} from "../../../../../../Components";
import productService from "../../../../../../Services/productService";
import toast, { Toaster } from 'react-hot-toast';
import { Logger } from '../../../../../../Helpers/Logger'; 

const SavedList = ()=>{

    const [noOfSavedItems, setNoOfSavedItems] = useState(0);
    const [sortOrder, setSortOrder] = useState("ASC") 
    
    const [loadingRequest, setLoadingRequest] = useState(false)    
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState({
        pageNumber: "1",
        pageSize: 20,
        total: 1
    })

    const [savedProducts, setSavedProducts] = useState([]);
    const [refreshList, setRefreshList] = useState(false);

    const getMySavedProduct = async () => {
        setLoadingRequest(true)
        await productService.getSavedProduct(page,sortOrder)
          .then((res:any) => {
            setLoadingRequest(false)
            setSavedProducts(res.data.data.dataset)
            setNoOfSavedItems(res.data.data.dataset.length)
            Logger("getMySavedProduct", res.data.data)
            setPagination({
              pageNumber: res.data.data.pageNumber,
              pageSize: res.data.data.pageSize,
              total: res.data.data.total
            })
          }, (error:any) => {
            setLoadingRequest(false)
            toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    
      useEffect(() => {
        const abortController = new AbortController()
        getMySavedProduct()
        setRefreshList(false)
        return () => { abortController.abort() }
      }, [page,refreshList])

    const sortingOptions = [
        {
            name:"Popularity",
            value:"Popularity"
        },
        {
            name:"Best Deals",
            value:"Best Deals"
        },
        {
            name:"High to Lows",
            value:"DESC"
        },
        {
            name:"Low to High",
            value:"ASC"
        },
        {
            name:"Rating",
            value:"Rating"
        } 
    ];
 
    return (
        <div className="buyer-saved-list">
            <div className="container mt-4">
                <BuyerPageTitleWithoutBreadcumbs title="Saved list"/>
                <div className="mt-4 w-100 d-flex flex-column flex-md-row  justify-content-md-between align-items-start align-items-md-center">
                    <div className="saved-list-item-count">
                        <div className="count">{noOfSavedItems}</div>
                        <div className="count-modifier">saved {WordPluralizer("item", noOfSavedItems)}</div>
                    </div>
                       
                    <div className="d-flex flex-row align-items-center me-4">
                        <SortDropDown 
                            options = {sortingOptions}
                            setSortOrder={setSortOrder}
                        />
                        <div className="sort-value-display">
                            {sortOrder}
                        </div>
                        
                        <button className="btn text-primary btn-link ms-3 p-0">Remove all items</button>
                    </div>
                </div>
                <div className="container mt-4 mb-5">
                <div className="row m-0 p-0 buyer-saved-list-card-list">
                  {
                    savedProducts?.map( (savedItem:any, index:number)=>{
                        return (    
                            <div key = {index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                            <BuyerSavedListCard
                                productBrand={savedItem.brand.name}
                                productName={savedItem.productName}
                                productBrandLogo={savedItem.brand?.logo}
                                productImage={savedItem.images[0]?.url}
                                amount={savedItem.price}
                                productUuid={savedItem.productUuid }
                                ratingInfo= {
                                    {
                                        totalRatingsValue:savedItem.totalRatingsValue,
                                        totalNumberOfRatings:savedItem.totalNumberOfRatings
                                    }
                                }
                                location={savedItem.locationState}
                                setRefreshList={setRefreshList}
                            /> 
                                <AddToCart/>
                           </div>  
                        )
                    })
                  }
 
                    { loadingRequest && <div className="spinner-border rounded-circle" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div> }

                    {/* 
                    {
                        loadingRequest && <>
                            <BuyerSavedListCardSkeleton/>
                            <BuyerSavedListCardSkeleton/>
                            <BuyerSavedListCardSkeleton/>
                            <BuyerSavedListCardSkeleton/>
                            <BuyerSavedListCardSkeleton/>
                            <BuyerSavedListCardSkeleton/>
                        </>
                    } */}
                </div>
                </div>
                {
                    pagination.total >= pagination.pageSize && <div className="pagination w-100 d-flex justify-content-center mb-5">
                        {pagination.total && <Pagination
                        className="pagination-bar ps-0 pt-0 mt-5 mb-5"
                        currentPage={Number(pagination.pageNumber)}
                        totalCount={pagination.total}
                        pageSize={pagination.pageSize}
                        onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                        }
                    </div>
                }
            </div>
            <Toaster></Toaster>
        </div>
    )
}

export default SavedList;
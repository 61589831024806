import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()
 
const statsService = {
  async getAll () {
    return axios.get("/profile/seller/statistics");
  },

}
export default statsService

import React, { useEffect, useState } from "react";
import "./History.scss";
import bankingFinanceService from "../../../../../../../Services/bankingFinanceService";
import { TimeConverter } from "../../../../../../../Helpers/TimeConverter";
import Pagination from "../../../../../../../Components/Pagination/Pagination";
import toast, { Toaster } from "react-hot-toast";
import { MiniLoader } from "../../../../../../../Components";

const History = () => {
    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line no-unused-vars
    const [sortOrder, setSortOrder] = useState("DESC");
    // eslint-disable-next-line no-unused-vars
    const [historyData, setHistoryData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [total, setTotal] = useState(0);
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

    const accountHistory = () => {
        setLoadingRequest(true);
        bankingFinanceService.walletHistory(pageNumber, sortOrder).then(
            (res: any) => {
                setLoadingRequest(false);
                setHistoryData(res.data.data.dataset);
                setPage(res.data.data.pageSize);
                setPageNumber(res.data.data.pageNumber);
                setTotal(res.data.data.total);
                console.log(res);
            },
            (error) => {
                setLoadingRequest(false);
                console.log(error);
                toast.error(error.message, {
                    duration: 20000,
                    className: "bg-danger text-white",
                });
            }
        );
    };

    useEffect(() => {
        accountHistory();
    }, []);

    return (
        <div className="history mt-4">
            <MiniLoader show={loadingRequest} />
            <div className="row">
                <div className="col-8">
                    <div className="history-page-title">Transaction History</div>
                </div>
                <div className="col-2">
                    <div className="dropdown">
                        <button
                            className="btn btn-sm border-0 dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <svg
                                className="me-2"
                                width="18"
                                height="18"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M22 7H16"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 7H2"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10 10.5C11.933 10.5 13.5 8.933 13.5 7C13.5 5.067 11.933 3.5 10 3.5C8.067 3.5 6.5 5.067 6.5 7C6.5 8.933 8.067 10.5 10 10.5Z"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M22 18H18"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M8 18H2"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M14 21.5C15.933 21.5 17.5 19.933 17.5 18C17.5 16.067 15.933 14.5 14 14.5C12.067 14.5 10.5 16.067 10.5 18C10.5 19.933 12.067 21.5 14 21.5Z"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span>Filter</span>
                        </button>
                        <ul
                            className="dropdown-menu shadow border-0"
                            aria-labelledby="dropdownMenuButton1">
                            {/* <li><a className="dropdown-item" href="#">Action</a></li> */}
                        </ul>
                    </div>
                </div>
                <div className="col-2">
                    <div className="dropdown">
                        <button
                            className="btn btn-sm border-0 dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <svg
                                className="me-2"
                                width="18"
                                height="18"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M21 8.25H3C2.59 8.25 2.25 7.91 2.25 7.5C2.25 7.09 2.59 6.75 3 6.75H21C21.41 6.75 21.75 7.09 21.75 7.5C21.75 7.91 21.41 8.25 21 8.25Z"
                                    fill="#292D32"
                                />
                                <path
                                    d="M18 13.25H6C5.59 13.25 5.25 12.91 5.25 12.5C5.25 12.09 5.59 11.75 6 11.75H18C18.41 11.75 18.75 12.09 18.75 12.5C18.75 12.91 18.41 13.25 18 13.25Z"
                                    fill="#292D32"
                                />
                                <path
                                    d="M14 18.25H10C9.59 18.25 9.25 17.91 9.25 17.5C9.25 17.09 9.59 16.75 10 16.75H14C14.41 16.75 14.75 17.09 14.75 17.5C14.75 17.91 14.41 18.25 14 18.25Z"
                                    fill="#292D32"
                                />
                            </svg>
                            <span>Sorting</span>
                        </button>
                        <ul
                            className="dropdown-menu shadow border-0"
                            aria-labelledby="dropdownMenuButton1">
                            {/* <li><a className="dropdown-item" href="#">Action</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className="">
        <div className="container-fluid">
          {historyData.length > 0 &&
            historyData.map((resData: any, index: React.Key) => {
              return (
                <div className="card bg-light border-0 mb-2" key={index}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-2 col-sm-1 p-0 px-md-5">
                        {resData === "out" ? (
                          <img src={received} className="iconImage"></img>
                        ) : (
                          <img src={send} className="iconImage"></img>
                        )}
                      </div>
                      <div className="col-6 col-sm-4 col-md-4 col-lg-5  col-xl-5 p-0 px-xl-5">
                        <b>{resData.description}</b>
                        <div className="timeTrans">
                          {TimeConverter(resData.createdAt)}
                        </div>
                      </div>
                      <div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-0 m-0">
                        <div className="amount p-0">
                          {resData.currencySymbol}
                          {resData.amountMajor}
                        </div>
                        <div className="desc d-sm-none">
                          {resData.type === "external_to_fund_wallet" && (
                            <>
                              <i className="d-icon d-circle fa-1x bg-info text-info rounded-circle me-3"></i>
                              Top up
                            </>
                          )}
                          {resData.type === "wallet_funds_withdrawal" && (
                            <>
                              <i className="d-icon d-circle fa-1x bg-success text-success rounded-circle me-3"></i>
                              Withdraw
                            </>
                          )}
                          {resData.type === "external_to_pay_for_order" && (
                            <>
                              <i className="d-icon d-circle fa-1x bg-danger text-danger rounded-circle me-3"></i>
                              Purchase
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-3 col-sm-4  col-md-3  col-lg-2 col-xl-2 p-0 px-xl-2 d-none d-sm-block">
                        <div className="desc">
                          {resData.type === "external_to_fund_wallet" && (
                            <>
                              <i className="d-icon d-circle fa-1x bg-info text-info rounded-circle me-3"></i>
                              Top up
                            </>
                          )}
                          {resData.type === "wallet_funds_withdrawal" && (
                            <>
                              <i className="d-icon d-circle fa-1x bg-success text-success rounded-circle me-3"></i>
                              Withdraw
                            </>
                          )}
                          {resData.type === "external_to_pay_for_order" && (
                            <>
                              <i className="d-icon d-circle fa-1x bg-danger text-danger rounded-circle me-3"></i>
                              Purchase
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-1 col-sm-1 col-md-1 p-0 px-lg-5">
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i className="d-icon d-more-vertical text-dark"></i>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-lg-end border-0 shadow">
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => alert("Option not enabled")}>
                                <i className="fa fa-trash text-danger"></i>{" "}
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div> */}
            <div className='history-inner mt-4'>
                <table className="table">
                    <tbody>
                        {historyData.length > 0 && historyData.map((resData: any, index: React.Key) => {
                            return (<tr key={index}>
                                <td className='history-column w-100'>
                                    <div className="row w-100">
                                        <div className="col-6 d-flex align-items-center">
                                            {resData === "out"
                                                ? <svg width="17" height="17" className='me-3' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.00141 18.2495C4.81141 18.2495 4.62141 18.1795 4.47141 18.0295C4.18141 17.7395 4.18141 17.2595 4.47141 16.9695L18.4714 2.96945C18.7614 2.67945 19.2414 2.67945 19.5314 2.96945C19.8214 3.25945 19.8214 3.73945 19.5314 4.02945L5.53141 18.0295C5.38141 18.1795 5.19141 18.2495 5.00141 18.2495Z" fill="#00C12B" />
                                                    <path d="M15.27 18.2505H5C4.59 18.2505 4.25 17.9105 4.25 17.5005V7.23047C4.25 6.82047 4.59 6.48047 5 6.48047C5.41 6.48047 5.75 6.82047 5.75 7.23047V16.7505H15.27C15.68 16.7505 16.02 17.0905 16.02 17.5005C16.02 17.9105 15.68 18.2505 15.27 18.2505Z" fill="#00C12B" />
                                                    <path d="M20.5 22.75H3.5C3.09 22.75 2.75 22.41 2.75 22C2.75 21.59 3.09 21.25 3.5 21.25H20.5C20.91 21.25 21.25 21.59 21.25 22C21.25 22.41 20.91 22.75 20.5 22.75Z" fill="#00C12B" />
                                                </svg>
                                                : <svg width="17" height="17" className='me-3' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.5 22.75H20.5C20.91 22.75 21.25 22.41 21.25 22C21.25 21.59 20.91 21.25 20.5 21.25H3.5C3.09 21.25 2.75 21.59 2.75 22C2.75 22.41 3.09 22.75 3.5 22.75Z" fill="#FFA685" />
                                                    <path d="M4.9986 18.2495C5.18859 18.2495 5.37859 18.1795 5.52859 18.0295L19.5286 4.02945C19.8186 3.73945 19.8186 3.25945 19.5286 2.96945C19.2386 2.67945 18.7586 2.67945 18.4686 2.96945L4.4686 16.9695C4.1786 17.2595 4.1786 17.7395 4.4686 18.0295C4.6186 18.1795 4.8086 18.2495 4.9986 18.2495Z" fill="#FFA685" />
                                                    <path d="M19 14.52C19.41 14.52 19.75 14.18 19.75 13.77V3.5C19.75 3.09 19.41 2.75 19 2.75H8.73C8.32 2.75 7.98 3.09 7.98 3.5C7.98 3.91 8.32 4.25 8.73 4.25H18.25V13.77C18.25 14.18 18.59 14.52 19 14.52Z" fill="#FFA685" />
                                                </svg>
                                            }
                                            <div>
                                                <b>{resData.description}</b>
                                                <div className='timeTrans'>{TimeConverter(resData.createdAt)}</div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className='amount p-0'>{resData.currencySymbol}{resData.amountMajor}</div>
                                            <div className='desc d-sm-none'>
                                                {resData.type === 'external_to_fund_wallet' && <><svg width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4" /></svg> Top up</>}
                                                {resData.type === 'wallet_funds_withdrawal' && <><svg width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4" /> </svg> Withdraw</>}
                                                {resData.type === 'external_to_pay_for_order' && <><svg width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#FFA685" /></svg> Purchase</>}
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className='desc'>
                                                {resData.type === 'external_to_fund_wallet' && <><svg className='me-3' width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4" /></svg> Top up</>}
                                                {resData.type === 'wallet_funds_withdrawal' && <><svg className='me-3' width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4" /> </svg> Withdraw</>}
                                                {resData.type === 'external_to_pay_for_order' && <><svg className='me-3' width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#FFA685" /></svg> Purchase</>}
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle border-0" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className='d-icon d-more-vertical text-dark'></i>
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-lg-end border-0 shadow">
                                                    {/* <li><a className="dropdown-item" href="#" onClick={() => alert("Option not enabled")}><i className='fa fa-trash text-danger'></i> Delete</a></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            {historyData.length > 10 && (
                <div className="pagination">
                    {total > 0 && (
                        <Pagination
                            className="pagination-bar"
                            currentPage={pageNumber}
                            totalCount={total}
                            pageSize={page}
                            onPageChange={(page: React.SetStateAction<number>) =>
                                setPage(page)
                            }
                        />
                    )}
                </div>
            )}
            <Toaster />
        </div>
    );
};

export default History;

import React, { FC, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast' 
import { Link, useParams } from 'react-router-dom'
import { EnterprisePageTitle, HorizontalLoader, ImageSrc } from '../../../../../../../Components' 
import wareHouseService from '../../../../../../../Services/wareHouseService'
import "./EnterpriseWareHouseShipToSiteRequestSent.scss"
 
interface propsType {
  module?:string
}
const EnterpriseWareHouseShipToSiteRequestSent:FC<propsType> = ({module}) => { 
    const {warehouseId} = useParams<{warehouseId:string}>()
    const {siteId} = useParams<{siteId:string}>() 
    const [siteRequest, setSiteRequest] = useState(true)
    const [siteOrderItem, setSiteOrderItem] = useState<any>([])
  
    
  
      const getSite = async () => {
        setSiteRequest(true)
        await wareHouseService.getSiteOrder(siteId)
        .then((res) => { 
            setSiteRequest(false)
            console.log("rr 000000",res.data.data)
            setSiteOrderItem(res.data.data)
        }, (error:any) => { 
          setSiteRequest(false)
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
      }


      useEffect(()=>{
        getSite()
        return ()=>toast.dismiss()
      }, [])
 
    console.log("siteOrderItem", siteOrderItem)
    return (
        <div className='enterpriseWareHouseShipToSiteRequestSent'>
             <div className="container">
                <EnterprisePageTitle
                    title={"Ship to site "}
                    parent={"Warehouse > Warehouse 1"}
                    parentUrl={`warehouse-management/${warehouseId}`}
                    showTitle={true}
                />
 
             <div className="row justify-content-center">
                <div className="col-md-7"> 
                {siteRequest ===true && <div className="card mt-5">
                      <div className="card-body mx-md-2"> 

                     <HorizontalLoader></HorizontalLoader>
                      </div>
                     </div>}

                    {siteRequest ===false && <div className="card mt-5">
                      <div className="card-body mx-md-2">  
                          <div className="sit-info mb-4">
                              <div className="site-title">{siteOrderItem[0] && siteOrderItem[0]?.deliverySiteDetails.state} site</div>
                              <div className="site-address">
                                
                                {siteOrderItem[0] && siteOrderItem[0].deliverySiteDetails.address}, 
                                {siteOrderItem[0] && siteOrderItem[0].deliverySiteDetails.state},
                                {siteOrderItem[0] && siteOrderItem[0].deliverySiteDetails.country},

                              </div>
                              <div className="site-contact"> 
                                {siteOrderItem[0] && siteOrderItem[0].deliverySiteDetails.contactFullName},
                                  
                                {siteOrderItem[0] && siteOrderItem[0].deliverySiteDetails.phoneNumber}</div>
                          </div> 
                          <div className="site-product border rounded position-relative p-3">
                          {siteOrderItem?.length > 0 && siteOrderItem?.map((res:any, index:React.Key)=>{
                             return <div  key={index} className="row">
                              <div className="col-10 my-auto">
                                <span className='product-image-container'>
                                  <ImageSrc src={""} alt={res.deliveryItems[0].productName} title={res.deliveryItems[0].productName} width={'product-image'}></ImageSrc>
                                </span>
                                <span className='product-title-container'>{res.deliveryItems[0].productName}</span>  
                              </div>
                              <div className="col-2  my-auto">
                              {res.deliveryItems[0].quantity}
                              </div>
                            </div>  
                              })}
                          </div>
                      </div>
                  </div>}

                  <div className="row mt-5 mb-5">
                    <div className="col-6">
                      <Link to={module && module==="estate"? "/estate-managers/order-management": "/enterprise/order-management"}><button className={module && module==="estate"? "btn delivery-btn delivery-btn-estate":"btn delivery-btn"}>See Delivery Fee</button></Link>
                    </div>
                    <div className="col-6">
                      <Link to={module && module==="estate"? "/estate-managers": "/enterprise"}><button className={module && module==="estate"? "btn go-btn-estate": "btn go-btn"}>Go to Dashboard</button></Link>
                    </div>
                  </div>
                </div>
              </div> 
            </div> 
            <Toaster></Toaster>
        </div>
    )
}

export default EnterpriseWareHouseShipToSiteRequestSent

/* eslint-disable space-before-function-paren */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../../Components/AuthProvider/AuthProvider'
import useLoggedInUser from '../../../../../../Hooks/useLoggedInUser'
import productService from '../../../../../../Services/productService'
import userService from '../../../../../../Services/userService'
import { StoreAllProduct } from '../../Component' 
import "./SellerStore.scss"
import StoreBanner from '../../Component/SellerStore/StoreBanner/StoreBanner'
 

const SellerStore = () => {
  const [userInfo, setUserInfo] = useState<any>({
    firstName: '',
    lastName: '',
    businessProfile: {
      businessName: '',
      cacNumber: '',
      businessAddress: ''
    }
  })
  const [productList, setProductList] = useState<any>([])
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1)
  const [loadingReqest, setLoadingrRequest] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [sortOrder, setSortOrder] = useState("DESC")
  const [paginationData, setPaginationData] = useState({
    pageNumber: "1",
    pageSize: 20,
    total: 1
  })

  const { user, logout } = useAuth()
  const navigate = useNavigate()

  function logoutNow(): void {
    logout()
    navigate('/login', { replace: true })
  }; 
  const getProductList = (pageNumber: number, sortOrder: string) => {
    setLoadingrRequest(true)
    productService.getSellerProduct(pageNumber, sortOrder, user.uuid)
      .then((res: any) => {
        setProductList(res.data.data.dataset)
        setPaginationData({
          pageNumber: res.data.data.pageNumber,
          pageSize: res.data.data.pageSize,
          total: res.data.data.total
        })
        setLoadingrRequest(false)
        console.warn("All product", res)
      }, (error: any) => {
        setLoadingrRequest(false)
        console.log("All product error", error.response)
        console.log("All product error", error.message)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
      })
  }

  const getUserProfile = async () => {
    if (user) {
      await userService.getProfile()
        .then((res) => {
          setUserInfo(res.data.data)
        }, (error: any) => {
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    } else {
      logoutNow()
    }
  }

  useEffect(() => {
    getUserProfile()
  }, [user])


  useEffect(() => {
    const abortController = new AbortController()
    getProductList(pageNumber, sortOrder)
    abortController.abort()
  }, [pageNumber, sortOrder])

  const setNextPage = (data: any) => {
    setPageNumber(data)
    setProductList([])
  }

  return (
    <div className="seller-store">
      <div className='container'>
        <StoreBanner businessProfile={userInfo.businessProfile} firstName={userInfo.firstName} lastName={userInfo.lastName} />
        <StoreAllProduct productList={productList} paginationData={paginationData} loadingReqest={loadingReqest} setNextPage={setNextPage} />
      </div>
      <Toaster />
    </div>
  )
}

export default SellerStore

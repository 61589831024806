import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()


const onBoardingService = {
  async businessInfo (payload) {
    return axios.put("onboarding/businessinfo", { ...payload })
  }
}

export default onBoardingService;
import React, { FC } from 'react'
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas'
import { useSelector } from 'react-redux'
import CookieConsent from "react-cookie-consent";
import { FooterLarge, Header, MobileHeader } from '../../../Layout'
import { CartDrawer } from '../../Frontend/Components'
import './Wrapper.css' 

interface PropTypes {
  children: React.ReactNode
}

const Wrapper:FC<PropTypes> = ({ children }) => {
  const cartDrawer = useSelector((state: {cartDrawer:{active:boolean, placement: OffcanvasPlacement | undefined}}) => state.cartDrawer)

  return ( 
    <React.Fragment> 
      <Header/>
      <MobileHeader/>
      {cartDrawer.active && <CartDrawer></CartDrawer>}
        { children }
      <FooterLarge/>

      <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="cinderbuildCookies"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      Notice. CinderBuild uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our Privacy Policy and our cookies usage..{" "}
      <span style={{ fontSize: "10px" }}> </span>

  </CookieConsent>
    </React.Fragment>
  )
}

export default Wrapper

import { Fragment, useEffect, useState , Key } from 'react' 
import "./EstateSubscriberRecurrentPayment.scss"  
import  { Toaster } from 'react-hot-toast';     
import investorService from '../../../../../../Services/investorService'; 
import { TimeConverter } from '../../../../../../Helpers/TimeConverter';
import { Link } from 'react-router-dom';
import ImageSrc from '../../../../../../Components/ImageSrc/ImageSrc';
import { HorizontalLoader } from '../../../../../../Components';
import { recurrentPaymentType } from '../../../../../../Models/recurrentPaymentType';
import { CurrencyFormatter } from '../../../../../../Helpers/CurrencyFormatter';
 
const EstateSubscriberRecurrentPayment = () => {
    
    const [transaction, setTransaction] = useState<Array<recurrentPaymentType>>()  
    const [loadingRequest, setLoadingRequest] = useState(false) 
   

    const getRecurrentPayment = async () => { 
        setLoadingRequest(true)
        await investorService.getRecurrentPayment().then((response)=>{
          console.log("getRecurrentPayment 001", response.data.data) 
          setLoadingRequest(false)
          setTransaction(response.data.data)
        }, (error: any) => { 
            setLoadingRequest(false)
            console.log("getRecurrentPayment error", error)
        })
    }

    useEffect(()=>{
        getRecurrentPayment()
    }, [])

    return ( 
        <div className='EstateSubscriberRecurrentPayment'>
             <div className="container"> 
                <div className="row mt-4">
                    <div className="col-12 col-lg-4 my-auto">
                        <div className="page-title">
                           My Recurrent Payment
                        </div>
                        <div className="page-description">
                           Here is a list of payments due for the current month.
                        </div>
                    </div> 
                </div>


                <div className="enterprise-table">
                    <div className="card mt-2">
                        <div className="card-body p-0">
                            <div className="table-responsive"> 
                              <table className="table rounded border-0 mt-0"> 
                                    <thead className='rounded'>
                                        <tr className='d-none d-md-table-row'> 
                                            <th scope="col">Due Date</th> 
                                            <th scope="col">Project name</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Project Type</th>
                                            <th scope="col">Amount Due</th> 
                                            <th scope="col"></th>  
                                        </tr> 
                                    </thead>
                                    <tbody>
                                    {!loadingRequest && transaction && transaction.map((res:recurrentPaymentType, index: Key) => {
                                        return (
                                            <Fragment key={index}>
                                            <tr className='d-none d-md-table-row'> 
                                                <td> {TimeConverter(res.nextPaymentDate)}</td>
                                                <td> {res.project.name}</td>
                                                <td>  <span className='project-details'>{res.project.details}</span></td> 
                                                <td> <span className='project-type'>{res.project.type}</span></td> 
                                                <td> ₦{CurrencyFormatter(res.amountPaidMinor)}</td> 
                                                <td>
                                                    <div className="btn-group">
                                                        <button type="button" className="btn border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className='d-icon d-more-vertical'></i>
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                                                        
                                                            <li>
                                                                <Link className="dropdown-item" to={`/estate-managers/subscriber-recurrent-payment/${res.uuid}`}>
                                                                    <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.16992 7.4375L11.9999 12.5475L20.7699 7.46747" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        <path d="M12 21.6091V12.5391" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        <path d="M9.92963 2.48L4.58963 5.45003C3.37963 6.12003 2.38965 7.80001 2.38965 9.18001V14.83C2.38965 16.21 3.37963 17.89 4.58963 18.56L9.92963 21.53C11.0696 22.16 12.9396 22.16 14.0796 21.53L19.4196 18.56C20.6296 17.89 21.6196 16.21 21.6196 14.83V9.18001C21.6196 7.80001 20.6296 6.12003 19.4196 5.45003L14.0796 2.48C12.9296 1.84 11.0696 1.84 9.92963 2.48Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        <path d="M16.9998 13.2416V9.5816L7.50977 4.10156" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    </svg> 
                                                                    View Details
                                                                </Link>
                                                            </li> 
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>  
                                            <tr className='d-md-none'>
                                                <td>
                                                    <ImageSrc src={''} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                                                </td>
                                                <td colSpan={4}>
                                                    <div className="row"> 
                                                        <div className='col-12 profile-title'>
                                                        {res.project.name}
                                                        </div>
                                                        <div className='col-12 profile-detail'><span className='project-details'>{res.project.details}</span></div> 
                                                        <div className='col-12 profile-detail'>{ TimeConverter(res.createdAt)}</div>
                                                        <div className='col-12 profile-detail mt-2'><span className='project-status'> ₦{CurrencyFormatter(res.amountPaidMinor)}</span></div> 
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='profile-btn'>
                                                        <Link className="dropdown-item" to={`/estate-managers/subscriber-recurrent-payment/${res.uuid}`}>
                                                            <button type="button" className="btn btn-sm p-1 border d-md-none">
                                                                View
                                                            </button> 
                                                        </Link>
                                                    </div>   
                                                </td>   
                                            </tr>  
                                            </Fragment>) 
                                            })} 
                                            {loadingRequest === false && !transaction &&  <tr>
                                            <td colSpan={9}>
                                                <div className='text-secondary text-center fw-bold'>No Recurrent Payment For the Month</div>
                                            </td>
                                        </tr>}
                                         {loadingRequest === true && <tr>
                                            <td colSpan={9}>
                                                <div className='text-secondary text-center fw-bold'><HorizontalLoader></HorizontalLoader></div>
                                            </td>
                                        </tr>}
                                    </tbody> 
                                </table> 
                             </div> 
                         </div>
                     </div>
                  </div>
  
              </div> 
            <Toaster></Toaster>
        </div>
    )
}

export default EstateSubscriberRecurrentPayment

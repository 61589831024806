import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import orderService from '../../../../../../../Services/orderService'
import "./PLPOrders.scss"
import { CurrencyFormatter } from '../../../../../../../Helpers'
import { Link } from 'react-router-dom'
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter'
import { Pagination } from '../../../../../../../Components'

const PLPOrders = () => {
  // eslint-disable-next-line no-unused-vars
  const [myOrders, setMyOrders] = useState([])

  const [sortOrder,] = useState("DESC")
  const [status,] = useState("ALL")

  const [page, setPage] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)

  // eslint-disable-next-line no-unused-vars
  const [paymentVariantActive, setPaymentVariantActive] = useState<string|any>(null)

  // eslint-disable-next-line no-unused-vars
  const [persona, setPersona] = useState("BUYER")
  const [pagination, setPagination] = useState({
    pageNumber: "1",
    pageSize: 20,
    total: 1
  })

  const getMyOrder = async () => {
    setLoadingRequest(true)
    await orderService.getAllMyOrder(page, sortOrder, persona, status, paymentVariantActive)
      .then((res:any) => {
        setLoadingRequest(false)
        // setMyOrders(res.data.data.dataset)
        // Logger("getMyOrder", res.data.data)
        setPagination({
          pageNumber: res.data.data.pageNumber,
          pageSize: res.data.data.pageSize,
          total: res.data.data.total
        })
        // //Logger("My orders", res)
      }, (error:any) => {
        setLoadingRequest(false)
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    getMyOrder()
    return () => { abortController.abort() }
  }, [page])

  return (
        <div className='plporders my-4'>
           <div className='plporders-inner table-responsive'>
             <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Item name</th>
                    <th scope="col">Order number</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Time</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                {myOrders.length > 0 && myOrders.map((res:{
                  referenceNumber:number,
                  calculatedTotalCostMajor:number,
                  currencySymbol:string,
                  orderItems:any,
                  status:any,
                  orderUuid:string,
                  createdAt:any,
                }, index) => {
                  return (<tr key={index}>
                    <td>
                        {res.orderItems.length > 0 && res.orderItems.map((resInner:any, index:any) => {
                          return <React.Fragment key={index}>{ resInner.productName}{res.orderItems.length !== (index + 1) ? ", " : ""}</React.Fragment>
                        })}
                    </td>
                    <td>
                      #{res.referenceNumber}
                    </td>
                    <td>
                      {res.currencySymbol} {CurrencyFormatter(res.calculatedTotalCostMajor)}
                    </td>

                    <td>
                        <div className='mb-2 processingText'>
                          {(res.status === "CONFIRMED" || res.status === "COMPLETED") && <><i className="fas fa-dot-circle bg-success text-success rounded-circle text-lowercase"></i> {res.status}</>}
                          {(res.status === "CANCELLED_BY_BUYER" || res.status === "CANCELLED_BY_SELLER" || res.status === "ENDED_WITH_DISPUTES") && <><i className="fas fa-dot-circle bg-danger text-danger rounded-circle text-lowercase"></i>  {res.status}</>}
                          {(res.status === "CREATED" || res.status === "IN_PROGRESS" || res.status === "AVAILABLE_FOR_PICKUP") && <><i className="fas fa-dot-circle bg-warning text-warning rounded-circle text-lowercase"></i>  {res.status}</>}
                        </div>
                    </td>
                    <td>
                      { TimeConverter(res.createdAt)}
                    </td>
                    <td><Link to={`/buyer/orders/${res.orderUuid}`}><button className='btn'>Order details</button></Link></td>
                  </tr>)
                })}
                {myOrders.length === 0 && loadingRequest === false && <tr>
                  <td colSpan={6}>
                    <div className='text-secondary text-center fw-bold'>No item found</div>
                  </td>
                </tr>}

                {loadingRequest && <tr>
                  <td colSpan={6}>
                    <div className='text-secondary text-center fw-bold'>
                      <span className='spinner-border spinner-border-lg'></span>
                    </div>
                  </td>
                </tr>}

                </tbody>
              </table>

          </div>

          {pagination.total >= pagination.pageSize && <div className="pagination w-100 d-flex justify-content-center mb-5">
              {pagination.total && <Pagination
              className="pagination-bar ps-0 pt-0 mt-5 mb-5"
              currentPage={Number(pagination.pageNumber)}
              totalCount={pagination.total}
              pageSize={pagination.pageSize}
              onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
              }
          </div>}
        <Toaster></Toaster>
      </div>
  )
}

export default PLPOrders

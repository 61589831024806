import React, { FC } from 'react';
import "./PageNotice.scss"; 
type NoticeType = "success" | "danger" | "warning" | "info";

interface Props {
    noticeType: NoticeType;
    message: string;
    closable: boolean;
    buttonLabel?: string;
    action: ()=>void
}

const PageNotice: FC<Props> = ({ noticeType, closable, message, buttonLabel, action }) => {
   
    const isWarning = noticeType === 'warning';
    const alertClassName = `alert ${isWarning ? 'alert-warning' : 'alert-success'} alert-dismissible fade show`;

    return (
        <div className='PageNotice'>
            <div className={alertClassName} role="alert">
                <div className="row">
                    <div className="col-md-9">
                        {message}
                    </div>
                    <div className="col-md-3 text-end">
                        {!closable && buttonLabel && (
                            <button className='btn btn-notice-warning' onClick={()=>action()}>
                                <svg width="17" height="17" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.88 12.0001L11.29 14.4201L16.12 9.58008" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M11.25 2.44982C11.94 1.85982 13.07 1.85982 13.77 2.44982L15.35 3.80982C15.65 4.06982 16.21 4.27982 16.61 4.27982H18.31C19.37 4.27982 20.24 5.14982 20.24 6.20982V7.90982C20.24 8.29982 20.45 8.86982 20.71 9.16982L22.07 10.7498C22.66 11.4398 22.66 12.5698 22.07 13.2698L20.71 14.8498C20.45 15.1498 20.24 15.7098 20.24 16.1098V17.8098C20.24 18.8698 19.37 19.7398 18.31 19.7398H16.61C16.22 19.7398 15.65 19.9498 15.35 20.2098L13.77 21.5698C13.08 22.1598 11.95 22.1598 11.25 21.5698L9.67 20.2098C9.37 19.9498 8.81 19.7398 8.41 19.7398H6.68C5.62 19.7398 4.75 18.8698 4.75 17.8098V16.0998C4.75 15.7098 4.54 15.1498 4.29 14.8498L2.94 13.2598C2.36 12.5698 2.36 11.4498 2.94 10.7598L4.29 9.16982C4.54 8.86982 4.75 8.30982 4.75 7.91982V6.19982C4.75 5.13982 5.62 4.26982 6.68 4.26982H8.41C8.8 4.26982 9.37 4.05982 9.67 3.79982L11.25 2.44982Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                {buttonLabel}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotice;

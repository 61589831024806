import "./Stepper.scss";
import React, {FC} from "react";
 
interface propTypes {
    currentStep:number;
    visitedStep:number[];
    children:React.ReactNode;
    totalSteps:number
}

const Stepper : FC<propTypes> = ({currentStep,visitedStep,children,totalSteps}) =>{
    
    return (
        <div className="buyer-plp-stepper">
            <div className="stepper-tracker">
                Step {currentStep} of {totalSteps} 
            </div>
            <div className="steps">
                {
                   children
                }
            </div>
        </div>
    )
}

export default Stepper;

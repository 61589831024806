/* eslint-disable no-unused-vars */
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import "./SellerContactDetails.scss";
import SecuritySafeOutlineIcon from "../../../../../../../Assets/Images/icon/security-safe-outline.svg";


interface propType {
    emailAddress: string;
    phoneNumber: string;
}

const SellerContactDetails: FC<propType> = ({ emailAddress, phoneNumber }) => {
    const defaultValues = {
        emailAddress, phoneNumber
    }
    const [activate, setActivate] = useState(true)

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<propType>({
        defaultValues
    });

    const onSubmit = handleSubmit((data) => {
        setActivate(true)
     });

    return (
        <div className="seller-profile-contact-details">
            <form className="form" onSubmit={onSubmit}>
                <div className="form-group my-3">
                    <label htmlFor="emailAddeess"> Email Address</label>
                    <input
                        className="form-control"
                        type="emailAddress"
                        placeholder={emailAddress}
                        disabled={activate}
                        {...register("emailAddress", {
                            pattern: {
                                value: /\S+@\S+.\S+/,
                                message: "Entered value does not match email format",
                            },
                        })}
                    />
                    <div className="text-danger">
                        {" "}
                        {errors.emailAddress && errors.emailAddress.message}{" "}
                    </div>
                </div>

                <div className="form-group mt-4 ">
                    <label htmlFor="phoneNumber"> Phone Number</label>
                    <input
                        className="form-control"
                        type="text"
                        placeholder={phoneNumber}
                        disabled={activate}
                        {...register("phoneNumber", {
                            required: "This field cannot be empty",

                        })}
                    />
                    <div className="text-danger">
                        {" "}
                        {errors.phoneNumber && errors.phoneNumber.message}{" "}
                    </div>

                </div>
                <div className="form-group mt-5 text-center">
                    {activate ?
                        (<button
                            className="form-control submit"
                            onClick={() => setActivate(false)}
                        >
                            <img className="me-2" src={SecuritySafeOutlineIcon} />
                            <span>Request Update</span>
                        </button>) : <button
                            className="form-control submit"
                            type="submit"
                        >
                            Submit
                        </button>}
                </div>
            </form>
        </div>
    );
};

export default SellerContactDetails;

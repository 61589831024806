import React, { useEffect, useState } from 'react'
import "./SearchProducts.scss"
import setting4 from "../../../../Assets/Images/icon/setting-4.png"
import sort from "../../../../Assets/Images/icon/sort.png"
import { ProductItem, ProductItemSkeleton } from '../../Components'
import { Toaster } from 'react-hot-toast';
import { useSearchProduct } from '../../../../Hooks'
import { useParams } from 'react-router-dom';
import { Logger } from '../../../../Helpers/Logger'

const SearchProducts = () => {
  const { searchText: seachKeyword } = useParams<{searchText:string|undefined}>()

  const [paginationData, setPaginationData] = useState<{
    total:number
  }>({
    total: 1
  })

  const { data: product, isFetching, isSuccess, refetch } = useSearchProduct(seachKeyword)

  useEffect(() => {
    isSuccess && setPaginationData({
      total: product?.length
    }) 
    refetch()
  }, [product, seachKeyword])

  Logger("{ data: product, isLoading, isSuccess }", { data: product, isFetching, isSuccess })

  return (
        <div className='products mb-5'>
            <div className="container my-2">

              <div className="ps-2">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/">Home</a></li>
                      <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">All Products</a></li>
                    </ol>
                  </nav>
                </div>

              <div className="row section-header  mt-5">
              {seachKeyword && <h1 className='search-title'>Search Result - {seachKeyword} </h1>}
                <div className="col-12 col-md-9 ps-3">
                <h2><b className="me-1">{isFetching ? <span className='spinner-border spinner-border-sm'></span> : paginationData.total}</b> products available</h2>
                </div>
                <div className="col-6 col-md-1 p-0">
                  <button className="btn">
                    <img src={setting4} className="nav-icon me-1" alt=""/> Filter
                  </button>
                </div>
                <div className="col-3 col-md-1 p-0">
                  <button className="btn p-1">
                    <img src={sort} className="nav-icon me-1" alt=""/> Sorting
                  </button>
                </div>
                <div className="col-3 col-md-1 p-0">
                  <button className="btn btn-popular p-1"> Popularity </button>
                </div>
              </div>
            </div>

            <div className="container">
                <div className="row">
                   {isSuccess && product?.length > 0 && product?.map((res, index:React.Key) => <ProductItem data={res} key={index}></ProductItem>)}
                   {isFetching === true && <ProductItemSkeleton cardCount={10}></ProductItemSkeleton>}
                </div>
            </div>
            <Toaster></Toaster>
        </div>
  )
}

export default SearchProducts

import React, { FC, useEffect } from 'react'
import './BuyerWraperWithoutSideBar.scss'
import EventBus from '../../../../Helpers/EventBus'
import { Logout } from '../../../../Helpers/Logout'
import { BuyerMobileHeader, Footer, Header } from '../../../../Layout'

interface propTypes {
  children: any
}

const BuyerWraperWithoutSideBar:FC<propTypes> = ({ children }) => {
  useEffect(() => {
    EventBus.on("logout", () => {
      Logout()
    });

    return () => {
      EventBus.remove("logout", () => {
        Logout()
      });
    };
  }, []);

  return (
    <React.Fragment>
      <div className='buyerwraper'>

      <BuyerMobileHeader/>
        <div className="row">
          <div className="col-100 ps-0">
             <Header/>
              {/* <BuyerHeader></BuyerHeader> */}
               { children }
          </div>
        </div>
      <Footer></Footer>
      </div>
    </React.Fragment>
  )
}

export default BuyerWraperWithoutSideBar

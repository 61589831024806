import React, { FC, useState } from 'react'
import "./AddWishList.scss"
import heart from "../../../../../Assets/Images/icon/heart.png"
import productService from '../../../../../Services/productService'
import toast from 'react-hot-toast'
import { Logger } from '../../../../../Helpers/Logger'

interface propsType {
    productUuid:string
}

const AddWishList:FC<propsType> = ({ productUuid }) => {
  const [saving, setSaving] = useState(false)
  const [savedProduct, setSavedProduct] = useState<string>("null")

  const saveProduct = async (productUuid:string) => {
    setSaving(true)
    await productService.savedProduct(productUuid)
      .then((res) => {
        Logger("Product saved", res)
        setSaving(false)
        setSavedProduct(productUuid)
        toast.success("Product added to wishlist", { duration: 1000, className: 'bg-white text-secondary', position: "top-right" });
      }, (error) => {
        setSaving(false)
        Logger("saveProduct error", error.response)
        toast.error(error.response.data.message, { duration: 1000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  const deleteProduct = async (productUuid:string) => {
    setSaving(true)
    await productService.deleteSavedProduct(productUuid)
      .then((res) => {
        setSaving(false)
        setSavedProduct("null")
        toast.success("Product removed from wishlist", { duration: 1000, className: 'bg-white text-secondary', position: "top-right" });
      }, (error) => {
        setSaving(false)
        Logger("deleteProduct error", error.response)
        toast.error(error.response.data.message, { duration: 1000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  return (
    <React.Fragment>
     {!saving && savedProduct === 'null' && <button className='btn p-0' onClick={() => saveProduct(productUuid)}>
     <img src={heart} className="wish-icon" alt=""/>
    </button>}

   {!saving && savedProduct === productUuid && (
    <button className='btn p-0' onClick={() => deleteProduct(productUuid)}><i className="d-icon d-heart-like-solid is-medium"></i></button>
   )}

   {saving && <div className="spinner-grow spinner-grow-sm" role="status">
         <span className="sr-only">Loading...</span>
  </div>}
    </React.Fragment>
  )
}

export default AddWishList

import React from 'react'
import "./NewsLetter.scss"

const NewsLetter = () => {
  return (
    <div className="newsletter pb-3 pt-3 pb-md-5 pt-md-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 text-center">
                <h2>Get updates on our latest deals</h2>

                <form action="">
                  <div className="row pt-4">
                    <div className="col-12 col-sm-8 col-md-9">
                      <div className="form-group w-100">
                         <input type="text" placeholder="Enter your email address" className="form-control"/>
                      </div>
                    </div>
                    <div className="col-5 col-sm-4 col-md-3">
                      <button className="btn">Submit</button>
                    </div>
                  </div>
                </form>
            </div>
          </div>
        </div>
    </div>
  )
}

export default NewsLetter

import React, { FC, Fragment, useEffect, useState } from 'react'
import EyeIcon from '../../../../../../../Assets/Icons/EyeIcon'
import FundingIcon from '../../../../../../../Assets/Icons/FundingIcon'
import WalletIcon from '../../../../../../../Assets/Icons/WalletIcon' 
import { CurrencyFormatter } from '../../../../../../../Helpers'
import balanceBg from "../../../../../../../Assets/Images/enterprise/balanceBg.png"
import { EnterpriseWalletWithdrawalModal, EnterpriseWalletFundingModal } from "../.."
import "./EnterpriseAccountBalance.scss" 
import toast, { Toaster } from 'react-hot-toast'
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService'

 
interface propsType {
    myBalance: {
        currency: string,
        currencySymbol: string,
        amountMajor: string
      }
}
const EnterpriseAccountBalance:FC<propsType> = ({ myBalance }) => {

   const [walletType, setWalletType] = useState(1)
   const [showWalletWidthdrawalModal, setShowWalletWidthdrawalModal] = useState(false)
   const [showWalletFundingModal, setShowWalletFundingModal] = useState(false)
  
   const [mySecondaryBalance, setMySecondaryBalance] = useState({
        currency: "",
        currencySymbol: "",
        amountMajor: ""
    })

    const handleWidthdrawalModalOpen = ()=>{
        setShowWalletWidthdrawalModal(true)
    }
 
    const handleFundingModalOpen = ()=>{
        setShowWalletFundingModal(true)
    }
  
    const getMySecondaryBalance = async () => {
         await bankingFinanceService.getMySecondaryBalance()
          .then((res:any) => {
             setMySecondaryBalance(res.data.data) 
          }, (error:any) => {
             toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    
      useEffect(()=>{
        getMySecondaryBalance() 
      }, [])

     
      const [toggleShow, setToggleShow] = useState<boolean>(false)  
      let hiddenAmount = String(myBalance.amountMajor);
      hiddenAmount = hiddenAmount.replace(/./g, "*");

      
  return (
        <div className='enterpriseAccountBalance'>
          <div className="title">Wallet Balance</div>
          <div className="card border-0 p-0 position-relative" style={{backgroundImage: `url(${balanceBg})`}}>
            <div className="card-body pb-0 px-0 overflow-hidden">

            {walletType===1 && <button className="btn btn-wallet-type" onClick={()=>setWalletType(2)}>Secondary Wallet &gt;&gt; </button>}
             {walletType===2 && <button className="btn btn-wallet-type" onClick={()=>setWalletType(1)}>Primary Wallet &gt;&gt; </button>}

                {walletType===1?<div className="container">
                    <div className="wallet-type">Primary Wallet <EyeIcon toggleShow={toggleShow} setToggleShow={setToggleShow}/></div> 
                    <div className="balance mt-4">{myBalance.currencySymbol}
                     {toggleShow ?<Fragment>{CurrencyFormatter(Number(myBalance?.amountMajor) ? Number(myBalance.amountMajor) : 0)}</Fragment> : hiddenAmount}
                    </div>

                     <div className="row mt-5">
                        <div className="col-4">
                            <button className="btn" onClick={handleFundingModalOpen}><FundingIcon/>Fund Wallet</button>
                        </div>
                        <div className="col-4">
                            <button className="btn" onClick={handleWidthdrawalModalOpen}><WalletIcon/>Withdraw</button>
                        </div>
                    </div>
                </div>:<div className="container">
                    <div className="wallet-type">Secondary Wallet <EyeIcon toggleShow={toggleShow} setToggleShow={setToggleShow}/></div>
                     
                    <div className="balance mt-4">{mySecondaryBalance.currencySymbol}
                     {toggleShow ?<Fragment>{CurrencyFormatter(Number(mySecondaryBalance?.amountMajor) ? Number(mySecondaryBalance.amountMajor) : 0)}</Fragment> : hiddenAmount}
                    </div>

                    <div className="row mt-5"> 
                        <div className="col-4">
                            <button className="btn d-none"><WalletIcon/>Withdraw</button>
                        </div>
                    </div>
                </div>}
  
            </div>
          </div>
          <EnterpriseWalletWithdrawalModal showModal={showWalletWidthdrawalModal} hide={(val:boolean) => setShowWalletWidthdrawalModal(val) }></EnterpriseWalletWithdrawalModal>
          <EnterpriseWalletFundingModal showModal={showWalletFundingModal} hide={(val:boolean) => setShowWalletFundingModal(val) }></EnterpriseWalletFundingModal>
          <Toaster></Toaster>
        </div>
  )
}

export default EnterpriseAccountBalance

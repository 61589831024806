import React, { useEffect, useState , FC } from 'react'
import { EnterprisePageTitle, HorizontalLoader, ImageSrc } from '../../../../../../../Components'
import "./EnterpriseWareHouseShipToSiteCheckout.scss"
import mastercard from "../../../../../../../Assets/Images/mastercard.png"
import Visa from "../../../../../../../Assets/Images/Visa.png"
import { useAuth } from '../../../../../../../Components/AuthProvider/AuthProvider'
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService'
import toast, { Toaster } from 'react-hot-toast'
import { Logger } from '../../../../../../../Helpers/Logger' 
import { useForm } from 'react-hook-form' 
import wareHouseService from '../../../../../../../Services/wareHouseService'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { savePaymentType } from '../../../../../../../Helpers/savePaymentType'
import { db } from '../../../../../../../Config/DBConfig'
 
interface propsType {
  module?: string
}
const EnterpriseWareHouseShipToSiteCheckout:FC<propsType> = ({ module }) => {
  const {deliveryRequestUuid} = useParams<{deliveryRequestUuid:string}>()  
  const [siteRequest, setSiteRequest] = useState(true)

  const [siteOrderItem, setSiteOrderItem] = useState<any>()

  // eslint-disable-next-line no-unused-vars
  const [siteUuid, setSiteUuid] = useState<string>("")
  const [wareHouseUuid, setWareHouseUuid] = useState<string>("")
    
  const { user } = useAuth()

  const [paymentVariant, setPaymentVar] = useState<string>("")
  
  const [loadingOrder, setLoadingOrder] = useState<boolean>(false)

  const [walletBalance , setWalletBalance] = useState ({
    currency: "",
    currencySymbol: "",
    amountMajor: ""
  })
  
  const updatePaymentVariant = async (e:any) => {
    setPaymentVar(e.target.value) 
  }
 

  const isWalletFundSufficient = ()=> {
    if (siteOrderItem && (siteOrderItem?.deliveryFeeAmountMajor <= Number(walletBalance.amountMajor))) {
      return true 
    } else {
      return false 
    }
}

 
  const getMyBalance = async () => { 
    await bankingFinanceService.getMySecondaryBalance()
      .then((res:any) => { 
        setWalletBalance(res.data.data)
        Logger("My balance", res.data.data)
      }, (error:any) => { 
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }


  useEffect(()=>{
    getMyBalance()
  },[])

 

 
//   const [siteDataGroupBy, setSiteDataGroupBy] =useState([])
 
//   useEffect(() => { 
//     const key: string = 'site';
//     const data = siteData && siteData.reduce( (rv: any, x:any) => { 
//         (rv[x[key].uuid] = rv[x[key].uuid] || []).push(x);
//         return rv;
//     }, {}); 

//     const refinedData:any = data && Object.keys(data).map( (siteUuid:string) =>  (data[siteUuid]));

//     setSiteDataGroupBy(refinedData)
 
//   }, [siteData])  


//   useEffect(() => {  
//     let newCost = 0
//     siteData && siteData?.map((res:any) => {
//         console.log("tottttt",res)
//         newCost += res.details.product.price * res.quantity;
//         setTotalCost(newCost);
//         return 1
//     })
//   }, [siteData])  
 
 

  const { register, watch } = useForm<{terms:string}>()

  

    const getSite = async () => {
      setSiteRequest(true)
      await wareHouseService.getSingleSiteOrder(deliveryRequestUuid)
      .then((res:any) => { 
          setSiteRequest(false)
        console.log("rr 000000",res.data.data)
        setSiteOrderItem(res.data.data)
        setSiteUuid(res.data.data.deliverySiteDetails.uuid)
        setWareHouseUuid(res.data.data.wareHouseDetails.uuid)
      }, (error:any) => { 
        setSiteRequest(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
    }


    useEffect(()=>{
      getSite()
      return ()=>toast.dismiss()
    }, [])

    const navigate = useNavigate()

    const acceptOrder = async () => {
        setLoadingOrder(true)

        console.log("res payload -----", {status: "DELIVERY_FEE_ACCEPTED", paymentVariant}) 
       await wareHouseService.getSiteOrderAccept(wareHouseUuid, deliveryRequestUuid, {status: "DELIVERY_FEE_ACCEPTED", paymentVariant})
       .then(async (res)=>{
         console.log("res pay ++++", res)
         
         await savePaymentType('CORPORATE_SITE_DELIVERY_FEE').then(async ()=>{

          setLoadingOrder(false)  
           
          if (paymentVariant === "CARD") { 
             toast.success("Order succesful, redirecting to payment terminal", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });  
               
             await db.siteCart.clear().then(()=>{   
                window.location.href = res.data.data.paymentDetails.paymentProviderRedirectUrl; 
             })
 
           } else if (paymentVariant === "WALLET" && res?.data.data.paymentTransactionStatus === "paid") {
            
            toast.success("Payment succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
           
             setTimeout(()=>{ 
                navigate(`/enterprise/payment-completed?action=/enterprise/order-management`, { replace: true })
             }, 2000)

          } else if (paymentVariant === "PAY_ON_DELIVERY" && res.data.status === true && res.data.data.paymentTransactionStatus === "unpaid") {
            toast.success("Pay on delivery Order succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            
            setTimeout(()=>{ 
              navigate(`/enterprise/order-management`, { replace: true })
           }, 3000)

          }

         }) 
        
        

       }, (error:any) => {  
        console.log("res error -----", error) 
        setLoadingOrder(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
    }
 
 

    return (
        <div className='enterpriseWareHouseShipToSiteCheckout'>
             <div className="container">
                <EnterprisePageTitle
                    title={"Ship to site "}
                    parent={"Warehouse > Warehouse 1"}
                    parentUrl="warehouse-management"
                    showTitle={true}
                />

              
              <div className="row">
                <div className="col-md-7"> 
                   <div className="site-card"> 
                        {siteRequest === true && <HorizontalLoader></HorizontalLoader>}
                        <div className="accordion" id="accordionExample">
                       
                             <div  className="accordion-item mb-3 border rounded">
                                <h2 className="accordion-header" id={`headingOne`}>
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne`} aria-expanded="true" aria-controls={`collapseOne`}>
                                Site 1
                                </button>
                                </h2>
                                <div id={`collapseOne`} className={`accordion-collapse collapse show`} aria-labelledby={`headingOne`} data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    {siteOrderItem?.deliveryItems?.length && siteOrderItem?.deliveryItems?.map((resInner:any, index:React.Key)=> <div key={index} className="card site-item mb-3">
                                            <div className="card-body p-0"> 
                                                <table className="table rounded" >
                                                    <tr>
                                                        <td colSpan={2} className="px-3"><ImageSrc src={""} alt={''} title={undefined} width={'site-item-image'}></ImageSrc> Dangote OPC 43 Grade Cement 50 kg Bag</td>
                                                    </tr>
                                                    <tr>
                                                        <td  className="ps-3">Quantity</td>
                                                        <td  className='text-end pe-3'>{resInner.quantity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td  className="ps-3">Site</td>
                                                        <td className='text-end pe-3'>
                                                            <div>{siteOrderItem?.deliverySiteDetails.address}, {siteOrderItem?.deliverySiteDetails.state}, {siteOrderItem?.deliverySiteDetails.country}.</div>
                                                            <div className='mt-0 pt-0'>{siteOrderItem?.deliverySiteDetails.contactFullName},  {siteOrderItem?.deliverySiteDetails.contactPhoneNumber}</div>
                                                        
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>  
                        </div>
                   </div>



                    <div className="card mt-5 payment-card">
                        <div className="card-header">
                           Payment options
                        </div>
                        <div className="card-body px-"> 
                        

                            <ul className="list-group mt-2 list-group-flush ps-1">
                                <li className="list-group-item ps-0 border-0">
                                    <input name="paymentVariant" className="form-check-input me-1" type="radio" value="WALLET" id="firstCheckboxStretched1" onChange={updatePaymentVariant} disabled={!user}/>
                                    <label className="form-check-label stretched-link ps-2" htmlFor="firstCheckboxStretched1">Secondary Wallet</label>
                                </li>
                                <li className="list-group-item ps-0 border-0">
                                    <input name="paymentVariant" className="form-check-input me-1" type="radio" value="CARD" id="secondCheckboxStretched2" onChange={updatePaymentVariant}/>
                                    <label className="form-check-label stretched-link ps-2" htmlFor="secondCheckboxStretched2">Card</label>
                                </li> 
                            </ul>

                            {paymentVariant && paymentVariant === "WALLET" && <div className="wallet pb-4 ">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="title">
                                        Your balance
                                        </div>

                                        <div className="price">
                                        {walletBalance.currencySymbol} {walletBalance.amountMajor}
                                        </div>

                                        {
                                        !isWalletFundSufficient() &&
                                        <>
                                            <div className="alert alert-danger mt-4">
                                            Your balance is low. fund wallet to continue shopping.
                                            </div>
                                            <button className="btn">Fund wallet</button>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>} 
                        </div>
                    </div> 
                </div>




                <div className="col-md-5"> 
                    <div className="card order-summary-card border-0">
                        <div className="card-body px-0s"> 
                            <div className="title">
                            Delivery
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="value">Delivery fee</div>
                                </div>
                                <div className="col-md-6 text-end">
                                    <div className="value">&#8358;{siteOrderItem && siteOrderItem?.deliveryFeeAmountMajor}</div>
                                </div>
                            </div>
                           
                        </div>
                        <div className="card-footer py-4">
                            <div className="row">
                                <div className="col-6">
                                    <div className="title">Total</div>
                                </div>
                                <div className="col-6 text-end">
                                     <div className="amount">&#8358;{siteOrderItem && siteOrderItem?.deliveryFeeAmountMajor}</div> 
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="payment-option">
                        Acceptable payment methods
                        <div className="">
                            <img src={mastercard} className=""/>
                            <img src={Visa} className=""/>
                        </div>
                    </div>

                    <label className='terms'><input type="checkbox" className='me-1 m-0'  {...register("terms", { required: "this field is required" })}/> I accept the   <Link to="/terms-and-conditions" className="text-terms"> Terms & Conditions</Link> and <Link to="/privacy-policy" className="text-terms">Privacy Policy</Link></label>

                    {!loadingOrder &&  <button className={module && module === "estate"? "btn paynow paynow-estate": "btn paynow"} disabled={watch("terms")? false : true} onClick={acceptOrder}>Pay &#8358;{siteOrderItem?.deliveryFeeAmountMajor}</button>}
                    {loadingOrder && <button className={module && module === "estate"? "btn paynow paynow-estate": "btn paynow"} disabled>Pay ₦{siteOrderItem?.deliveryFeeAmountMajor} <span className='spinner-border spinner-border-sm'></span></button>}
             
                </div>
              </div>
            </div>
            <Toaster></Toaster>
        </div>
    )
}

export default EnterpriseWareHouseShipToSiteCheckout

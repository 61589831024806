import React from "react"
import { useLiveQuery } from 'dexie-react-hooks';  
import "./EnterpriseCartItem.scss" 
import { db } from "../../../../../../../Config/DBConfig";
import { EnterpriseCartElement } from "../..";
import { useAllWarehouse } from "../../../../../../../Hooks/Queries/Enterprise/useAllWarehouse";
import { Logger } from "../../../../../../../Helpers/Logger";
import toast, {Toaster} from 'react-hot-toast';

const EnterpriseCartItem = () => {
  const cartData = useLiveQuery(
    async () => await db.cart.toArray()
  );

  const orderDetailList = useLiveQuery<any>(async () => await db.orderDetails?.toArray());

  const { data, isSuccess } = useAllWarehouse()


  const pickupLocations = async (e:any) => {
    Logger("pickupLocations", {e, orderDetailList}) 

    orderDetailList.length > 1 && await db.orderDetails.clear()

    if (orderDetailList.length ===0) {
      await db.orderDetails.add({ wareHouseUuid: e.target.value }).then(function (updated) {
        if (updated) {
          toast.success(`Saved`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        } else {
          toast.error(`error occured`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }
      }) 
    } else {
      await db.orderDetails.update(orderDetailList[0].id, { wareHouseUuid: e.target.value }).then(function (updated) {
        if (updated) {
          toast.success(`Saved`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        } else {
          toast.error(`error occured`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }
      }) 
    }
    
  }

  return (
        <div className='cartItem'>
            <div className="cart-item-list"> 
                {cartData?.length ? cartData.map((res, index:React.Key) => <EnterpriseCartElement key={index} item={res}></EnterpriseCartElement>) : <div className='text-danger'>Cart is empty</div>}
            </div>

              <div className="warehouse">  
                  <div className="card">
                    <div className="card-body"> 
                      <label htmlFor="">Warehouse to keep products</label>
                      <select 
                        className='form-control'  
                        name="pickuplocation"
                        onChange={pickupLocations}>
                        {isSuccess===true && data?.length > 0 && <option>--choose--</option>}
                        {isSuccess===true && data?.length > 0 && data?.map((result: any, index: React.Key)=><option key={index} value={result.uuid}>{result.name}</option>)}
                      </select> 
                  </div>
                </div> 
             </div>
            <Toaster></Toaster>
        </div>
  )
}

export default EnterpriseCartItem

import React, { useEffect, useState } from 'react'
import "./EstateSubscriberProjectsDetails.scss"
import { CbdModal, EstatePageTitle, HorizontalLoader } from '../../../../../../../Components' 
import toast, { Toaster } from 'react-hot-toast'
import { SubscribeToProject } from '../../../Components/Index'
import ImageCollage from '../../../../../../../Components/ImageCollage/ImageCollage' 
import { useParams } from 'react-router-dom';
import { projectDetailsType } from '../../../../../../../Models/projectType'
import projectService from '../../../../../../../Services/projectService'
import { CurrencyFormatter } from '../../../../../../../Helpers' 
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter'

const EstateSubscriberProjectsDetails = () => {

    const [showStatusModal, setShowStatusModal] = useState(false) 

     const { projectUuid } = useParams<{ projectUuid: string|undefined }>()
   
    const [loadingRequest, setLoadingRequest] = useState(false)  

    const [project, setProject] = useState<projectDetailsType>()

    const getProject = async () => { 
        setLoadingRequest(true)
        await projectService.getSingle(projectUuid).then((response)=>{
          console.log("projectService", response) 
          setLoadingRequest(false)
          setProject(response.data.data)
        }, error => { 
            setLoadingRequest(false) 
            toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
     
            console.log("projectService error", error)
        })
    }

    useEffect(()=>{
        getProject()
    }, [projectUuid])
    
    return (
        <div className='EstateSubscriberProjectsDetails'>
            <div className="container">
                <div className="top-header">

                   <EstatePageTitle
                        title={"project.name"}
                        parent="project management"
                        parentUrl="project-management"
                        showTitle={false}
                    /> 
                    {loadingRequest && <HorizontalLoader></HorizontalLoader>}
                   <div className="row mt-4">
                        <div className="col-md-8">  
                        {project && <div className="page-title"> 
                                <h2>{project?.name}</h2> 
                                <span className='ids'>ID: #0011</span>
                                <span className="open">{project?.status}</span>   
                            </div>}
                        </div> 
                        <div className="col-md-4 mt-auto text-md-end">
                        {project && <div className="button-container">
                               
                                <button className='btn btn-subscribe' onClick={()=>setShowStatusModal(true)}>
                                    <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.8009 7.91949V13.0695C19.8009 16.1495 18.0409 17.4695 15.4009 17.4695H6.61093C6.16093 17.4695 5.73093 17.4295 5.33093 17.3395C5.08093 17.2995 4.84094 17.2295 4.62094 17.1495C3.12094 16.5895 2.21094 15.2895 2.21094 13.0695V7.91949C2.21094 4.83949 3.97093 3.51953 6.61093 3.51953H15.4009C17.6409 3.51953 19.2509 4.46953 19.6809 6.63953C19.7509 7.03953 19.8009 7.44949 19.8009 7.91949Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M22.802 10.9206V16.0706C22.802 19.1506 21.042 20.4706 18.402 20.4706H9.61203C8.87203 20.4706 8.20204 20.3706 7.62204 20.1506C6.43204 19.7106 5.62203 18.8006 5.33203 17.3406C5.73203 17.4306 6.16203 17.4706 6.61203 17.4706H15.402C18.042 17.4706 19.802 16.1506 19.802 13.0706V7.92059C19.802 7.45059 19.762 7.03062 19.682 6.64062C21.582 7.04063 22.802 8.38059 22.802 10.9206Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10.9994 13.1394C12.4574 13.1394 13.6394 11.9574 13.6394 10.4994C13.6394 9.04136 12.4574 7.85938 10.9994 7.85938C9.54136 7.85938 8.35938 9.04136 8.35938 10.4994C8.35938 11.9574 9.54136 13.1394 10.9994 13.1394Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M5.28125 8.29883V12.6989" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M16.7227 8.30078V12.7008" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg> 
                                  Subscribe to Project   
                                  {/* || buy moreunits  */}
                                </button>
                            </div>}
                        </div>
                    </div>
                </div> 
            </div>
 
           <div className='container mt-5'>
                {project && <div className="row">
                    <div className="col-md-7"> 
                        <ImageCollage images={project.images}></ImageCollage> 
                    </div>
                    <div className="col-md-5">
                        <div className="price">
                           ₦{CurrencyFormatter(project.costPerSlot)} <span className='unit'>perunit</span>
                        </div>

                        <div className="project-content mt-3">
                            <div className="row">
                                <div className="col-4 mt-3">
                                    <div className="label">Project Type:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{project.type}</div>
                                </div> 
                                <div className="col-4 mt-3">
                                    <div className="label">Project Details:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{project.details}</div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="label">Current Stage:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">-</div>
                                </div>

                                <div className="col-4 mt-3">
                                    <div className="label">Number of Units:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount"> <span className='bg-light'> { project.numberOfSlotSold + project.numberOfSlots }</span></div>
                                </div>

                                <div className="col-4 mt-3">
                                    <div className="label">Number of Units Remainig:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount"> <span className='bg-light'> { project.numberOfSlots }</span></div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="label">Number of Units sold:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount"> <span className='bg-light'> { project.numberOfSlotSold }</span></div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="label">Start Date:</div>
                                </div>
                                <div className="col-8 mt-3"> 
                                    <div className="amount">{ TimeConverter(project.startDate)}</div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="label">Initial Percentage:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{ project.initialInvestmentPercentage}%</div>
                                </div>

                                <div className="col-4 mt-3">
                                    <div className="label">Duration:</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{ project.duration} months</div>
                                </div>

                                <div className="col-4 mt-3">
                                    <div className="label">Address</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{ project.address}</div>
                                </div>
                                <div className="col-4 mt-3">
                                    <div className="label">State</div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="amount">{ project.state}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
 
 
            <CbdModal showModal={showStatusModal} closeModal={ () => setShowStatusModal(false)} title={''}>
                <SubscribeToProject handleClose={() => setShowStatusModal(false)}></SubscribeToProject>
            </CbdModal>
            <Toaster></Toaster>
        </div>
    )
}

export default EstateSubscriberProjectsDetails

import React, { FC, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { HorizontalLoader, ImageSrc } from '../../../../../../../Components';
import { CurrencyFormatter } from '../../../../../../../Helpers';
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter';

interface propType {
    isSuccess: boolean;
    loadingRequest: boolean;
    myOrders: any 
    module?: string
}
 
const AllOrder:FC<propType> = ({isSuccess, myOrders, loadingRequest, module}) => {
    return (
            <table className="table rounded border-0"> 
            <thead className='rounded'>
                <tr className='d-none d-md-table-row'>
                    <th scope="col"></th>
                    <th scope="col">Order ID</th>
                    <th scope="col">Product name</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Date</th> 
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
            {isSuccess && myOrders.length > 0 && myOrders.map((res:{
                referenceNumber:number,
                calculatedTotalCostMajor:number,
                currencySymbol:string,
                orderItems:any,
                status:any,
                orderUuid:string,
                createdAt:any,
                procurementInvoiceUuid:string
                }, index: React.Key | null | undefined) => {
                return (
                    res.procurementInvoiceUuid===null && <Fragment key={index}>
                        <tr className='d-none d-md-table-row'>
                            <td>
                                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.15" cx="20" cy="20" r="20" fill="#F5A623"/>
                                    <path d="M11.1699 15.4375L19.9999 20.5475L28.7699 15.4675" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M20 29.6091V20.5391" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M17.9296 10.48L12.5896 13.45C11.3796 14.12 10.3896 15.8 10.3896 17.18V22.83C10.3896 24.21 11.3796 25.89 12.5896 26.56L17.9296 29.53C19.0696 30.16 20.9396 30.16 22.0796 29.53L27.4196 26.56C28.6296 25.89 29.6196 24.21 29.6196 22.83V17.18C29.6196 15.8 28.6296 14.12 27.4196 13.45L22.0796 10.48C20.9296 9.84 19.0696 9.84 17.9296 10.48Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M24.9998 21.2416V17.5816L15.5098 12.1016" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </td>
                            <td> #{res.referenceNumber}</td>
                            <td> {res.orderItems.length > 0 && res.orderItems.map((resInner:any, index:any) => {
                                return <React.Fragment key={index}>{ resInner.productName}{res.orderItems.length !== (index + 1) ? ", " : ""}</React.Fragment>
                                })}
                            </td>
                            <td>1</td>
                            <td> {res.currencySymbol} {CurrencyFormatter(res.calculatedTotalCostMajor)}</td>
                            <td>
                                {(res.status === "CONFIRMED" || res.status === "COMPLETED") && <span className='created-status'> {res.status}</span>}
                                {(res.status === "CANCELLED_BY_BUYER" || res.status === "CANCELLED_BY_SELLER" || res.status === "ENDED_WITH_DISPUTES") && <span className='created-status'> {res.status}</span>}
                                {(res.status === "CREATED" || res.status === "IN_PROGRESS" || res.status === "AVAILABLE_FOR_PICKUP") &&  <span className='created-status'>{res.status}</span>}
                            </td>
                            <td> { TimeConverter(res.createdAt)}</td> 
                            <td>
                            <div className="btn-group">
                                <button type="button" className="btn border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='d-icon d-more-vertical'></i>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                                <li>
                                    <a className="dropdown-item d-none" href="#">
                                        <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.4405 8.89844C20.0405 9.20844 21.5105 11.0584 21.5105 15.1084V15.2384C21.5105 19.7084 19.7205 21.4984 15.2505 21.4984H8.74047C4.27047 21.4984 2.48047 19.7084 2.48047 15.2384V15.1084C2.48047 11.0884 3.93047 9.23844 7.47047 8.90844" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12 14.9972V3.61719" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M15.3504 5.85L12.0004 2.5L8.65039 5.85" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> 
                                        Pay Now
                                    </a>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to={module && module==="estate"? `/estate-managers/order-details/${res.orderUuid}`:`/enterprise/order-details/${res.orderUuid}`}>
                                        <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.16992 7.4375L11.9999 12.5475L20.7699 7.46747" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12 21.6091V12.5391" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.92963 2.48L4.58963 5.45003C3.37963 6.12003 2.38965 7.80001 2.38965 9.18001V14.83C2.38965 16.21 3.37963 17.89 4.58963 18.56L9.92963 21.53C11.0696 22.16 12.9396 22.16 14.0796 21.53L19.4196 18.56C20.6296 17.89 21.6196 16.21 21.6196 14.83V9.18001C21.6196 7.80001 20.6296 6.12003 19.4196 5.45003L14.0796 2.48C12.9296 1.84 11.0696 1.84 9.92963 2.48Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M16.9998 13.2416V9.5816L7.50977 4.10156" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>

                                        Order Details
                                        </Link>
                                    </li> 
                                </ul>
                            </div>
                            </td>
                        </tr>  
                        <tr className='d-md-none'>
                            <td>
                                <ImageSrc src={''} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                            </td>
                            <td colSpan={4}>
                                <div className="row"> 
                                    <div className='col-12 profile-title'>
                                    {res.orderItems.length > 0 && res.orderItems.map((resInner:any, index:any) => {
                                        return <React.Fragment key={index}>{ resInner.productName}{res.orderItems.length !== (index + 1) ? ", " : ""}</React.Fragment>
                                    })}
                                    </div>
                                    <div className='col-12 profile-detail'>{res.currencySymbol} {CurrencyFormatter(res.calculatedTotalCostMajor)}</div> 
                                    <div className='col-12 profile-detail'>{ TimeConverter(res.createdAt)}</div>
                                </div>
                            </td>
                            <td className='profile-btn'>
                            <Link className="dropdown-item" to={`/enterprise/order-details/${res.orderUuid}`}>
                                <button type="button" className="btn btn-sm p-1 border d-md-none">
                                    View
                                </button> 
                            </Link>
                            </td>   
                        </tr>  
                        </Fragment>) 
                    })}
                    {loadingRequest === false && myOrders?.length === 0 &&  <tr>
                    <td colSpan={9}>
                        <div className='text-secondary text-center fw-bold'>No item found</div>
                    </td>
                </tr>}
            {loadingRequest === true && <tr>
                    <td colSpan={9}>
                        <div className='text-secondary text-center fw-bold'><HorizontalLoader></HorizontalLoader></div>
                    </td>
                </tr>}
            </tbody> 
        </table> 
    )
}

export default AllOrder

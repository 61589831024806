import React, { FC, useState , Fragment } from 'react'
import "./SecondaryWallet.scss"
import EyeIcon from '../../../../../../../Assets/Icons/EyeIcon';
import { CurrencyFormatter } from '../../../../../../../Helpers';
import PriBalanceBg from "../../../../../../../Assets/Images/enterprise/primary-wallet-bg.png"
import FundingIcon from '../../../../../../../Assets/Icons/FundingIcon'; 
 
interface propTyps {
    walletType: number;
    setWalletType: any; 
    myBalance:any;
    nodeRef: any
}

const SecondaryWallet:FC<propTyps> = ({walletType, setWalletType, myBalance, nodeRef }) => { 
   
     // eslint-disable-next-line no-unused-vars
     const [showWalletFundingModal, setShowWalletFundingModal] = useState(false)


    const handleFundingModalOpen = ()=>{
        setShowWalletFundingModal(true)
    }

   
  const [toggleShow, setToggleShow] = useState<boolean>(false)
  let hiddenAmount = String(myBalance.amountMajor);
  hiddenAmount = hiddenAmount.replace(/./g, "*");


    return ( 
        <div className="secondary-wallet card border-0 p-0 position-relative mt-4 mt-sm-5" style={{backgroundImage: `url(${PriBalanceBg})`}}>
            <div className="card-body pb-0 px-0 overflow-hidden">
 
            {walletType===2 && <button className="btn btn-wallet-type-primary-wallet" onClick={()=>setWalletType(1)}>Primary<br/> Wallet &gt;&gt; </button>}

                <div className="container" ref={nodeRef}>
                    <div className="wallet-type-primary-wallet">Secondary Wallet
                     <EyeIcon toggleShow={toggleShow} setToggleShow={setToggleShow}/>
                     </div>

                     <div className="balance-primary-wallet mt-1">{myBalance.currencySymbol}
                     {toggleShow ?<Fragment>{CurrencyFormatter(Number(myBalance?.amountMajor) ? Number(myBalance.amountMajor) : 0)}</Fragment> : hiddenAmount}
                    </div>
                    <div className="row mt-5">
                        <div className="col-4">
                            <button className="btn btn-primary-wallet d-none" onClick={handleFundingModalOpen}><FundingIcon/>Fund Wallet</button>
                        </div> 
                    </div>
                </div>  
            </div>
          </div> 
    )
}

export default SecondaryWallet

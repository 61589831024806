export const ToolTipsHelper = {
    get: () => {
        const loggedIn:any = localStorage.getItem('__cbuN');
        const userData = loggedIn ? JSON.parse(loggedIn) : null;
        const userUuid = userData?.uuid
       const tut = localStorage.getItem("tutorial")? JSON.parse(localStorage.getItem("tutorial") || ""): false
        if (tut && tut.userUuid === userUuid) {
          return tut.status
        } else {
            return false
        }
    },
    set: (userUuid:string) => {
        return localStorage.setItem("tutorial", JSON.stringify({ status: true, userUuid }))
    },
}
 
import { FC } from 'react' 
import "./ProductDescription.scss"

interface propsType{
  productDescription: string
}

const ProductDescription:FC<propsType> = ({productDescription}) => {
  
  return (
        <div>
           {productDescription}
        </div>
  )
}

export default ProductDescription

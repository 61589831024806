import React from 'react'
// eslint-disable-next-line no-unused-vars
import { Navigate, Outlet } from 'react-router-dom'
import SellerWrapper from '../../Pages/Skeleton/WrapperAuth/SellerWrapper/SellerWrapper'

import { useAuth } from '../AuthProvider/AuthProvider'

const ProtectedSeller = () => {

  const { user } = useAuth()  
  
  if (!user) { 
    return <Navigate to="/login" />
  }

  const patchAuth = () => {
     
    switch (user.isCooperate) {
      case true:
        return <Navigate to="/enterprise/dashboard" />
      case false:  
        return <Navigate to="/buyer/" />
      default:
        return <Navigate to="/" />
    }

  }

  switch (user.isSeller) {
    case false:
      return patchAuth()
    case true:
      return <SellerWrapper><Outlet/></SellerWrapper>
    default:
      return <Navigate to="/" />
  }
}

export default ProtectedSeller;

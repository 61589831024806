import React, { useState } from 'react'
import Modal from 'react-bootstrap/esm/Modal'
import toast, { Toaster } from 'react-hot-toast'
import BuyerPageTitle from '../../../../../../../Components/Buyer/BuyerPageTitle/BuyerPageTitle'
import "./WalletWithdrawal.scss"
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService';
import { Logger } from '../../../../../../../Helpers/Logger';
import { useNavigate } from 'react-router-dom';

const WalletWithdrawal = () => { 
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [amount, setAmount] = useState(0)
  const [password, setPassword] = useState("")
  const [withdrawaLRequest, setWithdrawaLRequest] = useState(false);

  const navigate = useNavigate()
  const handleClose = () => setShow(false)
  const handleClose2 = () => setShow2(false)

  const submit = async () => {
    setWithdrawaLRequest(true)

    await bankingFinanceService.walletWithdrawal({ password, amountMajor: amount })
      .then((res) => {
        setWithdrawaLRequest(false)
        Logger(res)
        setShow2(true)
        handleClose()
      }, (error:any) => {
        setWithdrawaLRequest(false)
        Logger(error.response.data.error)
        toast.error(error.response.data.error, { duration: 25000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  return (
        <div className='walletWithdrawal'>
           <div className="container mt-4">
            <BuyerPageTitle title={'Withdraw'} parent={"Banking and Finance"} parentUrl={'banking-and-finance'}></BuyerPageTitle>

               <div className="row justify-content-center mt-5">
                  <div className="col-12 col-sm-6 col-lg-5">
                    <div className="card">
                      <div className="card-header  border-0">
                        <div className='inst'>
                        How much do you want to withdraw from wallet?
                        </div>
                      </div>
                      <div className="card-body border-0">
                        <form action="">
                          <div className="form-group mb-5">
                            <label htmlFor="">Enter Amount (₦)</label>
                            <input type="number" className="form-control" placeholder='₦0.00' onChange={(e:any) => setAmount(e.target.value)}/>
                          </div>
                        </form>
                      </div>
                       <div className="card-footer  border-0">
                             <button className='btn mt-1 w-100' onClick={() => setShow(true)} disabled={!amount}>Withdraw</button>
                        </div>
                      </div>
                    </div>
               </div>
            </div>

            <Modal show={show} onHide={handleClose} contentClassName="customModal fw">
              <Modal.Header closeButton>
                <Modal.Title className="fw-bolder">Authorize Transaction</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="row mt-3 pb-4">
                    <div className="col-12">
                     <div className="form-group mb-5">
                        <label htmlFor="">Enter password to confirm transaction</label>
                        <input type="password" className="form-control" onChange={(e:any) => setPassword(e.target.value)} autoComplete="off"/>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                    {!withdrawaLRequest && <button className='btn apply btn-primary' onClick={submit}>Confirm Transaction</button>}
                         {withdrawaLRequest && <button className="btn apply btn-primary" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Processing request...
                          </button> }
                    </div>
                  </div>
              </Modal.Body>
            </Modal>

            <Modal show={show2} onHide={handleClose2} contentClassName="customModal fw">
              <Modal.Body>
                <div className="container">
                <div className="row justify-content-center mt-4 mb-4">
                  <div className="col-3">
                    <svg width="100%" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M124.688 -3.75V126.562H-5.15625V-3.75H124.688Z" fill="white"/>
                      <g opacity="0.851687">
                      <path d="M60.0002 10.4922C86.8817 10.4922 108.707 32.3179 108.707 59.1994C108.707 86.081 86.8817 107.907 60.0002 107.907C33.1187 107.907 11.293 86.081 11.293 59.1994C11.293 32.3179 33.1187 10.4922 60.0002 10.4922Z" fill="#528CF5" fillOpacity="0.3"/>
                      </g>
                      <path d="M60 17.8984C82.8952 17.8984 101.484 36.4876 101.484 59.3828C101.484 82.278 82.8952 100.867 60 100.867C37.1048 100.867 18.5156 82.278 18.5156 59.3828C18.5156 36.4876 37.1048 17.8984 60 17.8984Z" fill="#3378F3"/>
                      <path d="M40.6641 61.0547L52.7344 72.8906L77.1094 47.6953" stroke="white" strokeWidth="7.03125" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>

                  <div className="col-12 text-center mb-4">

                  <div className='fw-bold'>Success!</div>
                  <div className='details'>You have successfully withdraw N{amount} from your wallet.</div>
                  </div>

                  <div className="col-12">
                    <button className='btn apply btn-primary w-100' onClick={() => { setShow2(false); navigate("/buyer/banking-and-finance", { replace: true }) }}>Done</button>
                  </div>
                </div>
                </div>

              </Modal.Body>
            </Modal>
            <Toaster></Toaster>
        </div>
  )
}

export default WalletWithdrawal

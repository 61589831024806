import React, { FC } from 'react';
import DeliveryIcon from "../../../Assets/Images/icon/location-slected.png";
import "./SellerPageOrderDeliveryAddress.scss";

interface propsTypes {
    address:string;
    state:string;
    country:string;
}

const SellerPageOrderDeliveryAddress:FC<propsTypes> = ({ address, state, country }:propsTypes) => {
    return (
        <li className='list-group-item order-delivery-address'>
           <div className='d-flex flex-row justify-content-start order-delivery-address-title align-items-center'>
            <img width='20px' height='20px' className='m-0' src={DeliveryIcon} alt=''/>
            <p className='fw-normal list-item-title col ml-12px'>Delivery address</p>
           </div>
           {/* eslint-disable-next-line */}
           <div className='fw-normal'>        
            {
                address && state && country &&
                <>
                    <p className='m-0'>{address},</p>
                    <p className='m-0'>{`${state}, ${country}`}</p>
                </>
            /* eslint-disable-next-line */
            }          
           </div>
        </li>
    )
}
export default SellerPageOrderDeliveryAddress;

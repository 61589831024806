import React, { useEffect, useRef, useState } from 'react' 
import "./EstateManagerMobileHeader.scss" 
import cinderBuildLogo from "../../../Assets/Images/enterprise/cinderbuild-logo-enterprise.png"
import { Link, useNavigate } from 'react-router-dom';
import profileCircle from "../../../Assets/Images/icon/profile-circle.png"
import cart from "../../../Assets/Images/icon/shopping-cart.png"
import heart from "../../../Assets/Images/icon/heart.png"
import login from "../../../Assets/Images/login.png"
import userCircle from "../../../Assets/Images/user-cirlce-add.png"
// import { HeaderSearchBox } from '../../../Components';
import { useDispatch, useSelector } from 'react-redux';
import logoutIco from "../../../Assets/Images/logout.png"
import { TOGGLE_CART_DRAWER } from '../../../Constants/CartConstants';
import { useCategory } from '../../../Hooks';
import rightarrow from "../../../Assets/Images/icon/right-arrow.png"
import { useAuth } from '../../../Components/AuthProvider/AuthProvider'; 
 
import { BasicRBAC } from '../../../Helpers/BasicRBAC';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../Config/DBConfig';  
import ModalPrompt from '../../../Components/ModalPrompt/ModalPrompt';
import EstateManagerSidebarContent from '../Sidebar/EstateManagerSidebarContent/EstateManagerSidebarContent';

 
const EstateManagerMobileHeader = () => { 

  const dispatch = useDispatch()
  const handleShow = () => dispatch({ type: TOGGLE_CART_DRAWER, payload: { active: true, placement: "bottom" } })

  const [pageNumber,] = useState(1)
  const [sortOrder,] = useState("DESC")
  const [pageSize,] = useState(20)

  const navigate = useNavigate()

  const { isSuccess, data: categoryList } = useCategory(pageNumber, sortOrder, pageSize);
 
  const [logoutShow, setLogoutShow] = useState<boolean>(false)

  const cellRef = useRef<HTMLButtonElement>(null);
  const elementEventRef = useRef<HTMLAnchorElement>(null);

  const closeMenu = () => { 
    cellRef?.current?.click()
  }

  useEffect(() => {
    if (elementEventRef && elementEventRef.current) {
        elementEventRef.current.addEventListener("click", closeMenu) 
      }

      return () => {
        elementEventRef?.current?.removeEventListener("click", closeMenu)
      }
  }, [elementEventRef])
 
  
  const { user, logout } = useAuth()

  function logoutNow ():void { 
    setLogoutShow(!logoutShow)
  };
  
  const access = BasicRBAC
 


  const [cartCount, setCartCount] = useState(0)
 
  useLiveQuery(
    async () => {
      const carts = db.cart.toArray()

      setCartCount((await carts)?.length)
    }
  );


  const offcanverseMenu:any = useRef(null)
  const offcanverseBody:any = useRef(null)
   
  const handleClickAway = () => { 
    offcanverseMenu.current.classList.toggle('show')
    offcanverseBody.current.classList.toggle('show')
  };

  // toggle sidebar menu with redux
   const mobileMenu = useSelector((state: { mobileMenu: boolean })=>state.mobileMenu)
   
  useEffect(()=>{ 
    offcanverseMenu.current.classList.toggle('show')
    offcanverseBody.current.classList.toggle('show')
  }, [mobileMenu])
 
  return (
    <header className='estate-manager-mobile-header d-lg-none'> 
    <div className="container bg-white pt-2 pt-sm-3">
        <div className="row">
            
            <div className="col-1">
                <button className="btn p-0"  type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </button>
            </div>

            <div className="col-6">
                <Link className="navbar-brand" to="/">
                    <img src={cinderBuildLogo} className="logo  mb-1"/>
                </Link>
            </div>

            <div className="col-5 px-0">
                <ul className="nav float-end">
                    <li className="nav-item">
                        <Link className="nav-link px-1 p-0" to="/cart"> <img src={heart} alt="" className="nav-icon"/></Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link px-1 p-0 cartMenu" to="#" onClick={handleShow} > {cartCount ? <span className='cart-count'>{cartCount}</span> : ""}  <img src={cart} alt="" className="nav-icon"/></Link>
                    </li>
                    {!user && <li className="nav-item">
                        <div className="nav-link px-1 p-0 dropdown">
                            <a className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={profileCircle} className="avarter me-2"/>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow">
                                <li><Link className="dropdown-item" to="/login"> <img src={login} className="nav-icon-sub"></img> Login</Link></li>
                                <li><Link className="dropdown-item" to="/register"> <img src={userCircle} className="nav-icon-sub"></img> Signup</Link></li>
                            </ul>
                        </div>
                    </li> }
                    {user && <li className="nav-item">
                        <div className="nav-link px-1 p-0 dropdown">
                            <a className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={profileCircle} className="avarter me-2"/>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow">  
                                {access("My-Account") ===true && <li><Link className="dropdown-item" to="/estate-managers/settings"> <img src={login}></img> My Account</Link></li>}
                                {access("Order-Management") ===true && <li><Link className="dropdown-item" to="/estate-managers/order-management"> <img src={login}></img> My Orders</Link></li> }
                                <li><Link className="dropdown-item text-danger" to="#" onClick={logoutNow}> <img src={logoutIco}></img> Log out</Link></li>
                            </ul>
                        </div>
                    </li> }

                </ul>
            </div>
        </div>
    {/* <HeaderSearchBox></HeaderSearchBox> */}
        <div className="offcanvas offcanvas-start" ref={offcanverseMenu} tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasExampleLabel">Menu</h5>
                <button type="button" ref={cellRef} className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleClickAway}></button>
            </div>
            <div className="offcanvas-body container" ref={offcanverseBody}>
 
            <React.Fragment> 
                <div onClick={closeMenu} className='p-0 m-0'>
                    <EstateManagerSidebarContent></EstateManagerSidebarContent>
                </div>
                <hr className='bg-dark text-secondary mt-5'></hr>
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item border-0">
                        <h2 className="accordion-header border-0" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Categories
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body p-0">
                            <div className="mega-menu-tab">
                                <ul className="list-group">
                                    {isSuccess && categoryList?.map((res: any, index: React.Key) => <li className="list-group-item border-0" key={index}>
                                        <Link to={`/products-by-category/${res.uuid}`} ref={elementEventRef}>
                                            <div className="row h-100">
                                                <div className="col-2 my-auto">
                                                    <div className="img"></div>
                                                </div>
                                                <div className="col-8 my-auto p-0">{res.name}</div>
                                                <div className="col-2 my-auto text-end">
                                                    <img src={rightarrow} alt="" className='nav-icon'/>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>)}
                                </ul>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="accordion-item accordion-item-links border-0">
                       <Link to="/brands" data-bs-dismiss="offcanvas" ref={elementEventRef}>Brands</Link>
                    </div>
                    <div className="accordion-item accordion-item-links border-0">
                       <Link to="/bestdeals-product" ref={elementEventRef}> Best deals</Link>
                    </div>
                    <div className="accordion-item accordion-item-links border-0">
                       <Link to="/bestselling-product" ref={elementEventRef}> Best seller</Link>
                    </div>
                    <div className="accordion-item accordion-item-links border-0" >
                       <Link to="/products" ref={elementEventRef}> All Products</Link>
                    </div>
                    <div className="accordion-item accordion-item-links border-0">
                       <Link to="" ref={elementEventRef}> Resources</Link>
                    </div>
                </div>
                </React.Fragment> 
            </div>
        </div>
    </div>
    <ModalPrompt 
        showModal={logoutShow} 
        action={()=>{
          logout()
          navigate('/login', { replace: true })
        }} 
        closeModal={()=> setLogoutShow(false)}
        message={'Are you sure you want to logout?'}/>
    </header>
  )
}

export default EstateManagerMobileHeader

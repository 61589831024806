import React, { useEffect, useState } from 'react'
import "./EnterpriseWalletHistory.scss" 
import { EnterprisePageTitle } from '../../../../../../../Components'
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService'
import { TimeConverter } from '../../../../../../../Helpers/TimeConverter'
import Pagination from '../../../../../../../Components/Pagination/Pagination'
import toast, { Toaster } from 'react-hot-toast'
 
const EnterpriseWalletHistory = () => {
  const [sortOrder,] = useState("DESC")
  const [historyData, setHistoryData] = useState([])
  const [page, setPage] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [total, setTotal] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)

  const accountHistory = async () => {
    setLoadingRequest(true)
    await bankingFinanceService.walletHistory(pageNumber, sortOrder)
      .then((res:any) => {
        setLoadingRequest(false)
        setHistoryData(res.data.data.dataset)
        setPage(res.data.data.pageSize)
        setPageNumber(res.data.data.pageNumber)
        setTotal(res.data.data.total)
        console.log(res)
      }, (error) => {
        setLoadingRequest(false)
        console.log(error)
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    accountHistory()
  }, [])

  return (
        <div className='enterpriseHistory'>  
            <div className="container mt-4">
            <EnterprisePageTitle title={''} parent={"Banking & Finance"} parentUrl={"banking-and-finance"}></EnterprisePageTitle>
            <div className="container"> 
                <div className="row">
                    <div className="col-md-10">
                        <div className='history-title'>Transaction History</div>
                    </div> 
                    <div className="col-md-1"> 
                        <div className="dropdown">
                            <button className="btn btn-sm border btn-pill dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg className="me-1" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9404 22.6516C10.4604 22.6516 9.99039 22.5316 9.55039 22.2916C8.67039 21.8016 8.14039 20.9116 8.14039 19.9116V14.6116C8.14039 14.1116 7.81039 13.3616 7.50039 12.9816L3.76039 9.02156C3.13039 8.39156 2.65039 7.31156 2.65039 6.50156V4.20156C2.65039 2.60156 3.86039 1.35156 5.40039 1.35156H18.6004C20.1204 1.35156 21.3504 2.58156 21.3504 4.10156V6.30156C21.3504 7.35156 20.7204 8.54156 20.1304 9.13156L15.8004 12.9616C15.3804 13.3116 15.0504 14.0816 15.0504 14.7016V19.0016C15.0504 19.8916 14.4904 20.9216 13.7904 21.3416L12.4104 22.2316C11.9604 22.5116 11.4504 22.6516 10.9404 22.6516ZM5.40039 2.85156C4.70039 2.85156 4.15039 3.44156 4.15039 4.20156V6.50156C4.15039 6.87156 4.45039 7.59156 4.83039 7.97156L8.64039 11.9816C9.15039 12.6116 9.65039 13.6616 9.65039 14.6016V19.9016C9.65039 20.5516 10.1004 20.8716 10.2904 20.9716C10.7104 21.2016 11.2204 21.2016 11.6104 20.9616L13.0004 20.0716C13.2804 19.9016 13.5604 19.3616 13.5604 19.0016V14.7016C13.5604 13.6316 14.0804 12.4516 14.8304 11.8216L19.1104 8.03156C19.4504 7.69156 19.8604 6.88156 19.8604 6.29156V4.10156C19.8604 3.41156 19.3004 2.85156 18.6104 2.85156H5.40039Z" fill="#868484"/>
                                <path d="M5.99968 10.7473C5.85968 10.7473 5.72968 10.7073 5.59968 10.6373C5.24968 10.4173 5.13968 9.94733 5.35968 9.59733L10.2897 1.69733C10.5097 1.34733 10.9697 1.23733 11.3197 1.45733C11.6697 1.67733 11.7797 2.13733 11.5597 2.48733L6.62968 10.3873C6.48968 10.6173 6.24968 10.7473 5.99968 10.7473Z" fill="#868484"/>
                            </svg> 
                                <span>Filter</span>
                            </button>
                            <ul className="dropdown-menu shadow border-0" aria-labelledby="dropdownMenuButton1">
                                {/* <li><a className="dropdown-item" href="#">Action</a></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-1"> 
                        <div className="dropdown">
                            <button className="btn btn-sm border dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg className="me-1" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 11V17L11 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9 17L7 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                <span>Export</span>
                            </button>
                            <ul className="dropdown-menu shadow border-0" aria-labelledby="dropdownMenuButton1">
                                {/* <li><a className="dropdown-item" href="#">Action</a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='history-inner mt-4'>
              <table className="table">
                <tbody>
                {historyData.length === 0 && <tr> 
                    <td colSpan={1} className="history-column-empty w-100 text-center">
                        <div className=" w-100 history-column-empty">
                          No transaction yet
                         </div>
                    </td>
                </tr>}
                {historyData.length > 0 && historyData.map((resData:any, index:React.Key) => {
                  return (<tr key={index}>
                        <td className='history-column w-100'>
                            <div className="row w-100">
                                <div className="col-6 d-flex align-items-center">
                                {resData === "out"
                                  ? <svg width="17" height="17" className='me-3' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.00141 18.2495C4.81141 18.2495 4.62141 18.1795 4.47141 18.0295C4.18141 17.7395 4.18141 17.2595 4.47141 16.9695L18.4714 2.96945C18.7614 2.67945 19.2414 2.67945 19.5314 2.96945C19.8214 3.25945 19.8214 3.73945 19.5314 4.02945L5.53141 18.0295C5.38141 18.1795 5.19141 18.2495 5.00141 18.2495Z" fill="#00C12B"/>
                                    <path d="M15.27 18.2505H5C4.59 18.2505 4.25 17.9105 4.25 17.5005V7.23047C4.25 6.82047 4.59 6.48047 5 6.48047C5.41 6.48047 5.75 6.82047 5.75 7.23047V16.7505H15.27C15.68 16.7505 16.02 17.0905 16.02 17.5005C16.02 17.9105 15.68 18.2505 15.27 18.2505Z" fill="#00C12B"/>
                                    <path d="M20.5 22.75H3.5C3.09 22.75 2.75 22.41 2.75 22C2.75 21.59 3.09 21.25 3.5 21.25H20.5C20.91 21.25 21.25 21.59 21.25 22C21.25 22.41 20.91 22.75 20.5 22.75Z" fill="#00C12B"/>
                                    </svg>
                                  : <svg width="17" height="17" className='me-3' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 22.75H20.5C20.91 22.75 21.25 22.41 21.25 22C21.25 21.59 20.91 21.25 20.5 21.25H3.5C3.09 21.25 2.75 21.59 2.75 22C2.75 22.41 3.09 22.75 3.5 22.75Z" fill="#FFA685"/>
                                <path d="M4.9986 18.2495C5.18859 18.2495 5.37859 18.1795 5.52859 18.0295L19.5286 4.02945C19.8186 3.73945 19.8186 3.25945 19.5286 2.96945C19.2386 2.67945 18.7586 2.67945 18.4686 2.96945L4.4686 16.9695C4.1786 17.2595 4.1786 17.7395 4.4686 18.0295C4.6186 18.1795 4.8086 18.2495 4.9986 18.2495Z" fill="#FFA685"/>
                                <path d="M19 14.52C19.41 14.52 19.75 14.18 19.75 13.77V3.5C19.75 3.09 19.41 2.75 19 2.75H8.73C8.32 2.75 7.98 3.09 7.98 3.5C7.98 3.91 8.32 4.25 8.73 4.25H18.25V13.77C18.25 14.18 18.59 14.52 19 14.52Z" fill="#FFA685"/>
                                </svg>
                                }
                                    <div>
                                        <b>{resData.description}</b>
                                        <div className='timeTrans'>{TimeConverter(resData.createdAt)}</div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className='amount p-0'>{resData.currencySymbol}{resData.amountMajor}</div>
                                    <div className='desc d-sm-none'>
                                        {resData.type === 'external_to_fund_wallet' && <><svg width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4"/></svg> Top up</>}
                                        {resData.type === 'wallet_funds_withdrawal' && <><svg width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4"/> </svg> Withdraw</>}
                                        {resData.type === 'external_to_pay_for_order' && <><svg width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#FFA685"/></svg> Purchase</>}
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className='desc'>
                                        {resData.type === 'external_to_fund_wallet' && <><svg className='me-3' width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4"/></svg> Top up</>}
                                        {resData.type === 'wallet_funds_withdrawal' && <><svg className='me-3' width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="5.5" cy="5.5" r="5.5" fill="#48E2E4"/> </svg> Withdraw</>}
                                        {resData.type === 'external_to_pay_for_order' && <><svg className='me-3' width="9" height="9" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="5.5" fill="#FFA685"/></svg> Purchase</>}
                                    </div>
                                </div>
                                <div className="col-1"> 
                                </div>
                            </div>
                        </td>
                    </tr>)
                })}
                </tbody>
              </table>  
            </div>

            {historyData.length > 10 && <div className="pagination">
                {total > 0 && <Pagination
                className="pagination-bar"
                currentPage={pageNumber}
                totalCount={total}
                pageSize={page}
                onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                }
            </div>}
            <Toaster/>
        </div>
    </div>
  )
}

export default EnterpriseWalletHistory

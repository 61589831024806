import React, { FC, useState, useEffect } from 'react'
import "./Wallet.scss"
import mastercard from "../../../../../../../Assets/Images/mastercard.png"
import visa from "../../../../../../../Assets/Images/Visa.png"
import AlertModal from '../../../../../../../Components/AlertModal/AlertModal'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService'
import WalletWith from "../../../../../../../Assets/Images/icon/wallet-2.svg"
import WalletAdd from "../../../../../../../Assets/Images/icon/wallet-add.svg"
import toast, { Toaster } from 'react-hot-toast'
import MiniLoader from '../../../../../../../Components/Loader/MiniLoader/MiniLoader';

interface WalletProps {
  myBalance: {
    currency: string,
    currencySymbol: string,
    amountMajor: string
  }
}

const Wallet: FC<WalletProps> = ({ myBalance }) => {
  const [paymentType, setPaymentType] = useState("fund-wallet")
  const [widthdraw, setWidthdraw] = useState("widthdraw")
  const [showModal, setShowModal] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [dedicatedAccount, setDedicatedAccount] = useState({ bankName: "nill", bankAccountNumber: "nill", bankAccountName: "nill" })
  const [customModalTitle, setCustomModalTitle] = useState("Withdraw")
  // eslint-disable-next-line no-unused-vars
  const [paymentInformation, setPaymentInformation] = useState<any>()

  const [savingBankDetails, setSavingBankDetails] = useState(false);
  const [withdrawaLRequest, setWithdrawaLRequest] = useState(false);

  const [loadingRequest] = useState<boolean>(false)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const { register, handleSubmit } = useForm()

  const { register: register2, handleSubmit: handleSubmit2, reset } = useForm()

  const onSubmit = handleSubmit((data: any) => {
    processpayment(data)
  })

  const processpayment = async (data: { [x: string]: any }) => {
    //  setPaymentType("card-payment")
    setSavingBankDetails(true)
    console.log(data)

    await bankingFinanceService.fundWallet({ ...data, paymentVariant: "fund_main_wallet" })
      .then((res) => {
        setSavingBankDetails(false)
        setPaymentInformation(res.data.data)
        console.log(res)
        toast.loading("Redirecting to payment terminal");
        setTimeout(() => {
          window.location.href = res.data.data.paymentProviderRedirectUrl
        }, 1500)
      }, (error: any) => {
        setSavingBankDetails(false)
        console.log(error.response.data.error)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
      })
  }

  const onSubmit2 = handleSubmit2((data: any) => {
    processpayment2(data)
  })

  const processpayment2 = async (data: { [x: string]: any }) => {
    setWithdrawaLRequest(true)
    console.log(data)

    await bankingFinanceService.walletWithdrawal(data)
      .then((res) => {
        setWithdrawaLRequest(false)
        console.log(res)
        setShowModal(true)
        setAlertType("success")
        setAlertMessage(`Your you have successfully withdraw N${data.amountMajor} from your acount.`)
        handleClose()
        reset()
      }, (error: any) => {
        setWithdrawaLRequest(false)
        console.log(error.response.data.error)
        toast.error(error.response.data.error, { duration: 25000, className: 'bg-danger text-white' });
      })
  }

  const getDedicatedAccount = async () => {
    await bankingFinanceService.walletDedicatedAccount()
      .then((res) => {
        console.log("Dedicated Account", res)
        setDedicatedAccount(res.data.data)
      }, (error: any) => {
        console.log(error.response.data.error)
        toast.error(error.response.data.error, { duration: 25000, className: 'bg-danger text-white' });
      })
  }

  useEffect(() => {
    getDedicatedAccount()
  }, [])

  useEffect(() => {
    return () => toast.dismiss()
  }, [])

  return (
    <div className='wallet'>
      <MiniLoader show={loadingRequest} />
      <div className="container-fluid p-0">
        <div className="row">
          <div className="wallet-card col-6 ">
            <div className='walletName mb-2'>Wallet Balance</div>
            <div className="card border-0 ">
              <div className="card-body ">

                <div className="row">
                  <div className="col-12 outer d-flex justify-content-center align-items-center border-bottom border-1 border-white">
                    <div className='walletBalance'>{myBalance.currencySymbol} {myBalance.amountMajor}</div>
                  </div>
                  <div className='col-12'>
                    <div className='row'>
                      <div className='btn-div col-6 border-end border-0 border-white d-flex flex-column justify-content-center align-items-center btn ps-2' onClick={handleShow2}>
                        <img src={WalletAdd} alt='wallet add logo' />
                        <p>Fund Wallet</p>
                      </div>
                      <div className='btn-div col-6 d-flex flex-column justify-content-center align-items-center border-0 border-white btn' onClick={handleShow}>
                        <img src={WalletWith} alt='wallet-with logo' />
                        <p>Withdraw</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="border-0">
            <h5 id="fundModalLabel">Fund Wallet</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="wallet-modal pb-5 rounded">
          {paymentType === "fund-wallet" && <form onSubmit={onSubmit}>
            <div className='form-input'>
              <label htmlFor='amount'>How much do you want to add to wallet?</label>
              <div className='form-input-wrap'>
                <div className="form-input-symbol">N</div>
                <input id="amountMajor" type="number" className='form-control mt-3' {...register("amountMajor", { required: "this field is required" })} />
                {/* {errors.amountMajor && <div className='text-danger'>{errors.amountMajor.message}</div>} */}
              </div>
            </div>
            {!savingBankDetails && <button className='btn btn-outline-success w-100 wallet-btn mt-5 mb-3'>Card Payment</button>}
            {savingBankDetails && <button className="btn btn-success w-100 wallet-btn mt-5 mb-3" disabled>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Processing request...
            </button>}

            <button className='btn btn-outline-success w-100 wallet-btn' onClick={() => setPaymentType("bank-transfer")} >Bank Transfer</button>
          </form>}

          {paymentType === "card-payment" && <form>
            <div className='form-input'>
              <label htmlFor='amount'>Enter your card details</label>
              <div className='form-input-wrap'>
                <input id="amount" className='form-control ps-2 mt-3' placeholder='Card Nuimber' />
                <div className="form-input-logo">
                  <img src={mastercard} />
                  <img src={visa} />
                </div>
              </div>

              <div className='form-input-wrap'>
                <input id="cardNumber" className='form-control ps-2 mt-3' placeholder='Name on Card' {...register("cardNumber", { required: "this field is required" })} />
              </div>

              <div className="row">
                <div className="col-md-6">
                  <input id="amount" className='form-control ps-2 mt-3' placeholder='Expiraration MM/YY' />
                </div>
                <div className="col-md-6">
                  <input id="amount" className='form-control ps-2 mt-3' placeholder='CVV' />
                </div>
              </div>
              <button className='btn btn-success w-100 wallet-btn mt-4 text-light'>Continue</button>

            </div>
          </form>}

          {paymentType === "bank-transfer" && <div className='card bg-light p-lg-4 my-5'>
            <div className='card-header fw-bolder bg-light border-0'>Paystack Dedicated Account</div>
            <div className='card-body'>
              <div className="bank-transfer-details">
                <table>
                  <tbody>
                    <tr>
                      <td>Bank Name:</td>
                      <td className='ps-3'>{dedicatedAccount?.bankName}</td>
                    </tr>
                    <tr>
                      <td>Account Number:</td>
                      <td className='ps-3'>{dedicatedAccount?.bankAccountNumber}</td>
                    </tr>
                    <tr>
                      <td>Account Name:</td>
                      <td className='ps-3'>{dedicatedAccount?.bankAccountName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>}
          {paymentType === "bank-transfer" && <button className='btn btn-outline-success w-100 wallet-btn' onClick={() => setPaymentType("fund-wallet")}>Return to option</button>}
        </Modal.Body>
      </Modal>

      {/* // widthdraw */}
      <Modal show={show} onHide={handleClose} className="reactModalB" contentClassName="rounded">
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className='modal--title'>{customModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body wallet-modal withdraw-modal pb-5 myModal-content">

          <form onSubmit={onSubmit2}>
            {widthdraw === "widthdraw" && <React.Fragment>
              <div className='form-input mt-4'>
                <label htmlFor='amount'>Enter amount you want to withdraw</label>
                <div className='form-input-wrap'>
                  <div className="form-input-symbol">N</div>
                  <input id="amount" type="number" className='form-control mt-3' {...register2("amountMajor", { required: "this field is required" })} />
                  {/* {errors2.amountMajor && <div className='text-danger'>{errors2.amountMajor.message}</div>} */}
                </div>
              </div>
              <button className='btn btn-success text-white w-100 wallet-btn mt-4 mb-3' onClick={() => {
                setWidthdraw("widthdraw-confirmation")
                setCustomModalTitle("Authroize Transaction")
              }}>Confirm</button>
            </React.Fragment>}

            {widthdraw === "widthdraw-confirmation" && <React.Fragment>

              <div className="modal-body wallet-modal withdraw-modal pb-5">

                <div className='form-input mt-4'>
                  <label htmlFor='password'>Enter password to confirm transaction</label>
                  <div className='form-input-wrap'>
                    <input id="password" type="password" className='form-control  ps-3 mt-3' {...register2("password", { required: "this field is required" })} />
                    {/* {errors2.password && <div className='text-danger'>{errors2.password.message}</div>} */}
                  </div>
                </div>
                {!withdrawaLRequest && <button className='btn btn-success text-white w-100 wallet-btn mt-4 mb-3 py-2 fw-bold'>Withdraw</button>}
                {withdrawaLRequest && <button className="btn btn-success text-white w-100 wallet-btn mt-4 mb-3 py-2 fw-bold" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Processing request...
                </button>}
              </div>
            </React.Fragment>}
          </form>
        </Modal.Body>
      </Modal>

      <AlertModal showModal={showModal} alertType={alertType} message={alertMessage}></AlertModal>
      <Toaster />
    </div>
  )
}

export default Wallet

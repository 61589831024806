import { ACTIVEMENU } from '../../Constants/MobileMenuConstants';
 
export function ActiveMenuReducer (state: string, action: { type: string; payload: string; }) {
  switch (action.type) {
    case ACTIVEMENU:
      return action.payload
    default:
      return 'Dashboard'
  }
}
 

import React, { FC, useEffect, useState } from 'react'
import "./PickupContactInformation.scss"

import { useDispatch } from 'react-redux';
import { PICKUP_CONTACT_INFORMATION } from '../../../../../Constants/CheckoutConstants'
import toast from 'react-hot-toast'
import { db } from '../../../../../Config/DBConfig'
import { useLiveQuery } from 'dexie-react-hooks'

const PickupContactInformation:FC = () => {
  const [fields, updateFields] = useState({
    msisdn: "string",
    emailAddress: "string",
    fullName: "string"
  })

  const dispatch = useDispatch()

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    updateFields({
      ...fields,
      [name]: value,
    })
  }

  const orderDetails = useLiveQuery<any>(async () => await db.orderDetails.toArray())

  const saveAddress = async () => {
    await db.orderDetails.update(orderDetails[0]?.id, { pickupContactInformation: { ...fields } })
      .then(function (updated) {
        if (updated) {
          // toast.success(`Pickup contact info added`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        } else {
          toast.error(`Nothing was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }
      });
  }

  useEffect(() => {
    saveAddress()
    dispatch({ type: PICKUP_CONTACT_INFORMATION, payload: fields })
  }, [fields])

  return (
        <div className='pickupContactInformation'>

          <form action="">
            <div className="row mt-5">
                <div className="col-md-6">
                    <div className='title'>Enter your contact details:</div>
                    <div className='title-desc'>We use this information to keep you informed of the delivery.</div>
                </div>
                <div className="col-md-6 text-end title-req"><span className='text-danger'>*</span> Required field</div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className='form-input'>
                        <label htmlFor="first_name">Full name *</label>
                        <input type="text" id="first_name" name="fullName" className='form-control'
                        placeholder='Enter your first name'
                        onChange={(e) => handleChange(e)}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className='form-input'>
                        <label htmlFor="email">Email address * </label>
                        <input type="text" id="email" name="emailAddress" className='form-control'
                        placeholder='Enter your email address'
                        onChange={(e) => handleChange(e)}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className='form-input position-relative'>
                        <label htmlFor="phone_number">Phone number * </label>
                        <input type="text" id="phone_number"
                        name="msisdn"
                        className='form-control phoneContact'
                        placeholder=''
                         onChange={(e) => handleChange(e)}/>
                        <div className='countryCode'>+234</div>
                    </div>
                </div>

            </div>
          </form>
        </div>
  )
}

export default PickupContactInformation

import React, { FC } from 'react'
import SellerProductItemNone from '../../Products/SellerProductItem/SellerProductItemNone'; 
import "./ProductVariantList.scss"
import SellerProductVarientItem from '../SellerProductVarientItem';

interface propType {
    ProductVariantList:any[],
    loadingVariant: boolean
}
const ProductVariantList:FC<propType> = ({ ProductVariantList, loadingVariant }) => {
  return (
        <div className='product-variant-item'>
              <div className='prod-var-list text-start'>Product variants </div>
              <div className="container-fluid">
                <div className="row">
                        {ProductVariantList.length > 0 && ProductVariantList.map((productItemValues: any, index: React.Key) => {
                          return <SellerProductVarientItem key={index} productItemValues={productItemValues}/>
                        })}

                            {loadingVariant && [1, 2, 3, 4].map((productItemValues: any, index: React.Key) => {
                              return <SellerProductItemNone key={index}/>
                            })}
                        </div>

                        {ProductVariantList.length === 0 && !loadingVariant && <div className='row mt-5 justify-content-center'>
                        <div className='col-4 text-center'>
                            <div className='emptySearch mb-4'>
                                <i className='d-icon d-file-search is-larges'></i>
                                </div>
                                <div className='text-secondary fw-bold'>You have no variant for this product </div>
                            </div>
                        </div>}

            </div>
        </div>
  )
}

export default ProductVariantList

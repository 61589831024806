import React from 'react'
// import tickcircle from "../../../../../Assets/Images/icon/tick-circle.png"

const ProductOption = () => {
  return (
        <React.Fragment>
            <div className="product-option mt-4">
                {/* <img src={tickcircle} className="tick-icon" alt=""/> Next day delivery. */}
            </div>
            <div className="product-option">
                {/* <img src={tickcircle} className="tick-icon" alt=""/> Receive a 2 year warranty. */}
            </div>
        </React.Fragment>
  )
}

export default ProductOption

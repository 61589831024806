import React, { FC, useEffect, useState } from 'react'
import "./StoreAllProduct.scss"
import Pagination from '../../../../../../../Components/Pagination/Pagination';
import StoreProductItem from '../StoreProductItem/StoreProductItem';
import StoreProductItemNone from '../StoreProductItem/StoreProductItemNone';
import StoreAddProduct from '../StoreAddProduct/StoreAddProduct';

interface AllProductsPropsType {
  productList: any;
  paginationData: any;
  loadingReqest: boolean;
  setNextPage: any
}

const StoreAllProducts: FC<AllProductsPropsType> = ({ loadingReqest, productList, paginationData, setNextPage }) => {
  // eslint-disable-next-line no-unused-vars
  const [dataCompose, setDataCompose] = useState<any>({ totalCount: 200 })
  const [loader, setLoader] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [resultPerPage, setResultPerPage] = useState(50)
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [showNewProductModal, setshowNewProductModal] = useState(false)

  useEffect(() => {
    setNextPage(page)
  }, [page])

  useEffect(() => {
    setLoader(loadingReqest)
  }, [loadingReqest])

  return (
    <div className="seller-all-product">
      <div className="product-item my-md-5">
        <div className="row">
          <div className="col-6 col-md-4">
            <div className="category-tit-cont">
              <h6 className="sp-title">{productList.length} Products available</h6>
            </div>
          </div>

          <div className="col-6 col-md-8 text-end">
            <div className="row">
              <div className="col-md-5">
                <form className="d-flex search-container d-none d-xl-block">
                  <span className="fas fa-search"></span>
                  <input className=" search ps-5" type="search" placeholder="Search Product" aria-label="Search" />
                </form>
              </div>
              <div className="col-md-5">
                <form className="d-flex search-container d-none d-xl-block">

                  <input className=" search ps-5" placeholder="Filter All product" aria-label="Filter" />
                </form>
              </div>

            </div>
          </div>
        </div>
        <div className="container-md p-0 p-sm-3 p-md-3">
          <div className="row">
            {productList.length > 0 && productList.map((productItemValues: any, index: React.Key) => {
              return <StoreProductItem key={index} productItemValues={productItemValues} />
            })}

            {loader && [1, 2, 3, 4, 5, 6].map((productItemValues: any, index: React.Key) => {
              return <StoreProductItemNone key={index} />
            })}
          </div>

          {productList.length === 0 && !loader && <div className='emptySearch-container d-flex mt-5 justify-content-center'>
            <div>
              <div className='emptySearch'>
                <i className='d-icon d-file-search'></i>
              </div>
              <div className='text-secondary fw-bold'>You have no products yet</div>
            </div>
          </div>}

          {productList.length > 0 && loader === false && <div className="pagination text-end">
            {dataCompose.totalCount && <Pagination
              className="pagination-bar"
              currentPage={Number(paginationData.pageNumber)}
              totalCount={Number(paginationData.total)}
              pageSize={Number(paginationData.pageSize)}
              onPageChange={(page: React.SetStateAction<number>) => setPage(page)} />
            }
          </div>}
        </div>
      </div>

      <StoreAddProduct
        showNewProductModal={showNewProductModal}
        setShowNewProductModal={setshowNewProductModal}
        setShowParentModal={showNewProductModal}
      />
    </div>
  )
}

export default StoreAllProducts

import React, { FC, useState } from 'react'
import "./Navbar.scss"
import cinderBuildLogo from "../../../../Assets/Images/cinderBuild-logo.png"
import { Link } from 'react-router-dom';
// import useLoggedInUser from '../../Hooks/useLoggedInUser';

const Navbar: FC = () => {

  const [styckyNav] = useState(false)
  // function toggleMobileMenu() {
  // }


  return (
    <nav className={styckyNav === false ? "navbar seller-wrapper-navbar navbar-expand-md navbar-white" : "navbar navbar-expand-md navbar-white fixed-top  bg-white shadow-sm"}>
      <div className="container container-00 pt-0 mt-0">
        <Link to={`/`} className="navbar-brand">
          <img src={cinderBuildLogo} alt="Logo" className="logo" />
        </Link>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="fas fa-bars fa-2x text-dark"></span>
        </button>

      </div>
    </nav>
  )
}

export default Navbar

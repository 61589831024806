import { useLiveQuery } from 'dexie-react-hooks'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EnterprisePageTitle, ImageSrc } from '../../../../../../../Components'
import { db } from '../../../../../../../Config/DBConfig'
import { useAllSite } from '../../../../../../../Hooks/Queries/Enterprise/useAllSite'
import { sitesType } from '../../../../../../../Models/enterprise.types'
import "./EnterpriseWareHouseShipToSite.scss"
import toast, { Toaster } from 'react-hot-toast';
import sitesService from '../../../../../../../Services/sitesService';
import { ErrorHandler } from '../../../../../../../Helpers/ErrorHandler'
import { Logger } from '../../../../../../../Helpers/Logger'

interface propsType {
  module?: string;
}

const EnterpriseWareHouseShipToSite:FC<propsType> = ({ module }) => { 
    const {warehouseId} = useParams<{warehouseId:string}>()
    const [errorCheck, setErrorCheck] = useState(true)

    const [loadingOrder, setLoadingOrder] = useState<boolean>(false)

    const [setSite, setSetSite] = useState("null")

    const [updatingQuantity, setUpdatingQuantity] = useState("null")

    const navigate = useNavigate()
    const siteData:any = useLiveQuery(
        async () => await db.siteCart.toArray()
      );

      console.log("siteData", siteData)
      

    const {data, isSuccess } = useAllSite(warehouseId)
    
    const updateSiteCart = (e:{target:{value:string, name:string}}, data:any) => {
        setUpdatingQuantity(JSON.parse(e.target.value))
        db.siteCart.update(data.id, { [e.target.name]: JSON.parse(e.target.value) }).then(function (updated) {
          if (updated) {
            toast.success(`Updated ${e.target.name} info`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          } else {
            toast.error(`Nothing was updated`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          }
        });
      }

      const removeFromSiteCart = (id:number|undefined) => { 
        db.siteCart.where({ id }).delete().then(function (updated) {
            if (updated) {
              toast.success(`Item removed`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            } else {
              toast.error(`Nothing was removed`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            }
          });
      }

      useEffect(()=>{
        siteData?.length && siteData?.map((res: { quantity: number; site: string }) => {
            if (res.quantity===0 || setSite==="null") { 
                setErrorCheck(true)
                return true
            } else { 
                 setErrorCheck(false)
                 return false
            }
        }) 
      }, [siteData, updatingQuantity, setSite])



      const moveToSite = async () => {
    
        if (siteData) {
            setLoadingOrder(true);
    
            const arraySize = siteData.length
            for (const [index, currentProduct] of siteData.entries()) {
    
            const payload = {
                deliveryItems: [
                {
                    productUuid: currentProduct.details.product.uuid,
                    productName: currentProduct.details.product.name,
                    quantity: currentProduct.quantity
                }
                ],
                deliveryLocationUuid: setSite
            }
    
            await sitesService.moveToSite( currentProduct.details.warehouse.uuid, payload)
            .then(async (res:any) => { 
                setLoadingOrder(false); 
                if (index === arraySize - 1) {
                   // remove items from site cart
                     await db.siteCart.clear().then(()=>{
                      toast.success("We have received your request, we will get back to you with the delivery fee.", { duration: 40000, className: 'custom-alert bg-white text-dark', position: 'top-right' });
                      navigate(`/enterprise/warehouse-management/ship-to-site/request-success/${warehouseId}/${setSite}`, {replace: true})
                
                     })
                }
              }, (error: any) => { 
                setLoadingOrder(false);
                Logger("Add product to site error", error.response)
                ErrorHandler(error,
                  () => {
                    toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
                  })
              })
            }
        }
      }

 

    return (
        <div className='enterpriseWareHouseShipToSite'>
             <div className="container">
                <EnterprisePageTitle
                    title={"Ship to site "}
                    parent={"Warehouse"}
                    parentUrl={`warehouse-management/${warehouseId}`}
                    showTitle={true}
                />
 
              <div className="select-site">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="site"> Which site are you shipping to?</label>
                      <select name="site" id="site" className='form-control' onChange={(e) => setSetSite( e.target.value )}>
                        <option value="">Select site</option>
                        {isSuccess===true && data?.length >0 && data?.map((result:sitesType, index:number)=><option key={index} value={result.uuid}>Site {index+1} - {result.address}</option>)}
                      </select>
                  </div>
                </div>
              </div>
              <div className="site-moveable mt-5">
              What quantity are you shipping per item?
              </div>
              <div className="card mt-3">
                <div className="card-body px-0">
                    <div className="table-reponsive">
                        <table className="table">
                            <tbody>
                                {siteData?.length > 0? siteData?.map((res:any, index:React.Key)=>{
                                return <tr key={index}>
                                    <td className='ps-md-4'>
                                        <ImageSrc src={res.details?.product?.images?.length > 0 ? res.details?.product?.images[0].url : ""} alt={res.details?.product?.name} title={undefined} width={'product-image'}></ImageSrc>
                                        {res.details.product.name}
                                    </td>
                                    <td>
                                        <input name="quantity" type="number" defaultValue={res.quantity} className='form-control ship-quantity' onChange={(e) => updateSiteCart(e, res )}></input> 
                                    </td> 
                                    <td>
                                    <button className="btn btn-remove" onClick={() => removeFromSiteCart(res.id)}>
                                        <svg className='me-2' width="17" height="17" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 21C16.5 21 21 16.5 21 11C21 5.5 16.5 1 11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21Z" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.16992 13.8319L13.8299 8.17188" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M13.8299 13.8319L8.16992 8.17188" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>Remove
                                    </button>
                                    </td>
                                </tr> 
                             }): <tr>
                              <td colSpan={3} className='text-center'>
                                   No item found
                              </td>
                              </tr>}
 
                            </tbody>
                        </table>
                    </div>
                </div>
              </div> 
                <button className={module && module === "estate" ? "btn btn-continue btn-continue-estate":"btn btn-continue"} onClick={moveToSite} disabled={errorCheck || loadingOrder}>{loadingOrder && <span className='spinner-border spinner-border-sm'></span>} Continue to Ship </button>
             </div>
            <Toaster></Toaster>
        </div>
    )
}

export default EnterpriseWareHouseShipToSite

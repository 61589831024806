import React, { FC, useState } from "react";
import "./BankDetails.scss";
import { Toaster } from "react-hot-toast";
import { AddBankAccountModal } from "../../../../Buyer/Component";
import SellerRequestBankAccountUpdate from "../../RequestBankAccountUpdate/SellerRequestBankAccountUpdate";
 
interface BankDetailsPropsType {
  myBankAccount: {
    bankCode: null,
    bankName: null,
    bankAccountNumber: null,
    bankAccountName: null,
  }
}
const BankDetails: FC<BankDetailsPropsType> = ({ myBankAccount }) => {
  const [loading, setLoading] = useState(false);
  const [showAccountUpgradeForm, setShowAccountUpgradeForm] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)

  const handleModalClose = () => {
    setShowAccountUpgradeForm(false)
  }

  const handleModalOpen = () => {
    setShowAccountUpgradeForm(true)
  }


  const handleRequestAccountUpdate = async () => {
    setLoading(true);
    setTimeout(()=>{ 
      setLoading(false);
      handleModalOpen() 
    }, 500)
  }

  return (
    <div className="bank-details">
      <div className="title ">
        Bank Account
      </div>
      {!myBankAccount && (
        <div className="card border-0 ms-3 pt-5 pb-5">
          <div className="card-body">
            <div className='text-center'>
              <div className='nobank'>
                No bank available yet
              </div>
              <button className="btn add-bank" onClick={() => setShowAccountModal(true)}>Add Bank</button>
            </div>
          </div>
        </div>

      )}
      {myBankAccount &&
        <div className="card border-0">
          <div className="card-body">

          </div>
          <div className="card-footer border-0 pb-4">
            <div className="row">
              <div className="col-md-12">
                <div className="account-name">
                  {myBankAccount?.bankAccountName}
                </div>
              </div>
              <div className="col-md-6">
                <div className="bank">
                  {myBankAccount?.bankAccountNumber} -  {myBankAccount?.bankName}
                </div>
              </div>
              <div className="col-md-6">
                <button className='btn text-success d-flex align-items-center justify-content-center'
                  disabled={loading}
                  onClick={handleRequestAccountUpdate}
                >
                  Request Account Update
                  {
                    loading &&
                    <div className="spinner-border text-secondary ms-2" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      <Toaster />
      <SellerRequestBankAccountUpdate showModal={showAccountUpgradeForm} handleClose={handleModalClose}></SellerRequestBankAccountUpdate> 
      <AddBankAccountModal showModal={showAccountModal} hide={(val: boolean) => setShowAccountModal(val)}></AddBankAccountModal>
    </div>
  );
};

export default BankDetails;

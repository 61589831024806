import React, { FC, useEffect, useState } from 'react'
import "./LastOrder.scss" 
import { Link, useNavigate } from 'react-router-dom' 
import { ImageSrc } from '../../../../../../Components'
import orderService from '../../../../../../Services/orderService'
import productService from '../../../../../../Services/productService'
import { productType } from '../../../../../../Models'
import Modal from 'react-bootstrap/esm/Modal' 
import QuoteRequest from '../../../ProductSinglePage/QuoteRequest/QuoteRequest'
import Cookie from 'cookie-universal'
import { useAuth } from '../../../../../../Components/AuthProvider/AuthProvider' 
import { db } from '../../../../../../Config/DBConfig';  
import { useLiveQuery } from 'dexie-react-hooks'; 
// import { TOGGLE_CART_DRAWER } from '../../../../../../Constants/CartConstants';
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux';
import cartLight from "../../../../../../Assets/Images/icon/cart-light.png"
import ProductQuantity from '../../../ProductSinglePage/ProductQuantity/ProductQuantity'

interface valueType {
    productName: string,
    quantity: number,
    unitPriceForBuyer: number
}

interface propsType {
    sendDataToParent: (value:boolean)=>void 
    setLastOrderRequest: (value:boolean)=>void 
}

const LastOrder:FC<propsType> = ({ sendDataToParent, setLastOrderRequest }) => {
   
    const [showModal, setShowModal] = useState(false)
    const [show, setShow] = useState(false);
    const [availability, setAvailability] = useState(false)
    const [lastOrderData, setLastOrderData] = useState<valueType>()
    // eslint-disable-next-line no-unused-vars
    const [productData, setProductData] = useState<productType>()
    // eslint-disable-next-line no-unused-vars
    const [lastOrderReq, setLastOrderReq] = useState(false)
  
    const [loadingCart, setLoadingCart] = useState(false)
    const [alreadyAdded, setAlreadyAdded] = useState(false) 

    const { user } = useAuth()

    const navigate = useNavigate()
    
    // const dispatch = useDispatch()
    
   const getLastOrder = async () => {
        setLastOrderRequest(true)
        setLastOrderReq(true)
        await orderService.lastOrder().then(res=>{
            if (res.data.data[0]) {
            console.log("lastOrder", res.data.data[0])
            setLastOrderData(res.data.data[0])
            getProduct(res.data.data[0].productUuid)
            setAvailability(true)
            sendDataToParent(true)
            setLastOrderRequest(false)
            setLastOrderReq(false)
            } else { 
            setAvailability(false)
            } 
        }, error => { 
            setAvailability(false)
            setLastOrderRequest(false)
            setLastOrderReq(false)
            console.log("lastOrder error", error) 
        })
    }

    const getProduct = async (productUuid: string) => { 
          
        await productService.getSingleProduct(productUuid).then((res)=>{
            console.log("productService req", res.data.data)
            setProductData(res.data.data)
        })
    }

 
    const handleClose = () => setShowModal(false)
 
    useEffect(() => {
       getLastOrder()
    }, [])

    const cartQty = useSelector((state:{cartQty:number}) => state.cartQty)

     useLiveQuery(
        async () => { 
            if (productData) {
                const carts = db.cart.where({ productUuid: productData?.productUuid }).toArray()
            
                setAlreadyAdded((await carts).length >= 1) 
            }
        }
    );

  const unAuthQuote = () => { 
    const cookies = Cookie()
    cookies.set("referer", navigate.name)
 
    if (confirm("Hi, login is required to send a quote request. Click 'ok' to proceed to login page.") === true) {
      return navigate("/login", { replace: true })
    }
    return false
  }

  const addtocart = async () => {
    setLoadingCart(true);

    try { 
       await db.cart.add({
        productUuid: productData?.productUuid,
        quantity: cartQty,
        productDetails: productData
      });
      setLoadingCart(false);
      setAlreadyAdded(true)
    //   dispatch({ type: TOGGLE_CART_DRAWER, payload: { active: true, placement: "end" } })

      toast.success(`Kindly select a suitable delivery option`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      setShowModal(false)
      setTimeout(()=>{ 
        navigate('/checkout', {replace: true})
      }, 2000)
    } catch (error) {
      setAlreadyAdded(false)
      setLoadingCart(false);
      toast.error(`Failed to add ${name}: ${error}`, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    }
  }
  
  const [quickOrder, setQuickOrder] = useState(false)
  const toggleQuickOrder = () => setQuickOrder(!quickOrder)
  
    return (availability=== true ? <div className="LastOrder carousel-item active">
            <div className="card p-0 border-0">
                <div className="card-body p-0">
                    <div className="row h-100">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-7 my-auto p-5 text-col p-0">
                            <h2 className='quick-reorder'>
                            Reorder Now <button  onClick={toggleQuickOrder} className='btn py-1'><i className='d-icon d-information-circle fs-4 text-white'></i></button></h2>

                                {quickOrder && <h5 className='fw-bolder text-white'>
                                    {/* <i className='d-icon d-pin me-2'></i> */}
                                Save time and effort with our Fast Reorder feature! Easily replenish your favorite items by clicking the Fast Reorder button. No more searching or hassle—just a quick and streamlined process to get everything you need delivered right to your door.
                                </h5>}
                         
                            <div className="card product-info"> 
                                <div className="card-body pb-md-4">
                                    {lastOrderData && <h2>{lastOrderData.productName}</h2>}
                                    {lastOrderData && productData?.price !== 0 && <div className="slider-desc"><div className='d-dot'></div> Quantity: {lastOrderData.quantity}   <div className=' ms-3 d-dot'></div> Price: &#8358;{lastOrderData.unitPriceForBuyer * lastOrderData.quantity}.</div>}
        
                                    {/* <Link to="#">
                                        <button className='btn btn-getstarted mt-3' onClick={()=>setShowModal(true)}> Reorder </button>
                                    </Link>  */}
                                
                                    <div className='cart-btn-wraper '>   
                                        {productData?.price !== 0? <React.Fragment>
                                            {!loadingCart && (!productData?.isOnCart === false || !alreadyAdded) && <button className="btn btn-getstarted-0 mt-3" onClick={addtocart}>Buy Now</button>}
                                            {!loadingCart && (productData?.isOnCart || alreadyAdded) && <Link to={`/checkout`}><button className="btn btn-getstarted-0 mt-3"> Proceed to pay</button></Link>}
                                            {loadingCart && <button className="btn btn-getstarted-0 mt-3" disabled>
                                                <span className='spinner-border spinner-border-sm'></span>
                                                Please wait</button>}  
                                        </React.Fragment>
                                        : 
                                        <React.Fragment>
                                            {/* <div className="d-block text-white  mt-3">Click &apos;Request for Quote&apos; button below to get the best price instantly.</div> */}
                                            {user ? <button className="btn btn-getstarted-0 mt-3" onClick={()=>{ setShow(true); setShowModal(false) }}>Request For Quote</button>:
                                            <button className="btn btn-getstarted-0 mt-3" onClick={unAuthQuote}>Request For Quote</button>}
                                        </React.Fragment>}
                                        <QuoteRequest productDetail={productData} show={show} hide={(val:boolean) => setShow(val)}></QuoteRequest>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="m-0 col-12 col-sm-12 col-md-12 col-lg-5 my-auto sliderImage"> 
                            <div className="image-container">
                                <ImageSrc src={productData?.images[0].url} alt={productData?.productName || ''} title={productData?.productName || ''} width={'last-img'}></ImageSrc>
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>

        <Modal show={showModal} onHide={handleClose} contentClassName="lastOrderModal">
            <Modal.Header closeButton>
            <Modal.Title>Reorder Product</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
                <div className='container'>
                    <div className="row">
                        <div className="col-4">
                            <ImageSrc src={productData?.images[0].url} alt={productData?.productName || ''} title={productData?.productName || ''} width={'w-100'}></ImageSrc>
                        </div>
                        <div className="col-8">
                          {lastOrderData && <ul>
                            <li>{productData?.productName}</li> 
                            {productData?.price !== 0 && <li>Price: &#8358;{lastOrderData.unitPriceForBuyer}</li>}
                          </ul>}
                        </div>
                    </div>
                </div>
                <div className="soldby fs-6">
                    Sold by: {productData && productData.sellerPublicProfile.firstName} {productData && productData.sellerPublicProfile.lastName}
                </div> 
                <hr/>
                     {productData && <ProductQuantity maximumQuantity={productData?.maximumQuantity} minimumQuantity={productData?.minimumQuantity}></ProductQuantity>}
                <hr/>
                <React.Fragment>   
                    {productData?.price !== 0? <React.Fragment>
                        {!loadingCart && (!productData?.isOnCart === false || !alreadyAdded) && <button className="btn btn-add-cart mb-4" onClick={addtocart}><img src={cartLight}/> Add to cart</button>}
                        {!loadingCart && (productData?.isOnCart || alreadyAdded) && <Link to={`${module !==undefined? '/'+ module:""}/cart`}><button className="btn btn-add-cart mb-4"><img src={cartLight}/> Already Added to cart</button></Link>}
                        {loadingCart && <button className="btn btn-add-cart mb-4" disabled>
                            <span className='spinner-border spinner-border-sm'></span>
                            Adding to cart</button>}  
                    </React.Fragment>
                    : 
                    <React.Fragment>
                        <div className="request-quote-desc-text">Click &apos;Request for Quote&apos; button below to get the best price instantly.</div>
                        {user ? <button className="btn btn-add-cart mb-4" onClick={()=>{ setShow(true); setShowModal(false) }}>Request For Quote</button>:
                        <button className="btn btn-add-cart mb-4" onClick={unAuthQuote}>Request For Quote</button>}
                    </React.Fragment>}
                    <QuoteRequest productDetail={productData} show={show} hide={(val:boolean) => setShow(val)}></QuoteRequest>
                </React.Fragment>
            </Modal.Body> 
        </Modal>
       </div>
       :<React.Fragment></React.Fragment>
    )
}

export default LastOrder

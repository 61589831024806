  
import { db } from '../../../../../Config/DBConfig';
import cartService from '../../../../../Services/cartService';
import { savePaymentType } from '../../../../../Helpers/savePaymentType';
import { Logger } from '../../../../../Helpers/Logger';
import toast from 'react-hot-toast'; 
import { ErrorHandler } from '../../../../../Helpers/ErrorHandler';
 
export const DeliveryOrderUnRegisteredUser = async (orderDetails:any, setLoadingOrder:any, savePaymentDetail:any, cartData:any ) => {
 
  
  const deliveryOption = orderDetails[0].newDeliveryAddress
  ? {
      newDeliveryAddress: {
        address: orderDetails[0].newDeliveryAddress.address,
        country: JSON.parse(orderDetails[0].newDeliveryAddress.country).name,
        state: JSON.parse(orderDetails[0].newDeliveryAddress.state).name,
        contactFullName: orderDetails[0].newDeliveryAddress.contactFullName,
        contactPhoneNumber: orderDetails[0].newDeliveryAddress.contactPhoneNumber,
      },
    }
  : "";

const buyerDetails = {
  msisdn: orderDetails[0].newDeliveryAddress.contactPhoneNumber,
  emailAddress: orderDetails[0].newDeliveryAddress.email,
  fullName: orderDetails[0].newDeliveryAddress.contactFullName,
}

const serializedData = cartData?.map((res: { productDetails: { sellerPublicProfile: { userUuid: any; }; }; productUuid: any; quantity: any; }) => (
  {
    userUuid: res.productDetails.sellerPublicProfile.userUuid,
    orderReceiveType: orderDetails[0].deliveryType,
    cartItems: [
      {
        productUuid: res.productUuid,
        quantity: res.quantity,
      }
    ]
  }
))
 

await cartService.deliveryDetailsUnauth(
  {
    ...deliveryOption,
    buyer: buyerDetails,
    sellers: serializedData
  }
).then(async (res:any) => {
    setLoadingOrder(false)

    await savePaymentType("UNAUTH_BUYER_BUY").then(async ()=>{
      await db.cart.clear()
      await db.orderDetails.clear()
      
      Logger("ORDER created 111", res)
  
      savePaymentDetail(res)
  
      toast.success(" Order succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
  
      window.location.href = res.data.data.paymentProviderDetails.paymentProviderRedirectUrl;
    })
   
  }, (error:any) => { 
    setLoadingOrder(false)
    console.warn("ORDER ERROR: ", error.response)

    ErrorHandler(error,
      (e) => {
        toast.error(e, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  })
}
 
 
import { db } from '../../../../../Config/DBConfig';
import cartService from '../../../../../Services/cartService';
import { savePaymentType } from '../../../../../Helpers/savePaymentType';
import { Logger } from '../../../../../Helpers/Logger';
import toast from 'react-hot-toast'; 
import { ErrorHandler } from '../../../../../Helpers/ErrorHandler';

export const PickUpOrderRegisteredUser = async (orderDetails:any, setLoadingOrder:any, savePaymentDetail:any, navigate:any ) => {
 

     // remove address of excess field
     const newPickupLoc = orderDetails[0].pickupLocations.sellers.map((item:any) => {
        delete item.address;
        return {...item, orderReceiveType: orderDetails[0].deliveryType};
      });
 

    return  await cartService.deliveryDetails(
        {
          sellers: newPickupLoc
        },
        orderDetails[0].orderPaymentVariant
      ).then(async (res:any) => {

        setLoadingOrder(false)
        await savePaymentType("BUYER_BUY").then(async ()=>{

          await db.cart.clear()
          await db.orderDetails.clear()
          
          Logger("ORDER created", res)

          if (orderDetails[0].orderPaymentVariant === "CARD") {
            savePaymentDetail(res) 
  
            window.location.href = res.data.data.paymentProviderDetails.paymentProviderRedirectUrl;
  
          } else if (orderDetails[0].orderPaymentVariant === "WALLET" && res?.data.data.paymentTransactionStatus === "paid") {
            
            toast.success("Payment succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
           
             setTimeout(()=>{ 
                navigate(`/buyer/payment-completed?action=/buyer/my-orders`, { replace: true })
             }, 2000)
  
          } else if (orderDetails[0].orderPaymentVariant === "PAY_ON_DELIVERY") {
            toast.success("Pay on delivery Order succesful", { duration: 10000, className: 'custom-alert bg-white text-dark', position: "top-right" });
              
            setTimeout(()=>{ 
              navigate(`/buyer/my-orders`, { replace: true })
           }, 3000)
          }

        })

      

      }, (error:any) => {
        setLoadingOrder(false)
        console.warn("ORDER ERROR: ", error.response)

        ErrorHandler(error,
          () => {
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      })
} 

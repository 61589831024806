import "./BasicDetailsInput.scss";
import Countries from "../../../../../../../../Data/country_full.json";
import { useRef, FC, memo } from "react";
import States from "../../../../../../../../Data/stateNG.json";
// import {FormValuesType} from "../PLPApplicationFormConfig";
import { ErrorMessage } from "../../../../Component/index";
import { FormValuesType } from "../PLPApplicationFormConfig";
  
interface propTypes   {
    formikValues:FormValuesType
    handleChange:any;
    handleBlur : any;
    formikErrors:any;
    formikTouchedValues:any
}
export const BasicDetailsInput:FC<propTypes> = (prop) => {
    const {
        handleChange, handleBlur, formikErrors,
        formikTouchedValues, formikValues
    } = prop;

    const dropdownBtn = useRef<any>();
    const handleDropDownChange = (evt:any)=> {
        const value = evt.target.innerHTML;
        if (dropdownBtn) {
            dropdownBtn.current.innerHTML = value;
        }
        
    }

    return (
         <div className="BuyerbasicDetailInput">
            <div className="form-section-header">
              <div className="form-section-title">Deferred Payment Plan Application Form</div>
              <div className="form-section-description">This Deferred payment application form allows you access product financing. You are required to upload your 12 - months company bank statement, CAC Certificate, government ID, Utility bill and workplace ID (if applicable).</div>
            </div>
            <div className="row">
              <div className="mb-3 col-12 col-md-6 ">
                  <label htmlFor="firstNameFormControlInput" className="form-label">First name</label>
                  <input type="text" className={`form-control ${formikErrors.firstName && formikTouchedValues.firstName ? 'form-input-error' :'form-input'}`} 
                    id="firstNameFormControlInput"  placeholder="First name" name="firstName" value={formikValues.firstName}  onChange={handleChange}  onBlur ={handleBlur}
                  />
                  {
                    formikErrors.firstName  && formikTouchedValues.firstName &&
                    <ErrorMessage error={formikErrors.firstName}/>
                  }
              </div>
              <div className="mb-3 col-12 col-md-6">
                  <label htmlFor="lastNameFormControlInput" className="form-label">Last name</label>
                  <input type="text" className={`form-control ${formikErrors.lastName && formikTouchedValues.lastName ? 'form-input-error' :'form-input'}`} id="lastNameFormControlInput" placeholder="Last name"
                    name="lastName" value={formikValues.lastName}  onChange={handleChange}  onBlur ={handleBlur}
                  />
                  {
                    formikErrors.lastName  && formikTouchedValues.lastName &&
                    <ErrorMessage error={formikErrors.lastName}/>
                  }
              </div>
              <div className="mb-3 col-12 col-md-6">
                  <label htmlFor="phoneNumberFormControlInput" className="form-label">Phone number</label>
                  <div className="input-group">
                      <button ref={dropdownBtn} className="btn input-btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">+234</button>
                      <ul className="dropdown-menu custom-dropdown-menu">
                        {
                            Countries.countries.map((country,index)=>{
                                return (
                                    <li key={index} className="dropdown-item" onClick={handleDropDownChange}>+{country.phoneCode}</li>
                                )
                            })
                        }
                        
                      </ul>
                      <input type="text" id="phoneNumberFormControlInput" className={`form-control ${formikErrors.phoneNumber && formikTouchedValues.phoneNumber ? 'form-input-error' :'form-input'}`} aria-label="Text input with dropdown button"
                        name="phoneNumber" value={formikValues.phoneNumber}  onChange={handleChange}  onBlur ={handleBlur}
                      />
                  </div>
                  {
                    formikErrors.phoneNumber  && formikTouchedValues.phoneNumber &&
                    <ErrorMessage error={formikErrors.phoneNumber}/>
                  }
              </div>
              <div className="mb-3 col-12 col-md-6">
                  <label htmlFor="emailFormControlInput" className="form-label">Email address</label>
                  <input type="email" className={`form-control ${formikErrors.emailAddress && formikTouchedValues.emailAddress ? 'form-input-error' :'form-input'}`} id="emailFormControlInput" placeholder="Email address"
                    name="emailAddress" value={formikValues.emailAddress}  onChange={handleChange}  onBlur ={handleBlur}
                  />
                  {
                    formikErrors.emailAddress  && formikTouchedValues.emailAddress &&
                    <ErrorMessage error={formikErrors.emailAddress}/>
                  }
              </div>
              <div className="mb-3 col-12 col-md-6">
                  <label htmlFor="stateOfResidenceFormControlInput" className="form-label">State of residence</label>
                  <select className={`form-select form-control ${formikErrors.stateResidence && formikTouchedValues.stateResidence ? 'custom-form-select-error' : 'custom-form-select'}`} 
                    aria-label="Default select example" name="stateResidence" value ={formikValues.stateResidence}  onChange={handleChange}  onBlur ={handleBlur}
                  >
                    {
                        States[2]?.data?.map((state,index)=>{
                            return (
                                <option key={index}  value={state.name}>{state.name}</option>
                            )
                        })
                    }                
                  </select>
                  {
                    formikErrors.stateResidence  && formikTouchedValues.stateResidence &&
                    <ErrorMessage error={formikErrors.stateResidence}/>
                  }
              </div>
              <div className="mb-3 col-12 col-md-6">
                  <label htmlFor="bvnFormControlInput" className="form-label">BVN (Your BVN is used to verify your identity)</label>
                  <input type="text" className={`form-control ${formikErrors.idCardNumber && formikTouchedValues.idCardNumber ? 'form-input-error' :'form-input'}`} id="bvnFormControlInput"
                    name="idCardNumber" value={formikValues.idCardNumber} onChange={handleChange} onBlur ={handleBlur}
                  />
                  {
                    formikErrors.bvn  && formikTouchedValues.bvn &&
                    <ErrorMessage error={formikErrors.bvn}/>
                  }
              </div>
              <div className="mb-3 col-12 col-md-6">
                  <label htmlFor="bvnFormControlInput" className="form-label">ID number (Please provide your identity card number)</label>
                  <input type="text" className={`form-control ${formikErrors.bvn && formikTouchedValues.bvn ? 'form-input-error' :'form-input'}`} id="bvnFormControlInput"
                    name="bvn" value={formikValues.bvn} onChange={handleChange} onBlur ={handleBlur}
                  />
                  {
                    formikErrors.bvn  && formikTouchedValues.bvn &&
                    <ErrorMessage error={formikErrors.bvn}/>
                  }
              </div>
            </div>
        </div>
    )
}

export const MemoizedBasicDetailsInput = memo(BasicDetailsInput);
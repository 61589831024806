import React from 'react'
import "./ProductReview.scss"
import star from "../../../../../Assets/Images/icon/star.png"
import starlight from "../../../../../Assets/Images/icon/star-light.png"

const ProductReview = () => {
  return (
        <div>

          <div className="card review-list">
            <div className="card-header pb-0 text-danger">
               No review found
            </div>
          </div>

          <div className="card review-list d-none">
            <div className="card-header pb-0">
                What customers are saying about this product
            </div>
                <div className="card-body pt-0">
                    <div className="row mb-3">
                        <div className="col-6 review-rating">
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={starlight} alt=""/>
                            <span className="mx-1">4.0/5 (3 reviews)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card review-list d-none">
                <div className="card-header review-demacation pb-0">
                    What customers are saying about this product
                </div>
                <div className="card-body pt-0">
                    <div className="row mb-3">
                        <div className="col-6 review-rating">
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={starlight} alt=""/>
                            <span className="mx-1">3.0/5</span>
                        </div>
                        <div className="col-6 review-time">24th March, 2021</div>
                        </div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </div>
            </div>

            <div className="card review-list d-none">
                <div className="card-header review-demacation pb-0">
                    What customers are saying about this product
                </div>
                <div className="card-body pt-0">
                    <div className="row mb-3">
                        <div className="col-6 review-rating">
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={starlight} alt=""/>
                            <span className="mx-1">3.0/5</span>
                        </div>
                        <div className="col-6 review-time">24th March, 2021</div>
                    </div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </div>
            </div>

            <div className="card review-list d-none">
                <div className="card-header review-demacation pb-0">
                    What customers are saying about this product
                </div>
                <div className="card-body pt-0">
                    <div className="row mb-3">
                        <div className="col-6 review-rating">
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={star} alt=""/>
                            <img src={starlight} alt=""/>
                            <span className="mx-1">3.0/5</span>
                        </div>
                        <div className="col-6 review-time">24th March, 2021</div>
                    </div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </div>
            </div>
        </div>
  )
}

export default ProductReview

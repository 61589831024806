import React, { useEffect, useState, FC , Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import "./AddBankAccountModal.scss"
import { useForm } from 'react-hook-form';
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService';
import toast, { Toaster } from 'react-hot-toast';
// eslint-disable-next-line no-unused-vars
import { GetLength } from '../../../../../../../Helpers/getLength' 

interface formProps {
  bankName:string;
  accountNumber:string;
  bankCode:string;
}

interface propsType { 
  showModal:boolean;
  hide: (value:boolean)=>void
}

const AddBankAccountModal:FC<propsType> = ({showModal, hide}) => {
  const navigate = useNavigate()

  const [show, setShow] = useState(false); 
  const [savingBankDetails, setSavingBankDetails] = useState(false);
  const [nameSearchRequest, setNameSearchRequest] = useState(false); 
  const [allBank, setAllBank] = useState([]); 
  const [selectedBankObj, setSelectedBank] = useState<any>(null);
  // eslint-disable-next-line no-unused-vars
  const [myAccountNumber, setMyAccountNumber] = useState();
  const [myAccountDetails, setMyAccountDetails] = useState<any>(null);

  const handleClose = () => setShow(false); 

  const { handleSubmit, register, formState: { errors } } = useForm<formProps>()

  const onsubmit = handleSubmit((data:any) => processForm(data))

  const processForm = async (data: any) => {
    setSavingBankDetails(true)
    const bankName = JSON.parse(data.bankName).name
 
    const goog = () => {
      return false
    }
    goog()

    await bankingFinanceService.addBankAccount({ ...data, bankName, bankCode: selectedBankObj.code })
      .then((res:any) => {
        setSavingBankDetails(false)
        hide(false)
        toast.success("Bank Account Added Succesfully", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
       
        setTimeout(() => {
          return navigate("/buyer/banking-and-finance", { replace: true })
        }, 1000)
        
      }, (error:any) => {
        setSavingBankDetails(false) 
        toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  const getAllBank = async () => {
    await bankingFinanceService.getAllBank()
      .then((res) => {
        // console.log("resssss", res)
        setAllBank(res.data.data)
      }, (error:any) => {
        console.log("bank error", error.response)
      })
  }

  useEffect(() => {
    getAllBank()
  }, [])

  const selectedBank = (e: any) => {
    setSelectedBank(JSON.parse(e.target.value))
    // console.log(JSON.parse(e.target.value))
  }

  const selectBankNumber = (e: { target: { value: any; }; }) => {
    // console.log(e.target.value)
    setMyAccountNumber(e.target.value)
    if (GetLength(e.target.value) >= 10) {
      setNameSearchRequest(true)
      bankingFinanceService.VerifyAccount({
        accountNumber: e.target.value,
        bankCode: selectedBankObj.code
      }).then((res) => {
        // console.log("NAME ENQUIRY", res.data.data)
        setNameSearchRequest(false)
        setMyAccountDetails(res.data.data)
      }, (error) => {
        setNameSearchRequest(false)
        setMyAccountDetails(false)
        toast.error(error.message, { duration: 20000, className: 'bg-white text-light' });
        console.log(error.response)
      })
    }
  }

  return ( 
       <Fragment>
          <Modal 
            show={showModal} 
            onHide={()=>hide(false)}
            backdrop="static"
            keyboard={false}
            contentClassName="add-new-bank-account-modal">  
              <Modal.Header closeButton className='border-0'>
                <Modal.Title>Add Bank Account</Modal.Title>
              </Modal.Header> 
              <Modal.Body> 
                  <form onSubmit={onsubmit}> 
                      <div className='form-input'>
                          <div className='form-input-wrap'>
                            <label htmlFor="">Bank Name</label>
                              <select id="bankName" className='form-control ps-2 mt-2' placeholder='Bank Name' {...register("bankName", { required: "This field is required", onChange: (e) => { selectedBank(e) } })}>
                                <option>--select bank--</option>
                                {allBank.length > 0 && allBank.map((res:any, index:React.Key) => {
                                  return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                                })}
                              </select>
                              {errors.bankName && <div className="text-danger">{errors.bankName.message}</div>}
                          </div>
                          <div className='form-input-wrap mt-3'>
                            <label htmlFor="">Account Number </label>
                              <input id="accountNumber" type="number" className='form-control ps-2 mt-2' {...register("accountNumber", { required: "This field is required", onChange: (e) => selectBankNumber(e) })} placeholder="Account Number" disabled={!selectedBankObj || nameSearchRequest}/>
                              {nameSearchRequest && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> }
                              {/* {myAccountDetails !== false && myAccountDetails !== null && <span className='text-success fw-bolder'>{myAccountDetails.account_name}</span> } */}
                              {myAccountDetails === false && <span className='text-danger'>Invalid account number</span> }

                              {errors.accountNumber && (<div className="text-danger">{errors.accountNumber.message}</div>)}
                          </div>
                          <div className='form-input-wrap'>
                              <input id="bankCode" type="hidden" className='form-control ps-2 mt-3' placeholder='Bank Code' value={selectedBankObj && selectedBankObj.code} {...register("bankCode")}/>
                              {errors.bankCode && (<div className="text-danger">{errors.bankCode.message}</div>) }
                          </div> 

                          {myAccountDetails !== null && <div className='form-input-wrap'>
                              <input id="bankCode" type="text" className='form-control ps-2 mt-3 disabled' readOnly placeholder='Bank Code' value={myAccountDetails && myAccountDetails.account_name}/>
                              {errors.bankCode && (<div className="text-danger">{errors.bankCode.message}</div>) }
                          </div> }

                          {!savingBankDetails && <button className='btn w-100 add-btn mt-4 text-light'> Add bank account </button>}
                          {savingBankDetails && <button className="btn w-100 add-btn mt-4 text-light" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Saving...
                          </button> }
                      </div>
                  </form> 
            </Modal.Body>
          </Modal>
 

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            contentClassName="add-new-bank-account-modal"
            centered
          >
            <Modal.Header closeButton className='border-0'>
              <Modal.Title>Confirm Bank</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form>
                <div className='form-input'>
                    <label htmlFor='amount' className="bankConfirmPass mb-1 mt-5">Enter password to confirm bank</label>

                    <div className='form-input-wrap'>
                        <input id="amount" className='form-control--add-bank ps-2 mt-3' placeholder='Name on Card'/>
                    </div>
                    <button className='btn btn-primary w-100 addbank-btn mt-4 mb-5 text-light fw-bold'>Continue</button>

                </div>
            </form>
            </Modal.Body>
          </Modal>
          <Toaster/> 
      </Fragment>
  )
}

export default AddBankAccountModal

import React, { useEffect, useMemo, useState } from 'react'
import "./ProductsByBrand.scss"
import settings from "../../../../Assets/Images/icon/setting-4.png"
import sort from "../../../../Assets/Images/icon/sort.png"
import { useParams } from 'react-router-dom'
import { useFetchBrandsInfo, useFetchBrandsProducts } from '../../../../Hooks'
import { HeaderMeta, ImageSrc, Pagination } from '../../../../Components' 
import { ProductItem, ProductItemSkeleton } from '../../Components'

const ProductsByBrand = () => {
  const { brandUuid } = useParams<{brandUuid:string|undefined}>()

  const [pageNumber, setPage] = useState(1)
  const [sortOrder,] = useState("DESC")

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number,
    pageSize: number,
    total:number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1
  })
  const { isSuccess: infoSuccess, data: dataInfo, refetch: getInfo } = useFetchBrandsInfo(brandUuid)

  const { isSuccess, isLoading, data: product, isFetching, refetch: refetchProduct } = useFetchBrandsProducts(brandUuid, pageNumber, sortOrder)
 

  useEffect(() => {
    getInfo()
    refetchProduct()
  }, [brandUuid])

  useMemo(() => {
    isSuccess && setPaginationData({
      pageNumber: product?.pageNumber,
      pageSize: product?.pageSize,
      total: product?.total
    })
  }, [product])

  return (
        <div className='productsByBrand mb-5'>
        {dataInfo && <HeaderMeta title={`Brand > ${dataInfo.name}`}></HeaderMeta>}
            <div className="container my-2">

                <div className="ps-2">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/">Home</a></li>
                      <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">All Brands </a></li>
                      <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">Dangote</a></li>
                    </ol>
                  </nav>
                </div>

                <div className="brand-header">
                  <div className="card rounded-top">
                    <div className="card-body rounded-top">

                    </div>

                    <div className="card-footer rounded-bottom">
                      <div className="row justify-content-center">
                        <div className="col-1">
                         {infoSuccess && <ImageSrc src={dataInfo?.imageUrl} alt={dataInfo?.name} title={dataInfo?.name} width={''}></ImageSrc>}
                        </div>
                        <div className="col-5 my-auto">
                           <h2>{infoSuccess && dataInfo.name}</h2>
                        </div>
                        <div className="col-6  my-auto text-end">
                        {infoSuccess && <div className="sold-count">
                              1k+ Items sold
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row section-header  mt-5">
                  <div className="col-12 col-md-9 ps-3">
                    <h2><b className="me-1">{(isLoading || isFetching === true)? <span className='spinner-border spinner-border-sm'></span> : paginationData.total===0? 'No product available': paginationData.total + ' products available'}</b>  </h2>
                  </div>
                  <div className="col-6 col-md-1 p-0">
                    <button className="btn">
                      <img src={settings} className="nav-icon me-1" alt=""/> Filter
                    </button>
                  </div>
                  <div className="col-3 col-md-1 p-0">
                    <button className="btn p-1">
                      <img src={sort} className="nav-icon me-1" alt=""/> Sorting
                    </button>
                  </div>
                  <div className="col-3 col-md-1 p-0">
                    <button className="btn btn-popular p-1"> Popularity </button>
                  </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                   {(isSuccess===true && isFetching ===false) && product?.dataset?.length > 0 && product?.dataset?.map((res, index:React.Key) => <ProductItem data={res} key={index}></ProductItem>)}
                   {(isLoading === true || isFetching ===true) && <ProductItemSkeleton cardCount={10}></ProductItemSkeleton>}
                </div>

                {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                    {paginationData.total && <Pagination
                    className="pagination-bar ps-0"
                    currentPage={Number(paginationData.pageNumber)}
                    totalCount={paginationData.total}
                    pageSize={paginationData.pageSize}
                    onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                    }
                </div>}
            </div>
        </div>
  )
}

export default ProductsByBrand

import React, { Fragment, useRef, useState ,FC} from 'react' 
import "./WalletInformation.scss" 
import { useGetWalletBalance } from '../../../../../../../Hooks/Queries/Enterprise/useGetWalletBalance' 
import cwbg from '../../../../../../../Assets/Images/cb_w_bg.png'; 
import { CurrencyFormatter } from '../../../../../../../Helpers'
import EyeIcon from '../../../../../../../Assets/Icons/EyeIcon'

interface myBalanceType {
  myBalance: {
    currency: string,
    currencySymbol: string,
    amountMajor: Number
  }
}

const MortgageWalletInformation:FC<myBalanceType> = ({myBalance}) => { 
     
      useGetWalletBalance() 
      const nodeRef = useRef(null); 
  

      const [toggleShow, setToggleShow] = useState<boolean>(false)

      let hiddenAmount = String(myBalance.amountMajor);
      hiddenAmount = hiddenAmount.replace(/./g, "*");
      
    return (
        <div className='walletInformation'>    
          <div className="estate-primary-wallet card border-0 p-0 position-relative mt-4 mt-sm-5">
            <div className="card-body pb-0 px-0 overflow-hidden">
 
                <div className="container" ref={nodeRef}>
                    <div className="wallet-type">Available Credit
                     <span><EyeIcon toggleShow={toggleShow} setToggleShow={setToggleShow}/></span>
                    </div>

                    <div className="balance mt-1">{myBalance.currencySymbol}
                     {toggleShow ?<Fragment>{CurrencyFormatter(Number(myBalance?.amountMajor) ? Number(myBalance.amountMajor) : 0)}</Fragment> : hiddenAmount}
                    </div>
                    <div className="row mt-5">
                        <div className="col-4">
                         </div> 
                        <div className="col-8"> 
                            <img src={cwbg} className='wBg'/>
                        </div>
                    </div>
                </div>   
            </div>  
        </div> 
        </div>
    )
}

export default MortgageWalletInformation

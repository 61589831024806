import React, { useEffect, useState } from 'react'
import "./Registration.scss"
import whiteLogo from "../../../../Assets/Images/logo-white.png"
import cinderBg from "../../../../Assets/Images/cinderBg.png" 
import Logo from "../../../../Assets/Images/cinderBuildLogo.png"
import { Link, useNavigate, useParams } from 'react-router-dom'
import authService from '../../../../Services/authService'
import { ErrorHandler } from '../../../../Helpers/ErrorHandler'
import { useForm } from 'react-hook-form'
import { HeaderMeta } from '../../../../Components'
import ReactGA from 'react-ga';
import { Footer } from '../../../../Layout'
// import { Logger } from '../../../../Helpers/Logger'

interface propTypes {
  lastName: string;
  firstName: string;
  countryLongName: string;
  phoneNumber: string;
  distributorLink: string;
  password: string;
  findUsOption: string;
  isSeller: string;
  emailAddress: string;
  defaultSellerUniqueCode: string;
}

const Registration = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<any>(null)

  const { distributorlink } = useParams<{ distributorlink: any }>();
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const [submit, setSubmit] = useState(false)
  const [findOption, setFindOption] = useState<string | null>(null)

  const [contryList, setContryList] = useState<any>([])
  // eslint-disable-next-line no-unused-vars
  const [selectedCountry, setSelectedCountry] = useState<any>({
    name: 'Nigeria',
    iso2: 'NG',
    phoneCode: '234',
    currency: 'NGN',
    currencySymbol: '₦',
    image: {
      url: 'https://res.cloudinary.com/tradegrid/image/upload/v1644746374/nigeria-gfdaf34ede_640_o1zj6m.png',
      fileCloudProvider: '',
      keyFromCloudProvider: ''
    }
  });

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<propTypes>();

  const onSubmit = handleSubmit((data: any) => processLogin(data));

  const processLogin = async (datas: any) => {
    setSubmit(true)
    let serializedObj = { ...datas, role: "normal_user" };

    if (datas.isSeller === '2') {
      serializedObj = { ...datas, isSeller: false, role: "affiliate" }
    }

    await authService.register(serializedObj)
      .then(
        (data: any) => {

          if (data.data.status === false) { 

              setError(data.data.message)
              setSubmit(false)

            } else {

              localStorage.setItem("temp__reg", JSON.stringify(datas))
              navigate("/otp", { replace: true });
              setSubmit(false) 

            } 
        },
        (error: any) => {
          setSubmit(false)
          ErrorHandler(error,
            (e:any) => {
              setError(e)
            })
        }
      );
  }

  const changeCountry = (e: any) => { 
    setContryList(e)
  }

  const getCountry = async () => {
    await authService.getCountry().then((res: any) => {
      setContryList(res.data.data)
      // //Logger("res.data.data", res.data.data)
    },
    (error:any) => { 
      ErrorHandler(error,
        () => {
          setError(error.message) 
        })
    }) 
  }

  useEffect(() => {
    getCountry()
  }, [])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    setValue('defaultSellerUniqueCode', distributorlink, { shouldValidate: true })
  }, [distributorlink])


  const [terms, setTerms] = useState(false)
  const handleTerms = () => { 
    setTerms(!terms)
  }

  return (
    <React.Fragment>
      <div className="register">
        <HeaderMeta title="Registration"></HeaderMeta>
        <div className="row h-100">
          <div className="col-md-6 d-none d-md-block">
            <div className="register-right">
              <div className="container">
                <div className="register-header">
                  <div className="row">
                    <div className="col-md-2 text-end">
                      <div className="register-back">
                        <Link
                          to={'/'}
                        >
                          <span className="d-icon d-arrowhead-left shadow"></span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="register-logo">
                        <img src={whiteLogo} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="register-text">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-md-8">
                        <h2>Welcome to CinderBuild.
                          <br></br>Sign up to get started</h2>
                        <div className="desc">
                          Fill in your details to sign up on CinderBuild.<br />
                          Do you already have an account? Sign in
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <img src={cinderBg} alt="" className='abstImg' />
            </div>
          </div>

          <div className="col-md-6 my-auto">
            <div className="register-header d-md-none">
              <div className="px-4 mt-4 pb-2">
                <div className="row  h-100">
                  <div className="col-2">
                    <div className="register-back">
                      <Link to="/"><span className="d-icon d-arrowhead-left"></span></Link>
                    </div>
                  </div>
                  <div className="col-8 text-start ps-0 my-auto">
                    <div className="register-logo">
                      <img src={Logo} alt=""  className='w-75'/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="register-form pb-5 pt-5" data-aos="fade-up">
              <div className="row justify-content-center">
                <div className="col-md-8">

                  <h1>Sign up</h1>
                  <form onSubmit={onSubmit}>

                    <div className="form-group mb-3">
                      <label htmlFor="">First Name</label>
                      <input type="text" className="form-control" placeholder="Enter your first name"
                        {...register("firstName", { required: "field cannot be empty" })}
                      />
                      <div className="text-danger">{errors.firstName && errors.firstName.message}</div>
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="">Last name</label>
                      <input type="text" className="form-control" placeholder="Enter your last name"
                        {...register("lastName", { required: "field cannot be empty" })}
                      />    <div className="text-danger">{errors.lastName && errors.lastName.message}</div>
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="">Phone number</label>
                      <div className="row">
                        <div className="col-4 col-sm-4 col-md-4 col-lg-3">
                          <div className="dropdown">
                            <button className="form-control input dropdown-toggle border" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src={selectedCountry.image.url} className="w--25 rounded" /> +{selectedCountry.phoneCode}
                            </button>
                            <ul className="dropdown-menu border-0 shadow w---10" aria-labelledby="dropdownMenuButton1">
                              {contryList.length > 0 && contryList.map((res: any, index: any) => {
                                return (<li key={index} className="w---10" onClick={() => changeCountry(res)}>
                                  <div className="dropdown-item">
                                    <img src={res.image.url} className="w--25 rounded-circle" /> +{res.phoneCode}</div></li>)
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className="col-8 col-sm-8 col-md-8  col-lg-9 ps-0">
                          <input type="hidden" {...register("countryLongName")} className="type-input" value={selectedCountry.name} />
                          <input className="form-control input"
                            type="text"
                            placeholder="Phone Number"
                            {...register("phoneNumber", { required: "field cannot be empty" })}
                          />
                          <div className="text-danger">{errors.phoneNumber && errors.phoneNumber.message}</div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="form-group mb-3">
                      <label htmlFor="">Distributor’s link (optional)</label>
                      <input
                        className="form-control input"
                        type="text"
                        placeholder="Distributor's Link (Optional)"
                        {...register("defaultSellerUniqueCode")}
                        readOnly
                      />
                      <div className="text-danger">{errors.distributorLink && errors.distributorLink.message}</div>
                    </div> */}

                    <div className="form-group mb-3">
                      <label htmlFor="">Email address</label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email *"
                        autoComplete='off'
                        {...register("emailAddress", { required: "field cannot be empty" })}
                      />    <div className="text-danger">{errors.emailAddress && errors.emailAddress.message}</div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="">Join CinderBuild as</label>
                      <select
                        className="form-control"
                        {...register("isSeller", { required: "field cannot be empty" })}
                      >
                        <option>--select--</option>
                        {/* <option value="true">Join as a Seller</option> */}
                        <option value="false">Join as a Buyer</option>
                        <option value="2">Join as an Affiliate</option>
                      </select>
                      <div className="text-danger">{errors.isSeller && errors.isSeller.message}</div>
                      <div className='text-end'><Link to="/enterprise-setup" className='corporate-link'>Signup as Corporate</Link> </div>
                    </div>

                    <div className="form-group password-container mb-3">
                      <label htmlFor="password-field">Password</label>
                      <input
                        className="form-control"
                        id="password-field"
                        type={!showPassword ? "password" : "text"}
                        placeholder="Password"
                        {...register("password", { required: "field cannot be empty" })}
                      />
                      {!showPassword && <button className="btn p-0 field-icon" onClick={() => setShowPassword(true)}><i className="fas fa-eye"></i></button>}
                      {showPassword && <button className="btn p-0 field-icon" onClick={() => setShowPassword(false)}><i className="fas fa-eye-slash" aria-hidden="true"></i></button>}

                      <div className="text-danger">{errors.password && errors.password.message}</div>
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="findUsOption">How did you hear about us?</label>
                       <select
                        id="findUsOption"
                        className="form-control"
                        {...register("findUsOption")}
                        onChange={(e: any) => setFindOption(e.target.value)}
                      >
                        <option value="">--select an option--</option>
                        <option>Facebook</option>
                        <option>Instagram</option>
                        <option>LinkedIn</option>
                        <option>Google</option>
                        <option>Friends/ Family </option>
                        <option>CinderBuild reps</option>
                        <option>Referral from CinderBuild user</option>
                        <option>Banners/ Signage</option>
                        <option>Other</option>
                      </select> 

                      {findOption === "Other" && <div className='mt-3'> 
                            <label htmlFor="findUsOption">Please tell us how you heard about us?</label>
                            <input type="text" placeholder='Start typing...' {...register("findUsOption")} className="form-control input" data-aos="fade-left"></input>
                        </div>}
                      <div className="text-danger">{errors.isSeller && errors.isSeller.message}</div>
                    </div> 
                    <div className="form-group my-3 d-inline">
                      <label htmlFor="terms"><input type="checkbox" id="terms" className='ms-checkbox' required  onChange={handleTerms}/> Accept the <Link to="/terms-and-conditions">Terms & Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link></label>
                    </div>

                    {error !== null && <div className="alert alert-danger d-flex align-items-center mt-2 alert-dismissible fade show">
                      <i className="d-icon d-warning text-danger bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                      <div className="text-danger">
                        {error}
                      </div>
                      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>}

                    <div className="form-group mt-4">
                      {!submit && (<button type="submit" className="btn btn-register" disabled={!terms}>Signup</button>)}
                      {submit && (<button type="submit" className="btn btn-register" disabled>
                        Please wait
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>)}
                    </div>
                    <div className="signup mt-3">
                      Already have an account? <Link to="/login" className="text-primary">Log in</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </React.Fragment>
  )
}

export default Registration

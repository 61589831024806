import { BUSINESS_DOCUMENT_UPLOAD, CAC_DOCUMENT_UPLOAD, BANK_DOCUMENT_UPLOAD } from "../../Constants/SellerDocuments";
// import { useLocalStorage } from '../../Hooks/UseLocalStorage';

const initialState = {
  businessInfo: {
    businessName: "",
    cacNumber: "",
    businessAddress: "",
  },
  cacUpload: {
      cacCertificate: "",
      identificationCard: "",
  },
  bankInformation: {
      bankName: "",
      accountNumber: "",
  },
};

const DocumentUploadReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case BUSINESS_DOCUMENT_UPLOAD: {
      // eslint-disable-next-line no-case-declarations
      const { businessName, cacNumber, businessAddress } = action.payload;
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          businessName,
          cacNumber,
          businessAddress,
        },
      };
    }

    case CAC_DOCUMENT_UPLOAD: {
      // eslint-disable-next-line no-case-declarations
      const { cacCertificate, identificationCard } = action.payload;
      return {
        ...state,
        cacUpload: { ...state.cacUpload, cacCertificate, identificationCard },
      };
    }

    case BANK_DOCUMENT_UPLOAD: {
        // eslint-disable-next-line no-case-declarations
          const { bankName, accountNumber} = action.payload;
          return {
              ...state,
              bankInformation: {    ...state.bankInformation, bankName, accountNumber }
             }
    }
    default:
      return state;
  }
};

export default DocumentUploadReducer;

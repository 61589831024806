import React, { useState } from 'react'
import "./Cart.scss"

import location from "../../../../Assets/Images/icon/location-slected.png"
import mastercard from "../../../../Assets/Images/mastercard.png"
import Visa from "../../../../Assets/Images/Visa.png"
import { Link } from 'react-router-dom'
import { HeaderMeta } from '../../../../Components'
import { CartItem, SimilarProduct } from '../../Components'
import NewsLetter from '../../Components/Home/NewsLetter/NewsLetter'
import { db } from '../../../../Config/DBConfig'
import { useLiveQuery } from 'dexie-react-hooks'
import { Logger } from '../../../../Helpers/Logger'
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider'
import { CurrencyFormatter } from '../../../../Helpers'

const Cart = () => {
  const [totalCost, setTotalCost] = useState<number>(0);

  useLiveQuery(
    async () => {
      const cartItems = await db.cart.toArray()
      Logger("cartItemss", cartItems)
      let newCost = 0
      cartItems.length > 0 && cartItems.map((res:any) => {
        newCost += res.productDetails.unitPriceForBuyer * res.quantity;
        setTotalCost(newCost);
        return 1
      })
    })

  const { user } = useAuth()

  return (
        <div className='cart'>
          <HeaderMeta title="Cart"></HeaderMeta>
            <div className="container my-2">
                <div className="ps-2">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">Cart </a></li>
                    </ol>
                    </nav>
                </div>
            </div>

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Shopping cart</h1>
                        {user
                          ? <div className="location-address d-none">
                            <img src={location} alt="" className="location-selected"/>
                            14, Tunde Hassan street, Lagos, Nigeria
                        </div>
                          : <div className="location-address">
                            <Link to="/login">Log in to checkout faster</Link>
                        </div>}

                        <CartItem></CartItem>

                <div className="continue-shoping">
                    <Link to="/products"><button className="btn">Continue shopping</button></Link>
                </div>
            </div>

            <div className="col-md-4">
                <div className="cart-summary">
                    <div className="card border-0">
                        <div className="card-header border-0">
                        Summary
                        </div>
                        <div className="card-body">
                        <div className="row">
                            <div className="col-6 cart-el">
                            Subtotal
                            </div>
                            <div className="col-6 cart-el">
                            ₦{CurrencyFormatter(totalCost) }
                            </div>
                            <div className="col-6 cart-el d-none">
                            Delivery
                            </div>
                            <div className="col-6 cart-el d-none">
                            ₦{CurrencyFormatter(totalCost)}
                            </div>
                            <div className="col-12 text-left">
                            <hr/>
                            </div>

                            <div className="col-6 ">
                                <div className="cart-total">Total</div>
                            </div>
                            <div className="col-6">
                                 <div className="cart-total"> ₦{CurrencyFormatter(totalCost)}</div>
                            </div>

                            <div className="col-12">
                                <Link to="/checkout" className="btn checkout-btn mt-4 w-100">Checkout</Link>
                            </div>

                            <div className="col-12 text-left">
                            <hr/>
                            </div>

                            <div className="col-12 text-left havecoupon">
                            Do you have a discount code?
                            </div>

                            <div className="col-12">
                                <input type="text" className="form-control" placeholder="Enter discount code"/>
                            </div>

                            <div className="col-12">
                                <button className="btn apply-btn mt-4 w-100">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="payment-option">
                Acceptable payment methods
                <div className="">
                    <img src={mastercard} className=""/>
                    <img src={Visa} className=""/>
                </div>
            </div>
            </div>
        </div>
        <hr/>
        </div>
        </section>

        {/* BEST DEALS */}
        <section>
        <div className="bestdeals mb-5 pt-5">
            <SimilarProduct></SimilarProduct>
        </div>
        </section>

        <NewsLetter></NewsLetter>
        </div>

  )
}

export default Cart

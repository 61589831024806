import React from 'react'
import "./EnterprisePLP.scss"
import { Outlet } from 'react-router-dom';

const EnterprisePLP = () => {
    return (
        <div className='enterprisePLP'>
            <div className="container">
               <Outlet/>
             </div>
        </div>
    )
}

export default EnterprisePLP

import React, { useEffect, useState } from 'react'
import "./EstateSubscriberTransactionDetails.scss"
import { HorizontalLoader } from '../../../../../../../Components' 
import toast, { Toaster } from 'react-hot-toast' 
import ImageCollage from '../../../../../../../Components/ImageCollage/ImageCollage' 
import { useParams } from 'react-router-dom';  
import investorService from '../../../../../../../Services/investorService'
import { transactionType } from '../../../../../../../Models/transactionType'
import { CurrencyFormatter } from '../../../../../../../Helpers/CurrencyFormatter';

const EstateSubscriberTransactionDetails = () => {
 

     const { uuid } = useParams<{ uuid: string|undefined }>()
   
    const [loadingRequest, setLoadingRequest] = useState(false)  

    const [transaction, setTransaction] = useState<transactionType>()

    const getTransaction = async () => { 
        setLoadingRequest(true)
        await investorService.getTransactionsDetails(uuid).then((response)=>{ 
          setLoadingRequest(false)
          setTransaction(response.data.data)
        }, error => { 
            setLoadingRequest(false)
            toast.error(error.response.data.error, { duration: 20000, className: 'bg-white text-dark', position: "top-right" });
     
            console.log("projectService error", error)
        })
    }

    useEffect(()=>{
        getTransaction()
    }, [uuid])
    
    return (
        <div className='EstateSubscriberTransactionDetails'>
            <div className="container">
                <div className="top-header mt-4"> 
                        {transaction && <div className="page-title"> 
                                <h2>Transaction details</h2>   
                            </div>}
                        </div>
                    {loadingRequest && <HorizontalLoader></HorizontalLoader>} 
                </div>  
 
           <div className='container mt-4'>
                {transaction && <div className="row">
                    <div className="col-md-7"> 
                        <ImageCollage images={transaction.project.images}></ImageCollage> 
                    </div>
                    <div className="col-md-5">  
                        <div className="project-content mt-3">
                            <div className="row">
                                <div className="col-3 mt-3">
                                    <div className="label">Project Name:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{transaction.project.name}</div>
                                </div> 

                                <div className="col-3 mt-3">
                                    <div className="label">Project description</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{ transaction.project.details}</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Amount Paid:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">₦{CurrencyFormatter(transaction.amountPaidMinor)}</div>
                                </div> 
                                <div className="col-3 mt-3">
                                    <div className="label">Amount Remaining:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">₦{CurrencyFormatter(transaction.amountRemainingMinor)}</div>
                                </div>
                                {/* <div className="col-3 mt-3">
                                    <div className="label">Amount After:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">₦{CurrencyFormatter(transaction.amountAfterMinor)}</div>
                                </div> */}
                                <div className="col-3 mt-3">
                                    <div className="label">Description:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount"> {transaction.description}</div>
                                </div> 
                                <div className="col-3 mt-3">
                                    <div className="label">Initial Investment Percentage:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{ transaction.project.initialInvestmentPercentage}%</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Duration:</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{ transaction.project.duration} months</div>
                                </div>

                                <div className="col-3 mt-3">
                                    <div className="label">Address</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{ transaction.project.address}</div>
                                </div>
                                <div className="col-3 mt-3">
                                    <div className="label">State</div>
                                </div>
                                <div className="col-9 mt-3">
                                    <div className="amount">{ transaction.project.state}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div> 
            <Toaster></Toaster>
        </div>
    )
}

export default EstateSubscriberTransactionDetails

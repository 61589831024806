import { Outlet } from "react-router-dom";
import "./MyAccount.scss";

const  MyAccount = ()=> {
    return <div className="buyer-my-account">
        <div className="container">
        <div className="my-account-outlet">
            <Outlet/>
        </div>
        </div>
    </div>
}

export default MyAccount;

import React, { useEffect } from 'react'
import { AddToCartButton, HomeNewsletter, ProductAds, ProductDescription, ProductImage, ProductOption, ProductQuantity, ProductReview, ProductSaveForLaterButton, ProductVariation, SimilarProduct } from '../../Components'
import "./ProductSinglePage.scss" 
import star from "../../../../Assets/Images/icon/star.png"
import starlight from "../../../../Assets/Images/icon/star-light.png"
import { useParams } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import ProductSinglePageSkeleton from '../../Components/ProductSinglePage/SingleProductSkeleton/ProductSinglePageSkeleton'
import { BreadCrumb, HeaderMeta } from '../../../../Components'
import ReactGA from 'react-ga';
import { useGetProduct } from '../../../../Hooks'
import { useQueryClient } from '@tanstack/react-query'
import PriceCalculate from '../../Components/ProductSinglePage/PriceCalculate/PriceCalculate';
 
const ProductSinglePage = () => {
  const { productUuid } = useParams<{ productUuid:string }>()

  const queryClient = useQueryClient();
  const { isSuccess, data: productDetail, isLoading, isFetching, refetch: getProduct } = useGetProduct(productUuid);

  useEffect(() => {
       ReactGA.pageview(window.location.pathname) 
        ReactGA.ga('event', 'view_item', {
            items: [
            {
                id: "P12345",
                name: "Android Warhol T-Shirt",
                list_name: "Search Results",
                brand: "Google",
                category: "Apparel/T-Shirts",
                variant: "Black",
                list_position: 1,
                quantity: 2,
                price: '2.0'
            }
            ]
        });
  
      // Add the transaction.
      ReactGA.ga('ecommerce:addTransaction', {
        id: '1234',     // Transaction ID.
        affiliation: 'Google Store',
        revenue: '29.97',
        shipping: '5.25',
        tax: '2.42'
    });
    
    // Add the items belonging to the transaction.
    ReactGA.ga('ecommerce:addItem', {
        id: '1234',     // Transaction ID.
        name: 'Android Warhol T-Shirt',
        sku: 'P12345',  // Item ID.
        category: 'Apparel/T-Shirts',
        price: '12.99',
        quantity: '2'
    });

    ReactGA.ga('ecommerce:addItem', {
        id: '1234',     // Transaction ID.
        name: 'Flame challenge T-Shirt',
        sku: 'P67890',  // Item ID.
        category: 'Apparel/T-Shirts',
        price: '3.99',
        quantity: '1'
    });
    
    ReactGA.ga('ecommerce:send');

    window.scrollTo(0, 0)
    getProduct()

    return () => {
      queryClient.invalidateQueries(['productsSingle'])
    }
  }, [productUuid])

 
 
  return (
        <div className='productSinglePage'>
            <HeaderMeta title={isSuccess ? productDetail.productName : ""}></HeaderMeta>

            {(isLoading === true || isFetching === true) && <ProductSinglePageSkeleton></ProductSinglePageSkeleton>}

            {isLoading === false && isFetching === false && <BreadCrumb></BreadCrumb>}
           { (isLoading === false && isFetching === false) && <section>
                <div className="product-element pb-5">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-md-7">
                                <div className="mb-5 mt-3">
                                    <h1>{isSuccess && productDetail.productName}</h1>
                                    <div className="desc">
                                        <span className="me-2">Brand: {isSuccess && productDetail?.brand?.name}</span>   <span className="mx-2  d-none">| Location: {isSuccess && productDetail.sellerPublicProfile?.businessProfile?.businessAddress} |</span>  <span className="mx-2  d-none">
                                            <img src={star} alt=""/>
                                            <img src={star} alt=""/>
                                            <img src={star} alt=""/>
                                            <img src={star} alt=""/>
                                            <img src={starlight} alt=""/>
                                            <span className="mx-1">4.0/5 (0 reviews)</span>
                                        </span>
                                    </div>
                                </div>
                               <ProductImage></ProductImage>
                            </div>
                            <div className="col-md-5 my-auto">
                                <div className="product-details px-md-5">
                                    <div className="price">
                                    {isSuccess && Number(productDetail.unitPriceForBuyer) !== 0 && productDetail?.currencySymbol}

                                    {isSuccess && productDetail.unitPromoPriceForBuyer !== 0
                                      ? isSuccess && productDetail.unitPromoPriceForBuyer
                                      : (isSuccess && Number(productDetail.unitPriceForBuyer) === 0? "Request for a quote": productDetail?.unitPriceForBuyer)
                                    }
                                    </div>

                                    <div className="soldby">
                                    Sold by: {isSuccess && productDetail.sellerPublicProfile.firstName} {isSuccess && productDetail.sellerPublicProfile.lastName}
                                    </div> 
                                        <hr/>
                                        {isSuccess && <ProductQuantity maximumQuantity={productDetail?.maximumQuantity} minimumQuantity={productDetail?.minimumQuantity}></ProductQuantity>}
                                        <hr/>
                                        {isSuccess && productDetail?.tags?.length > 0 && <ProductVariation productDetail={productDetail}></ProductVariation>}

                                        <div className="pt-3">
                                            <AddToCartButton></AddToCartButton>
                                            {isSuccess &&
                                            (productDetail.category.uuid === "216b8687-de0d-4cf6-8cbf-f2cff870abf8" || productDetail.category.uuid === "66846c92-152a-4088-af87-5e5e7a3a7255")
                                              ? <PriceCalculate></PriceCalculate>
                                              : ""}
                                        </div> 
                                    
                                    <div className="">
                                        <ProductSaveForLaterButton></ProductSaveForLaterButton>
                                    </div>
                                    <ProductOption></ProductOption>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}

            {isLoading === false && isFetching === false && <section>
                   <div className="product-description">
                        <div className="container mt-md-5 pt-md-5 ">
                            <hr/>
                                <div className="row">
                                   <div className="col-md-6">
                                        <nav>
                                            <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
                                                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Product description</button>
                                                <button className="nav-link reviewtab d-none" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                                                  <span>Reviews<i className="review-count">0</i></span>
                                                </button>
                                            </div>
                                        </nav>
                                        <div className="tab-content border-0" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex={0}>
                                               {productDetail && <ProductDescription productDescription={productDetail?.productDescription}></ProductDescription>}
                                            </div>
                                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex={0}>
                                                <ProductReview></ProductReview>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mt-2 mt-md-0">
                                        <ProductAds></ProductAds>
                                    </div>
                               </div>
                            </div>
                        </div>
                    <hr/>
            </section>}
 
           

            {isLoading === false && isFetching === false && <SimilarProduct></SimilarProduct>}

            {isLoading === false && isFetching === false && <HomeNewsletter></HomeNewsletter>}

            <Toaster></Toaster>
            
        </div>
  )
}

export default ProductSinglePage

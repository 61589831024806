import "./SellerProfileHeader.scss";
import {FC} from "react";
import SellerProfileAvatar from "../SellerProfileAvatar/SellerProfileAvatar";
// import SecuritySafeOutlineIcon from "../../../../../../../Assets/Images/icon/security-safe-outline.svg";

interface propType {
    name:string,
    picture:string
}

const SellerProfileHeader:FC<propType> = ({name, picture}) => {
    return (
        <div className="profile-header pe-5">
            <div className="avatar-name">
                <SellerProfileAvatar picture={picture }/>
                <p className="profile-name">{name}</p>
            </div>
        </div>
    )
}

export default SellerProfileHeader;
 
import { SubPageTitle } from "../../../Component";
import BankingAndFinance from "./BankingAndFinance/BankingAndFinance";

const BankAndFinance = ()=>{
    return  (
       <div className="buyer-bankAndFinance">
            <SubPageTitle
                title="Bank & Finance"
                parents={[
                    {
                        title:"My account",
                        url: "my-account"
                    }
                ]}
            />
            <div className="row justify-content-center">
                <div className="col-12 profile-item-container mb-4">
                    <BankingAndFinance />
                </div>
            </div>
       </div>
    )

}

export default BankAndFinance;
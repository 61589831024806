import "./ProfileHeader.scss";
import {FC} from "react"; 
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
 
interface propType {
    name:string,
    picture:string 
    module?: string
  }

const ProfileHeader:FC<propType> = ({name, picture, module}) => {
    return (
        <div className="profileHeader">
            <div className="pictureAndName">
                <ProfileAvatar picture={picture} module={module}/>
                <div className="profileName">{name}</div>
            </div> 
        </div>
    )
}

export default ProfileHeader;
import React, { FC , useState , Key } from 'react'
import "./ProductVariation.scss"
import { productType } from '../../../../../Models'
import { Offcanvas } from 'react-bootstrap'
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';
import { Link } from 'react-router-dom';
import { db } from '../../../../../Config/DBConfig';
import { useDispatch } from 'react-redux';
import { TOGGLE_CART_DRAWER } from '../../../../../Constants/CartConstants';
import toast from 'react-hot-toast'; 
import QuoteRequest from '../QuoteRequest/QuoteRequest';
import { GetColorName } from 'hex-color-to-color-name';

interface propsType {
    productDetail: productType|undefined
}


const ProductVariation:FC<propsType> = ({productDetail}) => {
    console.log("ProductVariation data", productDetail)
     
    const [showQuote, setShowQuote] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    // eslint-disable-next-line no-unused-vars
    const [loadingCart, setLoadingCart] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [alreadyAdded, setAlreadyAdded] = useState(false)
   

    const dispatch = useDispatch()

    const addtocart = async (productUuid:string, productDetailVal:any) => {
        setLoadingCart(true);
        setShow(false)
        try { 
           await db.cart.add({
            productUuid,
            quantity: 1,
            productDetails: productDetailVal
          });

          setLoadingCart(false);
          setAlreadyAdded(true)
          dispatch({ type: TOGGLE_CART_DRAWER, payload: { active: true, placement: "end" } })
    
          toast.success(`Added to cart`, { duration: 2000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        } catch (error) {
          setAlreadyAdded(false)
          setLoadingCart(false);
          toast.error(`Failed to add ${name}: ${error}`, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        }
      }


    return (
        <div className='productVariation'>
            <div className='title'>Variation Available</div> 
            {productDetail?.tags.map((res, index:React.Key)=>{
                return Number(index) < 3 && <button key={index} className='btn btn-variable me-2 shadow-sm d-flex-inline justify-content-center text-center' onClick={()=>setShow(true)}>
                    <div>
                    {res.attribute.map((resInner:{name:string, value:any[]})=>{
                        if (res.attribute.length > 1) {
                            if (resInner?.name ==="color") { 
                                return <div key={index} className='row'>
                                   {resInner?.value.map((resColor,index)=><div key={index} className='col rounded-circle d-flex justify-content-center ' style={{width: '20px'}}><div  style={{backgroundColor: resColor, width: '20px', height: '20px'}} title={GetColorName(resColor)}></div></div>)}
                                   </div>
                            } else { 
                                return resInner?.value?.join(', ')
                            }
                        } else {
                            return resInner?.value?.join(', ')
                        }
                        
                        } )}
                        </div>
                    </button>
                })
            } 
            {productDetail?.tags && productDetail?.tags?.length > 3 && <a href="#" className="view_all" onClick={()=>setShow(true)}>view more</a>}
            <hr></hr>
            <Offcanvas 
                show={show} 
                onHide={handleClose} 
                placement= "end" className="productVariation-modal">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="pb-4">{productDetail?.productName} Variations</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <div className='text-center mt-2'>
                          <div className='table-responsive'>
                                <table className='table table-bordered'>
                                    <tbody> 
                                    {productDetail?.variantsProducts.map((res, index:React.Key) => {
                                        return <tr key={index}>
                                            <td>
                                                <Link to={`/product/${res.productUuid}`}>
                                                    <ImageSrc src={res?.images[0]?.url} alt={res.productName} title={res.productName} width={'imgTag'}></ImageSrc>
                                                </Link>
                                            </td> 
                                            <td>
                                                <div className='fw-bold'>
                                                    {res.tags.variable_title}
                                                </div>
                                                <div className='var_attributes'>
                                                    {res.tags.attribute.map((resInner:{name:string, value:any[]}, index:Key)=>{
                                                            if (res.tags.attribute.length > 1) {
                                                                if (resInner?.name ==="color") {
                                                                    return resInner?.value.map((resColor,index)=><><br></br><div key={index} style={{backgroundColor: resColor, width:'20px', height: '20px', display: 'inline-flex'}} title={GetColorName(resColor)}></div></>)
                                                                } else { 
                                                                    return resInner?.value?.join(', ')
                                                                }
                                                            } else {
                                                                return resInner?.value?.join(', ')
                                                            } 
                                                    } )}
                                                </div>
                                            </td> 
                                            <td>{res.price !==0 ? `${res.currencySymbol}${res.currencySymbol} ${res.price}` : ""}</td>
                                            <td>
                                                {res.price !==0 && <button className="btn btn-primary cart-btn" onClick={()=>addtocart(res.productUuid, res)}>Add To Cart</button>}
                                                {res.price ===0 && <button className="btn btn-primary cart-btn" onClick={()=>setShowQuote(true)}>Request For Quote</button>}
                                            </td>
                                        </tr> 
                                        })}
                                    </tbody>
                                </table>
                          </div> 
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            {/* <Modal show={show} onHide={handleClose} size="lg"  aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-90w" contentClassName="alert-modal-inner" centered>
                <Modal.Header closeButton className='border-0 text-center'>
                    <Modal.Title id="contained-modal-title-vcenter" className='fs-6'></Modal.Title> 
                </Modal.Header>
                <Modal.Body>
                  
                </Modal.Body>
             </Modal> */}
       <QuoteRequest productDetail={productDetail} show={showQuote} hide={(val:boolean) => setShowQuote(val)}></QuoteRequest>
        </div>
    )
}

export default ProductVariation

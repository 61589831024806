import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast' 
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService' 
import "./WalletInformation.scss" 
import { useGetWalletBalance } from '../../../../../../../Hooks/Queries/Enterprise/useGetWalletBalance'
import PrimaryWallet from './PrimaryWallet'
import SecondaryWallet from './SecondaryWallet'

const WalletInformation = () => {
    const [walletType, setWalletType] = useState(1) 
  
   const [myBalance, setMyBalance] = useState({
      currency: "",
      currencySymbol: "",
      amountMajor: ""
    })
 
  const [mySecondaryBalance, setMySecondaryBalance] = useState({
      currency: "",
      currencySymbol: "",
      amountMajor: ""
  })
  
  
    const getMyBalance = async () => { 
        await bankingFinanceService.getMyBalance()
          .then((res:any) => { 
            setMyBalance(res.data.data) 
          }, (error:any) => { 
            toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }


      const getMySecondaryBalance = async () => {
        await bankingFinanceService.getMySecondaryBalance()
         .then((res:any) => {
            setMySecondaryBalance(res.data.data) 
         }, (error:any) => {
            toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
         })
     }
    
    
      useEffect(() => {
        getMyBalance() 
        getMySecondaryBalance() 
      }, [])

      useGetWalletBalance() 
      const nodeRef = useRef(null); 


    
      function useDelayUnmount (isMounted: boolean, delayTime: number) {
        const [shouldRender, setShouldRender] = useState(false);
      
        useEffect(() => {
          let timeoutId: any;
          if (isMounted && !shouldRender) {
            setShouldRender(true);
          } else if (!isMounted && shouldRender) {
            timeoutId = setTimeout(() => setShouldRender(false), delayTime);
          }
          return () => clearTimeout(timeoutId);
        }, [isMounted, delayTime, shouldRender]);
        return shouldRender;
      }


      const [isMounted, setIsMounted] = useState(true);
      const shouldRenderChild = useDelayUnmount(isMounted, 500);
      const mountedStyle = { animation: "inAnimation 500ms ease-in" };
      const unmountedStyle = { animation: "outAnimation 510ms ease-in" };
    
       
      const handleToggleClicked = (val:React.SetStateAction<number>) => {
        setWalletType(val)
        setIsMounted(!isMounted);
      };


    return (
        <div className='walletInformation'>   

          {walletType === 1 && shouldRenderChild && <div style={isMounted ? mountedStyle : unmountedStyle}><PrimaryWallet walletType={walletType} setWalletType={(val: React.SetStateAction<number>)=>handleToggleClicked(val)} myBalance={myBalance} nodeRef={nodeRef}></PrimaryWallet></div>}
          {walletType === 2 && <SecondaryWallet walletType={walletType} setWalletType={(val: React.SetStateAction<number>)=>handleToggleClicked(val)} myBalance={mySecondaryBalance} nodeRef={nodeRef}></SecondaryWallet>}
          
        </div>
    )
}

export default WalletInformation

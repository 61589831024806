import {FC} from "react";
import "./NavCard.scss";
import  {Link} from "react-router-dom";

interface propsType {
    title:string;
    icon:string;
    description:string;
    link:string;
}

const NavCard: FC<propsType> = ({title, icon, description,link}) => {
    return (
        <li className="nav-card">
            <Link className="nav-link" to={link}>
                <div className="icon-container">
                    <img src={icon} alt=""/>
                </div>
                <div className="link-details">
                    <p className="title">{title}</p>
                    <p className="description">{description}</p>
                </div>
            </Link>
        </li>
    )
}

export default NavCard;
import * as yup from "yup";

export const ValidationSchema =  yup.object({
        businessName: yup.string().required("First name is required"),
        businessEmail : yup.string().email("Invalid email format").required("Your email address is required"),
        // wareHouseLocation: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        firstName: yup.string().required("First name is required"),
        phoneNumber: yup.string().required("Your phone number is required"),
        password: yup.string().required("First name is required")
})

export  const InitialFormValues = {
        businessName: "",
        businessEmail: "",
        // wareHouseLocation: "",
        lastName: "",
        firstName: "",
        phoneNumber: "",
        password:"", 
}

export  interface FormValuesType  { 
        businessName: string;
        businessEmail: string;
        // wareHouseLocation: string;
        lastName: string;
        firstName: string;
        phoneNumber: string;
        password: string;
}

export  interface FormErrorsType  {
        businessName: string;
        businessEmail: string;
        // wareHouseLocation: string;
        lastName: string;
        firstName: string;
        phoneNumber: string;
        password: string;
}
import "./BusinessBasicInfoHeader.scss";
import { FC, useState } from "react";
import ImgUploadLogo from "../../../../../../../../../Assets/Images/imgupld.svg"

interface propType { }

// eslint-disable-next-line no-empty-pattern
const BusinessBasicInfoHeader: FC<propType> = () => {
  const [imgSrc, setImgSrc] = useState(ImgUploadLogo)

  const handleImageSet = (e: any) => {
    e.preventDefault()
    setImgSrc(URL.createObjectURL(e.target.files[0]))
  }

  return (

    <div className="biz-basic-Info-header">
      <div className="company-logo"> 
        <input type="file" accept="image/*" hidden id="imgFile" onChange={handleImageSet} />
        <img src={imgSrc} alt='logo' />
      </div>
      <div className="header-text">
        <div className="heading">Company logo</div>
        <div className="text">
          Upload your Company Logo to increase the credibility of your store on
          payment pages
        </div>
      </div>
    </div>
  )
};

export default BusinessBasicInfoHeader;

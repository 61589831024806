import React, { useState } from 'react'
import "./ProductStatistics.scss"
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas'
import { CreateProject } from '../../Index'
import OffcanvasDrawer from '../../../../../../../Components/OffcanvasDrawer/OffcanvasDrawer';

const ProductStatistics = () => {

    const [show, setShow] = useState(false)  
    // eslint-disable-next-line no-unused-vars
    const [placement, setPlacement] = useState<OffcanvasPlacement>('end') 
    

    const handleClose = (placement:OffcanvasPlacement|undefined) => {
        setShow(false)
    }

    
    return (
        <div className='productStatistics'>
            <div className="card">
                <div className="card-header border-0 bg-white">
                    <div className="row">
                        <div className="col-md-10"> 
                            Project Statistics
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="no-product">
                        <div className="">
                            <div className="d-block text-center">
                                <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M47.6666 80.6668H18.3333C10.9999 80.6668 7.33325 77.0002 7.33325 69.6668V40.3335C7.33325 33.0002 10.9999 29.3335 18.3333 29.3335H36.6666V69.6668C36.6666 77.0002 40.3333 80.6668 47.6666 80.6668Z" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M37.0699 14.6665C36.7765 15.7665 36.6666 16.9765 36.6666 18.3332V29.3332H18.3333V21.9998C18.3333 17.9665 21.6333 14.6665 25.6666 14.6665H37.0699Z" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M51.3334 29.3335V47.6668" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M66 29.3335V47.6668" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M62.3334 62.3335H55C52.9834 62.3335 51.3334 63.9835 51.3334 66.0002V80.6668H66V66.0002C66 63.9835 64.35 62.3335 62.3334 62.3335Z" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M22 47.6665V62.3332" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M36.6666 69.6668V18.3335C36.6666 11.0002 40.3333 7.3335 47.6666 7.3335H69.6666C77 7.3335 80.6666 11.0002 80.6666 18.3335V69.6668C80.6666 77.0002 77 80.6668 69.6666 80.6668H47.6666C40.3333 80.6668 36.6666 77.0002 36.6666 69.6668Z" stroke="#C9CACB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> 
                            </div>
                            <div className="d-block text-center"> 
                                <div> 
                                    No project created yet
                                </div> 
                                <div>
                                    <button className='btn' onClick={()=>setShow(true)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 12H18" stroke="#C9CACB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12 18V6" stroke="#C9CACB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        New Project
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OffcanvasDrawer show={show} placement={placement} handleClose={handleClose} title={'Create New Project'} description="Kindly fill all fields to create a new project">
                <CreateProject handleClose={()=>setShow(false)}></CreateProject>
              </OffcanvasDrawer>
        </div>
    )
}

export default ProductStatistics

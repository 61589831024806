import axios from 'axios'
import { AxiosInterceptor } from '../Config/AxiosInterceptor';
import  { createSubscriberType } from "../Models/subscriberType"

AxiosInterceptor()

const authService = {
  login: async (data: any) => {
    return await axios.post("/access/login/password", data)
  },

  resetPassword: async (data: any) => { 
    return await axios.post(`/access/login/phonenumber`, data);
  },
  changePassword: async (data: any) => {
    return await axios.put(`/profile/newpassword`, data);
  },
  verifyPin: async (data: any) => { 
    return axios.post(`/access/login/phonenumber/verify/otp`, data);
  },

  verifyActivation: async () => { 
    return axios.get(`/estatedeveloper/isapproved`);
  },

  logout: async () => {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    // this.userSubject.next(null);
    location.href = '/account/login';
  },

  register: (user: any) => {
    return axios.post(`/onboarding/signup`, user);
  },

  registerEnterprise: (user: any) => {
    return axios.post(`/onboarding/signup/cooperate`, user);
  },

  otpReg: (data: any) => {
    return axios.post(`/onboarding/verify`, data);
  },

  saveBusiness: async (data: any) => {
    return await axios.put(`/onboarding/businessinfo`, data);
  },

  getAll: () => {
    return axios.get("/users");
  },

  getById: (id: any) => {
    return axios.get(`/users/${id}`);
  },
  getCountry: async () => {
    return await axios.get(`/miscellaneous/supported-countries`)
  },

  registerMortgage: (user: any) => {
    return axios.post(`/onboarding/signup/mortgage`, user);
  },

  registerSubscriber: (user:createSubscriberType) => {
    return axios.post(`/onboarding/signup/mortgageinvestor`, user);
  },
 
} 
export default authService

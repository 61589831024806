/* eslint-disable no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import "./StoreProductItem.scss"
// import { StoreEditProduct } from '../..';
import { PromptModal } from '../../../../../../../Components';
import productService from '../../../../../../../Services/productService';
import { toast } from 'react-hot-toast';
import StoreEditProduct from '../StoreEditProduct/StoreEditProduct';

interface productItemPropsTypes {
  productItemValues: any
}

const StoreProductItem: FC<productItemPropsTypes> = ({ productItemValues }) => {
  console.log(productItemValues)
  const [productData, setProductData] = useState()
  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [alertType, setAlertType] = useState("trash");
  // eslint-disable-next-line no-unused-vars
  const [alertSubTitle, setAlertSubTitle] = useState("Delete Product");
  const [id, setId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [alertMessage, setAlertMessage] = useState("Are you sure you want to trash this item?");

  const handleShow = () => setShow(true);

  // eslint-disable-next-line no-unused-vars
  const [showDelete, setShowDelete] = useState(false);

  const handleShowDelete = (prodductUuid: any) => {
    setShowDelete(true);
    setId(prodductUuid)
  }

  const deleteProd = async () => {
    setShowDelete(false)
    const deleteId = toast.loading('Deleting product...');
    await productService.deleteProduct(id)
      .then((res) => {
        console.log(res)
        toast.dismiss(deleteId);
        toast.success("Product deleted successfully", { duration: 25000, className: 'bg-success text-white' });
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }, (error) => {
        console.log(error.response.data.error)
        toast.error(error.response.data.error, { duration: 25000, className: 'bg-danger text-white' });
      })
  }

  useEffect(() => {
    return () => toast.dismiss()
  }, [])
  return (
    <div className="container col-6 col-sm-6 col-md-6  col-lg-6 col-xl-3 p-2 p-sm-3 p-md-3">
      <div className="card popular-card my-1 p-0">
        <div className="card-body text-center p-2 p-sm-3 p-md-3">

          <div className="row prodImg-container">
            <div className="col-12 mb-5">
            
            </div>
            <div className='col-12'>
              <img src={productItemValues.images[0]?.url} className="w--50" />
            </div>
            <div className="row  d-lg-flex">
              <div className="col-12">
                <h5 className="popular-title mt-4 text-start">{productItemValues.productName}</h5>
              </div>
              <div className="col-4 mt-4 p-0">
                {Number(productItemValues.price) !== 0 && <div className="prod-price text-start">{productItemValues.currencySymbol}{productItemValues.price}</div>}
              </div>
              <div className="col-8 mt-4 p-0">
                <div className="prod-rating text-end">
                  <i className='d-icon d-star text-warning'></i>  {productItemValues.totalNumberOfRatings / 20}/ 5
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="col-12 container-fluid px-2">
        </div>

      </div>
      <StoreEditProduct show={show} setShow={setShow} data={productData}></StoreEditProduct>

      <PromptModal showModal={showDelete} alertType={alertType} message={alertMessage} action={deleteProd} alertSubTitle={alertSubTitle}></PromptModal>

    </div>
  )
}

export default StoreProductItem

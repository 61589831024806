import React, { useEffect, useState, FC } from 'react'
import "./StoreEditProduct.scss"
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import productService from '../../../../../../../Services/productService';
import { CATEGORY_FAILURE, CATEGORY_SUCCESS } from '../../../../../../../Constants/CategoryConstants';
import toast, { Toaster } from 'react-hot-toast';
import { BRAND_FAILURE, BRAND_SUCCESS } from '../../../../../../../Constants/BrandConstants';
import brandService from '../../../../../../../Services/brandService';
import country from "../../../../../../../Data/country.json"
import stateList from "../../../../../../../Data/stateNG.json"
import lgas from "../../../../../../../Data/lgaNG.json"
import Modal from 'react-bootstrap/esm/Modal';
import { productType } from '../../../../../../../Models';
import { CalculateCommission } from '../../../../../../../Helpers/CalculateCommission';
import { CurrencyFormatter } from '../../../../../../../Helpers';
import { FilterSearch } from '../../../../../../../Helpers/FilterSearch';

interface EditProductPropType {
    show : boolean;
    data : productType|undefined;
    setShow:any
}
const StoreEditProduct:FC<EditProductPropType> = ({ show, setShow, data }) => {
  const initialState =
    {
      name: "",
      description: "",
      category: { name: "", uuid: "" },
      brand: { name: "", uuid: "" },
      price: 0,
      locationState: "",
      minQty: 0,
      maxQty: 0,
      contactPhoneNumber: "",
      contactFullName: "",
      state: "",
      country: "",
      address: "",
      productUuid: "",
      localGovernmentAreaPrices: ""
    }

  const { handleSubmit, register} = useForm()

  const [categories, setCatgories] = useState([])
  const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false)
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState<boolean>(false)
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false)
  const [comp, setComp] = useState(1)
  const [checkMainPrice, setCheckMainPrice] = useState(0)

  const dispatch = useDispatch()
  const [brands, setBrands] = useState([])
  const [images, setImages] = useState<any>([])
  // eslint-disable-next-line no-unused-vars
  const [uploadedProduct, setUploadedProduct] = useState<any>({})
  const [savePreviewData, setSavePreviewData] = useState<any>({})
  const [productData, setProductData] = useState(initialState)

  const [selectStates, setSelectedStates] = useState<any>([])
  // eslint-disable-next-line no-unused-vars
  const [selectedCountry, setCountry] = useState<any>({
    id: "",
    sortname: "",
    name: "",
    phoneCode: ""
  })
  const [lgaPriceRow, setLgaPriceRow] = useState([
    { localGovernmentArea: "", price: "0" }
  ])
  const [showLgaPriceRow, setShowLgaPriceRow] = useState<boolean>(false)

  const [selectLgas, setSelectedLgas] = useState<any>([])

  const [toggleBrandDropDown, setToggleBrandDropDown] = useState(false)

  const [brandFilter, setBrandFilter] = useState<any>([])

  const [activeBrand, setActiveBrand] = useState({
    imageUrl: "",
    name: "",
    productsCount: "",
    uuid: ""
  })
  const [brandSearch, setBrandSearch] = useState(true)

  const [activeCategory, setActiveCategory] = useState("");
  const [activeBrandUuid, setActiveBrandUuid] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [loadingBr, setLoadingBr] = useState<boolean>(false)

  const handleOnChangeBrand = async (e: { target: { value: any; }; }) => {
    console.log("search categories", categories)
    const value = e.target.value;
    setActiveBrand({
      imageUrl: "",
      name: value,
      productsCount: "",
      uuid: ""
    })
    if (value.length > 2) {
      const search = await FilterSearch(brands, value);
      console.log("search STATEsk", search)
      setBrandFilter(search)
      if (search.length >= 1 && value.length > 1)setBrandSearch(true)
      if (search.length === 0)setBrandSearch(false)
    } else {
      console.log("search STATEsd", brands)
      console.log("search STATEs vale", value.length)
      setBrandFilter(brands)
      if (value.length === 0)setBrandSearch(true)
      if (value.length > 2)setBrandSearch(false)
    }
  }

  const createBrand = async () => {
    const toastId = toast.loading("Adding new brand, please wait")
    await brandService.createBrand(activeCategory, activeBrand.name).then((data:any) => {
      console.log("createBrand", data.data.data)
      setActiveBrandUuid(data.data.data.uuid)
      setBrandSearch(true)
      toast.dismiss(toastId);
      toast.success("Brand added", { duration: 20000, className: 'bg-success shadow-md text-white' });
    }, (error:any) => {
      toast.dismiss(toastId);
      console.log("createBrand error", error.response)
      toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger shadow-sm text-white' });
    })
  }

  const togleBrDrop = () => {
    setToggleBrandDropDown(!toggleBrandDropDown)
  }

  const getAllCategory = async () => {
    setLoader(true)
    await productService.getAllCategory().then((res:any) => {
      setLoader(false)
      setCatgories(res.data.data)
      dispatch({ type: CATEGORY_SUCCESS, data: res.data.data })
    }, (error:any) => {
      toast.error(error.message, { duration: 20000, className: 'bg-danger text-white' });
      dispatch({ type: CATEGORY_FAILURE, data: error.message })
    })
  }

  const getBrand = async () => {
    await brandService.getAll().then((data:any) => {
      if (data.data.status) {
        setBrands(data.data.data)
        dispatch({ type: BRAND_SUCCESS, data: data.data.data })
      } else {
        console.log("getBrands error", data.data.message)
        dispatch({ type: BRAND_FAILURE, data: "getBrands error" })
      }
    }, (error:any) => {
      console.log("getBrands error", error.response)
      dispatch({ type: BRAND_FAILURE, data: error.response.data.error })
    })
  }

  const getBrandByCategory = async (catUuid:string) => {
    console.log("catUuid catUuid", catUuid)
    setActiveCategory(catUuid)
    setLoadingBr(true)
    const isAddProduct = true;
    await brandService.getCategoryByBrand(catUuid, isAddProduct).then((data:any) => {
      console.log("getCategoryByBrand all", data)
      if (data.data.status && data.data.data !== null) {
        setBrands(data.data.data)
      } else {
        setLoadingBr(false)
        setBrands([])
        console.log("getCategoryByBrand error", data.data.message)
      }
    }, (error:any) => {
      setBrands([])
      setLoadingBr(false)
      console.log("getCategoryByBrand error", error.response)
    //   dispatch({ type: BRAND_FAILURE, data: error.response.data.error })
    })
  }

  useEffect(() => {
    const abortController = new AbortController()
    getBrand()
    getAllCategory()
    return () => abortController.abort()
  }, [])
  console.log("country", country)

  const onsubmit = handleSubmit((data:any) => {
    console.log(productData.productUuid, data)
    processForm(productData.productUuid, data)
  })

  const processForm = async (productUuid:string, data: any) => {
    // serialize data
    if (activeBrandUuid === "") {
      toast.error('Brand field cannot be empty', { duration: 20000, className: 'bg-danger text-white' });
      return 0
    }

    const datas = {
      name: data.name,
      description: data.description,
      categoryUuid: data.categoryUuid,
      brandUuid: data.brandUud,
      price: data.price,
      locationState: JSON.parse(data.locationState).name,
      minQty: data.minQty,
      maxQty: data.maxQty,
      localGovernmentAreaPrices: lgaPriceRow[0].price === "0" ? null : lgaPriceRow
    }
    console.warn("data00", datas)

    setLoadingProduct(true)
    await productService.updateProduct(productUuid, datas)
      .then(async (res:any) => {
        setLoadingProduct(false)
        setUploadedProduct(res.data.data)
        console.log("Add product success", res)
        console.log("images", images)
        if (images.length > 0) {
          // cannot update image from backend
          toast.success("Proccessing images", { duration: 20000, className: 'bg-white shadow-md text-secondary' });
          await startUpload(productData.productUuid)
        } else {
          toast.success("Update saved successfully", { duration: 20000, className: 'bg-white shadow-md text-secondary' });
          handleClose()

          setTimeout(() => {
            window.location.reload()
          }, 3000)
        }
      }, (error:any) => {
        setLoadingProduct(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
        console.log("Add product error", error.response)
      })
  }

  const handleFileUpload = async (event: { target: any }) => {
    setLoadingPhoto(true)
    setImages([...images, ...event.target.files])
    console.log(event.target.files[0].name);
    setLoadingPhoto(false)
  };

  //   startUpload(uploadedProduct)
  // eslint-disable-next-line no-unused-vars
  const startUpload = async (productUuid:any) => {
    const arraySize = images.length
    images.forEach(async (res: string | Blob, index:any) => {
      const formData = new FormData();

      // Update the formData object
      formData.append(
        "file",
        res
      );
      await productService.file(productUuid, formData).then((res:any) => {
        console.log("FILE UPLOAD", res)
        toast.success("Photo saved successfully", { duration: 20000, className: 'bg-white shadow-md text-secondary' });
        setLoadingPhoto(false)
        if (arraySize === index + 1) {
          toast.success("Upload completed", { duration: 20000, className: 'bg-success shadow-md text-white' });
          handleClose()
          // setTimeout(() => {
          //   window.location.reload()
          // }, 3000)
        }
      }, (error:any) => {
        setLoadingPhoto(false)
        toast.error(error.response.data.error, { duration: 20000, className: 'bg-danger text-white' });
        console.log(error.response)
        console.log(error)
      })
    })
  }

  // const handleCountryChange = (e:any) => {
  //   // eslint-disable-next-line no-unused-vars
  //   const cont = JSON.parse(e.target.value)
  //   setCountry(JSON.parse(e.target.value))

  //   const selState = state.states.filter((res:any) => {
  //     return Number(res.country_id) === Number(cont.id)
  //   })
  //   setSelectedStates(selState)
  // }

  const savePreview = handleSubmit((data:any) => {
    console.log(data)
    setComp(0)
    setSavePreviewData(data)
  })

  const handleCountryChange = (e:any) => {
    const stateObj:any = stateList
    const allState = stateObj[2].data

    setSelectedStates(allState)

    const cont = JSON.parse(e.target.value)
    setCountry(cont)
  }

  useEffect(() => {
    if (data) {
      const priceList:any = data.localGovernmentAreaPrices
        ? data.localGovernmentAreaPrices?.prices?.map((res:any) => ({
          localGovernmentArea: res.localGovernmentArea,
          price: res.price
        }))
        : []
      console.warn("priceList o", data.localGovernmentAreaPrices)
      console.warn("priceList", priceList)
      setLgaPriceRow([...lgaPriceRow, ...priceList]);

      console.log("DATA effect", data)
      setProductData({
        name: data.productName,
        productUuid: data.productUuid,
        description: data.productDescription,
        category: data.category,
        brand: data.brand,
        price: data.price,
        locationState: data.pickupAddress.state,
        minQty: data.minimumQuantity,
        maxQty: data.maximumQuantity,
        contactPhoneNumber: data.pickupAddress.contactPhoneNumber,
        contactFullName: data.pickupAddress.contactFullName,
        state: data.pickupAddress.state,
        country: data.pickupAddress.country,
        address: data.pickupAddress.address,
        localGovernmentAreaPrices: ''
      })
    }
  }, [data])

  const updateData = (e: any) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value,
    })
  }

  function checkPrice (value:any) {
    setCheckMainPrice(value)
  }

  const removeImage = (i:number) => {
    const filteredItems = images.slice(0, i).concat(images.slice(i + 1, images.length))
    setImages(filteredItems)
  }

  useEffect(() => {
    return () => toast.dismiss();
  }, [])

  useEffect(() => {
    setCheckMainPrice(productData.price)
  }, [productData])

  const handleClose = () => {
    setShow(false);
  };

  const handleFormRowInputChange = (index: number, event: { target: { name: string; value: string } }) => {
    const values:any[] = [...lgaPriceRow];
    const updatedValue:any = event.target.name;
    values[index][updatedValue] = event.target.value;
    setLgaPriceRow(values);
  };

  const handleState = (e:any) => {
    const state_id = JSON.parse(e.target.value).id;
    const localGovt:any = lgas[2].data;
    const getLga:any = localGovt.filter((res: any) => Number(res.state_id) === Number(state_id))
    setSelectedLgas(getLga)
  }

  const handleAddFormRow = (e:any) => {
    e.preventDefault()
    const values = [...lgaPriceRow];
    values.push({
      localGovernmentArea: "",
      price: ""
    });
    setLgaPriceRow(values);
  };

  const handleRemoveFormRow = (index: number) => {
    const values = [...lgaPriceRow];
    values.splice(index, 1);
    setLgaPriceRow(values);
  };

  const handleActiveBrand = (e:any) => {
    console.log(e)
  }

  const brandAction = (data:any) => {
    setActiveBrandUuid(data.uuid)
    handleActiveBrand(data);
    setActiveBrand(data);
    togleBrDrop()
  }

  console.log("activeBrand DD", activeBrand)
  return (
        <div className='addproduct'>

        <Modal show={show} onHide={handleClose}>
          {comp === 1 && <>
          <Modal.Header closeButton>
                <Modal.Title><h4 className="modal-title">Edit Product</h4></Modal.Title>
              </Modal.Header>
              <Modal.Body className='addproduct-modal'>

        <form onSubmit={onsubmit}>
              <div className='form-group'>
                  <input type="text" className='form-control' placeholder='Product Name' value={productData && productData.name} {...register("name", { required: "This field is required", onChange: (e:any) => updateData(e) })}></input>
                  {/* {errors.name && <div className='text-danger'>{errors.name.message}</div>} */}
              </div>

              <div className='form-group'>
                  <select className='form-control' defaultValue={productData && productData.category?.name} {...register("categoryUuid", { required: "This field is required", onChange: (e) => getBrandByCategory(e.target.value) })}>
                        { productData.category && <option value={productData.category?.uuid}>{productData && productData.category?.name}</option> }
                        {categories.length > 0 && categories.sort((a:any, b:any) => a.name.localeCompare(b.name)).map((res:any, index:React.Key) => {
                          return <option key={index} value={res.uuid}>{res.name}</option>
                        })}
                  </select>
                  {/* {errors.categoryUuid && <div className='text-danger'>{errors.categoryUuid.message}</div>} */}
              </div>

              <div className='form-group'>
                  <div className="dropdown-form-element">
                    <div className="dropdown-select">
                      <input type="text" onClick={togleBrDrop} className="form-control" defaultValue={activeBrand.name !== '' ? activeBrand.name : productData.brand?.name} placeholder="Type or select brand from list" {...register("brandUud", { required: "This field is required", onChange: (e) => handleOnChangeBrand(e) })}></input>
                      {!brandSearch && <span className="bg-success text-white select-btn" onClick={() => createBrand()}>
                        <i className="fa fa-plus"></i>
                      </span>}
                    </div>
                    {toggleBrandDropDown && brandFilter.length > 0 && <ul className="dropdown-list shadow border rounded">
                      {brandFilter.length > 0 && brandFilter.sort((a:any, b:any) => a.name.localeCompare(b.name)).map((res:any, index:React.Key) => {
                        return <li className="dropdown-item" onClick={() => brandAction(res)} key={index}>{res.name}</li>
                      })}
                    </ul>}
                  {/* {errors.brandUud && <div className='text-danger'>{errors.brandUud.message}</div>} */}
                  </div>
              </div>

              <div className='form-group d-none'>
                  <input type="number" className='form-control' placeholder='Price (N)' defaultValue={productData.price} {...register("price", { onChange: (e:any) => { updateData(e); checkPrice(e.target.value); } })}></input>
                  {checkMainPrice !== 0 && <div className='commissionText'>Price shown to buyer: {CurrencyFormatter(Number(checkMainPrice) + Number(CalculateCommission(checkMainPrice)))}</div>}
                  {/* {errors.price && <div className='text-danger'>{errors.price.message}</div>} */}
              </div>

              <div className="container-fluid px-0">
                  <div className="row">
                       <div className="col-6">
                          <div className='form-group'>
                              <input type="number" className='form-control' placeholder='Min Order Quantity' defaultValue={productData.minQty} {...register("minQty", { required: "This field is required", onChange: (e:any) => updateData(e) })}></input>
                              {/* {errors.minQty && <div className='text-danger'>{errors.minQty.message}</div>} */}
                          </div>
                       </div>
                       <div className="col-6">
                            <div className='form-group'>
                                <input type="number" className='form-control' placeholder='Max Order Quanitity' defaultValue={productData.maxQty} {...register("maxQty", { required: "This field is required", onChange: (e:any) => updateData(e) })}></input>
                                {/* {errors.maxQty && <div className='text-danger'>{errors.maxQty.message}</div>} */}
                            </div>
                        </div>
                        <div className="col-6">
                                    <div className='form-group'>
                                        <select className='form-control' defaultValue={productData && productData.country} {...register("country", { required: "This field is required", onChange: (e:any) => handleCountryChange(e) })}>
                                        { productData.country && <option value={productData.country}>{productData && productData.country}</option> }

                                            {country.countries.length > 0 && country.countries.map((res:any, index:React.Key) => {
                                              return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                                            })}
                                        </select>
                                        {/* {errors.country && <div className='text-danger'>{errors.country.message}</div>} */}
                                    </div>
                                </div>
                              <div className="col-6">
                                    <div className='form-group'>
                                        <select className='form-control' defaultValue={productData && productData.state} {...register("locationState", { required: "This field is required", onChange: (e) => handleState(e) })}>
                                        { productData.state && <option value={JSON.stringify({ name: productData.state })}>{productData && productData.state}</option> }

                                        {selectStates.map((result: { id: string | number | readonly string[] | undefined; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => {
                                          return <option key={index} value={JSON.stringify(result)}>{result.name}</option>
                                        })}
                                        </select>
                                        {/* {errors.locationState && <div className='text-danger'>{errors.locationState.message}</div>} */}
                                    </div>
                                </div>

                        <div className="col-12">
                            <div>
                              {lgaPriceRow.length > 0 &&
                                  (<>
                                        {
                                        lgaPriceRow.map((field, index:number, array) => (
                                          <>
                                            <div key={index} className={showLgaPriceRow && Number(index) >= 1 ? "row my-1 d-none" : "row my-1"}>
                                              <div className="col-6">
                                                  <div className='form-group'>
                                                    <select className='form-control' defaultValue={field.localGovernmentArea} onChange={(event) => handleFormRowInputChange(index, event)} name="localGovernmentArea" >
                                                      {field.localGovernmentArea ? <option>{field.localGovernmentArea}</option> : <option>--choose Lga--</option>}
                                                      {selectLgas.length > 0 && selectLgas.map((res: { name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => <option key={index}>{res.name}</option>)}
                                                    </select>
                                                  </div>
                                                </div>

                                                <div className="col-5">
                                                  <div className='form-group'>
                                                    <input type="number" name="price" placeholder='Price for local government' className='form-control' onChange={(event) => handleFormRowInputChange(index, event)} defaultValue={field.price}/>
                                                    {array.length - 1 === index && <div className='mt-2 text-end'><a href="" className="btn btn-outline-success px-2 p-0 add-new-price" onClick={(e) => handleAddFormRow(e)}> <i className="fa fa-plus"></i> Add Price</a></div>}
                                                  </div>
                                                </div>
                                                <div className="col-1 mt-4 p-0">
                                                    {index >= 1 && array.length > 1 && <span className="btn btn-sm btn-danger border-0 rounded-circle btn-remove" onClick={() => handleRemoveFormRow(index)}> <i className='fa fa-minus'></i> </span>}
                                                </div>
                                            </div>
                                            {index === 0 && lgaPriceRow.length >= 2 && <div className="toggle-form-row"><span className='btn btn-sm' onClick={() => setShowLgaPriceRow(!showLgaPriceRow)}>{!showLgaPriceRow ? "Hide" : "Show"}</span></div>}
                                            </>
                                        ))
                                      }
                                  </>)}
                              </div>
                            </div>

                        <div className="col-6">
                            <div className='form-group'>
                                <input type="text" className='form-control' placeholder='Contact Name' defaultValue={productData.contactFullName} {...register("contactFullName", { required: "This field is required", onChange: (e:any) => updateData(e) })}></input>
                                {/* {errors.contactFullName && <div className='text-danger'>{errors.contactFullName.message}</div>} */}
                            </div>
                        </div>

                        <div className="col-6">
                            <div className='form-group'>
                                <input type="number" className='form-control' placeholder='Contact Phone' defaultValue={productData.contactPhoneNumber} {...register("contactPhoneNumber", { required: "This field is required", onChange: (e:any) => updateData(e) })}></input>
                                {/* {errors.contactPhoneNumber && <div className='text-danger'>{errors.contactPhoneNumber.message}</div>} */}
                            </div>
                        </div>
                  </div>
                  <div className='form-group'>
                    <input className='form-control' placeholder='Address' defaultValue={productData.address} {...register("address", { required: "This field is required", onChange: (e:any) => updateData(e) })}></input>
                    {/* {errors.address && <div className='text-danger'>{errors.address.message}</div>} */}
                </div>
              </div>
              <div className="container-fluid px-0 uploadImg my-4">
                  <h4 className='title'>Upload Image</h4>
                  <div className='desc my-2'>Atleast 1 image of the product </div>
                  <div className="row">
                      <div className="col-2">
                          <div className='addbtn'>
                              <label htmlFor="contained-button-file"><i className='d-icon d-add'></i></label>
                          </div>
                          <input
                                type="file"
                                accept="image/*"
                                multiple
                                id="contained-button-file"
                                className='d-none'
                                onChange={handleFileUpload}
                              />
                              {loadingPhoto && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                      </div>
                       <div className="col-10">
                            <div className="container-fluid px-0">
                                <div className="row">

                                {images.map((res:any, index:React.Key) => {
                                  return <div className="col-3" key={index}>
                                        <div className='uploadedImgCont p-1 mb-1'>
                                          <span className='btn rounded-circle p-1' onClick={() => removeImage(Number(index))}> <i className='fa fa-times text-danger'></i></span>
                                            <img src={URL.createObjectURL(res)} className="w-100"></img>
                                        </div>
                                    </div>
                                })}
                                    {data && data.images.map((res:any, index:React.Key) => {
                                      return <div className="col-3" key={index}>
                                        <div className='uploadedImgCont p-1 mb-1'>
                                            <img src={res.url} className="w-100"></img>
                                        </div>
                                    </div>
                                    })}
                                </div>
                            </div>
                        </div>
                  </div>
                  <div className='inst mt-2'>Each picture must not exceed 5 Mb, Supported formats are *.jpg, *.gif and *.png</div>
              </div>

              <div className='form-group'>
                    <textarea className='form-control' placeholder='Description' value={productData.description} {...register("description", { required: "This field is required", onChange: (e:any) => updateData(e) })}> </textarea>
                    {/* {errors.description && <div className='text-danger'>{errors.description.message}</div>} */}
                </div>

                <div className='btn-container mt-5 row'>
                    <div className='col-12 col-sm-6'>
                        <a className='btn btn-preview d-none w-100' onClick={savePreview}>Preview</a>
                    </div>
                    <div className='col-12 col-sm-6'>
                        {!loadingProduct && (<button type="submit" className="btn btn-upload w-100">Update</button>)}
                          {loadingProduct && (<button type="submit" className="btn btn-upload" disabled>
                             <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </button>)}
                    </div>
                </div>
                </form>
          </Modal.Body></>
          }

          {comp === 0 && <>
            <Modal.Header closeButton>
                <Modal.Title>

                    <h4 className="modal-title"> <button className='btn' onClick={() => setComp(1)}><i className='d-icon d-arrowhead-left'></i> </button> Add new product - Preview</h4></Modal.Title>
              </Modal.Header>
              <Modal.Body className='addproduct-modal'>
                       {images.length > 0 && <div className="col-12">
                                    <div className='uploadedImgCont p-1 mb-1'>
                                        <img src={URL.createObjectURL(images[0])} className="w-100"></img>
                                    </div>
                                </div>
                             }

                        <div className="container-fluid px-0">
                                <div className="row">

                                {images.length > 0 && images.map((res:any, index:React.Key) => {
                                  return <div className="col-3" key={index}>
                                        <div className='uploadedImgCont p-1 mb-1'>
                                            <img src={URL.createObjectURL(res)} className="w-100"></img>
                                        </div>
                                    </div>
                                })}
                                    {images.length === 0 && <div className="col-12 text-danger">
                                        No image selected
                                    </div>
                                     }

                                </div>
                            </div>
                  <h4>{savePreviewData.name}</h4>
                  <p>{savePreviewData.description}</p>

                  <div className='container-fluid'>
                      <div className="row">
                          <div className="col-6 mt-2">
                              <div className='text-capitalize fw-bold'>price</div>
                              <div>{savePreviewData.price}</div>
                          </div>
                          <div className="col-6 mt-2">
                              <div className='text-capitalize fw-bold'>min Qty</div>
                              <div>{savePreviewData.minQty}</div>
                          </div>
                          <div className="col-6 mt-2">
                              <div className='text-capitalize fw-bold'>max Qty</div>
                              <div>{savePreviewData.maxQty}</div>
                          </div>
                          <div className="col-6 mt-2">
                              <div className='text-capitalize fw-bold'>State</div>
                              <div>{ JSON.parse(savePreviewData.locationState).name}</div>
                          </div>
                          <div className="col-6 mt-2">
                              <div className='text-capitalize fw-bold'>contact Full Name</div>
                              <div>{savePreviewData.contactFullName}</div>
                          </div>
                          <div className="col-6 mt-2">
                              <div className='text-capitalize fw-bold'>contact Phone Number</div>
                              <div>{savePreviewData.contactPhoneNumber}</div>
                          </div>
                          <div className="col-6 mt-2">
                              <div className='text-capitalize fw-bold'>Address</div>
                              <div>{savePreviewData.address}</div>
                          </div>
                      </div>
                      <div className='mt-5'>
                        {!loadingProduct && (<button type="submit" className="btn-upload-prev border-0" onClick={onsubmit}>Upload</button>)}
                          {loadingProduct && (<button type="submit" className="btn btn-upload-prev border-0" disabled>
                             <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </button>)}
                      </div>
                  </div>

                    <div className="container-fluid px-0">
                        <div className="row">
                        </div>
                    </div>

              </Modal.Body>

          </>}
       </Modal>
          <Toaster/>
        </div>
  )
}

export default StoreEditProduct

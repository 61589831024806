import React from 'react'
import "./BuyerSidebar.scss"
import BuyerSidebarContent from './BuyerSidebarContent/BuyerSidebarContent'

const BuyerSidebar = () => {
  return (
        <div className='buyerSidebar d-none d-lg-block'>
          <BuyerSidebarContent></BuyerSidebarContent>
        </div>
  )
}

export default BuyerSidebar

import { useLiveQuery } from 'dexie-react-hooks';
import React, {useRef, useState} from 'react'
import { HeaderMeta } from '../../../../../../Components'
import "./EnterpriseCart.scss" 
import { Logger } from '../../../../../../Helpers/Logger';
import { useAuth } from '../../../../../../Components/AuthProvider/AuthProvider'; 
import { db } from '../../../../../../Config/DBConfig'; 
import { Link } from 'react-router-dom';

import location from "../../../../../../Assets/Images/icon/location-slected.png"
import mastercard from "../../../../../../Assets/Images/mastercard.png"
import Visa from "../../../../../../Assets/Images/Visa.png"
import { EnterpriseCartItem } from '../../Components';
import { CurrencyFormatter } from '../../../../../../Helpers/CurrencyFormatter';

const EnterpriseCart = () => {

    const onscrollPage = useRef(null)
 
     
    // When the user scrolls the page, execute myFunction
    window.onscroll = () => {
        myFunction()
    };

    // Get the header
    const sideBar:any = onscrollPage.current

    // Get the offset position of the navbar
    const sticky = sideBar?.offsetTop; 
    
    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const myFunction = () => { 
        if (window.pageYOffset > sticky) {
            sideBar.classList.add("sticky");
        } else {
            sideBar.classList.remove("sticky");
        }
    } 

     
    const [totalCost, setTotalCost] = useState<number>(0);
    
    useLiveQuery(
      async () => {
        const cartItems = await db.cart.toArray()
        Logger("cartItemss", cartItems)
        let newCost = 0
        cartItems.length > 0 && cartItems.map((res:any) => {
          newCost += res.productDetails.unitPriceForBuyer * res.quantity;
          setTotalCost(newCost);
          return 1
        })
      })
  
    const orderDetailList = useLiveQuery<any>(async () => await db.orderDetails?.toArray());

 
    const { user } = useAuth() 
    
    return (
        <div className='enterpriseCart'>
             <HeaderMeta title="Cart"></HeaderMeta>
            <div className="container my-2">
                <div className="ps-2">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-secondary">Cart </a></li>
                    </ol>
                    </nav>
                </div>
            </div>

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-lg-8">
                        <h1>Shopping cart</h1>
                        {user
                          ? <div className="location-address d-none">
                            <img src={location} alt="" className="location-selected"/>
                            14, Tunde Hassan street, Lagos, Nigeria
                        </div>
                          : <div className="location-address">
                            <Link to="/login">Log in to checkout faster</Link>
                        </div>}

                        <EnterpriseCartItem></EnterpriseCartItem>

                        <div className="continue-shoping mb-4">
                            <Link to="/enterprise/products"><button className="btn">Continue shopping</button></Link>
                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-4 position-relative">
                        <div className='wrapper'>
                            <div className='fixed-wrapper'>
                                <div className="cart-summary" ref={onscrollPage}>
                                    <div className="card border-0">
                                        <div className="card-header border-0">
                                        Summary
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-6 cart-el">
                                                Subtotal
                                                </div>
                                                <div className="col-6 cart-el">
                                                ₦{CurrencyFormatter(totalCost)}
                                                </div>
                                                <div className="col-6 cart-el d-none">
                                                Delivery
                                                </div>
                                                <div className="col-6 cart-el d-none">
                                                ₦{totalCost}
                                                </div>
                                                <div className="col-12 text-left">
                                                <hr/>
                                                </div>

                                                <div className="col-6 ">
                                                    <div className="cart-total">Total</div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="cart-total"> ₦{CurrencyFormatter(totalCost)}</div>
                                                </div>

                                                <div className="col-12">
                                                   {orderDetailList && orderDetailList[0]?.wareHouseUuid && <Link to="/enterprise/checkout" className="btn checkout-btn mt-4 w-100">Checkout</Link>}
                                                   {orderDetailList && orderDetailList.length === 0 && <button className="btn checkout-btn mt-4 w-100 disabled">Checkout</button>}
                                                </div>

                                                <div className="col-12 text-left">
                                                <hr/>
                                                </div>

                                                <div className="col-12 text-left havecoupon">
                                                Do you have a discount code?
                                                </div>

                                                <div className="col-12">
                                                    <input type="text" className="form-control" placeholder="Enter discount code"/>
                                                </div>

                                                <div className="col-12">
                                                    <button className="btn apply-btn mt-4 w-100">Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='position-relative'> 
                                    <div className="payment-option" ref={onscrollPage}>
                                        Acceptable payment methods
                                        <div className="">
                                            <img src={mastercard} className=""/>
                                            <img src={Visa} className=""/>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                 </div>
               <hr/>
            </div>
         </section> 
        </div>
    )
}

export default EnterpriseCart

import React, { useEffect, useState } from 'react'
import "./OrderCompleted.scss" 
import { HeaderMeta, HorizontalLoader, ImageSrc } from '../../../../Components'
import { Logger } from '../../../../Helpers/Logger'
import { Link, useSearchParams } from 'react-router-dom'
import { HomeNewsletter,  OrderCompletedDetails, ProductItemNoCol } from '../../Components'
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider'
import orderService from '../../../../Services/orderService'
import { ErrorHandler } from '../../../../Helpers/ErrorHandler'
import toast, { Toaster } from 'react-hot-toast'
import { useAllProduct } from '../../../../Hooks'
import ProductItemSkeleton from '../Enterprise/Components/EnterpriseProduct/ProductItemSkeleton/ProductItemSkeleton';

const OrderCompleted = () => {
 
  const [phone, setPhone] = useState<string|undefined>() 
  const [orderDetails, setOrderDetails] = useState<any>({})
  const [loadingRequest, setLoadingRequest] = useState(false)
  
 
  const { user } = useAuth() 
  const [searchParams] = useSearchParams();
  const reference = searchParams.get('reference')

  const getOrderDetails = async () => {
    Logger("AAAAA reference", reference)
    setLoadingRequest(true)
    await orderService.orderReceipt(reference).then((res) => {
      Logger("AAAAA", res.data.data[0]) 
      setLoadingRequest(false)
      setOrderDetails(res.data.data[0])
    }, (error:any) => {
      setLoadingRequest(false)
      ErrorHandler(error,
        (e) => {
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    })
  }
  useEffect(() => {
    if (reference)getOrderDetails()
  }, [reference])

  useEffect(() => { 
    if (orderDetails && orderDetails[0]?.orderReceiveType === "WARE_HOUSE") {
      setPhone(orderDetails[0]?.orderLocation?.contactPhoneNumber)
    }
  }, [orderDetails])
 


  const [pageNumber,] = useState(1)
  const [sortOrder,] = useState("DESC")
  const { data: productList, isSuccess, isLoading } = useAllProduct(pageNumber, sortOrder, [], [], [])

  

  return (
        <div className='checkout order-completed'>
            <HeaderMeta title={'Order Completed'}></HeaderMeta>
            <div className="container my-2">
                <div className="ps-2">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-secondary">Order Completed </Link></li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section>
            <div className="container">
                <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-9">
                      <h1 className='text-success'>Payment Successful</h1>
                    </div>
                    <div className="col-3 text-end">
                       <button className='btn p-0 print-btn mt-3'   onClick={() => window.open(window.location.origin + '/order-completed-print?reference='+ reference)}><i className='fa fa-print'></i> Print Receipt</button>
                    </div>
                  </div>
                  Your order with order number #{orderDetails?.referenceNumber} was successful. The order confirmation has been sent to {user && user.phoneNumber} {(!user && phone) ? phone : "" }. 
                             
                    <div className="cart-item-list card border-0">
                        <div className="card-header text-start bg-white py-4">
                          Order Completed!
                        </div>
                        <div className="card-body ppduct-cart-item">
                          {loadingRequest === true && <HorizontalLoader></HorizontalLoader>}
                            {loadingRequest ===false && orderDetails && orderDetails?.orderItems?.length > 0 && orderDetails?.orderItems?.map((res:any, index:React.Key) => 
                            <div className="row h-100" key={index}>
                              <div className="col-4 col-sm-3">
                                <ImageSrc src={res.images[0]?.url} alt={''} title={undefined} width={'w-100 rounded shadow-sm'}></ImageSrc>
                              </div>

                              <div className="col-6 col-sm-7 p-0">
                                <div className="productName">{res.productName}</div>
                                <div className="quantity">Quantity: {res.quantity}</div>
                                <div className="warehouse d-none d-sm-block">Warehouse: {orderDetails?.orderLocation?.name}</div>
                              </div>    
                              <div className="col-2 col-sm-2 text-end my-auto"><div className='price'>&#8358;{res.unitPriceForBuyer * res.quantity}</div></div>    
                            </div>)}
                        </div>
                      </div>
                     {loadingRequest ===false && <ul className="total-calculations list-group border-0 rounded-0 rounded-bottom">
                        <li className="list-group-item border-end-0 py-0 border-start-0 border-top-0 d-flex py-0 pt-2 pb-2 justify-content-between align-items-center">
                            <div className="col-6">Subtotal</div>
                            <div className="text-end">₦{orderDetails?.calculatedTotalCostMajor}</div>
                        </li>
                        <li className="list-group-item border-0 py-0  d-flex justify-content-between align-items-center">
                            <div className="col-6 fw-bold">Total</div>
                            <div className="text-end fw-bold">₦{orderDetails?.calculatedTotalCostMajor}</div>
                        </li> 
                      </ul>}
                    {loadingRequest ===false && orderDetails && <OrderCompletedDetails orderDetails={orderDetails}></OrderCompletedDetails>}
                </div>

                    <div className="col-12 col-lg-6 mt-md-5 pt-md-5"> 
                        <div className='similar-product-title mb-4 mt-5'>Similar products</div>
                          <div className="row"> 
                            {isSuccess && productList.dataset?.map((res:any, index: React.Key) =>
                              (Number(index) <2 && <div className="col-6 p-0" key={index}>
                                  <ProductItemNoCol data={res}></ProductItemNoCol>
                              </div> ))} 
                              {isLoading === true && <ProductItemSkeleton cardCount={2}></ProductItemSkeleton>}
                          </div> 
                    </div>
                </div>
                <hr/>
            </div>

           <HomeNewsletter></HomeNewsletter>

        </section>
        <Toaster></Toaster>
    </div>
  )
}
export default OrderCompleted

import React, { FC } from 'react';
import "./BuyerPageOrderPaymentMethod.scss";

interface propsTypes {
    method:string;
}

const BuyerPageOrderPaymentMethod:FC<propsTypes> = ({ method }:propsTypes) => {
    return (
        <li className='list-group-item order-payment-method'>
           <div className=''>
            <p className='list-item-title'>Payment method</p>
            <p className='fw-normal text-capitalize'>{method}</p>
           </div>
        </li>
    )
}
export default BuyerPageOrderPaymentMethod

import React, { FC, useEffect, useState } from 'react'

import { Button, Modal } from 'react-bootstrap';
import "./PromptModal.scss"

interface AlertModalPropType {
    showModal: boolean;
    alertType: string;
    message: string;
    action: () => void;
    alertSubTitle: undefined|string|null;
}

const PromptModal:FC<AlertModalPropType> = ({ showModal, alertType, message, action, alertSubTitle }) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  // eslint-disable-next-line no-unused-vars
  const handleShow = () => setShow(true)

  useEffect(() => {
    setShow(showModal)
  }, [showModal])

  return (
        <div className='prompt-modal'>
            <Modal show={show} onHide={handleClose} className="prompt-modal-inner-main" aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-90w" contentClassName="alert-modal-inner">
                <Modal.Header closeButton className='border-0 text-center'><Modal.Title id="contained-modal-title-vcenter">Comfirm</Modal.Title> </Modal.Header>
                <Modal.Body>
                    <div className='text-center mt-2 mt-md-5'>
                        <div className='bg-light  icon-container rounded-circle shadow-md'><i className={`d-icon d-${alertType} text-danger`}></i></div>
                        {alertSubTitle && <div className='alertType mt-3'>{alertSubTitle}</div>}
                        <div className='contText'>{message}</div>

                        <div className="d-grid gap-2 mb-4 mt-md-5">
                            <div className="row">
                                <div className="col">

                                <Button variant="primary" size="lg" className='' onClick={action}>
                                    Yes
                                </Button>
                                </div>
                                <div className="col">
                                    <Button variant="danger" size="lg" className='' onClick={handleClose}>
                                        No
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
             </Modal>
        </div>
  )
}

export default PromptModal

export const getUserRolePart = (user: { isDeveloper: boolean; isCooperate: boolean; isSeller: boolean; isInvestor: boolean }) => {
    switch (true) {
      case user?.isDeveloper:
        return '/estate-managers';
      case user?.isCooperate:
        return '/estate-managers';
      case user?.isSeller:
        return '/estate-managers';
      case user?.isInvestor:
        return '/estate-managers';
      default:
        return '/buyer';
    }
  };
  
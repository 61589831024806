import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

 

const bankingFinanceService = {
  async getMyBalance () {
    return axios.get(`/wallet/main/balance`);
  },
  async getMySecondaryBalance () {
    return await axios.get(`/wallet/secondary/balance`);
  },
  
  getMyBankAccount: async () => {
    return await axios.get("/profile/bankaccount")
  },
  addBankAccount: async (data) => {
    return await axios.put("/profile/bankaccount", data)
  }, 
  updateBankAccount: async (data) => {
    return await axios.put("/profile/bankaccount", data)
  },
  requestBankAccountUpdate: async (data) => {
    return await axios.post("/profile/request-bank-details-change", data)
  }, 
  getAllBank: async () => {
    return await axios.get("/bank/nigerianbanks")
  },
  VerifyAccount: async (data) => {
    return await axios.post("/bank/account/nameenquiry", data)
  },
  fundWallet: async (data) => {
    return await axios.post("/payments/paystack/initialize", data)
  },
  walletWithdrawal: async (data) => {
    return await axios.post("/wallet/withdraw", data)
  },
  secondaryWalletTransfer: async (data) => {
    return await axios.patch("/cooperate/wallet-to-wallet-transfter", data)
  },
  walletHistory: async (pageNumber, sortOrder) => {
    return await axios.get(`/wallet/transactions?pageNumber=${pageNumber}&sortOrder=${sortOrder}`)
  },
  walletDedicatedAccount: async () => {
    return await axios.get(`/payments/paystack/dedicated-account`)
  },
  getMyMortgateBalance: async () => {
    return await axios.get(`/mortgagecard/balance`);
  },
}
export default bankingFinanceService

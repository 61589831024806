import { TOGGLE_CART_DRAWER_MOB } from "../../Constants/CartConstants";

const CartDrawerMobReducer = (state = false, action: { type: string; payload: number; }) => {
  switch (action.type) {
    case TOGGLE_CART_DRAWER_MOB:
      return action.payload
    default:
      return state
  }
}

export default CartDrawerMobReducer;

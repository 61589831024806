import React, { useState } from 'react'
import { Upload } from '../Upload/Upload'
import "./Procurement.scss"
import Invoice from '../Invoice/Invoice';
import Orders from '../Orders/Orders';

const Procurement = () => {
    const [count, setCount] = useState<number>(0)
    const [invoiceCount, setInvoiceCount] = useState<number>(0)
    
    return (
        <div className='procurement'>
            <div className="card">
                <div className="card-header">
                    <div className="title">Procurement</div>
                    
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active rounded-0" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Upload</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link rounded-0" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Invoices <div className='badge'>{invoiceCount}</div></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link rounded-0" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Orders <div className='badge'>{count}</div></button>
                        </li>
                    </ul>
                </div>
                <div className="card-body"> 
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <Upload></Upload>
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <Invoice setCount={function (value: number): void {
                                setInvoiceCount(value)
                            } } ></Invoice> 
                        </div>
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <Orders setCount={function (value: number): void {
                                setCount(value)
                            } } ></Orders> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Procurement

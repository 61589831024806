import "./PLP.scss";
import  {Outlet} from "react-router-dom";

export const PLP = ()=>  {
    return (
        <div className="plp-page">
            <Outlet/>
        </div>
    )
}

export default PLP;

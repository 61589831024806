import React, { useState, FC , Fragment, useEffect } from 'react'
import { Modal } from 'react-bootstrap' 
import "./EnterpriseWalletFundingModal.scss" 
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService';
import toast, { Toaster } from 'react-hot-toast'; 
import { savePaymentType } from '../../../../../../../Helpers/savePaymentType';
 
 

interface propsType { 
  showModal:boolean;
  hide: (value:boolean)=>void;
  module?: string;
}

const EnterpriseWalletFundingModal:FC<propsType> = ({showModal, hide, module}) => {
  
  const [show, setShow] = useState(false)
  const [savingFunding, setSavingFunding] = useState(false) 
  const [amount, setAmount] = useState(0) 
  const [paymentType, setPaymentType] = useState(0) 
  const [, setPaymentInformation] = useState({})

  const handleClose = () => setShow(false)

  const [dedicatedAccount, setDedicatedAccount] = useState({ bankName: "nill", bankAccountNumber: "nill", bankAccountName: "nill" })

  const getDedicatedAccount = async () => {
    await bankingFinanceService.walletDedicatedAccount()
      .then((res) => { 
        setDedicatedAccount(res.data.data)
      }, (error:any) => {
        toast.error(error.response.data.error, { duration: 25000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    getDedicatedAccount()
  }, [])

  const processpayment = async () => {  
    if (Number(paymentType)===3) {
    setSavingFunding(true)

    await bankingFinanceService.fundWallet({ amountMajor: amount, paymentVariant: "fund_main_wallet" })
      .then(async (res) => {
        setSavingFunding(false)
        setPaymentInformation(res.data.data) 
        
        await savePaymentType("CORPORATE_WALLET_FUNDING").then(()=>{ 

          toast.loading("Redirecting to payment terminal");

          setTimeout(() => {
            window.location.href = res.data.data.paymentProviderRedirectUrl
          }, 1500)
      })
 
        
      }, (error:any) => {
        setSavingFunding(false) 
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
    } else if (Number(paymentType)===4) {
      hide(false)
      setShow(true)
    }
  }


 
 
  return ( 
       <Fragment>
          <Modal 
            show={showModal} 
            onHide={()=>hide(false)}
            backdrop="static"
            keyboard={false}
            contentClassName="enterpriseWalletFunding-modal">  
              <Modal.Header closeButton className='border-0'>
                <Modal.Title>Fund Wallet</Modal.Title>
              </Modal.Header> 
              <Modal.Body>
                   <div className='form-input'>
                      <form action="">  
                          <div className='form-input-wrap mt-3'>
                              <label htmlFor="amount">Amount</label>
                              <input id="amount" type="number" className='form-control ps-2 mt-2' name="amount" onChange={(e:any) => setAmount(e.target.value)} placeholder="Enter Amount"/>  
                          </div>
                          
 
                          <div className='form-input-wrap'>

                          <div className="section-title">Source of Funds</div>
                          
                            <div className="row">
                               <div className="col-md-6 d-none"> 
                                  <div className="card">
                                    <div className="card-body">
                                    <label htmlFor="primaryWallet" className="container">Primary Wallet <br></br>₦00.00
                                      <input id="primaryWallet" className='form-radio ps-2 mt-3' type="radio" name="paymentType" value="1" onChange={(e:any) => setPaymentType(e.target.value)} disabled/>
                                      <span className="checkmark"></span>
                                    </label>
                                     </div>
                                  </div>
                               </div> 
                               <div className="col-md-6 d-none"> 
                                  <div className="card">
                                    <div className="card-body">
                                    <label htmlFor="secondaryWallet" className="container">Secondary Wallet <br></br>₦00.00
                                      <input id="secondaryWallet" className='form-radio ps-2 mt-3' type="radio" name="paymentType" value="2" onChange={(e:any) => setPaymentType(e.target.value)}/>
                                      <span className="checkmark"></span>
                                    </label>
                                     </div>
                                  </div>
                               </div>  
                               <div className="col-md-6"> 
                                  <div className="card">
                                    <div className="card-body">
                                    <label htmlFor="bankCard" className="container">Use Bank Card <br></br> 
                                      <input id="bankCard" className='form-radio ps-2 mt-3' type="radio" name="paymentType" value="3" onChange={(e:any) => setPaymentType(e.target.value)}/>
                                      <span className="checkmark"></span>
                                    </label>
                                     </div>
                                  </div>
                               </div>  
                               <div className="col-md-6"> 
                                  <div className="card">
                                    <div className="card-body">
                                    <label htmlFor="bankTransfer" className="container">Bank Transfer
                                      <input id="bankTransfer" className={module && module==="estate"? 'form-radio-estate ps-2 mt-3': 'form-radio ps-2 mt-3'} type="radio" name="paymentType" value="4" onChange={(e:any) => setPaymentType(e.target.value)}/>
                                      <span className="checkmark"></span>
                                    </label>
                                     </div>
                                  </div>
                               </div>    
                            </div>
                           </div>  
                          </form>  
                          {!savingFunding && <button type="button" className={module && module === "estate"? 'btn w-100 add-btn-estate mt-4 text-light':'btn w-100 add-btn mt-4 text-light'} onClick={processpayment} disabled={!amount}> Fund Wallet</button>}
                          {savingFunding && <button className={module && module === "estate"? "btn w-100 add-btn-estate mt-4 text-light" : "btn w-100 add-btn mt-4 text-light"} disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Please wait...
                          </button> }
                      </div>
            </Modal.Body>
          </Modal>
  
          
          <Modal show={show} onHide={handleClose} contentClassName="customModal fts">
              <Modal.Header closeButton>
                <Modal.Title>Pay with Bank Transfer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="row mt-3 pb-4">
                    <div className="col-12">

                     <div className='mb2 mb-3'>Paystack Dedicated Account</div>
                      <div className="well">
                        <div className='text-center'>
                          <div className="bankName">{dedicatedAccount?.bankName}</div>
                          <div className="accountNumber">{dedicatedAccount?.bankAccountNumber}</div>
                          <div className="accountName">{dedicatedAccount?.bankAccountName}</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mt-4">
                        <button className={module && module === "estate"? 'btn apply-estate': 'btn apply'} onClick={()=>handleClose()}>I’ve sent the money</button>
                    </div>
                  </div>
              </Modal.Body>

            </Modal>
          <Toaster/> 
      </Fragment>
  )
}

export default EnterpriseWalletFundingModal
import "./BuyerPageDisputeModal.scss";
// import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';
import { Offcanvas } from 'react-bootstrap';
import { FC } from "react";
import CloseIcon from "../../../Assets/Images/icon/close_icon.svg";
import BuyerPageOrderSummaryItem from "../BuyerPageOrderSummaryItem/BuyerPageOrderSummaryItem";
import BuyerPageDisputeForm from "../../../Pages/Frontend/Interface/Buyer/Component/BuyerPageDisputeForm/BuyerPageDisputeForm";

interface propTypes  {
    showDisputeModal:Boolean;
    handleClose:any;
    orderDetails:any
} 
 
const BuyerPageDisputeModal:FC<propTypes> = ({showDisputeModal, handleClose, orderDetails}:propTypes) => {
     


    return (
        <Offcanvas show={showDisputeModal} onHide={handleClose} placement='end' className="custom-modal">
            <Offcanvas.Header>
                <div className="d-flex flex-row justify-content-between w-100">
                    <div className="custom-offcanvas-close-btn order-2"
                        onClick={handleClose}
                    >
                        <img src={CloseIcon} alt=''/>
                    </div>
                    <p className="custom-offcanvas-title order-1">Raise dispute</p>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ul className="list-group list-group-flush">
                {
                    orderDetails?.orderItems?.map((orderItem:any) => {
                    return (
                        <BuyerPageOrderSummaryItem
                            imageUrl={orderItem.images[0].url}
                            name={orderItem.productName}
                            quantity={orderItem.quantity}
                            amount={orderItem.unitPriceForBuyer}
                            key={orderItem.orderUuid}
                            color={orderItem.color || 'N/A'}
                            currency={orderDetails.currencySymbol}
                        />
                    )
                    })
                    }
                </ul>
                <BuyerPageDisputeForm orderDetails={orderDetails}/>
            </Offcanvas.Body> 
        </Offcanvas>
    )
}

export default BuyerPageDisputeModal;
import React, { memo, useEffect, useState } from 'react' 
import "./AccountStats.scss"
import { BasicRBAC } from '../../../../../../../Helpers/BasicRBAC';
import { useAllWarehouse, useSingleWarehouseManager } from '../../../../../../../Hooks/Queries/Enterprise/useAllWarehouse';

const AccountStats = () => {
   const [warehouseCount, setWarehouseCount] = useState(0)


   const access = BasicRBAC
   
   const { data, isSuccess } = access("allWareHouse") === true? useAllWarehouse() : useSingleWarehouseManager()
  
    useEffect(()=>{
        if ( isSuccess === true ) {
          setWarehouseCount(data.length)
        }
    }, [data])
   
     
    return (
        <div className='accountStats'> 
            <div className="row">
                <div className="col-6 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-1">
                                    <div className='icon-wraper'>
                                        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.4904 22.75H7.50042C5.78042 22.75 4.49043 22.29 3.69043 21.38C2.89043 20.47 2.58042 19.15 2.79042 17.44L3.69043 9.94C3.95043 7.73 4.51043 5.75 8.41043 5.75H15.6104C19.5004 5.75 20.0604 7.73 20.3304 9.94L21.2304 17.44C21.4304 19.15 21.1304 20.48 20.3304 21.38C19.5004 22.29 18.2204 22.75 16.4904 22.75ZM8.40042 7.25C5.52042 7.25 5.38042 8.38999 5.17042 10.11L4.27043 17.61C4.12043 18.88 4.30042 19.81 4.81042 20.38C5.32042 20.95 6.22042 21.24 7.50042 21.24H16.4904C17.7704 21.24 18.6704 20.95 19.1804 20.38C19.6904 19.81 19.8704 18.88 19.7204 17.61L18.8204 10.11C18.6104 8.37999 18.4804 7.25 15.5904 7.25H8.40042Z" fill="#7F59FE"/>
                                            <path d="M16 8.75C15.59 8.75 15.25 8.41 15.25 8V4.5C15.25 3.42 14.58 2.75 13.5 2.75H10.5C9.42 2.75 8.75 3.42 8.75 4.5V8C8.75 8.41 8.41 8.75 8 8.75C7.59 8.75 7.25 8.41 7.25 8V4.5C7.25 2.59 8.59 1.25 10.5 1.25H13.5C15.41 1.25 16.75 2.59 16.75 4.5V8C16.75 8.41 16.41 8.75 16 8.75Z" fill="#7F59FE"/>
                                            <path d="M20.41 17.7812H8C7.59 17.7812 7.25 17.4412 7.25 17.0312C7.25 16.6213 7.59 16.2812 8 16.2812H20.41C20.82 16.2812 21.16 16.6213 21.16 17.0312C21.16 17.4412 20.82 17.7812 20.41 17.7812Z" fill="#7F59FE"/>
                                        </svg> 
                                    </div>
                                </div>
                                <div className="col-12 col-sm-11">
                                    <div className="title">
                                       Project count
                                    </div> 
                                    <div className="value">
                                    0
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-1">
                                    <div className='icon-wraper'>
                                        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.4904 22.75H7.50042C5.78042 22.75 4.49043 22.29 3.69043 21.38C2.89043 20.47 2.58042 19.15 2.79042 17.44L3.69043 9.94C3.95043 7.73 4.51043 5.75 8.41043 5.75H15.6104C19.5004 5.75 20.0604 7.73 20.3304 9.94L21.2304 17.44C21.4304 19.15 21.1304 20.48 20.3304 21.38C19.5004 22.29 18.2204 22.75 16.4904 22.75ZM8.40042 7.25C5.52042 7.25 5.38042 8.38999 5.17042 10.11L4.27043 17.61C4.12043 18.88 4.30042 19.81 4.81042 20.38C5.32042 20.95 6.22042 21.24 7.50042 21.24H16.4904C17.7704 21.24 18.6704 20.95 19.1804 20.38C19.6904 19.81 19.8704 18.88 19.7204 17.61L18.8204 10.11C18.6104 8.37999 18.4804 7.25 15.5904 7.25H8.40042Z" fill="#10BDFF"/>
                                            <path d="M16 8.75C15.59 8.75 15.25 8.41 15.25 8V4.5C15.25 3.42 14.58 2.75 13.5 2.75H10.5C9.42 2.75 8.75 3.42 8.75 4.5V8C8.75 8.41 8.41 8.75 8 8.75C7.59 8.75 7.25 8.41 7.25 8V4.5C7.25 2.59 8.59 1.25 10.5 1.25H13.5C15.41 1.25 16.75 2.59 16.75 4.5V8C16.75 8.41 16.41 8.75 16 8.75Z" fill="#10BDFF"/>
                                            <path d="M7 16H17" stroke="#10BDFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7 14H17" stroke="#10BDFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.66732 19.5L8.66732 10L15.334 19.5L15.334 10" stroke="#10BDFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> 
                                    </div>
                                </div>
                                <div className="col-12 col-sm-11">
                                    <div className="title">
                                    Total Project value
                                    </div> 
                                    <div className="value">
                                    0
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-1">
                                    <div className='icon-wraper'>
                                        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.02 2.83821L3.63 7.03821C2.73 7.73821 2 9.22821 2 10.3582V17.7682C2 20.0882 3.89 21.9882 6.21 21.9882H17.79C20.11 21.9882 22 20.0882 22 17.7782V10.4982C22 9.28821 21.19 7.73821 20.2 7.04821L14.02 2.71821C12.62 1.73821 10.37 1.78821 9.02 2.83821Z" stroke="#34BA96" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M10.5 18H13.5C15.15 18 16.5 16.65 16.5 15V12C16.5 10.35 15.15 9 13.5 9H10.5C8.85 9 7.5 10.35 7.5 12V15C7.5 16.65 8.85 18 10.5 18Z" stroke="#34BA96" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12 9V18" stroke="#34BA96" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5 13.5H16.5" stroke="#34BA96" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> 
                                    </div>
                                </div>
                                <div className="col-12 col-sm-11">
                                    <div className="title">
                                    Order count
                                    </div> 
                                    <div className="value">
                                    {warehouseCount}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-1">
                                    <div className='icon-wraper'>
                                        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.4904 22.75H7.50042C5.78042 22.75 4.49043 22.29 3.69043 21.38C2.89043 20.47 2.58042 19.15 2.79042 17.44L3.69043 9.94C3.95043 7.73 4.51043 5.75 8.41043 5.75H15.6104C19.5004 5.75 20.0604 7.73 20.3304 9.94L21.2304 17.44C21.4304 19.15 21.1304 20.48 20.3304 21.38C19.5004 22.29 18.2204 22.75 16.4904 22.75ZM8.40042 7.25C5.52042 7.25 5.38042 8.38999 5.17042 10.11L4.27043 17.61C4.12043 18.88 4.30042 19.81 4.81042 20.38C5.32042 20.95 6.22042 21.24 7.50042 21.24H16.4904C17.7704 21.24 18.6704 20.95 19.1804 20.38C19.6904 19.81 19.8704 18.88 19.7204 17.61L18.8204 10.11C18.6104 8.37999 18.4804 7.25 15.5904 7.25H8.40042Z" fill="#FB607F"/>
                                            <path d="M16 8.75C15.59 8.75 15.25 8.41 15.25 8V4.5C15.25 3.42 14.58 2.75 13.5 2.75H10.5C9.42 2.75 8.75 3.42 8.75 4.5V8C8.75 8.41 8.41 8.75 8 8.75C7.59 8.75 7.25 8.41 7.25 8V4.5C7.25 2.59 8.59 1.25 10.5 1.25H13.5C15.41 1.25 16.75 2.59 16.75 4.5V8C16.75 8.41 16.41 8.75 16 8.75Z" fill="#FB607F"/>
                                            <path d="M7 16H17" stroke="#FB607F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7 14H17" stroke="#FB607F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.66732 19.5L8.66732 10L15.334 19.5L15.334 10" stroke="#FB607F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg> 
                                    </div>
                                </div>
                                <div className="col-12 col-sm-11">
                                    <div className="title">
                                   Total transaction value
                                    </div> 
                                    <div className="value">
                                    0
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default memo(AccountStats)

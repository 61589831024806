import React, {FC, useState} from "react";
import "./VerificationDocumentUploadCard.scss";


interface propTypes {
    title:string;
    description:string;
    value?:any;
    changeHandler?: any
    name:string;
    doc:string; 
    checkIfExist:any
}

const VerificationDocumentUploadCard:FC<propTypes> = ({title, description, value, changeHandler, name, doc, checkIfExist}) => {
    const [file, setFile] = useState<{target: {files: any}}>()
    return (
        <React.Fragment>
        <div className={checkIfExist(doc) ? "VerificationDocumentUploadCard w-100  border-danger": "VerificationDocumentUploadCard w-100"}>
           <div className="title">{title}</div>
           {!file && <div className="verificationDocumentUploadCardInner">
            <label className="mb-3 file-upload" htmlFor={name}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.25">
                    <path d="M21.5169 7.99175C21.9148 8.55833 21.4405 9.25 20.7482 9.25H3.5C2.94772 9.25 2.5 8.80228 2.5 8.25V6.42C2.5 3.98 4.48 2 6.92 2H9.24C10.87 2 11.38 2.53 12.03 3.4L13.43 5.26C13.74 5.67 13.78 5.72 14.36 5.72H17.15C18.9546 5.72 20.5516 6.61709 21.5169 7.99175Z" fill="#292D32"/>
                    <path d="M22.4834 11.7461C22.4815 11.1952 22.0343 10.7495 21.4834 10.7495L3.49998 10.7498C2.94771 10.7499 2.5 11.1976 2.5 11.7498V16.6499C2.5 19.5999 4.9 21.9999 7.85 21.9999H17.15C20.1 21.9999 22.5 19.5999 22.5 16.6499L22.4834 11.7461ZM15 16.7499H13.31V18.4999C13.31 18.9099 12.97 19.2499 12.56 19.2499C12.14 19.2499 11.81 18.9099 11.81 18.4999V16.7499H10C9.59 16.7499 9.25 16.4099 9.25 15.9999C9.25 15.5899 9.59 15.2499 10 15.2499H11.81V13.4999C11.81 13.0899 12.14 12.7499 12.56 12.7499C12.97 12.7499 13.31 13.0899 13.31 13.4999V15.2499H15C15.41 15.2499 15.75 15.5899 15.75 15.9999C15.75 16.4099 15.41 16.7499 15 16.7499Z" fill="#292D32"/>
                </g>
            </svg>
                <input 
                    id={name}
                    type="file" 
                    accept="image/*" 
                    className="form-control file-upload-input" 
                    onChange={(event)=>{
                        changeHandler(event, doc)
                        setFile(event)

                    }}
                    value={value}
                    name={name}
                />
            </label>
            <div className="document-desc">Drag and drop, or browse your files</div>
            </div>}
        {file && <div className="card file-upload-preview">
            <div className="card-body">
                <div className="row">
                    <div className="col-2 my-auto">
                        <svg width="28" height="28" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="10" fill="#E1E7FA"/>
                            <path d="M36 17.3332V30.6665C36 34.6665 34 37.3332 29.3333 37.3332H18.6667C14 37.3332 12 34.6665 12 30.6665V17.3332C12 13.3332 14 10.6665 18.6667 10.6665H29.3333C34 10.6665 36 13.3332 36 17.3332Z" stroke="#3A0CA3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M27.3333 14V16.6667C27.3333 18.1333 28.5333 19.3333 30 19.3333H32.6666" stroke="#3A0CA3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M18.6667 25.3335H24" stroke="#3A0CA3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M18.6667 30.6665H29.3334" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg> 
                    </div>
                    <div className="col-8">
                        <div className="file-name">
                           {file.target.files[0].name}
                        </div> 
                        <div className="row file-name mt-2">
                            <div className="col-4">
                                <div className="uploaded-size">
                                    {file.target.files[0].size}
                                </div>
                            </div>
                            <div className="col-8 p-0">
                                <div className="uploaded-message">
                                    Document Uploaded
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 my-auto">
                        <button className="btn p-1 border-0" onClick={()=>setFile(undefined)}>
                            <svg width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.85 9.14014L18.2 19.2101C18.09 20.7801 18 22.0001 15.21 22.0001H8.79002C6.00002 22.0001 5.91002 20.7801 5.80002 19.2101L5.15002 9.14014" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10.33 16.5H13.66" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9.5 12.5H14.5" stroke="#E73F3F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>}

        </div>
        </React.Fragment>
    )
}

export default VerificationDocumentUploadCard;
import BuyerPageTitleWithoutBreadcumbs from "../../../../../../Components/Buyer/BuyerPageTitleWithougtBreadcumbs/BuyerPageTitleWithoutBreadcumbs";
import ChatWithUsOnWhatsApp from "./ChatWithUsOnWhatsApp/ChatWithUsOnWhatsApp";
import FAQ from "./FAQ/FAQ";
import GiveUsACall from "./GiveUsACall/GiveUsACall";
import "./ContactUsPage.scss";

const ContactUsPage = () => {
    return (
        <div className="buyer-contact-us-page">
            <div className="container mt-4">
                <div className="row">
                 <BuyerPageTitleWithoutBreadcumbs title="Contact Us"/>
                 <div className="col-12 col-md-6 mt-4">
                    <GiveUsACall/>
                 </div>
                 <div className="col-12 col-md-6 mt-4">
                    <ChatWithUsOnWhatsApp/>
                 </div>
                 <div className="col">
                    <FAQ/>
                 </div>
                </div>
            </div>
        </div>
    )
}


export default ContactUsPage;
import React, { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from '../../Hooks/UseLocalStorage'
const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useLocalStorage('__cbuN', null)
  
  const navigate = useNavigate()

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data)
    if (data.isSeller === true) {
      return navigate('/seller/dashboard', { replace: true })
    }
 

    if (data.isSeller === false) {
      
        switch (user.isCooperate) {
          case true:
            return navigate('/enterprise/dashboard', { replace: true }) 
          case false:  
            return navigate('/buyer/dashboard', { replace: true })
          default:
            return navigate('/', { replace: true })
        } 
      
    }
  }

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null)
    return navigate('/login', { replace: true })
  }

  const value = useMemo(() => ({ user, login, logout }),
    [user])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

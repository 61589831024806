import React from 'react'

import { Link } from 'react-router-dom'
import "./PageHeader.scss"

const PageHeader = (props:any) => {
  return (
        <div className="pageheader mt-4 mt-lg-2">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pt-2">
                        <li className="breadcrumb-item text-dark me-2"><Link to="/" className="text-primary">Home</Link></li>
                        <li className="breadcrumb-item text-dark m4-4  ms-2" aria-current="page"><Link to="/products" className={props.titleNested ? "text-dark" : "text-primary"}>{props.title}</Link></li>
                        {props.titleNested && <li className="breadcrumb-item text-dark m4-4  ms-2" aria-current="page">{props.titleNested}</li>}
                    </ol>
                </nav>
            </div>

           {(props.slider === 1 || props.slider === true) && <div className="pageheader-banner">
               <div className="row  h--1000 h-100">
                  <div className="col-md-6 my-auto offset-md-3 text-center">
                     <h1>{props.pageTagline}</h1>
                  </div>
               </div>
            </div>}
        </div>
  )
}

export default PageHeader

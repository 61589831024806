import {Fragment, Key, useEffect, useState } from 'react' 
import "./EstateSubscriberPortfolio.scss"  
import  { Toaster } from 'react-hot-toast';   
import { HorizontalLoader, ImageSrc, OffcanvasDrawer } from '../../../../../../Components';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';
import { NewInvestor } from '../../Components/Index'; 
import { TimeConverter } from '../../../../../../Helpers/TimeConverter';
import { Link } from 'react-router-dom';  
import projectService from '../../../../../../Services/projectService'; 
import { portfolioType } from '../../../../../../Models/portfolioType';
 
const EstateSubscriberPortfolio = () => {
   
    const [sortOrder,] = useState("DESC")
    const [status,] = useState("ALL") 
    const [pageNumber,] = useState(1) 
    const [portfolio, setPortfolio] = useState<Array<portfolioType>>() 
    const [loadingRequest, setLoadingRequest] = useState(false) 
     
    const [show, setShow] = useState(false) 
    // eslint-disable-next-line no-unused-vars
    const [placement, setPlacement] = useState<OffcanvasPlacement>('end') 
    

    const handleClose = (placement:OffcanvasPlacement|undefined) => {
        setShow(false)
    }
    
    const getMyProjectPortfolio = async () => { 
        setLoadingRequest(true)
        await projectService.getMyProjectPortfolio( pageNumber,  sortOrder).then((response)=>{
          console.log("getMyProjectPortfolio 001", response.data.data.dataset) 
          setLoadingRequest(false)
          setPortfolio(response.data.data.dataset)
        }, error => { 
            setLoadingRequest(false)
            console.log("getMyProjectPortfolio error", error )
        })
    }

    useEffect(()=>{
        getMyProjectPortfolio()
    }, [status])

    return ( 
        <div className='EstateSubscriberPortfolio'>
             <div className="container">  
 
                <div className="row mt-4">
                    <div className="col-12 col-lg-10 col-xl-9 my-auto">
                        <div className="page-title">
                            My Portfolio
                        </div>
                        <div className="page-description">
                            Here is a list of all projects in your portfolio
                        </div>
                    </div> 
                    <div className="col-4 col-lg-2  col-xl-3 my-auto text-end">
                        <div className="button-container">
                            <Link to="/estate-managers/subscriber-recurrent-payment">
                               <button className='btn btn-subscribe'>
                                   <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M19.8009 7.91949V13.0695C19.8009 16.1495 18.0409 17.4695 15.4009 17.4695H6.61093C6.16093 17.4695 5.73093 17.4295 5.33093 17.3395C5.08093 17.2995 4.84094 17.2295 4.62094 17.1495C3.12094 16.5895 2.21094 15.2895 2.21094 13.0695V7.91949C2.21094 4.83949 3.97093 3.51953 6.61093 3.51953H15.4009C17.6409 3.51953 19.2509 4.46953 19.6809 6.63953C19.7509 7.03953 19.8009 7.44949 19.8009 7.91949Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                       <path d="M22.802 10.9206V16.0706C22.802 19.1506 21.042 20.4706 18.402 20.4706H9.61203C8.87203 20.4706 8.20204 20.3706 7.62204 20.1506C6.43204 19.7106 5.62203 18.8006 5.33203 17.3406C5.73203 17.4306 6.16203 17.4706 6.61203 17.4706H15.402C18.042 17.4706 19.802 16.1506 19.802 13.0706V7.92059C19.802 7.45059 19.762 7.03062 19.682 6.64062C21.582 7.04063 22.802 8.38059 22.802 10.9206Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                       <path d="M10.9994 13.1394C12.4574 13.1394 13.6394 11.9574 13.6394 10.4994C13.6394 9.04136 12.4574 7.85938 10.9994 7.85938C9.54136 7.85938 8.35938 9.04136 8.35938 10.4994C8.35938 11.9574 9.54136 13.1394 10.9994 13.1394Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                       <path d="M5.28125 8.29883V12.6989" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                       <path d="M16.7227 8.30078V12.7008" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                   </svg> 
                                   Recurrent Payments
                               </button>
                               </Link>
                           </div> 
                    </div> 
                </div>


                <div className="enterprise-table">
                    <div className="card mt-2">
                        <div className="card-body p-0">
                            <div className="table-responsive"> 
                                <table className="table rounded border-0 mt-0"> 
                                    <thead className='rounded'>
                                        <tr className='d-none d-md-table-row'> 
                                            <th scope="col">Date</th> 
                                            <th scope="col">Project name</th>
                                            <th scope="col" className='text-center'>Duration left</th>
                                            <th scope="col" className='text-center'>Duration Covered</th>
                                            <th scope="col" className='text-center'>Number of Units</th>
                                            <th scope="col">Status</th> 
                                            <th scope="col"></th>  
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {!loadingRequest && portfolio && portfolio.map((res:portfolioType, index: Key) => {
                                        return (
                                              <Fragment key={index}>
                                                <tr className='d-none d-md-table-row'> 
                                                    <td> {TimeConverter(res.createdAt)}</td>
                                                    <td> {res.project.name}</td>
                                                    <td className='text-center'>{res.durationLeft}</td> 
                                                    <td className='text-center'>{res.durationCovered}</td>  
                                                    <td className='text-center'>{res.numberOfSlots}</td>
                                                    <td> <span className='project-status'>{res.status}</span></td> 
                                                    <td>
                                                    <div className="btn-group">
                                                        <button type="button" className="btn border-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className='d-icon d-more-vertical'></i>
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                                                        
                                                            <li>
                                                                <Link className="dropdown-item" to={`/estate-managers/subscriber-portfolio/${res.projectSubscriptionUuid}`}>
                                                                <svg className="me-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.16992 7.4375L11.9999 12.5475L20.7699 7.46747" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path d="M12 21.6091V12.5391" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path d="M9.92963 2.48L4.58963 5.45003C3.37963 6.12003 2.38965 7.80001 2.38965 9.18001V14.83C2.38965 16.21 3.37963 17.89 4.58963 18.56L9.92963 21.53C11.0696 22.16 12.9396 22.16 14.0796 21.53L19.4196 18.56C20.6296 17.89 21.6196 16.21 21.6196 14.83V9.18001C21.6196 7.80001 20.6296 6.12003 19.4196 5.45003L14.0796 2.48C12.9296 1.84 11.0696 1.84 9.92963 2.48Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    <path d="M16.9998 13.2416V9.5816L7.50977 4.10156" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg> 
                                                                View Details
                                                                </Link>
                                                            </li> 
                                                        </ul>
                                                    </div>
                                                    </td>
                                                </tr>  
                                                <tr className='d-md-none'>
                                                    <td>
                                                        <ImageSrc src={''} alt={''} title={undefined} width={'table-profile'}></ImageSrc>
                                                    </td>
                                                    <td colSpan={4}>
                                                        <div className="row"> 
                                                            <div className='col-12 profile-title'>
                                                            {res.project.name}
                                                            </div>
                                                            <div className='col-12 profile-detail'><span className='project-details'>{res.project.details}</span></div> 
                                                            <div className='col-12 profile-detail'>{ TimeConverter(res.createdAt)}</div>
                                                            <div className='col-12 profile-detail mt-2'><span className='project-status'>{res.status}</span></div> 
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='profile-btn'>
                                                            <Link className="dropdown-item" to={`/estate-managers/subscriber-portfolio/${res.projectSubscriptionUuid}`}>
                                                                <button type="button" className="btn btn-sm p-1 border d-md-none">
                                                                    View
                                                                </button> 
                                                            </Link>
                                                        </div>   
                                                    </td>   
                                                </tr>  
                                                </Fragment>) 
                                            })} 
                                            {loadingRequest === false && !portfolio &&  <tr>
                                            <td colSpan={9}>
                                                <div className='text-secondary text-center fw-bold'>No portfolio found</div>
                                            </td>
                                        </tr>}
                                         {loadingRequest === true && <tr>
                                            <td colSpan={9}>
                                                <div className='text-secondary text-center fw-bold'><HorizontalLoader></HorizontalLoader></div>
                                            </td>
                                        </tr>}
                                    </tbody> 
                                </table> 
                             </div> 
                         </div>
                     </div>
                  </div>
              </div>
              <OffcanvasDrawer show={show} placement={placement} handleClose={handleClose} title={'New Subscriber'} description="Kindly fill all fields to add a new investor">
                <NewInvestor handleClose={()=>setShow(false)}></NewInvestor>
              </OffcanvasDrawer>
            <Toaster></Toaster>
        </div>
    )
}

export default EstateSubscriberPortfolio

import React, { useEffect, useRef, useState, FC } from 'react'
import toast from 'react-hot-toast' 
import bankingFinanceService from '../../../../../../../Services/bankingFinanceService' 
import "./WalletInformation.scss" 
import { useGetWalletBalance } from '../../../../../../../Hooks/Queries/Enterprise/useGetWalletBalance'
import PrimaryWallet from './PrimaryWallet' 

interface propsType {
  balance? : (amount:number)=>void
}
const WalletInformation:FC<propsType> = ({ balance }) => {
    const [walletType, setWalletType] = useState(1) 
  
   const [myBalance, setMyBalance] = useState({
      currency: "",
      currencySymbol: "",
      amountMajor: ""
    })
  
    const getMyBalance = async () => { 
        await bankingFinanceService.getMyBalance()
          .then((res:any) => { 
            setMyBalance(res.data.data) 
            // send balance to parent
            balance && balance(res.data.data.amountMajor)
          }, (error:any) => { 
            toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }

  
      useEffect(() => {
        getMyBalance()  
      }, [])

      useGetWalletBalance() 
      const nodeRef = useRef(null); 
 
      const [isMounted, setIsMounted] = useState(true);
       
      const handleToggleClicked = (val:React.SetStateAction<number>) => {
        setWalletType(val)
        setIsMounted(!isMounted);
      };


    return (
        <div className='walletInformation'>    
          <PrimaryWallet walletType={walletType} setWalletType={(val: React.SetStateAction<number>)=>handleToggleClicked(val)} myBalance={myBalance} nodeRef={nodeRef} module='estate'></PrimaryWallet> 
        </div>
    )
}

export default WalletInformation

import React, { FC, useEffect, useState } from 'react'
import "./SubscribeToProject.scss"
import { Link, useParams } from 'react-router-dom'

interface propsType {
    handleClose: ()=>void;
    numberOfSlot?:string|undefined
}

const SubscribeToProject:FC<propsType> = ({handleClose, numberOfSlot}) => { 
    const { projectUuid } = useParams<{ projectUuid: string|undefined }>()
    
    const [amount, setAmount] = useState(0)

    useEffect(()=>{
        if (numberOfSlot)setAmount(+numberOfSlot)
    }, [numberOfSlot])
    return (
        <div className='SubscribeToProject'>
            <div className="container px-md-5">
                <div className="title">Number of Units</div>
                <div className="desc">
                Kindly choose number of units you want to purchase
                </div>

                <div className="control-btn mt-4 mb-5">
                   <div className="container">
                    <div className="row">
                        <div className="col-4 d-flex justify-content-start">
                            <button onClick={()=>setAmount(amount>=1? amount - 1:0)}>-</button>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                            {amount}
                        </div>
                        <div className="col-4 d-flex justify-content-end"> 
                            <button onClick={()=>setAmount(amount+1)}>+</button>
                        </div>
                    </div>
                   </div>
                </div>

                <div className="row mt-4">
                    <div className="col-6 d-flex justify-content-end">
                        <button className='btn btn-cancel' onClick={()=>handleClose()}>Cancel</button>
                    </div>
                    <div className="col-6 d-flex justify-content-start">
                        <Link to={`/estate-managers/subscription-details/${projectUuid}/${amount}`}><button className='btn btn-proceed' onClick={()=>handleClose()}>Proceed</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscribeToProject

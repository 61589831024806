import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/esm/Modal'
import toast from 'react-hot-toast'
import "./BankingAndFinanceWalletFunding.scss"
import bankingFinanceService from '../../../../../../../../../Services/bankingFinanceService';
import { Logger } from '../../../../../../../../../Helpers/Logger';
import SubPageTitle from '../../../../../Component/MyAccount/SubPageTitle/SubPageTitle';
import { savePaymentType } from '../../../../../../../../../Helpers/savePaymentType';
 
const BankingAndFinanceWalletFunding = () => {
  const [show, setShow] = useState(false)
  const [savingFunding, setSavingFunding] = useState(false)
  const [amount, setAmount] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [paymentInformation, setPaymentInformation] = useState({})

  const handleClose = () => setShow(false)
  const [dedicatedAccount, setDedicatedAccount] = useState({ bankName: "nill", bankAccountNumber: "nill", bankAccountName: "nill" })

  const getDedicatedAccount = async () => {
    await bankingFinanceService.walletDedicatedAccount()
      .then((res) => {
        Logger("walletDedicatedAccount", res)
        setDedicatedAccount(res.data.data)
      }, (error:any) => {
        toast.error(error.response.data.error, { duration: 25000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }

  useEffect(() => {
    getDedicatedAccount()
  }, [])

  const processpayment = async () => { 
    setSavingFunding(true)

    await bankingFinanceService.fundWallet({ amountMajor: amount, paymentVariant: "fund_main_wallet" })
      .then(async (res) => {

        setSavingFunding(false)

        setPaymentInformation(res.data.data)
        Logger(res)

        await savePaymentType("BUYER_WALLET_FUNDING").then(()=>{

          toast.loading("Redirecting to payment terminal");
          
          setTimeout(() => {
            window.location.href = res.data.data.paymentProviderRedirectUrl
          }, 1500)

        })

      }, (error:any) => {
        setSavingFunding(false)
        Logger(error.response.data.error)
        toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
      })
  }
  return (
        <div className='bankingAndFinanceWalletFunding mb-4'>
           <div className="container mt-4">
            <SubPageTitle title={'Fund Wallet'} parents={
              [
                { title:'My account', url:'my-account'},
                { title:'Banking & Finance', url:'bank-and-finance'}
              ]
            }/>
               <div className="row justify-content-center mt-5">
                  <div className="col-12 col-sm-6 col-lg-5">
                    <div className="card">
                      <div className="card-header  border-0">
                        <div className='inst'>
                        How much do you want to add to wallet?
                        </div>
                      </div>
                      <div className="card-body border-0">
                        <form action="">
                          <div className="form-group mb-5">
                            <label htmlFor="">Enter Amount (₦)</label>
                            <input type="text" className="form-control" placeholder='₦0.00' onChange={(e:any) => setAmount(e.target.value)}/>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer  border-0">
                        <div className='row'>
                          <div className="col-md-6">
                             {!savingFunding && <button className='btn mt-1' onClick={processpayment} disabled={!amount}>Card Payment</button>}
                             {savingFunding && <button className='btn mt-1' disabled={!amount}>Card Payment <span className='spinner-border spinner-border-sm'></span></button>}
                          </div>
                          <div className="col-md-6">
                             <button className='btn mt-1' onClick={() => setShow(true)}>Bank Transfer</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               </div>
            </div>

            <Modal show={show} onHide={handleClose} contentClassName="customModal ft">
              <Modal.Header closeButton>
                <Modal.Title>Pay with Bank Transfer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="row mt-3 pb-4">
                    <div className="col-12">

                     <div className='mb2 mb-3'>Paystack Dedicated Account</div>
                      <div className="well">
                        <div className='text-center'>
                          <div className="bankName">{dedicatedAccount?.bankName}</div>
                          <div className="accountNumber">{dedicatedAccount?.bankAccountNumber}</div>
                          <div className="accountName">{dedicatedAccount?.bankAccountName}</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mt-4">
                        <button className='btn apply'>I’ve sent the money</button>
                    </div>
                  </div>
              </Modal.Body>

            </Modal>
        </div>
  )
}

export default BankingAndFinanceWalletFunding

import React  from 'react'
// import States from "../../../../../Data/stateNG.json";
// import { FormValuesType } from '../../../Interface/EnterpriseSetup/EnterpriseSetupFormConfig';

// interface propTypes   {
//     formikValues: FormValuesType;
//     handleChange:any;
//     handleBlur : any;
//     formikErrors:any;
//     formikTouchedValues:any
// }
   
 const EnterpriseCompanyInformation = (prop:any) => {
    const {
        formikValues,
        handleChange, 
        handleBlur, 
        formikErrors,
        formikTouchedValues, 
    } = prop; 

    return (
        <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <div className="form-group mb-3">
                <label htmlFor="businessName">Business Name*</label>
                <input type="text" id="businessName" 
                className={`form-control ${formikErrors.businessName && formikTouchedValues.businessName ? 'form-input-error' :'form-input'}`}  
                placeholder='Business name'
                value={formikValues.businessName}  onChange={handleChange}  onBlur ={handleBlur}/>
             </div> 
            <div className="form-group mb-3">
                <label htmlFor="businessEmail">Corporate Email Address</label>
                <input type="text" id="businessEmail" 
                className={`form-control ${formikErrors.businessEmail && formikTouchedValues.businessEmail ? 'form-input-error' :'form-input'}`}
                placeholder='Email Address'
                value={formikValues.businessEmail}  onChange={handleChange}  onBlur ={handleBlur}/> 
            </div> 
            {/* <div className="form-group mb-3">
                <label htmlFor="wareHouseLocation">First Warehouse Location</label>
                <select id="wareHouseLocation" 
                  className={`form-select form-control ${formikErrors.wareHouseLocation && formikTouchedValues.wareHouseLocation ? 'form-input-error' :'form-input'}`}
                  aria-label="Default select example" name="wareHouseLocation"
                  value={formikValues.wareHouseLocation}  onChange={handleChange}  onBlur ={handleBlur}
                    >
                        <option value="">--choose location--</option>
                    {
                        States[2].data?.map((state,index)=>{
                            return (
                                <option key={index}  value={state.name}>{state.name}</option>
                            )
                        })
                    }                
                    </select> 
            </div>    */}
        </div>
    )
}

export default EnterpriseCompanyInformation

import React, { FC, useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useAllBrandsByCategory, useCategory } from '../../../../../Hooks';
import "./ProductFilters.scss"

interface propsType {
    show: boolean;
    handleClose: ()=>void;
    categoryFilter: (value:string)=>void;
    brandFilter: (value:string)=>void;
    action: ()=>void;
    isFetching: boolean;
}
const ProductFilters:FC<propsType> = ({ show, handleClose, categoryFilter, brandFilter, action, isFetching }) => {
  const [pageNumber,] = useState(1)
  const [sortOrder,] = useState("DESC")
  const [pageSize,] = useState(20)

  const { isSuccess: isSuccessCat, data: categoryList } = useCategory(pageNumber, sortOrder, pageSize);

  const [categoryUuid, setCategoryUuid] = useState<string>()
  // eslint-disable-next-line no-unused-vars
  const [brandUuid, setBrandUuid] = useState<string>()

  const { isSuccess: isSuccessBrand, data: brandList, refetch: getBrandByCat } = useAllBrandsByCategory(categoryUuid);

  const handleCategory = (e: {target:{value:string}}) => {
    setCategoryUuid(e.target.value)
    categoryFilter(e.target.value)
  }

  const handleBrand = (e: {target:{value:string}}) => {
    setBrandUuid(e.target.value)
    brandFilter(e.target.value)
  }

  useEffect(() => {
    if (categoryUuid) {
      getBrandByCat()
    }
  }, [categoryUuid])


  const resetForm:any = useRef(null)

  const cancelForm = () => { 
    resetForm.current.reset();
  }


  return (
        <Modal show={show} onHide={handleClose} contentClassName="productFilters">
        <Modal.Header closeButton>
          <Modal.Title>Filter Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref={resetForm} onSubmit={(e)=>{ e.preventDefault() }}>
            <div className="form-group">
                <select className='form-control' onChange={handleCategory}>
                <option value="">--select category--</option>
                <option value={'null'}>all category</option>
                {isSuccessCat ? categoryList?.length > 0 && categoryList.map((res: { name: string, uuid:string}, index: React.Key) => <option key={index} value={res.uuid}>{res.name}</option>) : ""}
                </select>
            </div>
            <div className="form-group mt-3">
                <select className='form-control' onChange={handleBrand}>
                <option value="">--select brand--</option>
                <option value={'null'}>all brand</option>
                {isSuccessBrand && brandList && brandList.length > 0 && brandList.map((res: { name: string, uuid:string}, index: React.Key) => <option key={index} value={res.uuid}>{res.name}</option>)}
                </select>
            </div>
            <div className="form-group mt-3 d-none">
                <select className='form-control'>
                <option>Location</option>
                </select>
            </div>
            <div className="row mt-3">
              <div className="col-6 d-none">
                  <input type="text" className='form-control' placeholder='Min price'/>
              </div>
              <div className="col-6 d-none">
                  <input type="text" className='form-control' placeholder='Max price'/>
              </div>
              <div className="col-12 mt-4">
                  {isFetching===false && <button className='btn apply' type="submit" onClick={action}>Apply filter</button>}
                  {isFetching===true && <button className='btn apply disabled' type="submit">
                     <span className='spinner-border spinner-border-sm'></span>
                    </button>}
              </div>
              <div className="col-12 mt-3">
                  <button className='btn apply-outline' type="button" onClick={cancelForm}>Clear</button>
              </div>
            </div>
          </form>
        </Modal.Body>

      </Modal>
  )
}

export default ProductFilters

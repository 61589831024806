import React, { FC, Key, useEffect, useState } from 'react'
import "./NewInvestor.scss" 
import { useForm } from 'react-hook-form'
import { investorCreateType } from '../../../../../../../Models/investorType';
import projectService from '../../../../../../../Services/projectService';
import { projectDetailsType } from '../../../../../../../Models/projectType';
import investorService from '../../../../../../../Services/investorService';
import toast from 'react-hot-toast';

interface propsType {
    handleClose: ()=>void
}

const NewInvestor:FC<propsType> = ({handleClose}) => {

    const [sortOrder,] = useState("DESC") 
    const [status,] = useState("ACTIVE") 
    const [project, setProject] = useState([])
    const [loadingProject, setLoadingProject] = useState(false)
    const [pageNumber,] = useState(1)

    const getProject = async () => { 
        setLoadingProject(true)
        await projectService.index(status, pageNumber,  sortOrder).then((response)=>{ 
          setLoadingProject(false)
          setProject(response.data.data.dataset)
        }, error => { 
            setLoadingProject(false)
            console.log("projectService error", error)
        })
    }

    useEffect(()=>{
        getProject()
    }, [status])

    const [request, setRequest] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm<investorCreateType>()
    const onSubmit = handleSubmit((data: investorCreateType)=>processData(data))

    const processData = async (data: investorCreateType) => {
        setRequest(true)

        await investorService.create(data).then((response)=>{
            setRequest(false)
            handleClose()
            console.log("investorService.create", response)
            toast.success("Subscriber added succesfully", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            
            setTimeout(()=>{
                location.reload()
            }, 2000)
            
        }, error => {
            setRequest(false) 
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          
            console.log("add investor error", error)
        })
    }

    return (
        <div className='NewInvestor'>
            <form onSubmit={onSubmit}>
                <div className='form-group mb-3'>
                    <label htmlFor="FirstName">First Name</label>
                    <input type="text" id="FirstName" className='form-control' placeholder='First Name' {...register("firstName", {required: "field is required"})}/>
                    {errors.firstName && <div className='errors'>{errors.firstName.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="LastName">Last Name</label>
                    <input type="text" id="LastName" className='form-control' placeholder='Last Name' {...register("lastName", {required: "field is required"})}/>
                    {errors.lastName && <div className='errors'>{errors.lastName.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input type="telephone" id="phoneNumber" className='form-control' placeholder='Phone number' {...register("phoneNumber", {required: "field is required"})}/>
                    {errors.phoneNumber && <div className='errors'>{errors.phoneNumber.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="Email">Email</label>
                    <input type="email" id="Email" className='form-control' placeholder='Email' {...register("emailAddress", {required: "field is required"})}/>
                    {errors.emailAddress && <div className='errors'>{errors.emailAddress.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="project">Project {loadingProject && <span className='spinner-border spinner-border-sm'></span>}</label>
                    <select  id="project" className='form-control' placeholder='Project' {...register("projectUuid", {required: "field is required"})}>
                        <option value="">--choose--</option>
                        {project && project.map((res:projectDetailsType, index:Key)=><option key={index} value={res.projectUuid}>{res.name}</option>)} 
                     </select>
                     {errors.projectUuid && <div className='errors'>{errors.projectUuid.message}</div>}
                </div> 

                <div className='form-group mb-3'>
                    <label htmlFor="NumberofUnit">Number of Units</label>
                    <input type="text" id="NumberofUnit" className='form-control' placeholder='Number of Unit' {...register("numberOfSlots", {required: "field is required"})}/>
                    {errors.numberOfSlots && <div className='errors'>{errors.numberOfSlots.message}</div>}
                </div> 

                   
                <div className='form-group mb-3'>
                    <label htmlFor="Amount">Total Amount Paid</label>
                    <input type="text" id="Amount" className='form-control' placeholder='Amount' {...register("amountPaid", {required: "field is required"})}/>
                    {errors.amountPaid && <div className='errors'>{errors.amountPaid.message}</div>}
                </div>
            
                <div className='form-group mb-3'>
                    <label htmlFor="Durationleft">Duration Left (in months)</label>
                    <input type="number" id="Durationleft" className='form-control' placeholder='Duration Left' {...register("durationLeft", {required: "field is required"})}/>
                    {errors.durationLeft && <div className='errors'>{errors.durationLeft.message}</div>}
                </div> 

                <div className='form-group mb-3'>
                    <label htmlFor="Durationleft">Subscription Date</label>
                    <input type="date" id="Durationleft" className='form-control' {...register("susbscriptionDate", {required: "field is required"})}/>
                    {errors.susbscriptionDate && <div className='errors'>{errors.susbscriptionDate.message}</div>}
                </div>  
 
               <div className='form-group mb-3'>
                    <button className='btn create-btn' type="submit" disabled={request}>{request && <span className='spinner-border spinner-border-sm'></span>} Add Investor</button>
               </div>
            </form>
        </div>
    )
}

export default NewInvestor

import React, { useEffect, useState } from 'react'
import "./OrderCompletedPrint.scss" 
import { HeaderMeta, ImageSrc } from '../../../../Components' 
import { Logger } from '../../../../Helpers/Logger' 
import { Link, useSearchParams } from 'react-router-dom'
import { OrderCompletedDetails } from '../../Components'
import { useAuth } from '../../../../Components/AuthProvider/AuthProvider'
import cinderBuildLogo from "../../../../Assets/Images/cinderBuild-logo.png"
import orderService from '../../../../Services/orderService'
import { ErrorHandler } from '../../../../Helpers/ErrorHandler'
import { toast } from 'react-hot-toast';

const OrderCompletedPrint = () => {
 
  const [phone, setPhone] = useState<string|undefined>() 
  const [orderDetails, setOrderDetails] = useState<any>({})

 
  const { user } = useAuth() 
  const [searchParams] = useSearchParams();
  const reference = searchParams.get('reference')

  const getOrderDetails = async () => {
    Logger("AAAAA reference", reference)
    await orderService.orderReceipt(reference).then((res) => {
      Logger("AAAAA", res.data.data[0]) 
      setOrderDetails(res.data.data[0])
    }, (error:any) => {
      ErrorHandler(error,
        (e:any) => {
          toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
        })
    })
  }
  useEffect(() => {
    if (reference)getOrderDetails()
  }, [reference])

  useEffect(() => { 
    if (orderDetails && orderDetails[0]?.orderReceiveType === "WARE_HOUSE") {
      setPhone(orderDetails[0]?.orderLocation?.contactPhoneNumber)
    }
  }, [orderDetails])
 
 
  return (
        <div className='checkout order-completed mt-5'>
            <HeaderMeta title={'Order Receipt'}></HeaderMeta>
            <section>
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-9">
                        <img src={cinderBuildLogo} className="logo mt-3"/>
                    </div>
                    <div className="col-3 text-end">
                       <button className='btn p-0 print-btn mt-3' onClick={() => window.print()}><i className='fa fa-print'></i> Print Receipt</button>
                    </div>
                  </div>

                       Yay! Your order with order number #{orderDetails?.referenceNumber} was successful. The order confirmation has been sent to {user && user.phoneNumber} {(!user && phone) ? phone : "" }. 
                            
                           <div className="cart-item-list card border-0">
                              <div className="card-header text-start bg-white py-4">
                                 Order Completed!
                              </div>
                              <div className="card-body ppduct-cart-item">
                                  { orderDetails?.orderItems?.length >0 && orderDetails?.orderItems.map((res:any, index:React.Key) => 
                                  <div className="row h-100" key={index}>
                                    <div className="col-4 col-sm-3">
                                      <ImageSrc src={res.images[0]?.url} alt={''} title={undefined} width={'w-100 rounded shadow-sm'}></ImageSrc>
                                    </div>

                                    <div className="col-6 col-sm-7 p-0">
                                      <div className="productName">{res.productName}</div>
                                      <div className="quantity">Quantity: {res.quantity}</div>
                                      <div className="warehouse d-none d-sm-block">Warehouse: {orderDetails?.orderLocation?.name}</div>
                                    </div>    
                                    <div className="col-2 col-sm-2 text-end my-auto"><div className='price'>&#8358;{res.unitPriceForBuyer * res.quantity}</div></div>    
                                  </div>)}
                              </div>
                            </div>
                            <ul className="total-calculations list-group border-0 rounded-0 rounded-bottom">
                              <li className="list-group-item border-end-0 py-0 border-start-0 border-top-0 d-flex py-0 pt-2 pb-2 justify-content-between align-items-center">
                                  <div className="col-6">Subtotal</div>
                                  <div className="text-end">₦{orderDetails?.calculatedTotalCostMajor}</div>
                              </li>
                              <li className="list-group-item border-0 py-0  d-flex justify-content-between align-items-center">
                                  <div className="col-6 fw-bold">Total</div>
                                  <div className="text-end fw-bold">₦{orderDetails?.calculatedTotalCostMajor}</div>
                              </li> 
                            </ul>
                    <OrderCompletedDetails orderDetails={orderDetails}></OrderCompletedDetails>
                    <div className='w-100 text-center'>
                        <Link to="/"><button className='btn-cs btn-warning ms-md-5'>Continue shopping</button></Link>
                    </div>
                </div>
                </div>
                <hr/>
            </div>

        </section>
    </div>
  )
}
export default OrderCompletedPrint

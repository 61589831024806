import { EDIT_DELIVERY_ADDRESS } from "../../Constants/CheckoutConstants";

const EditDeliveryAddressReducer = (state = 0, action: { type: string; payload: number; }) => {
  switch (action.type) {
    case EDIT_DELIVERY_ADDRESS:
      return action.payload
    default:
      return state
  }
}

export default EditDeliveryAddressReducer;

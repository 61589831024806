import { useEffect, useState, useMemo, Key } from "react";
import userService from "../../../../../../../Services/userService";
import toast, { Toaster } from "react-hot-toast";
import { Logger } from '../../../../../../../Helpers/Logger';
import "./FAQ.scss";
import FAQIllustration from "../../../../../../../Assets/Images/faq-illustration.svg";
import FAQData from "../../../../../../../Data/faq.json";
import FaqItem from "../../../../Buyer/Component/FAQ/FaqItem/FaqItem";

export const FAQ = () => {

    const [userFirstName, setUserFirstName] = useState('');

    useEffect(() => {
        const getMyFirstName = async () => {
            await userService.getProfile()
                .then((res: any) => {
                    setUserFirstName(res.data.data.firstName)
                    Logger("getMyRequest", res.data.data)
                }, (error: any) => {
                    toast.error(error.message, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
                })
        }

        getMyFirstName();
    }, [])

    const faqListHalf = useMemo(() => {
        return FAQData.length / 2;
    }, [FAQData])
    return (
        <div className="container faq-container">
            <div className="row">
                <div className="m-12 col-md-8">
                    <p className="user-first-name">Hello {userFirstName},</p>
                    <p className="cta-text">
                        Here are some questions customers like you usually ask:
                    </p>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                {
                                    FAQData?.slice(0, faqListHalf)?.map((faqItem: { question: string; answer: string; }, index: Key | null | undefined) => {
                                        return (
                                            <FaqItem key={index} question={faqItem.question}
                                                answer={faqItem.answer}
                                            />
                                        )
                                    })
                                }
                            </div>
                            <div className="col-12 col-lg-6">
                                {
                                    FAQData?.slice(faqListHalf)?.map((faqItem: { question: string; answer: string; }, index: Key | null | undefined) => {
                                        return (
                                            <FaqItem key={index} question={faqItem.question}
                                                answer={faqItem.answer}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="justify-content-center d-md-grid col-md-6 d-none " >
                    <img src={FAQIllustration} alt='' className="d-none "/>
                </div>
            </div>
            <Toaster></Toaster>

        </div>
    )
}

export default FAQ;

import React from 'react'
import "./ProductImage.scss"
import { productType } from '../../../../../Models/productType';
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';
import { useQuery } from '@tanstack/react-query';
import { Logger } from '../../../../../Helpers/Logger';

const ProductImage = () => {
  const { data: productDetail } = useQuery<productType>(['productsSingleData'], {
    enabled: false,
    select: (data: any) => data.data.data,
  })
  Logger("productDetail", productDetail)
  return (
        <div className='productImage'> 
            <div id="carouselProductImage" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {productDetail && productDetail?.images?.length > 0? productDetail?.images.map((res: { url:string }, index:React.Key) => {
                      return <div key={index} className={Number(index) === 0 ? 'carousel-item active text-center' : 'carousel-item text-center'}>
                          <ImageSrc src={res.url?res.url: ""} alt={productDetail?.productName} title={productDetail?.productName} width={'d-blocks w-100s'}></ImageSrc> 
                        </div>
                    }): ( <div className='carousel-item active text-center'>
                        <ImageSrc src={""} alt={productDetail?.productName || ""} title={productDetail?.productName} width={'d-blocks w-100s'}></ImageSrc>
                        </div>)}
                </div>

                {productDetail && productDetail?.images?.length > 1 && <div className="carousel-control-container">
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselProductImage" data-bs-slide="prev">
                        <span className="d-icon d-arrowhead-left islarge text-secondary" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselProductImage" data-bs-slide="next">
                        <span className="d-icon d-arrowhead-right islarge text-secondary" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>}

                {productDetail && productDetail?.images?.length > 1 && <div className="carousel-indicators-container">
                    <div className="carousel-indicators">
                      {productDetail &&  productDetail?.images.map((res: { url:string }, index:React.Key) => {
                        return <div key={index} data-bs-target="#carouselProductImage" data-bs-slide-to={Number(index)} className={Number(index) === 0 ? 'active col-2' : "col-2"} aria-label="Slide 1">
                              <ImageSrc src={res.url} alt={productDetail?.productName} title={productDetail?.productName} width={'d-block w-100'}></ImageSrc>
                          </div>
                      })}
                    </div>
                </div>}
          </div>
    </div>
  )
}

export default ProductImage

import React, { FC, Fragment, useState } from 'react'
import "./PrimaryWallet.scss"
import EyeIcon from '../../../../../../../Assets/Icons/EyeIcon';
import { CurrencyFormatter } from '../../../../../../../Helpers'; 
import FundingIcon from '../../../../../../../Assets/Icons/FundingIcon';
import balanceBg from "../../../../../../../Assets/Images/enterprise/wallet-info-bg.png"
import EnterpriseWalletWithdrawalModal from '../../EnterpriseBankingAndFinance/EnterpriseWalletWithdrawal/EnterpriseWalletWithdrawalModal';
import EnterpriseWalletFundingModal from '../../EnterpriseBankingAndFinance/EnterpriseWalletFunding/EnterpriseWalletFundingModal';
 

interface propTyps {
    walletType: number;
    setWalletType: any; 
    myBalance:any;
    nodeRef: any
}

const PrimaryWallet:FC<propTyps> = ({walletType, setWalletType, myBalance, nodeRef }) => { 
    
     const [showWalletFundingModal, setShowWalletFundingModal] = useState(false) 
     const [showWalletWidthdrawalModal, setShowWalletWidthdrawalModal] = useState(false)

    const handleFundingModalOpen = ()=>{
        setShowWalletFundingModal(true)
    }

   
  
    const [toggleShow, setToggleShow] = useState<boolean>(false)

    let hiddenAmount = String(myBalance.amountMajor);
    hiddenAmount = hiddenAmount.replace(/./g, "*");

    return ( 
        <div className="primary-wallet card border-0 p-0 position-relative mt-4 mt-sm-5" style={{backgroundImage: `url(${balanceBg})`}}>
            <div className="card-body pb-0 px-0 overflow-hidden">

             <button className="btn btn-wallet-type" onClick={()=>setWalletType(2)}>Secondary<br/> Wallet &gt;&gt; </button>
        
                <div className="container" ref={nodeRef}>
                    <div className="wallet-type">Primary Wallet 
                     <span><EyeIcon toggleShow={toggleShow} setToggleShow={setToggleShow}/></span>
                    </div>

                    <div className="balance mt-1">{myBalance.currencySymbol}
                     {toggleShow ?<Fragment>{CurrencyFormatter(Number(myBalance?.amountMajor) ? Number(myBalance.amountMajor) : 0)}</Fragment> : hiddenAmount}
                    </div>
                     <div className="row mt-5">
                        <div className="col-4">
                            <button className="btn" onClick={handleFundingModalOpen}><FundingIcon/>Fund Wallet</button>
                        </div> 
                    </div>
                </div>   
            </div>
            <EnterpriseWalletWithdrawalModal showModal={showWalletWidthdrawalModal} hide={(val:boolean) => setShowWalletWidthdrawalModal(val) }></EnterpriseWalletWithdrawalModal>
            <EnterpriseWalletFundingModal showModal={showWalletFundingModal} hide={(val:boolean) => setShowWalletFundingModal(val) }></EnterpriseWalletFundingModal>
      
          </div> 
    )
}

export default PrimaryWallet

import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { CurrencyFormatter } from '../../../../../../../Helpers'
import "./AccountBalance.scss"

interface propsType {
    myBalance: {
        currency: string,
        currencySymbol: string,
        amountMajor: string
      }
}
const AccountBalance:FC<propsType> = ({ myBalance }) => {
  return (
        <div className='accountBalance'>
          <div className="title">Wallet Balance</div>
          <div className="card border-0 p-0  position-relative">
            <div className="card-body pb-0 px-0 overflow-hidden">
                <div className="row ">
                    <div className="col-12 p-0">
                        <div className="amount">{myBalance.currencySymbol} {CurrencyFormatter(Number(myBalance?.amountMajor) ? Number(myBalance.amountMajor) : 0)}</div>
                    </div>
                    <div className="col-6 p-0">
                        <div className="fund text-center">
                            <div>
                                <Link to="/buyer/my-account/bank-and-finance/banking-and-finance-wallet-funding" className='icon'>
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.2617 15.4375H9.26172" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M11.7617 12.998V17.998" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.6609 2.51814L12.6309 2.58814L9.73094 9.31814H6.88094C6.20094 9.31814 5.55094 9.45814 4.96094 9.70814L6.71094 5.52814L6.75094 5.42814L6.82094 5.26814C6.84094 5.20814 6.86094 5.14814 6.89094 5.09814C8.20094 2.06814 9.68094 1.37814 12.6609 2.51814Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M18.0505 9.51758C17.6005 9.37758 17.1205 9.31758 16.6405 9.31758H9.73047L12.6305 2.58758L12.6605 2.51758C12.8105 2.56758 12.9505 2.63758 13.1005 2.69758L15.3105 3.62758C16.5405 4.13758 17.4005 4.66758 17.9205 5.30758C18.0205 5.42758 18.1005 5.53758 18.1705 5.66758C18.2605 5.80758 18.3305 5.94758 18.3705 6.09758C18.4105 6.18758 18.4405 6.27758 18.4605 6.35758C18.7305 7.19758 18.5705 8.22758 18.0505 9.51758Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M21.5217 14.1984V16.1484C21.5217 16.3484 21.5117 16.5484 21.5017 16.7484C21.3117 20.2384 19.3617 21.9984 15.6617 21.9984H7.86172C7.62172 21.9984 7.38172 21.9784 7.15172 21.9484C3.97172 21.7384 2.27172 20.0384 2.06172 16.8584C2.03172 16.6284 2.01172 16.3884 2.01172 16.1484V14.1984C2.01172 12.1884 3.23172 10.4584 4.97172 9.70836C5.57172 9.45836 6.21172 9.31836 6.89172 9.31836H16.6517C17.1417 9.31836 17.6217 9.38836 18.0617 9.51836C20.0517 10.1284 21.5217 11.9884 21.5217 14.1984Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6.71 5.52734L4.96 9.70734C3.22 10.4573 2 12.1873 2 14.1973V11.2673C2 8.42734 4.02 6.05734 6.71 5.52734Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M21.5186 11.2677V14.1977C21.5186 11.9977 20.0586 10.1277 18.0586 9.52766C18.5786 8.22766 18.7286 7.20766 18.4786 6.35766C18.4586 6.26766 18.4286 6.17766 18.3886 6.09766C20.2486 7.05766 21.5186 9.02766 21.5186 11.2677Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                 <div className="label">Fund Wallet</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0">
                        <div className="widthdraw text-center">
                            <div>
                                <Link to="/buyer/my-account/bank-and-finance/banking-and-finance-wallet-withdrawal" className='icon'>
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13 9H7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M22.0002 10.9692V13.0292C22.0002 13.5792 21.5602 14.0292 21.0002 14.0492H19.0402C17.9602 14.0492 16.9702 13.2592 16.8802 12.1792C16.8202 11.5492 17.0602 10.9592 17.4802 10.5492C17.8502 10.1692 18.3602 9.94922 18.9202 9.94922H21.0002C21.5602 9.96922 22.0002 10.4192 22.0002 10.9692Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M17.48 10.55C17.06 10.96 16.82 11.55 16.88 12.18C16.97 13.26 17.96 14.05 19.04 14.05H21V15.5C21 18.5 19 20.5 16 20.5H7C4 20.5 2 18.5 2 15.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.50999 16.75 3.54999C19.33 3.84999 21 5.76 21 8.5V9.95001H18.92C18.36 9.95001 17.85 10.17 17.48 10.55Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div className="label">Withdraw</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
  )
}

export default AccountBalance

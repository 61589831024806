import React from "react";

import "./SellerSidebarContent.scss";
import {
  Link,
  NavLink,
  useLocation, 
} from "react-router-dom"; 
import toast, { Toaster } from "react-hot-toast";

import "react-confirm-alert/src/react-confirm-alert.css";  
import { ImageSrc } from "../../../../Components";
import cinderBuildSellerLogo from "../../../../Assets/Images/cinderBuild-seller-logo.png";
import sellerdash from "../../../../Assets/Images/sellerdash.png";
import { useAuth } from "../../../../Components/AuthProvider/AuthProvider"; 

const SellerSidebarContent = () => {
  const { user } = useAuth();

  const switchAccount = () => {
    toast.loading("Switching account in progress, please wait...");
    setTimeout(() => {
      localStorage.setItem(
        "__cbuN",
        JSON.stringify({ ...user, isSeller: false, switched: 1 })
      );
      toast.dismiss();
      window.location.href = "/buyer";
    }, 2000);
  };

  const location = useLocation()
 console.log("location 000", location)
  return (
    <React.Fragment>
      <div className="sellers-content">
        <ul className="nav flex-column mb-4 ps-2 pb-2">
          <li className="nav-item my-1 mt-3 mb-4 ps-3 d-none d-lg-block"> 
            <Link className="navbar-brand" to="/">
              <ImageSrc
                src={cinderBuildSellerLogo}
                alt={"CinderBuild"}
                title={"CinderBuild"}
                width={"seller-logo"}></ImageSrc> 
            </Link>
          </li>
          <li className="nav-item my-1">
            <NavLink
              className={({ isActive }) =>{
                 console.log("isActive", isActive)
                return ((isActive && location.pathname==="/seller/dashboard") || (location.pathname==="/seller")) ? "nav-link active h-100" : "nav-link h-100" 
              }
              }
              to="/seller">
                
             <img
                src={sellerdash}
                alt="chart-logo"
                className="w-20 me-3 bf-icon"
              />  
             
              <span className="my-auto">Dashboard</span>
              <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
            </NavLink>
          </li>

          <li className="nav-item my-1">
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav-link active h-100" : "nav-link h-100"
              }
              to="product"> 
              <svg
                className="w-20 me-3 "
                width="24"
                height="24"
                viewBox="0 0 28 31"
                xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1717_38895)">
                  <path
                    d="M18.4904 22.75H9.50042C7.78042 22.75 6.49043 22.29 5.69043 21.38C4.89043 20.47 4.58042 19.15 4.79042 17.44L5.69043 9.94C5.95043 7.73 6.51043 5.75 10.4104 5.75H17.6104C21.5004 5.75 22.0604 7.73 22.3304 9.94L23.2304 17.44C23.4304 19.15 23.1304 20.48 22.3304 21.38C21.5004 22.29 20.2204 22.75 18.4904 22.75ZM10.4004 7.25C7.52042 7.25 7.38042 8.38999 7.17042 10.11L6.27043 17.61C6.12043 18.88 6.30042 19.81 6.81042 20.38C7.32042 20.95 8.22042 21.24 9.50042 21.24H18.4904C19.7704 21.24 20.6704 20.95 21.1804 20.38C21.6904 19.81 21.8704 18.88 21.7204 17.61L20.8204 10.11C20.6104 8.37999 20.4804 7.25 17.5904 7.25H10.4004Z"
                    fill="#292D32"
                  />
                  <path
                    d="M18 8.75C17.59 8.75 17.25 8.41 17.25 8V4.5C17.25 3.42 16.58 2.75 15.5 2.75H12.5C11.42 2.75 10.75 3.42 10.75 4.5V8C10.75 8.41 10.41 8.75 10 8.75C9.59 8.75 9.25 8.41 9.25 8V4.5C9.25 2.59 10.59 1.25 12.5 1.25H15.5C17.41 1.25 18.75 2.59 18.75 4.5V8C18.75 8.41 18.41 8.75 18 8.75Z"
                    fill="#292D32"
                  />
                  <path
                    d="M22.41 17.7812H10C9.59 17.7812 9.25 17.4412 9.25 17.0312C9.25 16.6213 9.59 16.2812 10 16.2812H22.41C22.82 16.2812 23.16 16.6213 23.16 17.0312C23.16 17.4412 22.82 17.7812 22.41 17.7812Z"
                    fill="#292D32"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_1717_38895"
                    x="3"
                    y="0"
                    width="32"
                    height="32"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1717_38895"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1717_38895"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              Product
              <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
            </NavLink>
          </li> 

          <li className="nav-item my-1">
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav-link active h-100" : "nav-link h-100"
              }
              to="my-orders">
              <svg
                className="w-20 me-3"
                viewBox="0 0 25 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.5 2H4.24001C5.32001 2 6.17 2.93 6.08 4L5.25 13.96C5.11 15.59 6.39999 16.99 8.03999 16.99H18.69C20.13 16.99 21.39 15.81 21.5 14.38L22.04 6.88C22.16 5.22 20.9 3.87 19.23 3.87H6.32001"
                  stroke="#6D6D6D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.75 22C17.4404 22 18 21.4404 18 20.75C18 20.0596 17.4404 19.5 16.75 19.5C16.0596 19.5 15.5 20.0596 15.5 20.75C15.5 21.4404 16.0596 22 16.75 22Z"
                  stroke="#6D6D6D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.75 22C9.44036 22 10 21.4404 10 20.75C10 20.0596 9.44036 19.5 8.75 19.5C8.05964 19.5 7.5 20.0596 7.5 20.75C7.5 21.4404 8.05964 22 8.75 22Z"
                  stroke="#6D6D6D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.5 8H21.5"
                  stroke="#6D6D6D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> 
              My Orders
              <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
            </NavLink>
          </li>

          <li className="nav-item my-1">
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav-link active h-100" : "nav-link h-100"
              }
              to="my-quotes"> 
              <svg
                className="w-20 me-3"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
                  stroke="#6D6D6D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.5 2V9.85999C15.5 10.3 14.98 10.52 14.66 10.23L12.34 8.09003C12.15 7.91003 11.85 7.91003 11.66 8.09003L9.34003 10.23C9.02003 10.52 8.5 10.3 8.5 9.85999V2H15.5Z"
                  stroke="#6D6D6D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.25 14H17.5"
                  stroke="#6D6D6D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 18H17.5"
                  stroke="#6D6D6D"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Quote Requests
              <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
            </NavLink>
          </li>

          <li className="nav-item my-1">
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav-link active h-100" : "nav-link h-100"
              }
              to="banking-finance">
             <svg className="w-20 me-3"  width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 11.7501H3C2.04 11.7501 1.25 10.9601 1.25 10.0001V6.68013C1.25 6.00013 1.71998 5.31013 2.34998 5.06013L11.35 1.46016C11.73 1.31016 12.27 1.31016 12.65 1.46016L21.65 5.06013C22.28 5.31013 22.75 6.01013 22.75 6.68013V10.0001C22.75 10.9601 21.96 11.7501 21 11.7501ZM12 2.84016C11.96 2.84016 11.92 2.84011 11.9 2.85011L2.90997 6.45015C2.84997 6.48015 2.75 6.61013 2.75 6.68013V10.0001C2.75 10.1401 2.86 10.2501 3 10.2501H21C21.14 10.2501 21.25 10.1401 21.25 10.0001V6.68013C21.25 6.61013 21.16 6.48015 21.09 6.45015L12.09 2.85011C12.07 2.84011 12.04 2.84016 12 2.84016Z" fill="#212121"/>
                <path d="M22 22.75H2C1.59 22.75 1.25 22.41 1.25 22V19C1.25 18.04 2.04 17.25 3 17.25H21C21.96 17.25 22.75 18.04 22.75 19V22C22.75 22.41 22.41 22.75 22 22.75ZM2.75 21.25H21.25V19C21.25 18.86 21.14 18.75 21 18.75H3C2.86 18.75 2.75 18.86 2.75 19V21.25Z" fill="#212121"/>
                <path d="M4 18.75C3.59 18.75 3.25 18.41 3.25 18V11C3.25 10.59 3.59 10.25 4 10.25C4.41 10.25 4.75 10.59 4.75 11V18C4.75 18.41 4.41 18.75 4 18.75Z" fill="#212121"/>
                <path d="M8 18.75C7.59 18.75 7.25 18.41 7.25 18V11C7.25 10.59 7.59 10.25 8 10.25C8.41 10.25 8.75 10.59 8.75 11V18C8.75 18.41 8.41 18.75 8 18.75Z" fill="#212121"/>
                <path d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V11C11.25 10.59 11.59 10.25 12 10.25C12.41 10.25 12.75 10.59 12.75 11V18C12.75 18.41 12.41 18.75 12 18.75Z" fill="#212121"/>
                <path d="M16 18.75C15.59 18.75 15.25 18.41 15.25 18V11C15.25 10.59 15.59 10.25 16 10.25C16.41 10.25 16.75 10.59 16.75 11V18C16.75 18.41 16.41 18.75 16 18.75Z" fill="#212121"/>
                <path d="M20 18.75C19.59 18.75 19.25 18.41 19.25 18V11C19.25 10.59 19.59 10.25 20 10.25C20.41 10.25 20.75 10.59 20.75 11V18C20.75 18.41 20.41 18.75 20 18.75Z" fill="#212121"/>
                <path d="M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z" fill="#212121"/>
                <path d="M12 9.25C10.76 9.25 9.75 8.24 9.75 7C9.75 5.76 10.76 4.75 12 4.75C13.24 4.75 14.25 5.76 14.25 7C14.25 8.24 13.24 9.25 12 9.25ZM12 6.25C11.59 6.25 11.25 6.59 11.25 7C11.25 7.41 11.59 7.75 12 7.75C12.41 7.75 12.75 7.41 12.75 7C12.75 6.59 12.41 6.25 12 6.25Z" fill="#212121"/>
              </svg>

              {/* className="d-icon d-cart-shopping me-3"></i> */}
              Banking & Finance
              <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
            </NavLink>
          </li>

          <li className="nav-item my-1">
            <NavLink className="nav-link" to="product-leasing-program">
              {/* <i className="d-icon d-user-single me-3"></i>  */}
              <svg className="w-20 me-3 " width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#292D32" />
                <path d="M7 13H17" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 11H17" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.66732 16.5L8.66732 7L15.334 16.5L15.334 7" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              Product Leasing Program
            </NavLink>
          </li>

          <li className="nav-item  my-1">
            <NavLink className="nav-link" to="settings">
              {/* <i className="d-icon d-heart-like me-3"></i> */}
              <svg className="w-20 me-3 " width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2 12.8814V11.1214C2 10.0814 2.85 9.2214 3.9 9.2214C5.71 9.2214 6.45 7.9414 5.54 6.3714C5.02 5.4714 5.33 4.3014 6.24 3.7814L7.97 2.7914C8.76 2.3214 9.78 2.6014 10.25 3.3914L10.36 3.5814C11.26 5.1514 12.74 5.1514 13.65 3.5814L13.76 3.3914C14.23 2.6014 15.25 2.3214 16.04 2.7914L17.77 3.7814C18.68 4.3014 18.99 5.4714 18.47 6.3714C17.56 7.9414 18.3 9.2214 20.11 9.2214C21.15 9.2214 22.01 10.0714 22.01 11.1214V12.8814C22.01 13.9214 21.16 14.7814 20.11 14.7814C18.3 14.7814 17.56 16.0614 18.47 17.6314C18.99 18.5414 18.68 19.7014 17.77 20.2214L16.04 21.2114C15.25 21.6814 14.23 21.4014 13.76 20.6114L13.65 20.4214C12.75 18.8514 11.27 18.8514 10.36 20.4214L10.25 20.6114C9.78 21.4014 8.76 21.6814 7.97 21.2114L6.24 20.2214C5.33 19.7014 5.02 18.5314 5.54 17.6314C6.45 16.0614 5.71 14.7814 3.9 14.7814C2.85 14.7814 2 13.9214 2 12.8814Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              Settings
              <i className="d-icon d-arrowhead-right me-3 float-end my-auto"></i>
            </NavLink>
          </li>
        </ul>
        <ul className="nav flex-column mt-5 pt-5 support">
          <li className="nav-item my-1 ">
            <Link className="nav-link" to="#" onClick={switchAccount}>
              Switch to buyer
            </Link>
          </li>
          <li className="nav-item  my-1">
            <NavLink className="nav-link" to="support">
              {/* <i className="d-icon d-heart-like me-3"></i> */}

              Support
            </NavLink>
          </li>
        </ul>
      </div>
      <Toaster></Toaster>
    </React.Fragment>
  );
};

export default SellerSidebarContent;

import React from 'react'

const FundingIcon = () => {
    return (
        <svg className="me-1" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2617 15.4375H9.26172" stroke="#F5A623" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.7617 13V18" stroke="#F5A623" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.66 2.51814L12.63 2.58814L9.72996 9.31814H6.87996C6.19996 9.31814 5.54996 9.45814 4.95996 9.70814L6.70996 5.52814L6.74996 5.42814L6.81996 5.26814C6.83996 5.20814 6.85996 5.14814 6.88996 5.09814C8.19996 2.06814 9.67996 1.37814 12.66 2.51814Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.0505 9.51563C17.6005 9.37563 17.1205 9.31562 16.6405 9.31562H9.73047L12.6305 2.58562L12.6605 2.51562C12.8105 2.56563 12.9505 2.63563 13.1005 2.69563L15.3105 3.62563C16.5405 4.13563 17.4005 4.66562 17.9205 5.30562C18.0205 5.42562 18.1005 5.53563 18.1705 5.66563C18.2605 5.80563 18.3305 5.94562 18.3705 6.09562C18.4105 6.18562 18.4405 6.27563 18.4605 6.35563C18.7305 7.19563 18.5705 8.22563 18.0505 9.51563Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.5217 14.2003V16.1503C21.5217 16.3503 21.5117 16.5503 21.5017 16.7503C21.3117 20.2403 19.3617 22.0003 15.6617 22.0003H7.86172C7.62172 22.0003 7.38172 21.9803 7.15172 21.9503C3.97172 21.7403 2.27172 20.0403 2.06172 16.8603C2.03172 16.6303 2.01172 16.3903 2.01172 16.1503V14.2003C2.01172 12.1903 3.23172 10.4603 4.97172 9.71031C5.57172 9.46031 6.21172 9.32031 6.89172 9.32031H16.6517C17.1417 9.32031 17.6217 9.39031 18.0617 9.52031C20.0517 10.1303 21.5217 11.9903 21.5217 14.2003Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.71 5.53125L4.96 9.71125C3.22 10.4612 2 12.1913 2 14.2013V11.2713C2 8.43125 4.02 6.06125 6.71 5.53125Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.5186 11.2638V14.1938C21.5186 11.9938 20.0586 10.1237 18.0586 9.52375C18.5786 8.22375 18.7286 7.20375 18.4786 6.35375C18.4586 6.26375 18.4286 6.17375 18.3886 6.09375C20.2486 7.05375 21.5186 9.02375 21.5186 11.2638Z" stroke="#F5A623" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default FundingIcon

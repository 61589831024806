import React, { useState , Key } from 'react'
import "./EstateHeroSection.scss"
import estatebanner from '../../../../../../../Assets/Images/estate/estate-banner-image2.jpeg'
import logo from '../../../../../../../Assets/Images/logo-white.png'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import projectService from '../../../../../../../Services/projectService'
import { HorizontalLoader } from '../../../../../../../Components' 
import { ProjectItem } from '../../Index'
import { projectDetailsType } from '../../../../../../../Models/projectType'

export type SeatchformType = {
    type?: string,
    minCostPerSlot?: number,
    maxCostPerSlot?: number,
    searchWord?: string,
    projectName?: string
}
const EstateHeroSection = () => {
    const [hideElement, setHidden] = useState(false)
    const [request, setRequest] = useState(false)
    const [requestError, setRequestError] = useState<string>()
    
    const [projects, setProjects] = useState<Array<projectDetailsType>>()
   
    const { register, handleSubmit } = useForm<SeatchformType>()

    const onSubmit = handleSubmit(async (data:SeatchformType)=>{ 
        setRequest(true)
        setProjects(undefined)
        setRequestError(undefined)
        await projectService.projectSearch(data).then((response)=>{ 
         setProjects(response.data.data)
        setRequest(false)
        setRequestError(undefined)
        }, error =>{
         setProjects(undefined)
         setRequest(false)
            setRequestError(error.response.data.error)
            console.log(error)
        })
    })
    return (
        <div className='EstateHeroSection mb-5'>
            <div className="heroSection-inner" style={{
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(${estatebanner})`,
                backgroundSize: 'cover'
                }}>
                <div className="container pt-4"> 
                    <div className="logo">
                    <img src={logo}></img>
                    </div>
                    <div className="hero-content">
                        <div>
                            <div className="title" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                                Find your dream <br></br>property at better prices
                            </div>

                            <div className="button-action mt-5">
                                <Link to="/estate-subscribers-setup" data-aos="fade-up">
                                    <button className='btn'>Get Started</button>
                                </Link>
                                <Link to="/estate-managers/subscriber-projects" data-aos="fade-up">
                                    <button className='btn ms-3'>List a Property</button>
                                </Link>
                            </div>
                        </div>
                    </div> 
                </div>
            </div> 
            <div className="search-container" data-aos="fade-up">  
                <div className='search-container-inner shadow rounded'>
                    <div className="container px-md-5">
                        <form onSubmit={onSubmit}>
                            <div className="row h-100">
                                <div className="col-md-5 my-auto">
                                    <div className="form-input mb-2">
                                        <label htmlFor="">Location</label>
                                        <input type="text" className='form-control' {...register("searchWord")}  placeholder='Project Location'/>
                                    </div>
                                    {hideElement && <div className="form-input mt-2" >
                                        <label htmlFor="">Max Price</label>
                                        <input type="text" className='form-control' {...register('maxCostPerSlot')}  placeholder='Project Location'/>
                                    </div>}
                                </div>
                                <div className="col-md-5 my-auto"> 
                                    <div className="form-input  mb-2">
                                        <label htmlFor="">Project Type</label>
                                        <select className='form-control' {...register("type")}  placeholder='Project Type'>
                                            <option>--choose--</option>
                                            <option value="Shop">Shop</option>
                                            <option value="Office Spaces">Office Spaces</option>
                                            <option value="Flats and Apartments">Flats and Apartments</option>
                                            <option value="Min Price">Min Price</option>
                                            <option value="Max Price">Max Price</option>
                                            <option value="Semi Detached Bungalow">Semi Detached Bungalow</option>
                                            <option value="Semi Detached Duplex">Semi Detached Duplex</option>
                                            <option value="Co-working Space">Co-working Space</option>
                                            <option value="Detached Bungalow">Detached Bungalow</option>
                                            <option value="SUJIMOTO">SUJIMOTO</option>
                                            <option value="Featured Project">Featured Project</option>
                                            <option value="Shop In A Mall">Shop In A Mall</option>
                                            <option value="Self Contain">Self Contain</option>
                                            <option value="Mini Flats">Mini Flats</option>
                                            <option value="Detached Duplex">Detached Duplex</option>
                                            <option value="Houses">Houses</option>
                                            <option value="Terraced Bungalow">Terraced Bungalow</option>
                                            <option value="Commercial Properties">Commercial Properties</option>
                                            <option value="Terraced Duplex">Terraced Duplex</option>
                                        </select>
                                    </div>
                                    {hideElement && <div className="form-input mt-2 mb-2">
                                        <label htmlFor="">Min Price</label>
                                        <input type="text" className='form-control' {...register("minCostPerSlot")} placeholder='Project Type'/>
                                    </div>}
                                </div>
                                <div className="col-md-2 my-auto">
                                    <button type="submit" className='btn btn-search mt-md-3' disabled={request}>
                                        {request && <span className='spinner-border spinner-border-sm'></span>}
                                        Search</button>
                                </div>
                            </div>
                            <div className='more-text' onClick={()=>setHidden(!hideElement)}>
                                {!hideElement && <>More <span className='d-icon d-arrowhead-down'></span></>}
                                {hideElement && <>Less <span className='d-icon d-arrowhead-up'></span></>}
                            </div>
                        </form>
                    </div> 
                </div> 
            </div>
 
            <div className='search-result'>
                <div className="search-result-inner">
                   {request && <div className="card shadow-sm border-0 w-100">
                        <div className="card-body">
                            <HorizontalLoader></HorizontalLoader> 
                        </div>
                    </div>}

                   {requestError && <div className="card shadow-sm border-0 w-100">
                        <div className="card-body text-center request-error">
                             {requestError}
                        </div>
                    </div>}

                    {projects && <div className="card shadow-sm border-0 w-100">
                          <div className="card-body">
                            <div className="search-title">
                               {projects.length} project{projects.length > 1 && "s"} found
                            </div>
                            <div className="row">
                                { projects.map((project:projectDetailsType, index:Key)=><div key={index} className='col-md-3'><ProjectItem project={project}></ProjectItem></div>)}
                            </div>

                            <div className='search-link'>
                                <Link to="/estate-managers/subscriber-projects">See more  <i className='d-icon d-arrow-skip-forward'></i></Link>
                            </div>
                          </div>
                        </div>
                     }
                </div>
            </div>  
        </div>
    )
}

export default EstateHeroSection

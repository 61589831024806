
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "684613b2ba7744c892d70bb9ebd778eec54c0885",
  databaseURL: "https://cinderbuild-dev.firebaseapp.com",
  authDomain: "cinderbuild-dev.firebaseapp.com",
  projectId: "cinderbuild-dev",
  storageBucket: "cinderbuild-dev.appspot.com",
  messagingSenderId: "425688837510",
  appId: "1:425688837510:web:b7a44905bf9376935a9007",
  measurementId: "G-CKDYYK7Z7W"
};

const firebase = initializeApp(firebaseConfig);
export const firbaseDB = getFirestore(firebase);

import React, { FC } from 'react';
import "./SellerPageOrderAddInfo.scss";

interface propsTypes {
    contactFullName: string;
    contactPhoneNumber: string;
    contactEmail?: string;
}

const SellerPageOrderAddInfo: FC<propsTypes> = ({ contactFullName, contactPhoneNumber, contactEmail }: propsTypes) => {
    return (
        <li className='list-group-item order-contact-details'>
            <div className='d-flex flex-row justify-content-start order-contact-details-title align-items-center'>
                <p className='fw-normal list-item-title col ml-12px'>Additional information</p>
            </div>
            {/* eslint-disable-next-line */}
            <div className='fw-normal'>
                <p>Fullname:  {contactFullName}</p>
                <p>Phone number: {contactPhoneNumber}</p>
                <p>Email address:  {contactEmail}</p>

            </div>
        </li>
    )
}
export default SellerPageOrderAddInfo;

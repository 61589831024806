
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../Helpers/ErrorHandler";
import { Logger } from "../../../Helpers/Logger";
import productService from "../../../Services/productService";

export const useCategory = (page:number, sortOrder: string, pageSize:number) => {
  return useQuery(["productsCategory"], () => productService.getAllCategory(page, sortOrder, pageSize), {
    enabled: true,
    retry: 1,
    select: (data:{ data: {data:{ dataset: any[] } }}) => {  
      return data.data.data.dataset
    },
    onSuccess (data) {
      // toast.success("success", { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
    },
    onError (error: any) {
      Logger("useCategory error", error)
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          ErrorHandler(error,
            () => {
              toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
            })
        );
      } else {
        ErrorHandler(error,
          () => {
            toast.error(error.response.data.error, { duration: 20000, className: 'custom-alert bg-white text-dark', position: "top-right" });
          })
      }
    },
  });
};

import React, { FC } from 'react';
import DeliveryIcon from "../../../../../../../Assets/Images/icon/profile.png";
import "./EnterpriseOrderContactDetails.scss";

interface propsTypes {
    contactFullName:string;
    contactPhoneNumber:string;
    contactEmail?:string;
}

const EnterpriseOrderContactDetails:FC<propsTypes> = ({ contactFullName, contactPhoneNumber, contactEmail }:propsTypes) => {
    return (
        <li className='list-group-item order-contact-details'>
           <div className='d-flex flex-row justify-content-start order-contact-details-title align-items-center'>
            <img width='20px' height='20px' className='m-0' src={DeliveryIcon} alt=''/>
            <p className='fw-normal list-item-title col ml-12px'>Contact details</p>
           </div>
           {/* eslint-disable-next-line */}
           <div className='fw-normal ms-4 ps-2'>
            {
                contactFullName && <div className='m-0'>{contactFullName}</div>
                // eslint-disable-next-line
            }     
            {
                contactEmail && <div className='m-0'>{contactEmail}</div>
            }
            {
                contactPhoneNumber && <div className='m-0'>{contactPhoneNumber}</div>
            }
           </div>
        </li>
    )
}
export default EnterpriseOrderContactDetails;
